.ui-resizable-handle {
	.transition(0.1s);
	border-radius: @rounded-corners;

	&:hover {
		background-color: rgba(50,50,50,0.4);
	}

	&:active {
		background-color: rgba(50,50,50,0.5);
	}
}

.ui-resizable-e, .ui-resizable-w {
	width: 8px;
	right: -4px;
	cursor: col-resize;
}

.ui-resizable-s, .ui-resizable-n {
	height: 10px;
	margin-top: -2px;
	cursor: row-resize;
}
