.filelist {
	color: @Content_TextColor;
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 20px;

	&__container {
		overflow: auto;
		height: ~"calc(100% - 37px)";
		margin-top: 10px;
	}

	&__container-not-available {
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-style: italic;
			margin-top: 150px
		}
	}

	&__error {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin: 4px 0 0 7px;
		justify-content: flex-end;
		flex: 1 0 auto;

		.filelist__error-icon {
			margin-right: 5px;
			color: @state-error-hover;
		}

		.filelist__error-message {
			color: @state-error-hover;
		}

		.filelist__offline-icon {
			margin-right: 5px;
			color: @disabled-text;
		}

		.filelist__offline-message {
			color: @disabled-text;
		}
	}

	&__error + .g-kg-title-sub-container {
		flex: 0 1 auto;
	}

	&__item {
		display: flex;
		align-items: center;
		background-color: @Content_TextColor_Fade40;
		text-transform: capitalize;
		letter-spacing: 0.2em;
		font-size: 20px;
		border-radius: 6px;
		width: 100%;
		cursor: pointer;

		& + .filelist__item {
			margin-top: 10px;
		}

		.filelist__item-title {
			padding: 16px;
			width: 95%;
			.text-ellipsis();
		}

		.filelist__item-type {
			padding: 16px;
			font-size: 18px;
			color: @Content_TextColor;
			position: relative;

			&__crossed {
				position: absolute;
				width: 30px;
				height: 1px;
				transform: rotate(45deg);
				background-color: @Content_TextColor;
				top: 25px;
				left: 9px;
			}
		}

		.filelist__item-button {
			text-transform: capitalize;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			background-color: @Content_TextColor_Fade60;
			text-transform: uppercase;
			padding: 16px 30px;
		}
	}

	&--disabled,
	&__item--disabled {
		opacity: 0.5;
		cursor: default;
	}
}
