.g-workflow-events-add-modal {
	.modal-dialog.g-modal-autoresize .modal-content {
		width: 100%;
		height: 100%;
		min-width: 350px;
		max-width: 500px;
		min-height: 265px;
		max-height: 300px;
	}

	.modal-body ul li {
		height: @g-control-height;

		.g-container {
			height: 100%;
		}
	}
}
