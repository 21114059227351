/* DESKTOP */
.gw-img-edit {

	&:not(.gw-viewonly):not(.disabled) {

		&.gw-drag-over {
			.gw-img {
				opacity: 0.5;
			}
		}

		&:hover {
			cursor: pointer;

			.gw-img-wrap-vert {
				display: block;
			}

			.gw-img {
				opacity: 0.5;
			}
		}
	}

	&.disabled {
		opacity: 0.5;
		filter: grayscale(100%);

		&:hover,
		&:focus {
			color: @disabled-text;
		}
	}

	.gw-img-wrap-vert {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 1px;
		background-color: @white;
		border-radius: 4px 0;
		display: none;

		.gw-img-input-wrap {
			color: @input-color;
			font-family: @default-font;
			font-weight: @InputControl_FontWeight;
			font-size: @font-size-base;
			width: 94px;
			height: 30px;
			padding: 4px;
			border-radius: 5px;
			margin: 0 2px 2px 2px;

			label {
				margin: 0px;
				display: block;
				height: 22px;
				color: @input-color;
				font-family: @default-font;
				font-weight: @InputControl_FontWeight;
				font-size: @font-size-base;
			}

			.gw-img-btn-edit,
			.gw-img-btn-remove {
				float: left;
				font-size: 16px;
				margin: 0 auto;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				margin: 0 6px 0 0;
			}

			.gw-img-text {
				float: left;
			}

			.gw-img-input {
				display: none;
				height: 0px;
				width: 0px;
			}

			&:hover {
				background-color: @Theme_Hover;
				color: @Theme_HoverText;
				cursor: pointer;

				label {
					color: @Theme_HoverText;
					cursor: pointer;
				}
			}

			&:first-child {
				margin-top: 2px;
			}
		}
	}
}
