.g-loading-animation {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	.icon-spinner {
		font-size: 18px;
		line-height: unset;
		margin-right: 8px;
	}
}
