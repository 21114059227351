.g-item-list-command-row {
	padding: 7px 9px 5px 5px;
	text-align: right;

	.form-control {
		padding-top: 2px;
		width: 175px;
	}

	.input-group {
		width: 100%;
		height: 36px;
	}

	.input-group-addon {
		width: 60px;
		padding: 10px 0 0;
	}

	.form-group-wide {
		display:block;
		> .form-control {
			width: 100%; 
		}
	}
}

.g-item-list-fetchnext {
	width: 100%;
	display: inline-block;
	text-align: center;
}

.g-item-list-count {
	padding: 3px 15px;
	padding-top: 1px;
	display: none;
}

.g-item-list-groupbox {
	width: 100%;
}

.g-item-list {
	width: 100%;
	height: 100%;
	overflow: hidden;

	&.list-inline {
		margin-left: 0;
		margin-bottom: 0;
	}

	.gwGroupBox {
		position: relative;
		right: -8px;
		height: 100%;

		.panel-title {
			padding-left: 9px;
		}

		.panel-body {
			height: calc(~'100% - 40px');
		}
	}

	> li {
		width: 49%;
		height: 100%;
		position: relative;
		display: inline-flex;

		.ui-sortable, .g-entity-available-list {
			min-height: 35px;
			padding: 0px 5px;
			margin: 0 1px 0 0;
			list-style-type: none;

			li {
				padding: 5px 5px 6px;
				margin: 4px 3px;
				color: @Theme_ValueText;
				background-color: @Grid_ViewportRowColor;
				border: 1px solid @Grid_ViewportBorderColor;

				&:hover {
					cursor: pointer;
					background-color: @Grid_ViewportSelectedRowColor;
				}

				&.g-item-list-highlight {
					padding: 1px;
					margin: 0;
					background-color: #000;
				}

				&.ui-sortable-helper {
					background-color: @Grid_ViewportSelectedRowColor;
				}
			}

			.btn {
				padding: 0 4px;
				margin: 0 0 3px;
			}

			.col-desc {
				font-size: 12px;
				color: @Theme_GeneralText;
			}
		}

		&:first-child ul,
		&:last-child ul {
			li {
				position: relative;
			}

			.btn {
				position: absolute;
				top: 5px;
			}

			span {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&:first-child {
			ul {
				.btn {
					right: 5px;
				}

				span {
					margin-right: 30px;
				}
			}
		}

		&:last-child ul {
			.btn {
				left: 5px;
			}

			span {
				margin-left: 30px;
			}
		}

		&:first-child, &:last-child {
			.g-item-list-groupbox.alert-danger {
				.alert-danger-mixins();

				&.gwValidation-highlight > .panel-heading {
					.alert-danger-highlight();
				}

				> .panel-heading {
					.alert-danger-mixins();
				}
			}
		}

		.show-available-items-count {
			>.g-item-list-count {
				display: block;
			}
		}
	}
}
