.gwHyperlink {
	width: 100%;
	height: 100%;
	display: inline-block;
	color: @Theme_DefaultButton;
	font-size: 12px;
	text-decoration: none;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 5px 8px;

	&:hover {
		color: @Theme_FieldTitle;
	}
}
