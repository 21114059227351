.gwItemSelector {
	width: 100%;
	height: 100%;

	> .g-item-selector-heading {
		font-size: 18px;
		color: #000;
	}

	> .g-item-selector-sub-heading {
		font-style: italic;
		color: #000;
	}

	> .g-item-selector-search-list {
		position: relative;
		height: ~"calc(100% - 100px)";
		margin-top: 10px;
	}

	> .progress {
		margin-top: 20px
	}
}
