.tooltip {
	z-index: 2000; /* In Bootstrap 2.3.1 Tooltips fall behind modal dialogs, so this places it above */
	position: absolute;
	display: block;
	filter: alpha(opacity=0);
	opacity: 0;

	&.in {
		filter: alpha(opacity=90);
		opacity: 0.9;
	}

	&.top {
		padding: 5px 0;
		margin-top: 0;

		&.g-grid-icon-item-tooltip {
			margin-top: 10px;
		}

		.tooltip-arrow {
			border-top-color: @Tooltip_BackgroundColor;
		}

		&.tooltip-error .tooltip-arrow {
			border-top-color: @state-error;
		}

		&.tooltip-message-error .tooltip-arrow {
			border-top-color: @state-message-error;
		}

		&.tooltip-warning .tooltip-arrow {
			border-top-color: @state-warning;
		}

		&.tooltip-information .tooltip-arrow {
			border-top-color: @state-info;
		}
	}

	&.right {
		padding: 0 5px;
		margin-left: 0;
	}

	&.bottom {
		padding: 5px 0;
		margin-top: 0;

		.tooltip-arrow {
			border-bottom-color: @Tooltip_BackgroundColor;
		}

		&.tooltip-error .tooltip-arrow {
			border-bottom-color: @state-error;
		}

		&.tooltip-message-error .tooltip-arrow {
			border-bottom-color: @state-message-error;
		}

		&.tooltip-warning .tooltip-arrow {
			border-bottom-color: @state-warning;
		}

		&.tooltip-information .tooltip-arrow {
			border-bottom-color: @state-info;
		}
	}

	&.left {
		padding: 0 5px;
		margin-left: 0;
	}
}

.tooltip-inner {
	max-width: 250px;
	padding: 5px 8px;
	background-color: @Tooltip_BackgroundColor;
	border-radius: 4px;
	color: #fff;
	font-weight: @Tooltip_BodyFontWeight;
	text-align: center;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	line-break: auto;
	white-space: pre-wrap;
	word-break: normal;
	word-spacing: normal;
	.g-font-size-px(@Tooltip_BodyFontSize);

	p {
		margin: 2px;
	}

	.tooltipHeader {
		font-weight: bold;
	}

	.tooltip-error & {
		background-color: @state-error;
	}

	.tooltip-message-error & {
		background-color: @state-message-error;
	}

	.tooltip-warning & {
		background-color: @state-warning;
	}

	.tooltip-information & {
		background-color: @state-info;
	}
}

.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;

	.tooltip.top & {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 5px 5px 0;
		border-top-color: #000;
	}

	.tooltip.top-left & {
		right: 5px;
		bottom: 0;
		margin-bottom: -5px;
		border-width: 5px 5px 0;
		border-top-color: #000;
	}

	.tooltip.top-right & {
		bottom: 0;
		left: 5px;
		margin-bottom: -5px;
		border-width: 5px 5px 0;
		border-top-color: #000;
	}

	.tooltip.right & {
		top: 50%;
		left: 0;
		margin-top: -5px;
		border-width: 5px 5px 5px 0;
		border-right-color: #000;
	}

	.tooltip.left & {
		top: 50%;
		right: 0;
		margin-top: -5px;
		border-width: 5px 0 5px 5px;
		border-left-color: #000;
	}
	.tooltip.bottom & {
		top: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 0 5px 5px;
		border-bottom-color: #000;
	}
	.tooltip.bottom-left & {
		top: 0;
		right: 5px;
		margin-top: -5px;
		border-width: 0 5px 5px;
		border-bottom-color: #000;
	}
	.tooltip.bottom-right & {
		top: 0;
		left: 5px;
		margin-top: -5px;
		border-width: 0 5px 5px;
		border-bottom-color: #000;
	}
}
