.gwColorPicker {
	.input-group-addon {
		line-height: @g-control-height;
		border: 1px solid @InputControl_BorderBrushStart;
	}

	.form-control[disabled] {
		+ .input-group-addon {
			cursor: default;
		}
	}

	.form-control {
		padding-left: 15px;

		&:focus + .input-group-addon {
			border: 1px solid @InputControl_FocusedBorderBrushEnd;
		}
	}

	input[type="text"] {
		padding-bottom: 0;
	}

	&.input-group:before {
		content: '#';
		position: absolute;
		z-index: 5;
		left: 7px;
		top: 50%;
		font-size: 14px;
	}
}

.colpick_glow {
	background-color: @white;
	border-width: @popover-border-width;
	border-color: @popover-border-color;
	border-radius: @popover-border-radius;
	.box-shadow(2px 4px 6px rgba(51, 51, 51, 0.6));
	z-index: 1000;

	.colpick_field_letter,
	.colpick_field_arrs,
	input[type="text"] {
		background: @white;
	}

	.colpick_submit {
		color: @Theme_DefaultButtonText;
		background: @Theme_DefaultButton;
		border: 1px solid #d6d6d6;
		top: 145px;

		&:hover {
			color: @Theme_HoverText;
			background: @Theme_Hover;
			border-color: #d6d6d6;
		}
	}

	.colpick_field_letter {
		width: 17px;
		z-index: 1;
	}

	.ui-spinner-button {
		width: 12px;
		font-size: 0;
		cursor: pointer;

		&:hover {
			.opacity(0.5);
		}

		.ui-icon {
			left: -1px;
			width: 12px;
			height: 12px;
		}
	}

	.ui-spinner-input {
		left: 0;
		width: 35px;
	}

	.colpick_field .ui-spinner {
		position: absolute;
		right: 0;
		left: 17px;
		margin: 0;
		padding: 0;
		height: 19px;
		line-height: 19px;
		border: none;
		font-size: 12px;
		font-family: Arial, Helvetica, sans-serif;
		color: #555;
		text-align: right;
		outline: none;
	}

	.colpick_field_arrs {
		display: none;
	}
}

.colpick_field_uarr:before {
	border-bottom-color: @Theme_ValueText;
}

.colpick_field_darr:before {
	border-top-color: @Theme_ValueText;
}
