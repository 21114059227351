.gwSearchListAddressesPopup.popover {
	width: 300px;
	margin: 0;

	.arrow {
		display: none;
	}

	.popover-content {
		max-height: 400px;
		padding-bottom: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	ul {
		padding: 0;
		margin: 0;

		li {
			padding-top: 9px;
			padding-bottom: 9px;
			list-style-type: none;

			&:first-child {
				padding-top: 0;
			}

			&:not(:first-child) {
				border-top: 1px solid;
			}
		}
	}

	.badge {
		display: inline-block;
		min-width: 10px;
		padding: 3px 7px;
		font-size: 12px;
		font-weight: bold;
		line-height: 1;
		color: #fff;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		background-color: #777777;
		border-radius: 10px;
	}

	.badge:empty {
		display: none;
	}
}
