.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: none;
	padding: 0;
	font-weight: 400;
	line-height: 1.42857143;
	line-break: auto;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	white-space: normal;
	font-size: 14px;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.6);
	z-index: @popover-z-index;
	max-width: @g-menu-max-width;
	font-family: inherit;
	font-style: inherit;

	.popover-title {
		margin: 0;
		font-size: 14px;
		background-color: #f7f7f7;
		border-bottom: 1px solid #ebebeb;
		border-radius: 5px 5px 0 0;
		padding: 0 0 0 10px;
		display: flex;
		align-items: center;
		height: @g-modal-header-max-height;

		& > div {
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
		}

		.popover-title-button {
			cursor: pointer;
			margin-left: auto;
			padding: 10px 15px;
			color: @Theme_ValueText;
			display: flex;
			align-items: center;
			.opacity-hover;
		}
	}

	&.right {
		margin-left: @right-popover-margin-left;

		> .arrow {
			top: 50%;
			left: -(@popover-arrow-size + 1);
			margin-top: -(@popover-arrow-size + 1);
			border-left-width: 0;
			border-right-color: @popover-border-color;

			&:after {
				content: " ";
				border-left-width: 0;
				bottom: -6px;
				left: 1px;
				border-right-color: @Popover_BackgroundColor;
			}
		}
	}

	&.left {
		margin-left: @left-popover-margin-left;

		> .arrow {
			top: 50%;
			right: -11px;
			border-right-width: 0;
			right: -(@popover-arrow-size + 1);
			margin-top: -(@popover-arrow-size + 1);
			border-left-color: @popover-border-color;

			&:after {
				content: " ";
				border-right-width: 0;
				right: 1px;
				bottom: -@popover-arrow-size;
				border-left-color: @Popover_BackgroundColor;
			}
		}
	}

	&.bottom {
		margin-top: 10px;

		> .arrow {
			left: 50%;
			border-top-width: 0;
			top: -(@popover-arrow-size + 1);
			margin-left: -(@popover-arrow-size + 2);
			border-bottom-color: @popover-border-color;

			&:after {
				content: " ";
				border-top-width: 0;
				border-bottom-color: #fff;
				top: 1px;
				margin-left: -@popover-arrow-size;
				border-bottom-color: @Popover_BackgroundColor;
			}
		}
	}

	&.top {
		margin-top: -10px;

		> .arrow {
			border-bottom-width: 0;
			left: 50%;
			bottom: -(@popover-arrow-size + 1);
			margin-left: -(@popover-arrow-size + 1);
			border-top-color: @popover-border-color;

			&:after {
				content: " ";
				border-bottom-width: 0;
				bottom: 1px;
				margin-left: -@popover-arrow-size;
				border-top-color: @Popover_BackgroundColor;
			}
		}
	}

	&.g-header-popover,
	&.g-grid-action-popover {
		min-width: @g-menu-min-width;
	}

	&.g-grid-action-popover.top {
		margin-top: @g-menu-grid-action-popover-top;
	}

	> .arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
		display: block;
		border-width: (@popover-arrow-size + 1);

		&:after {
			content: "";
			border-width: 10px;
			position: absolute;
			display: block;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			border-width: @popover-arrow-size;
		}
	}

	li:hover > .submenu-arrow:after {
		border-left-color: @white;
	}

	> .gwDynamicMenu {
		max-width: calc(~'100vw - 22px');

		> ul {
			padding-left: 0;

			> li > a > input {
				margin: @g-menu-input-margin;
			}
		}
	}
}

.popover-content > .gwDynamicMenu,
.dropdown.open > .dropdown-menu {
	border-radius: @popover-border-radius;
	opacity: 1;
	visibility: visible;
}

.popover-content {
	height: @popover-content-height;
	padding: 0;
	border-radius: @popover-border-radius;
	background-color: @Popover_BackgroundColor;

	ul {
		.dropdown-menu-shared;
		box-shadow: none;
	}
}
