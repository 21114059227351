@import "~/Content/Themes/External/bootstrap/less/variables.less";
@unitButtonWidth: 19px;

.gwMeasure {
	height: 100%;
	display: flex;
	border: @input-border-width solid @InputControl_BorderBrushStart;
	background-color: @white;

	label {
		padding: 4px 7px 0;
	}

	input.form-control:focus:not([readonly]) {
		background: none !important;
		border: none !important;
	}

	.input-group-btn.btn {
		z-index: 2;
		width: @unitButtonWidth;
		padding: 0;
		border: none;
		color: @Icons_Color;
		background: none;
		display: block;
		order: 1;

		&[disabled] {
			opacity: 1;
		}

		span {
			text-align: center;
			vertical-align: middle;
		}

		i.icon-caret-down {
			text-align: center;
		}

		&.g-measure-mouseover {
			&.alert-danger {
				.alert-danger-mixins();
			}

			&.alert-message-error {
				.alert-message-error-mixins();
			}

			&.alert-warning {
				.alert-warning-mixins();
			}

			&.alert-info {
				.alert-info-mixins();
			}

			.icon-caret-down {
				opacity: .5;
			}
		}
	}

	&.g-measure-focused {
		background: @InputControl_FocusedBackgroundColorEnd;
		border: 1px solid @InputControl_FocusedBorderBrushEnd;

		.input-group-btn.btn {
			outline: none;
		}
	}

	input {
		min-width: 0;
	}

	input.g-measure-unit {
		text-align: center;
		text-transform: uppercase;
		position: static;
		border: none;
		padding-left: 0;
		padding-right: 0;
		background: none;
	}

	input.g-measure-magnitude {
		text-align: right;
		position: static;
		flex: 1 1 0px;
		padding-right: 6px;
		border: none;
		background: none;
	}

	.g-measure-divider {
		display: inline-block;
		align-self: flex-end;
		height: @unitDividerHeight;
		width: @input-border-width; // should be 0
		z-index: 3;
		background-color: @InputControl_BorderBrushStart;
		border-width: 0;
		order: 1;
	}


	&:not(.g-measure-focused) {
		&.alert-danger {
			.alert-danger-mixins();

			.g-measure-divider {
				background-color: @state-error;
			}

			input, button {
				color: @state-error-text;
				background-color: transparent;
			}
		}

		&.alert-message-error {
			.alert-message-error-mixins();

			.g-measure-divider {
				background-color: @state-message-error;
			}

			input, button {
				color: @state-message-error-text;
				background-color: transparent;
			}
		}

		&.alert-warning {
			.alert-warning-mixins();

			.g-measure-divider {
				background-color: @state-warning;
			}

			input, button {
				color: @state-warning-text;
				background-color: transparent;
			}
		}

		&.alert-info {
			.alert-info-mixins();

			.g-measure-divider {
				background-color: @state-info;
			}

			input, button {
				color: @state-info-text;
				background-color: transparent;
			}
		}
	}

	&.g-readonly-measure .input-group-btn {
		pointer-events: initial;

		.icon-caret-down {
			.readonly-icon;
		}
	}
}

label.g-measure-label:not(.disabled) {
	padding-right: 18px;
}

.g-measure-unit-length-1 {
	@unitTextboxWidth: 30px;

	input.g-measure-unit {
		width: @unitTextboxWidth;
	}
}

.g-measure-unit-length-2 {
	@unitTextboxWidth: 44px;

	input.g-measure-unit {
		width: @unitTextboxWidth;
	}
}

.g-measure-unit-length-3 {
	@unitTextboxWidth: 58px;

	input.g-measure-unit {
		width: @unitTextboxWidth;
	}
}

.gwMeasure-popover {
	z-index: 1050;
	font-size: @g-menu-font-size;

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		list-style-type: none;
	}

	.popover-title {
		display: none;
	}
}
