.g-duration {
	display: flex;
	align-items: baseline;

	.g-duration-label {
		display: inline-block;
		font-size: 10px;

		&:not(:last-child) {
			margin-right: 4px;
		}
	}

	.g-duration-value {
		display: inline-block;
	}
}

.g-title {
	font-size: 15px;
	margin-top: 0;
	margin-bottom: 15px;
	color: @Header_BackgroundColorStart;
	font-weight: 700;
}

.g-pave-button {
	.transition(0.12s, ease-in-out);
	.user-select(none);
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	color: @Header_BackgroundColorStart;
	background-color: @white;
	border-radius: @Button_BorderRoundedCorners;
	padding: 3px 7px;
	min-width: 24px;
	font-weight: 700;
	z-index: 1;
	font-size: 12px;

	& > i {
		font-size: 14px;
	}

	&:hover,
	&:active,
	&.popover-shown,
	&.in-meeting-mode {
		cursor: pointer;
		background: @Header_BackgroundColorStart;
		color: white;
	}

	&:active,
	&.popover-shown,
	&.in-meeting-mode {
		.element-elevation(-2);
	}
}

.g-pave-icon-button {
	color: #fff;
	cursor: pointer;
	display: flex;
	.user-select(none);
	.transition(0.1s, ease-in-out);

	& > i {
		font-size: 14px;
	}

	&:hover {
		opacity: 0.5;
	}

	&:active {
		opacity: 0.2;
	}
}

.g-pave-action-state-icon {
	margin-right: 10px;
	opacity: 0.85;
}

.g-pave-unsupported {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	font-size: 24px;

	i {
		font-size: 24px;
		margin-right: 10px;
	}
}

.g-pave-health-risk-excellent {
	background-color: @risk-state-excelent-color;
	border-color: @risk-state-excelent-color;
}

.g-pave-health-risk-good {
	background-color: @risk-state-good-color;
	border-color: @risk-state-good-color;
}

.g-pave-health-risk-caution {
	background-color: @risk-state-caution-color;
	border-color: @risk-state-caution-color;
}

.g-pave-health-risk-bad {
	background-color: lighten(@risk-state-bad-color, 2%);
	border-color: lighten(@risk-state-bad-color, 2%);
}

.g-pave-health-risk-alert {
	background-color: @risk-state-alert-color;
	border-color: @risk-state-alert-color;
}

.g-pave-health-risk-none {
	background-color: @risk-state-none-color;
}

.g-health-bar {
	.user-select(none);
	display: flex;
	overflow: hidden;
	flex: 0 0 20px;
	border-radius: 5px;
	position: relative;
	z-index: 1;
	height: @health-bar-size;
	background-color: #fff;
	border: 1px solid @grey;
	.transition(0.1s, ease-in-out);

	.g-health-bar-capacity-fill {
		background-color: @grey;
		-webkit-transition: width 0.5s ease-out, height 0.5s ease-out,
			background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
		-moz-transition: width 0.5s ease-out, height 0.5s ease-out,
			background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
		-o-transition: width 0.5s ease-out, height 0.5s ease-out,
			background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
		transition: width 0.5s ease-out, height 0.5s ease-out,
			background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
	}

	.g-health-bar-text {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		padding: 5px;
		min-width: 0;

		> span {
			.text-ellipsis();
		}
	}

	&.g-channel-health-bar {
		cursor: pointer;

		&:focus,
		&:active,
		&.popover-shown {
			.element-elevation(-2);

			.g-health-bar-capacity-fill {
				.element-elevation(-2);
			}
		}
	}

	&.g-pave-health-risk-excellent {
		.setup-health-bar-risk(@risk-state-excelent-color, 8%);
	}

	&.g-pave-health-risk-good {
		.setup-health-bar-risk(@risk-state-good-color, 7%);
	}

	&.g-pave-health-risk-caution {
		.setup-health-bar-risk(@risk-state-caution-color, 18%);
	}

	&.g-pave-health-risk-bad {
		.setup-health-bar-risk(@risk-state-bad-color, 15%);
	}

	&.g-pave-health-risk-alert {
		.setup-health-bar-risk(@risk-state-alert-color, 20%);
	}

	&.g-pave-health-risk-none {
		.setup-health-bar-risk(@risk-state-none-color, 8%);
	}
}
