.gwOptionButtonGrid {
	width: 100%;
	height: 100%;
}

.gwOptionButtonGrid__wrap {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: stretch;
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.gwOptionButtonGrid .gwGroupBox-container {
	height: 100%;
}

.gwOptionButtonGrid__cols--0 .gwOptionButton:first-child {
	border-bottom-left-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--0 .gwOptionButton:last-child {
	border-top-right-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--0 .gwOptionButton:not(:last-child) {
	border-right: none;
}

.gwOptionButtonGrid__cols--1,
.gwOptionButtonGrid__cols--2,
.gwOptionButtonGrid__cols--3,
.gwOptionButtonGrid__cols--4 {
	flex-wrap: wrap;
}

.gwOptionButtonGrid__cols--1 .gwOptionButton:first-child {
	border-top-right-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--1 .gwOptionButton:last-child {
	border-bottom-left-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--1 .gwOptionButton:not(:last-child) {
	border-bottom: none;
}

.gwOptionButtonGrid__cols--2 .gwOptionButton {
	width: 50%;
}

.gwOptionButtonGrid__cols--2 .gwOptionButton:nth-child(odd) {
	border-right: none;
}

.gwOptionButtonGrid__cols--2 .gwOptionButton:not(:last-child):not(:nth-last-child(2)) {
	border-bottom: none;
}

.gwOptionButtonGrid__cols--2 .gwOptionButton:nth-child(2) {
	border-top-right-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--2 .gwOptionButton:nth-last-child(2) {
	border-bottom-left-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--3 .gwOptionButton {
	width: 33%;
}

.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-child(3n+1),
.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-child(3n+2) {
	border-right: none;
}

.gwOptionButtonGrid__cols--3 .gwOptionButton:not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)) {
	border-bottom: none;
}

.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-child(3) {
	border-top-right-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-last-child(3) {
	border-bottom-left-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--4 .gwOptionButton {
	width: 25%;
}

.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4n+1),
.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4n+2),
.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4n+3) {
	border-right: none;
}

.gwOptionButtonGrid__cols--4 .gwOptionButton:not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)):not(:nth-last-child(4)) {
	border-bottom: none;
}

.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4) {
	border-top-right-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-last-child(4) {
	border-bottom-left-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButton {
	width: 100%;
	overflow: hidden;
	border: 1px solid @InputControl_BorderBrushStart;
}

.gwOptionButton:first-child {
	border-top-left-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButton:last-child {
	border-bottom-right-radius: @InputControl_BorderRoundedCorners;
}

.gwOptionButton__icon-position--left {
	flex-direction: row;
}

.gwOptionButton__icon-position--left .gwOptionButton__icon {
	margin-right: 5px;
}

.gwOptionButton__icon-position--right {
	flex-direction: row-reverse;
}

.gwOptionButton__icon-position--right .gwOptionButton__icon {
	margin-left: 5px;
}

.gwOptionButton__icon-position--top {
	flex-direction: column;
}

.gwOptionButton__icon-position--top .gwOptionButton__icon {
	margin-bottom: 5px;
}

.gwOptionButton__icon-position--bottom {
	flex-direction: column-reverse;
}

.gwOptionButton__icon-position--bottom .gwOptionButton__icon {
	margin-top: 5px;
}

.gwOptionButton__icon-position--top .gwOptionButton__text,
.gwOptionButton__icon-position--bottom .gwOptionButton__text {
	width: 100%;
}

.gwOptionButton__icon-size--m {
	font-size: medium;
}

.gwOptionButton__icon-size--l {
	font-size: large;
}

.gwOptionButton__icon-size--xl {
	font-size: x-large;
}

.gwOptionButton__icon-size--xxl {
	font-size: xx-large;
}

.gwOptionButton__label {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 5px;
	margin-bottom: 0;
	text-align: center;
	text-shadow: none;
	cursor: pointer;
	font-size: 14px;
	background-color: @InputControl_BackgroundColorStart;
	border: 1px dashed transparent;
}

.gwOptionButton__text {
	.text-overflow();
}

.gwOptionButton__radio {
	position: absolute;
	cursor: pointer;
	.opacity(0);
}

.gwOptionButton__radio:focus + .gwOptionButton__label {
	border-color: @Theme_GeneralText;
}


.gwOptionButton__placeholder,
.gwOptionButton__radio[disabled] {
	cursor: default;
}

.gwOptionButton__radio:checked + .gwOptionButton__label {
	color: #fff;
	background: #2292c6;
	font-weight: bold;
}

.gwOptionButton__radio:checked + .gwOptionButton__label:not(.disabled) {
	&:hover,
	&:focus {
		outline: 0;
		.box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)");
	}
}
