.gwHorizontalWheelScroll {
	padding-bottom: 8px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;

	&:hover {
		padding-bottom: 0;
	}

	&::-webkit-scrollbar {
		height: 8px;
		width: 8px;
		background-color: rgba(0,0,0,0);
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(87,90,93,1);
	}
}
