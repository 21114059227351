.gwTransitionTextBox,
.gwFinder {
	height: 100%;

	.search-container {
		height: @g-control-height;
		max-width: 500px;
		margin: 0 auto;

		label {
			max-width: 500px;
			padding-right: 30px;
		}

		.input-group-addon {
			color: @Theme_Icons;
		}
	}

	.template-container {
		height: calc(100% ~'-' @g-control-height);

		.g-absolute {
			margin: 0 auto;
			float: unset;
			width: 0%;
			.g-anchored {
				position: absolute;
			}
		}
	}
}
