.g-async-indicator {
	position: absolute;
	top: 0;
	z-index: 1030;
	width: 100%;
	height: 100vh;
	cursor: wait;

	.g-async-splash {
		display: none;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
		justify-content: center;
		align-items: center;
	}

	.g-async-splash-content {
		padding: 50px;
		font-size: 20px;
		background-color: white;
		border: 1px solid rgba(0,0,0,.2);
		border-radius: 6px;
		.box-shadow(0 5px 15px rgba(0,0,0,.5));
	}
}
