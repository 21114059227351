.g-designed-button{
	padding: 3px;
}

.gwButton {
	height: 100%;

	div& .btn {
		height: 100%;
		width: 100%;
		margin: 0;
	}

	.btn.g-btn-async {
		.g-btn-async-loading-padding();
	}
}
