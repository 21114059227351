.g-document-shell {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&.gwGroupBox {
		height: auto;
	}

	> .gwGroupBox > .panel-body {
		position: relative;
		height: 100%;
		margin-top: @g-modal-panel-body-margin-top;

		#g-page-options {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			.box-sizing(border-box);

			.gwGroupBox {
				padding-left: 10px;
			}

			.tab-pane {
				width: 100%;
				height: 100%;

				&:not(.active) {
					display: none;
				}

				embed, object {
					width: 100%;
					height: 100%;
				}

				object > span.text-not-supported-display {
					font-weight: bold;
				}
			}

			.tab-pane#preview {
				text-align: center;
			}
		}
	}

	h1 {
		margin-left: 30px;
		font-size: 22px;
	}

	.gt-cover-note {
		position: relative;
		float: left;
		width: 50%;
		height: 100%;
		.box-sizing(border-box);

		.gwCheckBox {
			position: absolute;
			width: 230px;
			height: @g-control-height;
		}
	}

	.gt-cover-note-text {
		height: 100%;
		padding-top: 45px;
		.box-sizing(border-box);

		textarea {
			width: 100%;
			height: 100%;
			.box-sizing(border-box);
		}
	}

	.gt-cover-note,
	.gt-documents-list {
		.gwGroupBox-container {
			height: 100%;
		}
	}

	.gt-documents-list {
		position: relative;
		float: left;
		width: 50%;
		height: 100%;
		.box-sizing(border-box);
	}

	#recipients {
		display: flex;
		flex-flow: column;
	}

	#recipients, #documents {
		padding: 0 @modal-padding @modal-padding @modal-padding;
	}

	.g-printing {
		display: flex;
		flex-wrap: wrap;

		.select2-selection {
			min-height: @g-control-height;
		}
	}

	.g-printing-printer {
		width: 100%;
	}

	#preview .panel-body {
		padding: 0;
	}

	.tab-pane,
	#recipients .gwDataGrid,
	.doc-preview {
		height: 100%;
	}

	&.g-document-popup .doc-preview {
		position: relative;
	}

	&.g-document-window {
		position: absolute;
		top: 0;
		left: 0;

		.panel-body {
			display: flex;
			flex-direction: column;

			#g-page-options {
				display: flex;
				position: static;
			}
		}

		.g-document-footer {
			position: static;
		}
	}
}

.relative-data-grid {
	position: relative;
	flex-grow: 1;
}
