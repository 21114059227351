.g-action-state,
li > a.g-action-state {
	cursor: pointer;
	.user-select(none);
	border: @focus-border-width dashed transparent;

	&:focus {
		outline: none;
		opacity: 1;
		text-decoration: none;
	}

	&:hover {
		opacity: 1;
		border-color: transparent;
	}

	&:active {
		opacity: 1;
		border-color: transparent;
	}

	i,
	&:focus i {
		&.icon-globe {
			color: @Theme_DefaultButton;
		}

		&.icon-unpin,
		&.icon-pin {
			color: @Theme_Icons;
		}
	}

	&-list-item {
		.action-state(@bg-color: transparent, @text-color: @Theme_GeneralText, @text-color-lighten-10: @Theme_GeneralText_Lighten10, @transition: 0, @border-radius: @Button_BorderRoundedCorners, @disabled-bg-color: transparent);
	}

	&-header {
		.action-state( @bg-color: transparent, @text-color: @Header_TextColor, @text-color-lighten-10: lighten(@Header_TextColor, 10%), @bg-hover-color: @Header_BackgroundColorStart_Darken10, @bg-active-color: @Header_BackgroundColorStart_Lighten10, @bg-open-color: @Header_TextColor, @text-open-color: @Header_BackgroundColorStart, @disabled-use-opacity: true, @disabled-bg-color: transparent);
	}
}
