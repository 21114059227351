.gwTrackingProgress .gwProgressLinear {
	margin: 10px 0;
}

.gwTrackingProgress .gwFlag {
	width: 32px;
	height: 25px;
}

.gwTrackingProgress .flag-icon {
	width: 100%;
	height: 100%;
	vertical-align: top;
}

.gwTrackingProgress__row {
	display: flex;
	justify-content: space-between;
	margin: 3px 0;
}

.gwTrackingProgress__arrow {
	width: 15px;
	height: 15px;
	align-self: center;
}

.gwTrackingProgress-compact__arrow {
	width: 15px;
	height: 15px;
	align-self: center;
	margin-left: 5px;
	margin-right: 5px;
}

.gwTrackingProgress__port {
	width: 180px;
	height: 50px;
}

.gwTrackingProgress-compact__port {
}

.gwTrackingProgress__mode {
	width: 50px;
	text-align: center;
}

.gwTrackingProgress__mode {
	font-size: 50px;
}

.gwTrackingProgress__mode.icon-truck {
	.icon-flip-horizontal;
}

.gwTrackingProgress-compact__progress {
}

.gwTrackingProgress__scheduling {
	display: flex;
	height: 50px;
	align-items: center;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
}

.gwTrackingProgress__location {
	display: flex;
	align-items: center;


	.gwTrackingMapButton {
		font-size: 24px;
	}
}

.gwTrackingProgress__caption {
	width: 100px;
	font-family: 'Noto Sans Condensed';
}

.gwTrackingProgress__date {
	width: 100px;
	color: @Theme_DefaultButton;
}

.gwTrackingProgress-compact__date {
	align-items: center;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	color: @Theme_DefaultButton;
}
