.gwProgressLinear {
	position: relative;
	height: 5px;
	background: #ededed;
	border-radius: 10px;
}

.gwProgressLinear__point {
	position: absolute;
	top: 0;
	left: 50%;
	width: 10px;
	height: 10px;
	margin-top: -2.5px;
	margin-left: -5px;
	background: #fff;
	border: 2px solid #ccc;
	border-radius: 50%;
}

.gwProgressLinear__bar {
	display: block;
	height: 100%;
}

.gwProgressLinear__point--status-default {
	border-color: #575a5d;
}

.gwProgressLinear__point--status-success {
	border-color: @state-success;
}

.gwProgressLinear__point--status-warning {
	border-color: @state-warning;
}

.gwProgressLinear__bar--status-default {
	background-color: #575a5d;
}

.gwProgressLinear__bar--status-success {
	background-color: @state-success;
}

.gwProgressLinear__bar--status-warning {
	background-color: @state-warning;
}
