.gwTrackingControl {
	display: flex;
	width: 100%;
	height: 100%;

	.gwTrackingControl-text {
		flex-grow: 1;
	}

	.gwTrackingControl-button {
		margin: auto 5px auto 5px;
		height: @g-flat-button-height;
		width: 80px;
	}
}

.gwTrackingControl-multiLine {
	width: 100%;
	height: 100%;

	.gwTrackingControl-text {
		.g-stretch-to-parent-container-absolute();
		bottom: 40px;
	}

	.gwTrackingControl-button {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 80px;
	}
}
