/**,
*:before,
*:after {
	box-sizing: border-box;
}*/

/*body {
	font-family: Roboto, sans-serif;
	color: #f9fafc;
	font-size: 20px;
}*/
/*Do not change styles as it is an ongoing change. This will be handled int he cleanup styles in WI00202923*/
.gwTruckDashboard {
	background-color: @Theme_Background;
	color: @Content_TextColor;
	height: inherit;

	h1 {
		font-weight: normal;
		font-size: 35px;
		color: @Content_TextColor;
	}

	h2 {
		font-weight: normal;
		font-size: 25px;
		margin: 0px;
		color: @Content_TextColor;
	}

	input[type=text] {
		background-color: rgba(255, 255, 255, 0.22);
		color: @Content_TextColor;
		padding: 10px;
		border: 1px solid @Content_TextColor;
		font-size: 25px;
		padding: 18px 28px;
		border-radius: 0px;
		background-image: none;
	}

	input[type=text]::placeholder {
		color: rgba(249, 250, 252, 0.8);
	}

	button,
	input[type=button],
	input[type=submit] {
		&:not(.g-grid-action-button) {
			background-color: rgba(255, 255, 255, 0.25);
			border: 1px solid @Content_TextColor;
			text-transform: capitalize;
			letter-spacing: 0.2em;
			padding: 12px 30px;
			color: @Content_TextColor;
			font-weight: bold;
			font-size: 20px;
			border-radius: 6px;
			min-width: 206px;
		}
	}

	button + button,
	input[type=button] + input[type=button],
	input[type=submit] + input[type=submit],
	.btn-group + button,
	button + .btn-group {
		margin-left: 8px;
	}

	button.action,
	input[type=button].action,
	input[type=submit].action {
		background-color: #00A8E1;
		border: 1px solid #00A8E1;
	}

	input[type=checkbox] {
		overflow: hidden;
		clip: rect(0 0 0 0);
		height: 1px;
		width: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
	}

	input[type=checkbox] + label {
		padding: 3px 0 0 40px;
		position: relative;
		cursor: pointer;
	}

	input[type=checkbox] + label::before {
		content: '';
		width: 26px;
		height: 26px;
		border: 1px solid @Content_TextColor;
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 1px;
	}

	input[type=checkbox]:checked + label::after {
		content: '';
		position: absolute;
		width: 16.5px;
		height: 12.2px;
		background-image: url('@{ContentBaseUri}/Shared/Images/check.YT_sDhWWSmx9IxaCweL_eDFU10w.svg');
		background-size: contain;
		left: 4px;
		top: 7px;
	}
	//this should be removed once we have reusuable components for truck dashboard
	.g-kg-status-search {
		font-size: 20px;
		color: @Content_TextColor;
	}
	//this should be removed once we have reusuable components for truck dashboard
	.gwPinnedItems {
		.gwPinnedItem-sticky-button {
			background-color: transparent;
		}
	}
	//this should be removed once we have reusuable components for truck dashboard
	.g-kg-item-name,
	.g-kg-actions-container .g-kg-action a,
	.g-kg-title-container .g-kg-item-count {
		color: @Content_TextColor;
	}

	&__error {
		display: flex;
		flex-flow: row nowrap;
		align-items: baseline;
		margin: 4px 0 0 7px;
		justify-content: flex-end;
		flex: 1 0 auto;
		font-size: 20px;

		.gwTruckDashboard__error-icon {
			margin-right: 5px;
			color: @state-error-hover;
		}

		.gwTruckDashboard__error-message {
			color: @state-error-hover;
		}
	}
}

#top-bar {
	height: 79px;
	padding: 15px 25px;
}

#top-bar .left {
	width: 30%;
	float: left;
}

#top-bar .right {
	width: 70%;
	float: right;
	text-align: right;
}

#top-bar .right > * {
	vertical-align: top;
}

.gwMainContent {
	background-color: rgba(255, 255, 255, 0.1);
	margin: 0 24px;
	height: ~"calc(100% - 179px)";
	border-radius: 15px 15px 0 0;
	padding: 15px 30px;
}

.gwMainContent > *:first-child {
	margin-top: 0;
}

.gwMainContent .page {
	display: none;
	max-height: 100%;
}

.gwMainContent {
	#page-runsheet {
		height: 100%;

		input[type=text] {
			color: #003479;
			font-size: inherit;
			border-color: #d3d6d9;
			padding: 10px;
			background-color: @Content_TextColor;
		}

		button.btn-primary {
			font-size: 14px;
			cursor: pointer;
			user-select: none;
			background-color: #00a8e1;
			border-color: transparent;
			color: white;
			text-shadow: none;
			letter-spacing: normal;
			text-transform: capitalize;
			min-width: initial;
		}

		.g-btn-async {
			font-size: 14px;
			text-shadow: none;
			letter-spacing: normal;
			text-transform: capitalize;
			min-width: initial;
		}

		.search-results {
			height: 100%;
			position: relative;

			.gwSearchList {
				width: 100%
			}
		}
	}

	.truck-status-error {
		text-align: center;
	}

	.tap-instruction {
		display: inline-block;
		padding-top: 20px;
		color: @Content_TextColor;

		h4 {
			display: inline-block;
			font-weight: normal;
			margin-left: 10px;
		}
	}

	#page-edocs {
		height: 100%;
	}

	#page-conversation {
		height: 100%;

		.gwConversationList__conversation-list {
			background-color: transparent;
		}

		.gwConversationList__conversation-list--border {
			border: 0;
		}

		.gwConversationList__conversation-more-button {
			.g-button-background(rgba(255, 255, 255, 0.50), @Button_BackgroundHoverColorStart, @Button_BackgroundPressedColorStart, rgba(27, 36, 49, 0.9), lighten(rgba(27, 36, 49, 0.9), 10%), @Button_TextHoverColor, none, 50%);
			right: 90px;

			&.unreadMessages {
				background-color: @Button_BackgroundHoverColorStart;
			}
		}
	}

	.message-notification-dot {
		height: 20px;
		width: 20px;
		background-color: @state-error;
		border-radius: 50%;
		display: block;
		position: relative;
		top: -1em;
		left: calc(~"50% + 15px");
	}
}

.gwMainContent .page.current {
	display: flex;

	.g-container {
		display: flex;
		max-height: 100%;
		width: 100%;
	}
}

#bottom-bar {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	height: 100px;
	background-color: rgba(0, 0, 0, 0.86);
	display: flex;
	justify-content: space-between;
}

#bottom-bar a {
	width: 166px;
	height: 100%;
	font-size: 50px;
	padding-top: 20px;
	text-align: center;
	color: @Content_TextColor;
	text-decoration: none;
}

#bottom-bar a.active {
	color: @Theme_DefaultButton;
}

#container.page-login #bottom-bar {
	display: none;
}

.scroll-list {
	overflow: scroll;
	max-width: 50%;
	max-height: 462px;
}

.scroll-list ul {
	font-size: 25px;
	list-style-type: none;
	padding: 0;
	margin: 0;
	position: relative;
	background-color: rgba(249, 250, 252, 0.25);
}

.scroll-list li {
	padding: 19px 25px;
	margin: 0;
}

.scroll-list li.selected {
	background-color: rgba(0, 0, 0, 0.5);
}

.scroll-list .jspVerticalBar {
	width: 23px;
	background-color: rgba(249, 250, 252, 0.25);
	border: 1px solid rgba(249, 250, 252, 0.5);
}

.scroll-list .jspTrack {
	background-color: transparent;
}

.scroll-list .jspDrag {
	background-color: #456A8F;
}

#page-map,
#page-map iframe {
	height: 100%;
}
