.gwSelectMenu-list {
	.select2-results > .select2-results__options {
		max-height: 400px;
	}

	.select2-results__option {
		margin: 0 0 5px 0;

		&[aria-selected=true] {
			background: none;
		}

		&--highlighted {
			&[aria-selected] {
				background: none;
			}

			.gwSelectMenu-item {
				.dropdown-menu-section-hover;
			}
		}
	}

	.select2-results__message {
		margin-bottom: 0;
	}
}

.gwSelectMenu-item {
	.dropdown-menu-list-line;

	i {
		margin: @g-menu-chevron-margin;
		color: @Theme_FieldTitle;
	}

	a.g-desc {
		color: @Content_TextColor;
	}

	&.dropdown-header {
		.dropdown-menu-header;

		a {
			color: @Theme_DefaultButton;
		}
	}

	&.selected {
		.dropdown-menu-section-open;

		> i.icon-chevron-down {
			transform: rotate(180deg) translateY(-3px);
		}
	} 
}
