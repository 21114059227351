.g-workflow-tasks {
	.gwGroupBox-container {
		height: calc(50% ~"-" @g-control-height);

		&:first-child {
			height: @g-control-height * 2;
		}
	}

	&.g-workflow-headers-unsupported {
		.g-workflow-sidebar {
			display: none;
		}

		.g-workflow-main {
			margin-left: 0;
		}
	}

	li.active .g-menu-item-icon {
		color: #fff;
	}

	.g-workflow-sidebar {
		.gwGroupBox-container {
			height: 100%;

			.panel-body {
				overflow-y: auto;
			}
		}

		.g-menu-item-target {
			display: flex;
			align-items: center;
			height: 40px;

			&:hover .g-menu-item-icon {
				opacity: 1;
			}
		}
	}
}

.g-workflow-details {
	display: flex;
	height: 100%;

	> .g-container {
		flex: 1;
	}

	.g-checkbox-container,
	.gwCheckBox {
		min-width: 120px;
	}

	.gwCheckBox__box {
		min-width: 66px;
	}
}
