@ticket-info-row-height: 22px;
@ticket-width-padding: 6px;
@ticket-height-padding: 2px;

.g-ticket {
	display: flex;

	.g-ticket-tags {
		display: flex;
		--g-ticket-tag-size: 0px;

		.g-ticket-tag {
			background-color: @white;
			border-width: var(--g-ticket-tag-size);


			&.size-small {
				--g-ticket-tag-size: 2px;
			}

			&.size-large {
				--g-ticket-tag-size: 5px;
			}
		}

		&:empty {
			display: none;
		}
	}

	.g-ticket-body {
		flex: 1 0 0%;
		display: flex;
		height: 100%;
		.element-elevation(2);
		border-radius: @rounded-corners;
		background-clip: padding-box;
		position: relative;
		overflow: hidden;
		.user-select(none);
		z-index: 1; // fixes https://stackoverflow.com/questions/31693219/issue-while-using-transitions-opacity-change-overflow-hidden
		.g-ticket-main-tag {
			z-index: 3;
		}

		.g-ticket-loading {
			.g-stretch-to-parent-container-absolute();
			background-color: rgba(211, 214, 217, 0.7);
			z-index: 10;
			cursor: not-allowed;
		}

		.g-ticket-body-content {
			flex: 1 0 0%;
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			position: relative;
			background-color: @color-light-black;

			& > * {
				position: relative; // fixes z-index order in Web-kit (Safari)
				z-index: 2;
			}

			&:before {
				.transition(0.12s, ease-in-out);
				content: "";
				z-index: 0;
				background-color: @white;
				.g-stretch-to-parent-container-absolute();
			}

			.g-ticket-background {
				.g-stretch-to-parent-container-absolute();
				z-index: 1;
				opacity: 0.18;
			}

			.g-ticket-job-code {
				font-weight: bold;
				line-height: 12px;
				display: flex;
				align-items: center;
				min-height: 18px;
			}

			.g-ticket-job-summary {
				.text-ellipsis();
				padding: @ticket-height-padding @ticket-width-padding;
				max-height: @ticket-info-row-height - 2px;
			}

			.g-ticket-task-type {
				margin-right: 5px;
				font-weight: bold;
				line-height: 12px;
				display: flex;
				align-items: center;
				height: @ticket-info-row-height;
			}

			.g-ticket-task-description {
				.text-ellipsis();
				padding: @ticket-height-padding @ticket-width-padding;
				max-height: @ticket-info-row-height;
			}

			.g-ticket-upcoming-tasks-stack {
				@color-upcoming-tasks: #7c8084;
				transition: all 0.12s ease-out, order 0s;
				height: @ticket-info-row-height;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:not(:empty) {
					background-color: @color-upcoming-tasks;

					&:hover,
					&:active,
					&.popover-shown {
						background-color: darken(@color-upcoming-tasks, 8%);
					}

					&:active,
					&.popover-shown {
						.element-elevation(-2);
					}
				}

				span {
					margin: 0 5px;
					line-height: 12px;
				}

				i {
					display: flex;
					margin-right: 5px;
					color: #FFFFFF;
					font-size: 12px;
				}
			}

			.g-ticket-duration {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				min-width: 44px;
			}

			.g-ticket-status-indicators {
				overflow: hidden;
				display: flex;

				.g-status-indicators-content {
					display: flex;
				}
			}

			.g-ticket-task-note:not(:empty) {
				.transition(0.12s, ease-in-out);
				background-color: rgba(140, 140, 140, 0.25);
				font-weight: 500;
				padding: @ticket-height-padding @ticket-width-padding;
				max-height: @ticket-info-row-height;
				.text-ellipsis();
				width: 100%;
			}

			.g-ticket-text-container {
				overflow: hidden;

				& > div {
					width: inherit;
					margin: 0px @ticket-width-padding;
				}
			}

			.g-duration {
				overflow: hidden;
				margin: 0px @ticket-width-padding;
				flex-shrink: 0;
			}

			&:focus,
			&:hover {
				cursor: pointer;

				&:before {
					opacity: 0.9;
				}
			}

			&:active {
				cursor: pointer;

				&:before {
					opacity: 0.85;
				}
			}
		}
	}
}


.g-ticket-large {
	.g-ticket-tags {
		flex: 0 0 20px;
		flex-direction: column;
		align-items: flex-start;
		margin-left: -@rounded-corners;
		padding-top: @rounded-corners;
		padding-bottom: @rounded-corners;

		.g-ticket-tag {
			flex: 0 1 15px;
			width: 100%;
			border-radius: @rounded-corners-small;
			.element-elevation(2);

			& + .g-ticket-tag {
				margin-top: 5px;
			}
		}
	}

	.g-ticket-body {
		.g-ticket-main-tag {
			flex: 0 0 12px;
		}

		.g-ticket-body-content {
			.g-ticket-job-code {
				order: 1;
				margin-left: @ticket-width-padding;
				margin-right: auto;
				min-height: @ticket-info-row-height;
			}

			.g-ticket-duration {
				order: 2;
			}

			.g-ticket-status-indicators {
				order: 3;
				border-bottom-left-radius: @rounded-corners;
			}

			.g-ticket-job-summary {
				order: 4;
				flex: 1 0 100%;
				padding-top: 1px;
				padding-bottom: 1px;
			}

			.g-ticket-task-type {
				order: 5;
				margin-left: @ticket-width-padding;
			}

			.g-ticket-task-description {
				order: 6;
				margin-right: auto;
				flex: 1 0 66%;
			}

			.g-ticket-upcoming-tasks-stack-container {
				order: 7;

				.g-ticket-upcoming-tasks-stack {
					border-top-left-radius: @rounded-corners;
				}
			}

			.g-ticket-task-note {
				order: 8;
			}
		}
	}
}

.g-ticket-medium {
	.g-ticket-tags {
		flex: 0 0 18px;
		flex-direction: column;
		align-items: flex-start;
		margin-left: -@rounded-corners;
		padding-top: @rounded-corners + 1;
		padding-bottom: @rounded-corners;

		.g-ticket-tag {
			flex: 0 1 16px;
			width: 100%;
			border-radius: @rounded-corners-small;
			.element-elevation(2);

			& + .g-ticket-tag {
				margin-top: 4px;
			}
		}
	}

	.g-ticket-body {
		.g-ticket-main-tag {
			flex: 0 0 12px;
		}

		.g-ticket-body-content {
			.g-ticket-job-code,
			.g-ticket-task-description {
				display: none;
			}

			.g-ticket-task-type {
				order: 1;
				padding-left: @ticket-width-padding;
				margin-right: auto;
			}

			.g-ticket-duration {
				order: 2;
			}

			.g-ticket-status-indicators {
				order: 3;
				border-bottom-left-radius: @rounded-corners;
			}

			.g-ticket-job-summary {
				order: 4;
				flex: 1 0 74%;
				min-height: @ticket-info-row-height;
			}

			.g-ticket-upcoming-tasks-stack-container {
				order: 5;

				.g-ticket-upcoming-tasks-stack {
					border-top-left-radius: @rounded-corners;
				}
			}

			.g-ticket-task-note {
				order: 6;
			}
		}
	}
}

.g-ticket-small {
	@small-ticket-tag-height: 6px;
	width: 100%;
	position: relative;

	.g-ticket-tags {
		position: absolute;
		height: @small-ticket-tag-height;
		bottom: 0;
		right: 0;
		width: 48%;

		.g-ticket-tag {
			flex: 1 0 0;
			z-index: 4;
			border-top-width: 0 !important;

			&.size-large {
				--g-ticket-tag-size: 4px;
			}

			&:last-child {
				border-bottom-right-radius: @rounded-corners;
			}
		}
	}

	.g-ticket-body {
		flex: 1 0 auto;
		flex-wrap: wrap;

		.g-ticket-main-tag {
			flex: 0 0 100%;
			height: @small-ticket-tag-height;
			order: 2;
			display: flex !important;
		}

		.g-ticket-body-content {
			flex: 1 0 100%;
			flex-wrap: nowrap;
			order: 1;

			.g-ticket-job-code,
			.g-ticket-job-summary,
			.g-ticket-task-note,
			.g-ticket-duration,
			.g-ticket-task-description,
			.g-status-text {
				display: none;
			}

			.g-ticket-task-type {
				order: 1;
				padding-left: @ticket-width-padding;
				width: 38px;
				margin-right: 0;
			}

			.g-ticket-upcoming-tasks-stack-container {
				order: 2;
				margin-left: auto;
				width: 40px;
			}

			.g-ticket-status-indicators {
				order: 3;
				max-width: @status-indicator-size * 2;
			}
		}
	}
}

.g-ticket-tiny {
	.g-ticket-tags {
		display: none;
	}

	.g-ticket-body {
		.g-ticket-main-tag {
			flex: 0 0 12px;
		}

		.g-ticket-body-content {
			column-count: 2;
			column-gap: 0;
			display: initial;
			position: initial;

			.g-ticket-job-code,
			.g-ticket-job-summary,
			.g-ticket-task-note,
			.g-ticket-duration,
			.g-ticket-task-description,
			.g-status-text {
				display: none;
			}

			.g-ticket-task-type {
				width: 40px;
				padding-left: @ticket-width-padding;
				margin-right: 0;
			}

			.g-ticket-upcoming-tasks-stack-container {
				height: @ticket-info-row-height;
				width: 40px;
			}

			.g-ticket-status-indicators {
				width: @status-indicator-size;
				height: 44px;
				margin-left: auto;

				.g-status-indicators-content {
					flex-direction: column;
				}

				.g-status-indicator {
					width: @status-indicator-size;
				}
			}
		}
	}
}

.g-ticket-upcoming-task {
	min-width: 400px;
	max-width: 500px;
	flex-direction: column-reverse;

	.g-ticket-tags {
		flex: 0 0 14px;
		align-self: flex-end;
		justify-content: flex-end;
		margin-bottom: -@rounded-corners;
		padding-right: @rounded-corners + 1;
		width: 30%;

		.g-ticket-tag {
			flex: 0 1 16px;
			width: 100%;
			border-radius: @rounded-corners-small;
			.element-elevation(2);

			& + .g-ticket-tag {
				margin-left: 4px;
			}
		}
	}

	.g-ticket-body {
		flex: 1 0 auto;

		.g-ticket-main-tag {
			flex: 0 0 12px;
		}

		.g-ticket-body-content {
			flex: 1 0 0%;
			flex-direction: column;

			.g-ticket-row {
				display: flex;
				flex: 1 0 0%;
				height: 34px;
				width: 100%;
				align-items: center;
				padding: @ticket-height-padding @ticket-width-padding;
			}

			.g-ticket-task-type {
				margin-left: @ticket-width-padding;
			}

			.g-ticket-task-description {
				margin-right: auto;
			}

			.g-ticket-status-indicators {
				border-radius: @status-indicator-size;
				flex-shrink: 0;
			}

			.g-ticket-task-note {
				display: block;
				padding: @ticket-height-padding @ticket-width-padding * 2 !important;
			}
		}
	}
}

@skeleton-padding: 8px;
@skeleton-line-height: 8px;
@skeleton-spacing: 18px;
@skeleton-max-width: 200px;

.g-ticket-skeleton {
	width: 100%;
	height: 60px;
	max-width: @skeleton-max-width;
	margin: 10px;
	border-radius: @rounded-corners;
	padding: @skeleton-padding;
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;

	&-elements {
		.g-stretch-to-parent-container-absolute();
		background-image:
			linear-gradient(@grey 100%, transparent 0), /* job # line */
			linear-gradient(@grey 100%, transparent 0), /* time line */
			linear-gradient(@grey 100%, transparent 0), /* job desc */
			linear-gradient(@grey 100%, transparent 0), /* task type */
			linear-gradient(@grey 100%, transparent 0), /* task desc */
			linear-gradient(@white 100%, transparent 0); /* ticket bg */
		background-size:
			40% @skeleton-line-height, /* job # line */
			15% @skeleton-line-height, /* time line */
			calc(100% - @skeleton-padding * 2) @skeleton-line-height, /* job desc line */
			18% @skeleton-line-height, /* task type */
			40% @skeleton-line-height, /* task desc */
			100% 100%; /* ticket bg */
		background-position:
			@skeleton-padding @skeleton-padding, /* job # line */
			calc(100% - @skeleton-padding) @skeleton-padding, /* time line */
			@skeleton-padding @skeleton-padding + @skeleton-spacing, /* job desc line */
			@skeleton-padding @skeleton-padding + @skeleton-spacing * 2, /* task type */
			@skeleton-padding + 50% @skeleton-padding + @skeleton-spacing * 2, /* task desc */
			0 0; /* ticket bg */
		background-repeat: no-repeat;
	}

	&-bar {
		position: absolute;
		left: -@skeleton-max-width;
		height: 100%;
		width: 50%;
		background-image: linear-gradient(90deg, rgba(white, 0) 0, rgba(white, .8) 50%, rgba(white, 0) 100%);
		background-size: 100px 100%;
		background-position: 0 0;
		animation: loading 1s infinite;
	}

	.g-status-popover-contents & {
		border: 1px solid #d3d6d9
	}
}

@keyframes loading {
	to {
		transform: translateX(@skeleton-max-width * 2);
	}
}
