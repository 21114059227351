input[type="checkbox"],
input[type="radio"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
	box-sizing: border-box;
	padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="search"] {
	-moz-appearance: none;
	appearance: none;
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
	cursor: default;
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

select[multiple],
select[size] {
	height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.form-control:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #999;
}

.form-control::-webkit-input-placeholder {
	color: #999;
}

.form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #eeeeee;
	opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: not-allowed;
}

textarea.form-control {
	height: auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"].form-control,
	input[type="time"].form-control,
	input[type="datetime-local"].form-control,
	input[type="month"].form-control {
		line-height: 34px;
	}
}

.form-group {
	margin-bottom: 15px;
}

@media (min-width: 768px) {
	.form-inline .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}

	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}

	.form-inline .input-group {
		display: inline-table;
		vertical-align: middle;
	}

	.form-inline .input-group .input-group-addon,
	.form-inline .input-group .input-group-btn,
	.form-inline .input-group .form-control {
		width: auto;
	}

	.form-inline .input-group > .form-control {
		width: 100%;
	}

	.form-inline .control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}
}

.form-horizontal .form-group {
	margin-right: -15px;
	margin-left: -15px;
}

@media (min-width: 768px) {
	.form-horizontal .control-label {
		padding-top: 7px;
		margin-bottom: 0;
		text-align: right;
	}
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}

.input-group[class*="col-"] {
	float: none;
	padding-right: 0;
	padding-left: 0;
}

.input-group .form-control {
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0;
}

.input-group .form-control:focus {
	z-index: 3;
}

.input-group-btn,
.input-group .form-control {
	display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.input-group-addon,
.input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
	margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:last-child > .btn:not(:last-child),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
	border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
	border-left: 0;
}

.input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap;
}

.input-group-btn > .btn {
	position: relative;
}

.input-group-btn > .btn + .btn {
	margin-left: -1px;
}

.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
	z-index: 2;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
	margin-right: -1px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
	z-index: 2;
	margin-left: -1px;
}

.form-horizontal .form-group {
	margin-right: 0;
	margin-left: 0;
}

.form-control {
	padding-right: 7px;
	padding-left: 7px;
	.box-shadow(none);
}

a.form-control:focus,
input.form-control:focus,
textarea.form-control:focus {
	.box-shadow(none);
}

.input-group {
	width: 100%;
	height: 100%;

	.form-control {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: 18px;
		padding-right: 30px;
		border-radius: 4px;

		&:first-child {
			padding-right: 26px;
			border-radius: 4px;
		}
	}
}

.input-group-addon {
	font-size: 14px;
	font-weight: 400;
	color: #555555;
	text-align: center;
	display: table;
	padding: 0;
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 100%;
	line-height: 100%;
	cursor: pointer;
	z-index: 5;
	background: none;
	border: none;

	i {
		vertical-align: middle;
		text-align: center;
		display: table-cell;
	}
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: default;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
	cursor: default;
}

.gwTextBox > input,
.gwNumeric > input,
.importTaskPage .g-control-wrap input,
.g-mapping-name-input {
	width: 100%;
	height: 100%;
	padding-top: 18px;
	margin-bottom: 0;
}

.input-group.no-bottom-radius {
	.form-control:first-child {
		border-bottom-left-radius: 0;
	}

	.input-group-addon:last-child {
		border-bottom-right-radius: 0;
	}
}

.gwTextBox-textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="datetime-local"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	padding-bottom: 3px;
	color: @InputControl_TextColor;

	&.alert-danger {
		.alert-danger-mixins();
	}

	&.alert-danger + label {
		color: @blacklabel;
	}

	&.alert-danger:focus {
		color: @InputControl_TextColor;
	}

	&.alert-danger:focus + label {
		color: @Theme_FieldTitle;
	}

	&.alert-message-error {
		.alert-message-error-mixins();
	}

	&.alert-warning {
		.alert-warning-mixins();
	}

	&.alert-info {
		.alert-info-mixins();
	}
}

input[type="text"],
input[type="password"],
input[type="datetime-local"],
.gwTextBox-textarea {
	&.gwValidation-highlight {
		&.alert-danger {
			.alert-danger-highlight();
		}

		&.alert-message-error {
			.alert-message-error-highlight();
		}

		&.alert-info {
			.alert-info-highlight();
		}

		&.alert-warning {
			.alert-warning-highlight();
		}
	}
}

input[type="text"]:-webkit-autofill + label,
input[type="password"]:-webkit-autofill + label {
	/* fixed autofill labels colour for Chrome and Safari, due to the fact
		that these browsers have custom autofill light-coloured backgrounds	*/
	color: @blacklabel;
}

.form-control,
.gwTextBox-textarea {
	border-color: @InputControl_BorderBrushStart;
	color: @InputControl_TextColor;
	background-color: @InputControl_BackgroundColorStart;
}

.form-control,
.select2-container,
.typeahead.dropdown-menu {
	.g-font-size-px(@InputControl_FontSize);
	font-weight: @InputControl_FontWeight;
}

input[type="text"],
input[type="datetime-local"] {
	border-radius: @InputControl_BorderRoundedCorners;

	&:focus {
		&:not([readonly]) &:not(.g-uneditable) {
			background: @InputControl_FocusedBackgroundColorEnd;
			border: 1px solid @InputControl_FocusedBorderBrushEnd;
		}
	}
}

.dateclockpicker > input.popover-active,
.gwTextBox-textarea.active:not(.g-uneditable),
.select2-container--focus:not(.select2-container--disabled) .select2-selection,
.select2-container--open .select2-selection,
input.form-control:focus:not([readonly]),
.form-control.active:not(.g-uneditable),
.g-readonly-override:focus,
.g-next-control-focus {
	background: @InputControl_FocusedBackgroundColorEnd !important;
	border: 1px solid @InputControl_FocusedBorderBrushEnd !important;
}

.typeahead.dropdown-menu {
	border-color: #333;
}

.gwSearchBox-drop-above {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-top: 2px;
}

.g-dropdown-opened-below {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.g-dropdown-opened-above {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.gwTriStateRadioButton-wrap:focus,
input.form-control[readonly]:focus:not(.g-readonly-override) {
	border-color: @InputControl_BorderBrushEnd !important;
}

.g-jobDocAddress-loading-wrap,
.g-readonly-jobDocAddress,
.form-control[disabled],
.form-control[readonly]:not(.g-readonly-override),
fieldset[disabled] .form-control,
.gwTextBox-textarea.g-uneditable,
.gwMeasure.g-readonly-measure {
	color: @InputControl_ReadOnlyTextColor;
	background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
	cursor: text;
}

.form-control[readonly].g-readonly-override {
	cursor: default;
}

.gwSearchBox input[type="text"],
.gwDatePicker.input-group input[type="text"].form-control {
	border-radius: @InputControl_BorderRoundedCorners;
}

.gwDatePicker.input-group input[type="datetime-local"].form-control {
	border-radius: @InputControl_BorderRoundedCorners;
}

.gwSearchBox,
.gwDatePicker {
	&.input-group .input-group-addon:last-child {
		border-radius: @InputControl_BorderRoundedCorners;
		.border-left-radius(0px);
	}
}

.input-group-addon:not(.disabled):hover {
	opacity: 0.5;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"] {
	-webkit-appearance: none;
	display: block;
	color: @Theme_Icons;
	vertical-align: top;
	line-height: 20px;
	width: 100%;

	&::-webkit-calendar-picker-indicator,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		display: none;
		color: @Theme_Icons;
	}

	&::-webkit-clear-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

input.g-menu-item--colorscheme__selector__radio[type="radio"] {
	margin: 0 5px 0 0;
}
