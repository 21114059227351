.g-modal.g-truck-dashboard-delivery .modal-body,
.g-truck-dashboard-delivery__address .gwGroupBox .g-groupbox-panel-heading .panel-title {
	padding-top: 0;
}

.g-truck-dashboard-delivery__form {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.g-truck-dashboard-delivery__form .gwTextBox {
	position: relative;
}

.g-truck-dashboard-delivery__form__item {
	padding: 5px 5px 0 5px;
	width: 475px;
}

.g-truck-dashboard-delivery__form__item:first-child {
	padding-bottom: 5px;
}

.g-truck-dashboard-delivery__address.gwJobDocAddress-container {
	width: 100%;
}

.g-truck-dashboard-delivery__container {
	display: flex;
}

.g-truck-dashboard-delivery__container__item {
	flex: 1;
}

.g-truck-dashboard-delivery__declaration {
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
	color: @Theme_ValueText;
}

.g-truck-dashboard-delivery__signature {
	margin-top: 10px;
	width: 465px;
	height: 300px;
}
