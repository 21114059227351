.g-pave {
	display: flex;
	font-size: 13px;

	.g-board {
		height: inherit;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;

		.g-board-sections {
			display: grid;
			width: 100%;
			height: 100%;

			.g-section-container {
				padding: 5px;
				overflow: auto;
				height: auto;
				width: auto;
			}
		}
	}
}

.g-pave-unsupported {
	color: @grey;
	background-color: @white;
	border: 1px solid @grey;
	border-radius: 4px;
	padding: 15px;
	overflow: auto;
	text-align: center;
}

.g-pave-section-index-font {
	font-size: 14px;
}

.g-pave-section-zone-font {
	font-size: 14px;
}
