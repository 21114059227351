/* DESKTOP */

.g-body--no-header {
	height: 100%;
	padding-top: 0;

	#g-header-for-binding {
		display: none;
	}
}

.dropdown > .dropdown-menu > .dropdown-submenu,
.g-grid-actions-dropdown.dropdown-submenu,
.g-dynamic-menu-list > .dropdown-submenu {
	.nestedPadding(5);
}
