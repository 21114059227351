.gwConfigureFilter {
	height: @g-control-height;

	.input-group-addon i {
		color: @Theme_Icons;
	}

	&.disabled {
		.form-control {
			background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
		}

		.input-group-addon {
			pointer-events: none;
		}

		.form-control,
		.input-group-addon {
			color: @InputControl_ReadOnlyTextColor;
		}
	}
}
