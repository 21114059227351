.g-modal-configurator-content .gwSearchList-container {
	height: 100%;
}

.g-configurationtmpl__optionbutton-group .gwOptionButtonGrid__wrap {
	padding-right: 0;
	padding-left: 0;
	margin: 0;
	border: none;
}

.g-configurationtmpl-filter {
	.gwFilter-groups {
		max-height: 128px;
	}
}
