.typeahead.dropdown-menu {
	width: 350px;
	z-index: 2000;
}

.open > .dropdown-menu {
	display: block;
}

.open > a {
	outline: 0;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.dropdown-menu-left {
	right: auto;
	left: 0;
}

.pull-right > .dropdown-menu {
	right: 0;
	left: auto;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border-radius: 4px;
	.dropdown-menu-shared;

	&.pull-right {
		right: 0;
		left: auto;
	}

	> li {
		> a {
			display: block;
			padding: 3px 20px;
			clear: both;
			font-weight: 400;
			line-height: 1.42857143;
			color: #333333;
			white-space: nowrap;
		}

		> a:hover,
		> a:focus {
			color: #262626;
			text-decoration: none;
			background-color: #f5f5f5;
		}
	}

	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: #e5e5e5;
	}

	> .active {
		> a,
		> a:hover,
		> a:focus {
			color: #fff;
			text-decoration: none;
			background-color: #337ab7;
			outline: 0;
		}
	}

	> .disabled {
		> a,
		> a:hover,
		> a:focus {
			color: #777777;
		}

		> a:hover,
		> a:focus {
			text-decoration: none;
			cursor: not-allowed;
			background-color: transparent;
			background-image: none;
			filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
		}
	}
}

.dropdown-menu-shared {
	float: none;
	min-width: 250px;
	padding: 0;
	margin-bottom: 0;
	font-size: @g-menu-font-size;
	list-style-type: none;
	border-color: #999;
	border-radius: 0 0 5px 5px;
	.box-shadow(2px 4px 6px rgba(51, 51, 51, 0.6));
}
