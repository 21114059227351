li.g-jump-item {
	position: relative;

	> a {
		width: 100%;
		height: @g-jump-item-height;
		overflow: hidden;
		font-size: @g-jump-item-font-size;
		cursor: pointer;
		display: flex;
		align-items: center;

		> span {
			display: inline;
			line-height: @g-jump-item-height;
			.text-overflow();
		}
	}

	i.chevron {
		margin: -2px 6px 0 0;
	}

	i.g-filled-star,
	i.g-empty-star {
		width: @icon-button-size;
		height: @icon-button-size;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		.opacity-hover(@transition: 0.02s);
	}

	i.g-filled-star {
		color: #f5b400;
	}

	i.g-empty-star {
		color: #808080;
	}

	&.g-no-pin > a {
		padding-right: 10px;
	}

	> span {
		width: 100%;
		height: @g-jump-item-height;
		padding-left: @g-jump-icon-padding;
		font-size: @g-jump-item-font-size;
		line-height: @g-jump-item-height;
		cursor: auto;
	}
}

li.g-jump-add-remove > a {
	padding: 0;

	> span {
		width: 100%;
		height: @g-jump-item-height;
		padding-left: @g-jump-icon-padding;
		font-size: @g-jump-item-font-size;
		line-height: @g-jump-item-height;
	}
}

.gwFavorites {
	width: 100%;
	height: 100%;
	padding: 4px;

	> .gwGroupBox-container {
		height: 100%;
	}

	.g-favorites-wrap {
		position: relative;
	}

	.gwGroupBox {
		width: 100%;

		.panel-heading {
			border: 0;
		}

		.panel-body {
			position: relative;
			height: calc(100% ~'-' @g-control-height);
			margin: 0;
		}
	}

	.dropdown-submenu {
		i.chevron {
			margin: @g-menu-chevron-margin;

			&.icon-chevron-down {
				display: inline;
			}
		}

		&.open {
			> a > i.icon-chevron-down {
				transform: rotate(180deg) translateY(-3px);
			}
		}
	}

	[data-contents="gwGroupBox"] {
		height: 100%;
		overflow: hidden;
		background-color: #fff;
		border: 1px solid #D3D6D9;
		border-radius: 5px;

		ul {
			margin: 0;
			overflow: auto;
			-webkit-overflow-scrolling: touch;

			li {
				margin-right: 5px;
				margin-bottom: 5px;
				margin-left: 5px;
				border-radius: 5px;

				&:first-child {
					margin-top: 5px;
				}

				> a {
					width: 100%;

					> i.g-empty-star,
					> i.g-filled-star {
						float: left;
					}

					> span {
						float: left;
						max-width: calc(100% ~"-" @g-jump-icon-size);
					}
				}

				&.g-favorites-placeholder {
					height: 2px;
					margin: 0;
					background-color: #000;
					border-radius: 0;
				}

				&.g-no-pin {
					> a > span {
						max-width: calc(100% ~"-" @g-jump-icon-size);
					}
				}
			}
		}
	}
}

.gwFavorites-with-recents > .gwGroupBox-container {
	height: auto;
}

.gwFavorites-error {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1005;
	width: 100%;
	height: 100%;
	margin: 0;
	font-size: 16px;
	text-align: center;
	vertical-align: middle;
}

.g-absolute {
	.gwFavorites-with-recents {
		display: flex;
		flex-direction: column;
		min-height: 240px;
		max-height: 100%;

		.g-favorites {
			display: flex;
			flex-direction: column;
			flex: 0 1 auto;
			min-height: 120px;
			max-height: calc(~'100% - 120px');
		}

		.g-recents {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			min-height: 120px;
		}

		.gwGroupBox {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;

			.panel-heading {
				flex: 0 0 auto;
			}

			.panel-body {
				display: flex;
				flex-direction: column;
				flex: 1 1 auto;

				> div {
					flex: 1 1 auto;
					overflow-y: auto;
					-webkit-overflow-scrolling: touch;
				}
			}
		}
	}
}
