.gwToggle {
	display: flex;
	align-items: center;

	.btn.btn-link {
		color: @Content_TextColor;
		padding: 0;
		cursor: pointer;

		&.btn-selected {
			color: @Grid_TitleFontColor;
		}
	}

	.gwToggleSwitch(42px);
}

.gwToggleSwitch(@switch_width) {
	@switch_height: round(0.55 * @switch_width);
	@slider_width: 0.72 * @switch_height;
	@slider_margin: 0.14 * @switch_height;
	@slider_translate: @switch_width - @slider_width - (2 * @slider_margin);

	.switch {
		position: relative;
		display: inline-block;
		margin: 0 15px;
		width: @switch_width;
		height: @switch_height;

		.switch-input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		.switch-slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: @Grid_TitleFontColor;
			-webkit-transition: .4s;
			transition: .4s;
			border-radius: @switch_height;

			&:before {
				position: absolute;
				content: "";
				height: @slider_width;
				width: @slider_width;
				left: @slider_margin;
				bottom: @slider_margin;
				background-color: @Content_TextColor;
				-webkit-transition: .4s;
				transition: .4s;
				border-radius: 50%;
			}
		}

		.switch-input:checked + .switch-slider:before {
			-webkit-transform: translateX(@slider_translate);
			transform: translateX(@slider_translate);
		}
	}
}
