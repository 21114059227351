.g-board-search-container {
	padding: 0 @g-header-button-padding;
	z-index: 4;
}

.g-board-search {
	border-radius: @rounded-corners;
	.user-select(none);
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 10px;
	.transition(0.25s, ease-in-out);
	display: flex;
	align-items: center;
	cursor: text;
	background-color: var(--g-board-search-background-color);
	border: var(--g-board-search-border);

	&:hover {
		background-color: var(--g-board-search-background-color-hover);
	}

	.g-board-search-icon {
		margin-top: -1px;
	}

	&.header-search {
		--g-board-search-background-color: rgba(255, 255, 255, 0.3);
		--g-board-search-background-color-hover: rgba(255, 255, 255, 0.44);
		--g-board-search-border: none;
		--g-board-search-input-width: 110px;

		@media (max-width : @screen-sm) {
			display: none;
		}

		.g-board-search-input {
			.transition(0.25s, ease-in-out);
			.placeholder(white, none);
		}

		.g-board-search-icon {
			.transition(0.25s, ease-in-out);
			color: white;
		}

		&.focused {
			background-color: white !important;

			.g-board-search-input {
				width: 180px;
				color: black;
			}

			.g-board-search-icon {
				color: @Header_BackgroundColorStart;
			}
		}
	}

	&.menu-search {
		--g-board-search-background-color: intial;
		--g-board-search-background-color-hover: #f2f2f2;
		--g-board-search-border: 1px solid @InputControl_BorderBrushStart;
		--g-board-search-input-width: 90%;
	}

	.g-board-search-input {
		width: var(--g-board-search-input-width);
		background-color: transparent;
		padding-left: 5px;
		padding-right: 5px;
		.text-ellipsis();
		border: none;
	}
}
