.user-agreement {
	display: flex;
	flex-direction: column;
	min-width: 275px;
	max-width: 1000px;
	min-height: 400px;
	height: calc(100% ~"-" @g-control-height);
	margin-left: auto;
	margin-right: auto;
}

.user-agreement-header {
	margin-top: 0;
	margin-bottom: 10px;
	border-bottom: 1px solid @navbar-default-border;
}

.user-agreement-header__text {
	color: @Body_TextColor;
	font-size: 22px;
	line-height: 26px;
}

.user-agreement-content {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 10px;
	height: calc(100% ~"-" @g-control-height);
}

.user-agreement-content__text {
	flex: 1;
	margin: 5px;
	color: @Body_TextColor;
	overflow-y: auto;
	white-space: pre-line;
}

.user-agreement-content__checkbox {
	margin: 5px;
	margin-left: auto;
	width: 275px;
	height: @g-control-height;
}

.user-agreement-content__button {
	margin: 5px;
	margin-left: auto;
	width: 150px;
	height: @g-control-height;
}
