.g-section {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	--g-section-header-space-display: initial;

	&.BUF {
		--g-channel-header-size: @channel-header-size;
		--g-channel-header-info-container-size: @channel-header-info-container-size;
	}

	&.BUC {
		@channel-header-size-intermediate: @channel-header-size - 20px;
		--g-channel-header-size: @channel-header-size-intermediate;
		--g-channel-header-info-container-size: auto;
	}

	&.NOT {
		--g-section-header-space-display: none;
	}

	.g-section-flow-up {
		--g-section-flow-direction: column;
		--g-section-flow-justify-content: flex-start;
	}

	.g-section-flow-down {
		--g-section-flow-direction: column-reverse;
		--g-section-flow-justify-content: flex-end;
	}

	.g-section-flow-left {
		--g-section-flow-direction: row;
		--g-section-flow-justify-content: flex-start;
	}

	.g-section-flow-right {
		--g-section-flow-direction: row-reverse;
		--g-section-flow-justify-content: flex-end;
	}

	.g-section-filter-launcher, .g-section-ticket-size-selection, .g-section-reset-layout-button {
		&.active {
			background-color: @Theme_Icons;
			color: @white;
			border-radius: 5px;
			.opacity-hover();
		}
	}

	.g-section-reset-layout-button {
		pointer-events: none;
		color: gray;

		&.active {
			pointer-events: auto;
		}
	}

	.g-section-header {
		display: flex;
		align-items: center;
		padding-bottom: 5px;
		flex: 0 0 auto;

		.g-section-acceptability-bands {
			flex-grow: 1;
			display: flex;
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 8px;
			margin-bottom: -4px;
			min-width: 60px;
			overflow: auto;
			-webkit-overflow-scrolling: touch;

			&::-webkit-scrollbar {
				display: none;
			}

			.g-acceptability-band {
				&.overflow {
					position: absolute;
					visibility: hidden;
					top: -1000px;
					left: 0;
				}
			}
		}

		.g-section-controls {
			display: flex;
			margin-left: auto;

			.g-container {
				padding: 0 1px
			}
		}

		& > .g-title {
			margin: 0;
			line-height: initial;
			.text-ellipsis();
		}
	}

	.g-section-grid {
		overflow: auto;
		display: flex;
		flex: 1 0 0%;
		-webkit-overflow-scrolling: touch;
		-webkit-transform: translate3d(0,0,0); // fixes the issue with scrollbars on Safari appearing behind position fixed element;
		&.g-section-flow-vertical {
			--g-section-grid-contents-direction: row;
			--g-section-cells-direction: column;
			--g-section-grid-channel-container-min-size: @channel-min-width;
		}

		&.g-section-flow-horizontal {
			--g-section-grid-contents-direction: column;
			--g-section-cells-direction: row;
			--g-section-grid-channel-container-min-size: @channel-min-height;
		}

		.g-section-grid-contents {
			// this div fixes an issue with position: sticky inside overflow:auto for Safari https://codepen.io/jamiejakov/pen/xjQJyr
			display: flex;
			flex: 1 0 0%;
			overflow: auto;
			flex-direction: var(--g-section-grid-contents-direction);
		}

		@media not all and (min-resolution:.001dpcm) { /* CSS Statements that only apply to Safari 11+ */
			@supports (-webkit-appearance:none) and (stroke-color:transparent) {
				.g-section-grid-contents {
					// this div fixes an issue with position: sticky inside overflow:auto for Safari https://codepen.io/jamiejakov/pen/xjQJyr (only when vertical, not when horizontal)
					overflow: visible;
				}
			}
		}

		.g-section-index-headers,
		.g-section-zone-headers {
			display: flex;
			position: sticky;
			position: -webkit-sticky;
			z-index: 91; // UI resizable is z-index: 90; it's done inline with JS
			background-color: @Body_BackgroundColorStart;
			flex-direction: var(--g-section-cells-direction);

			.single-cell& {
				.g-section-index-header {
					display: none;
				}
			}

			.g-section-header-space {
				display: var(--g-section-header-space-display);
				flex: 0 0 var(--g-channel-header-size);
				align-self: flex-end;
			}

			.g-section-index-headers-list,
			.g-section-zone-headers-list {
				display: flex;
				flex: 1 0 0%;
				flex-direction: var(--g-section-flow-direction);
				justify-content: var(--g-section-flow-justify-content);
			}

			.g-section-index-header,
			.g-section-zone-header {
				transition: @pave-expand-transition;
				overflow: hidden;
				border-bottom: @section-grid-border;
				border-right: @section-grid-border;
				display: flex;
				justify-content: center;
				flex: 1 0 0%; //basis is overwritten in JS
				&.collapsed {
					flex-grow: 0 !important;
				}

				&.reset {
					//Setting flex grow to 1 sets the proportions perfectly https://codepen.io/jamiejakov/pen/eMLKZV
					flex-grow: 1 !important;
				}

				&.shrink-text {
					font-size: 11px;
				}

				.g-section-index-header-text,
				.g-section-zone-header-text {
					text-align: center;
					padding: 5px;
					width: 100%;
					height: 100%;
					flex: 1 0 auto;
				}
			}
		}

		.g-section-index-headers {
			.single-cell& {
				background-color: transparent;
			}
		}

		.g-channels-container {
			display: flex;
			flex: 1 0 0%;
			min-width: 0; //nessessary for text elipsis to work (see css-tricks https://codepen.io/jamiejakov/pen/eMvGwp)
			min-height: 0;

			.g-channels {
				display: flex;
				flex: 1 0 0%;
				min-width: 0; //nessessary for text elipsis to work (see css-tricks https://codepen.io/jamiejakov/pen/eMvGwp)
				min-height: 0;
				flex-direction: var(--g-section-grid-contents-direction);

				.g-channel-container {
					position: relative;
					display: flex;
					flex: 1 0 auto; //gets overriden in vertical and horizontal
					min-width: 0; //nessessary for text elipsis to work (see css-tricks https://codepen.io/jamiejakov/pen/eMvGwp)
					min-height: 0;
					flex-basis: var(--g-section-grid-channel-container-min-size);
				}
			}
		}
	}
}

.g-ticket-size-item-container, .g-filter-item-container {
	display: flex;
	justify-content: space-between;

	&-loading {
		pointer-events: all;
		cursor: wait;
		label, span, input {
			pointer-events: none;
		}
	}

	.g-item-checkbox {
		flex-grow: 1;
	}

	.g-item-spinner {
		align-self: flex-end;
		flex-grow: 0;
		width: 0px;
		padding-right: 21px;
		margin: 0;
	}
}

.g-section-grid.g-section-flow-vertical {
	.g-section-index-headers,
	.g-section-zone-headers {
		width: @section-index-header-size;

		.single-cell & {
			width: 1px;
		}

		.g-section-header-space {
			width: 5px;
		}

		.g-section-index-header-text,
		.g-section-zone-header-text {
			.vertical-text();
		}
	}

	.g-section-index-headers {
		left: 0;

		.g-section-index-header {
			border-left: @section-grid-border;
		}
	}

	.g-section-zone-headers {
		left: @section-index-header-size;
	}

	.g-section-header-space.channel-header-border {
		margin-right: -4px;
		border-top-left-radius: @rounded-corners;
		border-top: @channel-header-border;
		border-left: @channel-header-border;
	}

	.g-channels-container {
		margin-left: -1px;

		.g-channels {
			.g-channel-container {
				min-width: var(--g-section-grid-channel-container-min-size);

				&:first-child .g-channel-header {
					margin-left: 0;
				}

				&:last-child .ui-resizable-handle {
					right: 0px;
				}
			}
		}
	}
}

.g-section-grid.g-section-flow-horizontal {
	.g-section-index-headers,
	.g-section-zone-headers {
		height: @section-index-header-size;

		.single-cell & {
			height: 1px;
		}

		.g-section-header-space {
			height: 5px;
		}
	}

	.g-section-index-headers {
		top: 0;

		.g-section-index-header {
			border-top: @section-grid-border;
		}
	}

	.g-section-zone-headers {
		top: @section-index-header-size;
	}

	.g-section-header-space.channel-header-border {
		margin-bottom: -4px;
		border-top-left-radius: @rounded-corners;
		border-top: @channel-header-border;
		border-left: @channel-header-border;
	}

	.g-channels-container {
		margin-top: -1px;

		.g-channels {
			.g-channel-container {
				min-height: var(--g-section-grid-channel-container-min-size);

				&:first-child .g-channel-header {
					margin-top: 0;
				}

				&:last-child .ui-resizable-handle {
					bottom: 0px;
				}
			}
		}
	}
}

.g-section-flow-up {
	.g-section-index-headers-list,
	.g-section-zone-headers-list {

		.g-section-index-header {
			&:first-child {
				border-top-left-radius: @rounded-corners;
			}

			&:last-child {
				border-bottom-left-radius: @rounded-corners;
			}
		}

		.g-section-index-header,
		.g-section-zone-header {
			&:first-child {
				border-top: @section-grid-border;
			}
		}
	}
}

.g-section-flow-down {
	.g-section-index-headers-list,
	.g-section-zone-headers-list {

		.g-section-index-header {
			&:first-child {
				border-bottom-left-radius: @rounded-corners;
			}

			&:last-child {
				border-top-left-radius: @rounded-corners;
			}
		}

		.g-section-index-header,
		.g-section-zone-header {
			&:last-child {
				border-top: @section-grid-border;
			}
		}
	}
}

.g-section-flow-left {
	.g-section-index-headers-list,
	.g-section-zone-headers-list {

		.g-section-index-header {
			&:first-child {
				border-top-left-radius: @rounded-corners;
			}

			&:last-child {
				border-top-right-radius: @rounded-corners;
			}
		}

		.g-section-index-header,
		.g-section-zone-header {
			&:first-child {
				border-left: @section-grid-border;
			}
		}
	}
}

.g-section-flow-right {
	.g-section-index-headers-list,
	.g-section-zone-headers-list {

		.g-section-index-header {
			&:first-child {
				border-top-right-radius: @rounded-corners;
			}

			&:last-child {
				border-top-left-radius: @rounded-corners;
			}
		}

		.g-section-index-header,
		.g-section-zone-header {
			&:last-child {
				border-left: @section-grid-border;
			}
		}
	}
}

.g-section-ticket-size-selection {
}
