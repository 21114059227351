.gwSignature {
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: center;
	color: @input-color;
	display: flex;
	flex-direction: column;

	.gwSignature-canvas-wrapper {
		display: flex;
		flex: 1 0 0px;
		border: 1px solid @InputControl_BorderBrushStart;
		background-color: @InputControl_FocusedBackgroundColorEnd;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		width: 100%;
		height: 100%;
	}

	&.alert-danger {
		background-color: transparent;

		.gwSignature-canvas-wrapper {
			.alert-danger-mixins();
		}

		.gwSignature-canvas-wrapper:hover {
			border: 1px solid @InputControl_BorderBrushStart;
			background-color: @InputControl_FocusedBackgroundColorEnd;
		}

		&.gwValidation-highlight .gwSignature-canvas-wrapper {
			.alert-danger-highlight();
		}
	}

	&.alert-message-error {
		background-color: transparent;

		.gwSignature-canvas-wrapper {
			.alert-message-error-mixins();
		}

		&.gwValidation-highlight .gwSignature-canvas-wrapper {
			.alert-message-error-highlight();
		}
	}

	&.alert-warning {
		background-color: transparent;

		.gwSignature-canvas-wrapper {
			.alert-warning-mixins();
		}

		&.gwValidation-highlight .gwSignature-canvas-wrapper {
			.alert-warning-highlight();
		}
	}

	.gwSignature-canvas {
		width: 100%;
		height: 100%;
		touch-action: none;
	}

	.gwSignature-canvas.readonly {
		border: 1px solid @InputControl_BorderBrushStart;
		background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
	}

	.btn {
		.g-button-background(@Button_BackgroundColorStart, @Button_BackgroundHoverColorStart, @Button_BackgroundPressedColorStart, @Button_TextColor, @Button_TextColor_Lighten10, @Button_TextHoverColor, @border-radius: 0 0 5px 5px);
	}
}

.gwSignature-config-control {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 50%;
	max-height: 50%;
	border-radius: 3px;
	color: @input-color;
	.translate(-50%, -50%);
}
