#toast-container {
	font-size: 20px;
	margin: 10px;

	&.toast-center {
		position: absolute;
	}

	&.toast-center-glow-2 {
		position: fixed
	}

	&.toast-top-left,
	&.toast-top-right,
	&.toast-top-full-width {
		margin-top: 72px;
	}

	&.toast-top-full-width,
	&.toast-bottom-full-width {
		> .toast {
			width: 100%;
			padding-top: 10px;
			padding-bottom: 15px;
			border-radius: 0;

			> .toast-close-button {
				top: 50%;
				margin-top: -14px;
				line-height: 17px;
			}
		}
	}

	> .toast {
		position: relative;
		width: 100%;
		padding-left: 57px;
		padding-right: 30px;
		.opacity(@ToastAlert_Opacity);

		a {
			color: @state-title-text;

			&:hover {
				text-decoration: underline;
			}
		}

		> i {
			position: absolute;
			top: 50%;
			left: 13px;
			margin-top: -17px;
			font-size: 34px;
		}

		> .toast-message {
			display: inline-block;
			word-break: break-word;
			white-space:pre-wrap;
		}

		> .toast-close-button {
			position: absolute;
			top: -.2em;
			right: .3em;
		}

		&.toast-error {
			color: @state-title-text;
			background-color: @state-error;
			background-image: none !important;

			> .toast-close-button {
				color: @state-title-text;
			}
		}

		&.toast-success {
			color: @state-title-text;
			background-color: @state-success;
			background-image: none !important;

			> .toast-close-button {
				color: @state-title-text;
			}
		}

		&.toast-warning {
			color: @state-title-text;
			background-color: @state-warning;
			background-image: none !important;

			> .toast-close-button {
				color: @state-title-text;
			}
		}

		&.toast-info {
			color: @state-title-text;
			background-color: @state-info;
			background-image: none !important;

			> .toast-close-button {
				color: @state-title-text;
			}
		}

		&.toast-message-error {
			color: @state-title-text;
			background-color: @state-message-error;
			background-image: none !important;

			> .toast-close-button {
				color: @state-title-text;
			}
		}
	}
}
