.g-arrow {
	width: 10px;
	height: 10px;
	padding: 3px;
	border: solid #000;
	border-width: 0 3px 3px 0;
}

.g-arrow--right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.g-arrow--left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.g-arrow--up {
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}

.g-arrow--down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}
