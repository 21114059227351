.flag-icon.flag-icon-es-ct {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/es-ct.8M2WsbnztzXpajm0IoBc9zUBnt8.png');
}

.flag-icon.flag-icon-eu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/eu.bGyIstUVdTIE2X27nW4tT4Sw25k.png');
}

.flag-icon.flag-icon-gb-eng {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gb-eng.31QpN5bgGMAETl-N5xilshDKoEs.png');
}

.flag-icon.flag-icon-gb-nir {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gb-nir.9y1ehUByCJ3k0Q8i6G91-wGwJZE.png');
}

.flag-icon.flag-icon-gb-sct {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gb-sct.I619Xwi8UwzO0CV8ZkmnaC938aM.png');
}

.flag-icon.flag-icon-gb-wls {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gb-wls.LVYur7wFaAt-0ONeqxF5Cg01KHA.png');
}

.flag-icon.flag-icon-un {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/un.sSVdOiFJwG9B54KH8QywUwohjFQ.png');
}

.flag-icon.flag-icon-xk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/xk.6MJ0gxcCQeHav8Fk993IF7Aks9M.png');
}
