.g-context-change-dialog .modal-dialog .modal-body {
	padding: 0;
}

.g-context-change {
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.g-context-change__header {
	padding: 10px;
	border-bottom: 1px solid @SidebarPanel_BorderColor;
}

.g-context-change__title {
	font-size: 17px;
	color: @Theme_FieldTitle;
}

.g-context-change__subtitle {
	font-size: 17px;
	color: @Theme_FieldTitle;
}

.g-context-change__body{
	flex: 1;
}
