@arrow-icon-color: #808080;
@lock-icon-color: #cccccc;

.g-hierarchical-item-list {
	height: 100%;
	overflow: hidden;

	a {
		outline: none;
	}

	.alert-danger {
		.alert-danger-mixins() !important;
	}

	.gwValidation-highlight {
		.alert-danger-highlight() !important;
	}

	.g-hierarchical-item-list-col {
		padding-left: 0;
		padding-right: 0;
	}

	.left {
		margin-right: 5px;
	}

	.right {
		margin-left: 5px;
	}

	.full-height {
		height: 100%;
	}

	.g-hierarchical-item-list-row.full-height {
		height: calc(~'100% - 76px');
	}

	.push-to-front {
		z-index: 3;
	}

	.center-icon {
		vertical-align: middle;
		margin-top: -1px;
	}

	.form-group {
		margin-bottom: -1px;

		input[type="text"] {
			.placeholder(none, italic);
			padding-top: 3px;
			border-radius: 5px 5px 0px 0px;
		}

		.input-group-addon {
			top: 9px;

			i {
				vertical-align: baseline;
				display: inline-block;
			}

			&:hover {
				opacity: 1;
				cursor: default;
			}
		}
	}

	.g-mapping-table-container {
		margin-top: -1px;
		height: 100% !important;

		.no-items {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			text-align: center;

			span {
				color: @Theme_FieldTitle;
			}
		}

		.g-mapping-table-container-content {
			margin-left: -1px;
			min-height: 100%;

			.indentedGroupBox {
				.g-groupbox-wrapper:first-child {
					margin-top: -1px;
				}

				.item {
					top: -1px;

					&:last-child {
						border-bottom-left-radius: 4px !important;
					}
				}
			}

			.item {
				position: relative;
				height: 30px;
				padding: 6px 8px;
				white-space: nowrap;
				border: 1px solid @SidebarPanel_BorderColor;
				border-right-width: 0px;
				margin-bottom: -1px;

				.enable-icon, .disable-icon {
					color: @arrow-icon-color !important;
				}

				&:hover {
					background-color: @Theme_Hover;
					cursor: pointer;

					a, i, span, .disable-icon.icon-lock {
						color: @white;
						color: @Theme_HoverText !important;
					}
				}
			}

			.table-name-header {
				width: calc(~'100% - 15px');
				overflow: hidden;
				text-overflow: ellipsis;

				i {
					display: inline-block;
					margin-left: 4px;
				}

				.g-table-title {
					margin-left: 5px;
					position: relative;
					top: -1px;
				}

				.g-table-title-children {
					vertical-align: middle;
				}

				.g-table-title-nochildren {
					span {
						font-size: 14px;
						color: @Theme_GeneralText;
					}

					i {
						margin-left: 0px;
						color: @Theme_DefaultButton;
						opacity: 0.9;
						vertical-align: inherit;
					}
				}

				.g-item-path,
				.g-item-desc {
					font-size: 11px !important;
					color: @Theme_FieldTitle !important;
					font-style: italic !important;
					position: relative;
					top: -1px;
				}

				.enable-icon {
					text-align: right;
					position: absolute;
					right: 8px;
					top: 8px;
				}

				.disable-icon {
					margin-left: 2px !important;
					margin-right: 8px;
					position: relative;
					top: -1px;

					&.icon-lock {
						margin-left: 5px !important;
						margin-right: 10px !important;
						color: @lock-icon-color !important;
					}
				}
			}

			.ui-sortable-helper {
				background-color: @Theme_Hover !important;
				cursor: move !important;

				.table-name-header {
					cursor: move !important;
				}

				a, i, span {
					color: @Theme_HoverText !important;
				}
			}

			.ui-draggable-dragging {
				width: 100%;
				z-index: 200;
				border: 1px solid @GroupBox_BorderColor;
				border-radius: 0 !important;
				background-color: @Theme_Hover;
				cursor: move !important;

				a, i, span {
					color: @Theme_HoverText !important;
				}

				.table-name-header {
					cursor: move !important;
				}
			}

			.show-border {
				border-bottom: 1px solid @GroupBox_BorderColor;
				margin-bottom: -1px;
			}
		}
	}

	.left {
		.dragging-placeholder {
			.table-name-header {
				visibility: hidden;
			}
		}

		.drop-adorner {
			visibility: collapse;
			border: 1px solid @Theme_Hover;
			background-color: @Grid_ViewportSelectedRowColor;
			border-radius: 4px;
			position: absolute;
			top: -33px;
			bottom: 1px;
			right: 5px;
			left: 0px;
			z-index: 100;
		}
	}

	.right {
		.dragging-placeholder:not(.ui-draggable) {
			&:not(.dragged-out-placeholder) {
				height: 30px;
				margin-left: 1px;
				margin-top: 1px;
				background-color: @Grid_ViewportSelectedRowColor;
				border: 1px solid @Theme_Hover;
			}

			&.dragged-out-placeholder {
				height: 30px;

				.table-name-header {
					visibility: hidden;
				}
			}

			&:first-child {
				margin-top: 2px;
			}

			&:last-child {
				border-bottom-width: 2px;
			}
		}

		.dragging-placeholder.ui-draggable {
			.table-name-header {
				visibility: hidden;
			}
		}

		.drop-adorner {
			visibility: collapse;
			border: 1px solid @Theme_Hover;
			background-color: @Grid_ViewportSelectedRowColor;
			border-radius: 4px;
			position: absolute;
			top: -33px;
			bottom: 1px;
			right: 0px;
			left: 5px;
			z-index: 100;
		}
	}

	.ui-droppable-hover {
		.drop-adorner {
			visibility: visible;
		}
	}
}

.g-hierarchical-item-list-fetchnext {
	display: block;
	height: 30px;
	padding: 6px 8px;
	text-align: center;

	&:focus {
		text-decoration: none;
	}
}

.g-hierarchical-item-list-row {
	display: flex;
}

.g-hierarchical-item-list-col {
	width: 50%;
	position: relative;
}
