.g-search-result-key-fields {
	color: @Theme_FieldTitle;
	width: @g-search-result-key-fields-width;

	li {
		display: inline-block;
		min-width: @g-search-result-key-fields-li-min-width;
		margin-right: 10px;
		vertical-align: top;

		.key-field-value {
			color: @Theme_ValueText;
		}

		.key-field-heading {
			text-decoration: none;

			.key-field-value {
				color: @Theme_DefaultButton;

				&:hover {
					color: @Theme_FieldTitle;
				}
			}
		}

		span {
			display: inline;
			vertical-align: top;
			line-height: 20px;
		}

		&:first-child {
			display: block;
			font-size: 16px;
			font-weight: bold;
		}
	}
}

.g-global-search-results {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	border-radius: 4px;
	border: 1px solid @grey;
	height: 100%;
	width: 100%;

	ul {
		margin: 0;
	}
}

.g-search-result-info-container {
	padding: 3px 7px;
	text-align: center;
	flex-basis: 300px;
	flex-grow: 0;
	flex-shrink: 0;
}

.g-search-result-last-event {
	padding: 3px 7px;
	background: @Grid_ReadonlyViewportAlternatingColor;
	border: 1px solid @GroupBox_BorderColor;
	border-radius: 4px;

	.g-last-event-desc {
		font-size: 15px;
		font-weight: bold;
		.text-overflow();
	}

	.g-last-event-details {
		font-size: 12px;
		color: @Theme_FieldTitle;
	}

	.g-last-event-date {
		font-weight: bold;
		color: @Grid_TitleFontColor;
	}
}

.g-search-results-items > li.focused-text {
	color: @white;
}

.g-search-results-items {
	> li {
		padding: 6px;
		height: auto;
		background-color: @white;

		& + li {
			border-top: 1px solid @grey;
		}

		&:last-child {
			border-bottom: 1px solid @grey;
		}

		&.g-info-card-field-container {
			padding: 0;

			.entity-and-key-fields-container {
				display: flex;
				border-radius: 0;
			}

			.g-card-actions {
				width: @gwDataGrid-row-actions-icon-width;
				border-right: 1px solid @grey;
				align-self: stretch;
				flex: none;
				color: @Theme_Icons;

				> button {
					background-color: transparent;
				}
			}

			.g-card-key-field {
				color: @Theme_Icons;
				font-size: 14px;
				padding: 8px;

				> ul {
					padding: 0;
				}
			}

			.g-card-sub-heading {
				font-size: 12px;
				color: @Theme_FieldTitle;
			}
		}

		.gwTrackingProgress__mode {
			cursor: default;
		}

		.gwTrackingProgress__mode:hover {
			opacity: 1;
		}

		&.g-tracking-search-result {
			h3 {
				display: inline;
				margin-top: 0;
				font-size: 15px;
				color: @Theme_DefaultButton;
			}

			.g-grid-action-button {
				width: auto;
				height: auto;
				padding: 4px;
				display: inline-block;
			}

			.entity-and-key-fields-container {
				position: relative;
			}

			.entity-img-container {
				flex-basis: 22px;
				position: @entity-img-container-position;
				top: 0;
				right: @entity-img-container-right;
				align-self: auto;
			}

			.hide-img-container {
				.entity-img-container {
					display: none;
				}

				.g-search-result-key-fields {
					margin-left: 5px;
				}
			}

			> .g-search-results-selected {
				width: 100%;
				margin: 7px 0 0;
				border: none;
				padding: 5px;
			}

			&.focused {
				background-color: @Grid_ViewportSelectedRowColor;
			}

			&:hover {
				background-color: @Tracking_BackgroundHoverColor;
			}
		}

		.g-search-results-events-container {
			position: relative;
			height: auto;
		}

		.entity-and-key-fields-container {
			display: @entity-and-key-fields-container-display;
		}

		.entity-img-container {
			flex-basis: @entity-img-container-width;
			flex-grow: 0;
			flex-shrink: 0;
			text-align: @entity-img-container-text-align;
			align-self: @entity-img-container-align;
		}

		i {
			color: @Icons_Color;
			cursor: pointer;

			&:hover {
				opacity: 0.5;
			}
		}

		.g-search-results-expand {
			margin-top: auto;
			text-align: center;
		}

		.g-search-results-selected {
			border: 1px solid @grey;
			border-radius: 4px;
			@marginSize: 5px;
			@paddingSize: 0;
			@minusValue: (@marginSize + @paddingSize) * 2;
			padding: @paddingSize;
			margin: 15px @marginSize @marginSize @marginSize;
			width: calc(~"100% - "@minusValue);
			height: calc(~"100% - "@minusValue);
			background: @white;
		}
	}

	.g-search-results .dropdown-menu {
		background-color: @Grid_HeaderBackgroundColorStart;

		a {
			width: 100%;
		}

		> li > a {
			color: @Grid_HeaderTextColor;
		}
	}

	.g-tracking-event-container {
		width: 100%;

		li {
			padding: 4px 0;

			&:hover {
				background-color: @Tracking_BackgroundHoverColor;
				border-radius: 4px;
			}
		}

		.g-tracking-event {
			display: flex;

			.g-tracking-event-left {
				display: block;
				min-width: 105px;
				border-right: 1px solid @GroupBox_BorderColor;
			}

			.g-tracking-event-right {
				display: block;
			}

			.g-tracking-event-time {
				display: inline-block;
				color: @Theme_ValueText;
				width: 100%;
				text-align: right;
				padding-right: 4px;
			}

			.g-tracking-event-desc {
				display: inline-block;
				padding-left: 4px;
				font-weight: bold;
			}

			.g-tracking-event-details {
				display: block;
				padding-left: 4px;
			}
		}
	}

	.g-tracking-item,
	.g-tracking-group-item {
		position: relative;
		display: flex;
		width: 100%;
		padding: 10px 15px;
		vertical-align: top;
		background-color: #fff;
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
		border-bottom: 1px solid @grey;
		font-size: @Body_FontSize;

		&.focused {
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
			z-index: 2;
		}

		&:not(.focused):hover {
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
			z-index: 1;
		}
	}

	.g-tracking-item {
		min-width: 1170px;
	}

	.g-tracking-group-item__master-key-field {
		padding-right: 10px;
	}

	.g-tracking-group-item__master-key-field:not(:last-child) {
		padding-bottom: 10px;
	}

	.g-tracking-group-item__address {
		font-family: 'Noto Sans Condensed';
	}

	.g-tracking-group-item__address-company {
		font-size: 17px;
		font-weight: bold;
		color: @Theme_DefaultButton;
	}

	.g-tracking-group-item__address-company,
	.g-tracking-group-item__address-line1,
	.g-tracking-group-item__address-line2,
	.g-tracking-group-item__address-line3 {
		.text-overflow();
	}

	.g-tracking-group-item__master .gwFlag {
		width: 32px;
		height: 25px;

		.flag-icon {
			width: 100%;
			height: 100%;
			vertical-align: top;
		}
	}

	.g-tracking-group-item__master {
		flex: 1;
		min-width: 235px;
		border-right: 1px solid @grey;
	}

	.g-tracking-group-item__details {
		display: flex;
		flex: 4;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: start;
		padding: 0 10px 8px;
	}

	.g-tracking-group-item__child {
		display: flex;
		border-bottom: 1px solid @grey;
		padding: 5px 0;

		&:last-child {
			border-bottom: none;
		}

		&.buttons-only .g-tracking-group-item__child-actions {
			width: 40px;

			span {
				display: none;
			}
		}

		&.text-only {
			.g-tracking-group-item__child-actions {
				width: 100px;

				i {
					display: none;
				}
			}

			.g-tracking-group-item__child-actions .btn span {
				margin-left: 0;
			}
		}
	}

	.g-tracking-group-item__child-field {
		width: 150px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.g-tracking-group-item__child--padding-right {
		padding-right: 130px;

		&.buttons-only {
			padding-right: 40px;
		}

		&.text-only {
			padding-right: 100px;
		}
	}

	.g-tracking-group-item__child-actions {
		position: absolute;
		right: 15px;
		width: 130px;
		height: 20px;
		margin-top: -1px;
		padding-right: 5px;
		padding-left: 5px;
		background: #fff;
		text-align: center;
		border-left: 1px solid @grey;

		.btn {
			display: block;
			padding: 0 6px;
			font-size: 13px;
			.text-overflow();

			span {
				margin-left: 5px;
			}

			i {
				font-size: 12px;
			}
		}
	}

	.g-tracking-group-item__child-value {
		.text-overflow();

		.key-field-value:empty:after {
			content: '\200b';
		}
	}

	.g-tracking-item__key-field-heading {
		text-decoration: none;
		color: @Theme_DefaultButton;

		&:hover {
			color: @Theme_FieldTitle;
		}
	}

	.g-tracking-item__key-fields {
		flex: 1;
		min-width: 235px;
		border-right: 1px solid @grey;
	}

	.g-tracking-item__key-fields a {
		font-weight: bold;
		font-size: 16px;
	}

	.g-tracking-item__key-fields-featured,
	.g-tracking-item__key-fields-additional {
		display: flex;
		margin: 3px 0;
		justify-content: space-between;
	}

	.g-tracking-item__key-fields a,
	.g-tracking-item__key-fields-featured,
	.g-tracking-item__key-fields-additional {
		padding-right: 10px;
	}

	.g-tracking-item__key {
		width: 120px;
		font-weight: bold;
		text-transform: uppercase;
		font-family: 'Noto Sans Condensed';
		font-size: 12px;
	}

	.g-tracking-item__value {
		flex: 1;
		margin-left: 10px;
		color: @Theme_DefaultButton;
	}

	.g-tracking-item__details {
		display: flex;
		flex: 3;
		flex-wrap: wrap;
		align-content: start;
	}

	.g-tracking-item__progress {
		width: 600px;
		padding: 0 15px;
		flex: 2;
	}

	.g-tracking-item__expand {
		position: absolute;
		right: 15px;
		bottom: 10px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border: solid @Theme_DefaultButton;
		border-width: 0 3px 3px 0;
		padding: 3px;
		cursor: pointer;
		-webkit-transition: 0.35s ease-out;
		-moz-transition: 0.35s ease-out;
		-o-transition: 0.35s ease-out;
		transition: 0.35s ease-out;
	}

	.g-tracking-item__events {
		flex: 1;
		min-width: 235px;
		padding-left: 15px;
		border-left: 1px solid @grey;
	}

	.g-tracking-item__events-history {
		width: 100%;
		padding: 15px 0;
		margin: 15px 0 0 15px;
		border-top: 1px solid @grey;
	}

	.g-tracking-item__event {
		display: flex;
		padding: 3px 0;
		border-top: 1px solid @grey;
	}

	.g-tracking-item__event-date {
		padding-right: 4px;
		min-width: 65px;
		text-align: right;
	}

	.g-tracking-item__event-time {
		padding-right: 4px;
		min-width: 40px;
		text-align: right;
		opacity: 0.4;
	}

	.g-tracking-item__event-description {
		padding-left: 4px;
		border-left: 1px solid @grey;
	}

	.g-tracking-item__event-details {
		padding-left: 4px;
		flex: 1;
	}

	.g-tracking-item__event-user {
		padding-left: 4px;
		border-left: 1px solid @grey;
		text-align: right;
		opacity: 0.4;
		flex: 0 0 170px;
	}

	.g-tracking-item__last-event {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	.g-tracking-item__last-event-description {
		display: flex;
		justify-content: space-between;
		padding: 5px 0;
		margin-bottom: 5px;
		border-bottom: 1px solid @grey;
		font-weight: bold;
		font-family: 'Noto Sans Condensed';
	}
}

.g-padding-bottom-none {
	padding-bottom: 0 !important;
}
