.g-modal {
	.modal-dialog {
		&.g-modal-autoresize,
		&.g-modal-sizetocontent {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
			padding: 16px;
		}

		&.g-modal-autoresize {
			min-width: @g-modal-autoresize-min-width;
			min-height: 200px;
			margin: 0;
			width: auto;

			.g-modal-alert-bar {
				position: @g-modal-alert-bar-position;
				top: @g-modal-alert-bar-top;
				z-index: 1;
				width: 100%;
			}

			.modal-content {
				width: auto;
				height: 80%;
				min-width: @g-modal-autoresize-min-width;
				min-height: 200px;
				align-items: stretch;

				.modal-body-wrapper {
					height: 100%;
				}
			}

			.g-heading {
				margin-bottom: 0;
			}
		}

		&.g-modal-sizetocontent {
			margin: 0px;
			height: 100%;

			.modal-body-wrapper {
				position: static;
				height: auto;
			}

			.g-header {
				border-bottom: 0;
				background-color: transparent;
			}
		}

		.modal-header {
			display: flex;
			flex: 0 0 auto;
			align-items: center;
			width: 100%;
			padding: 0;
			height: @g-modal-header-max-height;
			.border-top-radius(@modal-border-radius);

			.g-tasktray-alerts {
				.g-display-option-button,
				.g-display-option-text {
					color: @Theme_Icons;
				}
			}
		}

		.modal-title-container {
			overflow: hidden;
			height: 100%;
			display: flex;
			align-items: center;
		}

		.modal-title .g-import-file-name {
			color: @Theme_FieldTitle;
		}

		.modal-content {
			border: none;
			.box-shadow(@modal-dialog-box-shadow);
			background-color: @Body_BackgroundColorStart;
			max-width: 100%;
			max-height: 100%;
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			border-radius: @modal-border-radius;
		}

		.modal-body {
			color: @Body_TextColor;
			width: 100%;
			height: 100%;
			overflow: auto;
			padding: @modal-padding;
			-webkit-overflow-scrolling: touch;
			background-color: @Content_BackgroundColorStart;

			> .g-icon-error,
			> .g-icon-message-error,
			> .g-icon-success,
			> .g-icon-warning,
			> .g-icon-information,
			> .g-icon-question {
				position: absolute;
				top: 10px;
				left: 10px;
				font-size: 34px;
			}

			> div > .gwValidationSummary {
				padding-top: 15px;
				margin-left: -40px;
			}

			> .g-icon-error {
				color: @state-error;
			}

			> .g-icon-message-error {
				color: @state-message-error;
			}

			> .g-icon-success {
				color: @state-success;
			}

			> .g-icon-warning {
				color: @state-warning;
			}

			> .g-icon-information {
				color: @state-info;
			}

			> .g-icon-question {
				color: @state-info;
			}

			&.text-only {
				white-space: pre-wrap;
			}
		}

		.modal-body-with-icon {
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			padding-top: 17px;
			padding-bottom: 17px;
			padding-left: 50px;
			position: relative;

			.alert-entity-message(@bg, @border) {
				&:hover {
					background: @bg;
					border-top-color: @bg;
					border-bottom-color: @border;
					border-left-color: @bg;
					border-right-color: @bg;

					span {
						background: @bg !important;
					}

					&:last-child {
						border-bottom-color: @bg;
					}
				}
			}

			.gwValidationSummary ul li.gwValidationSummary-alert-entity-message {
				&.alert-danger {
					.alert-entity-message(@state-error-pale, @state-error);
				}

				&.alert-message-error {
					.alert-entity-message(@state-message-error-pale, @state-message-error);
				}

				&.alert-warning {
					.alert-entity-message(@state-warning-pale, @state-warning);
				}

				&.alert-info {
					.alert-entity-message(@state-info-pale, @state-info);
				}
			}
		}

		.modal-footer {
			width: 100%;
			padding: @g-footer-padding;
			bottom: @g-footer-bar-bottom;
			margin-top: 0;
			border-radius: @g-footer-bar-border-radius;
			display: flex;
			flex: 0 0 auto;
			justify-content: space-between;

			.g-footer-buttons-wrap {
				width: 100%;
				position: relative;
				display: flex;
				justify-content: space-between;

				.g-footer-container {
					display: flex;
					align-items: center;

					.g-divider {
						height: 100%;
						background-color: white;
					}

					&.right {
						min-width: @g-footer-flex-min-width;
					}

					.btn {
						display: flex;
						justify-content: center;
						align-items: center;

						span {
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}

			&:before, &:after {
				content: none;
			}

			&.g-vertical {
				display: table;
				margin-right: auto;
				margin-left: auto;

				.btn {
					display: block;
					width: 100%;
					margin: 10px auto;
				}
			}

			&.g-horizontal .btn {
				min-width: @g-footer-button-horizontalmin-width;
				font-size: @g-footer-button-font-size;

				&.g-import-retry {
					float: left;
				}
			}
		}
	}

	&.g-dialog-help .modal-dialog.g-modal-autoresize .modal-content {
		height: 100%;
	}

	&.g-task-dialog {
		.g-modal-autoresize .modal-content {
			width: auto;
		}
	}

	.g-heading {
		position: static;
		border-bottom: none;
		min-height: 0;
		height: 100%;

		.navbar {
			position: relative;
			right: 2px;
			height: 100%;
			border: 0;

			.nav {
				height: 100%;
				display: flex;
				align-items: center;

				> li {
					height: 100%;
					margin: 0 2px;

					> a {
						margin-top: 0;
						outline: none;

						&:not(.g-has-alerts) {
							&:hover,
							&:focus {
								background: none;
							}
						}
					}
				}
			}

			.nav .navigation-buttons .btn {
				width: 73px;
			}
		}
	}

	.g-modal-alert-bar {
		display: none;
		padding: 5px 10px;

		&.alert {
			margin-bottom: 5px;
			border-radius: 0;
		}
	}

	.modal-body .gwFilter-save-form {
		width: @g-min-popup-field-width;
	}

	.ui-resizable-handle.ui-resizable-se {
		width: 13px;
		height: 13px;
	}

	&.no-padding .modal-dialog .modal-body {
		padding: 0;
	}
}

.g-modal .g-heading .navbar .nav > li,
.g-modal-header-buttonpane,
.g-popout-header-buttonpane {
	.dropdown-menu {
		margin-top: 6px;
		right: 0;
		left: auto;
		height: auto;

		&:before {
			right: 9px;
			left: auto;
		}

		&:after {
			right: 10px;
			left: auto;
		}

		a:hover {
			opacity: 0.5;
		}
	}

	.g-tasktray-alerts {
		.dropdown-menu {
			padding: 5px;

			li.alert {
				cursor: auto;
			}

			li.gwValidationSummary-alert-entity-message:hover {
				a {
					.alert-danger-highlight() !important;
				}

				span {
					.alert-danger-mixins() !important;
					.alert-danger-highlight() !important;
				}
			}
		}
	}
}

.g-modal-header-buttonpane,
.g-popout-header-buttonpane,
.modal-body-wrapper,
.g-heading {
	&.text-only .g-display-option-button,
	&.buttons-only .g-display-option-text {
		display: none;
	}

	a:hover {
		img {
			color: @white;
		}

		img + .g-hover-icon {
			display: inline;
		}
	}

	.g-hover-icon {
		display: none;
		color: @white;
	}
}

.g-modal-header-buttonpane,
.g-popout-header-buttonpane {
	order: 1;
	margin-left: auto;
	margin-bottom: 0;
	display: flex;
	height: 100%;
	flex: 0 0 auto;

	li {
		display: flex;
		align-items: center;
		padding: 0;
		flex: 0 0 auto;

		a {
			display: flex;
			align-items: center;
		}
	}
}

.g-filter-dialog {
	.gwFilter,
	.gwFilter-groups-wrapper {
		height: 100%;

		.g-filter-button-container {
			width: @g-filter-button-container-width-popup;
		}
	}

	.gwFilter-container {
		height: 100%;
	}

	.gwFilter-actions .g-kg-title-sub-container {
		width: @g-filter-sub-container-dialog-width;
		margin-right: @g-dialog-filter-actions-margin;
	}

	.modal-dialog.g-modal-autoresize .modal-content {
		height: 500px;
	}
}

.g-change-password {
	width: @g-min-popup-field-width;
	margin: 0;

	input[type="password"] {
		height: @g-control-height;
	}
}

.g-error-reporter {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;

	label {
		white-space: normal;
		flex: 0 0 auto;
		width: 100%;
		font-size: 14px;
		line-height: 16px;
		color: @Theme_GeneralText;
	}

	textarea {
		width: 100%;
		flex: 1 1 auto;
		padding: 15px 5px;
		margin-top: 6px;
		resize: none;
	}
}

.bulk-allocation-dialog .modal-dialog.g-modal-autoresize {
	.modal-content {
		width: 100%;
		height: 100%;
		max-width: 730px;
		max-height: 500px;
	}
}

.bulk-allocation-dialog-results {
	position: absolute;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.bulk-allocation-dialog-log-warning {
	font-weight: bold;
	color: @state-warning-text;
}

.bulk-allocation-dialog-log-error {
	font-weight: bold;
	color: @state-error-text;
}

.bulk-allocation-dialog-log-important {
	font-weight: bold;
}

.g-dialog__location-tracker.g-modal {
	.modal-body {
		position: relative;
		padding: 0;
	}

	iframe {
		position: absolute;
		height: 100%;
	}
}

.g-workflow-confirm-dialog.g-modal {
	.modal-dialog .modal-body-with-icon.modal-body {
		padding-top: 0;
		padding-bottom: 0;
	}

	.g-confirmation-question-wrapper {
		min-height: 50px;
		padding-top: 10px;
		padding-bottom: 10px;

		.g-confirmation-retype-message-input {
			margin-top: 10px;

			.gwTextBox-textarea {
				padding-top: 0;
			}
		}

		.g-confirmation-user-message-input {
			margin-top: 10px;

			.gwTextBox-textarea {
				padding-top: 0;
			}
		}
	}
}

.g-about-info-section {
	margin-top: 73px;
	display: flex;
	justify-content: center;
	flex-direction: column;

	@media (min-width : @screen-md) {
		max-width: calc(80vw);
	}

	&.g-about-info-section-for-disclaimer {
		@media (min-width : 600px) and (max-width: @screen-sm) {
			max-width: calc(80vw);
		}

		@media (min-width : @screen-sm) and (max-width: @screen-md) {
			max-width: calc(60vw);
		}

		@media (min-width : @screen-md) {
			max-width: calc(40vw);
		}
	}



	img {
		max-width: 100%;
	}

	.g-about-item {
		display: flex;

		@media (max-width : @screen-sm) {
			flex-direction: column;
		}

		.g-about-title {
			text-align: right;
			color: #20a2dc;
			width: 200px;

			@media (max-width : @screen-sm) {
				text-align: left;
			}
		}

		.g-about-text {
			margin-left: 8px;
			word-break: break-all;
			flex: 1;

			@media (max-width : @screen-sm) {
				margin-left: 0;
			}
		}
	}

	> div + div {
		margin-top: 8px;
	}
}

.g-usage-message {
	text-indent: 3em;
}

.g-dialog-about-logo {
	width: 228px;
}

.g-dialog-default-help .modal-dialog.g-modal-autoresize {
	.modal-body {
		padding: 48px 10px 51px;
	}
}

.g-dialog-configure-help {
	.g-dialog-configure-help-instructions .cleditorToolbar {
		margin-top: 15px;
	}

	.modal-body ul {
		margin-bottom: 0;

		li {
			position: relative;
			padding: 5px 0;
			overflow: hidden;

			&:not(.g-dialog-configure-help-instructions) label {
				position: relative;
				float: left;
			}
		}
	}
}

.modal-content.ui-resizable.ui-draggable {
	min-width: 200px;
}

.g-modal-configurator {
	.modal-content.ui-resizable {
		min-width: 400px !important;
		min-height: 300px !important;
	}

	.modal-body {
		.grid-action-button();
	}
}

.g-modal-theme-configurator .g-modal-body-wrap li {
	height: @g-control-height;

	&:first-child .input-group .form-control {
		.border-top-radius(4px);
	}

	&:first-child .input-group-addon {
		border-top-right-radius: 4px;
	}

	&:last-child .input-group .form-control {
		.border-bottom-radius(4px);
	}

	&:last-child .input-group-addon {
		border-bottom-right-radius: 4px;
	}
}

.g-modal-theme-configurator {
	&.g-modal .modal-dialog .modal-body {
		padding: 0;
		border-radius: 0;
	}

	.g-modal-body-wrap {
		height: calc(~"100%" - @g-message-bar-height);
		padding: 15px;
	}

	.g-configure-item-body-wrap {
		position: relative;
		height: 100%;
	}

	.g-configure-theme__container {
		position: relative;
	}

	.g-configure-theme__overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		z-index: 6;
		border-radius: 4px;
		background: rgba(246, 246, 246, 0.35);

		.icon-spin {
			font-size: 24px;
			align-self: center;
		}
	}

	.g-configure-theme__top-container {
		display: flex;
		padding: 3px 0;
	}

	.g-configure-theme__title-container {
		display: inline-block;
		font-weight: bold;
		font-size: 15px;
		color: @Theme_DefaultButton;
	}

	.g-configure-theme__title-sub-container {
		display: flex;
		flex: 1 0 auto;
		justify-content: flex-end;
	}

	.g-configure-theme__button {
		cursor: pointer;
		color: @Theme_Icons;
		display: flex;
		align-items: center;

		span {
			margin-left: 5px;
		}

		&.disabled {
			opacity: 0.5;
		}
	}

	.gwSelectBox-container {
		position: relative;
		height: @g-control-height;
		margin-bottom: 10px;
	}
}

.g-modal-body-wrap {
	height: 100%;

	> .g-modal-loading-indicator {
		height: 100%;
	}

	.list-unstyled li {
		display: flex;

		&.configure-staff-help {
			justify-content: space-between;
		}

		&.configure-customer-help {
			justify-content: flex-end;
		}
	}
}

.g-modal-configurator-content {
	position: relative;
	height: 100%;
}

.tox .tox-dialog__title,
.popover .popover-title {
	color: @Theme_ValueText;
}

li a.g-restore-button-visible i.g-maximize,
li a:not(.g-restore-button-visible) i.g-restore {
	display: none;
}

.g-maximized-dialog {
	width: 100% !important;
	height: 100% !important;
}

.g-maximized-resized-dialog {
	top: 50px !important;
	left: 50px !important;
	width: ~"calc(100% - 100px)" !important;
	height: ~"calc(100% - 100px)" !important;
}

.g-maximized-dragged-dialog {
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.modal-content.ui-resizable.ui-state-disabled {
	opacity: 1;
}

div.tox .tox-dialog__title {
	max-height: @g-modal-title-max-height;
	line-height: @g-modal-header-line-height;
}

div.tox .tox-dialog__title,
.popover .popover-title {
	display: @g-modal-title-display;
	font-size: @g-title-area-font-size;
	font-weight: 500;
	overflow: hidden;
	white-space: @modal-title-white-space;
	text-overflow: ellipsis;
}

.g-modal .modal-dialog .modal-footer .btn,
.tox-dialog__footer .tox-button--secondary {
	.g-button-background(transparent, @Button_BackgroundHoverColorStart, @Button_BackgroundPressedColorStart, @Button_TextColor, @Button_TextColor_Lighten10, @Button_TextHoverColor);
}

.g-modal .modal-dialog .modal-footer .btn-primary,
.tox-dialog__footer .tox-button {
	.g-button-background(@PrimaryButton_BackgroundColorStart, @PrimaryButton_BackgroundHoverColorStart, @PrimaryButton_BackgroundPressedColorStart, @PrimaryButton_TextColor, @PrimaryButton_TextColor_Lighten10, @PrimaryButton_TextHoverColor);
}

.g-modal-open {
	position: fixed;
	pointer-events: none;
}

[data-modal-active=true] {
	pointer-events: auto;
}

.modal-open {
	overflow: hidden;
}

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}

.modal-in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-dialog {
	position: relative;
}

.modal-content {
	position: relative;
	background-clip: padding-box;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}

.modal-backdrop.fade {
	filter: alpha(opacity=0);
	opacity: 0;
}

.modal-backdrop.in {
	filter: alpha(opacity=50);
	opacity: 0.5;
}

.modal-footer .btn + .btn {
	margin-bottom: 0;
	margin-left: 5px;
}

