.item-tracking-shell {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	h3 {
		margin-top: 0;
		font-size: 15px;
		color: @Theme_DefaultButton;
		display: inline;
	}
}

.item-tracking-query {
	width: 100%;
	max-width: 1450px;
	height: auto;
	padding: 10px;
}

.item-tracking-control-wrapper {
	max-width: 725px;
}

.item-tracking-map-wrapper {
	width: 100%;
	max-width: 1450px;
	height: 300px;
	padding: 10px;
}

.item-tracking-map {
	height: 100% !important;
	width: 100%;
}

.item-tracking-search-list {
	flex-grow: 1;
	width: 100%;
	max-width: 1450px;
	padding: 10px;

	> div {
		height: 100%;
	}
}

.g-dialog-track {
	.item-tracking-map-wrapper {
		width: 1220px;
	}

	.item-tracking-search-list {
		width: 1220px;
	}
}
