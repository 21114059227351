.g-tab {
	padding-top: 2px;
	color: @Header_TextColor;
	.opacity-hover;
	position: relative;

	&:after {
		.g-stretch-to-parent-container-absolute();
		content: "";
		.transition(0.12s, ease-out);
		border-bottom: 2px solid transparent;
		bottom: -@focus-border-width;
		pointer-events: none;
	}

	&.active {
		&:after {
			border-bottom: 2px solid @Theme_ValueText;
		}
	}
}
