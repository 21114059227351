#g-board-refresh-timer-menu-button-container {
	cursor: default;
	pointer-events: none;
	opacity: 0.6;

	#g-board-refresh-timer-menu-button {
		height: 30px;
		padding: 0 10px;
		display: flex;
		align-items: center;

		.g-display-option-text {
			margin-left: 8px;
			margin-right: 2px;
		}

		i {
			opacity: 0.8;
		}
	}
}

.g-board-refresh-hidden-refresh{
	pointer-events: none;
	visibility: hidden;
	position:absolute;
}
