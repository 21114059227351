.gwTrackingPort {
	display: flex;
	flex-direction: column;
}

.gwTrackingPort__info {
	display: flex;
}

.gwTrackingPort__name {
	width: 100%;
	.text-overflow();
}

.gwTrackingPort__unloco {
	padding-left: 5px;
	font-weight: bold;
	font-size: 20px;
}

.gwTrackingPort__country {
	opacity: 0.4;
}
