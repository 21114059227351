.gwDatePicker {
	display: inline-block;

	input[readonly]:not(.g-read-restricted) {
		.placeholder(transparent);
	}

	&:not(.dateclockpicker-readonly) label {
		padding-right: 30px;
	}
}