#clienterror,
#browsernotsupported {
	min-width: 260px;

	.g-heading {
		margin-top: 0;
		margin-bottom: 10px;
		border-bottom: 1px solid @navbar-default-border;

		h1 {
			color: @Body_TextColor;
			font-size: 22px;
			line-height: 26px;
		}
	}

	.g-content {
		color: @Body_TextColor;
		position: absolute;
		top: @clienterror-g-content-top;
		left: @clienterror-g-content-left;
		height: @clienterror-g-content-height;
		width: @clienterror-g-content-width;
		margin: @clienterror-g-content-margin;

		> ul {
			width: 100%;
			height: 100%;
			margin: 0;

			i {
				display: initial;
				margin-right: 5px;
				font-size: 24px;
				color: @Theme_DefaultButton;
			}

			> li {
				list-style-type: none;
			}
		}

		.well {
			text-align: left;
			margin-top: 20px;
			background-color: @Body_BackgroundColorStart;

			ul {
				li {
					margin-top: 5px;
				}

				&.g-browserlinks {
					margin-top: 0;
					margin-left: 0;
				}
			}
		}

		h3 {
			display: initial;
			line-height: 24px;
			color: @Theme_DefaultButton;
		}
	}
}
