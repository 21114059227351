.gwTriStateRadioButton {
	height: 100%;
	min-width: 200px;
	position: inherit;
	padding: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&:focus {
		.box-shadow(none);
	}

	.btn-group .btn-tri-state {
		.action-state(@bg-color: @ToggleButton_TurnedOffBackgroundColorStart,
				@bg-hover-color: @Theme_Hover,
				@bg-active-color: @PrimaryButton_BackgroundPressedColorStart,
				@bg-open-color: @ToggleButton_TurnedOnBackgroundColorEnd,
				@text-color: @Button_TextColor,
				@text-color-lighten-10: @Button_TextColor_Lighten10,
				@text-hover-color: @Button_TextHoverColor,
				@text-open-color: @ToggleButton_TurnedOnTextColor,
				@border-radius: 0px,
				@border-style: solid);
		width: 50px;
		@gwTriStateRadioButton-button-height: @g-control-height - 16px;
		height: @gwTriStateRadioButton-button-height;
		padding: 0 0 2px;
		border-radius: 5px;
		border: 1px solid @ToggleButton_BorderColor;
		text-transform: none;

		&:focus + .btn-tri-state {
			z-index: 1;
		}

		&:first-child:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:last-child:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&:not(:last-child):not(:first-child) {
			border-radius: 0;
		}
	}


	&.captionLeft {
		justify-content: flex-end;

		.btn-group {
			width: 117px;
			height: 100%;
			border-left: solid 1px @InputControl_BorderBrushStart;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 0 0 auto;
		}

		.active:not(.g-uneditable) .btn-group {
			border-left-color: @InputControl_FocusedBorderBrushEnd;
		}

		.gwTriStateRadioButton-label {
			flex: 1 1 auto;
			margin: 0 7px 0 7px;
			text-align: right;
		}

		&.alert-danger .btn-group {
			border-color: @state-error;
		}

		&.alert-warning .btn-group {
			border-color: @state-warning;
		}

		&.alert-message-error .btn-group {
			border-color: @state-message-error;
		}

		&.alert-info .btn-group {
			border-color: @state-info;
		}

		&.alert-danger,
		&.alert-warning,
		&.alert-info,
		&.alert-message-error {
			label.gwTriStateRadioButton-label {
				color: @InputControl_LabelColor;
			}
		}

		&:not(.g-uneditable) {
			background-color: @InputControl_BackgroundColorEnd;
		}

		&.active:not(.g-uneditable) .btn-group {
			border-left-color: @InputControl_FocusedBorderBrushEnd;
		}

		&.alert-danger {
			.alert-danger-mixins();

			&.gwValidation-highlight {
				.alert-danger-highlight();
			}
		}

		&.alert-message-error {
			.alert-message-error-mixins();

			&.gwValidation-highlight {
				.alert-message-error-highlight();
			}
		}

		&.alert-warning {
			.alert-warning-mixins();

			&.gwValidation-highlight {
				.alert-warning-highlight();
			}
		}

		&.alert-info {
			.alert-info-mixins();

			&.gwValidation-highlight {
				.alert-info-highlight();
			}
		}
	}

	&.g-uneditable {
		background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
	}
}

.kgCellInner {
	.gwTriStateRadioButton {
		justify-content: center;
		min-width: unset;
		padding: 0 5px;

		.btn-group {
			display: flex;
			overflow: hidden;

			.btn-tri-state {
				width: 40px;
				height: 20px;
				font-size: 12px;
			}
		}
	}
}
