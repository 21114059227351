.gwGroupBox {
	width: 100%;
	height: 100%;
	margin-bottom: 0;
	background: transparent;
	color: @GroupBox_TextColor;
	border: 1px solid @GroupBox_BorderColor;
	border-radius: @GroupBox_BorderRoundedCorners;
	.box-shadow(none);

	.panel-title {
		.panel-title-details;
		font-size: 15px;
		margin-top: 0;
		margin-bottom: 0;

		> a {
			color: inherit;
		}

		&.Left {
			text-align: left;
		}

		&.Center {
			text-align: center;
		}

		&.Right {
			text-align: right;
		}
	}

	.panel-heading {
		.panel-heading-details;
		border-bottom: 1px solid transparent;

		.panel-title a {
			color: @GroupBox_HeaderTextColor;
		}
	}

	.panel-heading.g-groupbox-panel-heading {
		.panel-title {
			padding-top: @g-panel-line-height-padding-top;
			line-height: @g-panel-line-height;
			margin-left: 0px;
		}
	}

	.panel-body {
		&:before,
		&:after {
			display: table;
			content: " ";
		}

		&:after {
			clear: both;
		}

		height: 100%;
		padding: 0;
		position: relative;
	}

	[data-contents="gwGroupBox"] {
		width: 100%;
		height: 100%;
		padding: 0;
	}

	.panel-title a {
		i {
			display: none;
		}

		&:focus {
			text-decoration: none;
		}
	}

	.collapsible-groupbox-icon(-6px, -6px, 180deg);
}

.g-collapsed
{
	height: auto !important;
}

.gwGroupBox-header-hidden > .panel-body {
	margin-top: 0;
}

.gwGroupBox:not(.gwGroupBox-header-hidden) .panel-body {
	@panel-title-height: @g-panel-line-height-padding-top + @g-panel-line-height + 1;
	height: calc(100% ~'-' @panel-title-height);
}
