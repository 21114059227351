.g-status-indicators {
	position: relative;
	background-clip: padding-box;

	&:before {
		.transition(0.12s, ease-in-out);
		content: "";
		.g-stretch-to-parent-container-absolute();
		z-index: 2;
	}

	&:hover {
		cursor: pointer;

		.g-status-indicator {
			background-color: @color-light-black;

			&:before {
				opacity: 0.9;
			}
		}
	}

	&:active,
	&.popover-shown {
		&:before {
			.element-elevation(-2);
		}
	}
}

.g-status-indicator {
	.transition(0.12s, ease-in-out);
	.user-select(none);
	position: relative;
	width: @status-indicator-size;
	height: @status-indicator-size;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: @status-indicator-size;
	flex-shrink: 0;
	background-color: transparent;
	overflow: hidden;
	-webkit-text-stroke: var(--g-status-indicator-text-stroke);

	--g-status-indicator-text-stroke: initial;
	--g-status-indicator-icon-color: @white;
	--g-status-indicator-bg-color: @white;

	&:before {
		.transition(0.12s, ease-in-out);
		content: "";
		z-index: 0;
		background-color: var(--g-status-indicator-bg-color);
		.g-stretch-to-parent-container-absolute();
	}

	i {
		color: var(--g-status-indicator-icon-color);
		line-height: @status-indicator-icon-size;
		font-size: @status-indicator-icon-size;
		position: relative;
		z-index: 2;
	}
}

.g-status-alert {
	--g-status-indicator-text-stroke: 0.8px darken(@status-indicator-alert-color, 20%);
	--g-status-indicator-bg-color: lighten(@status-indicator-alert-color, 10%);
	.increase-icon-size(@status-indicator-icon-size, 5);
}

.g-status-unallocated {
	--g-status-indicator-bg-color: lighten(@status-indicator-unallocated-color, 10%);
	.increase-icon-size(@status-indicator-icon-size, 2);
	width: auto;

	i {
		width: @status-indicator-size;
		display: flex;
		justify-content: center;
		margin-top: 1px;
	}
}

.g-status-current {
	--g-status-indicator-text-stroke: 0.8px darken(@status-indicator-current-color, 20%);
	--g-status-indicator-bg-color: lighten(@status-indicator-current-color, 10%);
}

.g-status-working {
	--g-status-indicator-text-stroke: 0.8px darken(@status-indicator-working-color, 20%);
	--g-status-indicator-bg-color: lighten(@status-indicator-working-color, 10%);

	i {
		margin-left: 2px;
	}
}

.g-status-idle {
	--g-status-indicator-text-stroke: 0.8px darken(@status-indicator-idle-color, 20%);
	--g-status-indicator-bg-color: lighten(@status-indicator-idle-color, 10%);
	.increase-icon-size(@status-indicator-icon-size, -1);
}

.g-status-suspended {
	--g-status-indicator-text-stroke: 0.8px darken(@status-indicator-suspended-color, 20%);
	--g-status-indicator-bg-color: lighten(@status-indicator-suspended-color, 10%);
}

.g-status-blocked {
	--g-status-indicator-text-stroke: 0.8px darken(@status-indicator-blocked-color, 20%);
	--g-status-indicator-bg-color: lighten(@status-indicator-blocked-color, 10%);
	.increase-icon-size(@status-indicator-icon-size, 2);
}

.g-status-cancelled {
	--g-status-indicator-text-stroke: 0.8px darken(@status-indicator-cancelled-color, 20%);
	--g-status-indicator-bg-color: lighten(@status-indicator-cancelled-color, 10%);
	.increase-icon-size(@status-indicator-icon-size, 2);
}

.g-status-closed,
.g-status-none {
	--g-status-indicator-bg-color: lighten(@status-indicator-closed-color, 10%);
}

.g-status-assigned {
	--g-status-indicator-bg-color: lighten(@status-indicator-assigned-color, 10%);
	--g-status-indicator-icon-color: @status-indicator-closed-color;
}

.g-status-open {
	--g-status-indicator-bg-color: @white;
	--g-status-indicator-icon-color: @status-indicator-working-color;
}

.g-status-away {
	--g-status-indicator-bg-color: @status-indicator-closed-color;

	&:active {
		box-shadow: none;
	}
}

.g-status-loading {
	--g-status-indicator-bg-color: lighten(@status-indicator-closed-color, 10%);
}

.g-status-text {
	.transition(0.07s, ease-in-out);
	padding-left: 8px;
	color: #FFFFFF;
	font-weight: 500;
	line-height: 12px;
	position: relative;
	z-index: 2;
}
