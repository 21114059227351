.g-import-wizard-page .modal-dialog.g-modal-autoresize .modal-body {
	padding: 0;
}

.importTaskPage {
	position: relative;
	width: 100%;
	height: ~"calc(100% - 25px)";
	padding: 0 10px;

	.g-control-wrap {
		input.form-control {
			float: left;
			width: 200px;
			height: 44px;
		}

		.g-import-parameter input.form-control {
			float: none;
		}

		button {
			margin-top: 3px;
		}

		span.error {
			color: @state-error-text;
		}

		.gwGroupBox {
			.panel-body {
				position: relative;
				margin-top: 0;
			}
		}

		&.file-uploader {
			height: ~"calc(100% - 55px)";

			& > .gwGroupBox > .panel-body {
				height: ~"calc(100% - 45px)";

				.gwFileUploader {
					height: 100%;

					.gwFileUploader-results-logs {
						height: ~"calc(100% - 90px)";
						margin-bottom: 0;
					}
				}
			}
		}

		.g-import-parameter {
			.gwTextBox {
				width: 100%;
				float: left;
				margin-top: 2px;

				input {
					width: 100%;
				}
			}
		}
	}

	.g-import-tab {
		height: 100%;
	}

	.file-uploader {
		padding-top: 11px;
	}

	.gwFileUploader {
		width: 100%;
	}

	.gwFileUploader-results {
		height: 100%;
	}

	.gwFileUploader-results-logs {
		width: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}
