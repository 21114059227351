.gwMeasureCalculator {
	width: 259px;

	.list-group {
		padding-top: 53px;
		margin-bottom: 0;
		padding-left: 0;

		a {
			text-align: center;
		}
	}

	a.list-group-item {
		color: @Grid_ViewportTextColor;
		background-color: #fff;
		border: 1px solid @Grid_ViewportBorderColor;
		position: relative;
		display: block;
		padding: 10px 15px;
		margin-bottom: -1px;

		&.active {
			z-index: 2;
			color: @Grid_ViewportTextColor;
			background-color: @Grid_ViewportSelectedRowColor;
			border-color: @Grid_ViewportBorderColor;
		}

		&.invalid {
			background-color: @Grid_ReadonlyViewportBackgroundColor;
			background-image: none;
			cursor: default;

			&:hover {
				color: @Grid_ViewportTextColor;
				background-color: @Grid_ReadonlyViewportBackgroundColor;
			}
		}

		&:hover {
			color: @Theme_HoverText;
			text-decoration: none;
			background-color: @Theme_Hover;
		}

		&:first-child {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		&:last-child {
			margin-bottom: 0;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
		}
	}

	.magnitude {
		width: 200px;
	}

	.unit {
		left: 214px;
		width: 55px;

		label {
			margin-left: 10px;
		}

		input {
			text-align: center;
		}
	}
}
