.gwGroupMessageDialog {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.recipient-area {
	display: flex;
}

/*bootstrap typeahead styling - start*/
.recipient-area .typeahead.dropdown-menu {
	background-color: @Theme_Background;
}

.recipient-area .typeahead.dropdown-menu .active a {
	background-color: @Theme_Hover;
}

.recipient-area.typeahead.dropdown-menu a {
	font-weight: bold;
	color: @Theme_DefaultButton;
}
/*bootstrap typeahead styling - end*/

.recipient-area__label {
	display: inline-block;
	padding: 3px 0 3px 7px;
	color: @Theme_ValueText;
	white-space: nowrap;
}

.recipient-area__label.recipient-area__label--underline a {
	color: @Theme_ValueText;
}

.recipient-area__label--bold {
	font-weight: bold;
}

.recipient-area__label:first-of-type {
	padding-left: 0;
	padding-right: 7px;
}

.recipient-area__label:last-of-type {
	padding-right: 0;
}

.recipient-area__label--underline {
	text-decoration: underline;
}

.recipient-list-inputbox__pill {
	background-color: @recipient-pill-colour;
	border-radius: 25px;
	display: flex;
	align-items: center;
	margin: 2px;
	margin-left: 5px;
}

.recipient-list-inputbox__icon {
	color: darken(@recipient-pill-colour, 40%);
}

.recipient-list-inputbox__name {
	padding-right: 18px;
	padding-left: 18px;
	text-decoration-line: none;
	color: darken(@recipient-pill-colour, 40%);
	font-size: 12px;
}

.recipient-list-inputbox__all-drivers {
	text-decoration-line: underline;
}

.recipient-list-inputbox {
	background-color: @InputControl_BackgroundColorEnd;
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	max-height: 75px;
}

.gwTextBox-textarea.recipient-list-inputbox {
	order: 0;
	padding-top: 3px;
}

.recipient-list-inputbox__inputbox {
	border: none;
	background-color: transparent;
	display: block !important;
	margin-left: 5px;
}

.message-history-area {
	border: @SidebarPanel_BorderColor 1px solid;
	padding: 2px;
	margin-top: 10px;
	background-color: @InputControl_BackgroundColorEnd;
	overflow: auto;
	flex: 1 1;
	border-radius: @modal-border-radius
}

.current-message-area {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.current-message-area__current-message {
	width: 100%;
	resize: none;
}

.sendButton {
	margin: 10px 0px 0px 0px;
}

.recipient-list-inputbox__pill > .icon-times-circle {
	font-size: 18px;
	cursor: pointer;
}

.current-message-area__current-message.g-container .gwTextBox.gwTextBox-textarea.g-widget-border {
	padding-top: 3px;
}
