.g-tasktray-actions {
	ul li {
		&:hover a {
			text-decoration: none;
		}

		&.active,
		&.active:hover,
		&.ui-btn-up-active,
		&.ui-btn-up-active:hover {
			background-color: @link-color;

			&:first-child {
				border-radius: 4px 4px 0 0;
			}

			&:last-child {
				border-radius: 0 0 4px 4px;
				border-bottom: 1px solid rgba(0,0,0,.05);
			}

			a {
				text-shadow: 0 -1px 0 rgba(0,0,0,.2);
			}

			a,
			i {
				color: @white;
			}
		}

		& > div {
			position: relative;

			a {
				position: relative;
				z-index: 1;
				display: block;
				padding: .7em 15px .7em 15px;
				.text-overflow;
				cursor: pointer;
			}

			i {
				position: absolute;
				top: 50%;
				right: 10px;
				margin-top: -7px;
			}
		}
	}
}

.g-row-task-shell {
	position: relative;
	height: 100%;
	margin-right: @g-row-task-shell-margin-right;
	background-color: @Content_BackgroundColorStart;

	h1 {
		float: left;
		margin: 7px 0 0;
		font-size: 22px;
	}

	.g-shell-content {
		margin-bottom: 77px;
		overflow-x: auto;
		.g-stretch-to-parent-container-absolute();

		> .gwShellContainer {
			position: relative;
		}
	}
}

.g-footer-buttons {
	padding: 0;
	margin: 0;
	font-size: 0;

	> div {
		position: relative;
		padding: 0 0 0 4px;
	}

	.btn {
		width: 100%;

		> img {
			vertical-align: sub;
		}
	}

	[type="checkbox"] {
		position: absolute;
		top: -1px;
		right: -2px;
		z-index: 1;
	}
}

.g-sidebar-hidden {
	margin-right: 0 !important;
}

.g-configurable-page .gwMessageBar,
.g-configurable-task .gwMessageBar {
	margin-top: @g-message-bar-margin-top;
}

.g-configurable-task .g-sidebar {
	top: 35px;
}

.g-configurable-task.g-has-flow-steps .g-sidebar {
	top: 61px;
}

.g-task-page .g-sidebar {
	top: @g-sidebar-top - @g-sidebar-top-flow-steps-offset;
}

.g-configurable-page .g-sidebar {
	top: 37px;
}

.g-task-page.g-has-flow-steps .g-sidebar {
	top: @g-sidebar-top;
}

.g-page-shell {
	height: 100%;
	overflow: auto;
	margin: 0;
}

.g-page-padding {
	padding: @g-page-shell-padding;
}

.g-page-overlay .g-page-shell {
	height: @g-page-overlay-shell-height;
}

.g-configurable-page .g-page-shell {
	padding: 0;
}

.g-configurable-page .gwShellContainer {
	padding: @g-page-configurable-shell-padding-top;
}

.g-page-overlay .g-sidebar {
	top: @g-title-bar-height;
}

.g-configurable-page .g-page-shell,
.g-configurable-task .g-page-shell {
	margin-top: @g-page-shell-top - @g-page-shell-top-configurable-offset;
}

.g-sidebar {
	position: absolute;
	right: 1px;
	bottom: @g-sidebar-bottom;
	z-index: 10;

	.g-grip {
		position: absolute;
		top: 50%;
		left: -1px;
		margin-top: -12px;

		li {
			background: @Theme_FieldTitle;
		}
	}
}

.g-sidebar-wrap {
	left: 0 !important;
	width: @g-sidebar-width;
	height: 100%;
	background: @SidebarPanel_BackgroundColor;
	border-top: 1px solid @SidebarPanel_BorderColor;
	border-left: 1px solid @SidebarPanel_BorderColor;
	border-radius: 5px 0 0;
}

.g-sidebar-contents {
	bottom: 64px;
	padding-left: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	.g-stretch-to-parent-container-absolute();

	aside:first-of-type,
	aside:first-of-type .panel-heading {
		border-top-left-radius: 4px;
	}

	.g-related-tasks-group-box {
		height: auto;
	}

	.g-validation-group-box {
		height: auto;
	}

	> ul > li {
		padding-bottom: 5px;
	}

	aside:last-of-type .panel-collapse.in {
		border-bottom: none;
	}

	.gwGroupBox {
		margin-bottom: 0;
		border: none;

		.panel-body {
			position: inherit;
			margin-top: 0;
			color: #000;
			background: @SidebarPanel_BackgroundColor;
		}

		.panel-collapse.in {
			border-bottom: @sidebar-border;
		}

		.collapsible-groupbox-header();
	}
}

.g-sidebar-contents-description {
	display: block;
	padding: 5px;
	margin: 0;
	font-size: 12px;
	color: @Theme_GeneralText;
	white-space: normal;
}

.g-sidebar-contents-description.g-no-additional-info {
	color: @Theme_FieldTitle;
	text-align: center;
}

.g-footer-bar {
	height: @g-footer-bar-height;
	flex: 1 0 @g-footer-bar-height;
	display: flex;
	order: 1;

	.btn + .btn {
		margin-bottom: 0;
		margin-left: 5px;
	}

	.btn {
		.g-button-background(transparent, @Button_BackgroundHoverColorStart, @Button_BackgroundPressedColorStart, @Button_TextColor, @Button_TextColor_Lighten10, @Button_TextHoverColor);
	}

	.btn-primary {
		font-size: 14px;
		.g-button-background(@PrimaryButton_BackgroundColorStart, @PrimaryButton_BackgroundHoverColorStart, @PrimaryButton_BackgroundPressedColorStart, @PrimaryButton_TextColor, @PrimaryButton_TextColor_Lighten10, @PrimaryButton_TextHoverColor);
	}

	.g-footer-content {
		height: @g-footer-bar-height;
		position: fixed;
		z-index: 11;
		width: 100%;
		left: 0;
		.g-footer();
		display: flex;
		justify-content: space-between;

		.g-footer-container {
			display: flex;
			flex: 0 0 auto;
			padding: @g-footer-padding;

			&.open > .dropdown-menu {
				.g-in;
			}

			.g-button-container {
				min-width: 104px;

				div {
					min-width: 100px;
				}
			}

			&.left {
				.g-button-container {
					margin-right: 5px;
				}
			}

			&.right {
				min-width: @g-footer-flex-min-width;
			}

			&.right.collapsed {
				flex-shrink: 1;
			}

			&.collapsed {
				.btn-primary {
					border-radius: @g-footer-border-radius 0 0 @g-footer-border-radius !important;
				}

				.g-divider {
					margin: 0 2px 0 2px;
				}
			}
		}
	}

	.dropdown-menu {
		.g-fade;
		display: block;
		top: auto;
		bottom: @g-footer-bar-height;
		width: @g-footer-dropdown-menu-width;
		border-radius: @g-footer-dropdown-menu-border-radius;
		border-width: @g-footer-dropdown-menu-border-width;
		.box-shadow(@modal-dialog-dropdown-menu-box-shadow);

		> li > span {
			min-width: @g-footer-dropdown-menu-right-min-width;
		}
	}

	.g-footer-button-container {
		&.g-form-actions {
			min-width: 100px;

			div {
				min-width: 100px;
			}

			.btn {
				min-width: 100px;
			}
		}

		&.g-dropdown-arrow {
			margin-left: 0;
			padding: 0;
			color: @Icons_Color;
			min-width: 44px;

			div {
				min-width: 44px;
			}

			.btn {
				min-width: 44px;
			}
		}

		.icon-caret-down {
			min-width: 44px;
		}
	}

	.g-button-container {
		height: 30px;
	}
}

.g-task-dialog2 {
	.modal-dialog .modal-body {
		padding: 0;
	}

	.g-task-page {
		height: calc(~'100% - 44px');
	}

	.g-footer-bar .g-footer-content {
		position: static;
		border-radius: 0 0 6px 6px;
	}

	.modal-dialog .modal-footer {
		display: none !important;
	}

	.g-sidebar {
		top: 37px;
	}

	.g-header-menu {
		flex-grow: 1;

		.g-modal-header-buttonpane {
			flex-grow: 1;
			justify-content: flex-end;
		}
		/* Force override mixin .action-state set in mixins.less */
		li > a.g-action-state-header:not(.disabled):not([disabled]):hover {
			i, span {
				color: @Icons_Color !important;
			}
		}
	}
}

.g-material-design .g-app-container .g-page-shell {
	overflow-y: hidden;
}
