@unitButtonWidth: 18px;

.kgCanvas .kgRow .kgCell {
	.gwMeasure.gwDataGridMeasure {
		label {
			display: none;
		}

		&:not(.g-measure-focused) {
			&.alert-danger {
				.alert-danger-mixins();
				border-style: solid;
			}

			&.alert-message-error {
				.alert-message-error-mixins();
				border-style: solid;
			}

			&.alert-warning {
				.alert-warning-mixins();
				border-style: solid;
			}

			&.alert-info {
				.alert-info-mixins();
				border-style: solid;
			}
		}

		&.g-measure-focused {
			background: @InputControl_FocusedBackgroundColorEnd !important;
			border: 1px solid @InputControl_FocusedBorderBrushEnd;
		}

		button.g-measure-unit {
			z-index: 1000;
			height: 100%;
			left: 0;
		}

		input.g-measure-magnitude {
			border-width: @input-border-width 0 @input-border-width @input-border-width;
		}

		input.g-measure-unit {
			border-width: @input-border-width @input-border-width @input-border-width 0;
		}
	}

	.g-measure-unit-length-1 {
		@unitTextboxWidth: 30px;

		input.g-measure-unit {
			width: @unitTextboxWidth;
		}
	}

	.g-measure-unit-length-2 {
		@unitTextboxWidth: 44px;

		input.g-measure-unit {
			width: @unitTextboxWidth;
		}
	}

	.g-measure-unit-length-3 {
		@unitTextboxWidth: 58px;

		input.g-measure-unit {
			width: @unitTextboxWidth;
		}
	}
}
