.gwFileUploader {
	ul .btn {
		padding: 0 5px;
	}

	> ul:first-child > li {
		margin: 10px 0;
		border-bottom: 1px solid #ccc;

		&:last-child {
			border-bottom: none;
		}
	}

	.fileinput-wrapper {
		position: relative;
	}
}

.gwFileUploader-buttonbar .btn span:before {
	content: ' ';
}

.gwFileUploader-log-warning {
	font-weight: bold;
	color: @state-warning-text;
}

.gwFileUploader-log-error {
	font-weight: bold;
	color: #a3131c;
}

.gwFileUploader-log-important {
	font-weight: bold;
	color: #003478;
}

.gwFileUploader-log-success {
	font-weight: bold;
	color: #01a7e1;
}

.gwFileUploader-results {
	.file-upload-info {
		padding: 8px 8px 8px;
		background-color: #f6f6f6;
		border: solid 1px #e6e8e9;
		border-radius: 5px;
	}

	.progress {
		margin-bottom: 6px;

		span {
			position: absolute;
			display: block;
			width: 100%;
			color: #000;
		}
	}

	.upload-outcome {
		text-align: center;
	}
}

.gwFileUploaderInput {
	padding-right: 90px;
}
