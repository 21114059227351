.koGrid {
	width: 100%;
	height: 100%;
	line-height: normal;
	border-radius: 5px;
	border: 1px solid @Grid_ViewportBorderColor;
	background-color: @Grid_ViewportAlternatingRowColor;
	overflow: hidden;

	&[readonly="readonly"] {
		.kgViewport {
			background-color: @Grid_ReadonlyViewportAlternatingColor;
		}

		.kgRow--odd {
			background-color: @Grid_ReadonlyViewportAlternatingColor;
		}

		.kgRow--even {
			background-color: @Grid_ReadonlyViewportBackgroundColor;
		}
	}

	&:not([readonly="readonly"]) {
		.kgRow:not(.kgRow--hovered):not(.kgRow--selected):not(.colorScheme--applied):not(.kgRow--drag-over-top):not(.kgRow--drag-over-bottom) {
			.g-uneditable {
				background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
			}
		}
	}

	&.gw-drag-over {
		opacity: 0.5;
	}
}

.kgRow:not(.kgRow--hovered):not(.kgRow--selected).colorScheme--applied {
	.g-uneditable {
		color: inherit;
	}
}

.kgViewport {
	font-weight: @Grid_ViewportFontWeight;
	color: @Grid_ViewportTextColor;
	.g-font-size-px(@Grid_ViewportFontSize);

	.g-grid-action-button {
		padding: @gwDataGrid-command-col-kgCellText-button-padding-top 6px (@gwDataGrid-command-col-kgCellText-button-padding-top - 1);
		color: @Grid_ViewportTextColor;
	}
}

.kgRow {
	border-color: @Grid_ViewportBorderColor;
}

.kgRow--odd {
	background-color: @Grid_ViewportAlternatingRowColor;
}

.kgRow--even {
	background-color: @Grid_ViewportRowColor;
}

.kgRow.add-row {
	.g-grid-action-button {
		padding: 4px 6px 6px;
	}
}

.kgCell.g-uneditable {
	border-bottom-color: @Grid_ViewportBorderColor;
}

.kgCellInner,
.kgCellText {
	&.alert-danger {
		.alert-danger-mixins();

		&.gwValidation-highlight,
		input[type="tel"].gwValidation-highlight.alert-danger,
		input[type="text"].gwValidation-highlight.alert-danger,
		.select2-container.gwValidation-highlight.alert-danger .select2-selection,
		.gwSearchBox.gwValidation-highlight.alert-danger input[type="text"] {
			.alert-danger-highlight();
		}
	}

	&.alert-message-error {
		.alert-message-error-mixins();

		&.gwValidation-highlight,
		input[type="tel"].gwValidation-highlight.alert-message-error,
		input[type="text"].gwValidation-highlight.alert-message-error,
		.select2-container.gwValidation-highlight.alert-message-error .select2-selection,
		.gwSearchBox.gwValidation-highlight.alert-message-error input[type="text"] {
			.alert-message-error-highlight();
		}
	}

	&.alert-warning {
		.alert-warning-mixins();

		&.gwValidation-highlight,
		input[type="tel"].gwValidation-highlight.alert-warning,
		input[type="text"].gwValidation-highlight.alert-warning,
		.select2-container.gwValidation-highlight.alert-warning .select2-selection,
		.gwSearchBox.gwValidation-highlight.alert-warning input[type="text"] {
			.alert-warning-highlight();
		}
	}

	&.alert-info {
		.alert-info-mixins();

		&.gwValidation-highlight,
		input[type="tel"].gwValidation-highlight.alert-info,
		input[type="text"].gwValidation-highlight.alert-info,
		.select2-container.gwValidation-highlight.alert-info .select2-selection,
		.gwSearchBox.gwValidation-highlight.alert-info input[type="text"] {
			.alert-info-highlight();
		}
	}
}

.kgRow:not(.kgRow--selected) .g-uneditable {
	cursor: pointer;
}

.kgRow--selected {
	background: @Grid_ViewportSelectedRowColor !important;

	.select2-selection,
	input[type="text"], input[type="tel"],
	.gwCheckBox,
	.gwMeasure,
	button {
		background: transparent !important;
	}
}

.kgRow.g-sibling-selecting,
.kgRow--hovered {
	color: @Grid_ViewportTextColor !important;
	background-color: @Grid_ViewportHoverRowColor !important;
}

.kgTopPanel {
	.g-font-size-px(@Grid_HeaderFontSize);
}

.kgHeaderContainer {
	border-color: @Grid_HeaderBorderColor;
}

.kgHeaderCell {
	color: @Grid_HeaderTextColor;

	a,
	button {
		color: @Grid_HeaderTextColor;
	}
}

.kgSortButtonDown,
.kgSortButtonUp {
	border-color: @Grid_HeaderTextColor transparent;
}

.kgHeaderText,
.kgHeaderTextContainer {
	padding: 4px 5px 5px 5px;
}

.kgHeaderTextContainer .kgHeaderText {
	padding: 0;
}

.kgHeaderCell {
	border-color: @Grid_HeaderBorderColor;
}

.kgHeaderContainer {
	font-weight: @Grid_HeaderFontWeight;
	background-color: @Grid_HeaderBackgroundColorStart;
}

.kgFixedViewport {
	border-right: 1px solid @Grid_HeaderBorderColor;
}

.kgGroupPanel--drag-over {
	background-color: @Grid_AggregateHeaderBackgroundColor;
}

.kgGroupPlaceholder {
	margin-left: 11px;
	margin-right: 11px;
}

.kgGroupItem {
	&:hover {
		cursor: pointer;
	}
}

.kgGroupName {
	background-color: @Grid_GroupTabBackgroundColor;
	border-radius: 5px;
	border: none;
	margin-top: 2px;
	padding: 3px 10px 4px;
	font-weight: normal;
	font-size: 10px;
	color: @Grid_HeaderTextColor;
}

.kgRemoveGroup {
	opacity: 1;
	margin-top: 2px;

	span& {
		font-size: 10px;
	}
}

.kgGroupArrow {
	width: auto;
	border: none;
	margin-top: 5px;
	margin-left: 11px;
	margin-right: 11px;
	float: none;
	color: @Grid_GroupArrowColor;
}

.kgGroup {
	background-color: transparent;
	display: flex;
	font-weight: bold;

	&:hover {
		background-color: @Grid_ViewportHoverRowColor;
	}
}

.kgGroupText {
	position: static;
	line-height: normal;
	padding-top: 4px;
}

[class^="icon-"], [class*=" icon-"] {
	&.kgGroupArrowExpanded, &.kgGroupArrowCollapsed {
		position: static;
		border-style: none;
		font-size: 25px;
		font-weight: bold;
		line-height: 0.5;
		cursor: pointer;
		margin: 0;
		height: 100%;
	}
}

.kgGroupArrowExpanded {
	padding: 5px 15px 5px 4px;
	width: 24px;
}

.kgGroupArrowCollapsed {
	padding: 5px 15px 5px 9px;
	width: 23px;
}

.kgGroupPanelDescription {
	margin-top: 3px;
	margin-right: 11px;
	font-style: italic;
	color: @Grid_ViewportTextColor;
}

.g-kg-top-container {
	display: flex;
	width: 100%;
	line-height: @g-kg-top-container-line-height;

	.g-kg-searchInputGroup {
		position: relative;
		margin-right: 3px;
		flex: 0 1 130px;

		.g-kg-searchInput {
			padding: 0 12px;
		}

		.g-kg-searchInput-button {
			color: @Theme_ValueText;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			width: 30px;
			z-index: 5;
			text-align: center;
			vertical-align: middle;
			height: 34px;
			line-height: 34px;
			margin: 1px 0;
		}
	}
}

.g-search-results {
	.g-kg-title-container {
		display: @g-kg-title-container-display;
		flex-flow: row wrap;

		&.Center {
			justify-content: center;
		}

		.g-kg-item-name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding-right: 3px;
		}

		.g-kg-item-count-container {
			margin: @g-kg-item-count-container-margin;
			margin-right: 3px;
		}
	}
}

.g-kg-title-container {
	display: inline-block;
	flex: 0 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;

	&.Center {
		width: 100%;
		text-align: center;
	}

	.g-kg-item-name {
		font-weight: bold;
		.g-font-size-px(@Grid_TitleFontSize);
		color: @Grid_TitleFontColor;
		white-space: nowrap;
	}

	.g-kg-item-count-container {
		display: @g-kg-item-count-container-display;
	}

	.g-kg-item-count {
		.g-font-size-px(@Grid_ItemCountFontSize);
		color: @Grid_ItemCountFontColor;
	}
}

.g-kg-title-sub-container {
	display: flex;
	flex: 1 0 auto;
	height: 100%;
	float: right;
	overflow: hidden;
}

.g-kg-actions-container {
	.g-actions-buttons-container;
	display: flex;
	flex: 1 0 auto;
	flex-flow: row-reverse nowrap;
	align-items: center;

	&.hide-pinned-items {
		.gwPinnedItems {
			display: none;
		}
	}

	.gwPinnedItems {
		display: flex;
		flex: 1 0 auto;
		justify-content: flex-end;
		height: 100%;
		overflow: hidden;

		.gwPinnedItem-overflow {
			.g-kg-action;
		}
	}

	.g-kg-action {
		display: flex;
		flex: 0 0 auto;
		text-align: right;
		color: @Theme_Icons;
		height: 100%;
		margin-left: 14px;
		line-height: normal;
		font-size: 12px;

		a {
			color: @Theme_Icons;
			display: flex;
			align-items: center;

			&:hover {
				color: @Theme_Icons;
				cursor: pointer;

				span,
				i {
					opacity: 0.5;
				}
			}

			&:focus {
				text-decoration: none;
			}

			&:active {
				span,
				i {
					opacity: 0.8;
				}
			}
		}

		button {
			border: none;
			background: none;
			outline: none;
			padding: 0;
			color: @Theme_Icons;
			text-transform: none;

			&:hover {
				color: @Theme_Icons;
				cursor: pointer;
				opacity: 0.5;
			}

			&:active:focus {
				background: none;
				color: @Theme_Icons;
			}

			&.g-btn-loading {
				> span {
					display: none;
				}
			}
		}

		.g-display-option-button {
			float: left;
			line-height: 22px;
		}

		.g-display-option-text {
			float: left;
			line-height: 22px;
			margin-left: 5px;
			white-space: nowrap;
			font-weight: @g-button-text-font-weight;
		}

		&.text-only .g-display-option-button,
		&.buttons-only .g-display-option-text {
			display: none;
		}

		&.buttons-only .g-display-option-button {
			min-width: 14px;
			text-align: center;
		}
	}
}

.kgCell {
	border-color: @Grid_ViewportBorderColor;

	input[type="text"],
	input[type="tel"] {
		width: 100%;
		height: 100%;
		padding: 0 7px 1px 7px;
		margin: 0;
		border-color: transparent;
		border-radius: 0;
		.box-shadow(none);
		.box-sizing(border-box);
	}

	.gwNumeric,
	.gwSearchBox,
	.gwTextBox {
		height: 100%;
	}

	.gwDatePicker {
		input[type="text"] {
			padding-right: 25px;
		}
	}

	.gwMeasure {
		border-color: transparent;
	}

	.gwSearchBox-description {
		width: 100%;
		padding-top: 0;
	}

	.gwSearchBox-description-wrap {
		padding-top: 4px;
	}

	.gwSearchBox-description-spacer {
		min-width: 30px;
		padding-right: 8px;
	}

	.gwSearchBox-description-divider {
		height: @unitDividerHeight;
		padding-top: 0;
		margin: @gwSearchBox-description-divider-margin;
		border-right: 1px solid @grey;
	}

	.gwSearchBox-description-text {
		display: block;
		max-height: none;
		min-width: 18px;
		padding-left: 8px;
		line-height: @gwSearchBox-description-line-height;
		.text-ellipsis();
		.g-font-size-px(@Grid_ViewportFontSize);
	}

	.gwSearchBox-text {
		padding-right: 30px !important;
	}

	.form-control {
		border-radius: 0 !important;
		.g-font-size-px(@Grid_ViewportFontSize);
		.text-ellipsis();
	}

	input.form-control[readonly]:focus:not(.g-readonly-override) {
		border-color: transparent !important;
	}

	.input-group-addon {
		line-height: @gwDataGrid-row-height-without-border - 5;

		i {
			display: inline;
		}
	}

	.select2 {
		.select2-selection {
			background: none;
			border-color: transparent;
			border-radius: 0;
			.g-font-size-px(@Grid_ViewportFontSize);

			.select2-selection__rendered {
				padding: 0 29px 0 7px;
				line-height: @gwDataGrid-row-height-without-border - 3;
			}
		}
	}
}

.kgCell.g-text-center {
	text-align: center;
}

.kgHeaderCell {
	.icon-caret-up,
	.icon-caret-down {
		padding: 0 2px 0 3px;
		float: right;
	}
}

.kgHeaderTextHover:hover {
	background-color: @Theme_Hover;
}

.kgCellText {
	padding: @gwDataGrid-grid-text-vertical-padding 8px;
}

.kgCellInner {
	> .g-container {
		height: 100%;
	}
}

.kgRemoveGroupText {
	display: none;
}

.kgRow--drag-over-top {
	box-shadow: 0 2px 0 0 #00A8E1 inset;
}

.kgRow--drag-over-bottom {
	box-shadow: 0 -2px 0 0 #00A8E1 inset;
}

.g-kg__filter-tag {
	padding: 0 3px;
	margin: 3px 0;
	border-radius: @Tiles_RoundedCorners;
	box-shadow: #aaa 2px 2px 4px 1px;

	.g-display-option-button {
		padding: 0 2px;
		margin-left: 5px;
		cursor: pointer;
	}
}
