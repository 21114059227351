.gwTruckHeaderDisplayer {
	.indicator {
		display: inline-block;
		width: 40px;
		height: 50px;
		font-size: 15px;
		text-align: center;
		padding-top: 33px;
		position: relative;
		margin: 0 5px;
		text-transform: uppercase;
	}

	.indicator::before {
		content: '';
		position: absolute;
		top: 5px;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border: 7px solid @state-error;
		border-radius: 7px;
	}

	.indicator.on::before {
		border-color: @state-success;
	}

	.time {
		display: inline-block;
		font-size: 18px;
		margin: 15px 10px 0 10px;
	}
}
