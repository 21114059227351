.gwDataGrid {
	position: relative;
	width: 100%;
	height: 100%;
	.grid-action-button();

	.add-row {
		height: @gwDataGrid-row-height-without-border;
		border-right: 1px solid @btn-default-border;

		&:hover {
			background-color: @Grid_ViewportHoverRowColor;
		}

		button {
			padding-top: 5px;
			padding-left: 7px;

			img {
				color: @Theme_Icons;
			}
		}

		.g-grid-actions {
			height: 100%;
		}
	}

	.kgHeaderContainer {
		.g-grid-action-button {
			padding: 4px 6px;
		}
	}

	.kgRow {
		background-color: @Grid_ViewportAlternatingRowColor;
	}

	[readonly='readonly'] {
		.kgRow {
			background-color: @Grid_ReadonlyViewportAlternatingColor;
		}
	}

	.label-danger {
		background-color: #d9534f;
	}

	.label-danger[href]:hover,
	.label-danger[href]:focus {
		background-color: #c9302c;
	}
}

.kgCell:not(.g-uneditable):hover .gwFastCheckBox--hoverable::before {
	border-color: @Checkbox_BorderHoverColor;
}

.gwDataGrid-message-toast {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	margin-top: -20px;

	.alert {
		text-align: center;
	}

	.alert-success {
		border-color: @state-success-text;
		.box-shadow(0 1px 15px @state-success-text);
	}

	.alert-danger {
		border-color: @state-error-text;
		.box-shadow(0 1px 15px @state-error-text);
	}

	.alert-info {
		border-color: @state-info-text;
		.box-shadow(0 1px 15px @state-info-text);
	}
}

.gwDataGrid-inner-wrap {
	display: flex;
	height: @g-control-height;

	.g-kg-top-container {
		align-self: flex-end;
	}
}

.gwDataGrid-wrapper {
	.g-stretch-to-parent-container-absolute();
	top: @g-control-height;

	.gwDataGrid-load-error {
		display: none;
	}

	&.gwDataGrid-no-title {
		top: 0px;
	}
}

.gwDataGrid-data-error .gwDataGrid-load-error {
	z-index: 1005;
	display: block;
	margin: -42px 0 0 0;
	font-size: 16px;
	text-align: center;
	.g-stretch-to-parent-container-absolute();

	> div {
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}

.gwDataGrid-access-error {
	width: 100%;
	height: 100%;
	border: 1px solid @state-warning;
	border-radius: 5px;

	.g-content {
		display: table;
		margin: auto;
		top: 46px;
		position: relative;

		i {
			display: initial;
			margin-right: 5px;
			font-size: 24px;
			color: @Theme_DefaultButton;
		}

		> li {
			list-style-type: none;
		}

		h3 {
			display: initial;
			line-height: 24px;
			color: @Theme_DefaultButton;
		}
	}
}

.gwDataGrid-loading-message {
	display: none;

	.g-loading-animation .icon-spinner {
		font-size: 30px;
		margin: 0;
		color: @Theme_Icons;
	}

	position: absolute;
	top: 94px;
	left: 50%;
	z-index: 2;
	display: block;
	height: 30px;

	&.alert {
		margin: 0;
		padding: 0;
	}
}

.gwDataGrid-loading {
	.opacity(.5);
}

.gwDataGrid-cell-text-popover {
	border: 1px solid @InputControl_FocusedBorderBrushEnd;
	border-radius: @InputControl_BorderRoundedCorners;
	.box-shadow(0 4px 5px rgba(0, 0, 0, .15));
	max-height: 200px;
	padding: 5px;
	overflow: auto;

	&.popover.right {
		margin-left: 0px;
	}

	.arrow,
	.popover-title {
		display: none;
	}

	.popover-content {
		white-space: pre-wrap;
		overflow-wrap: break-word;
	}
}