.flag-icon.flag-icon-ad {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ad.BikIfkvhBhtY5_TluJrG7hOBBfg.png');
}

.flag-icon.flag-icon-ae {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ae.w_OTw5p1IZVMkho6pMh6x4eO5zQ.png');
}

.flag-icon.flag-icon-af {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/af.hzvqlKG4k3HRVxfWhzXEZHuYyzE.png');
}

.flag-icon.flag-icon-ag {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ag.E6yj6YyV0QTk5EOesJsOMAVFcp4.png');
}

.flag-icon.flag-icon-ai {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ai.zRfQrSCs629zaSF4J4q3P16R8Hw.png');
}

.flag-icon.flag-icon-al {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/al.Pup3LtNzRfSE8EYKx_Anpre74AE.png');
}

.flag-icon.flag-icon-am {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/am.3l7lcFsiwf2b_j6SiS3KO3Fkd8w.png');
}

.flag-icon.flag-icon-ao {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ao.kEpCbqzHPkeuhIhuDirOPEXebao.png');
}

.flag-icon.flag-icon-aq {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/aq.WgtlpA0v4hgDKrx29fEp-9pIjHU.png');
}

.flag-icon.flag-icon-ar {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ar.A1zEItPYUO8B_OyGKw1YQnocJ5c.png');
}

.flag-icon.flag-icon-as {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/as.GkSReEN3WZ62mYzdQkpIUDp4maU.png');
}

.flag-icon.flag-icon-at {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/at.24MikLQ-qmJoYjHDjcvryFtBb8U.png');
}

.flag-icon.flag-icon-au {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/au.4oAhbli0_Q3XJkG-ZmycZwr9YxA.png');
}

.flag-icon.flag-icon-aw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/aw.f4Sa_W30tbaIhZN2Ht4r8DMrqXg.png');
}

.flag-icon.flag-icon-ax {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ax.GmczBf9bffJAWvB8xZBerz-4GQQ.png');
}

.flag-icon.flag-icon-az {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/az.vw5SdWLpk4txz33o5mCdZ2OAMNA.png');
}

.flag-icon.flag-icon-ba {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ba.dO1WLbf3tthkPUjjOcjzQFiSj_k.png');
}

.flag-icon.flag-icon-bb {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bb.wd3dDitsapIAgJ8Jdn-woMxppL4.png');
}

.flag-icon.flag-icon-bd {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bd.s0bRbJEi7YQYweNxTcUr-4C60sE.png');
}

.flag-icon.flag-icon-be {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/be.qxe4qB4Pa72z6Vyp96Ah_u4oOaE.png');
}

.flag-icon.flag-icon-bf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bf.24M26Di6vWd_PwPfBPEpNwkW_Xw.png');
}

.flag-icon.flag-icon-bg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bg.dLLGH6dLZuLm_fZv8bh8U_REgDM.png');
}

.flag-icon.flag-icon-bh {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bh.1ltx05FoZCefk2Nj87VGMTTIh9E.png');
}

.flag-icon.flag-icon-bi {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bi.irUqDWUU8tidwDwIcQ6EB6LsF_E.png');
}

.flag-icon.flag-icon-bj {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bj.QDzWv25FsqXUlJAON6Anx_PcwLE.png');
}

.flag-icon.flag-icon-bl {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bl.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-bm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bm.eznjgdnJf_mWSXShvcrQ-Gd-xNk.png');
}

.flag-icon.flag-icon-bn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bn.WQAW6TmbCnee4v5EoW4Pk0sI60Y.png');
}

.flag-icon.flag-icon-bo {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bo.VeUQzR4-vxgPgATTLeb5W4CRroA.png');
}

.flag-icon.flag-icon-bq {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bq.WeM4S7cbNIC_9nuNMqvzcIZKVqY.png');
}

.flag-icon.flag-icon-br {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/br.spHrG7kMIwbHOT8-sQ0BWMttYHk.png');
}

.flag-icon.flag-icon-bs {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bs.tOZ_hwOXFHkN3QmTKTdPFRT-FsM.png');
}

.flag-icon.flag-icon-bt {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bt.8Fnjl5kVyiJd_1ACx0_cXF7NvUc.png');
}

.flag-icon.flag-icon-bv {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bv.fg43BhIeoLA2512YaEMhFENOpLI.png');
}

.flag-icon.flag-icon-bw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bw.Do-dTURyHjp2vBNid32jJ25z8qY.png');
}

.flag-icon.flag-icon-by {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/by.ZBxill3hiUsVs4GNRIrPEPIPmmc.png');
}

.flag-icon.flag-icon-bz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/bz.mDHWXISw-B33H4YqDT4dFHP1TcU.png');
}

.flag-icon.flag-icon-ca {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ca.APty-NcHADxXCxncDJJjL--HTMU.png');
}

.flag-icon.flag-icon-cc {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cc.tqM56tkgTqyB2OmerJgo0Atppcc.png');
}

.flag-icon.flag-icon-cd {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cd.Vz12FySnklg135NoyPhf6QT84eU.png');
}

.flag-icon.flag-icon-cf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cf.a_VwINSg8jiaLV9NgzKzu1kcLrM.png');
}

.flag-icon.flag-icon-cg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cg.Mj88o4zvP5ztGat7L0jZqxPt164.png');
}

.flag-icon.flag-icon-ch {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ch.fzAu_AY0X0knu3kaPAdcpBNV554.png');
}

.flag-icon.flag-icon-ci {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ci.5pni6rkrz6dsY59ghCumJsOf5Zo.png');
}

.flag-icon.flag-icon-ck {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ck.CcxkhDg40u9lh_HeCWOY1Npspj0.png');
}

.flag-icon.flag-icon-cl {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cl.WZky6QNmgOkPFRuTYwimV4n9Np4.png');
}

.flag-icon.flag-icon-cm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cm.1AVCXRMXfpjPGxusW-kcoViuw4c.png');
}

.flag-icon.flag-icon-cn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cn.nVqkZjyiQ5ReSd9-WF5ddnnpPbE.png');
}

.flag-icon.flag-icon-co {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/co.gp2-WlPY3LF0k9GKVCv3vfDEyj4.png');
}

.flag-icon.flag-icon-cr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cr.7bmejnLm73B7o7EFNTGioJqifnk.png');
}

.flag-icon.flag-icon-cu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cu.fS3rEzBsWnkk_1Y_lwESULlIrHI.png');
}

.flag-icon.flag-icon-cv {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cv.M3Zy1aeURxEgoWb0Rld6z2wXw4A.png');
}

.flag-icon.flag-icon-cw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cw.I9P5tkQC27bRopJTAYM7NJnhO-0.png');
}

.flag-icon.flag-icon-cx {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cx._vZ-ZNVosPkXM8OgFtN61FcHnIo.png');
}

.flag-icon.flag-icon-cy {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cy.K5Z9jBrhOKR_TMqT8CbYalfpKgY.png');
}

.flag-icon.flag-icon-cz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/cz.NJhBzyqWyfRHBS6XFeteBQzasBQ.png');
}

.flag-icon.flag-icon-de {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/de.mIYsYxS0lxChiBR23bGPuzCmHDo.png');
}

.flag-icon.flag-icon-dj {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/dj.xmsebxmunds58yfJvZSl2h3DcZY.png');
}

.flag-icon.flag-icon-dk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/dk.e5DOZ0flSJvd8ULWT6Tp6ofiyuY.png');
}

.flag-icon.flag-icon-dm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/dm.FYkmvecExhkOieoyIULLyHgXFX4.png');
}

.flag-icon.flag-icon-do {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/do.zMS5egVEIc_rqfV5ewd78ILpxP8.png');
}

.flag-icon.flag-icon-dz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/dz.7vGad1ZlV_V0-UTq9ml_5E7Q_JY.png');
}

.flag-icon.flag-icon-ec {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ec.8qb4074G67STy4p0q84vcBYXfJI.png');
}

.flag-icon.flag-icon-ee {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ee.kFN_YpDHu8a0S_fL90nqHSF8KSE.png');
}

.flag-icon.flag-icon-eg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/eg.rGFx8r3i9Cv6jK6ytwU4G154T8o.png');
}

.flag-icon.flag-icon-eh {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/eh.h9XqP_4MfOdSlgsCedlwr1WkAS0.png');
}

.flag-icon.flag-icon-er {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/er._T_-VcaUGMnUxCuGlUNWp2VjN_o.png');
}

.flag-icon.flag-icon-es {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/es.x3ofouy56195M4akf9e5SPIjz_M.png');
}

.flag-icon.flag-icon-et {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/et.sV0x59U4Stfh4dsVc5OXkdbegcc.png');
}

.flag-icon.flag-icon-fi {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/fi.DUkiuma-kKMJVwe2QteWmJJhxrM.png');
}

.flag-icon.flag-icon-fj {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/fj.7UtIjxHJPyw-dtXNilxzQi2hMpw.png');
}

.flag-icon.flag-icon-fk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/fk.ATxCuxYS2nc-wDSEfx21qlGtnhA.png');
}

.flag-icon.flag-icon-fm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/fm.e_zCgBnd85Qqu8kDsg2oFctQbPE.png');
}

.flag-icon.flag-icon-fo {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/fo.oJrxTPaXjLRb6LmAgAAssmS7xbM.png');
}

.flag-icon.flag-icon-fr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/fr.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-ga {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ga.Vf-c9IoZOMx5cBGSMCUtTg00YoA.png');
}

.flag-icon.flag-icon-gb {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gb.h5kLQbp8Uandkp4tVr84vqmkecA.png');
}

.flag-icon.flag-icon-gd {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gd.ZY1bc0tWNTLPOeVXmIlaDBO871c.png');
}

.flag-icon.flag-icon-ge {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ge.dBd3AGxL60lPiv4jb5I75APnBrU.png');
}

.flag-icon.flag-icon-gf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gf.aY2NgRYm0P1uaERRzQK0NIW4Sw0.png');
}

.flag-icon.flag-icon-gg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gg.YrhZYh17NMexWQBCNBr7wYyBCfI.png');
}

.flag-icon.flag-icon-gh {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gh.rpHG_EF6ChHkVjWisNqcCpt3ZmI.png');
}

.flag-icon.flag-icon-gi {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gi.KBDOjkoFxEtiP8UA4SOU2TrPiwU.png');
}

.flag-icon.flag-icon-gl {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gl.Li049ucjojPbdj0FSwBiIJJ1yv4.png');
}

.flag-icon.flag-icon-gm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gm.Hg5Yb_4a5OQYBeXfuauX6hLVdf0.png');
}

.flag-icon.flag-icon-gn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gn.4s8rCkPsAD8srA8ylLPOMjC8V5g.png');
}

.flag-icon.flag-icon-gp {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gp.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-gq {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gq.1n_WSdKPUhDPzTHRLuaNOYvFUvQ.png');
}

.flag-icon.flag-icon-gr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gr.fy-Sm1kT3aOPfrXDVncxpkL-Vio.png');
}

.flag-icon.flag-icon-gs {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gs.y5RZdIp75KuR5Gw5JUXrtp5a-dk.png');
}

.flag-icon.flag-icon-gt {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gt.xb_Oqw90HvKog4kuLji4sE-KuDA.png');
}

.flag-icon.flag-icon-gu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gu.W5UgFqJ9f-vNfeopwd9fzfze2Mo.png');
}

.flag-icon.flag-icon-gw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gw.LWp8K_FXuCiv0D_EmkhZxsIJlFk.png');
}

.flag-icon.flag-icon-gy {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/gy.4Fyo_TUv3N2MS4b5teznRVLymdo.png');
}

.flag-icon.flag-icon-hk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/hk.FJFDP7BMZhFM6OLVRmP_ns5Awcs.png');
}

.flag-icon.flag-icon-hm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/hm.y4Sv1KCSsfo4dp9cB_HFcHB1-F0.png');
}

.flag-icon.flag-icon-hn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/hn.YhBFTCDeF2dbvXf-5I3tN1w52Qo.png');
}

.flag-icon.flag-icon-hr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/hr.0ErF3JJYI4h2kYk8nfHtV1rdhyY.png');
}

.flag-icon.flag-icon-ht {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ht.3od1GtstJngv4NEOL7cDfZVbxlI.png');
}

.flag-icon.flag-icon-hu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/hu.shJOEvIhSnD4nNj96W7i_N58zso.png');
}

.flag-icon.flag-icon-id {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/id.KVTyYrVXVODBznmgSCqUOpu-DQE.png');
}

.flag-icon.flag-icon-ie {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ie.IKh-LacYP7wT7oPNdX2NMrRImz4.png');
}

.flag-icon.flag-icon-il {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/il.-ePGUzr8H16Vwd2o-NW-fiq3ph8.png');
}

.flag-icon.flag-icon-im {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/im.NpiJnzzDTIf55ssA58ZoBPsr2PY.png');
}

.flag-icon.flag-icon-in {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/in.kcv1ifhs6VM9IIJwbvxAmAU-v1E.png');
}

.flag-icon.flag-icon-io {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/io.TVoiTKud6CbKrDPNKKe3rm-7dog.png');
}

.flag-icon.flag-icon-iq {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/iq.IbnN6BQYhrC-V42AIDlcXLwjuOU.png');
}

.flag-icon.flag-icon-ir {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ir.WUV9rxKKjgaw5PuzCG-EU0z_5Eo.png');
}

.flag-icon.flag-icon-is {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/is.upg7Vq164Y_IBYQEeBwYpBopsOM.png');
}

.flag-icon.flag-icon-it {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/it.Zz55gcJ86u9MxaKStO0DJ75lFZg.png');
}

.flag-icon.flag-icon-je {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/je.q-rWzdG9_77rWi9EAXEaFM18zD8.png');
}

.flag-icon.flag-icon-jm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/jm.y1SPdDehysy6wW_0zQkErbsMGg8.png');
}

.flag-icon.flag-icon-jo {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/jo.4nV4W3JPPWqbXrCY5tLhTnOf3wc.png');
}

.flag-icon.flag-icon-jp {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/jp.Yg0N9a1P1lcMFzjdcrJ17RYh1L0.png');
}

.flag-icon.flag-icon-ke {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ke.BJeMHMU8bEDMgdyfKqv4C2Yzvbo.png');
}

.flag-icon.flag-icon-kg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/kg.ttI28wYjYUany9rclCjcqVnVg5c.png');
}

.flag-icon.flag-icon-kh {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/kh.Aig2XOzLaAP0qI0twTdtC1O8Drw.png');
}

.flag-icon.flag-icon-ki {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ki.qgbYIxLj1wZiIuLIyKr3sdSYr6U.png');
}

.flag-icon.flag-icon-km {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/km.z_O_W1UTgVqMAJDnnmZtlroLLiE.png');
}

.flag-icon.flag-icon-kn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/kn.2LeAPlIhq_5N4Tw97vlO7SLub1s.png');
}

.flag-icon.flag-icon-kp {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/kp.aXoogK04HN8FrLnUKwyJ6lCQ53M.png');
}

.flag-icon.flag-icon-kr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/kr.PcNe1p9sJIoKTmEVUk-fwGhcMQk.png');
}

.flag-icon.flag-icon-kw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/kw.EfiyF_7dJdEUCiWqbPh28raptlM.png');
}

.flag-icon.flag-icon-ky {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ky.lIsRLd6iHgYkY4Lid1lE-FP9n0M.png');
}

.flag-icon.flag-icon-kz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/kz.fqlgV5wVzHqUHboB-74P_TyE6Bc.png');
}

.flag-icon.flag-icon-la {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/la.vAOvzGMO8tWY98w4leV73NfQ-Y0.png');
}

.flag-icon.flag-icon-lb {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/lb.puIG0DacdzznbzC90o3vJ4vsjs4.png');
}

.flag-icon.flag-icon-lc {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/lc.xi5EUoQC3tLJILVFDPGZ4yi4b1I.png');
}

.flag-icon.flag-icon-li {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/li.8-SuuKWJmpL0M-7cwuthfP9l62g.png');
}

.flag-icon.flag-icon-lk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/lk.uPylpCmtAz3o6KpS0bpiTGbb4ag.png');
}

.flag-icon.flag-icon-lr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/lr.HDWrt6TBVPSLgISVHVwIpKVwkEI.png');
}

.flag-icon.flag-icon-ls {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ls.lfap3m-87A9OSEj2wmoPrGgWfgc.png');
}

.flag-icon.flag-icon-lt {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/lt.nodL4BULCwF6U4WYeXKyO15Oo8k.png');
}

.flag-icon.flag-icon-lu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/lu.chiHZSaxadewlk9p1shTW-BLnvA.png');
}

.flag-icon.flag-icon-lv {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/lv.otO_yVqczK3C-KkD-7JS6b0_NuQ.png');
}

.flag-icon.flag-icon-ly {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ly.v3GW_E4cehYvhQNDy-Vfaol512Q.png');
}

.flag-icon.flag-icon-ma {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ma.Ei7XF7D5nDe82IxextQETZq9hys.png');
}

.flag-icon.flag-icon-mc {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mc.8_4qSkW3Mn7XVN1MPjVKeIA1fT8.png');
}

.flag-icon.flag-icon-md {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/md.gcZuendEb0b0vUBk0sosGf4fbmo.png');
}

.flag-icon.flag-icon-me {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/me.TYEPa29e1aKYoaL8ikYv-C745Rk.png');
}

.flag-icon.flag-icon-mf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mf.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-mg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mg.foSRK5VilHOfgSSyAcF5Es0JYAI.png');
}

.flag-icon.flag-icon-mh {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mh.jXfb1DrH-plzlgjSmEkT5YBFOic.png');
}

.flag-icon.flag-icon-mk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mk.J5C5Ezb0liwzTY1XectRE-AHsdg.png');
}

.flag-icon.flag-icon-ml {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ml.2m1peh1sqLlLqhQFPTmfYW55AdM.png');
}

.flag-icon.flag-icon-mm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mm.U5Yl6pZRRsLtlfyfyzWLj8iiuF8.png');
}

.flag-icon.flag-icon-mn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mn.YoUXFOCWamFGI8F1lduVYz0im8Q.png');
}

.flag-icon.flag-icon-mo {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mo.JWDp3vzdfF7oviMI5Wy73shok-4.png');
}

.flag-icon.flag-icon-mp {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mp.npLzaDiJQoK21znPz0_5HXmvUQo.png');
}

.flag-icon.flag-icon-mq {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mq.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-mr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mr.NviYx62FRS6yuQ5fOtN0JZ4Mk-8.png');
}

.flag-icon.flag-icon-ms {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ms.2MP4l-WBk8vxd_-3fiWi3a1FjfY.png');
}

.flag-icon.flag-icon-mt {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mt.TogG9kFwNv3wj9U4KDQrLDdNp84.png');
}

.flag-icon.flag-icon-mu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mu.bEswb_wyc9mIfgHHGzCGwT3GIac.png');
}

.flag-icon.flag-icon-mv {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mv.M7D9UfAuEp7ldZaEWe0uiK3POZs.png');
}

.flag-icon.flag-icon-mw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mw.2yRVLijNqNE8C1ldmvQYvSlfRz0.png');
}

.flag-icon.flag-icon-mx {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mx.Mx2wlRGOO1CAYn4JYTTlrsU3b20.png');
}

.flag-icon.flag-icon-my {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/my.46ni4Ubgjr58auvg2LTBJepCkv8.png');
}

.flag-icon.flag-icon-mz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/mz.yQomNz0ucDb2Wq8tk2SoXQ0Q8Fg.png');
}

.flag-icon.flag-icon-na {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/na.yrHN8AwyDpqLBkqTUPlbOgcbQpU.png');
}

.flag-icon.flag-icon-nc {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/nc.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-ne {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ne.hNAxFZRrRQkt2Nj4priMMxjehsw.png');
}

.flag-icon.flag-icon-nf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/nf.vfmwIE2JqDEFm4KCXBERAYi1sGM.png');
}

.flag-icon.flag-icon-ng {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ng.QMej4WQYLJ89Cm99YJEdWPdSze0.png');
}

.flag-icon.flag-icon-ni {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ni.AHpezI3wgxrJ4UWtggw9u-MBVoY.png');
}

.flag-icon.flag-icon-nl {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/nl.5G_Omh2G4C5lm_y153BHeb79tb8.png');
}

.flag-icon.flag-icon-no {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/no.lVy4P5dU-xKAuVaWgA39ysyQatY.png');
}

.flag-icon.flag-icon-np {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/np.2KXF6DuhXO39uKFje9a6OEEpn_k.png');
}

.flag-icon.flag-icon-nr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/nr.AxZCDCmOsm0iKlF5iHU5cWj3470.png');
}

.flag-icon.flag-icon-nu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/nu.Xx8Ql0wFPujjlXKKWv3ijCHsVJc.png');
}

.flag-icon.flag-icon-nz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/nz.hAVZsHYqLHa4hajcGBlJJ909734.png');
}

.flag-icon.flag-icon-om {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/om._tpSYKvZ8Dr6DtrRONVqOytWVr0.png');
}

.flag-icon.flag-icon-pa {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pa.6mcJsrhHvn3s1qOjlf-ydzC5KFY.png');
}

.flag-icon.flag-icon-pe {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pe.giyhNMhpM3P3m7RBOKuDfBd2J7o.png');
}

.flag-icon.flag-icon-pf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pf.qGBwSg37ncxhyw4M5LFPoq7r7mA.png');
}

.flag-icon.flag-icon-pg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pg.HVI8V-UlAz-tlY4BWfYJW9Gw_Vs.png');
}

.flag-icon.flag-icon-ph {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ph.UaRrG5Ex5sRZUwa4aNRJsVZ_iIA.png');
}

.flag-icon.flag-icon-pk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pk.Irh4_G4Q_p0U6PFY1EkDyGQqpys.png');
}

.flag-icon.flag-icon-pl {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pl.K-SNljgcz82niFskMa6etl_qQSE.png');
}

.flag-icon.flag-icon-pm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pm.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-pn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pn.ozeDG6EmtICp6iFnOnXN-dz0d9Q.png');
}

.flag-icon.flag-icon-pr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pr.uPN_TKaR-NU6NLmJQH4Xc2G9Z6M.png');
}

.flag-icon.flag-icon-ps {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ps.HenQ5X18D4bfmJPhhIZLVFFOq1k.png');
}

.flag-icon.flag-icon-pt {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pt.YWeWE8EbMnICir3rve2doj3QKAM.png');
}

.flag-icon.flag-icon-pw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/pw.6GHn63i0qXpHEkTBxXP_lEwsst4.png');
}

.flag-icon.flag-icon-py {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/py.egNbw8sqFSCt7xQEzyoeifVX6vQ.png');
}

.flag-icon.flag-icon-qa {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/qa.ZT2ITKnycrGSlojYT0V7kVSYJq0.png');
}

.flag-icon.flag-icon-re {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/re.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-ro {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ro.f8qPamU__QX8lnj9M6tGYT9uc9M.png');
}

.flag-icon.flag-icon-rs {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/rs.Pwcbbw8Isig4kliCWv6JcZWLsVM.png');
}

.flag-icon.flag-icon-ru {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ru.YgvQxf1Exq9LLkK9oTWHrkmYlTQ.png');
}

.flag-icon.flag-icon-rw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/rw.f3yqV5mhcoGnwEOSNA_NIHoL7bw.png');
}

.flag-icon.flag-icon-sa {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sa.rSLmoQe7pitQ7_L-g9zivo6JUbo.png');
}

.flag-icon.flag-icon-sb {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sb.-wYlfmALoxamBapxd4wTm4L7Ns0.png');
}

.flag-icon.flag-icon-sc {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sc.mFh1CRZVVE2pi6h_pfVV2Ylm-vo.png');
}

.flag-icon.flag-icon-sd {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sd.TzXWbKrvjRR_EYccelM-xC0somk.png');
}

.flag-icon.flag-icon-se {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/se.GVtUCLnrcKP8C7WaGGv_ovmTXvo.png');
}

.flag-icon.flag-icon-sg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sg.h-A6YyLKvFnEHPFyRHq30HaE7q0.png');
}

.flag-icon.flag-icon-sh {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sh.q2QdhrCubr1PFmnqGEJ0firWVh4.png');
}

.flag-icon.flag-icon-si {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/si.sZIE5fSivvccXrEO91i0OHKoIxo.png');
}

.flag-icon.flag-icon-sj {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sj.pjx3OISWUL4vO6c5oqQzFczrgEc.png');
}

.flag-icon.flag-icon-sk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sk.X_oT5AiREzboKUhxou5SsXTyTWE.png');
}

.flag-icon.flag-icon-sl {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sl.LpG27F067nR-E7DB2qbGjQj-z4A.png');
}

.flag-icon.flag-icon-sm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sm.n98mvMWGhGae7lb5zupgjj4R1dw.png');
}

.flag-icon.flag-icon-sn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sn.ZR2u9MlOGuvsWiz-uJzwLqmcCqE.png');
}

.flag-icon.flag-icon-so {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/so.PfbsQNvTn_EBooJBvqTsB_8s09I.png');
}

.flag-icon.flag-icon-sr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sr.BErhUg9HI_u7TpqQ0RMaHJbc0_0.png');
}

.flag-icon.flag-icon-ss {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ss.bM1Yv3AJO3zxI6LfTmasMY8eIOc.png');
}

.flag-icon.flag-icon-st {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/st.E7sze5TXC-vybFZSIn1dzhQhN6E.png');
}

.flag-icon.flag-icon-sv {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sv.5A__moIYDbqYJO95US0c-bEXr0s.png');
}

.flag-icon.flag-icon-sx {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sx.cH16fxNz7BpnbPBZ3L2w8oBz6K8.png');
}

.flag-icon.flag-icon-sy {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sy.saN3j8sAMl4PgTHFxL52adAEE5U.png');
}

.flag-icon.flag-icon-sz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/sz.b3-fjtGkqG8dW7ybu4oflKx4-ls.png');
}

.flag-icon.flag-icon-tc {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tc.OuWOrKlpRQvO4HCala-p6C5YeoY.png');
}

.flag-icon.flag-icon-td {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/td.gYtFHS6UR0Qn7-RsRqP4eQq3j1Q.png');
}

.flag-icon.flag-icon-tf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tf.IgpqIoiqNz87jIPTHn66z5OIA6k.png');
}

.flag-icon.flag-icon-tg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tg.UqjvZh6gvIrGOjql0INczDNG5fc.png');
}

.flag-icon.flag-icon-th {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/th.X2oQ7WkrsdPT8Cibfk71-McRzNo.png');
}

.flag-icon.flag-icon-tj {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tj.oHqYilWoDkd-ypLzbaa480LJnSs.png');
}

.flag-icon.flag-icon-tk {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tk.piLSKCPvdUTvnWnEwn0ROd-y7dg.png');
}

.flag-icon.flag-icon-tl {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tl._exQYsnRw5oR7d_Aw6Isdtl4m9Y.png');
}

.flag-icon.flag-icon-tm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tm.BCrjYtOGU1LrgEWr3ux53paRHH0.png');
}

.flag-icon.flag-icon-tn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tn.iqnKq2HdF8j_ySugaBuP37HRAdA.png');
}

.flag-icon.flag-icon-to {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/to.0Py8T-KyJgPrrByAg6fWUNA8GEE.png');
}

.flag-icon.flag-icon-tr {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tr.BUFds2vTNloBH9fE6CgTVXmKJqs.png');
}

.flag-icon.flag-icon-tt {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tt.yJIlxYEoPJHDHLhf6FnkjQpCq_E.png');
}

.flag-icon.flag-icon-tv {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tv.0UBP6kJzQKE2q-7Ca3pRVKyyxjw.png');
}

.flag-icon.flag-icon-tw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tw.E5D_f3-xB9-aK3ZKnNgsfGjkXWg.png');
}

.flag-icon.flag-icon-tz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/tz.1uFoOpGkywlBsa1yKid4m-IAZd8.png');
}

.flag-icon.flag-icon-ua {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ua.O75QOyrxUjoaMazNa6jdI2Z5Rs4.png');
}

.flag-icon.flag-icon-ug {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ug.QxEBWIaSdwZaCDIASHkdULF5yRo.png');
}

.flag-icon.flag-icon-um {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/um.JiJuuKnbGsVuf9--HHtWu0sbpTc.png');
}

.flag-icon.flag-icon-us {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/us.JiJuuKnbGsVuf9--HHtWu0sbpTc.png');
}

.flag-icon.flag-icon-uy {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/uy.VMRnfJxzEgZ-IAdgLYODucxRJNc.png');
}

.flag-icon.flag-icon-uz {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/uz.o-LL9D_IEAM-hF1xPDztL5LYxbA.png');
}

.flag-icon.flag-icon-va {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/va.bzlYjQc0al_Qdg204exWT3Vbyp4.png');
}

.flag-icon.flag-icon-vc {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/vc.MQhPVV_ZBuMAcr7Kh2jbilLrDu4.png');
}

.flag-icon.flag-icon-ve {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ve.61WCeZtCvXUQr7HU6CZ9AOQcr1o.png');
}

.flag-icon.flag-icon-vg {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/vg.kAXoPjEMXiSmz9myoLw2x_ebM3I.png');
}

.flag-icon.flag-icon-vi {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/vi.vGqygxI61IXvFZNyD8pVUSjCWdw.png');
}

.flag-icon.flag-icon-vn {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/vn.d9Hh1PmDyoIKlNW7CqEkw0dwmAg.png');
}

.flag-icon.flag-icon-vu {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/vu.edAOcfor-IGAQua_ziVI1QcLq20.png');
}

.flag-icon.flag-icon-wf {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/wf.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-ws {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ws.nGCa20fY_P_sz4MsHC5JM3Cj2ug.png');
}

.flag-icon.flag-icon-ye {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/ye.OyHm71ZMrXtt0Wikq5ugry7g4nw.png');
}

.flag-icon.flag-icon-yt {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/yt.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}

.flag-icon.flag-icon-za {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/za.aKs4-MafZrPye5yiYP7DaeDZKvw.png');
}

.flag-icon.flag-icon-zm {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/zm.0a7E8YNhbbSMj9zBtTHtO2c8eoM.png');
}

.flag-icon.flag-icon-zw {
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/zw.Ge5wbXDrAV3-0l34RLcxHXRnxA8.png');
}
