.g-page-popout {
	.g-page-shell {
		padding: 0;
		overflow: hidden;
	}
}

.g-popout-body {
	padding: 10px;
	overflow: auto;

	> .g-modal-loading-indicator {
		height: 100%;
	}

	&:not(.g-popout-body-no-footer) {
		@sub-height: @navbar-height + @g-footer-bar-height;
		height: calc(100% ~'-' @sub-height);
	}

	&.g-popout-body-no-footer {
		height: calc(100% ~'-' @navbar-height);
	}
}
