.gwLabel {
	height: 100%;
	flex: 1 1;

	> p {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		max-height: 100%;
	}
}
