html.no-touch .g-tablet-mode-btn,
.dropdown-backdrop {
	display: none;
}

.g-centered {
	position: absolute;
	top: 50%;
	left: 50%;
}

.g-hidden {
	display: none !important;
}

.g-label-tester {
	max-width: initial;
}

.g-offscreen {
	position: absolute;
	top: -9999em;
	left: -9999em;
}

.g-uneditable {
	color: @input-color;
	border-color: @grey;

	&.input-group-addon {
		background-color: transparent;
	}
}

.hideWithSpace {
	visibility: hidden;
}

.g-grip {
	width: 1px;

	li {
		width: 1px;
		height: 3px;
		margin-bottom: 2px;
		background: @Theme_FieldTitle;
	}
}

.g-sidebar-no-items {
	margin: 8px 28px;
	font-size: 11px;
	color: @Theme_FieldTitle;
	text-align: center;
}

.g-flat-button {
	padding: 5px 10px;
	font-size: 16px;
	color: @Grid_HeaderTextColor;
	border: none;
	background: @Theme_ValueText;

	span {
		padding-left: 6px;
		font-family: @default-font;
		font-size: 14px;
	}
}

.g-group-input.form-control {
	border-radius: 0;
	height: @g-control-height;

	&[type="text"] {
		padding-top: 18px !important;
	}

	&.g-top-left-input {
		border-top-left-radius: 5px;
	}

	&.g-top-right-input {
		border-top-right-radius: 5px;
	}

	&.g-bottom-left-input {
		border-bottom-left-radius: 5px;
	}

	&.g-bottom-right-input {
		border-bottom-right-radius: 5px;
	}
}

.g-header-button {
	display: block;
	padding: 5px;
	text-decoration: none;
	cursor: pointer;
	border-radius: 4px;
}

.g-header-button:hover,
.g-header-button:focus,
.g-header-button:active,
.dropdown.open .g-header-button,
.pressed.g-header-button {
	text-decoration: none;
}

.alert-count {
	position: absolute;
	top: 1px;
	right: 1px;
	display: block;
	width: 14px;
	height: 14px;
	padding: 2px;
	font-size: 10px;
	line-height: 10px;
	text-align: center;
	border-radius: 3px;
	color: @white;
}

.g-dynamic-menu-list .alert-count {
	position: absolute;
	top: 50%;
	right: 5px;
	margin-top: -7px;
	color: #fff;
}

.error-count {
	background-color: @state-error;
}

.warning-count {
	background-color: @state-message-error;
}

.dropdown-submenu {
	position: relative;

	> .dropdown-menu {
		position: static !important;
		border: 0;
		box-shadow: none;
	}

	.pull-left {
		float: none;

		> .dropdown-menu {
			left: -100%;
			border-radius: 6px 0 6px 6px;
		}
	}
}

.g-anchor-container {
	position: absolute;
}

.g-anchored {
	position: absolute;
	width: 100%;

	> .gwButton button {
		@marginSize: 3px;
		min-width: 100px - (@marginSize * 2);
		.page-button(@marginSize);
	}

	> .gwLabel {
		max-width: 100%;
		white-space: normal;
	}
}

.gwErrorLabel-wrapper {
	overflow: auto;
	width: 100%;
	height: 100%;
	-webkit-overflow-scrolling: touch;
	border: 1px dashed #b94c4a;

	.gwErrorLabel {
		padding: 0;
		height: 100%;
		display: flex;
		align-items: center;

		> p {
			font-size: 12px;
			color: @error-message-text;
			max-height: 100%;
			margin-bottom: 0px;
			white-space: pre-wrap;
		}
	}
}

.g-heading {
	position: absolute;
	right: 0;
	left: 30px;
	z-index: 900;
	min-height: 40px;
	margin-top: 0;
	margin-bottom: 10px;
	border-bottom: 1px solid @navbar-default-border;

	.navbar {
		position: absolute;
		top: 0;
		right: 0;
		margin-bottom: 0;

		&.pull-right {
			margin-right: 10px;
		}

		.btn-group > .btn:first-child,
		li button {
			margin-top: 5px;
			overflow: visible;
		}

		li button {
			padding: 2px 10px;
			margin-right: 3px;
			overflow: visible;
		}

		.dropdown-menu {
			right: 0;
			left: auto;
			padding: 10px 15px;

			&.pull-left {
				right: auto;
			}

			h3 {
				margin: 0 0 10px;
				line-height: 30px;
			}

			> ul > li > a {
				display: block;
				padding: 3px 20px 3px 3px;
				clear: both;
				font-weight: normal;
				line-height: @line-height-base;
				color: @dropdown-link-color;
				white-space: nowrap;
			}
		}

		.btn-group .dropdown-menu {
			padding: 10px 0;
			text-align: left;
			text-shadow: 0 0;
		}

		.dropdown-submenu > .dropdown-menu {
			left: -106%;
			border-radius: 6px 0 6px 6px;
		}

		.nav > li {
			&:not(.divider-vertical) {
				margin-right: 2px;
			}

			> a {
				&.g-has-alerts {
					color: @white;
					background: none;

					> img,
					i {
						color: @white;
					}
				}
			}
		}
	}
}

.g-header1 {
	font-family: @default-font;
	font-weight: @RichTextEditor_Header1FontWeight;
	color: @RichTextEditor_Header1TextColor;
	.g-font-size-px(@RichTextEditor_Header1FontSize);
}

.g-header2 {
	font-family: @default-font;
	font-weight: @RichTextEditor_Header2FontWeight;
	color: @RichTextEditor_Header2TextColor;
	.g-font-size-px(@RichTextEditor_Header2FontSize);
}

.g-header3 {
	font-family: @default-font;
	font-weight: @RichTextEditor_Header3FontWeight;
	color: @RichTextEditor_Header3TextColor;
	.g-font-size-px(@RichTextEditor_Header3FontSize);
}

.g-default {
	font-family: @default-font;
	font-weight: @Body_FontWeight;
	color: @Body_TextColor;
	.g-font-size-px(@Body_FontSize);
	background-color: @Body_BackgroundColorEnd;
}

.gwLabel-wrapper {
	overflow: auto;
	width: 100%;
	height: 100%;
	-webkit-overflow-scrolling: touch;
}

.gwLabel {
	min-width: 100%;
	display: table;
	table-layout: fixed;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	> p {
		margin: 0px;
		width: 100%;
		height: 100%;
		display: table-cell;
		white-space: pre-wrap;
	}
}

.gwNote-wrapper {
	height: 100%;
	overflow: auto;
	line-height: 18px;
}

.notification-label {
	color: white;
}

.g-information-label {
	background: @state-info;
}

.g-success-label {
	background: @state-success;
}

.g-warning-label {
	background: @state-warning;
}

.g-message-error-label {
	background: @state-message-error;
}

.g-error-label {
	background: @state-error;
}

.g-extra-small-text {
	font-size: 12px;
}

.g-small-text {
	font-size: 13px;
}

.g-medium-text {
	font-size: 15px;
}

.g-large-text {
	font-size: 22px;
}

.g-extra-large-text {
	font-size: 35px;
}

.g-extra-extra-large-text {
	font-size: 55px;
}

.g-small-padding {
	padding: 0px 4px
}

.g-medium-padding {
	padding: 0px 10px
}

.g-large-padding {
	padding: 0px 18px
}

.icons-color {
	color: @Icons_Color;
}

.gwMessageBar {
	display: flex;
	width: 100%;
	height: @g-message-bar-height;
	padding: 5px;
	line-height: 22px;
	color: @white;
	background-color: @InputControl_LabelColor;
	border-bottom: @sidebar-border;

	small {
		text-transform: none;
	}

	.g-kg-actions-container {
		flex: 1 0 82px;
		height: auto;
	}

	.g-kg-action {
		margin: 0;
	}
}

.gwMessageBar-title {
	display: inline-block;
	text-transform: uppercase;
	.text-overflow();
}

.gwMessageBar,
.gwMessageBar .g-kg-action a,
.gwMessageBar .g-kg-action a:hover {
	color: @white;
}

.g-fade {
	opacity: 0;
	visibility: hidden;
	transition: visibility .25s, opacity .25s linear; // if transition time is changed, change the timeout duration for the removal of the popover tip in gwDynamicMenu.js to match
	-webkit-transition: visibility .25s, opacity .25s linear;
	-o-transition: visibility .25s, opacity .25s linear;
}

.g-in {
	opacity: 1;
	visibility: visible;
}

.g-fade.in {
	.g-in;
}

.g-fade.disabled {
	pointer-events: none;
}

.dropdown > .dropdown-menu {
	.g-fade;
	display: block !important;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	border-radius: 5px;
}

.dropdown > .dropdown-menu > .dropdown-submenu,
.g-grid-actions-dropdown.dropdown-submenu,
.g-dynamic-menu-list > .dropdown-submenu {
	margin-left: 5px;
	margin-right: 5px;

	> .dropdown-menu {
		> li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove) {
			margin-left: 5px !important;
		}
	}
}

.dropdown-submenu > .dropdown-menu {
	padding-left: 15px;
}

.popover-content > .gwDynamicMenu,
.dropdown.open > .dropdown-menu {
	border-radius: @popover-border-radius;
	opacity: 1;
	visibility: visible;
}

.popover-content > .gwDynamicMenu {
	max-width: calc(~'100vw - 22px');
}

.popover-content > .gwDynamicMenu > ul {
	padding-left: 0;

	> li > a > input {
		margin: @g-menu-input-margin;
	}
}

.g-favorites-popover,
.g-recents-popover {
	.g-jump-item > a {
		padding-left: 0;
	}
}

.hideable-dropdown-menu > .popover-content {
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.g-grid-action-popover.hide-pin > .popover-content {
	.icon-pin, .icon-unpin {
		display: none;
	}

	.g-menu-item-icon-container {
		.icon-pin, .icon-unpin {
			display: none;
		}
	}
}

.g-header-popover {
	.popover-content {
		padding-left: 0;
		padding-right: 0;
		-webkit-transform: translate3d(0, 0, 0);
	}

	.g-dynamic-menu-list {
		padding-left: 0;
	}
}

.g-dynamic-menu-list .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}

.dropdown-menu-shared,
.g-dynamic-menu-list {
	-webkit-transform: translate3d(0, 0, 0);

	&.pull-left {
		right: auto;
	}

	&.pull-right {
		left: auto;
	}

	.divider, .divider:hover {
		margin: 5px 0;
		background-color: #aaa;
	}

	.dropdown-header {
		.dropdown-menu-header;
	}

	.dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
		margin-left: 0;
		margin-right: 0;
	}

	> li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
		margin-left: 5px;
		margin-right: 5px;
	}

	.g-loggedin-info-section + .dropdown-header {
		border-top: 0;
	}

	.g-loggedin-info-section {
		border-bottom: 1px solid #aaa;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	> li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.alert):not(.dropdown-header) {
		margin-bottom: 5px;
	}

	> li.g-jump-add-remove > a {
		padding: 0;
	}

	> li > a,
	> li > div > a,
	> li > span,
	> span,
	> li > label,
	> li.checkbox-item,
	> li > .g-menu-item--colorscheme {
		.dropdown-menu-list-line;

		.icon-spinner.icon-spin {
			margin-right: 5px;
		}
	}

	> li > label {
		margin-bottom: 0;

		> input[type="radio"] {
			margin-top: 0;
			margin-right: 6px;
			cursor: pointer;
		}
	}

	> li > a > input[type="radio"] {
		margin: 0;
		line-height: normal;
		height: ~"calc(100% - 1em)";
	}

	span {
		.text-overflow();
	}

	& > li {
		&.checkbox-item {
			padding-left: 9px;
			overflow: hidden;

			> label {
				font-size: 13px;

				> span {
					line-height: normal;
				}
			}
		}

		.g-jump-item > a {
			padding: 0 2px;
		}

		& > a,
		&.checkbox-item,
		& > .g-dropdown-list-item,
		& > div > a {
			display: flex;
			align-items: center;
		}

		& > span {
			display: flex;
			align-items: center;
		}

		.g-pin-target span,
		.g-no-pin span,
		.g-jump-item .g-menu-item-target {
			padding-right: @dropdown-menu-item-padding;
		}
	}

	&:not(.g-display-option) {
		> li:last-child,
		> li:last-child > a,
		> li:last-child > span {
			border-bottom-right-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		> li:first-child,
		> li:first-child > a,
		> li:first-child > span {
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
		}

		> li:last-child {
			margin-bottom: 5px;
		}

		> li:first-child:not(.g-loggedin-info-section):not(.dropdown-header),
		> li:nth-child(2):not(.dropdown-header):not(.alert) {
			margin-top: 5px;
		}
	}

	&.g-dynamic-menu-list {
		> li.doc-menu-item {
			border-top-right-radius: 0;
			border-top-left-radius: 0;
		}

		> li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation {
			display: inline-flex;
			overflow-y: hidden;
			width: 100%;

			label {
				margin: 0;
			}
		}
	}

	label,
	li {
		&.dropdown-header {
			cursor: default;
		}

		a {
			&.disabled,
			.disabled {
				cursor: default;
			}
		}

		&.disabled,
		.disabled {
			color: @disabled-text;
			cursor: text;

			&:hover,
			&:focus {
				background-color: transparent;
				color: @disabled-text;
			}

			&.g-error-text {
				color: @error-message-text;
			}

			&.g-error-actions {
				display: block;
				white-space: normal;
				word-break: break-word;
				height: auto;
			}
		}
	}

	.dropdown-submenu {
		> .dropdown-menu {
			position: fixed;
			top: 0;
			left: 0;
			min-width: 100% !important;
			max-width: 100% !important;
			margin-top: 5px;
			overflow-x: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			border-radius: 0;

			> li {
				margin-top: 0 !important;

				&:last-child {
					margin-bottom: 0;
				}

				> a {
					input {
						margin: @g-menu-input-margin;
					}
				}
			}
		}



		i.chevron {
			margin: @g-menu-chevron-margin;

			&.icon-chevron-down {
				display: inline;
			}
		}

		&.open {
			> a > i.icon-chevron-down {
				transform: rotate(180deg) translateY(-3px);
			}
		}
	}
}

.navbar .nav,
.g-modal-header-buttonpane,
.g-popout-header-buttonpane {
	.dropdown.open {
		&:before,
		&:after {
			position: absolute;
			right: calc(~'50% - 6px');
			z-index: 1001;
			display: block;
			border: @popover-arrow-size solid transparent;
			border-top: none;
			content: '';
		}

		&:before {
			bottom: -(@popover-arrow-size);
			border-bottom-color: @popover-border-color;
		}

		&.more-dropdown:after {
			border-bottom-color: #f2f2f2;
		}

		&:after {
			bottom: -(@popover-arrow-size+1);
			border-bottom-color: @white;
		}
	}

	.g-tasktray-actions.g-mobile-only {
		flex-direction: column;
		justify-content: center;

		&.hide .dropdown.open {
			display: none;
		}
	}
}

.g-watermark {
	color: #cccccc;
}

.g-pageload-error {
	padding: 10px;
}

.g-menu-item--colorscheme__actions {
	display: flex;
	align-items: center;
}

.g-menu-item--colorscheme__selector {
	display: flex;
	flex-grow: 1;
	align-items: center;
	height: 100%;
	.text-ellipsis();
}

.g-menu-item-icon-container,
.open > .g-menu-item-target > .g-menu-item-icon-container {
	margin-left: auto;

	.g-menu-item-unclickable-persistent-icon,
	.g-menu-item-persistent-icon,
	.g-menu-item-icon {
		height: @icon-button-size;
		width: @icon-button-size;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.g-menu-item-persistent-icon,
	.g-menu-item-icon {
		.opacity-hover(0s);
	}

	.g-menu-item-icon {
		opacity: 0;
	}
}

.g-menu-item-icon {
	color: @Theme_Icons;

	&:not(.g-in) {
		opacity: 0;
	}
}

.g-menu-item-unclickable-persistent-icon {
	cursor: default;
}

.g-menu-item-persistent-icon,
.g-menu-item-icon {
	cursor: pointer;
}

.g-menu-item-target {
	position: relative;

	> .g-menu-item-text {
		width: 100%;
		.text-ellipsis();

		&.g-published,
		&.g-with-persistent-icon {
			width: calc(~'100% - 21px');
		}
	}

	&:hover {
		> .g-menu-item-text {
			width: calc(~'100% - 21px');

			&.g-published {
				width: calc(~'100% - 45px');
			}
		}
	}
}

.g-divider {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	width: 1px;

	&:before {
		height: 20px;
		border-left: 1px solid @Theme_GeneralText;
		content: ' ';
		opacity: 0.5;
		left: 0;
	}
}

.btn-primary + .g-divider {
	background-color: @PrimaryButton_BackgroundColorStart;

	&:before {
		border-color: @white;
	}
}

.gwPinnedItems > .gwPinnedItem-hidden-button {
	position: absolute;
	top: -100px;
	left: -500px;
	visibility: hidden;
}

.g-foreground-theme-background {
	color: @Theme_Background !important;
}

.g-foreground-theme-general-text {
	color: @Theme_GeneralText !important;
}

.g-foreground-theme-icons {
	color: @Theme_Icons !important;
}

.g-foreground-theme-field-title-text {
	color: @Theme_FieldTitle !important;
}

.g-foreground-theme-value-text {
	color: @Theme_ValueText !important;
}

.g-foreground-theme-default-button {
	color: @Theme_DefaultButton !important;
}

.g-foreground-theme-button {
	color: @Theme_Button !important;
}

.g-foreground-theme-default-button-text {
	color: @Theme_DefaultButtonText !important;
}

.g-foreground-theme-hover {
	color: @Theme_Hover !important;
}

.g-foreground-theme-hover-text {
	color: @Theme_HoverText !important;
}

.g-foreground-theme-field-control {
	color: @Theme_FieldControl !important;
}

.g-background-theme-background {
	background: @Theme_Background !important;
	border-color: @Theme_Background !important;

	&:hover {
		border-color: @Theme_Background_Darken15 !important;
	}
}

.g-background-theme-general-text {
	background: @Theme_GeneralText !important;
	border-color: @Theme_GeneralText !important;

	&:hover {
		border-color: @Theme_GeneralText_Darken15 !important;
	}
}

.g-background-theme-icons {
	background: @Theme_Icons !important;
	border-color: @Theme_Icons !important;

	&:hover {
		border-color: @Theme_Icons_Darken15 !important;
	}
}

.g-background-theme-field-title-text {
	background: @Theme_FieldTitle !important;
	border-color: @Theme_FieldTitle !important;

	&:hover {
		border-color: @Theme_FieldTitle_Darken15 !important;
	}
}

.g-background-theme-value-text {
	background: @Theme_ValueText !important;
	border-color: @Theme_ValueText !important;

	&:hover {
		border-color: @Theme_ValueText_Darken15 !important;
	}
}

.g-background-theme-default-button {
	background: @Theme_DefaultButton !important;
	border-color: @Theme_DefaultButton !important;

	&:hover {
		border-color: @Theme_DefaultButton_Darken15 !important;
	}
}

.g-background-theme-button {
	background: @Theme_Button !important;
	border-color: @Theme_Button !important;

	&:hover {
		border-color: @Theme_Button_Darken15 !important;
	}
}

.g-background-theme-default-button-text {
	background: @Theme_DefaultButtonText !important;
	border-color: @Theme_DefaultButtonText !important;

	&:hover {
		border-color: @Theme_DefaultButtonText_Darken15 !important;
	}
}

.g-background-theme-hover {
	background: @Theme_Hover !important;
	border-color: @Theme_Hover !important;

	&:hover {
		border-color: @Theme_Hover_Darken15 !important;
	}
}

.g-background-theme-hover-text {
	background: @Theme_HoverText !important;
	border-color: @Theme_HoverText !important;

	&:hover {
		border-color: @Theme_HoverText_Darken15 !important;
	}
}

.g-background-theme-field-control {
	background: @Theme_FieldControl !important;
	border-color: @Theme_FieldControl !important;

	&:hover {
		border-color: @Theme_FieldControl_Darken15 !important;
	}
}

.icon-flip-horizontal {
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.icon-flip-vertical {
	-moz-transform: scaleY(-1);
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
}

.g-body-dragging {
	pointer-events: none;
}
