.gwSearchBox-results {
	.g-location-search-item {
		padding: 3px 8px;
		cursor: pointer;

		.main {
			color: @Theme_GeneralText;
		}
	}
}
