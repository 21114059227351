.g-modal.edocs .g-modal-autoresize {
	.modal-content {
		min-height: 200px;
	}
}

.g-edocs-modal {
	height: 100%;
	display: flex;

	.entity-references {
		width: 200px;
		display: block;
		flex-shrink: 0;

		.panel-body {
			overflow-y: auto;
			margin-top: 0;
			position: inherit;
		}
	}

	.edocs-grid {
		margin-left: 10px;
		position: relative;
		height: 100%;
		padding-bottom: @g-control-height;
		flex: 1;
		overflow: hidden;

		.koGrid {
			@sub-height: @g-control-height + 2;
			height: calc(100% ~"-" @sub-height);
		}

		.g-grid-actions {
			height: 100%;

			.g-grid-action-button {
				height: 100%;
				display: inline-block;
				padding: 0 6px;
				border: none;
				background: transparent;
				outline: none;
			}
		}

		.loading {
			.opacity(.4);
		}

		.footer {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: @g-control-height;

			.show-deleted-edocs {
				float: left;
				width: 250px;
				height: 100%;
				margin-right: 3px;
			}

			.btn {
				height: 100%;
			}
		}
	}
}

.g-edoc-row-actions li i {
	width: 17px;
}

.g-edocs-edit-page {
	min-width: 400px;

	.g-anchored {
		height: 44px;
		position: static;
	}

	.g-anchored:not(:first-child) {
		margin-top: 4px;
	}

	.error {
		padding-top: 8px;
		color: @state-error-text;
	}

	.gwFileUploader .fileinput-button {
		float: right;
		margin-top: -39px;
	}
}

.edocs-grid__file-select {
	float: left;
	height: 100%;
	margin-right: 3px;
}

.g-edocs-edit-page-header h4 {
	width: 389px;
	.text-overflow();
}
