.nav {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav > li {
	position: relative;
	display: block;
}

.nav > li > a {
	position: relative;
	display: block;
	padding: 10px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
	text-decoration: none;
	background-color: #eeeeee;
}

.nav > li.disabled > a {
	color: #777777;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
	color: #777777;
	text-decoration: none;
	cursor: default;
	background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: #eeeeee;
	border-color: #337ab7;
}

.nav > li > a > img {
	max-width: none;
}

.nav-pills > li {
	float: left;
}

.nav-pills > li > a {
	border-radius: 4px;
}

.nav-pills > li + li {
	margin-left: 2px;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
	color: #fff;
	background-color: #337ab7;
}

.nav-stacked > li {
	float: none;
}

.nav-stacked > li + li {
	margin-top: 2px;
	margin-left: 0;
}

.tab-content > .tab-pane {
	display: none;
}

.tab-content > .active {
	display: block;
}

.navbar {
	position: relative;
	min-height: 50px;
	margin-bottom: 20px;
	border: 1px solid transparent;
}

@media (min-width: 768px) {
	.navbar {
		border-radius: 4px;
	}
}

.nav-pills > .active > a > .badge {
	color: #337ab7;
	background-color: #fff;
}

.nav-pills > li > a > .badge {
	margin-left: 3px;
}

.nav:before,
.nav:after,
.navbar:before,
.navbar:after {
	display: table;
	content: " ";
}

.nav:after,
.navbar:after {
	clear: both;
}

.navbar {
	min-height: 30px;

	.nav > li > .dropdown-menu {
		&:before {
			position: absolute;
			top: -7px;
			right: 12px;
			display: inline-block;
			border-right: 7px solid transparent;
			border-bottom: 7px solid #ccc;
			border-left: 7px solid transparent;
			border-bottom-color: rgba(0,0,0,.2);
			content: '';
		}

		&:after {
			position: absolute;
			top: -6px;
			right: 13px;
			display: inline-block;
			border-right: 6px solid transparent;
			border-bottom: 6px solid @white;
			border-left: 6px solid transparent;
			content: '';
		}

		&.pull-left {
			&:before {
				right: auto;
				left: 12px;
			}

			&:after {
				right: auto;
				left: 13px;
			}
		}
	}
}
