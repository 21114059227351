.gwCarousel {
	height: 100%;
	width: 100%;

	[data-contents="gwCarousel"] {
		height: 100%;
		width: auto;
		overflow-y: hidden;
		white-space: nowrap;
	}

	.gwGroupBox {
		height: 100% !important;
		width: 100% !important;
	}
}

.gwCarousel__child {
	display: inline-block;
	vertical-align: middle;
	margin-left: 4px;
	margin-right: 4px;
}
