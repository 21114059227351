.customMappingPage {
	width: 613px;
	height: 220px;

	div {
		height: 100%;
	}

	.g-mapping-editable-cell {
		height: 100%;
		display: flex;
		align-items: center;

		input {
			font-size: 12px;
		}

		span {
			padding: 0px 8px 1px 8px;
		}
	}
}
