.gwTruckDisplayer {
	color: #f9fafc;
	width: 100%;

	label {
		margin: 0px;
		font-size: inherit;
		color: #f9fafc;
		overflow: visible;
	}

	.page {
		display: none;
		max-height: 100%;
	}

	.page.current {
		display: block;
	}

	#truck-side-view .truck-type-title {
		margin-top: 0;
	}

	#truck-side-view button.truck-config-change {
		float: right;
		margin: 0 0 20px 30px;
	}

	.buttons-row {
		border-top: 1px solid #f9fafc;
		padding-top: 20px;
		margin-top: 20px;
		display: flex;

		.gwToggle {
			.btn.btn-link {
				font-size: 20px;
				letter-spacing: 3px;
				line-height: 52px;
			}
		}

		.gwToggleSwitch(56px);
	}

	.truck-buttons-row-status {
		display: flex;
		align-items: flex-start;
	}

	button {
		background-color: #1b2431;
		color: #f9fafc;
		border: none;
	}

	button.status-check,
	button.status-critical,
	button.status-ok {
		padding: 0px;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: flex-start;
	}

	button.status-check,
	button.status-critical,
	button.status-ok {
		i {
			width: 55px;
			font-size: 25px;
			line-height: 2.1;
			color: #f9fafc;
		}

		span {
			padding: 12px 20px;
		}
	}

	button.status-check > i {
		background-color: @state-warning;
	}

	button.status-critical > i {
		background-color: @state-error;
	}

	.btn:not(.btn-link) {
		&:not(.disabled):not([disabled]) {
			&:focus,
			&:hover,
			&:active {
				background-color: @Grid_TitleFontColor;
				border: 1px solid @Content_TextColor;
			}
		}

		&[disabled] {
			background-color: @Theme_Background;
			border-color: @Content_TextColor_Fade50;
		}
	}

	.open > .btn:not(.btn-link):not(.disabled):not([disabled]) {
		background-color: @Grid_TitleFontColor;
	}

	.legend label {
		margin-left: 20px;
		display: inline-block;
	}

	.legend label::before {
		display: inline-block;
		content: '';
		width: 25px;
		height: 12px;
		border-radius: 2px;
		margin-right: 12px;
	}

	.legend label.status-good::before {
		background-color: @state-success;
	}

	.legend label.status-check::before {
		background-color: @state-warning;
	}

	.legend label.status-critical::before {
		background-color: @state-error;
	}

	.truck.truck-view-side {
		.legend label .status-offline::before {
			color: #f9fafc;
			opacity: 0.5;
		}
	}


	.legend label.status-offline::before {
		font-family: 'icomoon' !important;
		content: "\f05e";
		font-size: 15px;
		width: 10px;
		transform: scaleX(-1);
		-moz-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		color: #898a8c;
	}

	.legend label.status-offline.faded::before {
		color: white;
		opacity: 0.5;
	}

	.truck-front-back {
		color: rgba(249, 250, 252, 0.5);
		height: 2em;
		margin-top: 125px;
		font-size: 20px;
	}

	.truck-front-back label {
		float: left;
	}

	.truck-front-back label + label {
		float: right;
	}

	.flex-container-space-between {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.flex-container-align-items-centre {
		display: flex;
		align-items: center;
	}

	.flex-container-justify-centre {
		display: flex;
		justify-content: center;
	}

	#tyres-detail button.view-sensors,
	#tyres-detail button.hide-sensors {
		margin-left: 30px;
		padding: 12px 20px;
		font-size: 16px;
	}

	#tyres-detail button.hide-sensors,
	#tyres-detail.sensors button.view-sensors {
		display: none;
	}

	#tyres-detail.sensors button.hide-sensors,
	#tyres-detail button.view-sensors {
		display: inline-block;
	}
	/*
 * TRUCK DIAGRAMS
 * https://www.truck.net.au/sites/default/files/TAPs%20-%20description%20of%20truck%20configuration%20September%202016.pdf
 *
 * Vehicle classes:
 * vehicle-r  - Rigid truck
 * vehicle-tp - Pig trailer
 * vehicle-td - Dog trailer
 * vehicle-m  - Prime mover
 * vehicle-s  - Semi trailer 
 * vehicle-l  - Lead trailer
 * vehicle-d  - Dolly
 * vehicle-a-x - Any vehicle with a single axle group with x axles
 * vehicle-a-x-y - Any vehicle with two axle groups with x and y axles
 */
	.truck-container {
		text-align: center;
		width: 100%;
		overflow: hidden;

		.truck-error-message {
			height: 35px;
			text-align: center;
		}
	}

	#truck-side-view .truck-container {
		margin-top: 70px;
		margin-left: 20px;
		margin-right: 10px;
	}

	#axle-weights .truck-container {
		margin-top: 60px;
		min-height: 300px;
	}

	.truck {
		display: inline-flex;
		white-space: nowrap;
		transform-origin: 0 0;
		text-align: left;
		position: relative;
	}

	.truck.truck-view-bottom {
		margin: 20px 0;
	}

	.truck * {
		display: flex;
		flex-shrink: 0;
	}

	.truck .vehicle {
		position: relative;
		justify-content: flex-end;
	}

	.truck .vehicle-m,
	.truck .vehicle-r,
	.truck .vehicle-td {
		justify-content: space-between;
	}

	.truck .vehicle-tp {
		justify-content: center;
	}

	.truck .vehicle-td,
	.truck .vehicle-tp {
		min-width: 360px;
	}

	.truck .vehicle-r {
		min-width: 430px;
	}

	.truck .vehicle-s {
		min-width: 590px;
	}

	.truck .vehicle-l {
		min-width: 490px;
	}

	.truck .vehicle-l.vehicle-a-4 {
		min-width: 550px;
	}

	.truck .vehicle-m + .vehicle {
		margin-left: -80px;
	}

	.truck .vehicle-m.vehicle-a-1-2 + .vehicle {
		margin-left: -150px;
	}

	.truck .vehicle-m.vehicle-a-1-3 + .vehicle {
		margin-left: -190px;
	}

	.truck .vehicle-m.vehicle-a-2-2 + .vehicle {
		margin-left: -160px;
	}

	.truck .vehicle-m.vehicle-a-2-3 + .vehicle {
		margin-left: -230px;
	}

	.truck .vehicle-tp,
	.truck .vehicle-td,
	.truck .vehicle-d {
		margin-left: 10px;
	}

	.truck .vehicle-d + .vehicle {
		margin-left: -150px;
	}

	.truck .vehicle-d.vehicle-a-3 + .vehicle {
		margin-left: -220px;
	}

	.truck .vehicle-l + .vehicle {
		margin-left: -160px;
	}
	/* Axle groups 
 * Class axle-group-x has x axles
 */
	.truck .axle-group {
		position: relative;
	}

	.truck.truck-view-side .axle-group {
		border-radius: 30px;
		height: 60px;
		margin: 142px 10px 60px 10px;
		z-index: 10;
	}

	.truck.truck-view-bottom .axle-group {
		margin: -15px 10px;
	}

	.truck .vehicle-s .axle-group {
		margin-left: 310px;
	}

	.truck .vehicle-r .axle-group,
	.truck .vehicle-m .axle-group {
		margin-left: 20px;
	}

	.truck .vehicle-d .axle-group {
		margin-left: 80px;
	}

	.truck .vehicle-td .drawbar + .axle-group {
		margin-left: 70px;
	}

	.truck .vehicle-tp .axle-group {
		margin-left: 130px;
		margin-right: 70px;
	}

	.truck .axle-group + .axle-group {
		margin-left: 130px;
	}

	.truck .vehicle-m .axle-group + .axle-group {
		margin-left: 110px;
	}

	.truck .vehicle-l .axle-group {
		position: absolute;
		right: 0;
	}

	.truck .vehicle-m .axle-group + .axle-group-3 {
		margin-left: 80px;
	}

	.truck .vehicle-m .axle-group + .axle-group-4 {
		margin-left: 0;
	}

	.truck .vehicle-m .axle-group-2 + .axle-group {
		margin-left: 50px;
	}

	.truck .vehicle-r .axle-group + .axle-group {
		margin-left: 270px;
		margin-right: 40px;
	}

	.truck .vehicle-r .axle-group + .axle-group-2 {
		margin-left: 220px;
	}

	.truck .vehicle-r .axle-group-2 + .axle-group-2 {
		margin-left: 150px;
	}

	.truck.truck-view-bottom .axle-group {
		height: ~"calc(100% + 30px)";
	}


	.truck.truck-view-side .axle-group .axle-group-weights {
		position: absolute;
		top: ~"calc(100% + 10px)";
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		font-size: 25px;
	}

	.truck.truck-view-side .axle-group .axle-group-weights .weight-box,
	.truck.truck-view-side .axle-group .axle-group-weights label.max {
		display: none;
	}

	.truck.truck-view-bottom .axle-group .axle-group-weights {
		display: none;
	}
	/* Axles
 * Class axle-x has x wheels
 */
	.truck .axle {
		width: 60px;
	}

	.truck .axle + .axle {
		margin-left: 10px;
	}

	.truck.truck-view-side .axle {
		height: 100%;
		border: 10px solid #E2E2E2;
		border-radius: 60px;
		position: relative;
	}

	.truck.truck-view-side .axle.noborder {
		border: none;
	}

	.truck.truck-view-bottom .axle {
		height: 100%;
		position: relative;
	}

	.truck.truck-view-bottom .axle::before {
		content: '';
		position: absolute;
		left: ~"calc(50% - 1.5px)";
		width: 3px;
		background-color: #f9fafc;
		top: 2px;
		height: ~"calc(100% - 4px)";
	}
	/* Wheels */
	.truck.truck-view-side .wheel {
		width: ~"calc(100% + 20px)";
		height: ~"calc(100% + 20px)";
		position: absolute;
		left: -10px;
		top: -10px;
		border-radius: 50%;
	}

	.truck.truck-view-bottom .wheel {
		width: 100%;
		height: 30px;
		background-color: #E2E2E2;
		position: absolute;
		left: 0;
		border-radius: 5px;
		flex-wrap: wrap;
		color: #000;
		padding: 2px;
	}

	.truck.truck-view-bottom .wheel:nth-child(1) {
		top: 0;
	}

	.truck.truck-view-bottom .axle-2 .wheel:nth-child(2),
	.truck.truck-view-bottom .axle-4 .wheel:nth-child(4) {
		bottom: 0;
	}

	.truck.truck-view-bottom .axle-4 .wheel:nth-child(2) {
		top: 35px;
	}

	.truck.truck-view-bottom .axle-4 .wheel:nth-child(3) {
		bottom: 35px;
	}

	.truck.truck-view-side:not(.truck-axle-weights) .wheel {
		i {
			font-size: 29px;
			padding: 14px 15px;
		}
	}

	.truck.truck-view-side:not(.truck-axle-weights) .wheel {
		i.icon-ban {
			font-size: 66px;
			padding: 0px 0px;
			transform: scaleX(-1);
			-moz-transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
			opacity: 0.5;
		}
	}

	.truck.truck-axle-weights .wheel {
		i.icon-ban::before {
			content: '';
		}
	}

	.truck.truck-view-bottom:not(.stats) .wheel {
		i {
			font-size: 15px;
			padding: 5px 19px;
		}
	}

	.truck.truck-view-bottom .wheel {
		i.icon-ban {
			font-size: 15px;
			padding: 5px 22px;
			transform: scaleX(-1);
			-moz-transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
			color: #898a8c;
		}
	}

	.truck.truck-view-bottom.sensors .wheel {
		i.icon-ban {
			display: none;
		}
	}

	.truck-axle-weights, .stats {
		.wheel.status-check,
		.wheel.status-critical {
			i::before {
				content: '';
			}
		}
	}

	.truck.wheel {
		i.icon-ban {
			font-size: 50px;
		}
	}

	.truck:not(.truck-axle-weights) .wheel.status-check {
		background-color: @state-warning;
		color: #f9fafc;
		z-index: 2;
	}

	.truck:not(.truck-axle-weights) .wheel.status-critical {
		background-color: @state-error;
		color: #f9fafc;
		z-index: 2;
	}

	.truck .wheel.status-good {
		color: #f9fafc;
		z-index: 2;
		border: none;
	}

	.truck.truck-view-side .wheel.status-good {
		border: 10px solid @state-success;
		color: #f9fafc;
		z-index: 2;
		background: none;
	}

	.truck.truck-view-side .wheel.status-offline {
		padding: 7px 14px;
		z-index: 2;
		background: none;
	}

	.truck.truck-view-side.truck-axle-weights .wheel.status-good {
		border: 10px solid #e2e2e2;
		background: none;
	}

	.truck .wheel label {
		display: none;
	}

	.truck.stats:not(.sensors) .wheel.status-check,
	.truck.stats:not(.sensors) .wheel.status-critical {
		.stat {
			color: #f9fafc;
		}
	}

	.truck.sensors .wheel.status-check,
	.truck.sensors .wheel.status-critical {
		.sensor {
			color: #f9fafc;
		}
	}


	.truck.stats:not(.sensors) .wheel label.stat,
	.truck.sensors .wheel label.sensor {
		font-size: 11.5px;
		flex-basis: 100%;
		text-align: center;
		display: block;
		line-height: 13px;
		word-wrap: break-word;
		white-space: pre-wrap;
		color: @black;
	}
	/* Cab: side view only */
	.truck .cab {
		display: none;
	}

	.truck.truck-view-side .cab {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		height: 138px;
		background-repeat: no-repeat;
		background-position: left bottom;
	}

	.truck.truck-view-side .vehicle-r .cab {
		background-image: url('@{ContentBaseUri}/Shared/Images/cab-1.HZus5ZocKVE73KUH6mahii7fqXI.svg');
		background-size: 120px 120px;
		width: 120px;
	}

	.truck.truck-view-side .vehicle-m .cab {
		background-image: url('@{ContentBaseUri}/Shared/Images/cab-2.EX2Y_l6orEPpv-d2rcUFtka79lc.svg');
		background-size: 162px 105px;
		width: 162px;
	}
	/* Drawbar */
	.truck .drawbar {
		display: block;
		position: absolute;
		background-color: #f9fafc;
		left: 0;
	}

	.truck.truck-view-side .drawbar {
		top: 130px;
		width: 90px;
		height: 8px;
	}

	.truck.truck-view-side .vehicle-td .drawbar,
	.truck.truck-view-side .vehicle-tp .drawbar {
		right: ~"calc(100% - 40px)";
	}

	.truck.truck-view-side .vehicle-d .drawbar {
		left: 0;
	}

	.truck.truck-view-side .drawbar::before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		bottom: 0;
		border-left: 26px solid #f9fafc;
		border-top: 26px solid transparent;
	}

	.truck.truck-view-bottom .drawbar {
		top: ~"calc(50% - 1px)";
		height: 2px;
	}

	.truck.truck-view-bottom .vehicle-td .drawbar,
	.truck.truck-view-bottom .vehicle-tp .drawbar {
		width: 60px;
	}

	.truck.truck-view-bottom .vehicle-d .drawbar {
		width: 70px;
	}
	/* Prime Mover, Lead trailer, Dolly: line above rear axle group */
	.truck.truck-view-side .vehicle-m .axle-group:last-child::after,
	.truck.truck-view-side .vehicle-l .axle-group::after,
	.truck.truck-view-side .vehicle-d .axle-group::after {
		content: '';
		position: absolute;
		width: ~"calc(100% + 10px)";
		height: 8px;
		right: 0;
		top: -12px;
		background-color: #f9fafc;
		border-radius: 0 4px 4px 0;
	}

	.truck.truck-view-side .vehicle-m .axle-group:last-child::after {
		width: ~"calc(100% + 80px)";
	}

	.truck.truck-view-side .vehicle-l .axle-group::after {
		width: ~"calc(100% + 40px)";
		max-width: 220px;
	}
	/* Box on top */
	.truck.truck-view-side .vehicle-r::before,
	.truck.truck-view-side .vehicle-tp::before,
	.truck.truck-view-side .vehicle-td::before,
	.truck.truck-view-side .vehicle-s::before,
	.truck.truck-view-side .vehicle-l::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 138px;
		right: 0;
		top: 0;
		z-index: -1;
		background-color: rgba(255,255,255,0.25);
		border: 8px solid #f9fafc;
		border-radius: 18px;
	}

	.truck.truck-view-side .vehicle-r::before {
		width: ~"calc(100% - 130px)";
	}

	.truck.truck-view-side .vehicle-tp::before,
	.truck.truck-view-side .vehicle-td::before {
		width: ~"calc(100% - 60px)";
	}

	.truck.truck-view-side .vehicle-s::before,
	.truck.truck-view-side .vehicle-l::before {
		height: 125px;
		border-bottom-right-radius: 0;
	}

	.truck.truck-view-side .vehicle-l::before {
		width: ~"calc(100% - 170px)";
		left: 0;
		right: auto;
	}
	/* Box: bottom view */
	.truck.truck-view-bottom .vehicle {
		height: 210px;
	}

	.truck.truck-view-bottom .vehicle::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
		background-color: rgba(255,255,255,0.25);
		border-radius: 10px;
		border: 2px solid #f9fafc;
	}

	.truck.truck-view-bottom .vehicle-d::before {
		width: calc(100% - 70px);
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.truck.truck-view-bottom .vehicle-tp::before,
	.truck.truck-view-bottom .vehicle-td::before {
		width: calc(100% - 60px);
	}
	/* Solid block with angled left side under trailer */
	.truck.truck-view-side .vehicle-s .axle-group:last-child::before,
	.truck.truck-view-side .vehicle-l::after {
		content: '';
		width: ~"calc(100% + 45px)";
		height: 0;
		position: absolute;
		border-top: 15px solid #f9fafc;
		border-left: 35px solid transparent;
	}

	.truck.truck-view-side .vehicle-s .axle-group:last-child::before {
		width: ~"calc(100% + 45px)";
		right: -10px;
		top: -20px;
	}

	.truck.truck-view-side .vehicle-l::after {
		width: 100px;
		right: 170px;
		top: 123px;
	}

	.truck.truck-view-side .vehicle-l.vehicle-a-4::after {
		width: 150px;
	}
	/* Alert messages */
	.truck.truck-view-side .axle-group .alert {
		background-color: #E2E2E2;
		color: #000;
		font-size: 24px;
		position: absolute;
		bottom: ~"calc(100% + 25px)";
		left: 50%;
		transform: translateX(-35%);
		padding: 10px 15px;
		border-radius: 5px;
		z-index: 10;
		display: block;
	}

	.truck.truck-view-side.small-scale .axle-group .alert {
		font-size: 30px;
	}

	.truck.truck-view-side .vehicle-s .axle-group .alert,
	.truck.truck-view-side .vehicle-l .axle-group .alert,
	.truck.truck-view-side .vehicle-d .axle-group .alert {
		bottom: ~"calc(100% + 5px)";
	}

	.truck.truck-view-side .axle-group .alert::after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 100%;
		width: 0;
		height: 0;
		border-top: 10px solid #E2E2E2;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	}

	.truck.truck-view-side .axle-group.status-check .alert {
		background-color: @state-warning;
		color: #F9FAFC;
	}

	.truck.truck-view-side .axle-group.status-check .alert::after {
		border-top-color: @state-warning;
	}

	.truck.truck-view-side .axle-group.status-critical .alert {
		background-color: @state-error;
		color: #F9FAFC;
	}

	.truck.truck-view-side .axle-group.status-critical .alert::after {
		border-top-color: @state-error;
	}

	.truck.truck-view-bottom .axle-group .alert {
		display: none;
	}
	/* Left/Right Scroller */
	.truck-scroller {
		position: relative;
		width: ~"calc(100% + 120px)";
		margin-left: -60px;
	}

	.truck-scroller button.scroll-left,
	.truck-scroller button.scroll-right {
		position: absolute;
		z-index: 10;
		top: 50%;
		transform: translateY(-50%);
		height: 38px;
		width: 22px;
		min-width: 0;
		padding: 0;
		text-align: center;
		margin: 0;
		background-size: contain;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-color: transparent;
		border: none;
	}

	.truck-scroller button.scroll-left {
		left: 25px;
		background-image: url('@{ContentBaseUri}/Shared/Images/arrow-left.THWLHlPjQMxV8iAA1mE4MKQQrfw.svg');
	}

	.truck-scroller button.scroll-right {
		right: 25px;
		background-image: url('@{ContentBaseUri}/Shared/Images/arrow-right.pMzWbviEn3RF3g1_-fG7oVKKsZI.svg');
	}

	.truck-scroller button[disabled] {
		opacity: 0.3;
	}

	.truck-scroller button.hidden {
		display: none;
	}

	.truck-scroller {
		overflow: hidden;
		width: ~"calc((100% + 100px) / 1.5)";
		transform-origin: 50% 0;
		transform: scale(1.5);
		margin: 0 auto 0 auto;
	}

	.truck-scroller .truck-container {
		margin-top: 0;
		text-align: left;
	}

	.truck-scroller .truck.animated {
		transition: transform .5s ease;
	}

	.truck-scroller .truck.animated .vehicle {
		transition: opacity .5s ease;
	}

	.truck-scroller .truck .vehicle:not(.selected) {
		opacity: 0.2;
	}
	/* Better click handling for bottom view; clicks on vehicles "on top" should not override clicks on wheels underneath */
	.truck.truck-view-bottom .vehicle-s {
		z-index: 3;
	}

	.truck.truck-view-bottom .vehicle-l {
		z-index: 4;
	}

	.truck.truck-view-bottom .vehicle-m {
		z-index: 5;
	}

	.truck.truck-view-bottom .vehicle.selected {
		z-index: 6;
	}

	.truck.truck-view-bottom .vehicle-d {
		z-index: 7;
	}
	/* Axle weights page */
	.truck.truck-axle-weights .axle-group {
		margin-bottom: 120px;
	}

	.truck.truck-axle-weights .axle-group .axle-group-weights {
		display: block;
		min-width: 100%;
	}

	.truck.truck-axle-weights .axle-group .axle-group-weights label.weight-caption {
		min-width: 100%;
		text-align: center;
		display: block;
	}

	.truck.truck-axle-weights .axle-group .axle-group-weights label.max {
		min-width: 100%;
		text-align: center;
		display: block;
		padding: 20px 10px;
		font-size: 24px;
	}

	.truck.truck-axle-weights .axle-group .axle-group-weights .weight-box {
		display: flex;
	}

	.truck.truck-axle-weights .axle-group .axle-group-weights label.max {
		display: block;
		color: rgba(255, 255, 255, 0.6);
	}

	.truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::before,
	.truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::after {
		text-align: right;
		position: absolute;
		right: ~"calc(100% + 10px)";
		padding: 20px 10px;
		text-transform: capitalize;
	}

	.truck.truck-axle-weights .axle-group .axle-group-weights input.weight,
	.truck.truck-axle-weights .axle-group .axle-group-weights label.max,
	.truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::before,
	.truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::after {
		font-size: 30px;
	}

	.truck.truck-axle-weights.small-scale .axle-group .axle-group-weights input.weight,
	.truck.truck-axle-weights.small-scale .axle-group .axle-group-weights label.max,
	.truck.truck-axle-weights.small-scale .vehicle:first-child .cab + .axle-group .axle-group-weights::before,
	.truck.truck-axle-weights.small-scale .vehicle:first-child .cab + .axle-group .axle-group-weights::after {
		font-size: 34px;
	}

	.weight-box {
		width: 180px;
		height: 42px;
		margin: 15px auto;
	}

	.gwNumeric {
		width: 100%;

		input {
			border-radius: 0;
			padding-top: 3px;
			padding-right: 65px;
			font-size: 30px;
			background-color: inherit;
			color: inherit;
			border-color: inherit;
			border-width: 0 0 1px 0;

			&.alert-danger {
				background-color: inherit;
				color: inherit;
				border-color: inherit;
			}

			&:focus {
				/* Force !important to override !important used in \Glow\DotNet\HTML\Client\Client\Content\Themes\Shared\components\_input.less */
				background-color: transparent !important;
				border-width: 0 0 1px 0 !important;
			}
		}
	}

	label.weight-unit {
		order: 2;
		margin-left: -60px;
		font-size: 30px;
		align-self: center;
		color: @Content_TextColor_Fade50;

		&:after {
			content: "\f040";
			font-family: 'icomoon';
			margin: 5px;
			font-size: 22px;
			color: @Grid_TitleFontColor;
		}
	}

	.alerts {
		margin-left: auto;
		align-self: center;
	}

	button.btn-alerts {
		padding: 0;
		min-width: 0;
	}

	.btn-alerts--icon {
		width: 24px;
		font-size: 24px;
		margin: 14px;
	}

	.alert-count {
		border-radius: 5px;
		height: 18px;
		width: 18px;
		padding: 4px 6px;
		font-size: 12px;
	}

	.gwValidationSummary {
		width: 100%;
	}

	.tap-to-edit-instruction {
		font-size: 18px;
		font-style: italic;
		margin: 0 80px;

		&:before {
			content: "\f0a6";
			font-family: 'icomoon';
			margin: 0 5px;
			font-style: normal;
		}
	}
}
