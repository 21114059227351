.g-workflow-menu-dialog {
	.modal-dialog {
		&.g-modal-autoresize {
			.modal-content {
				min-width: 800px;
				min-height: 500px;
			}
		}
	}
}
