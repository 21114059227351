.g-code-desc-container {
	cursor: pointer;
	display: flex;
	background: @Theme_Background;
	padding: 0;
	font-weight: normal;
	line-height: @line-height-base;

	.g-code {
		color: @Theme_GeneralText;
		padding: 2px 8px;
		background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
		border-right: 1px solid #d3d6d9;
		flex-grow: 0;
		flex-shrink: 0;
		min-width: 56px;
		white-space: nowrap;
	}

	.g-desc {
		color: @Theme_GeneralText;
		padding: 2px 8px;
		padding-right: 16px;
	}
}

.g-code-only-container, .g-desc-only-container {
	cursor: pointer;
	display: block;
	padding: 3px 8px;
	clear: both;
	font-weight: normal;
	line-height: @line-height-base;
	color: @dropdown-link-color;
}

.select2-results__option--highlighted, .gwSearchBox-results > .active {
	&.gwAddressSearchBox-results-item,
	&.g-location-search-item,
	&.gwSearchBox-show-more,
	.g-code-only-container,
	.g-desc-only-container,
	.g-code-desc-container {
		color: @dropdown-link-active-color;
		text-decoration: none;
		outline: 0;
		background: @dropdown-link-active-bg !important;

		.g-code, .g-desc, .g-orginfo, .g-address-code, .g-address > div, .main, > a {
			color: @dropdown-link-active-color !important;
		}

		.g-code, .g-desc {
			background: @dropdown-link-active-bg !important;
		}

		> a {
			cursor: pointer;
		}
	}
}
