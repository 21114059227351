.gwMapsContainer {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: stretch;
	flex-flow: row nowrap;

	.gwEventLog {
		padding-right: 5px;
		height: 100%;
		flex: 0 1 auto;

		.g-sidebar-left {
			height: 100%;
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-start;

			.g-sidebar-wrap {
				width: 600px;
				border-bottom: 1px solid @SidebarPanel_BorderColor;
				border-right: 1px solid @SidebarPanel_BorderColor;
				border-radius: 5px;
			}

			.g-grip.list-unstyled.left {
				align-self: center;
			}

			.g-sidebar-contents {
				overflow: hidden;
				height: 100%;
				position: relative;

				.g-map-plugin-launcher {
					height: 100%;

					.gwEventLogSideBar {
						height: 100%;

						div.g-close-sidebar {
							float: right;
							height: 24px;
							cursor: pointer;
							width: 16px;
							margin-top: 2px;

							i {
								left: 0px;
							}
						}

						.gwGroupBox-container {
							height: 100%;

							> .gwGroupBox {

								.panel-title {
									width: 90%;
								}

								.panel-collapse {
									height: calc(~'100% - 33px');
									width: 100%;

									.panel-body {
										height: 100%;
									}

									.padded {
										padding: 2px 10px 0;
										height: 100%;

										.search-results {
											height: 100%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}



	.gwMaps {
		height: 100%;
		flex: 1 1 50px;
		display: flex;
		align-items: stretch;
		flex-flow: row nowrap;

		iframe {
			height: 100%;
		}

		.map-wrapper {
			height: 100%;
			flex: 1 1 50px;
			padding-top: 1px;
			padding-right: 5px;
			position: relative;

			.map {
				height: 100%;
				border: @sidebar-border;
				border-radius: 5px;

				.cluster div, .cluster-label {
					font-weight: normal !important;
					color: #fff !important;
				}
			}
		}

		.g-sidebar-right {
			flex: 0 1 auto;
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-start;

			.g-sidebar-wrap {
				min-width: 350px;
				border-bottom: 1px solid @SidebarPanel_BorderColor;
				border-right: 1px solid @SidebarPanel_BorderColor;
				border-radius: 5px;
			}

			.gwSearchBox {
				height: 32px;
			}

			.g-grip.list-unstyled {
				align-self: center
			}
		}
	}
}

.gwLocationTrackingSidebar, .gwGeofencingSideBar {
	> .gwGroupBox-container > .gwGroupBox {
		background: transparent;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;

		.padded {
			padding: 2px 2px 0;
		}
	}

	.search-options {
		padding: 2px;

		> div {
			margin-bottom: 2px;
		}

		.input-group {
			height: 28px;

			input {
				height: 28px;
				padding-top: 0;
				font-style: italic;
				background-image: none;

				&[disabled] {
					cursor: default;
					background-color: #fff;
				}
			}

			.input-group-addon {
				height: 28px;

				i {
					margin-top: 0;
				}

				.icon-search {
					color: @Theme_Icons;
					animation: none;
				}
			}
		}
	}

	.advanced-filter-tag {
		position: absolute;
		top: 4px;
		left: 7px;
		z-index: 2;
		font-style: italic;

		a {
			margin-right: 2px;

			&:hover {
				color: #003479;
			}
		}
	}

	.search-results {
		padding: 0;
		margin: 2px 0 0 0;
		overflow: hidden;
		background: none;

		.view-route {
			padding: 6px 12px;
		}

		> .search-result-group {
			list-style: none;
			background: white;

			> .search-result-items {
				padding: 0;
				overflow: hidden;

				.search-result-item {
					padding: 4px 8px;
					font-size: 12px;
					color: @Theme_GeneralText;
					list-style: none;
					cursor: pointer;
					background: none;

					&.active {
						background-color: #ebeef3;
					}

					& + .search-result-item {
						border-top: 1px solid #d3d6d9;
					}
				}
			}

			& + .search-result-group {
				margin-top: 2px;
			}
		}

		.search-result-title {
			margin: 0;
			font-size: 12px;
			background: none;

			&.outlier {
				opacity: 0.5;
			}

			a {
				display: flex;
				line-height: 40px;
				align-items: center;
				color: @Theme_Icons;
				font-weight: 600;
				text-decoration: none;
				padding: 0px 8px 0px 7px;

				i {
					top: 6px;
					right: 8px;
					color: @Theme_Icons;
					.rotate(180deg);
					font-size: 10px;
				}

				&.collapsed i {
					.rotate(0);
				}

				span.g-messages {
					position: relative;

					.unread-icon {
						position: absolute;
						height: 10px;
						width: 10px;
						left: 56%;
						top: 20%;
						background-color: red;
						border-radius: 50%;
						border: solid 1px white;
					}
				}

				span.g-speed-alert {
					color: @state-error;
				}

				span.g-icons {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin-left: auto;

					i {
						margin: 5px 10px;
						transform: none;
						font-size: 14px;
					}
				}

				.g-speed-alert.icon-tachometer:before {
					margin: 0 5px;
					font-size: 17px;
				}
			}
		}

		.actions {
			float: right;
			margin: -1px -4px 1px 8px;

			i {
				color: @Theme_Icons;

				&:hover {
					opacity: 0.5;
				}
			}

			.gps-actions-icon {
				padding: 2px 5px;
			}
		}

		.type {
			font-weight: bold;
			color: @Theme_DefaultButton;
		}

		.driver {
			font-weight: bold;
		}

		.time {
			margin: 8px 0;
		}

		.g-dashboard {
			display: inline-flex;
		}

		.road-sign {
			width: 30px;
			height: 30px;
			border: 3px solid #EE0000;
			border-radius: 100px;
			margin: 10px 0 0 10px;
		}

		.speed-limit {
			text-align: center;
			padding-top: 4px;
			color: #000000;
			font-family: Trebuchet MS;
			font-weight: bold;
			font-size: 12px
		}
	}

	.history-options {
		padding-top: 4px;
		margin-top: 4px;
		border-top: 1px solid #d3d6d9;

		.header {
			margin-bottom: 4px;
			font-weight: bold;
			color: #003479;
		}

		.g-anchored {
			position: relative;
		}

		.start-date-time {
			height: 44px;

			input {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		.end-date-time {
			height: 43px;
			margin-bottom: 8px;

			input {
				border-top: none;
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
		}
	}

	.g-geofencetool {
		overflow: hidden;
		margin-bottom: 6px;
		margin-top: 6px;
		height: 34px;
		padding-left: 5px;
		padding-right: 5px;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		flex-flow: row nowrap;

		.g-geofencetool-button {
			flex: 1 1 20px;
			display: flex;
			justify-content: flex-start;
			align-items: stretch;
			flex-flow: row nowrap;
			max-width: 50%;

			& + .g-geofencetool-button {
				margin-left: 2px;
			}

			.btn {
				flex: 1 1 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
			}
		}
	}

	.g-drawing.disabled,
	.g-geofencesearch.disabled {
		color: @InputControl_ReadOnlyTextColor;
		border-color: @Button_BorderDisabledColor;
		background-color: @Button_BackgroundDisabledColorStart;
	}

	.g-geofence-result {
		padding: 5px 7px;
		background-color: white;

		& + .g-geofence-result {
			border-top: 1px solid #d3d6d9;
		}

		.g-geofencetool {
			padding: 0;
		}
	}

	.g-geofence-drawing, .g-geofence-search {
		margin-bottom: 6px;
		border-top: 1px solid #d3d6d9;
		border-bottom: 1px solid #d3d6d9;

		.g-address-geofence {
			font-size: 12px;
			color: @Theme_GeneralText;

			.company-header {
				font-weight: bold;
			}

			.g-sidebar-contents-description.g-no-additional-info {
				text-align: center;
			}
		}

		.g-address-geofence.readOnly {
			cursor: text;

			.company-header, .address {
				opacity: 0.5;
			}
		}

		.active {
			background-color: #ebeef3;

			.g-address-geofence {
				cursor: pointer;
			}

			.g-address-geofence.readOnly {
				cursor: text;
			}
		}
	}
}

.gwMapsContainer {
	.refresh-timer {
		position: absolute;
		top: 12px;
		left: 11px;
		font-size: 18px;
		font-weight: 500;
		color: @Theme_ValueText;
		background-color: @InputControl_BackgroundColorStart;
		border-radius: 3px;
		.box-shadow(0 1px 4px -1px rgba(0,0,0,.298039));

		div {
			float: left;
			padding: 7px 16px 8px 17px;
		}

		i {
			display: block;
			float: right;
			padding: 8px 13px 7px;
			cursor: pointer;
			border-left: 1px solid @grey;
			line-height: inherit;
			font-size: 18px;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	.map-tooltip {
		position: absolute;

		.content {
			width: 300px;
			min-width: 180px;
			max-width: 500px;
			max-height: 230px;
			font-size: 12px;
			color: #000;
			border: 1px solid @black;
			border-radius: 5px;
			.box-shadow(2px 2px 2px 0 rgba(87,90,93,.4));
		}

		.cluster {
			overflow: hidden;
			background-color: #fafafa;

			ul {
				overflow: auto;
				-webkit-overflow-scrolling: touch;
				max-height: 202px;
				padding: 0;
				margin: 0;
			}

			li {
				padding: 6px 8px 4px;
				list-style-type: none;
				cursor: pointer;
				border-bottom: 1px solid #d3d6d9;

				&:last-child {
					border-bottom: 0;
				}

				&.active {
					background-color: #ebeef3;
				}
			}

			> div {
				padding: 4px 8px;
				background-color: #f2f2f2;
				border-top: 1px solid #d3d6d9;
			}
		}

		.single {
			padding: 6px 8px 4px;
			background-color: #f2f2f2;
		}

		h4 {
			margin: 0 0 2px;
			font-size: 12px;
			color: #003479;
		}

		.arrow {
			position: absolute;

			&:before,
			&:after {
				position: absolute;
				width: 0;
				height: 0;
				border: solid transparent;
				pointer-events: none;
				content: '';
			}

			&:before {
				margin-left: -7px;
				border-color: rgba(51,51,51,0);
				border-width: 6px 7px;
			}

			&:after {
				margin-left: -6px;
				border-color: rgba(242,242,242,0);
				border-width: 5px 6px;
			}

			&.bottom {
				bottom: 1px;

				&:before,
				&:after {
					top: 100%;
				}

				&:before {
					border-top-color: #333;
				}

				&:after {
					border-top-color: #f2f2f2;
				}
			}

			&.top {
				top: 1px;

				&:before,
				&:after {
					bottom: 100%;
				}

				&:before {
					border-bottom-color: #333;
				}

				&:after {
					border-bottom-color: #f2f2f2;
				}
			}
		}
	}

	.over-limit {
		top: 80px;
		width: 100%;

		.content {
			width: 367px;
			margin: 0 auto;
		}
	}

	.g-marker-label {
		position: relative;
		padding-left: 16px;

		.content {
			width: 100%;
			height: 18px;
			max-width: 250px;
			padding: 0 4px 0 2px;
			font-family: @default-font;
			font-size: 12px;
			color: #000;
			background-color: #fff;
			border: 1px solid #003479;
			border-left: none;
			.text-overflow();
		}

		.curve {
			position: absolute;
			background: none;
			top: 0px;
			left: 9px;
		}
	}
}

.gwLocationSearchBox .input-group-addon i.icon-map-marker {
	font-size: 18px;
}

.g-location-search-item {
	.main {
		font-weight: 500;
	}

	.additional {
		font-size: 11px;
		color: #999;
	}

	&.active .additional {
		color: #fff;
	}
}

.g-activity-indicator {
	border-radius: 50%;
	width: 12px;
	height: 12px;
	margin: 0 5px;
}

.g-detail-text {
	flex-grow: 1;
	margin: 0 10px;
	.text-overflow();
}

.g-inactive {
	background-color: @state-error;
}

.g-slow {
	background-color: @state-warning;
}

.g-active {
	background-color: @state-success;
}

.g-location-tracking-root i.g-location-popup,
.g-geofencing-root i.g-geofencing-popup {
	font-size: 20px;
	color: @Theme_Icons;
}

.g-autogeofence {
	font-size: 18px;
	color: @Theme_ValueText;
	background-color: @InputControl_BackgroundColorStart;
	border: 0;
	border-radius: 3px;
	cursor: pointer;
	.box-shadow(0 1px 4px -1px rgba(0,0,0,.298039));
	padding: 7px 16px 8px 17px;
	.user-select(none);
	top: 12px;
	position: relative;

	&[disabled] {
		cursor: default;
		color: @btn-link-disabled-color;
	}
}

.message-multiple-button-container {
	width: 100%;
	padding: 10px;
}

.message-multiple-button {
	display: flex;
	align-items: baseline;
}

.message-multiple-button__image {
	margin-right: 10px;
}
