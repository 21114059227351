.g-board-meeting-mode {
	position: absolute;
	top: -5px;
	opacity: 0;
	.transition(0.3s, ease-in-out);
	pointer-events: none;
	right: 0;

	&.g-board-meeting-mode-in-view-mode {
		opacity: 1;
		z-index: 5;
		pointer-events: initial;
		right: 130px;
	}

	&:not(.g-board-meeting-mode-in-view-mode) {
		right: 0px !important;
	}

	.g-board-meeting-mode-container {
		.element-elevation(2);
		border-bottom-left-radius: @rounded-corners;
		border-bottom-right-radius: @rounded-corners;
		display: flex;
		height: 100%;
		overflow: hidden;
		background-color: @Grid_HeaderBackgroundColorEnd;
		padding-top: 2px;

		.g-timer,
		.g-board-meeting-mode-controls {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			padding: 0 5px;
		}

		.g-timer {
			background-color: @white-opacity-overlay;
			font-weight: bold;
			padding: 0 10px;

			.g-timer-text {
				margin-left: 8px;
			}

			.g-date-text {
				margin-left: 8px;
				margin-right: 8px;
				white-space: nowrap;
			}
		}

		.g-board-meeting-mode-controls {
			.g-board-meeting-control-button {
				width: 14px;
				padding: 15px 8px;
				height: 100%;
				width: 100%;

				> i {
					width: 12px;
				}
			}
		}
	}
}
