.panel-body {
	&.jspScrollable {
		outline: none;
	}

	.jspVerticalBar {
		z-index: 5;
		width: 5px;
		background: transparent;
	}

	.jspHorizontalBar {
		height: 0;
	}

	.jspTrack {
		height: 100%;
	}

	.jspDrag {
		background: @Theme_Hover;
	}
}

.preview-holder {
	position: absolute;
	z-index: 3;
	cursor: move;
	background: #f2f2f2;
	border: 1px solid #d3d6d9;
	border-radius: 4px;
	.opacity(.6);
}

.g-stack-container {
	position: absolute;
	overflow: hidden;
	border: 1px solid @InputControl_BorderBrushStart;

	&.active {
		border-color: @input-border-focus !important;
	}

	&:not([data-disable-drag="true"]) {
		cursor: move;
	}
}

.g-stack-container > .g-container {
	visibility: hidden;
}

.g-stack-container > .g-container.g-selected {
	visibility: visible;
}

.g-stack-container-buttonpane {
	position: absolute;
	top: 2px;
	right: 2px;
	z-index: 5;

	li {
		display: inline-block;
	}
}

.g-stack-container-menu-item {
	> a > i + span {
		margin-left: 5px;
	}

	.g-btn-hide {
		position: absolute;
		top: 50%;
		right: 5px;
		margin-top: -7px;
	}

	.g-btn-configure-list {
		top: 1px;
		right: 21px;
		padding: 0;
		background: none;
	}

	.g-btn-loading .icon-cog {
		display: none;
	}

	.g-loading-animation .icon-spinner {
		font-size: 14px;
	}

	&:hover .g-btn-configure-list,
	&:hover .g-btn-hide:not(.disabled) {
		color: #fff;
	}
}

.popover-content ul > li.g-stack-container-menu-item .g-loading-animation .icon-spinner {
	margin-right: 0;
}

.g-configurable-container {
	z-index: 1;

	&:hover {
		z-index: 6;
	}

	&.dragging {
		z-index: 7;
	}

	&.active {
		z-index: 5;
		border-color: @input-border-focus;
	}

	&.closing {
		z-index: 4;
	}

	> .gwListView {
		height: 100%;
		overflow: hidden;
	}

	.gwTrackingControl.g-widget-config-mode {
		.gwTextBox-textarea {
			height: calc(~'100% - 40px');
		}

		.btn {
			cursor: inherit;
		}
	}
}

.draggable {
	cursor: move;
}

.g-btn-drag {
	left: -9px;
	padding: 2px;
}

.g-configuration-master-list {
	height: 100%;
}

.g-configurable-custom {
	> .g-btn-hide {
		right: -9px;
		padding: 1px 3px 2px;
		cursor: pointer;
	}

	.g-btn-drag,
	> .g-btn-hide {
		position: absolute;
		top: -9px;
		z-index: 5;
		display: none;
		font-size: 14px;
		color: #fff;
		background: @Theme_DefaultButton;
		border-radius: 50%;
	}

	> .g-btn-hide {
		&.disabled,
		&.disabled:hover {
			background: #ccc;
		}

		&.disabled {
			cursor: default;
		}
	}

	.g-btn-drag:hover,
	> .g-btn-hide:not(.disabled):hover {
		background: @Theme_Hover;
	}

	&.active,
	&.dragging,
	&:hover {
		.g-btn-drag,
		> .g-btn-hide {
			display: block;
		}
	}

	&:not(.active) {
		.g-btn-drag,
		> .g-btn-hide:not(.disabled) {
			background: @Theme_ValueText;
		}
	}

	> .gs-resize-handle-both {
		background-size: contain;
	}
}

.g-configurable-shell .g-menu-item-icon.icon-cog {
	display: none;
}

.g-stack-container-buttonpane,
.g-stack-container-menu-item .g-btn-hide,
.g-stack-container .g-btn-hide,
.g-configurable-default .g-btn-hide,
.g-btn-configure-list {
	color: @GroupBox_HeaderTextColor;
}

.g-stack-container-buttonpane li:hover i {
	color: @Theme_Hover;
	cursor: pointer;
}

.g-stack-container .g-btn-hide,
.g-configurable-default .g-btn-hide,
.g-btn-configure-list {
	position: absolute;
	top: 2px;
	right: 2px;
	z-index: 5;
	cursor: pointer;
	background: @white;
	border-radius: 50%;
	padding: 2px;

	&:hover {
		color: @Theme_Hover;
	}

	&.disabled,
	&.disabled:hover {
		color: #ccc;
	}

	&.disabled {
		cursor: default;
	}

	.gwDataGrid & {
		top: 37px;
		right: 5px;
		z-index: 6;

		&:not(.disabled) {
			color: @Grid_HeaderTextColor;

			&:hover {
				color: #666;
			}
		}
	}
}

.g-btn-configure-list {
	top: 2px;
	right: 18px;
}

.g-configurable-default:hover,
.g-configurable-default.active {
	.g-config-widget-style-shadow {
		border: 1px solid @InputControl_BorderBrushStart;
	}
}

.g-configurable-custom:hover,
.g-configurable-custom.active {
	> .gwGroupBox,
	.g-config-widget-style-shadow {
		.box-shadow(0 0 6px #c7caca);

		&.alert-danger {
			.alert-danger-highlight-shadow();
		}

		&.alert-message-error {
			.alert-message-error-highlight-shadow();
		}

		&.alert-info {
			.alert-info-highlight-shadow();
		}

		&.alert-warning {
			.alert-warning-highlight-shadow();
		}
	}
}

.g-configurable-custom {
	> .gwGroupBox,
	.g-config-widget-style-shadow {
		&.alert-danger {
			.alert-danger-shadow();
		}

		&.alert-message-error {
			.alert-message-error-shadow();
		}

		&.alert-info {
			.alert-info-shadow();
		}

		&.alert-warning {
			.alert-warning-shadow();
		}

		&.gwValidation-highlight {
			&.alert-danger {
				.alert-danger-highlight-shadow();
			}

			&.alert-message-error {
				.alert-message-error-highlight-shadow();
			}

			&.alert-info {
				.alert-info-highlight-shadow();
			}

			&.alert-warning {
				.alert-warning-highlight-shadow();
			}
		}
	}
}

.gs-w:hover span.gs-resize-handle {
	opacity: 0;
}

.g-configurable-custom:hover > span.gs-resize-handle,
.g-configurable-default:hover > span.gs-resize-handle,
.g-stack-container:hover > span.gs-resize-handle {
	opacity: 1;
}

span.gs-resize-handle-x {
	bottom: 0;
}

span.gs-resize-handle-y {
	right: 0;
}

.g-config-widget-style-default {
	background-color: @InputControl_BackgroundColorEnd;
}

.g-config-widget-style-default,
.g-config-widget-style-border,
.g-configurable-default .gwAsyncImage,
.g-configurable-default .glow-vue-component {
	border: 1px solid @InputControl_BorderBrushStart;
}

div.g-config-widget-with-icon {
	display: table;
	width: 100%;
	height: 100%;

	i {
		display: table-cell;
		padding-right: 8px;
		text-align: right;
		vertical-align: middle;
		color: @Theme_Icons;
	}
}

.g-config-widget-style-border {
	&.alert-danger,
	.alert-danger & {
		.alert-danger-config();
	}

	&.alert-message-error,
	.alert-message-error & {
		.alert-message-error-config();
	}

	&.alert-warning,
	.alert-warning & {
		.alert-warning-config();
	}

	&.alert-info,
	.alert-info & {
		.alert-info-config();
	}
}

.g-config-widget-style-validation,
.g-config-widget-style-default {
	&.alert-danger {
		.alert-config-validation-style(@state-error-text, @state-error-pale, @state-error-hover, @state-error);
	}

	&.alert-message-error {
		.alert-config-validation-style(@state-message-error-text, @state-message-error-pale, @state-message-error-hover, @state-message-error);
	}

	&.alert-info {
		.alert-config-validation-style(@state-info-text, @state-info-pale, @state-info-hover, @state-info);
	}

	&.alert-warning {
		.alert-config-validation-style(@state-warning-text, @state-warning-pale, @state-warning-hover, @state-warning);
	}

	&.gwValidation-highlight.gwTile[data-color],
	&.gwValidation-highlight {
		&.alert-danger {
			.alert-danger-highlight-config();
		}

		&.alert-message-error {
			.alert-message-error-highlight();
		}

		&.alert-info {
			.alert-info-highlight();
		}

		&.alert-warning {
			.alert-warning-highlight();
		}
	}
}

.g-config-widget-style-validation.gwLabel {
	border: 1px solid @InputControl_BorderBrushStart;
}

.g-configurable-default .g-widget-config-mode {
	&.gwDataGrid {
		.border-bottom-radius(4px);
	}
}

.g-configurable-default.active .g-config-widget-style-border,
.g-configurable-default.active .g-widget-config-mode,
.g-configurable-default.dragging .g-widget-config-mode,
.g-configurable-default.active .gwLabel,
.g-configurable-default.dragging .gwLabel,
.g-configurable-default.active .gwAsyncImage,
.g-configurable-default.dragging .gwAsyncImage,
.g-configurable-default.active .glow-vue-component,
.g-configurable-default.dragging .glow-vue-component {
	border-color: @input-border-focus !important;
}

.g-configurable-default:not(.g-overlap-widget):not(.g-overlap-container),
.g-configurable-default label,
.g-configurable-default .btn {
	cursor: move;
}

.g-configurable-widget {
	z-index: 4;
	overflow: hidden;

	&.dragging {
		z-index: 5;
	}

	.g-uneditable,
	.dateclockpicker-readonly .input-group-addon,
	.gwTriStateRadioButton.captionLeft,
	.gwTriStateRadioButton.captionLeft .gwTriStateRadioButton-label,
	.gwCheckBox.gwCheckBox--readonly {
		cursor: inherit;
	}

	.gwTriStateRadioButton.captionLeft {
		background-color: @InputControl_BackgroundColorEnd;
	}

	.gwMeasure {
		.g-measure-divider {
			position: absolute;
			right: 62px;
			bottom: 0;
		}

		&.g-readonly-measure {
			cursor: inherit;
		}
	}
}

.gwJobDocAddress-container.g-configurable-widget {
	overflow: visible;

	.gwJobDocAddress {
		height: 100%;
	}
}

.g-configuration-sidebar {
	ul {
		margin-bottom: 0;
		font-size: 12px;
	}

	li {
		position: relative;

		&.active {
			background: @Grid_ViewportSelectedRowColor !important;
		}

		&:first-child {
			border-top-color: #ccc;
		}

		&:not(.gwConfigurablePanels-no-results):hover {
			border-color: #aaa;
		}

		&.g-has-alerts > a {
			padding-right: 12px;
		}

		.alert-count {
			top: 2px;
			right: 2px;
		}

		> a {
			display: block;
			padding: 8px;
			color: @Theme_GeneralText;
			.text-overflow();

			&:hover {
				color: @Theme_HoverText;
				background-color: @Theme_Hover;

				i,
				i.disable-icon {
					color: @Theme_HoverText;
				}
			}

			i {
				margin-right: 3px;
			}

			i.disable-icon {
				color: @Theme_FieldTitle;
			}

			span i {
				color: @Theme_DefaultButton;
			}

			i,
			span span {
				vertical-align: middle;
			}
		}
	}

	h3.panel-title {
		padding-left: 0;
		color: @InputControl_LabelColor;
	}

	.panel-heading h3 {
		display: block;
		.text-overflow();
	}

	.gwCheckBox--bordered {
		min-width: (@g-sidebar-width - 1);
	}

	.gwImageEdit-container {
		height: 150px;

		.gw-img-edit {
			padding-top: 21px;
			border-radius: 0;
			background-color: @InputControl_BackgroundColorEnd;
		}
	}
}

.g-configuration-sidebar__heading {
	padding-left: 8px;
	padding-right: 8px;
	margin-top: 5px;
	margin-bottom: 3px;
	color: @Theme_FieldTitle;
	.text-overflow();

	i {
		margin-right: 1px;
	}
}

.g-configuration-sidebar-active {
	.gwGroupBox {
		> div.panel-heading {
			background-color: @Theme_Hover;

			a,
			a i {
				color: @Theme_HoverText;
			}
		}
	}
}

.g-configuration-sidebar-no-results {
	padding: 8px;
	color: @Theme_FieldTitle;
	text-align: center;
}

.g-configuration-sidebar-addl-item {
	&.alert-danger {
		.alert-danger-mixins();
	}

	&.alert-message-error {
		.alert-message-error-mixins();
	}

	&.alert-warning {
		.alert-warning-mixins();
	}

	&.alert-info {
		.alert-info-mixins();
	}

	&.gwValidation-highlight {
		&.alert-danger {
			.alert-danger-highlight();
		}

		&.alert-message-error {
			.alert-message-error-highlight();
		}

		&.alert-info {
			.alert-info-highlight();
		}

		&.alert-warning {
			.alert-warning-highlight();
		}
	}
}

.g-widget-config-mode {
	&.gwJobDocAddress {
		.g-widget-config-mode {
			position: relative;
			height: 42px;
			border-radius: @InputControl_BorderRoundedCorners;
			background-color: @InputControl_BackgroundColorEnd;

			&.disabled {
				background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
			}
		}

		.gwJobDocAddress-inner .g-widget-config-mode {
			border-radius: 0;
		}

		.gwJobDocAddress-top .g-widget-config-mode {
			.border-bottom-radius(0);
		}

		.gwJobDocAddress-bottom .g-widget-config-mode {
			.border-top-radius(0);
		}

		.gwJobDocAddress-bottom.g-cols-2 li:first-child .g-widget-config-mode {
			border-bottom-right-radius: 0;
		}

		.gwJobDocAddress-bottom.g-cols-2 li:last-child .g-widget-config-mode {
			border-bottom-left-radius: 0;
		}

		.gwJobDocAddress-inner,
		.gwJobDocAddress-top,
		.gwJobDocAddress-bottom {
			.g-widget-config-mode {
				border: 1px solid @InputControl_BorderBrushStart !important;
			}
		}
	}

	&.gwMapsContainer {
		.gwMaps .g-sidebar-right .g-sidebar-wrap {
			border-radius: 0;
		}

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -18px;
			margin-left: -20px;
			font-size: 36px;
			color: @Theme_Icons;
		}
	}

	&.gwButton {
		border-radius: @Button_BorderRoundedCorners;

		.btn {
			cursor: move;

			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			span {
				display: inline-block;
				width: calc(~'100% - 2px');
				vertical-align: middle;
				.text-overflow();
			}
		}
	}

	&.gwRichTextEditor {
		.tox-tinymce {
			display: none;
		}

		.tox-editor-header,
		iframe {
			visibility: hidden;
		}
	}

	&.gwTile {
		.gwTile-wrap {
			display: block;
			width: 100%;
			height: 100%;
			padding: 5px;
		}

		&[data-size="Wide"] .gwTile-wrap {
			padding: 5px 10px;
		}

		&[data-size="Large"] .gwTile-wrap {
			padding: 10px;
		}
	}

	.gwSearchBox-description-divider {
		position: absolute;
		bottom: 0;
		left: 40px;
	}

	&.gwConversationManager {
		.g-textarea-panel {
			background-color: @InputControl_BackgroundColorEnd;
			border: 1px solid @InputControl_BorderBrushStart;
		}

		.g-conversation-panel {
			left: 1px;
			right: 1px;
		}

		.btn {
			cursor: move;
		}
	}

	&.gwSearchBox {
		&.alert-danger,
		&.alert-message-error,
		&.alert-warning,
		&.alert-info {
			border-width: 1px;
			border-style: solid;
		}
	}

	&.gwFavorites {
		overflow: hidden;

		&.gwFavorites-with-recents,
		&.gwFavorites-with-recents .g-recents {
			min-height: auto;
		}

		.gwFavorites-error {
			z-index: 1;
			border-radius: 0;
		}
	}

	&.gwTriStateRadioButton .btn-group .btn {
		cursor: inherit;

		&:hover {
			color: inherit;
			background: inherit;
		}
	}

	&.gwTextBox {
		height: 100%;

		input,
		textarea {
			display: none;
		}
	}

	&.gwDataGrid {
		.kgNoSelect,
		.kgHeaderContainer {
			height: 100%;
		}

		.kgTopPanel {
			height: 24px;
		}

		.kgNonFixedViewport,
		.kgNonFixedHeaderContainer {
			width: calc(~'100% - 31px');
		}

		.kgViewport {
			height: calc(~'100% - 24px');
		}

		.kgFixedViewport,
		.kgFixedHeaderContainer {
			width: 31px;
			border-right: 1px solid @Grid_HeaderBorderColor;
		}

		.gwDataGrid-inner-wrap {
			overflow: hidden;
		}

		.g-kg-title-container {
			display: block;
		}
	}

	&.gwSearchList {
		.g-global-search-results ul {
			height: 100%;

			li {
				height: 100%;
				min-height: inherit;
				margin-bottom: 0;

				&:hover {
					background-color: @white;
				}
			}
		}

		.kgTopPanel {
			width: 100% !important;
		}

		.g-search-results {
			flex: 1;
		}

		.g-search-results-items-container {
			height: calc(100% ~'-' @g-control-height);
		}
	}

	&.gwFinder {
		display: flex;
		flex-direction: column;

		&.gwFinder-item-position-top {
			flex-direction: column-reverse;
		}

		.search-container {
			width: 100%;
			border-radius: @InputControl_BorderRoundedCorners;
			order: 1;
		}

		.template-container {
			order: 2;
		}
	}

	&.gwOptionButtonGrid input[type=radio] + label {
		cursor: move;
	}

	&.btn span {
		.text-overflow();
	}
}

.g-footer-buttons[type="checkbox"] {
	cursor: pointer;
}
