.android__controls {
	padding: 10px;
	width: 640px;
	margin-right: 90px;
}

.android__input {
	.select2-selection {
		min-height: @g-control-height;
	}

	width: 500px;
	margin-top: 10px;
	position: relative;
}

.android__warning {
	font-weight: bold;
}

.android__warningLink {
	font-weight: bold;
	font-size: 20px;
}

.android__note {
	font-weight: bold;
	margin-top: 10px;
}

.android__topMargin {
	margin-top: 20px;
}

.android__ol {
	list-style: none;
	counter-reset: steps;
}

.android__li {
	counter-increment: steps;
	margin-top: 20px;
}

.android__li:before {
	content: counter(steps);
	font-weight: bold;
	float: left;
	margin-right: 15px;
	font-size: 15px;
}

.android__triStateRadioButton {
	margin-left: 15px;
}

.android__step {
	overflow-x: hidden;
}

.android__error {
	color: @error-message-text;
	margin-left: 22px;
	margin-top: 15px;
}

.android__flexstep {
	display: flex;
}

.android__barcode {
	margin-left: 24px;
}

.android__checkBoxStep {
	display: flex;
	margin-top: 10px;
}

.android__multierror {
	.gwErrorLabel-container:not(:first-of-type) {
		.gwErrorLabel-wrapper {
			border-top: none;
		}
	}

	.gwErrorLabel-container:not(:last-of-type) {
		.gwErrorLabel-wrapper {
			border-bottom: none;
		}
	}

	width: 500px;
}
