.gwConversationList-container {
	height: 100%;
	width: 100%;
}

.gwConversationList {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.gwConversationList__conversation-list--border {
	border: 1px solid @SidebarPanel_BorderColor;
}

.gwConversationList__conversation-list {
	background-color: @Popover_BackgroundColor;
	border-radius: @modal-border-radius;
	flex-grow: 1;
	overflow-y: auto;
	margin-bottom: 10px;
}

.gwConversationList__message-separator {
	display: flex;
	align-items: center;
	text-align: center;
}

.gwConversationList__message-separator > span {
	width: 150px;
}

.gwConversationList__message-separator--line {
	border-top: 1px solid @SidebarPanel_BorderColor;
}

.gwConversationList__conversation-more-button {
	.g-button-background(@Button_BackgroundColorStart, @Button_BackgroundHoverColorStart, @Button_BackgroundPressedColorStart, @Button_TextColor, @Button_TextColor_Lighten10, @Button_TextHoverColor, none, 50%);
	.translate(0,-20px);
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	height: 50px;
	justify-content: center;
	opacity: 0.5;
	position: absolute;
	right: 40px;
	text-align: center;
	vertical-align: middle;
	width: 50px;

	&.unreadMessages {
		opacity: 1;
		background-color: @Button_BackgroundHoverColorStart;
	}

	i {
		font-size: 28px;
	}
}

.gwConversationList__conversation-messages {
	display: flex;
	overflow: auto;
	flex-direction: row;
	flex: 2 0 0;
}

.gwConversationList__conversation-list-item {
	padding: 10px 20px;
	display: flex;
}

.gwConversationList__message-group-posted-date {
	font-weight: bold;
	font-style: italic;
	margin: auto;
}

.gwConversationList__message {
	border-radius: @popover-border-radius;
	padding: 10px 15px;
	position: relative;
}

.gwConversationList__message--own {
	background-color: @message-own-background-color;
	margin-left: auto;
}

.gwConversationList__message--own:after {
	content: '';
	position: absolute;
	left: 100%;
	top: 50% - @popover-arrow-size;
	width: 0;
	height: 0;
	border: @popover-arrow-size solid transparent;
	border-left-color: @message-own-background-color;
	border-right: 0;
}

.gwConversationList__message--system,
.gwConversationList__message--other {
	align-self: flex-start;
	background-color: @message-other-background-color;
}

.gwConversationList__message--system:after,
.gwConversationList__message--other:after {
	content: '';
	position: absolute;
	right: 100%;
	top: 50% - @popover-arrow-size;
	width: 0;
	height: 0;
	border: @popover-arrow-size solid transparent;
	border-right-color: @message-other-background-color;
	border-left: 0;
}

.gwConversationList__message-header {
	font-weight: bold;
	font-size: @g-label-font-size;
}

.gwConversationList__message-body {
	margin-top: 4px;
	white-space: pre-wrap;
}

.gwConversationList__send-message-text {
	width: 100%;

	.gwTextBox {
		width: 100%;
		padding: 3px 0;
	}

	label {
		display: none;
	}
}

.gwConversationList__send-message-container {
	display: flex;
}

.gwConversationList__send-message-button {
	margin-top: 10px;
}
