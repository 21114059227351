#g-content {
	width: 100%;
	height: 100%;
	padding-right: 0;
	padding-left: 0;
	margin-right: 0;
	border-top: none;
	display: flex;

	.g-page {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		height: 100%;

		&.g-helppage {
			padding: 0;

			.g-popout-wrap {
				height: 100%;
				padding-bottom: 38px;
				overflow: hidden;

				.g-popout-body-wrap {
					width: 100%;
					height: 100%;
					padding: 10px;
					margin-top: 38px;
					overflow-y: scroll;

					img {
						height: auto;
						max-width: 100%;
					}
				}
			}
		}
	}
}

.g-app-container {
	height: 100%;
	overflow: auto;

	.g-page-shell {
		overflow: inherit;
	}
}

.g-loading {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;

	&.g-page-loading {
		font-size: 20px;
	}
}
