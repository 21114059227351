.g-pave-popover {
	background-color: #fafafa;

	.popover-title {
		background-color: @white;
	}

	.popover-content {
		padding: 10px;

		.g-dynamic-menu-list {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 0;
		}
	}

	ul li {
		cursor: initial;
	}

	.g-channel-popup-subtitle {
		font-weight: bold;
	}
}

.g-cell-ticket-popover {
	max-width: 630px;

	.g-tickets {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}
}

.g-ticket-upcoming-tasks-popover {
	.popover-content .g-dynamic-menu-list {
		padding-bottom: 14px;
	}

	.g-upcoming-task-popup-section {
		margin-bottom: 10px;
		max-width: 450px;

		&:last-child {
			margin-bottom: 0;
		}

		.g-upcoming-task-popup-details {
			margin-left: 35px;

			span {
				overflow-wrap: break-word;
				white-space: normal;
			}
		}

		&.task-group-section {
			& + .task-group-section {
				margin-top: 35px;
			}
		}
	}

	.g-upcoming-task-popup-subtitle {
		display: flex;
		align-items: center;
		margin-bottom: 5px;

		img {
			height: @status-indicator-size;
			width: auto;
		}

		span {
			font-weight: bold;
			margin-left: 10px;
			font-size: 14px;
		}

		i {
			font-size: 16px;
			width: 25px;
			display: flex;
			justify-content: center;
		}
	}

	.g-upcoming-task-tickets {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.g-ticket-upcoming-task {
		.g-status-indicator {
			height: @status-indicator-size-large;
			min-width: @status-indicator-size-large;
		}

		& + .g-ticket-upcoming-task {
			margin-top: 8.88px;
		}
	}
}

.g-popup-small-text {
	font-size: 12px;
	opacity: 0.6;
}

.g-channel-image-popover {
	&.g-action-buttons-popover {
		.popover-content {
			width: 250px;
		}
	}
}

.g-channel-health-popover {
	.popover-content .g-dynamic-menu-list {
		padding-bottom: 0;
	}

	.g-channel-health-popup-components {
		margin-top: 30px;
	}

	.g-channel-health-popup-status {
		display: flex;

		.g-health-status-bar {
			flex: 0 0 10px;
			min-width: 10px;
			border-radius: @rounded-corners;
			margin-right: 8px;
		}

		.g-health-status-text {
			--g-health-status-text-max-width: 430px;
			max-width: var(--g-health-status-text-max-width);

			.g-channel-popup-text {
				margin-top: 5px;
			}

			&-1 {
				--g-health-status-text-max-width: 322px;
			}

			&-2 {
				--g-health-status-text-max-width: 340px;
			}
		}
	}

	.g-health-component-capacity-container {
		margin-top: 10px;

		.g-health-component-capacity + .g-health-component-capacity {
			margin-top: 8px;
		}

		.g-health-bar {
			margin-top: 2px;
		}
	}

	.g-channel-health-popup-breakdown {
		margin-top: 25px;

		table {
			th {
				padding: 5px 10px;
				background-color: lighten(@grey, 8%);
				width: 130px;
				max-width: 130px;
				.text-ellipsis();

				&.full-width {
					max-width: 240px;
					width: 240px;
				}
			}

			th,
			td {
				text-align: center;
			}

			thead th:first-child {
				background-color: transparent;
			}

			tbody {
				th {
					text-align: left;
					font-weight: normal;
				}
			}

			thead tr th:first-child,
			tbody tr th {
				width: 100px;
			}

			.g-channel-health-breakdown-table-row-block {
				td,
				th {
					font-weight: bold;
					padding: 10px;
				}
			}

			.g-channel-health-breakdown-table-row-inner {
				font-size: 12px;
			}

			.g-channel-health-row-block-content {
				display: flex;
				justify-content: center;
				position: relative;
			}

			.g-pave-health-risk-bad {
				color: darken(@risk-state-bad-color, 10%);
				background-color: transparent;
			}

			.g-health-utilised-capacity-button {
				color: @Theme_Icons;
				position: absolute;
				right: 0px;
				top: 0;
				display: flex;
				align-items: center;
				padding: 0 10px;
				.opacity-hover;
			}
		}

		.g-channel-health-popup-breakdown-footnote {
			margin-top: 10px;
			max-width: 330px;

			span {
				font-size: 11px;
			}
		}
	}
}

.g-channel-status-popover,
.g-ticket-status-popover {
	.popover-content .g-dynamic-menu-list {
		padding-bottom: 0;
	}

	.g-status-popover-contents {
		display: flex;
		flex-direction: column;

		.g-status-popover-statuses {
			display: flex;
			flex-direction: column;

			.g-status-popover-status {
				display: flex;

				& + .g-status-popover-status {
					margin-top: 10px;
				}

				.g-status-indicator {
					margin-right: 10px;
					border-radius: @status-indicator-size;
					height: @status-indicator-size-large;
					width: @status-indicator-size-large;
					border-radius: 100%;
				}

				.g-status-popover-status-text {
					margin-top: 4px;

					.g-status-popover-status-text-content {
						white-space: initial;
					}
				}
			}
		}

		.g-status-popover-workdetails,
		.g-ticket-status-popover-actions:not(:empty) {
			margin-top: 20px;
			min-width: 0;
		}

		.g-title {
			margin: 0;

			&:not(:empty) {
				margin-top: 20px;
			}
		}

		.g-channel-status-popover-tickets:not(:empty) {
			margin-top: 10px;
			display: flex;
			flex-direction: column;
		}
	}
}

.g-channel-status-popover {
	.g-status-popover-contents {
		min-width: 250px;
		max-width: 282px;
	}
}

.g-ticket-status-popover {
	.g-status-popover-contents{
		width: 100%;
	}
}

.g-action-buttons-popover {
	.popover-content {
		width: 200px;
	}

	.g-dynamic-menu-list {
		min-width: initial;
	}
}

.g-health-reserved-info-popover {
	max-width: 300px;

	.popover-content .g-dynamic-menu-list {
		padding-bottom: 0;
	}

	.g-health-reserved-info-description {
		margin-bottom: 15px;
		font-weight: 500;
	}

	.g-health-reserved-info-capacity {
		display: flex;

		.g-duration {
			margin-left: 5px;
			font-weight: 700;
		}
	}
}
