.gwCheckBox__input {
	display: none;
}

.gwCheckBox {
	display: flex;
	width: 100%;
	padding: 0 !important;
	margin: 0;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0);
	align-items: center;

	&--bordered {
		height: 100%;
		min-width: 200px;
		min-height: @g-control-height;
		background-color: @Checkbox_BackgroundColor;
		border-color: @InputControl_BorderBrushStart;
		border-radius: 4px;
		justify-content: flex-end;

		.gwCheckBox__span {
			max-height: 32px;
			padding: 0 8px;
			line-height: @bootstrap-line-height;
			text-align: right;
			white-space: normal;
		}

		.gwCheckBox__box {
			height: 100%;
			min-width: @g-checkbox-tick-area-width;
			min-height: 40px;
			border-left: 1px solid @InputControl_BorderBrushStart;
			justify-content: center;
		}
	}

	&--borderless-left-aligned {
		flex-direction: row-reverse;
		justify-content: flex-end;

		.gwCheckBox__span {
			padding-left: 7px;
		}
	}

	&--borderless-right-aligned {
		justify-content: space-between;

		.gwCheckBox__span {
			padding-right: 8px;
		}
	}

	&--borderless-centered {
		height: 100%;
		justify-content: center;

		.gwCheckBox__span {
			display: none;
		}
	}

	&--readonly {
		color: @InputControl_LabelColor;
		background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
		cursor: default;
	}

	&:not(.gwCheckBox--readonly) {
		&:hover .gwCheckBox__box::before {
			border-color: @Checkbox_BorderHoverColor;
		}
	}

	&.alert-danger {
		.alert-danger-mixins();

		&.gwValidation-highlight {
			.alert-danger-highlight();
		}

		.gwCheckBox__box {
			border-color: @state-error;
		}
	}

	&.alert-message-error {
		.alert-message-error-mixins();

		&.gwValidation-highlight {
			.alert-message-error-highlight();
		}

		.gwCheckBox__box {
			border-color: @state-message-error;
		}
	}

	&.alert-warning {
		.alert-warning-mixins();

		&.gwValidation-highlight {
			.alert-warning-highlight();
		}

		.gwCheckBox__box {
			border-color: @state-warning;
		}
	}

	&.alert-info {
		.alert-info-mixins();

		&.gwValidation-highlight {
			.alert-info-highlight();
		}

		.gwCheckBox__box {
			border-color: @state-info;
		}
	}
}

.g-anchored > label[for].gwCheckBox {
	padding: 0;
}

.gwCheckBox__span {
	overflow: hidden;
	text-overflow: ellipsis;
}

.gwCheckBox__box,
.gwFastCheckBox {
	display: flex;
	font-size: 12px;
	font-style: italic;
	font-weight: 900;
	line-height: 14px;
	align-items: center;

	&::before {
		width: 14px;
		height: 14px;
		background-color: @Checkbox_BackgroundColor;
		border: 1px solid @Theme_ValueText;
		border-radius: 2px;
		content: '';
	}

	&::after {
		width: 14px;
		margin-left: -14px; // Do not use position: absolute or transform to align the check mark as that kills performance.
		color: @Theme_Hover;
	}
}

.gwFastCheckBox {
	width: 100%;
	height: 100%;
	cursor: inherit;
	justify-content: center;
}

.gwCheckBox__input:checked + .gwCheckBox .gwCheckBox__box,
.gwFastCheckBox--checked {
	&::after {
		content: '\2713';
	}
}

.gwFastCheckBox--null {
	&::after {
		content: '\2014';
		padding-left: 1.5px;
	}
}

.gwCheckBox--readonly .gwCheckBox__box,
.g-uneditable .gwFastCheckBox,
.gwFastCheckBox--readonly {
	&::before {
		background-color: @Checkbox_ReadOnlyBackgroundColor;
		border-color: @Checkbox_ReadOnlyBorderColor;
	}

	&::after {
		color: @Checkbox_ReadOnlyTickColor;
	}
}
