.gwTrackingFlagDisplay {
	display: inline-flex;
	align-items: center;
	height: 46px;
	padding: 3px 7px;
	font-size: 24px;

	> i {
		font-size: 24px;

		&.icon-chevron-right {
			margin: 0 5px;
			font-size: 22px;
			color: #C4C7C8;
		}

		&.icon-truck {
			.icon-flip-horizontal;
		}

		&:hover,
		&.fa:hover {
			cursor: default;
			opacity: 1;
		}
	}

	> .g-iata {
		position: relative;

		> .gwFlag {
			opacity: 0.4;
			-webkit-filter: blur(2px);
			filter: blur(2px);
		}

		> .g-iata-text {
			position: absolute;
			top: 0;
			left: 0;
			margin: 4px 1px;
			color: @Theme_ValueText;
			font-family: @font-family-monospace;
			font-size: 18px;
			font-weight: bold;
			text-shadow: -1px 1px 2px rgba(255,255,255,1), 1px 1px 2px rgba(255,255,255,1), 1px -1px 2px rgba(255,255,255,1), -1px -1px 2px rgba(255,255,255,1);
		}
	}
}
