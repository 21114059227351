.gwTile {
	text-align: center;
	border-radius: @Tiles_RoundedCorners;
	margin: @gwTile-margin;
	padding: 0;
	overflow: hidden;
	border-style: solid;
	border-width: @gwTile-border-thickness;
	box-shadow: #aaa 2px 2px 4px 1px;

	&:hover {
		opacity: 0.6;
	}

	> a {
		display: block;
		width: 100%;
		height: 100%;
		padding: 5px;
	}

	a:focus {
		outline: 1px dashed @Tiles_ForegroundBrush;
		outline-offset: -3px;
	}

	.gwTile-wrap {
		position: relative;
	}

	.gwTile-img {
		height: 100%;
		max-width: 100%;
		color: @Tiles_ForegroundBrush;

		img {
			width: auto;
			max-width: 100%;
			max-height: 100%;
			pointer-events: none;
		}

		&:focus {
			outline: none;
		}
	}

	.gwTile-text {
		color: @Tiles_ForegroundBrush;

		.gwTile-text-contents {
			&:focus {
				outline: none;
			}

			span {
				font-weight: @Tiles_FontWeight;
				vertical-align: middle;
				display: inline-block;
				width: 100%;
			}
		}
	}

	.g-menu-item-icon {
		position: absolute;
		top: 0;
		right: 0;
		color: @Tiles_ForegroundBrush;
	}

	.g-menu-item-target:hover .g-menu-item-icon {
		opacity: 1;
	}

	&[data-displaymode="TextOnly"] {
		.gwTile-text {
			margin: 0;

			.gwTile-text-contents {

				span {
					line-height: normal;
					white-space: normal;
				}
			}
		}
	}

	&[data-displaymode="IconOnly"] {
		.gwTile-img {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.gwTile-text {
			display: none;
		}
	}

	&[data-size="Standard"] {
		width: @gwTile-Standard-width;
		height: @gwTile-Standard-height;

		&[data-displaymode="TextAndIcon"] {
			.gwTile-img {
				height: @gwTile-Standard-TextAndIcon-icon-height;
				line-height: @gwTile-Standard-TextAndIcon-icon-height;
			}

			.gwTile-text {
				height: @gwTile-Standard-TextAndIcon-text-height;
				margin-top: 5px;
			}

			.gwTile-text-contents span {
				max-height: @gwTile-Standard-TextAndIcon-text-height;
				font-size: @Tiles_FontSize_Standard;
				.text-overflow();
			}
		}

		&[data-displaymode="TextOnly"] {
			.gwTile-text-contents {
				line-height: @gwTile-Standard-content-height;
			}
		}
	}

	&[data-size="Wide"] {
		width: @gwTile-Wide-width;
		height: @gwTile-Wide-height;
		margin: 4px;
		padding: 0;

		> a {
			padding: @gwTile-Wide-vertical-padding @gwTile-Wide-horizontal-padding;
		}

		.gwTile-img {
			float: left;
			margin-top: 5px;
		}

		.gwTile-text {
			color: @Tiles_ForegroundBrush;
			float: right;
		}

		.gwTile-text-contents {
			line-height: @gwTile-Wide-content-height;

			span {
				line-height: normal;
				font-size: @Tiles_FontSize_Wide;
			}
		}

		&[data-displaymode="TextAndIcon"] {
			.gwTile-img {
				width: @gwTile-Wide-TextAndIcon-icon-width;
			}

			.gwTile-text {
				width: @gwTile-Wide-TextAndIcon-text-width;
				margin-left: @gwTile-Wide-TextAndIcon-text-margin-left;
			}
		}

		&[data-displaymode="TextOnly"] {
			.gwTile-img {
				display: none;
			}

			.gwTile-text {
				margin: 0;
				width: 100%;

				.gwTile-text-contents {
					span {
						display: inline-block;
						line-height: normal;
						white-space: normal;
					}
				}
			}
		}

		&[data-displaymode="IconOnly"] {
			.gwTile-img {
				width: 100%;
				margin-top: 0;

				img {
					padding: 2px 0;
				}
			}

			.gwTile-text {
				display: none;
			}
		}
	}

	&[data-color="Default"] {
		background: @Tiles_BackgroundBrush;
		border-color: @Tiles_BackgroundBrush;

		&:hover {
			border-color: @Tiles_BackgroundBrush_Darken15;
		}
	}

	&[data-color="Primary"] {
		background: @Tiles_LargeBackgroundBrush;
		border-color: @Tiles_LargeBackgroundBrush;

		&:hover {
			border-color: @Tiles_LargeBackgroundBrush_Darken15;
		}
	}

	&[data-color="Secondary"] {
		background: @Tiles_RectangleBackgroundBrush;
		border-color: @Tiles_RectangleBackgroundBrush;

		&:hover {
			border-color: @Tiles_RectangleBackgroundBrush_Darken15;
		}
	}

	&[data-size="Large"] {
		width: @gwTile-Large-width;
		height: @gwTile-Large-height;
		margin: 4px;
		padding: 0;

		> a {
			padding: @gwTile-Large-padding;
		}

		.gwTile-text {
			color: @Tiles_ForegroundBrush;

			.gwTile-text-contents {
				width: 100%;
				overflow: hidden;

				span {
					line-height: normal;
					font-size: @Tiles_FontSize_Large;
				}
			}
		}

		&[data-displaymode="TextAndIcon"] {
			.gwTile-img {
				height: @gwTile-Large-TextAndIcon-icon-height;
				line-height: @gwTile-Large-TextAndIcon-icon-height;
			}

			.gwTile-text {
				margin-top: 5px;
			}

			.gwTile-text-contents {
				line-height: @gwTile-Large-TextAndIcon-text-height;

				span {
					max-height: @gwTile-Large-TextAndIcon-text-height - 10px;
					overflow: hidden;
				}
			}
		}

		&[data-displaymode="TextOnly"] {
			.gwTile-text {

				.gwTile-text-contents {
					height: 100%;
					line-height: @gwTile-Large-content-height;

					span {
						max-height: @gwTile-Large-content-height - 6px;
					}
				}
			}
		}

		&[data-color="Default"] {
			background: @Tiles_LargeBackgroundBrush;
			border-color: @Tiles_LargeBackgroundBrush;

			&:hover {
				border-color: @Tiles_LargeBackgroundBrush_Darken15;
			}
		}
	}
}

.g-configurable-container .gwTile,
.g-configurable-widget .gwTile {
	&:hover {
		.opacity(1);
	}
}

.g-configurable-container > .gwTile a {
	cursor: default;
}

.g-configurable-widget > .gwTile a {
	cursor: inherit;
}
