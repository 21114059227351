.gwAsyncImage {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
}
