.clientLinkContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;

	.clientLinkImage {
		content: url('@{ContentBaseUri}/Shared/Images/ClientLink/android-chrome-256x256.--yF8KIc88yqFMUkiKYi4TXIzaU.png');
	}

	h1 {
		font-size: 3.5em;
		margin-top: 20px;
		margin-bottom: 5px;
	}
}