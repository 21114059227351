.gwSelectList__item {
	display: flex;
	padding: 10px;
	border-bottom: 1px solid @SidebarPanel_BorderColor;
	cursor: pointer;

	&:last-child {
		border-bottom: 0;
	}

	&:hover {
		background-color: @Theme_Hover;

		& .gwSelectList__arrow,
		& .gwSelectList__code,
		& .gwSelectList__desc {
			color: @Theme_HoverText;
		}
	}
}

.gwSelectList__name {
	flex: 1;
}

.gwSelectList__code {
	font-size: 20px;
	color: @Theme_FieldTitle;
}

.gwSelectList__desc {
	font-size: 16px;
	color: @Theme_ValueText;
}

.gwSelectList__arrow {
	align-self: center;
	padding: 5px;
	color: @Theme_Icons;
}
