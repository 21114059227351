.g-action-buttons-container {
	display: flex;
	flex-direction: column;
}

.g-action-button-item {
	width: 100%;

	& + & {
		margin-top: 10px;
	}
}

.g-action-button {
	display: flex;
	.transition(0.12s, ease-in-out);
	.user-select(none);
	border-radius: @rounded-corners;
	padding: 5px 10px;
	align-items: center;
	border: none;
	width: 100%;

	&:not(.disabled):not([disabled]) {
		.element-elevation(2);

		&:hover {
			cursor: pointer;
		}

		&:active {
			.element-elevation(1);
		}
	}

	&-icon {
		width: 14px;
		height: 14px;
		margin-right: 8px;
		text-align: center;
		opacity: 0.8;
	}

	&-text {
		margin-right: 15px;
	}

	.g-pave-external-icon {
		margin-left: auto;
	}

	&.default {
		.setup-action-button(white, 5%, 5%);
	}

	&.good {
		.setup-action-button(@risk-state-excelent-color, 5%, 5%);
	}

	&.info {
		.setup-action-button(@risk-state-good-color, 5%, 5%);
	}

	&.warning {
		.setup-action-button(@risk-state-caution-color, 8%, 5%);
	}

	&.error {
		.setup-action-button(@risk-state-bad-color, 5%, 5%);
	}

	&.bad {
		.setup-action-button(lighten(@risk-state-bad-color, 15%), 0%, 6%);
	}

	&.alert {
		.setup-action-button(@risk-state-alert-color, 5%, 5%);
	}

	&.none {
		.setup-action-button(@risk-state-none-color, 5%, 5%);
	}

	&.disabled,
	&[disabled] {
		color: @black;
		background-color: lighten(@risk-state-none-color, 5%);

		&:hover,
		&:active {
			background-color: lighten(@risk-state-none-color, 5%) !important;
		}
	}
}

.g-action-button-separator {
	margin-top: 10px;
	margin-bottom: 10px;
}
