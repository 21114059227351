.gwValidationSummary {
	margin: 0;

	input[type="checkbox"] {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	ul {
		margin: 2px 2px 4px;

		li.alert {
			border-radius: 0;
			margin-bottom: 0;
		}

		&:last-child a:hover,
		li:last-child {
			border-radius: 0 0 3px 3px;
		}

		&:last-child {
			margin-bottom: 2px;

			a {
				border-bottom: none;
			}
		}

		li.gwValidationSummary-alert-entity-header {
			display: block;
			min-height: 25px;
			padding: 0 8px;
			border: none;
			border-radius: 3px 3px 0 0;

			> span {
				height: @g-alert-header-text-height;
				line-height: 25px;
				font-size: 12px;
				font-weight: bold;
				color: @state-title-text;
				padding: 0;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: @g-alert-header-white-space;
				display: block;
			}
		}

		li.gwValidationSummary-alert-entity-message {
			position: relative;
			padding: 8px;
			border-color: transparent;
			color: @black;

			.alert-entity-message(@bg, @focus-bg, @border) {
				background: @bg;
				border-bottom-color: @border;

				&:hover {
					background: @focus-bg;
					border-color: @border;
				}
			}

			&.alert-danger {
				.alert-entity-message(@state-error-pale, @state-error-hover, @state-error);

				&:hover {
					a {
						.alert-danger-highlight() !important;
					}

					span {
						.alert-danger-mixins() !important;
						.alert-danger-highlight() !important;
					}
				}
			}

			&.alert-message-error {
				.alert-entity-message(@state-message-error-pale, @state-message-error-hover, @state-message-error);
			}

			&.alert-warning {
				.alert-entity-message(@state-warning-pale, @state-warning-hover, @state-warning);

				.gwValidationSummary-alert-caption {
					margin-right: @g-alert-tickbox-size;
					padding-right: 5px;
				}
			}

			&.alert-info {
				.alert-entity-message(@state-info-pale, @state-info-hover, @state-info);
			}

			a {
				display: block;
				font-size: 12px;
				height: 100%;
				color: @black;
				padding: @g-alert-content-padding;

				&:focus {
					text-decoration: none;
				}
			}

			&:last-child {
				border-bottom-color: transparent;
			}
		}

		.gwValidationSummary-alert-caption {
			font-weight: bold;
			margin-bottom: 4px;
		}

		.gwValidationSummary-alert-caption,
		.gwValidationSummary-alert-description {
			display: block;
			line-height: 20px;
			white-space: normal;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}


.gwValidationSummary-alert-entity-message .g-tickbox {
	label:after {
		width: @g-alert-tick-width;
		height: @g-alert-tick-height;
		top: @g-alert-tick-top;
		left: @g-alert-tick-left;
	}
}

.gwValidationSummary-alert-entity-message.alert-warning {
	&:hover .g-tickbox {
		input[type="checkbox"]:checked + label:after {
			border-color: @black;
		}
	}

	.g-tickbox {
		position: absolute;
		top: @g-alert-tickbox-top;
		right: @g-alert-tickbox-right;
		width: @g-alert-tickbox-size;
		height: @g-alert-tickbox-size;
		padding: 0;
		margin-left: 0;
		z-index: 999;

		input[type="checkbox"] {
			top: 0;
			right: 0;

			&:checked + label {
				&:hover:after {
					.opacity(1);
				}

				span {
					background: #fff7c3;
					left: @g-alert-tickbox-corner-open-left;
				}
			}

			&:focus + label {
				box-shadow: none;
			}
		}

		label {
			background: transparent;
			border-color: @grey;
			width: @g-alert-tickbox-size;
			height: @g-alert-tickbox-size;
			top: 0;
			margin: 0;
		}

		span {
			top: -1px;
			left: 5px;
			width: @g-alert-tickbox-corner-open-width;
			height: @g-alert-tickbox-corner-open-height;
		}
	}
}

.alert-danger,
.alert-message-error,
.alert-warning,
.alert-info {
	color: @state-title-text;
}

.alert-danger {
	background-color: @state-error;
}

.alert-message-error {
	background-color: @state-message-error;
}

.alert-warning {
	background-color: @state-warning;
}

.alert-info {
	background-color: @state-info;
}
