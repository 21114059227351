.g-width-px(@size) {
	width: 0px + @size;
}

.g-height-px(@size) {
	height: 0px + @size;
}

.g-font-size-px(@size) {
	font-size: 0px + @size;
}

.alert-danger-mixins() {
	color: @state-error-text;
	background-color: @state-error-pale;
	border-color: @state-error;
}

.alert-danger-highlight() {
	background: @state-error-hover;
}

.alert-danger-config() {
	color: @state-error-text;
	background-color: @state-error-pale;
	border-color: @state-error-hover;
}

.alert-danger-highlight-config() {
	background: @state-error-hover;
	border-color: @state-error;
}

.alert-danger-shadow() {
	.box-shadow(0 0 6px @state-error-hover);
}

.alert-danger-highlight-shadow() {
	.box-shadow(0 0 6px @state-error);
}

.alert-message-error-mixins() {
	color: @state-message-error-text;
	background-color: @state-message-error-pale;
	border-color: @state-message-error;
}

.alert-message-error-highlight() {
	color: @state-message-error-hover;
}

.alert-message-error-config() {
	color: @state-message-error-text;
	background-color: @state-message-error-pale;
	border-color: @state-message-error-hover;
}

.alert-message-error-highlight-config() {
	background: @state-message-error-hover;
	border-color: @state-message-error;
}

.alert-message-error-shadow() {
	.box-shadow(0 0 6px @state-message-error-hover);
}

.alert-message-error-highlight-shadow() {
	.box-shadow(0 0 6px @state-message-error);
}

.alert-warning-mixins() {
	color: @state-warning-text;
	background-color: @state-warning-pale;
	border-color: @state-warning;
}

.alert-warning-highlight() {
	background: @state-warning-hover;
}

.alert-warning-config() {
	color: @state-warning-text;
	background-color: @state-warning-pale;
	border-color: @state-warning-hover;
}

.alert-warning-highlight-config() {
	background: @state-warning-hover;
	border-color: @state-warning;
}

.alert-warning-shadow() {
	.box-shadow(0 0 6px @state-warning-hover);
}

.alert-warning-highlight-shadow() {
	.box-shadow(0 0 6px @state-warning);
}

.alert-info-mixins() {
	color: @state-info-text;
	background-color: @state-info-pale;
	border-color: @state-info;
}

.alert-info-highlight() {
	background: @state-info-hover;
}

.alert-info-config() {
	color: @state-info-text;
	background-color: @state-info-pale;
	border-color: @state-info-hover;
}

.alert-info-highlight-config() {
	background: @state-info-hover;
	border-color: @state-info;
}

.alert-info-shadow() {
	.box-shadow(0 0 6px @state-info-hover);
}

.alert-info-highlight-shadow() {
	.box-shadow(0 0 6px @state-info);
}

.alert-config-style(@text-color, @background-color, @border-color) {
	color: @text-color;
	background-color: @background-color;
	border-color: @border-color;
}

.alert-config-validation-style(@text-color, @background-color, @border-color, @border-hover-color) {
	.alert-config-style(@text-color, @background-color, @border-color);

	.gwTile-img,
	.gwTile-text {
		color: @text-color;
	}

	&.gwCheckBox,
	&.gwLabel,
	&.gwTile[data-color],
	&.gwSearchBox,
	&.gwGroupBox {
		.alert-config-style(@text-color, @background-color, @border-color);
	}

	&.gwTile[data-color]:hover,
	&:hover {
		border-color: @border-hover-color;
	}
}

.koGridActionColumn() {
	.btn {
		background: none;
		border: 1px solid transparent;
		.box-shadow(none);

		&:hover {
			background: @btn-default-bg;
			border: 1px solid @btn-default-border;
			border-bottom-color: darken(@btn-default-border, 10%);
			border-radius: 4px;
			.box-shadow(~"inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05)");
		}

		i {
			margin-top: 3px;
			font-size: 17px;
		}
	}
}

.grid-action-button() {
	.g-grid-action-button {
		display: block;
		width: 100%;
		height: 100%;
		font-size: 15px;
		outline: none;
		background: transparent;
		border: none;
		border-radius: 0;
		.opacity-hover;

		i {
			display: block;
			margin-right: auto;
			margin-left: auto;
			color: @Theme_Icons;
		}
	}
}

.g-stretch-to-parent-container-absolute() {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: auto;
	height: auto;
}

.g-footer() {
	z-index: 10;
	border-top: 1px solid @InputControl_BorderBrushStart;

	.btn {
		height: @g-footer-button-height;
		min-width: 100px;
		padding: 0 10px;
		font-size: 14px;
		font-weight: @g-footer-text-font-weight;

		&.g-btn-async {
			.g-btn-async-loading-padding();
		}
	}
}

.g-btn-async-loading-padding() {
	padding-left: 20px;
	padding-right: 20px;

	&[data-icon] {
		padding-left: 11px;
		padding-right: 11px;
	}

	&.g-btn-loading, &.g-btn-loading[data-icon] {
		padding-left: 9.5px;
		padding-right: 9.762px;
	}
}

.nestedPadding (@index, @in: "") when (@index > 1) {
	@concatenate: "@{in} > .dropdown-submenu > .dropdown-menu";
	@selector: ~"> .dropdown-menu @{concatenate}";

	@{selector} {
		padding-left: 15px;

		> li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove) {
			padding-left: 5px !important;
		}
	}

	.nestedPadding((@index - 1), @concatenate);
}

.nestedPadding(0, @in) {
}

.g-button-background(@start-color, @hover-color, @active-color, @text-color: @white, @text-color-lighten-10: lighten(@white, 10%), @text-hover-color: @white, @text-shadow: none, @border-radius: @Button_BorderRoundedCorners) {
	text-shadow: @text-shadow;
	.action-state(@bg-color: @start-color, @bg-hover-color: @hover-color, @bg-active-color: @active-color, @text-color: @text-color, @text-color-lighten-10: @text-color-lighten-10, @text-hover-color: @text-hover-color, @border-radius: @border-radius);

	&.btn-toggle {
		&:not(.active) {
			background-color: @ToggleButton_TurnedOffBackgroundColorStart;
		}

		&.active {
			background-color: @ToggleButton_TurnedOnBackgroundColorStart;
		}
	}
}

.action-state(
	@bg-color: @PrimaryButton_BackgroundColorStart,
	@bg-hover-color: @Theme_Hover,
	@bg-active-color: @PrimaryButton_BackgroundPressedColorStart,
	@bg-open-color: @Grid_ViewportSelectedRowColor,
	@text-color: @Theme_GeneralText,
	@text-color-lighten-10: @Theme_GeneralText_Lighten10,
	@text-hover-color: @Theme_HoverText,
	@text-open-color: @Theme_GeneralText,
	@disabled-use-opacity: false,
	@disabled-bg-color: @Button_BackgroundDisabledColorStart,
	@transition: 0.1s,
	@border-radius: none,
	@border-style: dashed)
{
	& when (@transition > 0) {
		.transition(@transition, ease-in);
	}

	background-color: @bg-color;
	color: @text-color;
	border-radius: @border-radius;

	a,
	label {
		color: @text-color;
	}

	i {
		color: @text-color;

		&.chevron {
			color: @text-color-lighten-10;
		}
	}

	&:not(.disabled):not([disabled]) {
		.open > &,
		.active > &,
		&.pressed,
		&.popover-shown {
			background-color: @bg-open-color;
			color: @text-open-color;

			i {
				color: @text-open-color;
			}

			&:focus {
				background-color: @bg-open-color;
				color: @text-open-color;

				i {
					color: @text-open-color;
				}
			}

			@media (hover){
				&:hover {
					background-color: @bg-hover-color;
					color: @text-hover-color;
				}
			}

			&:active {
				background-color: @bg-active-color;
				color: @text-hover-color;
			}
		}

		&:focus {
			background-color: @bg-color;
			color: @text-color;
			border: @focus-border-width @border-style @black;

			& when not (@border-radius = 0px) {
				border-radius: @border-radius;
			}

			& when (@border-radius = none) {
				border-radius: 3px;
			}
		}

		@media (hover){
			&:hover {
				color: @text-hover-color;
				background-color: @bg-hover-color;

				& when not (@border-radius = 0px) {
					border-radius: @border-radius;
				}

				a,
				i,
				label,
				span {
					color: @text-hover-color !important;
				}

				i {
					&.g-menu-item-icon,
					&.g-menu-item-persistent-icon {
						.opacity-hover(0s);
					}
				}
			}
		}

		&:active {
			color: @text-hover-color;
			background-color: @bg-active-color;

			& when not (@border-radius = 0px) {
				border-radius: @border-radius;
			}

			a,
			i,
			label,
			span {
				color: @text-hover-color !important;
			}
		}
	}


	&.disabled,
	&[disabled] {
		background-color: @disabled-bg-color;

		& when (@disabled-use-opacity = false) {
			color: @disabled-text;

			&:active {
				color: @disabled-text;

				a,
				i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
				label,
				span {
					color: @disabled-text;
				}

				i {
					opacity: 1;

					&.g-menu-item-always-enabled-icon {
						.opacity-hover(0s);
					}

					&:not(.g-menu-item-always-enabled-icon) {
						&.g-menu-item-icon,
						&.g-menu-item-persistent-icon {
							display: none;
						}
					}
				}
			}
		}

		& when (@disabled-use-opacity = true) {
			opacity: 0.5;
		}
	}
}

.opacity-hover(@transition: 0.1s) {
	.transition(@transition, ease-in);
	opacity: 1;
	cursor: pointer;
	.user-select(none);
	border: @focus-border-width dashed transparent;

	&:focus {
		border: @focus-border-width dashed @black;
		border-radius: 3px;
		outline: none;
		text-decoration: none;
	}

	&:hover {
		opacity: 0.5;
		text-decoration: none;
	}

	&:active {
		opacity: 0.3;
		text-decoration: none;
	}
}

.g-relative-left-align() {
	position: relative;
	top: 0;
	left: 0;
	float: left;
}

.transition(@duration: 0.2s, @ease: ease-out, @type: all) {
	-webkit-transition: @type @duration @ease;
	-moz-transition: @type @duration @ease;
	-o-transition: @type @duration @ease;
	transition: @type @duration @ease;
}

.element-elevation(@level: 1) {
	& when (@level = -5) {
		box-shadow: 0 19px 38px rgba(0,0,0,0.30) inset, 0 0px 12px rgba(0,0,0,0.22) inset;
	}

	& when (@level = -4) {
		box-shadow: 0 14px 28px rgba(0,0,0,0.25) inset, 0 0px 10px rgba(0,0,0,0.22) inset;
	}

	& when (@level = -3) {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19) inset, 0 0px 6px rgba(0,0,0,0.23) inset;
	}

	& when (@level = -2) {
		box-shadow: 0 3px 6px rgba(0,0,0,0.16) inset, 0 0px 6px rgba(0,0,0,0.16) inset;
	}

	& when (@level = -1) {
		box-shadow: 0 1px 3px rgba(0,0,0,0.12) inset, 0 0px 2px rgba(0,0,0,0.24) inset;
	}

	& when (@level = 0) {
		box-shadow: none;
	}

	& when (@level = 1) {
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	}

	& when (@level = 2) {
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	}

	& when (@level = 3) {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	}

	& when (@level = 4) {
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	}

	& when (@level = 5) {
		box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
	}
}

.user-select(@value) {
	-webkit-user-select: @value;
	-moz-user-select: @value;
	user-select: @value;
}

.text-ellipsis(@multiline: false) {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	& when (@multiline = true) {
		white-space: normal;
	}
}

.vertical-text() {
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-writing-mode: vertical-lr;
	writing-mode: vertical-rl;
}

.page-button(@marginSize) {
	@paddingSize: 0;
	@minusValue: (@marginSize + @paddingSize) * 2;
	padding: @paddingSize;
	margin: @marginSize;
	width: calc(~"100% - "@minusValue);
	height: calc(~"100% - "@minusValue);
}

.g-actions-buttons-container {
	padding: @g-actions-button-container;
}

// Group boxes
// This will modify the group boxes' header style to make it look like the one on the side panel
.collapsible-groupbox-header(@height: 24px, @line-height: 22px, @font-size: 12px) {
	& > .panel-heading {
		height: @height;
		line-height: normal;
		padding: 0;
		border-bottom: @sidebar-border;
		background-color: @Theme_Button;

		.panel-title {
			width: 100%;
			font-weight: normal;
		}

		&:hover {
			background-color: @PrimaryButton_BackgroundHoverColorStart;

			a, i {
				color: @white;
			}
		}

		a {
			display: block;
			padding: 0 8px;
			line-height: @line-height;
			color: @Theme_GeneralText;
			font-size: @font-size;
			.text-overflow();

			i {
				color: @Theme_GeneralText;
			}
		}
	}
}

.collapsible-groupbox-icon(@margin-top, @collapsed-margin-top, @expanded-angle) {
	&.g-show-collapsible-icon {
		.panel-title a {
			position: relative;

			span {
				margin-left: 12px;
			}

			.g-collapsible-icon {
				position: absolute;
				top: 50%;
				left: 8px;
				display: block;
				margin-top: @margin-top;
				.rotate(@expanded-angle);

				&.fa:before {
					content: "\f0de";
				}
			}

			&.collapsed > .g-collapsible-icon {
				margin-top: @collapsed-margin-top;
				.rotate(0);
			}
		}
	}
}

// Placeholder text
.placeholder(@color: @input-color-placeholder, @font-style: italic) {
	// Firefox 4-18
	&:-moz-placeholder {
		color: @color;
		font-style: @font-style;
	}
	// Firefox 19+
	&::-moz-placeholder {
		color: @color;
		font-style: @font-style;
	}
	// Safari and Chrome
	&::-webkit-input-placeholder {
		color: @color;
		font-style: @font-style;
	}
}

.panel-title-details {
	padding-left: 0;
	font-size: 15px;
	vertical-align: middle;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: @g-panel-title-overflow;
	white-space: @g-panel-title-white-space;
}

.panel-heading-details {
	display: block;
	padding: 0;
	color: @GroupBox_HeaderTextColor;
	background-image: none;
	border-radius: @GroupBox_BorderRoundedCorners;
}

.readonly-icon {
	color: @readonly-icon-color;
	cursor: default;
	opacity: 0.5;
}

// this stuff should be deleted...
.dropdown-menu-list-line {
	display: flex;
	align-items: center;
	height: @dropdown-menu-item-height;
	padding: 0 @dropdown-menu-item-padding;
	clear: both;
	font-size: @dropdown-menu-item-font-size;
	font-weight: normal;
	line-height: @dropdown-menu-item-height;
	color: @Theme_GeneralText;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	outline: 0;
	position: relative;

	&.g-pin-target{
		padding-right: 0;
	}
}

.dropdown-menu-header {
	font-size: @g-menu-font-size;
	color: @Theme_DefaultButton;
	padding: 2px 8px;
	height: auto;
	line-height: 28px;
	border-top: 1px solid #aaaaaa;
	cursor: default;
	margin-bottom: 0px;
}

.dropdown-menu-section-hover {
	color: @Theme_HoverText;
	background-color: @Theme_Hover;

	label,
	span,
	a,
	i.chevron {
		color: @Theme_HoverText;
	}
}

.dropdown-menu-section-open {
	background-color: @Grid_ViewportSelectedRowColor;
	border-color: @Grid_ViewportSelectedRowColor;
}
// until here
// by the time WI00207690 is done, this should be gone...
