.gwPdfViewer, .gwPdfViewer__content {
	height: 100%;
	width: 100%;
}

.gwPdfViewer__loading-animation {
	height: 100%;
}

.gwPdfViewer__error-message {
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}
