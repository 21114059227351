.gwFilter {
	position: relative;
	z-index: 6;
	width: 100%;
	display: flex;

	.gwFilter-groups-wrapper {
		width: 100%;
		min-height: 30px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;

		.g-kg-item-name {
			vertical-align: middle;
		}

		.gwFilter-container {
			display: flex;
			flex-direction: column;
			flex: 0 1 auto;
			height: 100%;
			min-height: 0;
			border: 1px solid @grey;
			border-radius: 4px;
			background: #F6F6F6;
			padding: 0px;
			margin-bottom: @g-filter-container-margin-bottom;
		}

		ul.gwFilter-groups {
			flex: 0 1 auto;
			padding: 0;
			overflow-x: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			list-style: none;
			margin-bottom: 4px;

			.gwFilter-group {
				position: relative;

				&.user .gwFilter-strips {
					padding: 8px;
				}

				.gwFilter-strips {
					.gwFilter-strip {
						margin-bottom: @g-filter-strip-height-margin;
						list-style: none;
						overflow: hidden;

						.form-group {
							margin-bottom: 4px;

							.gwSearchBox-description-wrap {
								padding-right: 32px;

								.gwSearchBox-description {
									padding-top: 11px;

									.gwSearchBox-description-text {
										line-height: 10px;
									}
								}
							}

							.gwAddressSearchBox {
								border: none;

								.form-control:first-child {
									border-radius: 4px;
								}
							}
						}

						.gwFilter-path-span {
							font-size: 11px;
							font-style: italic;
							color: @Theme_ValueText;
						}

						.gwPath-indent {
							margin-left: 25px;
						}
					}
				}

				.gwFilter-separator-container {
					padding-top: 0;
					padding-bottom: 8px;

					.gwFilter-separator {
						margin: 0 8px;
					}
				}

				&.default {
					.gwFilter-strips {
						padding: 8px 8px 0 8px;

						.gwFilter-strip {
							margin-bottom: 8px;

							.form-group {
								margin-bottom: 0;
							}

							.g-icon-grip-button {
								cursor: default;
								background: @input-bg-disabled;
								.opacity(.65);

								i {
									color: @Theme_FieldTitle;
								}
							}

							.gwFilter-3rd-col {
								display: none;
							}
						}

						.gwFilter-strip:last-child {
							margin-bottom: 0;
						}
					}

					.gwFilter-separator-container {
						padding-top: 16px;
					}
				}
			}
		}
	}

	.gwFilter-1st-col {
		width: @gwFilter-1st-col-width;
		float: left;

		.gwFilter-path-span {
			margin-left: 25px;
		}

		.g-icon-grip-button {
			width: 19px;
			height: @g-filter-height;
			float: left;
			cursor: move;
			display: @g-icon-grip-button-display;
			.g-flat-button();
			text-align: center;
			padding: 4px 0;
			.opacity-hover;

			&.g-disabled-grip {
				cursor: default;
				background: @input-bg-disabled;
				.opacity(.65);

				i {
					color: @Theme_FieldTitle;
				}
			}
		}
	}

	.gwFilter-2nd-col {
		width: @gwFilter-2nd-col-width;
		float: left;

		.colWidth-1rd {
			width: @g-colWidth-1rd-width;
			float: left;
			padding-left: @g-colWidth-rd-padding-left;

			.form-control {
				display: table;
			}

			.gwNumeric label,
			.gwDatePicker label {
				position: absolute;
				z-index: 4;
				padding-top: @g-control-label-padding-top;
				padding-right: 0;
				padding-left: 6px;
				margin-top: 0;
				margin-left: 0;
				line-height: inherit;
				text-align: left;
			}
		}

		.colWidth-2rd {
			width: @g-colWidth-2rd-width;
			float: left;
			padding-left: @g-colWidth-rd-padding-left;

			.form-control {
				display: table;
			}

			.gwNumeric label,
			.gwDatePicker label {
				position: absolute;
				z-index: 4;
				padding-top: @g-control-label-padding-top;
				padding-right: 0;
				padding-left: 6px;
				margin-top: 0;
				margin-left: 0;
				line-height: inherit;
				text-align: left;
			}

			.gwFilter-boolean .gwTriStateRadioButton .btn-group {
				display: inline;
			}

			.gwSearchBox {
				height: @gwFilter-search-box-height;
			}
		}

		.gwFilter-operator {
			height: @g-filter-height;
			font-style: italic;
			cursor: pointer;
		}

		::-webkit-input-placeholder,
		:-moz-placeholder, /* Firefox 18- */
		::-moz-placeholder /* Firefox 19+ */ {
			color: @disabled-text;
		}

		.input-group-addon {
			height: @g-filter-height;
			position: relative;
			float: right;
		}

		.gwTriStateRadioButton {
			height: @g-filter-height;

			.btn-group .btn {
				height: @g-filter-height;
				width: @g-radioButton-width;
			}
		}
	}

	.gwFilter-3rd-col {
		width: @gwFilter-3rd-col-width;
		padding: @gwFilter-3rd-col-padding;
		float: left;
	}

	.gwFilter-1st-col, .gwFilter-2nd-col {
		.select2 {
			.select2-selection__arrow {
				color: @Theme_ValueText;
			}

			.select2-selection__rendered {
				padding-top: 0;
				line-height: 31px;
			}
		}
	}

	.gwFilter-separator {
		height: 1px;
		margin: 15px 8px;
		border-bottom: @gwFilter-separator-border dotted @Theme_DefaultButton;

		&:after {
			position: absolute;
			left: @gwFilter-separator-left;
			z-index: 1;
			padding-right: 8px;
			padding-left: 8px;
			margin-top: -8px;
			color: @Theme_FieldTitle;
			font-size: 11px;
			font-weight: bold;
			background: #F6F6F6;
			content: 'OR';
		}
	}

	.gwFilter-dropbox {
		padding: 16px;
		color: @Theme_ValueText;
		text-align: center;
		background: #F3F9FC;
		border: 1px dashed @Theme_DefaultButton;
		border-radius: 5px;
		margin-bottom: 10px;
		max-height: 50px;

		.ui-sortable-placeholder {
			height: 1px;
		}

		&:before {
			content: 'Drop Filter Here To Create New Group';
		}
	}

	.clear {
		clear: both;
	}
}

.gwFilter-button {
	float: @gwFilter-1st-col-form-control-float;
	width: @gwFilter-1st-col-form-control-width;
	height: @g-filter-height;
}

.gwFilter-select-operator {
	.select2-results__option {
		font-style: italic;

		&[aria-disabled=true] {
			padding-top: 10px;

			.g-code-desc-container {
				font-weight: bold;
			}
		}
	}
}

.gwFilter-strip {
	select.form-control {
		color: @Theme_ValueText;
		background-color: @InputControl_BackgroundColorEnd;
	}

	select.form-control:focus {
		background: @white;
		border-color: @input-border-focus;
		box-shadow: none;
	}

	.inner-numeric-from .form-control, .inner-duration-from .form-control {
		padding: 0 6px 0 38px;
	}

	.inner-numeric-to .form-control, .inner-duration-to .form-control {
		padding: 0 6px 0 24px;
	}

	.inner-date-from .form-control {
		padding: 0 28px 0 40px;
	}

	.inner-date-to .form-control {
		padding: 0 28px 0 24px;
	}

	.gwDatePicker {
		height: @g-filter-height;

		.form-control {
			line-height: @g-inner-date-line-height;
		}
	}

	.form-control {
		padding: 0 6px;
		height: @g-filter-height;
		text-align: left;
	}

	.descriptionReadOnly {
		background: #F2F2F2;
		color: @InputControl_ReadOnlyTextColor;
		padding: @g-descriptionReadOnly-padding;
	}

	.gwFilter-error {
		display: flex;
		align-items: center;
		float: left;

		.errorMessage {
			white-space: pre-wrap;
		}

		i {
			color: @state-error;
			margin: 5px;
		}
	}

	a {
		i {
			font-size: @g-deleteFilterButton-size;
			color: @Body_LinkColor;

			&:hover {
				opacity: 0.5;
			}
		}
	}
}

.g-filter-button.filter-button-disabled {
	color: @disabled-text;
	cursor: default !important;
}

.g-filter-button {
	flex: @g-filter-button-flex;
	display: flex;
	white-space: nowrap;
	width: auto;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: @g-filter-button-justify;
	min-width: @g-filter-button-width;
	background: none;
	height: @g-filter-button-line-height;
	line-height: normal;
	color: @Theme_Icons;
	padding: 0 10px;
	font-size: 12px;
	.user-select(none);

	.g-display-option-button {
		font-size: @gwFilter-icon-size;
		float: @gwFilter-icon-float;
		margin-top: @gwFilter-icon-margin-top;
	}

	.g-display-option-text {
		margin: 0 0 0 5px;
		font-weight: @g-button-text-font-weight;
	}

	&:not(.filter-button-disabled) {
		.opacity-hover;
	}
}

.gwFilter-actions {
	float: @gwFilter-actions-float;
	width: 100%;
	flex: 0 0 @g-control-height;

	.g-kg-top-container {
		height: auto;
	}

	.g-kg-title-sub-container {
		width: @gwFilter-actions-title-sub-container-width;
		height: auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;
		align-items: center;
	}

	.g-kg-title-container {
		line-height: @g-control-height;
	}

	.text-only .g-display-option-button,
	.buttons-only .g-display-option-text {
		display: none;
	}

	.buttons-only .g-display-option-button {
		min-width: @gwFilter-icon-size;
	}

	> ul {
		padding: 0;
	}

	> ul > li {
		width: @gwFilter-actions-footer-button-width;
		padding: 0;
		display: inline-block;
		float: left;
		list-style: none;

		button {
			width: 100%;
			height: @gwFilter-actions-footer-button-height;
		}

		> ul:before {
			position: absolute;
			top: -10px;
			right: 0;
			display: inline-block;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #CCC;
			border-left: 10px solid transparent;
			border-bottom-color: rgba(0,0,0,.2);
			content: '';
		}

		button[disabled] {
			color: @disabled-text;
		}

		button[disabled]:hover {
			opacity: 1;
		}

		span {
			position: relative;
			line-height: 20px;
			font-family: @default-font;
			margin-left: 5px;
		}
	}

	.g-flat-button {
		height: @g-flat-button-height;
		width: @g-flat-button-width;
		margin: @g-flat-button-margin;
		border-radius: @g-flat-button-border-radius;
	}

	.g-flat-button:hover {
		opacity: 0.5;
	}

	li:first-child .g-flat-button {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	li:last-child .g-flat-button {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.gwFilter-actions-main-buttons {
	padding: 0 6px 6px 6px;
	flex: 0 0 @g-control-height;

	.g-filter-add-button, .g-filter-find-button {
		width: @gwFilter-actions-footer-button-width;
		height: @gwFilter-actions-footer-button-height;
		line-height: @gwFilter-actions-footer-button-line-height;
		padding: 0;
	}

	.g-filter-find-button {
		float: right;
	}
}

.gwFilter-actions-popover {
	width: @g-saved-popover-content-width;
	max-width: @g-saved-popover-content-width;

	&.bottom {
		border-radius: @g-saved-popover-content-border-radius;
	}

	.arrow {
		display: @g-saved-popover-content-arrow;
	}

	.popover-content {
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		.gwDynamicMenu {
			> span {
				padding: 6px 10px;
			}

			.icon-spinner.icon-spin {
				margin-right: 10px;
			}

			ul {
				margin-bottom: 0;
			}

			li {
				position: relative;

				a {
					padding-right: 0;
					.text-overflow();
				}

				.button-bar {
					display: flex;
				}
			}
		}
	}
}

.g-filter-widget-container {
	display: flex;
	flex: 1 0 100%;
}

.g-aggregate-filter-container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: stretch;

	.g-aggregate-summary {
		flex: 1 0 auto;
	}

	.g-edit-aggregate {
		flex: 0 0 auto;
		width: auto;
		cursor: pointer;
		color: @Theme_ValueText;
		margin-left: -25px;
		padding-right: 11px;

		&:hover {
			opacity: 0.5;
		}
	}
}
