.g-workflow-milestones {
	.g-workflow-sidebar {
		.g-workflow-default-sidebar {
			margin-top: 5px;
		}
	}

	.g-workflow-milestones-main {
		height: 100%;

		&.g-show-trigger-actions .gwGroupBox-container {
			height: 50%;
		}
	}

	.g-last-milestone-event {
		overflow: hidden;
		width: 100%;
		text-align: center;
		background: #d9d9d9;
		border-radius: 4px;
		z-index: 1;

		span {
			display: block;
			line-height: 20px;
			.text-overflow();

			&:first-child {
				font-size: 16px;
				font-weight: bold;
			}

			&:last-child {
				color: @Grid_TitleFontColor;
				text-transform: uppercase;
			}
		}
	}
}
