.gwJobDocAddress-container {
	min-height: @gwJobDocAddress-min-height;
}

.gwJobDocAddress-container[data-bind*="isReadOnly: true"] {
	min-height: @gwJobDocAddress-min-height-readonly;
}

.gwJobDocAddress {
	.gwGroupBox-container > .gwGroupBox {
		width: 100%;
		height: 100%;
	}

	.panel-heading {
		width: @g-JobDocAddress-panel-heading-width;
	}

	.panel-title-container {
		display: flex;
		align-self: flex-end;
		align-items: baseline;

		.panel-title {
			flex: 1 1 auto;
		}
	}

	.nav-pills {
		line-height: @g-control-height;
		position: @g-JobDocAddress-nav-pills-position;
		top: @g-JobDocAddress-nav-pills-top;
		right: 0;
		text-align: center;

		li.nav-pills-inner-container {
			background: #FFFFFF;
			border: @g-JobDocAddress-nav-pills-border;
			border-radius: 4px;

			&.disabled {
				background: transparent;
			}
		}

		li:first-child {
			margin-right: @g-JobDocAddress-nav-pills-child-margin;
		}

		li:last-child {
			margin-left: @g-JobDocAddress-nav-pills-child-margin;
		}

		li {
			width: @g-JobDocAddress-nav-pills-width;
			min-width: 26px;
			height: @g-JobDocAddress-nav-pills-height;
			display: inline-block;
			float: @g-JobDocAddress-nav-pills-float;
			line-height: normal;
			text-align: center;
			margin: @g-JobDocAddress-nav-pills-margin;

			a {
				padding: @g-jobdocaddress-nav-pills-padding;
				border-radius: 3px;
			}

			i, button {
				font-size: @g-nav-pills-font-size;
			}
		}

		.gwJobDocAddress-clear-all-icon-right {
			padding: 0;
			color: @AddressControl_ModeSelectorBackgroundColor;
			display: flex;
			justify-content: flex-end;
			background: none;

			button {
				padding: @g-jobdocaddress-nav-pills-button-padding;
				min-height: 33px;
				height: 100%;
				background: none;

				&:hover:not(:disabled) {
					opacity: 0.5;
				}
			}
		}

		a,
		a:focus {
			color: @Theme_DefaultButton;
			background-image: none;
		}

		a:hover {
			color: @Theme_DefaultButtonText;
			background-color: @Theme_Hover;
			background-image: none;
		}

		> .active {
			> a,
			> a:hover,
			> a:focus {
				color: @Theme_DefaultButtonText;
				background-color: @Theme_DefaultButton;
				background-image: none;
			}
		}
	}

	.tab-content {
		overflow: hidden;
		border: 0;
		border-radius: 4px;
		margin-bottom: 0;
	}

	.form-group {
		margin: 0;

		.gwTextBox > input {
			margin-bottom: 0;
		}
	}

	.btn-toggle {
		width: 90px;
		padding: 4px;
	}

	.form-horizontal {
		height: 20px;
		margin-bottom: 6px;

		.controls {
			margin-left: 88px;
		}

		.col-xs-9 {
			padding-right: 0;
			padding-left: 0;
		}

		.control-label {
			padding-top: 0;
			padding-right: 5px;
			padding-left: 0;
		}

		.form-group {
			height: 100%;

			> div {
				height: 100%;
			}
		}

		.input-group-addon {
			padding-top: 1px;

			i {
				margin-top: 0;
			}
		}
	}

	.g-selected-org {
		margin: 5px 0;
		font-weight: bold;
		line-height: 33px;
		.text-overflow();
	}

	[data-contents="gwGroupBox"] {
		overflow: visible;
	}

	.g-name-label {
		position: absolute;
		z-index: 1;
		margin-top: 2px;
		margin-left: 5px;
		line-height: 14px;
		text-align: left;
	}

	.g-contact-pane {
		margin-top: 30px;
	}

	.g-cols-2 {
		ul {
			display: table;
			width: 100%;
		}

		li {
			display: table-cell;
			width: 50%;
			height: 100%;
			vertical-align: top;

			&.g-col-1 input[type="text"] {
				border: 1px solid #d3d6d9;
			}
		}

		&.gwJobDocAddress-bottom {
			li.g-col-1 input[type="text"] {
				border-bottom-right-radius: 0;
			}

			li.g-col-2 input[type="text"] {
				border-bottom-left-radius: 0;
			}
		}
	}

	li {
		input[type="text"] {
			padding-top: 18px;
			padding-bottom: 3px;
		}

		&.gwJobDocAddress-inner input[type="text"] {
			border-radius: 0;
		}

		&.gwJobDocAddress-inner ul > li{
			position: relative;
		}

		&.gwJobDocAddress-top input[type="text"] {
			.border-bottom-radius(0);
		}

		&.gwJobDocAddress-bottom input[type="text"] {
			.border-top-radius(0);
		}

		&.gwJobDocAddress-inner input[type="text"],
		&.gwJobDocAddress-top input[type="text"],
		&.gwJobDocAddress-bottom input[type="text"] {
			border: 1px solid #d3d6d9;
		}
	}

	button[disabled] {
		color: #ccc;

		&:hover {
			opacity: initial;
		}
	}

	.gwAddressSearchBox {
		border: none;
		border-radius: 0;
	}

	.gwJobDocAddress-top .gwAddressSearchBox {
		border-radius: 4px 4px 0 0;
	}

	.icon-spinner {
		font-size: 16px;
		color: @Theme_Icons;
	}

	.g-readonly-jobDocAddress {
		ul {
			padding: 0;
			margin-bottom: 0;
		}

		li {
			min-height: 20px;
			line-height: 20px;
			list-style-type: none;
			font-size: 14px;
			word-break: break-all;

			.g-contact-indicator {
				float: left;
				width: 18px;
				font-size: 11px;
				color: @Theme_FieldTitle;
			}

			.g-contact-detail {
				float: left;
				width: ~'calc(100% - 18px)';
				word-break: break-all;
			}

			.g-readonly-contact-email span {
				background: yellow;
				word-break: break-all;
			}

			> span {
				font-size: 14px;
				color: @InputControl_ReadOnlyTextColor;
			}
		}

		.divider {
			border-top: 1px solid #d3d6d9;
			margin: 4px 0;
		}

		.g-empty-jobDocAddress-tab-text {
			display: block;
			border: 0;
			font-size: 11px;
			color: @Theme_FieldTitle;
			height: 18px;
			line-height: 18px;
			padding: 0;

			> span {
				font-size: 14px;
				color: @InputControl_ReadOnlyTextColor;
			}
		}
	}

	.g-jobDocAddress-loading-wrap {
		position: relative;

		> div {
			transform: translate(-50%, -50%);
		}

		span {
			font-size: 16px;
		}
	}

	.g-readonly-jobDocAddress,
	.g-jobDocAddress-loading-wrap {
		min-height: 252px;
		max-height: @g-JobDocAddress-read-only-max-height;
		border: 1px solid @InputControl_BorderBrushStart;
		border-radius: 5px;
		padding: 3px 8px 5px 8px;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.gwJobDocAddress-override-indicator {
	width: 20px;
	color: @Theme_FieldTitle;
	cursor: default;
}

.gwJobDocAddress-divider {
	height: 14px;
	margin-top: 11px !important;
	margin-right: 4px;
	border-left: 1px solid #d3d6d9;
}

.gwJobDocAddress-full-address {
	margin-top: 5px;
}

.gwJobDocAddress-wrap {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
}
