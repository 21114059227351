hgroup {
	display: block;
}

audio,
canvas,
progress,
video {
	vertical-align: baseline;
}

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

hr {
	box-sizing: content-box;
	height: 0;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	margin: 0;
}

html input[disabled] {
	cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input {
	line-height: normal;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
	*,
	*:before,
	*:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	}

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	.navbar {
		display: none;
	}

	.btn > .caret,
	.dropup > .btn > .caret {
		border-top-color: #000 !important;
	}

	.label {
		border: 1px solid #000;
	}

	.table {
		border-collapse: collapse !important;
	}

	.table td,
	.table th {
		background-color: #fff !important;
	}
}

* {
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

body {
	line-height: 1.42857143;
}

input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

a {
	text-decoration: none;
}

a:focus {
	color: #23527c;
	text-decoration: underline;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

img {
	vertical-align: middle;
}

hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #eeeeee;
}

ul,
ol {
	margin-top: 0;
	margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
	margin-bottom: 0;
}

html,
body {
	width: 100%;
}

html {
	height: 100%;
	overscroll-behavior-y: contain;
}

body {
	height: calc(~'100% - 38px');
	font-weight: @Body_FontWeight;
	color: @Body_TextColor;
	.g-font-size-px(@Body_FontSize);
	background-color: @Body_BackgroundColorEnd;
}

label {
	.text-overflow;
	margin-bottom: 6px;
	font-size: @g-label-font-size;
	font-weight: normal;
	color: @Theme_FieldTitle;
}

a:hover {
}

iframe {
	width: 100%;
	height: 280px;
	border: none;
}

hr {
	width: 95%;
	border-top: 1px dashed #5f90bc;
}

a {
	color: @Theme_DefaultButton;

	&:hover {
		color: @Theme_Hover;
		text-decoration: none;
	}
}

.g-invalid-property {
	border: 1px solid red;
	height: 100%;
	position: relative;
	border-radius: 4px;
	background-color: #f2f2f2;

	span {
		color: red;
		font-size: 14px;
		padding-left: 6px;
		position: absolute;
		top: 50%;
		font-style: italic;
		transform: translateY(-50%);
	}
}

.g-read-restricted {
	.icon-eye-slash {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 10;
	}
}

.noscript {
	font-size: 20px;
	margin: 20px;
	display: flex;
	justify-content: center;
	padding: 10px;
	color: @state-title-text;
	background-color: @state-error;
}

#g-btn-reload-page {
	margin: 20px;
}
