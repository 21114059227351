.g-tickbox {
	position: relative;
	display: inline-block;
	height: @g-tickbox-help-height;
	width: @g-tickbox-help-width;
	margin-left: @g-tickbox-help-margin-left;

	input[type="checkbox"] {
		.opacity(0);

		&:checked + label {
			border-color: @InputControl_FocusedBorderBrushEnd;

			&:hover:after {
				.opacity(.7);
			}

			&:after {
				border: 1px solid @Theme_ValueText;
				border-top: none;
				border-right: none;
				.opacity(1);
			}
		}

		&:focus + label {
			box-shadow: inset 0 1px 1px @InputControl_FocusedBorderBrushEnd, 0 1px 3px @InputControl_FocusedBorderBrushEnd;
		}
	}

	label {
		position: absolute;
		top: 50%;
		left: 0;
		overflow: visible;
		width: @g-tickbox-help-label-width;
		height: @g-tickbox-help-label-height;
		margin-top: -4px;
		font-size: @g-tickbox-help-font-size;
		color: @InputControl_FocusedBorderBrushEnd;
		cursor: pointer;
		background-color: @InputControl_BackgroundColorStart;
		border: 1px solid @InputControl_FocusedBorderBrushEnd_Lighten30;
		border-radius: 2px;

		&:hover {
			border-color: @InputControl_FocusedBorderBrushEnd;
		}

		&:hover:after {
			.opacity(.5);
		}

		&:after {
			position: absolute;
			top: @g-tickbox-help-after-top;
			left: 1px;
			width: @g-tickbox-help-after-width;
			height: 4px;
			background: transparent;
			content: '';
			.rotate(-45deg);
			.opacity(0);
		}
	}

	span {
		position: absolute;
		top: -4px;
		left: @g-tickbox-help-span-left;
	}
}
