.g-anchored > label[for],
.importTaskPage .g-control-wrap label,
.mappingPage label.control-label,
.delimiter-selector > label,
.worksheet-selector > label,
.header-line-selector > label,
.g-change-password li label,
.gwConfigureFilter label,
.gwJobDocAddress li label,
.gwSearchBox label,
.gwSelectBox label,
.gwTextBox label,
.gwNumeric label,
.gwDatePicker label,
.gwAnchor label,
.g-import-input label,
.gwRichTextEditor label,
.gwColorPicker label,
.gwFinder label,
.gwMeasure label,
.gwTransitionTextBox label,
.g-configuration-sidebar .gwImageEdit-label,
.g-quickSearch-input-group label {
	position: absolute;
	z-index: 4;
	padding: 5px 8px 2px;
	margin-bottom: 0;
	line-height: 14px;
	order: 0;
	max-width: 100%;
}
