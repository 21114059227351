.gwThemeColorSelectMenu__option {
	display: flex;
	padding: 3px 5px;
}

.gwThemeColorSelectMenu__option-color-box {
	width: 30px;
	border: 1px solid black;
}

.gwThemeColorSelectMenu__option-description {
	flex: 1;
	margin-left: 5px;
	.text-overflow();
}
