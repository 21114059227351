.g-dialog-colorscheme {
	.modal-dialog.g-modal-autoresize .modal-content {
		height: 500px;
	}

	.g-colorscheme {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.g-colorscheme__name {
		float: left;
		width: 50%;
	}

	.g-colorscheme__ispublished {
		float: left;
		width: 25%;
	}

	.g-colorscheme__colorstrips {
		height: 300px;
	}

	.koGrid {
		.kgViewport {
			.g-colorscheme-action-button {
				padding: 4px 6px;
			}

			.kgRow {
				.g-colorscheme__colorstrips__input {
					input {
						background-color: transparent;
					}

					input[disabled] {
						color: @InputControl_ReadOnlyTextColor;
						background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
					}
				}

				.gwColorPicker {
					&.input-group:before {
						top: 2px;
					}

					input.form-control {
						padding-left: 16px;
					}

					input.ui-spinner-input {
						padding-right: 12px;
					}

					.colpick_hex_field input {
						right: 0;
					}
				}
			}
		}
	}
}
