.gwFlowStepsBar {
	position: relative;
	height: @gwFlowStepsBar-height;
	flex: 1 0 @gwFlowStepsBar-height;
	width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	border-bottom: 1px solid #d3d6d9;
	background-color: #ebeef3; 

	li {
		padding: 0;

		&:first-child {
			margin-left: -7px;

			.gwFlowStepsBar-step-text {
				&:before {
					display: none;
				}
			}
		}
	}
}

.gwFlowStepsBar-arrow-left,
.gwFlowStepsBar-arrow-right {
	position: absolute;
	z-index: 7;
	display: inline-block;
	top: 0;
	height: 0;
	border-top: @gwFlowStepsBar-hidden-arrow solidtransparent;
	border-bottom: @gwFlowStepsBar-hidden-arrow solidtransparent;
	.opacity(.25);
}

.gwFlowStepsBar-arrow-left {
	left: 5px;
	border-right: @gwFlowStepsBar-hidden-arrow solidtransparent;
}

.gwFlowStepsBar-arrow-right {
	right: 5px;
	border-left: @gwFlowStepsBar-hidden-arrow solidtransparent;
}

.gwFlowStepsBar-bar {
	width: 100%;
	height: auto;
	max-height: @gwFlowStep-height;
	margin-left: 0;
	margin-bottom: 0;
	font-size: 0;
	white-space: nowrap;
	overflow: @gwFlowStepsBar-overflow;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		display: none;
	}
}

.gwFlowStepsBar-step {
	position: relative;
	display: inline-block;
	white-space: nowrap;
	background: transparent;

	.gwFlowStepsBar-end {
		display: none;
	}

	&-final &-text {
		margin-right: 0;

		&:after{
			display: none;
		}
	}

	&-final &-highlight {
		right: 0;

		&:after {
			display: none;
		}
	}
}

.gwFlowStepsBar-step-shell {
	cursor: default;

	&:hover {
		.gwFlowStepsBar-step-completed {
			.gwFlowStepsBar-step-text {
				color: @FlowStep_PreviousStepHoverTextColor;
				background: @FlowStep_PreviousStepHoverBackgroundColorStart;

				&:after {
					border-left: @gwFlowStep-arrow-border-left solid @FlowStep_PreviousStepHoverBackgroundColorStart;
				}
			}

			&.gwFlowStepsBar-step-final .gwFlowStepsBar-end:before {
				border-color: @FlowStep_PreviousStepHoverBackgroundColorStart;
				background-color: @FlowStep_PreviousStepHoverBackgroundColorStart;
			}
		}
	}
}

.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-current,
.gwFlowStepsBar-step-current {
	.gwFlowStepsBar-step-text {
		color: @FlowStep_CurrentStepTextColor;
		background: @FlowStep_CurrentStepBackgroundColorStart;

		&:after {
			border-left: @gwFlowStep-arrow-border-left solid @FlowStep_CurrentStepBackgroundColorStart;
		}
	}
}

.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-current.gwFlowStepsBar-step-final,
.gwFlowStepsBar-step-current.gwFlowStepsBar-step-final {
	.gwFlowStepsBar-end:before {
		border-color: @FlowStep_CurrentStepBackgroundColorStart;
		background-color: @FlowStep_CurrentStepBackgroundColorStart;
	}
}

.gwFlowStepsBar-step-clickable {
	cursor: pointer;
}

.gwFlowStepsBar-bar li:first-child .gwFlowStepsBar-step-text {
	padding-left: 14px;
}

.gwFlowStepsBar-step-text {
	display: inline-flex;
	align-items: center;
	font-size: 12px;
	height: @gwFlowStep-height;
	padding-right: 8px;
	padding-left: @gwFlowStep-padding-left;
	color: @FlowStep_FutureStepTextColor;
	background: @FlowStep_FutureStepBackgroundColorStart;

	&:before,
	&:after {
		position: absolute;
		display: inline-block;
		width: 0;
		height: @gwFlowStep-height;
		content: ' ';
	}

	&:before {
		top: 0;
		left: 1px;
		z-index: 0;
		border-top: @gwFlowStep-step-before-border solid transparent;
		border-bottom: @gwFlowStep-step-before-border solid transparent;
		border-left: @gwFlowStep-arrow-border-left3 solid @white;
	}

	&:after {
		top: 0;
		left: 100%;
		z-index: 1;
		border-top: @gwFlowStep-step-after-border solid transparent;
		border-bottom: @gwFlowStep-step-after-border solid transparent;
		border-left: @gwFlowStep-arrow-border-left solid @FlowStep_FutureStepBackgroundColorStart;
	}
}

.gwFlowStepsBar-step-highlight {
	position: absolute;
	right: 1px;
	bottom: 0;
	left: 4px;
	z-index: 2;
	height: 2px;
	background-color:@FlowStep_CurrentStepHighlightColor;

	&:before,
	&:after {
		position: absolute;
		bottom: 0;
		z-index: 2;
		display: inline-block;
		width: 0;
		height: 2px;
		content: ' ';
	}

	&:before {
		left: -2px;
		border-top: 1px solid transparent;
		border-right: 1px solid @FlowStep_CurrentStepHighlightColor;
		border-bottom: 1px solid @FlowStep_CurrentStepHighlightColor;
		border-left: 1px solid transparent;
	}

	&:after {
		left: 100%;
		border-top: 1px solid @FlowStep_CurrentStepHighlightColor;
		border-right: 1px solid transparent;
		border-bottom: 1px solid transparent;
		border-left: 1px solid @FlowStep_CurrentStepHighlightColor;
	}
}

.gwFlowStepsBar-step-undefined,
.gwFlowStepsBar-step-final {
	.gwFlowStepsBar-end {
		display: inline-block;
	}
}

.gwFlowStepsBar-step-undefined {
	.gwFlowStepsBar-end {
		width: @gwFlowStep-future-step-width;
		margin-left: 0;
		background-color: @FlowStep_FutureStepBackgroundColorStart;

		&:before {
			position: absolute;
			top: 0;
			left: @gwFlowStep-step-before-left;
			z-index: 0;
			display: inline-block;
			width: 0;
			background-color: transparent;
			border-top: @gwFlowStep-step-before-border-end solid transparent;
			border-bottom: @gwFlowStep-step-before-border-end solid transparent;
			border-left: @gwFlowStep-arrow-border-left2 solid @white;
			content: ' ';
		}
	}

	.gwFlowStepsBar-end-arrow-one,
	.gwFlowStepsBar-end-arrow-two {
		display: inline-block;

		&:before,
		&:after {
			position: absolute;
			top: @gwFlowStep-step-double-arrow-top;
			width: 0;
			height: @gwFlowStep-step-double-arrow-height;
			background-color: transparent;
			content: ' ';
			border-top: @gwFlowStep-arrow-border-left-end solid transparent;
			border-bottom: @gwFlowStep-arrow-border-left-end solid transparent;
		}

		&:before {
			border-left: @gwFlowStep-arrow-border-left-end2 solid @FlowStep_FutureStepBackgroundColorStart;
		}

		&:after {
			border-left: @gwFlowStep-arrow-border-left-end2 solid @white;
		}
	}

	.gwFlowStepsBar-end-arrow-one {
		&:before {
			left: @gwFlowStep-end-arrow-one-before-left;
			z-index: 5;
		}

		&:after {
			left: @gwFlowStep-end-arrow-one-after-left;
			z-index: 4;
		}
	}

	.gwFlowStepsBar-end-arrow-two {
		&:before {
			left: @gwFlowStep-end-arrow-two-before-left;
			z-index: 3;
		}

		&:after {
			left: @gwFlowStep-end-arrow-two-after-left;
			z-index: 2;
		}
	}
}

.gwFlowStepsBar-step-final {
	.gwFlowStepsBar-end {
		width: 11px;
		height: @gwFlowStep-height;
		background-color: @Theme_Background;

		&:before,
		&:after {
			position: absolute;
			display: inline-block;
			top: 0;
			width: 6px;
			height: @gwFlowStep-height;
			background-color: transparent;
			content: ' ';
		}

		&:before {
			left: -2px;
			border: 3px solid;
			border-color: @FlowStep_FutureStepBackgroundColorStart;
			background-color: @FlowStep_FutureStepBackgroundColorStart;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		&:after {
			right: -2px;
			border: 3px solid @FlowStep_FutureStepBackgroundColorStart;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			background-color: @FlowStep_FutureStepBackgroundColorStart;
		}
	}

	.gwFlowStepsBar-end-arrow-one,
	.gwFlowStepsBar-end-arrow-two {
		display: none;
	}

	&.gwFlowStepsBar-step-completed .gwFlowStepsBar-end:before {
		border-color: @FlowStep_PreviousStepBackgroundColorStart;
		background-color: @FlowStep_PreviousStepBackgroundColorStart;
	}
}

.gwFlowStepsBar-end {
	position: absolute;
	height: @gwFlowStep-height;
	overflow: hidden;
}

.gwFlowStepsBar-end-undefined {
	top: 5px;
	border-top: 11px solid transparent;
	border-bottom: 11px solid transparent;
	border-left: 11px solid @white;
}

.gwFlowStepsBar-step-completed .gwFlowStepsBar-step-text {
	color: @FlowStep_PreviousStepTextColor;
	background: @FlowStep_PreviousStepBackgroundColorStart;

	&:after {
		border-left: @gwFlowStep-arrow-border-left solid @FlowStep_PreviousStepBackgroundColorStart;
	}
}
