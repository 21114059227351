.gwAnchor {
	width: 100%;
	height: @g-anchor-size + 4px;
	padding: 0;
}

.gwAnchor-selection {
	position: relative;
	display: block;
	width: @g-anchor-size;
	height: @g-anchor-size;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2px;
}

.gwAnchor-center {
	position: absolute;
	top: @g-anchor-long-edge-size;
	left: @g-anchor-long-edge-size;
	width: @g-anchor-center-size;
	height: @g-anchor-center-size;
	border: @g-anchor-outline-thickness solid @g-anchor-center-outline-color;
}

.gwAnchor-edge {
	position: absolute;
	border: @g-anchor-edge-outline-thickness solid @g-anchor-edge-outline-color;
	background-color: @g-anchor-edge-background-color;

	&:hover {
		cursor: pointer;
		background-color: @g-anchor-edge-hover-background-color;
		border-color: @g-anchor-edge_hover-outline-color;
	}
}

.gwAnchor-edge-left {
	top: @g-anchor-calculated-middle-point;
	left: 0;
	width: @g-anchor-long-edge-size;
	height: @g-anchor-short-edge-size;
}

.gwAnchor-edge-top {
	top: 0;
	left: @g-anchor-calculated-middle-point;
	width: @g-anchor-short-edge-size;
	height: @g-anchor-long-edge-size;
}

.gwAnchor-edge-right {
	top: @g-anchor-calculated-middle-point;
	left: @g-anchor-long-edge-size + @g-anchor-center-size;
	width: @g-anchor-long-edge-size;
	height: @g-anchor-short-edge-size;
}

.gwAnchor-edge-bottom {
	top: @g-anchor-long-edge-size + @g-anchor-center-size;
	left: @g-anchor-calculated-middle-point;
	width: @g-anchor-short-edge-size;
	height: @g-anchor-long-edge-size;
}

.gwAnchor-edge-selected {
	background-color: @g-anchor-edge-selected-background-color;
	border-color: @g-anchor-edge_selected-outline-color;

	&:hover {
		background-color: @g-anchor-edge-selected-background-color;
		border-color: @g-anchor-edge_selected-outline-color;
	}
}
