.image-capture {
	@Thumbnail_Size: 84px;
	display: flex;
	flex-direction: column;
	height: 100%;

	.image-list {
		display: flex;
		padding: 0;
		margin: 0;
		list-style: none;
		flex-wrap: wrap;
		flex-grow: 1;
		align-content: flex-start;
		overflow: auto;
	}

	.image-thumbnail-container {
		position: relative;
	}

	.image-thumbnail {
		width: @Thumbnail_Size;
		height: @Thumbnail_Size;
		border: 1px solid @Panel_BorderColor;
		margin: 8px;
		border-radius: 5px;
		transition: all 2s ease-in;
	}

	.image-delete {
		position: absolute;
		top: 0;
		right: 0;
		text-decoration: none;
		border-radius: 50%;
		text-decoration: none;
		line-height: 23px;
		padding: 0 6px;
		border: 1px solid @Panel_BorderColor_Darken20;
		background-color: @Popover_BackgroundColor_Darken5;
	}

	.image-add {
		width: @Thumbnail_Size;
		height: @Thumbnail_Size;
		border: 1px solid @Panel_BorderColor;
		background-color: transparent;
		margin: 8px;
		padding: 0;
		font-size: 40px;
	}

	.image-data {
		padding: 8px;
		width: 100%;
		align-self: flex-end;
	}
}

.image-capture-dialog {
	.modal-dialog.g-modal-autoresize .modal-content {
		height: 500px;
	}
}
