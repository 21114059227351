@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.mappingPage {
	margin-right: 0;

	.g-heading .navbar {
		.dropdown.open .dropdown-menu {
			margin-top: 6px;
		}

		li .btn i {
			margin-right: 2px;
		}
	}

	.g-sample-file .gwFileUploader input[type='file'] {
		left: 200px;
	}

	.gwGroupBox .g-mapping-details {
		width: auto;
	}

	.modal-dialog.g-modal-autoresize .modal-content {
		top: 10px;
		min-width: 1050px;
	}

	.modal-dialog.g-modal-autoresize .modal-body {
		padding: 0;
	}

	.g-row-task-shell {
		height: ~'calc(100% - 39px)';
	}

	.tab-content {
		height: 100%;
		overflow: hidden;
	}

	.tab-pane {
		height: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		padding-top: 10px;
		padding-bottom: 60px;

		&.g-import-preview-tab {
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			padding-bottom: 0;
			height: ~'calc(100% - 20px)';

			.g-preview-container {
				border-radius: 5px;
				border-width: 1px;

				.g-preview-wrapper {
					.g-preview-table {
						> .gwGroupBox {
							.collapsible-groupbox-header();
						}

						.panel-body {
							border-radius: 5px;
							height: 300px;
						}

						div[data-contents='gwGroupBox'] {
							padding: 10px;
						}

						.koGrid {
							height: ~'calc(100% - 40px)';
						}
					}

					&:first-child {
						.g-preview-table > .gwGroupBox {
							border-top-left-radius: 5px;
							border-top-right-radius: 5px;

							.panel-heading {
								border-top-left-radius: 5px;
								border-top-right-radius: 5px;
							}
						}
					}

					&:not(:first-child) {
						.g-preview-table > .gwGroupBox {
							border-top: none;
						}
					}

					&:last-child {
						.g-preview-table > .gwGroupBox {
							border-bottom-left-radius: 5px;
							border-bottom-right-radius: 5px;
						}
					}
				}
			}
		}
	}

	.kgCell > div {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.gwGroupBox .panel-body {
		position: relative;
		margin-top: 0;
	}

	.mapping-tab {
		.g-mapping-table-container {
			height: 100%;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			border-radius: 4px;
		}

		.g-groupbox-wrapper {
			height: 95%;
		}

		.gwGroupBox-container {
			height: 100%;

			.panel-body {
				height: 100%;
			}

			&.g-mapping-table {
				> .gwGroupBox {
					.collapsible-groupbox-header();
					overflow-y: auto;
					border-bottom: none;
					background-color: @Theme_Background;
				}

				&.g-first-mapping-table {
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
				}

				&.g-last-mapping-table {
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
					border-bottom: 1px solid #d3d6d9;
				}

				.panel-title {
					.g-table-title {
						float: left;
					}

					.g-remove-table {
						float: right;
						padding-top: 3px;
						display: block;
						color: @Theme_Icons;

						&:hover {
							opacity: 0.5;
						}
					}
				}

				.panel-collapse {
					height: ~'calc(100% - 34px)';
					min-height: 300px;

					& > .panel-body {
						margin-top: 10px;
						margin-left: 10px;
					}
				}
			}

			&[data-collapsible='false'] {
				.panel-heading {
					padding-left: 0;

					.panel-title {
						padding-left: 0;
					}
				}
			}
		}

		.g-mapping-footer {
			display: flex;
			position: absolute;
			bottom: 10px;

			.btn {
				margin-right: 10px;
			}
		}
	}

	.g-shell-content {
		margin: 0 10px 0 10px;
	}

	.gwFileUploader .fileinput-wrapper {
		float: right;
		margin-top: -38px;
	}

	.data-tab > div:first-child {
		height: 38px;
		margin-bottom: 10px;
	}

	.g-mapping-name,
	.g-sample-file,
	.gwFileUploader-buttonbar,
	.worksheet-selector {
		width: 660px;
	}

	.worksheet-selector {
		height: 42px;
	}

	.delimiter-selector,
	.header-line-selector {
		float: left;
		width: 330px;
	}

	.g-include-headers {
		float: left;
		width: 220px;
	}

	.g-line-delimiter-container {
		height: auto;
		width: 200px;
		margin-bottom: 8px;
		display: inline-block;
	}

	.g-mapped-table-container {
		margin-bottom: 8px;
		display: inline-block;
	}

	.sourcePanel {
		width: 30%;
		height: 100%;

		.g-mapped-table-container {
			width: 100%;
		}

		.panel-buttons {
			margin-top: 18px;
			text-align: left;

			.icon-chevron-left {
				padding-right: 5px;
			}

			.icon-chevron-right {
				padding-left: 5px;
			}
		}

		.g-loading-animation {
			position: absolute;
			top: 50%;
			left: 35%;
		}
	}

	.targetPanel {
		width: 68%;
		height: 100%;
		margin-left: 10px;

		.g-mapped-table-container {
			width: 450px;
		}

		.g-custom-mapping-list-cell {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			padding-left: 4px;

			.g-custom-mapping-list-applied {
				.text-ellipsis();
			}

			.g-custom-mapping-list-actions {
				margin-left: auto;
				flex-shrink: 0;
			}

			.g-custom-mapping-list-icon {
				float: right;
				height: @gwDataGrid-row-height-without-border;
				width: 24px;
				padding: 0;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					.opacity-hover(0s);
				}

				i {
					color: @Theme_Icons;
				}
			}
		}

		.g-matching-mode-cell-container {
			height: 100%;

			.gwSearchBox {
				height: @gwDataGrid-row-height-without-border;

				.form-control {
					padding-left: 5px;
					font-size: 12px;
				}
			}
		}

		.g-matching-mode-cell {
			.select2-selection__rendered {
				padding-left: 4px !important;
			}
		}

		.g-mapping-default-cell {
			height: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.panel-buttons {
			float: right;
			margin-top: 18px;
		}
	}

	.panel-container {
		display: flex;

		.sourcePanel,
		.targetPanel {
			flex: 0 1 auto;

			[data-contents='gwGroupBox'] .g-mapping-grid-container {
				height: 100%;

				.g-mapping-grid {
					height: ~'calc(100% - 30px)';
				}
			}
		}
	}

	.g-mapped-to {
		width: 100%;
		height: 100%;
		padding-top: 4px;
		padding-left: 8px;
	}

	.g-data-mapping-droppable {
		height: 100%;
	}

	.g-data-mapping-draggable {
		overflow: hidden;
		height: 100%;
		cursor: move;

		.g-data-mapping-draggable-field {
			overflow: hidden;
			color: #003479;
		}

		.g-data-mapping-draggable-header {
			width: 100%;
			height: 100%;
			padding: 5px;

			.g-data-mapping-draggable-inner {
				height: 100%;
				display: flex;
				align-items: center;
			}
		}

		.g-data-mapping-draggable-column {
			width: 32px;
			height: 20px;
			line-height: 20px;
			background: @Theme_GeneralText;
			color: @white;
			margin: 2px 0 0 2px;
			text-align: center;
			border-radius: 3px;
			text-align: center;
			float: left;

			.g-data-mapping-draggable-inner {
				line-height: normal;
				padding: 0;
			}
		}

		&.ui-draggable-dragging {
			border: 2px solid @Grid_ViewportHoverRowColor;
			border-radius: 5px;
			background: white;
			height: 30px !important;

			.g-data-mapping-draggable-column {
				margin-top: 3px;
				background: @Grid_ViewportHoverRowColor;
			}
		}
	}

	.select-table {
		margin-right: 4px;
	}

	.select-table-wrapper {
		float: right;
		position: relative;
		margin-top: -38px;
	}

	.kgRow--odd:not(.kgRow--selected):not(.kgRow--hovered) .g-mapping-readonly-cell {
		background-color: @Grid_ReadonlyViewportBackgroundColor;
	}

	.kgRow--even:not(.kgRow--selected):not(.kgRow--hovered) .g-mapping-readonly-cell {
		background-color: @Grid_ReadonlyViewportAlternatingColor;
	}

	&.importMappingPage,
	&.g-full-page {
		.mapping-tab .gwGroupBox .panel-body {
			padding-bottom: 77px;
		}
	}
}

.g-mapping-default-cell span,
.g-data-mapping-draggable-field {
	padding: 0 8px;
	line-height: @gwDataGrid-row-height-without-border;
}

.g-mapping-full-page .g-sidebar {
	top: @g-sidebar-top-flow-steps-offset;
}
