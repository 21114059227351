.g-notes-modal {
	height: 100%;

	.gwRichTextEditor {
		position: relative;

		.tox-tinymce {
			height: 100% !important;
		}
	}
}

#gNotesDialogGrid .gwDataGrid {
	position: relative;
}

#gNotesDialogGrid {
	height: 50%;
	min-height: 200px;
}

#gNotesDialogText, #gNotesDialogData {
	height: ~'calc(50% - 25px)';
	margin-top: 10px;
	margin-bottom: 15px;
	min-height: 150px;
}
