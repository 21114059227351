#importPage {
	.g-import-controls {
		padding: 10px;
		width: 530px;
		float: left;
		margin-right: 90px;

		.g-import-input {
			.select2-selection {
				min-height: @g-control-height;
			}
		}

		.gwGroupBox {
			.panel-body {
				position: relative;
				margin-top: 0;
			}

			div[data-contents='gwGroupBox'] {
				padding-left: 10px;
			}
		} 

		.gwValidation-highlight {
			.alert-danger-highlight();

			.select2-choice {
				.alert-danger-highlight();
			}
		}

		.g-import-input {
			width: 500px;

			.g-sample-file {
				position: relative;

				.gwFileUploaderInput {
					height: 100%;
					padding-top: 18px;
					margin-bottom: 0;
				}

				.gwFileUploader {
					position: absolute;
					top: 5px;
					right: 0;
				}
			}
		}

		.g-import-buttons {
			margin-top: 10px;
			float: right;

			.btn {
				width: 150px;
			}
		}
	}

	+ .g-sidebar {
		top: 3px;
	}
}
