.dateclockpicker-popover .popover-title,
.dateclockpicker-popover .popover-content,
.dateclockpicker-popover .dateclockpicker-footer {
	position: relative;
	padding: 0
}

.dateclockpicker-popover .popover-title {
	font-size: 32px !important;
	text-align: center;
	line-height: 34px;
	height: 45px;
	margin: 0;
	background-color: #f7f7f7;
}

.dateclockpicker-popover .popover-title .dateclockpicker-calendar-view,
.dateclockpicker-popover .popover-title .dateclockpicker-clock-view {
	padding-top: 3px;
	padding-bottom: 3px
}

.dateclockpicker-popover .popover-title span {
	cursor: pointer;
	margin: 0 5px
}

.dateclockpicker-popover .popover-title span.dateclockpicker-span-separator {
	margin: 0 -4px;
	cursor: default
}

.dateclockpicker-popover .popover-title span.dateclockpicker-span-months {
	position: relative
}

.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:before,
.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:after {
	content: "-";
	position: absolute;
	top: 4px
}

.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:before {
	left: -17px
}

.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:after {
	right: -17px
}

.dateclockpicker-popover .popover-content {
	width: 234px;
	height: 218px;
	font-size: 12px
}

.dateclockpicker-popover .popover-content:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px
}

.dateclockpicker-popover .popover-content .dateclockpicker-clock-view {
	padding: 8px
}

.dateclockpicker-popover.popover.top > .arrow, .dateclockpicker-popover.popover.bottom > .arrow {
	margin-left: -15px
}

.dateclockpicker-popover.popover.top > .arrow:after,
.dateclockpicker-popover.popover.bottom > .arrow:after {
	margin-left: -6px
}

.dateclockpicker-popover.popover > .arrow {
	border-width: 7px
}

.dateclockpicker-popover.popover > .arrow:after {
	border-width: 6px
}

.dateclockpicker-popover.hideable-dropdown-menu > .popover-content {
	overflow: hidden
}

.dateclockpicker .input-group-addon {
	cursor: pointer
}

.dateclockpicker-moving {
	cursor: move
}

.popover.dateclockpicker-align-right {
	&.top,
	&.bottom {
		> .arrow {
			left: auto;
			right: 7px;
		}
	}
}

.dateclockpicker-align-middle.popover > .arrow {
	left: auto;
	right: 50%;
	margin-right: -7px
}

.dateclockpicker-days,
.dateclockpicker-months,
.dateclockpicker-years,
.dateclockpicker-calendar-view,
.dateclockpicker-clock-view,
.dateclockpicker-dial {
	-webkit-transition: -webkit-transform 350ms,opacity 350ms;
	-moz-transition: -moz-transform 350ms,opacity 350ms;
	-o-transition: -o-transform 350ms,opacity 350ms;
	transition: transform 350ms,opacity 350ms
}

.dateclockpicker-transition-out {
	opacity: 0
}

.dateclockpicker-transition-out.dateclockpicker-calendar-view,
.dateclockpicker-transition-out.dateclockpicker-days,
.dateclockpicker-transition-out.dateclockpicker-hours {
	-webkit-transform: scale(1.2,1.2);
	-moz-transform: scale(1.2,1.2);
	-o-transform: scale(1.2,1.2);
	transform: scale(1.2,1.2)
}

.dateclockpicker-transition-out.dateclockpicker-clock-view,
.dateclockpicker-transition-out.dateclockpicker-months,
.dateclockpicker-transition-out.dateclockpicker-years,
.dateclockpicker-transition-out.dateclockpicker-minutes {
	-webkit-transform: scale(0.8,0.8);
	-moz-transform: scale(0.8,0.8);
	-o-transform: scale(0.8,0.8);
	transform: scale(0.8,0.8)
}

.dateclockpicker-plate {
	border-radius: 50%;
	width: 198px;
	height: 198px;
	overflow: visible;
	position: relative;
	margin: 0 auto;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	user-select: none
}

.dateclockpicker-canvas,
.dateclockpicker-dial {
	width: 198px;
	height: 198px;
	position: absolute;
	left: -2px;
	top: -2px;
	touch-action: none;
}

.dateclockpicker-tick {
	border-radius: 50%;
	text-align: center;
	line-height: 28px;
	width: 28px;
	height: 28px;
	position: absolute;
	cursor: pointer;
	font-size: 14px
}

.dateclockpicker-hours .dateclockpicker-outertick {
	font-size: 11px
}

.dateclockpicker-canvas {
	-webkit-transition: opacity 175ms;
	-moz-transition: opacity 175ms;
	-o-transition: opacity 175ms;
	transition: opacity 175ms
}

.dateclockpicker-canvas line {
	stroke-width: 1
}

.dateclockpicker-canvas-out {
	opacity: 0.25
}

.dateclockpicker-canvas-bearing,
.dateclockpicker-canvas-fg {
	stroke: none
}

.dateclockpicker-canvas-hours-outertick {
	font-size: 11px
}

.dateclockpicker-canvas-bg {
	stroke: none
}

.dateclockpicker-calendar-view {
	overflow: hidden
}

.dateclockpicker-calendar-view,
.dateclockpicker-clock-view {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 8px
}

.dateclockpicker-days,
.dateclockpicker-months,
.dateclockpicker-years {
	position: absolute
}

.dateclockpicker-months,
.dateclockpicker-years {
	width: 100%;
	overflow: hidden
}

.dateclockpicker-months span,
.dateclockpicker-years span {
	display: block;
	float: left;
	cursor: pointer;
	border-radius: 4px;
	text-align: center;
	width: 48px;
	height: 40px;
	line-height: 40px;
	margin: 3px
}

.dateclockpicker-days {
	width: 216px;
	margin-left: 1px
}

.dateclockpicker-days .dateclockpicker-daysofweek,
.dateclockpicker-days .dateclockpicker-daysofmonth {
	width: 100%;
	overflow: hidden;
	margin-bottom: 0
}

.dateclockpicker-days .dateclockpicker-daysofweek span,
.dateclockpicker-days .dateclockpicker-daysofmonth span {
	float: left;
	width: 24px;
	margin: 4px;
	padding: 0;
	text-align: center
}

.dateclockpicker-days .dateclockpicker-daysofweek {
	margin-top: 8px;
	margin-bottom: 8px
}

.dateclockpicker-days .dateclockpicker-daysofweek span {
	text-transform: uppercase;
	margin: 0 4px;
	font-size: 11px;
	line-height: 11px
}

.dateclockpicker-days .dateclockpicker-daysofweek span:first-child {
	margin-left: 0
}

.dateclockpicker-days .dateclockpicker-daysofweek span:last-child {
	margin-right: 0
}

.dateclockpicker-days .dateclockpicker-daysofmonth span {
	height: 20px;
	cursor: pointer;
	border-radius: 4px;
	display: block;
	line-height: 20px
}

.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(7n+1) {
	margin-left: 0
}

.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(7n+0) {
	margin-right: 0
}

.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(1),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(2),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(3),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(4),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(5),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(6),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(7) {
	margin-top: 0
}

.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(36),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(37),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(38),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(39),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(40),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(41),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(42) {
	margin-bottom: 0
}

.dateclockpicker-months span:hover,
.dateclockpicker-years span:hover,
.dateclockpicker-calendar-switch:hover,
.dateclockpicker-calendar-prev:hover,
.dateclockpicker-calendar-next:hover {
	cursor: pointer
}

.dateclockpicker-oldday {
	color: #999
}

.dateclockpicker-calendar-header {
	text-align: center;
	line-height: 14px;
	margin-bottom: 4px
}

.dateclockpicker-calendar-switch {
	font-weight: bold;
	padding: 2px 10px
}

.dateclockpicker-calendar-prev {
	float: left
}

.dateclockpicker-calendar-next {
	float: right
}

.dateclockpicker-calendar-prev,
.dateclockpicker-calendar-next {
	padding: 0 5px;
	font-size: 16px
}

.dateclockpicker-footer {
	height: 44px;
	overflow: hidden;
	border-radius: 0 0 5px 5px
}

.dateclockpicker-footer button {
	height: 28px;
	border-radius: 2px;
	font-size: 14px;
	padding: 0 7px 1px;
	border-radius: 5px;
	line-height: normal
}

.dateclockpicker-displaymode-datetime .dateclockpicker-footer button:first-child {
	float: left
}

.dateclockpicker-displaymode-datetime .dateclockpicker-footer button:last-child {
	float: right
}

.dateclockpicker-displaymode-date .dateclockpicker-footer button:last-child,
.dateclockpicker-displaymode-time .dateclockpicker-footer button:last-child {
	display: none
}

.dateclockpicker-displaymode-date .dateclockpicker-footer > div,
.dateclockpicker-displaymode-time .dateclockpicker-footer > div {
	text-align: center
}

.dateclockpicker-displaymode-duration .input-group-addon {
	display: none
}

.dateclockpicker-popover {
	&.popover {
		color: @DateTimePicker_PopoverTextColor;
		border: 1px solid @DateTimePicker_PopoverBorderColor;

		.popover-title {
			color: @DateTimePicker_PopoverTextColor;
			font-weight: normal;
			padding: 0;
			display: block;
			height: 48px;
		}

		&.bottom > .arrow {
			border-bottom-color: @DateTimePicker_PopoverBorderColor;

			&:after {
				border-bottom-color: @DateTimePicker_PopoverHeaderBackgroundColor;
			}
		}

		&.top > .arrow {
			border-top-color: @DateTimePicker_PopoverBorderColor;

			&:after {
				border-top-color: @DateTimePicker_PopoverFooterBackgroundColor;
			}
		}

		.dateclockpicker-calendar-view,
		.dateclockpicker-clock-view {
			display: block;
		}

		.dateclockpicker-calendar-view {
			.dateclockpicker-span-months:before,
			.dateclockpicker-span-months:after {
				top: 3px;
			}
		}
	}

	.popover-title {
		background-color: @DateTimePicker_PopoverHeaderBackgroundColor;
		border-bottom: 1px solid @DateTimePicker_PopoverContentBorderColor;

		span:hover {
			color: @Theme_Hover;

			&:before,
			&:after {
				color: @DateTimePicker_PopoverTextColor;
			}
		}
	}

	.popover-content {
		background-color: @DateTimePicker_PopoverContentBackgroundColor;
	}

	.text-primary {
		color: @Theme_DefaultButton;
	}
}

.dateclockpicker .input-group-addon {
	height: @g-control-height;

	i {
		color: @Theme_Icons;
	}
}

.dateclockpicker-readonly .input-group-addon .icon-calendar {
	.readonly-icon;
	&.icon-calendar:hover {
		opacity: 1;
	}
}

.dateclockpicker-readonly .input-group-addon .icon-clock-o {
	.readonly-icon;
	&.icon-clock-o:hover {
		opacity: 1;
	}
}

.dateclockpicker-plate {
	background-color: @DateTimePicker_PopoverClockBackgroundColor;
}

.dateclockpicker-canvas line {
	stroke: @Theme_DefaultButton;
}

.dateclockpicker-canvas-bearing,
.dateclockpicker-canvas-fg,
.dateclockpicker-canvas-bg {
	fill: @Theme_DefaultButton;
}

.dateclockpicker-canvas-txt {
	fill: @DateTimePicker_PopoverClockBackgroundColor;
}

.dateclockpicker-tick {
	&.dateclockpicker-active {
		color: @Theme_DefaultButtonText;
		background: @Theme_DefaultButton;
	}

	&:hover {
		color: @Theme_DefaultButtonText;
		background: @Theme_Hover;
	}
}

.dateclockpicker-outertick {
	color: @Theme_DefaultButton;
}

.dateclockpicker-days .dateclockpicker-daysofmonth .dateclockpicker-active,
.dateclockpicker-days .dateclockpicker-daysofmonth .dateclockpicker-active:hover,
.dateclockpicker-months span.dateclockpicker-active,
.dateclockpicker-months span.dateclockpicker-active:hover,
.dateclockpicker-years span.dateclockpicker-active,
.dateclockpicker-years span.dateclockpicker-active:hover {
	color: @Theme_DefaultButtonText;
	background: @Theme_DefaultButton;
}

.dateclockpicker-days .dateclockpicker-daysofmonth span:hover,
.dateclockpicker-months span:hover,
.dateclockpicker-years span:hover,
.dateclockpicker-calendar-switch:hover,
.dateclockpicker-calendar-prev:hover,
.dateclockpicker-calendar-next:hover {
	color: @Theme_HoverText;
	background: @Theme_Hover;
	border-radius: 3px;
}

.dateclockpicker-footer {
	background: @DateTimePicker_PopoverFooterBackgroundColor;
	border-top: 1px solid @DateTimePicker_PopoverContentBorderColor;

	button {
		color: @DateTimePicker_PopoverTextColor;
		background: @white;
		border: 1px solid @DateTimePicker_PopoverFooterButtonBorderColor;

		&:hover {
			color: @Theme_HoverText;
			background: @Theme_Hover;
			border-color: @DateTimePicker_PopoverFooterButtonBorderColor;
		}
	}
}
