.gw-img-edit {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: center;
	color: @input-color;
	font-family: @default-font;
	font-weight: @InputControl_FontWeight;
	font-size: @font-size-base;

	&:not(.gw-viewonly) {
		background-color: #f2f2f2;
		border: 1px solid #d3d6d9;
		border-radius: 4px;
	}

	.gw-img-wrap {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;

		.gw-img {
			border-radius: 3px;
			max-width: 100%;
			max-height: 100%;
			top: 50%;
			left: 50%;
			.translate(-50%, -50%);
			position: absolute;
		}

		.gw-img-fallback {
			max-width: 50%;
			max-height: 50%;
		}
	}

	.gw-viewonly.gw-img-wrap {
		margin-right: 0px;
	}
}

.gw-img-edit.alert-danger {
	.alert-danger-mixins();
}
