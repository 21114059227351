.increase-icon-size(@originalSize, @amount){
	> i{
		font-size: @originalSize + @amount !important;
		line-height: @originalSize + @amount !important;
	}
}


.setup-health-bar-risk(@color, @lightenValue) {
	background-color: lighten(@color, @lightenValue) !important;

	.g-health-bar-capacity-fill {
		background-color: @color;
	}

	&.g-channel-health-bar {
		&:hover,
		&:focus,
		&:active,
		&.popover-shown {
			background: darken(lighten(@color, @lightenValue), 7%) !important;

			.g-health-bar-capacity-fill {
				background-color: darken(@color, 7%);
			}
		}
	}
}

.setup-acceptability-band(@color) {
	background-color: @color !important;

	&:hover,
	&:focus,
	&:active,
	&.popover-shown {
		background: darken(@color, 7%) !important;
	}
}

.setup-action-button(@color, @lightenValue, @darkenValue) {
	background-color: lighten(@color, @lightenValue);

	&:hover,
	&:focus {
		background-color: darken(@color, @darkenValue);
	}
}
