.tablePage {
	height: 100%;

	.modal-dialog.g-modal-autoresize .modal-content {
		width: 400px;
		height: 600px;

		.modal-body {
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}
	}

	.title-container {
		background-color: #f2f2f2;
		padding-left: 6px;
		padding-right: 4px;
		line-height: 30px;
		border-width: 1px 1px 0px 1px;
		border-color: @grey;
		border-style: solid;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.title {
		font-size: 12px;
		color: @Theme_GeneralText;
	}

	.g-technical-icon,
	.g-technical-icon-selected {
		width: 24px;
		height: 24px;
		line-height: 24px;
		float: right;
		border-radius: 4px;
		text-align: center;
		margin-top: 3px;
		display: inline-block;

		&:hover {
			opacity: 0.5;
			cursor: pointer;
		}
	}

	.g-technical-icon-selected {
		background: @Theme_DefaultButton;
	}

	.g-technical-name-title {
		display: block;
		font-size: 11px;
		line-height: 14px;
		font-style: italic;
		margin-bottom: 5px;
	}

	.g-technical-name-container {
		& > .panel-heading,
		& > .gwGroupBox > .panel-heading {
			height: 100% !important;
		}
	}

	.rootTable {
		&.g-collapsed {
			border-bottom: 1px solid @grey;
		}

		> .panel-heading,
		> .gwGroupBox > .panel-heading {
			border-left: none !important;
			border-bottom-left-radius: initial !important;
		}

		&.firstChild,
		&.firstChild > .gwGroupBox {
			border-top: 1px solid @grey;
			border-bottom: 1px solid @grey;
		}
	}

	.contents {
		padding-bottom: 4px;
	}

	.gwGroupBox {
		.collapsible-groupbox-header(28px, 26px, 14px);
		.collapsible-groupbox-icon(-5px, -7px, 315deg);
		overflow: hidden;
		border-bottom: none;
		border-right: none;
		border-left: none;

		& > .panel-heading {
			border-bottom: 1px solid @grey;
			border-left: 1px solid @grey;
			padding-left: 0px;

			.panel-title {
				padding: 0px;

				a {
					span {
						margin-left: 2px;
					}

					> i {
						margin-left: 2px;
					}

					&.collapsed > i {
						margin-left: 0px;
					}
				}
			}
		}
	}

	.g-table-group {
		&:last-child:not(.rootTable) > &.g-collapsed & > .panel-heading {
			border-bottom: initial !important;
		}

		& > .panel-heading,
		& > .gwGroupBox > .panel-heading {
			background-color: @Theme_Button_Darken15;
			border-bottom-left-radius: 4px;

			a {
				color: @Theme_ValueText;
			}

			a i {
				color: @Theme_ValueText;
			}

			> .panel-title > a > span > .table-name-header,
			> .panel-title > a > .table-name-header {
				float: left;
				margin-left: 12px !important;
			}
		}

		& > .panel-heading:hover,
		& > .gwGroupBox > .panel-heading:hover {
			background: @Theme_Hover;

			a {
				color: @Theme_HoverText;
			}

			a i {
				color: @Theme_HoverText;
			}
		}

		&.g-collapsed,
		&.g-collapsed > .gwGroupBox {
			.collapsible-groupbox-header(28px, 26px, 14px);
		}

		&.g-collapsed {
			& > .panel-heading,
			& > .gwGroupBox > .panel-heading {
				border-bottom: none;
				border-bottom-left-radius: 0 !important;
			}

			& > .panel-heading:hover,
			& > .gwGroupBox > .panel-heading:hover {
				background-color: @PrimaryButton_BackgroundHoverColorStart;

				a {
					color: @Theme_HoverText;
				}

				a i {
					color: @Theme_HoverText;
				}
			}
		}
	}

	.g-table-group-nochild {
		.collapsible-groupbox-header(28px, 26px, 14px);

		& > .panel-heading,
		& > .gwGroupBox > .panel-heading {
			border-bottom: none;
			background-color: #fafafa;

			.table-name-header {
				display: inline-block;
			}

			.g-technical-name-title {
				padding-left: 0px;
			}

			.g-table-title-nochildren {
				color: @Theme_ValueText;
			}

			&:hover {
				background: @Theme_Hover;

				.g-table-title-nochildren, .g-technical-name-title, i {
					color: @Theme_HoverText;
				}

				.g-select-table-icon {
					visibility: hidden;
					height: 0px;
					width: 0px;
				}

				.g-select-table-icon-hover {
					visibility: visible;
				}
			}
		}
	}

	.firstChild,
	.firstChild > .gwGroupBox {
		border-top: none;
	}

	.gwGroupBoxSelectedTable {
		& > .panel-heading,
		& > .gwGroupBox > .panel-heading {
			background-color: @Grid_ViewportSelectedRowColor;
		}
	}

	.g-mapping-table-container {
		border-width: 0 1px 1px 1px;
		border-color: @Panel_BorderColor;
		border-style: solid;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		background-color: @Accordion_BackgroundColor;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		/* Firefox */
		height: ~"-moz-calc(100% - 30px)";
		/* WebKit */
		height: ~"-webkit-calc(100% - 30px)";
		/* Opera */
		height: ~"-o-calc(100% - 30px)";
		/* Standard */
		height: ~"calc(100% - 30px)";
	}

	.g-table-group > .panel-collapse > .panel-body {
		position: relative;
		margin-top: 0;
	}

	.g-table-group-nochild {
		.panel-collapse > .panel-body {
			visibility: hidden !important;
		}

		> .panel-heading > .panel-title > a > i:before,
		> .gwGroupBox > .panel-heading > .panel-title > a > i:before {
			visibility: hidden !important;
		}
	}

	.g-shell-content {
		margin: 0 10px;
		font-size: 16px;
	}

	.g-select-table-icon {
		float: right;
		margin: 4px 0 0 0;
		color: @Icons_Color;
	}

	.g-select-table-icon-hover {
		float: right;
		margin: 4px 0 0 0;
		color: @Theme_HoverText;
		visibility: hidden;
	}

	.indentedGroupBox {
		margin-left: 20px;

		> .g-groupbox-wrapper:last-child > .gwGroupBox > .panel-heading,
		> .g-groupbox-wrapper:last-child > .gwGroupBox-container > .gwGroupBox > .panel-heading {
			border-bottom-left-radius: 4px !important;
			border-bottom: 1px solid @grey;
		}
	}
}
