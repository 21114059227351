.allocate-shopping-cart__inventory {
	display: flex;
	margin: 5px;
}

.allocate-shopping-cart__control {
	width: 200px;
}

.allocate-shopping-cart__sm-control {
	width: 100px;
}

.allocate-shopping-cart__input {
	width: 200px;
	margin-left: 5px;
}

.allocate-shopping-cart--warning {
	input {
		.alert-warning-mixins();
	}
}
