.gwTextBox {
	display: flex;
	flex-direction: column;
	height: @g-control-height;

	.character-casing-upper {
		text-transform: uppercase;
	}

	.character-casing-lower {
		text-transform: lowercase;
	}
}

.gwTextBox-textarea {
	height: 100%;
	padding-top: 18px;
	border-style: solid;
	border-width: 1px;
	border-radius: @InputControl_BorderRoundedCorners;

	input {
		display: none;
		order: 1;
	}

	textarea {
		height: 100%;
		resize: none;
		border: none;
		background: transparent;
		order: 1;

		&:focus {
			.box-shadow(none);
		}

		.character-casing-upper {
			text-transform: uppercase;
		}

		.character-casing-lower {
			text-transform: lowercase;
		}
	}

	label {
		margin-top: -18px;
	}
}

.gwTextBox-popover {
	border: 1px solid @InputControl_FocusedBorderBrushEnd;
	border-radius: @InputControl_BorderRoundedCorners;
	.box-shadow(0 4px 5px rgba(0, 0, 0, .15));

	.arrow,
	.popover-title {
		display: none;
	}

	.popover-content {
		padding: 5px 0px 5px 5px;
		height: 200px;

		textarea {
			height: 100%;
			width: 100%;
			resize: none;
			border: none;
			color: @InputControl_TextColor;
			.g-font-size-px(@InputControl_FontSize);
		}
	}
}

.gwTextBox-popover.g-uneditable {
	.popover-content {
		color: @InputControl_ReadOnlyTextColor;
		background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
		cursor: text;
	}
}
