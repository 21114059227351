.gwSearchBox {
	height: @g-control-height;
	border-radius: 4px;

	label:not(.disabled) {
		padding-right: 30px;
	}

	&.alert-danger input {
		.alert-danger-mixins();
	}

	&.alert-message-error input {
		.alert-message-error-mixins();
	}

	&.alert-warning input {
		.alert-warning-mixins();
	}

	&.alert-info input {
		.alert-info-mixins();
	}

	&.alert-danger,
	&.alert-message-error,
	&.alert-warning,
	&.alert-info {
		border: none;
		background: none;
	}

	.input-group-addon i:not(.icon-spin) {
		animation: none;
	}

	.input-group-addon i {
		color: @Theme_Icons;
	}

	&.gwValidation-highlight {
		&.alert-danger input[type="text"] {
			.alert-danger-highlight();
		}

		&.alert-message-error input[type="text"] {
			.alert-message-error-highlight();
		}

		&.alert-info input[type="text"] {
			.alert-info-highlight();
		}

		&.alert-warning input[type="text"] {
			.alert-warning-highlight();
		}
	}

	.input-group > .form-control[readonly] ~ .input-group-addon .icon-search {
		.readonly-icon;

		&.icon-search:hover {
			opacity: 1;
		}
	}
}

.gwSearchBox-count {
	.gwSearchBox-count-text {
		font-size: 11px;
		color: @disabled-text;
		line-height: 22px;
	}

	border-bottom: 1px solid @grey;
	padding: 0px 8px;
}

.gwSearchBox-results {
	height: 100%;
	max-height: 400px;
	margin-bottom: 0;
	list-style: none;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	border-top-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	font-size: @g-menu-font-size;
}

.gwSearchBox-show-more {
	> a {
		display: block;
		cursor: pointer;
		padding-bottom: 4px;
	}

	text-align: center;
}

.gwSearchBox-wrap {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&.gwSearchBox-CodeDescMode {
		.input-group .input-group-addon:last-child {
			.border-right-radius(0px);
		}

		.gwSearchBox-text-wrap {
			width: 45%;
		}

		.gwSearchBox-desc-wrap {
			width: 55%;
		}
	}

	> i {
		position: absolute;
		right: 2px;
		top: 2px;
		font-size: 14px;
		z-index: 5;
	}
}

.gwSearchBox-desc-wrap,
.gwSearchBox-text-wrap {
	height: 100%;
	vertical-align: top;
}

.gwSearchBox-desc-wrap input[type="text"] {
	width: 100%;
	height: 100%;
	vertical-align: bottom;
	border-left: none;
	.text-overflow();
	.border-left-radius(0px);
	.box-sizing(border-box);
}

.gwSearchBox-text-wrap {
	.input-group {
		height: 100%;
		white-space: normal;
	}

	input[type="text"] {
		z-index: 0;
		padding-top: 14px;
		.border-right-radius(0px);
	}
}

.gwSearchBox-description-text {
	display: inline-block;
	max-height: 15px;
	font-size: @InputControl_FontSize;
	line-height: 15px;
	color: @InputControl_ReadOnlyTextColor;
	white-space: nowrap;
	vertical-align: top;
}

.gwSearchBox-description-divider {
	display: inline-block;
	float: left;
	padding-top: 12px;
	margin-left: 8px;
	margin-right: 8px;
	margin-top: 8px;
	border-right: 1px solid @grey;
}

.gwSearchBox-description-wrap {
	padding-left: 8px;
	padding-right: 30px;
}

.gwSearchBox-description {
	cursor: text;
	position: relative;
	overflow: hidden;
	z-index: 3;
	float: left;
	padding-top: 22px;
	white-space: nowrap;
	max-width: 100%;
}

.gwSearchBox-description-spacer {
	float: left;
	font-size: 14px;
	min-width: 40px;
	min-height: 1px;
	white-space: nowrap;
	visibility: hidden;
}

.kgCell .gwSearchBox-description-spacer {
	display: list-item;
}

.typeahead.dropdown-menu {
	width: auto;
}

.g-is-using-min-width {
	border-top-right-radius: 5px;
}

.g-is-using-min-width.gwSearchBox-drop-above {
	border-bottom-right-radius: 5px;
}

input.g-read-restricted + div {
	.gwSearchBox-description-divider {
		display: none;
	}
}
