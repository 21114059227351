.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.btn:hover,
.btn:focus,
.btn.focus {
	color: #333;
	text-decoration: none;
}

.btn:active,
.btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	cursor: not-allowed;
	filter: alpha(opacity=65);
	opacity: 0.65;
	-webkit-box-shadow: none;
	box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
	pointer-events: none;
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}

.btn-default:focus,
.btn-default.focus {
	color: #333;
	background-color: #e6e6e6;
	border-color: #8c8c8c;
}

.btn-default:hover {
	color: #333;
	background-color: #e6e6e6;
	border-color: #adadad;
}

.btn-default:active,
.btn-default.active {
	color: #333;
	background-color: #e6e6e6;
	background-image: none;
	border-color: #adadad;
}

.btn-default:active:hover,
.btn-default.active:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.btn-default:active.focus,
.btn-default.active.focus {
	color: #333;
	background-color: #d4d4d4;
	border-color: #8c8c8c;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
	background-color: #fff;
	border-color: #ccc;
}

.btn-default .badge {
	color: #fff;
	background-color: #333;
}

.btn-primary {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;
}

.btn-primary:focus,
.btn-primary.focus {
	color: #fff;
	background-color: #286090;
	border-color: #122b40;
}

.btn-primary:hover {
	color: #fff;
	background-color: #286090;
	border-color: #204d74;
}

.btn-primary:active,
.btn-primary.active {
	color: #fff;
	background-color: #286090;
	background-image: none;
	border-color: #204d74;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.btn-primary:active.focus,
.btn-primary.active.focus {
	color: #fff;
	background-color: #204d74;
	border-color: #122b40;
}

.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
	background-color: #337ab7;
	border-color: #2e6da4;
}

.btn-primary .badge {
	color: #337ab7;
	background-color: #fff;
}

.btn-link {
	font-weight: 400;
	color: #337ab7;
	border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	border-color: transparent;
}

.btn-link:hover,
.btn-link:focus {
	color: #23527c;
	text-decoration: underline;
	background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
	color: #777777;
	text-decoration: none;
}

.btn-group {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.btn-group > .btn {
	position: relative;
	float: left;
}

.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
	z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
	margin-left: -1px;
}

.btn-group > .btn:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.btn-group > .btn:first-child {
	margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
	float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn .caret {
	margin-left: 0;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.btn-sm {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-tile {
	width: 100%;
}

.btn .label {
	position: relative;
	top: -1px;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.btn,
.btn-primary {
	text-shadow: none;
}

.btn:active,
.btn.active {
	.box-shadow(none);
}

.btn-group > .btn:first-child {
	margin: 0;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	cursor: default;
}

.btn,
.tox .tox-dialog__footer .tox-button {
	border: 1px solid transparent;
	font-weight: @g-button-text-font-weight;

	.g-loading-animation {
		color: #888;

		.icon-spinner {
			color: @Theme_Icons;
		}
	}

	&:focus {
		border: 1px dashed @Theme_GeneralText;
		outline: 0;
	}

	&:active,
	&:focus:active {
		outline: 0;
		.box-shadow(none);
	}

	.btn-text {
		.text-overflow();
	}
}

.btn.g-btn-async {
	text-shadow: none;
	.g-btn-async-loading-padding();
	display: inline-flex;
	justify-content: center;
	align-items: center;

	> i {
		margin-right: 5px;
	}

	&.g-btn-loading {
		color: #888888;

		> i {
			display: none;
		}
	}
}

.g-btn-async {
	.g-loading-animation-wrapper {
		display: none;
	}

	&.g-btn-loading .g-loading-animation-wrapper {
		display: flex;
	}

	&.g-btn-loading + .g-menu-item-icon-container {
		display: none;
	}

	&.g-header-button {
		&.g-btn-loading .g-display-option-button {
			display: none;
		}

		.g-loading-animation .icon-spinner {
			margin-right: 3px;
		}
	}
}

.g-footer-bar > .g-footer-content,
.modal-footer,
.g-document-footer,
.tox .tox-dialog__footer {
	background-color: @PopupFooter_BackgroundColorStart;
}

.btn,
.nav-pills > li > a {
	font-size: 14px;
}

.btn:not(.btn-link) {
	.g-button-background(@Button_BackgroundColorStart, @Button_BackgroundHoverColorStart, @Button_BackgroundPressedColorStart, @Button_TextColor, @Button_TextColor_Lighten10, @Button_TextHoverColor);
}

.btn.btn-primary,
.tox-button {
	font-size: 14px;
	.g-button-background(@PrimaryButton_BackgroundColorStart, @PrimaryButton_BackgroundHoverColorStart, @PrimaryButton_BackgroundPressedColorStart, @PrimaryButton_TextColor, @PrimaryButton_TextColor_Lighten10, @PrimaryButton_TextHoverColor);
}

button[disabled],
.btn[disabled],
.gwOptionButtonGrid input[type=radio][disabled] + label {
	color: @disabled-text;
	background-color: @Button_BackgroundDisabledColorStart;
	border-color: transparent;
	cursor: default;
}
