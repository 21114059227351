.power-bi-shell {
	height: 100%;
	padding: 12px 20px 20px;
	position: relative;

	.power-bi-content {
		width: 100%;
		height: 100%;
		flex: 0 1 auto;

		.power-bi-report-link {
			margin: 5px;
		}

		.power-bi-report-item {
			display: flex;
			margin-bottom: 15px;
		}
	}
}
