/* SHARED */
.g-header {
	display: flex;
	width: 100%;
	height: @navbar-height;
	flex: 1 0 @navbar-height;
	position: relative;
	z-index: 12;

	&:empty {
		background-color: @Theme_DefaultButton;
	}

	.g-header-contents {
		position: fixed;
		display: flex;
		align-items: center;
		width: 100%;
		z-index: 112;
		background-color: @Theme_DefaultButton;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		height: @navbar-height;
	}

	.header-link() {
		height: 100%;
		padding: 0 @g-header-button-padding;
		display: flex;
		align-items: center;
		white-space: nowrap;
		border-radius: 0;
	}

	.g-header-title {
		display: flex;
		align-items: center;
		height: 100%;
		color: @Header_TaglineColor;
		font-size: @g-title-area-font-size;
		padding: 0;
		min-width: 0;
		flex: 1 0 0%;
		overflow: hidden;

		.g-logo {
			height: 100%;
			display: flex;
			align-items: center;
			flex: 0 0 auto;
			padding: 0 6px;

			+ .g-tagline {
				margin-left: 2px;
			}
		}

		.g-tagline {
			margin: 0 0 0 6px;
			.text-ellipsis();
			max-height: @navbar-height - 4px;

			.g-tagline-text-container {
				display: flex;
			}
		}

		.g-title-name {
			max-height: @navbar-height - 6px;
			.text-ellipsis( );
		}
	}

	.g-header-menu {
		list-style-type: none;
		display: flex;
		align-items: center;
		height: 100%;
		margin-left: auto;

		.nav {
			height: 100%;
			flex: 0 0 auto;

			&:first-child {
				margin-left: auto;
			}

			&.gwPinnedItems {
				display: flex;
				flex: 1 0 auto;
				justify-content: flex-end;
				align-items: center;
			}
		}

		.nav > li {
			height: 100%;
			display: flex;
			flex: 0 0 auto;
			align-items: center;

			> a {
				.header-link;

				> i {
					font-size: @g-nav-pills-font-size;
					line-height: @g-modal-header-navpill-line-height;
					margin: @g-nav-pills-margin;
					text-align: center;
					float: left;
				}

				.g-display-option-text,
				.g-not-loggedin-display {
					line-height: @g-modal-header-navpill-line-height;
					font-size: 12px;
					float: left;
					margin: @g-nav-pills-margin;
					font-weight: @g-button-text-font-weight;
				}
			}

			> .dropdown-menu {
				margin: @g-nav-dropdown-menu-margin;
			}

			.g-divider {
				width: 10px;
			}

			&.more-dropdown {
				> .dropdown-menu {
					&:before {
						border-bottom-color: #333;
					}

					&:after {
						border-bottom-color: #f2f2f2;
					}
				}
			}

			&.g-overflow-button > a {
				font-size: 22px;
				line-height: 25px;
			}
		}

		.nav > .dropdown > .dropdown-menu {
			right: 0;
			left: auto;
			margin-right: @g-nav-dropdown-menu-margin-right;
		}

		.nav-pills > li + li,
		.nav-pills + .nav-pills {
			margin-left: 0;
		}
	}

	&.g-header-light {
		background-color: @PopupHeader_BackgroundColorStart;
		box-shadow: none;
		border-bottom: 1px solid @Grid_HeaderBorderColor;

		.g-header-title {
			color: @Theme_ValueText;
			flex: 0 1 auto;
			font-weight: 500;
			margin: 0 0 0 8px;
		}

		.g-header-menu {
			flex-shrink: 0;

			.nav {
				padding-left: 0px;
			}

			.nav > li > a {
				background-color: transparent;
				color: @Theme_Icons;
				.opacity-hover;
				font-weight: 500;

				> i {
					color: @Theme_Icons;
				}
			}
		}
	}

	.g-homepage {
		visibility: @Header_HomeIcon;
	}
}

header .g-header {
	.g-header-title {
		flex: 0 1 auto;
	}

	.g-header-menu {
		flex: 1 0 auto; //needed due to how jump menu in the header is implemented.
		margin-left: 0;
	}
}

.nav.buttons-only .g-display-option-text,
.nav.text-only .g-display-option-button:not(.g-header-item-persistent-icon) {
	display: none;
}

.nav.buttons-only i {
	min-width: @g-nav-pills-icon-min-width;
}

.nav.buttons-only > li > a > span.alert-count.error-count {
	display: block !important;
}

.g-header-popover {
	.g-loggedin-info-section {
		background-color: @Theme_Background;
		height: 58px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.loggedin-info,
		.loggedin-info:hover {
			font-size: 11px;
			color: @Theme_FieldTitle;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			text-overflow: @loggedin-info-text-overflow;
			overflow-y: hidden;
			cursor: default;

			.loggedin-user {
				font-size: 12px;
			}
		}

		.loggedin-img-section {
			margin: 0 10px 0 10px;
			cursor: default;
			z-index: 9001;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow-y: hidden;
			align-items: center;
			justify-content: center;
			display: flex;

			.loggedin-img {
				max-width: 40px;
			}
		}
	}

	h3 {
		margin: 0 0 10px;
		line-height: 30px;
	}
}

.g-saving-indicator-container {
	.g-header-button {
		cursor: default;

		&:hover {
			box-shadow: none;
		}
	}
}

.g-index-count {
	color: @Header_TextColor;
	font-size: 12px;
	padding: 0 2px;
}

.g-logo-img {
	font-size: @g-logo-img-size;
}
