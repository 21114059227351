.g-acceptability-band,
.g-acceptability-bands-overflow-button {
	border-radius: @rounded-corners;
	.user-select(none);
	cursor: pointer;
	.element-elevation(1);
	.transition(0.1s, ease-in-out);
	padding: 5px 10px;

	&:focus,
	&:active,
	&.popover-shown {
		.element-elevation(0);
	}

	&.g-pave-health-risk-excellent {
		.setup-acceptability-band(@risk-state-excelent-color);
	}

	&.g-pave-health-risk-good {
		.setup-acceptability-band(@risk-state-good-color);
	}

	&.g-pave-health-risk-caution {
		.setup-acceptability-band(@risk-state-caution-color);
	}

	&.g-pave-health-risk-bad {
		.setup-acceptability-band(@risk-state-bad-color);
	}

	&.g-pave-health-risk-alert {
		.setup-acceptability-band(@risk-state-alert-color);
	}

	&.g-pave-health-risk-none {
		.setup-acceptability-band(@risk-state-none-color);
	}

}

.g-acceptability-band {
	white-space: nowrap;
	font-weight: 500;

	& + & {
		margin-left: 8px;
	}
}

.g-acceptability-bands-overflow-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	margin-left: 8px;

	i {
		margin-top: -3px;
		font-size: 18px;

		&:before {
			content: '•••';
		}
	}
}

.g-acceptability-bands-in-overflow-popover {
	.g-dynamic-menu-list {
		display: flex;
		flex-direction: column;
		min-width: 0;
	}

	.g-acceptability-band {
		& + .g-acceptability-band {
			margin-left: 0px;
			margin-top: 8px;
		}
	}
}

#g-acceptability-band-popup {
	width: 100%;
	min-width: 550px;

	.g-acceptability-band-boundaries-container {
		margin-top: 10px;

		.g-acceptability-band-boundaries-blocks {
			display: flex;

			.g-acceptability-band-boundary {
				display: flex;
				flex-direction: column;
				flex: 0 1 100%;
				align-items: center;

				&:first-child {
					.g-acceptability-band-boundary-block {
						border-top-left-radius: @rounded-corners;
						border-bottom-left-radius: @rounded-corners;
					}
				}

				&:last-child {
					.g-acceptability-band-boundary-block {
						border-top-right-radius: @rounded-corners;
						border-bottom-right-radius: @rounded-corners;
					}
				}

				&.has-value {
					.g-acceptability-band-boundary-block {
						border-color: rgba(0,0,0,0.2);
					}
				}

				.g-acceptability-band-boundary-block {
					width: 100%;
					height: 40px;
					margin-top: 2px;
					margin-bottom: 2px;
					border-width: 1.5px;
					border-style: solid;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 600;
					color: rgba(0,0,0,0.5);
				}
			}
		}

		.g-acceptability-band-boundaries-values {
			display: flex;
			justify-content: space-between;
			margin-left: -25px;
			margin-right: -25px;

			&-text {
				width: 50px;
				text-align: center;
			}
		}
	}

	.g-acceptability-band-popup-actions {
		margin-top: 15px;
		display: flex;
		justify-content: flex-end;
	}
}
