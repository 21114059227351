.modal-body {
	padding: 17px;
	position:static
}

.modal-footer {
	.g-footer();
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
	cursor: default;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
	cursor: default;
}

.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
	cursor: default;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
	margin-left: -5px;
}

.list-inline > li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
}

.fade {
	opacity: 0;
	transition: opacity 0.15s linear;
}

.fade.in {
	opacity: 1;
}

.collapse {
	display: none;
}

.collapse.in {
	display: block;
}

tr.collapse.in {
	display: table-row;
}

tbody.collapse.in {
	display: table-row-group;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition-property: height, visibility;
	transition-duration: 0.35s;
	transition-timing-function: ease;
}

.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #777777;
	white-space: nowrap;
}

.hide {
	display: none !important;
}

.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.invisible {
	visibility: hidden;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

.small {
	font-size: 85%;
}

:focus {
	outline: none;
}

.label {
	display: inline;
	padding: 0.2em 0.6em 0.3em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25em;

	&:empty {
		display: none;
	}
}

a.label:hover,
a.label:focus {
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}
