/* Problem with backticks - https://github.com/googlefonts/noto-fonts/issues/736 */
@font-face {
	font-family: 'Noto Sans';
	src: local("Arial"), local("Sans-Serif");
	unicode-range: U+60;
}

@font-face {
	font-family: 'Noto Sans KR';
	src: local("Arial"), local("Sans-Serif");
	unicode-range: U+60;
}

@font-face {
	font-family: 'Noto Sans Condensed';
	src: local("Arial"), local("Sans-Serif");
	unicode-range: U+60;
}
/* End Problem with backticks */

@font-face {
	font-family: 'Noto Sans Condensed';
	src: url('@{ContentBaseUri}/Shared/fonts/NotoSans-CondensedBold.6H3DGo7wIlucwm3bXdkkv2aajtw.woff2') format('woff2'), url('@{ContentBaseUri}/Shared/fonts/NotoSans-CondensedBold.3_UdNFFtZ4xmC6RLzey1gUrs6os.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Noto Sans Condensed';
	src: url('@{ContentBaseUri}/Shared/fonts/NotoSans-Condensed.9CtZHjwxsd_6HpaFHuPbCG8QNWM.woff2') format('woff2'), url('@{ContentBaseUri}/Shared/fonts/NotoSans-Condensed.Nhe-theHinQL11jHLrYqdcZfzsU.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Noto Sans Condensed';
	src: url('@{ContentBaseUri}/Shared/fonts/NotoSans-CondensedItalic.A6VYQG5ztx23WbqS_4WE0LjSYYI.woff2') format('woff2'), url('@{ContentBaseUri}/Shared/fonts/NotoSans-CondensedItalic.CrCl1jQ1OE_2O4BFi_C0vKStKgw.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Noto Sans Condensed';
	src: url('@{ContentBaseUri}/Shared/fonts/NotoSans-CondensedBoldItalic.tlE9kpZAIyqDokXUUB5POC5cPlk.woff2') format('woff2'), url('@{ContentBaseUri}/Shared/fonts/NotoSans-CondensedBoldItalic.nKFl5tMSSJfIlHwvo8gQuZT3M_A.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

body {
	font-family: @default-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
}

[data-font-weight="SemiBold"] {
	font-weight: bold;
}

[data-font-weight="Bold"] {
	font-weight: bold;
}

[data-textwrapping="NoWrap"] {
	white-space: nowrap;
}

[data-textwrapping="Wrap"] {
	white-space: normal;
}

[data-textwrapping="WrapWithOverflow"] {
	white-space: normal;
}
