.gwValidationSummary-alert-entity-message.alert-warning {
	&:hover .g-tickbox {
		label {
			border-color: #575a5d;
			width: @g-alert-tickbox-size;
			height: @g-alert-tickbox-size;

			span {
				background: @state-warning-hover;
				left: @g-alert-tickbox-corner-open-left;
			}

			&:after {
				border: 1px solid #fdca30;
				border-top: none;
				border-right: none;
				.opacity(1);
			}
		}
	}
}
