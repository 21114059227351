.select2 {
	width: 100%;
	height: 100%;

	.select2-selection {
		height: 100%;
		border-color: @grey;
		background-color: @InputControl_FocusedBackgroundColorEnd;

		.select2-selection__rendered {
			padding: 16px 26px 0 7px;
			line-height: 24px;
			color: @Theme_ValueText;
			-webkit-user-select: text;
			user-select: text;
		}

		.select2-selection__arrow {
			top: 0;
			right: 0;
			height: 100%;
			color: @Theme_Icons;

			.select2-selection__icon {
				position: static;
				margin: 0 4px 0 0;
				border: none;
			}

			.icon-caret-down {
				width: 8px;
				height: 15px;
			}

			.icon-spinner {
				width: 14px;
				height: 14px;
			}
		}
	}

	&.select2-container--disabled {
		.select2-selection {
			background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
		}

		.select2-selection__rendered {
			color: @InputControl_ReadOnlyTextColor;
		}

		.select2-selection__arrow {
			display: none;
		}
	}

	&.alert-danger {
		background-color: transparent;

		.select2-selection {
			background-color: @state-error-pale;
			.alert-danger-mixins();
		}
	}

	&.alert-message-error {
		background-color: transparent;

		.select2-selection {
			background-color: @state-message-error-pale;
			.alert-message-error-mixins();
		}
	}

	&.alert-warning {
		background-color: transparent;

		.select2-selection {
			background-color: @state-warning-pale;
			.alert-warning-mixins();
		}
	}

	&.alert-info {
		background-color: transparent;

		.select2-selection {
			background-color: @state-info-pale;
			.alert-info-mixins();
		}
	}

	&.gwValidation-highlight {
		&.alert-danger .select2-selection {
			.alert-danger-highlight();
		}

		&.alert-message-error .select2-selection {
			.alert-message-error-highlight();
		}

		&.alert-info .select2-selection {
			.alert-info-highlight();
		}

		&.alert-warning .select2-selection {
			.alert-danger-highlight();
		}
	}
}

.select2-container--open {
	.select2-dropdown--below {
		border-top: 1px solid @InputControl_FocusedBorderBrushEnd;
	}

	.select2-dropdown--above {
		border-bottom: 1px solid @InputControl_FocusedBorderBrushEnd;
	}

	&.select2-container--below .select2-selection {
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}

	&.select2-container--above .select2-selection {
		border-top-right-radius: 0 !important;
		border-top-left-radius: 0 !important;
	}
}

.select2-selection__arrow {
	display: flex;
	justify-content: center;
	align-items: center;
}

.select2-selection__icon:hover {
	opacity: 0.5;
}

.select2-dropdown {
	overflow-y: hidden;
	border: 1px solid @InputControl_FocusedBorderBrushEnd;
	background: @InputControl_FocusedBackgroundColorEnd;

	&--above {
		margin-top: 1px;
		.box-shadow(0 -4px 5px rgba(0, 0, 0, .15));

		&.select2-dropdown--larger-than-container {
			border-bottom-right-radius: 4px;
		}
	}

	&--below {
		margin-top: -1px;
		.box-shadow(0 4px 5px rgba(0, 0, 0, .15));

		&.select2-dropdown--larger-than-container {
			border-top-right-radius: 4px;
		}
	}
}

.select2-search {
	height: 35px;
	border-bottom: 1px solid @grey;

	.input-group-addon {
		display: flex;
		height: 34px;
		align-items: center;
		justify-content: center;
		color: @Theme_Icons
	}
}

.select2-search__field {
	height: 100%;
	font-size: 13px;
	background-color: @InputControl_FocusedBackgroundColorEnd;
	border-radius: 4px;
}

.select2-results {
	font-size: @g-menu-font-size;
	-webkit-overflow-scrolling: touch;
}

.select2-results__message {
	color: @Theme_GeneralText;
	background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
}

.select2-results__option {
	padding: 0;

	&.select2-results__message {
		padding: 0 5px;
	}
}

.gwSelectBox {
	height: 100%;

	label:not(.disabled) {
		padding-right: 18px;
		pointer-events: none;
	}
}

.gwSelectBox-code-only .g-code-desc-container {
	.g-code {
		width: 100%;
		background-color: @InputControl_FocusedBackgroundColorEnd;
	}

	.g-desc {
		display: none;
	}
}

.gwSelectBox-desc-only .g-code-desc-container {
	.g-code {
		display: none;
	}

	.g-desc {
		width: 100%;
		background-color: @InputControl_FocusedBackgroundColorEnd;
	}
}
