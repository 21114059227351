.gwSearchList {
	height: 100%;
	min-width: @g-search-list-min-width;
	.grid-action-button();
	display: flex;
	flex-direction: column;

	.g-kg-status-search {
		font-size: 12px;
		text-align: center;
		color: #575a5d;
	}

	.gwSearchList-wrap {
		position: relative;
		height: 100%;
	}

	.g-search-list-filter {
		max-height: @g-search-list-filter-max-height;
		height: auto;
		min-height: @g-search-list-filter-min-height;
		flex: 0 1 auto;
	}

	.g-search-list-mask {
		position: absolute;
		background: @g-search-list-spinner-background-color;
		width: 100%;
		height: 100%;
		z-index: 100;
		.opacity(.5);
	}

	.g-search-results {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1 0 @g-search-results-koGrid-min-height + @g-control-height;
		min-height: 0;

		ul {
			list-style: none;
		}

		.notifications-wrapper {
			position: absolute;
			display: flex;
			justify-content: center;
			margin-top: @g-control-height + 25px;
			width: 100%;
			z-index: 100;

			.searching {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: 20px;
			}

			.icon-spinner {
				font-size: 30px;
				border-radius: 50%;
				line-height: initial;
				margin: 0;
			}

			.gwLabel-wrapper {
				height: auto;
				margin-top: 18px;
			}

			.gwLabel {
				padding: 6px;
				border-radius: @border-radius-large;
			}
		}

		.notifications-wrapper.g-grouped {
			margin-top: @g-control-height + 50px;
		}

		.dropdown-menu > li > a:hover {
			background-color: @dropdown-link-hover-bg;
		}

		.g-search-noresult {
			position: absolute;
			top: 40px;
			right: 0;
			left: 0;
			text-align: center;
		}

		> ul {
			margin-left: 0;

			> li img {
				float: left;
				color: @Icons_Color;
			}
		}

		.kgCanvas .kgRow .kgCell .gwSearchListAddresses .kgCellText {
			div {
				overflow: hidden;
				text-overflow: ellipsis;

				&.g-multi-address {
					padding-right: 33px;
				}
			}

			span {
				padding: 0;
				line-height: 20px;

				&.badge {
					position: absolute;
					top: 4px;
					right: 8px;
					padding: 3px 7px;
					line-height: 1;
				}
			}
		}
	}

	.g-search-results-items-container {
		@search-results-height: @g-control-height + 3;
		height: calc(~'100% - '@search-results-height);
		overflow: hidden;
	}

	.g-search-results-grid {
		display: flex;
		flex: 1 0 100%;
		flex-direction: column;
		max-width: 100%;
		height: 100%;

		.koGrid {
			flex: 1 0 auto;
			height: @g-search-results-koGrid-min-height;
		}

		.g-kg-top-container {
			flex: 0 0 @g-control-height;
		}

		.row-template-container,
		.row-template-container > div {
			height: 100%;
		}
	}

	.g-search-results-grid-error {
		z-index: 1005;
		display: none;
		margin: 0;
		font-size: 16px;
		text-align: center;
		.g-stretch-to-parent-container-absolute();

		> div {
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
	}

	.g-search-results-grid-error-visible {
		display: inline-block;
	}

	.g-search-results-footer {
		position: absolute;
		bottom: 0;
		width: 100%;
		font-weight: @Grid_FooterFontWeight;
		.g-font-size-px(@Grid_FooterFontSize);
	}

	.g-search-results-header-contextmenu {
		position: absolute;
		z-index: 100;
		display: none;

		.dropdown-menu {
			position: static;
			display: block;

			a {
				width: 100%;
			}
		}
	}

	.g-grid-actions {
		width: 100%;

		.gwDynamicMenu ul {
			background-color: @Grid_HeaderBackgroundColorEnd;
		}
	}

	.g-quickSearch-container {
		display: flex;
		flex: 0 1 auto;
		width: 200px;
		flex-flow: row nowrap;
		align-items: center;
		margin: 4px 0 0 7px;

		.g-quickSearch-input-group {
			position: relative;

			> Label {
				padding: 2px 8px 0;
				display: block;
			}

			.g-quickSearch-text {
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding-top: 12px;
				padding-bottom: 2px;
			}
		}

		.g-quickSearch-button {
			margin-left: 5px;
			flex: 0 0 15px;
		}
	}
}

.g-kg-title-container--fill {
	flex-grow: 1;
}

.gwSearchList__refresh-container {
	.opacity-hover();
	border: none;
	flex-grow: 1;
	margin: @g-kg-item-count-container-margin;
	min-width: 0; //By default the min-width of a flex child is set to auto, so when the content grows beyond the available width and it can’t wrap, it is still not constrained until you set an explicit min-width. See https://medium.com/@gaurav5430/css-flex-positioning-gotchas-child-expands-to-more-than-the-width-allowed-by-the-parent-799c37428dd6
	white-space: nowrap;
	padding-left: 5px;
}

.gwSearchList__refresh-label {
	flex-grow: 1;
	.g-font-size-px(@Grid_ItemCountFontSize);
	white-space: nowrap;
	.alert-warning-mixins();
}

.modal-body {
	.gwSearchList {
		.g-search-list-mask {
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: @modal-border-radius
		}
	}
}

.kgCell__drag-handle {
	cursor: move;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.g-move-record {
	background: @drag-records-bg;
	padding-top: 11px;
	color: @Theme_DefaultButtonText;
	border-radius: 5px;
	width: 130px;
	height: 40px;
	text-align: center;
	cursor: move;
	pointer-events: all;
}

.koGrid--drag-over {
	border-color: @Theme_DefaultButton;
	position: relative;

	.koGridContainer:after {
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: @Theme_DefaultButton;
		opacity: 0.1;
	}
}

.kgRow--drag-over {
	&:before {
		content: '';
		height: 100%;
		width: calc(~'100% - 1px');
		position: absolute;
		top: 0;
		background-color: @Theme_DefaultButton;
		opacity: 0.1;
	}

	&:after {
		content: '';
		height: 100%;
		width: calc(~'100% - 1px');
		position: absolute;
		top: 0;
		border: 1px solid @Theme_DefaultButton;
	}
}
