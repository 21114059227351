/* COMMON STYLES */
/* Shared */
/* Was previously in Default Theme */
/* buttons */
/* drag and drop */
/* hover */
/* gwTile */
/* gwDataGrid */
/* Search Filter */
/* Global Search*/
/* Search list */
/* grid actions & auto-refresh - kogrid */
/* modal dialog */
/* popovers */
/* header */
/* Menu */
/* gwFavorites */
/* anchor*/
/* form-flow bar */
/* footer bar */
/* sidebar */
/* sidebar alerts */
/* message bar */
/* title bar */
/* page overlay */
/* page */
/* customized columns */
/* 4xx */
/* popups */
/* help popup */
/* documents popup */
/*group box */
/* jobdocaddress nav pills */
/* JobDocAddress */
/* Tile */
/* nav-pills */
/* grid notifications */
/* documents */
/* dropdown variables */
/* checkbox */
/* Misc */
/*validation colours*/
/* gwConversationList */
.g-actions-buttons-container {
  padding: 0 0 0 7px;
}
.panel-title-details {
  padding-left: 0;
  font-size: 15px;
  vertical-align: middle;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.panel-heading-details {
  display: block;
  padding: 0;
  color: var(--group-box-header-text-color);
  background-image: none;
  border-radius: 0;
}
.readonly-icon {
  color: var(--content-text-color);
  cursor: default;
  opacity: 0.5;
}
.dropdown-menu-list-line {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  clear: both;
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  color: var(--theme-general-text);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  position: relative;
}
.dropdown-menu-list-line.g-pin-target {
  padding-right: 0;
}
.dropdown-menu-header {
  font-size: 13px;
  color: var(--theme-default-button);
  padding: 2px 8px;
  height: auto;
  line-height: 28px;
  border-top: 1px solid #aaaaaa;
  cursor: default;
  margin-bottom: 0px;
}
.dropdown-menu-section-hover {
  color: var(--theme-hover-text);
  background-color: var(--theme-hover);
}
.dropdown-menu-section-hover label,
.dropdown-menu-section-hover span,
.dropdown-menu-section-hover a,
.dropdown-menu-section-hover i.chevron {
  color: var(--theme-hover-text);
}
.dropdown-menu-section-open {
  background-color: var(--grid-viewport-selected-row-color);
  border-color: var(--grid-viewport-selected-row-color);
}
.modal-body {
  padding: 17px;
  position: static;
}
.modal-footer {
  z-index: 10;
  border-top: 1px solid var(--input-control-border-brush-start);
}
.modal-footer .btn {
  height: 30px;
  min-width: 100px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
}
.modal-footer .btn.g-btn-async {
  padding-left: 20px;
  padding-right: 20px;
}
.modal-footer .btn.g-btn-async[data-icon] {
  padding-left: 11px;
  padding-right: 11px;
}
.modal-footer .btn.g-btn-async.g-btn-loading,
.modal-footer .btn.g-btn-async.g-btn-loading[data-icon] {
  padding-left: 9.5px;
  padding-right: 9.762px;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  cursor: default;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  cursor: default;
}
.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
  cursor: default;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.35s;
  transition-timing-function: ease;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777777;
  white-space: nowrap;
}
.hide {
  display: none !important;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.invisible {
  visibility: hidden;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.small {
  font-size: 85%;
}
:focus {
  outline: none;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label:empty {
  display: none;
}
a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.koGrid {
  width: 100%;
  height: 100%;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid var(--grid-viewport-border-color);
  background-color: var(--grid-viewport-alternating-row-color);
  overflow: hidden;
}
.koGrid[readonly="readonly"] .kgViewport {
  background-color: var(--grid-readonly-viewport-alternating-color);
}
.koGrid[readonly="readonly"] .kgRow--odd {
  background-color: var(--grid-readonly-viewport-alternating-color);
}
.koGrid[readonly="readonly"] .kgRow--even {
  background-color: var(--grid-readonly-viewport-background-color);
}
.koGrid:not([readonly="readonly"]) .kgRow:not(.kgRow--hovered):not(.kgRow--selected):not(.colorScheme--applied):not(.kgRow--drag-over-top):not(.kgRow--drag-over-bottom) .g-uneditable {
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.koGrid.gw-drag-over {
  opacity: 0.5;
}
.kgRow:not(.kgRow--hovered):not(.kgRow--selected).colorScheme--applied .g-uneditable {
  color: inherit;
}
.kgViewport {
  font-weight: Normal;
  color: var(--grid-viewport-text-color);
  font-size: 12px;
}
.kgViewport .g-grid-action-button {
  padding: 6px 6px 5px;
  color: var(--grid-viewport-text-color);
}
.kgRow {
  border-color: var(--grid-viewport-border-color);
}
.kgRow--odd {
  background-color: var(--grid-viewport-alternating-row-color);
}
.kgRow--even {
  background-color: var(--grid-viewport-row-color);
}
.kgRow.add-row .g-grid-action-button {
  padding: 4px 6px 6px;
}
.kgCell.g-uneditable {
  border-bottom-color: var(--grid-viewport-border-color);
}
.kgCellInner.alert-danger,
.kgCellText.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.kgCellInner.alert-danger.gwValidation-highlight,
.kgCellText.alert-danger.gwValidation-highlight,
.kgCellInner.alert-danger input[type="tel"].gwValidation-highlight.alert-danger,
.kgCellText.alert-danger input[type="tel"].gwValidation-highlight.alert-danger,
.kgCellInner.alert-danger input[type="text"].gwValidation-highlight.alert-danger,
.kgCellText.alert-danger input[type="text"].gwValidation-highlight.alert-danger,
.kgCellInner.alert-danger .select2-container.gwValidation-highlight.alert-danger .select2-selection,
.kgCellText.alert-danger .select2-container.gwValidation-highlight.alert-danger .select2-selection,
.kgCellInner.alert-danger .gwSearchBox.gwValidation-highlight.alert-danger input[type="text"],
.kgCellText.alert-danger .gwSearchBox.gwValidation-highlight.alert-danger input[type="text"] {
  background: #ffb0b0;
}
.kgCellInner.alert-message-error,
.kgCellText.alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.kgCellInner.alert-message-error.gwValidation-highlight,
.kgCellText.alert-message-error.gwValidation-highlight,
.kgCellInner.alert-message-error input[type="tel"].gwValidation-highlight.alert-message-error,
.kgCellText.alert-message-error input[type="tel"].gwValidation-highlight.alert-message-error,
.kgCellInner.alert-message-error input[type="text"].gwValidation-highlight.alert-message-error,
.kgCellText.alert-message-error input[type="text"].gwValidation-highlight.alert-message-error,
.kgCellInner.alert-message-error .select2-container.gwValidation-highlight.alert-message-error .select2-selection,
.kgCellText.alert-message-error .select2-container.gwValidation-highlight.alert-message-error .select2-selection,
.kgCellInner.alert-message-error .gwSearchBox.gwValidation-highlight.alert-message-error input[type="text"],
.kgCellText.alert-message-error .gwSearchBox.gwValidation-highlight.alert-message-error input[type="text"] {
  color: #ffc675;
}
.kgCellInner.alert-warning,
.kgCellText.alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.kgCellInner.alert-warning.gwValidation-highlight,
.kgCellText.alert-warning.gwValidation-highlight,
.kgCellInner.alert-warning input[type="tel"].gwValidation-highlight.alert-warning,
.kgCellText.alert-warning input[type="tel"].gwValidation-highlight.alert-warning,
.kgCellInner.alert-warning input[type="text"].gwValidation-highlight.alert-warning,
.kgCellText.alert-warning input[type="text"].gwValidation-highlight.alert-warning,
.kgCellInner.alert-warning .select2-container.gwValidation-highlight.alert-warning .select2-selection,
.kgCellText.alert-warning .select2-container.gwValidation-highlight.alert-warning .select2-selection,
.kgCellInner.alert-warning .gwSearchBox.gwValidation-highlight.alert-warning input[type="text"],
.kgCellText.alert-warning .gwSearchBox.gwValidation-highlight.alert-warning input[type="text"] {
  background: #fef293;
}
.kgCellInner.alert-info,
.kgCellText.alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.kgCellInner.alert-info.gwValidation-highlight,
.kgCellText.alert-info.gwValidation-highlight,
.kgCellInner.alert-info input[type="tel"].gwValidation-highlight.alert-info,
.kgCellText.alert-info input[type="tel"].gwValidation-highlight.alert-info,
.kgCellInner.alert-info input[type="text"].gwValidation-highlight.alert-info,
.kgCellText.alert-info input[type="text"].gwValidation-highlight.alert-info,
.kgCellInner.alert-info .select2-container.gwValidation-highlight.alert-info .select2-selection,
.kgCellText.alert-info .select2-container.gwValidation-highlight.alert-info .select2-selection,
.kgCellInner.alert-info .gwSearchBox.gwValidation-highlight.alert-info input[type="text"],
.kgCellText.alert-info .gwSearchBox.gwValidation-highlight.alert-info input[type="text"] {
  background: #b5e6fd;
}
.kgRow:not(.kgRow--selected) .g-uneditable {
  cursor: pointer;
}
.kgRow--selected {
  background: var(--grid-viewport-selected-row-color) !important;
}
.kgRow--selected .select2-selection,
.kgRow--selected input[type="text"],
.kgRow--selected input[type="tel"],
.kgRow--selected .gwCheckBox,
.kgRow--selected .gwMeasure,
.kgRow--selected button {
  background: transparent !important;
}
.kgRow.g-sibling-selecting,
.kgRow--hovered {
  color: var(--grid-viewport-text-color) !important;
  background-color: var(--grid-viewport-hover-row-color) !important;
}
.kgTopPanel {
  font-size: 11px;
}
.kgHeaderContainer {
  border-color: var(--grid-header-border-color);
}
.kgHeaderCell {
  color: #FFFFFF;
}
.kgHeaderCell a,
.kgHeaderCell button {
  color: #FFFFFF;
}
.kgSortButtonDown,
.kgSortButtonUp {
  border-color: #FFFFFF transparent;
}
.kgHeaderText,
.kgHeaderTextContainer {
  padding: 4px 5px 5px 5px;
}
.kgHeaderTextContainer .kgHeaderText {
  padding: 0;
}
.kgHeaderCell {
  border-color: var(--grid-header-border-color);
}
.kgHeaderContainer {
  font-weight: Normal;
  background-color: var(--grid-header-background-color-start);
}
.kgFixedViewport {
  border-right: 1px solid var(--grid-header-border-color);
}
.kgGroupPanel--drag-over {
  background-color: #DFEAF2;
}
.kgGroupPlaceholder {
  margin-left: 11px;
  margin-right: 11px;
}
.kgGroupItem:hover {
  cursor: pointer;
}
.kgGroupName {
  background-color: #7490B7;
  border-radius: 5px;
  border: none;
  margin-top: 2px;
  padding: 3px 10px 4px;
  font-weight: normal;
  font-size: 10px;
  color: #FFFFFF;
}
.kgRemoveGroup {
  opacity: 1;
  margin-top: 2px;
}
span.kgRemoveGroup {
  font-size: 10px;
}
.kgGroupArrow {
  width: auto;
  border: none;
  margin-top: 5px;
  margin-left: 11px;
  margin-right: 11px;
  float: none;
  color: #003479;
}
.kgGroup {
  background-color: transparent;
  display: flex;
  font-weight: bold;
}
.kgGroup:hover {
  background-color: var(--grid-viewport-hover-row-color);
}
.kgGroupText {
  position: static;
  line-height: normal;
  padding-top: 4px;
}
[class^="icon-"].kgGroupArrowExpanded,
[class*=" icon-"].kgGroupArrowExpanded,
[class^="icon-"].kgGroupArrowCollapsed,
[class*=" icon-"].kgGroupArrowCollapsed {
  position: static;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  line-height: 0.5;
  cursor: pointer;
  margin: 0;
  height: 100%;
}
.kgGroupArrowExpanded {
  padding: 5px 15px 5px 4px;
  width: 24px;
}
.kgGroupArrowCollapsed {
  padding: 5px 15px 5px 9px;
  width: 23px;
}
.kgGroupPanelDescription {
  margin-top: 3px;
  margin-right: 11px;
  font-style: italic;
  color: var(--grid-viewport-text-color);
}
.g-kg-top-container {
  display: flex;
  width: 100%;
  line-height: auto;
}
.g-kg-top-container .g-kg-searchInputGroup {
  position: relative;
  margin-right: 3px;
  flex: 0 1 130px;
}
.g-kg-top-container .g-kg-searchInputGroup .g-kg-searchInput {
  padding: 0 12px;
}
.g-kg-top-container .g-kg-searchInputGroup .g-kg-searchInput-button {
  color: var(--theme-value-text);
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 30px;
  z-index: 5;
  text-align: center;
  vertical-align: middle;
  height: 34px;
  line-height: 34px;
  margin: 1px 0;
}
.g-search-results .g-kg-title-container {
  display: flex;
  flex-flow: row wrap;
}
.g-search-results .g-kg-title-container.Center {
  justify-content: center;
}
.g-search-results .g-kg-title-container .g-kg-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 3px;
}
.g-search-results .g-kg-title-container .g-kg-item-count-container {
  margin: 2px 0 11px 0;
  margin-right: 3px;
}
.g-kg-title-container {
  display: inline-block;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.g-kg-title-container.Center {
  width: 100%;
  text-align: center;
}
.g-kg-title-container .g-kg-item-name {
  font-weight: bold;
  font-size: 15px;
  color: var(--grid-title-font-color);
  white-space: nowrap;
}
.g-kg-title-container .g-kg-item-count-container {
  display: inline-block;
}
.g-kg-title-container .g-kg-item-count {
  font-size: 12px;
  color: var(--grid-item-count-font-color);
}
.g-kg-title-sub-container {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  float: right;
  overflow: hidden;
}
.g-kg-actions-container {
  padding: 0 0 0 7px;
  display: flex;
  flex: 1 0 auto;
  flex-flow: row-reverse nowrap;
  align-items: center;
}
.g-kg-actions-container.hide-pinned-items .gwPinnedItems {
  display: none;
}
.g-kg-actions-container .gwPinnedItems {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  height: 100%;
  overflow: hidden;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow {
  display: flex;
  flex: 0 0 auto;
  text-align: right;
  color: var(--theme-icons);
  height: 100%;
  margin-left: 14px;
  line-height: normal;
  font-size: 12px;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow a {
  color: var(--theme-icons);
  display: flex;
  align-items: center;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow a:hover {
  color: var(--theme-icons);
  cursor: pointer;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow a:hover span,
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow a:hover i {
  opacity: 0.5;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow a:focus {
  text-decoration: none;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow a:active span,
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow a:active i {
  opacity: 0.8;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  color: var(--theme-icons);
  text-transform: none;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow button:hover {
  color: var(--theme-icons);
  cursor: pointer;
  opacity: 0.5;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow button:active:focus {
  background: none;
  color: var(--theme-icons);
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow button.g-btn-loading > span {
  display: none;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow .g-display-option-button {
  float: left;
  line-height: 22px;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow .g-display-option-text {
  float: left;
  line-height: 22px;
  margin-left: 5px;
  white-space: nowrap;
  font-weight: normal;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow.text-only .g-display-option-button,
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow.buttons-only .g-display-option-text {
  display: none;
}
.g-kg-actions-container .gwPinnedItems .gwPinnedItem-overflow.buttons-only .g-display-option-button {
  min-width: 14px;
  text-align: center;
}
.g-kg-actions-container .g-kg-action {
  display: flex;
  flex: 0 0 auto;
  text-align: right;
  color: var(--theme-icons);
  height: 100%;
  margin-left: 14px;
  line-height: normal;
  font-size: 12px;
}
.g-kg-actions-container .g-kg-action a {
  color: var(--theme-icons);
  display: flex;
  align-items: center;
}
.g-kg-actions-container .g-kg-action a:hover {
  color: var(--theme-icons);
  cursor: pointer;
}
.g-kg-actions-container .g-kg-action a:hover span,
.g-kg-actions-container .g-kg-action a:hover i {
  opacity: 0.5;
}
.g-kg-actions-container .g-kg-action a:focus {
  text-decoration: none;
}
.g-kg-actions-container .g-kg-action a:active span,
.g-kg-actions-container .g-kg-action a:active i {
  opacity: 0.8;
}
.g-kg-actions-container .g-kg-action button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  color: var(--theme-icons);
  text-transform: none;
}
.g-kg-actions-container .g-kg-action button:hover {
  color: var(--theme-icons);
  cursor: pointer;
  opacity: 0.5;
}
.g-kg-actions-container .g-kg-action button:active:focus {
  background: none;
  color: var(--theme-icons);
}
.g-kg-actions-container .g-kg-action button.g-btn-loading > span {
  display: none;
}
.g-kg-actions-container .g-kg-action .g-display-option-button {
  float: left;
  line-height: 22px;
}
.g-kg-actions-container .g-kg-action .g-display-option-text {
  float: left;
  line-height: 22px;
  margin-left: 5px;
  white-space: nowrap;
  font-weight: normal;
}
.g-kg-actions-container .g-kg-action.text-only .g-display-option-button,
.g-kg-actions-container .g-kg-action.buttons-only .g-display-option-text {
  display: none;
}
.g-kg-actions-container .g-kg-action.buttons-only .g-display-option-button {
  min-width: 14px;
  text-align: center;
}
.kgCell {
  border-color: var(--grid-viewport-border-color);
}
.kgCell input[type="text"],
.kgCell input[type="tel"] {
  width: 100%;
  height: 100%;
  padding: 0 7px 1px 7px;
  margin: 0;
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.kgCell .gwNumeric,
.kgCell .gwSearchBox,
.kgCell .gwTextBox {
  height: 100%;
}
.kgCell .gwDatePicker input[type="text"] {
  padding-right: 25px;
}
.kgCell .gwMeasure {
  border-color: transparent;
}
.kgCell .gwSearchBox-description {
  width: 100%;
  padding-top: 0;
}
.kgCell .gwSearchBox-description-wrap {
  padding-top: 4px;
}
.kgCell .gwSearchBox-description-spacer {
  min-width: 30px;
  padding-right: 8px;
}
.kgCell .gwSearchBox-description-divider {
  height: 12px;
  padding-top: 0;
  margin: 7px 0 0 0;
  border-right: 1px solid #d3d6d9;
}
.kgCell .gwSearchBox-description-text {
  display: block;
  max-height: none;
  min-width: 18px;
  padding-left: 8px;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}
.kgCell .gwSearchBox-text {
  padding-right: 30px !important;
}
.kgCell .form-control {
  border-radius: 0 !important;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.kgCell input.form-control[readonly]:focus:not(.g-readonly-override) {
  border-color: transparent !important;
}
.kgCell .input-group-addon {
  line-height: 20px;
}
.kgCell .input-group-addon i {
  display: inline;
}
.kgCell .select2 .select2-selection {
  background: none;
  border-color: transparent;
  border-radius: 0;
  font-size: 12px;
}
.kgCell .select2 .select2-selection .select2-selection__rendered {
  padding: 0 29px 0 7px;
  line-height: 22px;
}
.kgCell.g-text-center {
  text-align: center;
}
.kgHeaderCell .icon-caret-up,
.kgHeaderCell .icon-caret-down {
  padding: 0 2px 0 3px;
  float: right;
}
.kgHeaderTextHover:hover {
  background-color: var(--theme-hover);
}
.kgCellText {
  padding: 4px 8px;
}
.kgCellInner > .g-container {
  height: 100%;
}
.kgRemoveGroupText {
  display: none;
}
.kgRow--drag-over-top {
  box-shadow: 0 2px 0 0 #00A8E1 inset;
}
.kgRow--drag-over-bottom {
  box-shadow: 0 -2px 0 0 #00A8E1 inset;
}
.g-kg__filter-tag {
  padding: 0 3px;
  margin: 3px 0;
  border-radius: 5px;
  box-shadow: #aaa 2px 2px 4px 1px;
}
.g-kg__filter-tag .g-display-option-button {
  padding: 0 2px;
  margin-left: 5px;
  cursor: pointer;
}
#tinymce {
  width: auto;
  height: auto;
  padding-top: 0;
}
.tox.tox-tinymce {
  padding-top: 15px;
  border: 1px solid #d3d6d9;
  border-radius: 4px;
  background-color: #fff;
}
.tox.tox-tinymce .tox-tbtn:active,
.tox.tox-tinymce .tox-tbtn:focus {
  background: transparent;
}
.tox.tox-tinymce .tox-tbtn[aria-disabled='true']:hover {
  background: transparent;
}
.tox.tox-tinymce .tox-tbtn[aria-disabled='true']:active svg,
.tox.tox-tinymce .tox-tbtn[aria-disabled='true']:focus svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox.tox-tinymce .tox-toolbar__group {
  padding: 0;
}
.tox.tox-tinymce .tox-toolbar__group:first-child {
  padding-left: 6px;
}
.tox.tox-tinymce .tox-edit-area,
.tox.tox-tinymce .tox-statusbar {
  border-top: 1px solid #d3d6d9;
}
.tox.tox-tinymce .tox-statusbar__path-item,
.tox.tox-tinymce .tox-statusbar__path-divider {
  display: none;
}
.tox .tox-menu.tox-collection--list .tox-collection__group .tox-collection__item--active {
  background-color: var(--theme-hover);
  color: white !important;
}
.tox .tox-menu .tox-collection__item-label span {
  background-color: transparent !important;
}
.tox .tox-dialog {
  border-radius: 4px !important;
  box-shadow: 6px 6px 10px -3px rgba(102, 102, 102, 0.9) !important;
}
.tox .tox-dialog * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tox .tox-dialog .tox-dialog__header {
  height: 38px;
  padding: 2px 10px 3px;
  cursor: default;
  background-color: var(--body-background-color-start);
}
.tox .tox-dialog .tox-dialog__header .tox-button--naked {
  color: var(--theme-icons);
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.tox .tox-dialog .tox-dialog__header .tox-button--naked:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.tox .tox-dialog .tox-dialog__header .tox-button--naked:hover {
  opacity: 0.5;
  text-decoration: none;
}
.tox .tox-dialog .tox-dialog__header .tox-button--naked:active {
  opacity: 0.3;
  text-decoration: none;
}
.tox .tox-dialog .tox-dialog__header .tox-button--naked:hover:not(:disabled) {
  background-color: transparent;
}
.tox .tox-dialog .tox-dialog__body {
  border-top: 1px solid #d3d6d9;
  background-color: var(--body-background-color-start);
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content {
  padding: 15px 10px 30px;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group {
  position: relative;
  height: 42px;
  margin-bottom: 0;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-label {
  position: absolute;
  z-index: 99999;
  padding: 5px 8px 0;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-textfield {
  height: 42px;
  padding: 18px 7px 4px;
  border-radius: 0;
  border-color: var(--input-control-border-brush-start);
  color: var(--input-control-text-color);
  font-size: 14px;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-textfield:focus {
  background: var(--input-control-focused-background-color-end);
  border: 1px solid var(--input-control-focused-border-brush-end);
  box-shadow: none;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-textfield:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-form__controls-h-stack .tox-form__group {
  margin-left: 0;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-form__controls-h-stack .tox-form__group .tox-button[title='Constrain proportions'] {
  position: relative;
  top: 4px;
  left: 4px;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-listbox {
  height: 42px;
  padding: 18px 7px 4px;
  border-radius: 0;
  border-color: var(--input-control-border-brush-start);
  color: var(--input-control-text-color);
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-listbox__select-label {
  font-size: 14px;
  color: var(--button-text-color);
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-listbox:last-child:not(.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-listbox--active) {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-listbox--active {
  background: var(--input-control-focused-background-color-end);
  border: 1px solid var(--input-control-focused-border-brush-end);
  border-radius: 0;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-listbox:focus {
  background: var(--input-control-focused-background-color-end);
  border: 1px solid var(--input-control-focused-border-brush-end);
  box-shadow: none;
}
.tox .tox-dialog .tox-dialog__body .tox-dialog__body-content .tox-form__group .tox-listbox span {
  margin-left: 0;
}
.tox .tox-dialog .tox-dialog__footer {
  padding: 6px;
  border-top: 1px solid #d3d6d9;
}
.tox .tox-dialog .tox-dialog__footer .tox-button {
  width: 100px;
  padding: 2px;
  margin-left: 5px;
  font-size: 15px;
  font-weight: bold;
}
.tox .tox-dialog .tox-dialog__footer .tox-button.tox-button--secondary {
  color: var(--button-text-color);
  background-color: transparent;
}
.tox .tox-dialog .tox-dialog__footer .tox-button.tox-button--secondary:hover {
  color: var(--button-text-hover-color);
  background-color: var(--button-background-hover-color-start);
}
.tox .tox-dialog .tox-dialog__title,
.tox .tox-dialog .tox-label,
.tox .tox-dialog .tox-listbox__select-label,
.tox .tox-dialog .tox-collection__item-label,
.tox .tox-dialog .tox-button {
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
}
.tox .tox-dialog .tox-label {
  font-size: 12px;
}
.tox .tox-dialog-wrap .tox-dialog-wrap__backdrop {
  background-color: #333;
  opacity: 0.5;
}
.ui-resizable-handle {
  -webkit-transition: 0.1s;
  transition: 0.1s;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  border-radius: 5px;
}
.ui-resizable-handle:hover {
  background-color: rgba(50, 50, 50, 0.4);
}
.ui-resizable-handle:active {
  background-color: rgba(50, 50, 50, 0.5);
}
.ui-resizable-e,
.ui-resizable-w {
  width: 8px;
  right: -4px;
  cursor: col-resize;
}
.ui-resizable-s,
.ui-resizable-n {
  height: 10px;
  margin-top: -2px;
  cursor: row-resize;
}
hgroup {
  display: block;
}
audio,
canvas,
progress,
video {
  vertical-align: baseline;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  margin: 0;
}
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
}
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
body {
  line-height: 1.42857143;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  text-decoration: none;
}
a:focus {
  color: #23527c;
  text-decoration: underline;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
img {
  vertical-align: middle;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
html,
body {
  width: 100%;
}
html {
  height: 100%;
  overscroll-behavior-y: contain;
}
body {
  height: calc(100% - 38px);
  font-weight: Normal;
  color: var(--body-text-color);
  font-size: 13px;
  background-color: var(--body-background-color-end);
}
label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: normal;
  color: var(--theme-field-title);
}
iframe {
  width: 100%;
  height: 280px;
  border: none;
}
hr {
  width: 95%;
  border-top: 1px dashed #5f90bc;
}
a {
  color: var(--theme-default-button);
}
a:hover {
  color: var(--theme-hover);
  text-decoration: none;
}
.g-invalid-property {
  border: 1px solid red;
  height: 100%;
  position: relative;
  border-radius: 4px;
  background-color: #f2f2f2;
}
.g-invalid-property span {
  color: red;
  font-size: 14px;
  padding-left: 6px;
  position: absolute;
  top: 50%;
  font-style: italic;
  transform: translateY(-50%);
}
.g-read-restricted .icon-eye-slash {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
.noscript {
  font-size: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  padding: 10px;
  color: #fff;
  background-color: #d90000;
}
#g-btn-reload-page {
  margin: 20px;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus,
.btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active {
  color: #333;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.btn-default:active.focus,
.btn-default.active.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary:active,
.btn-primary.active {
  color: #fff;
  background-color: #286090;
  background-image: none;
  border-color: #204d74;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.btn-primary:active.focus,
.btn-primary.active.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}
.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}
.btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn {
  position: relative;
  float: left;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-group > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .btn-group {
  float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn .caret {
  margin-left: 0;
}
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-tile {
  width: 100%;
}
.btn .label {
  position: relative;
  top: -1px;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.btn,
.btn-primary {
  text-shadow: none;
}
.btn:active,
.btn.active {
  box-shadow: none;
}
.btn-group > .btn:first-child {
  margin: 0;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: default;
}
.btn,
.tox .tox-dialog__footer .tox-button {
  border: 1px solid transparent;
  font-weight: normal;
}
.btn .g-loading-animation,
.tox .tox-dialog__footer .tox-button .g-loading-animation {
  color: #888;
}
.btn .g-loading-animation .icon-spinner,
.tox .tox-dialog__footer .tox-button .g-loading-animation .icon-spinner {
  color: var(--theme-icons);
}
.btn:focus,
.tox .tox-dialog__footer .tox-button:focus {
  border: 1px dashed var(--theme-general-text);
  outline: 0;
}
.btn:active,
.tox .tox-dialog__footer .tox-button:active,
.btn:focus:active,
.tox .tox-dialog__footer .tox-button:focus:active {
  outline: 0;
  box-shadow: none;
}
.btn .btn-text,
.tox .tox-dialog__footer .tox-button .btn-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn.g-btn-async {
  text-shadow: none;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn.g-btn-async[data-icon] {
  padding-left: 11px;
  padding-right: 11px;
}
.btn.g-btn-async.g-btn-loading,
.btn.g-btn-async.g-btn-loading[data-icon] {
  padding-left: 9.5px;
  padding-right: 9.762px;
}
.btn.g-btn-async > i {
  margin-right: 5px;
}
.btn.g-btn-async.g-btn-loading {
  color: #888888;
}
.btn.g-btn-async.g-btn-loading > i {
  display: none;
}
.g-btn-async .g-loading-animation-wrapper {
  display: none;
}
.g-btn-async.g-btn-loading .g-loading-animation-wrapper {
  display: flex;
}
.g-btn-async.g-btn-loading + .g-menu-item-icon-container {
  display: none;
}
.g-btn-async.g-header-button.g-btn-loading .g-display-option-button {
  display: none;
}
.g-btn-async.g-header-button .g-loading-animation .icon-spinner {
  margin-right: 3px;
}
.g-footer-bar > .g-footer-content,
.modal-footer,
.g-document-footer,
.tox .tox-dialog__footer {
  background-color: var(--popup-footer-background-color-start);
}
.btn,
.nav-pills > li > a {
  font-size: 14px;
}
.btn:not(.btn-link) {
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: var(--button-background-color-start);
  color: var(--button-text-color);
  border-radius: 5px;
}
.btn:not(.btn-link) a,
.btn:not(.btn-link) label {
  color: var(--button-text-color);
}
.btn:not(.btn-link) i {
  color: var(--button-text-color);
}
.btn:not(.btn-link) i.chevron {
  color: var(--button-text-color-lighten10);
}
.open > .btn:not(.btn-link):not(.disabled):not([disabled]),
.active > .btn:not(.btn-link):not(.disabled):not([disabled]),
.btn:not(.btn-link):not(.disabled):not([disabled]).pressed,
.btn:not(.btn-link):not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .btn:not(.btn-link):not(.disabled):not([disabled]) i,
.active > .btn:not(.btn-link):not(.disabled):not([disabled]) i,
.btn:not(.btn-link):not(.disabled):not([disabled]).pressed i,
.btn:not(.btn-link):not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .btn:not(.btn-link):not(.disabled):not([disabled]):focus,
.active > .btn:not(.btn-link):not(.disabled):not([disabled]):focus,
.btn:not(.btn-link):not(.disabled):not([disabled]).pressed:focus,
.btn:not(.btn-link):not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .btn:not(.btn-link):not(.disabled):not([disabled]):focus i,
.active > .btn:not(.btn-link):not(.disabled):not([disabled]):focus i,
.btn:not(.btn-link):not(.disabled):not([disabled]).pressed:focus i,
.btn:not(.btn-link):not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .btn:not(.btn-link):not(.disabled):not([disabled]):hover,
  .active > .btn:not(.btn-link):not(.disabled):not([disabled]):hover,
  .btn:not(.btn-link):not(.disabled):not([disabled]).pressed:hover,
  .btn:not(.btn-link):not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--button-background-hover-color-start);
    color: var(--button-text-hover-color);
  }
}
.open > .btn:not(.btn-link):not(.disabled):not([disabled]):active,
.active > .btn:not(.btn-link):not(.disabled):not([disabled]):active,
.btn:not(.btn-link):not(.disabled):not([disabled]).pressed:active,
.btn:not(.btn-link):not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--button-background-pressed-color-start);
  color: var(--button-text-hover-color);
}
.btn:not(.btn-link):not(.disabled):not([disabled]):focus {
  background-color: var(--button-background-color-start);
  color: var(--button-text-color);
  border: 1px dashed #333;
  border-radius: 5px;
}
@media (hover) {
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover {
    color: var(--button-text-hover-color);
    background-color: var(--button-background-hover-color-start);
    border-radius: 5px;
  }
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover a,
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i,
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover label,
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover span {
    color: var(--button-text-hover-color) !important;
  }
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .btn:not(.btn-link):not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.btn:not(.btn-link):not(.disabled):not([disabled]):active {
  color: var(--button-text-hover-color);
  background-color: var(--button-background-pressed-color-start);
  border-radius: 5px;
}
.btn:not(.btn-link):not(.disabled):not([disabled]):active a,
.btn:not(.btn-link):not(.disabled):not([disabled]):active i,
.btn:not(.btn-link):not(.disabled):not([disabled]):active label,
.btn:not(.btn-link):not(.disabled):not([disabled]):active span {
  color: var(--button-text-hover-color) !important;
}
.btn:not(.btn-link).disabled,
.btn:not(.btn-link)[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.btn:not(.btn-link).disabled:active,
.btn:not(.btn-link)[disabled]:active {
  color: #999;
}
.btn:not(.btn-link).disabled:active a,
.btn:not(.btn-link)[disabled]:active a,
.btn:not(.btn-link).disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.btn:not(.btn-link)[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.btn:not(.btn-link).disabled:active label,
.btn:not(.btn-link)[disabled]:active label,
.btn:not(.btn-link).disabled:active span,
.btn:not(.btn-link)[disabled]:active span {
  color: #999;
}
.btn:not(.btn-link).disabled:active i,
.btn:not(.btn-link)[disabled]:active i {
  opacity: 1;
}
.btn:not(.btn-link).disabled:active i.g-menu-item-always-enabled-icon,
.btn:not(.btn-link)[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.btn:not(.btn-link).disabled:active i.g-menu-item-always-enabled-icon:focus,
.btn:not(.btn-link)[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.btn:not(.btn-link).disabled:active i.g-menu-item-always-enabled-icon:hover,
.btn:not(.btn-link)[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.btn:not(.btn-link).disabled:active i.g-menu-item-always-enabled-icon:active,
.btn:not(.btn-link)[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.btn:not(.btn-link).disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.btn:not(.btn-link)[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.btn:not(.btn-link).disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.btn:not(.btn-link)[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.btn:not(.btn-link).btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.btn:not(.btn-link).btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.btn.btn-primary,
.tox-button {
  font-size: 14px;
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: var(--primary-button-background-color-start);
  color: var(--primary-button-text-color);
  border-radius: 5px;
}
.btn.btn-primary a,
.tox-button a,
.btn.btn-primary label,
.tox-button label {
  color: var(--primary-button-text-color);
}
.btn.btn-primary i,
.tox-button i {
  color: var(--primary-button-text-color);
}
.btn.btn-primary i.chevron,
.tox-button i.chevron {
  color: var(--primary-button-text-color-lighten10);
}
.open > .btn.btn-primary:not(.disabled):not([disabled]),
.open > .tox-button:not(.disabled):not([disabled]),
.active > .btn.btn-primary:not(.disabled):not([disabled]),
.active > .tox-button:not(.disabled):not([disabled]),
.btn.btn-primary:not(.disabled):not([disabled]).pressed,
.tox-button:not(.disabled):not([disabled]).pressed,
.btn.btn-primary:not(.disabled):not([disabled]).popover-shown,
.tox-button:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .btn.btn-primary:not(.disabled):not([disabled]) i,
.open > .tox-button:not(.disabled):not([disabled]) i,
.active > .btn.btn-primary:not(.disabled):not([disabled]) i,
.active > .tox-button:not(.disabled):not([disabled]) i,
.btn.btn-primary:not(.disabled):not([disabled]).pressed i,
.tox-button:not(.disabled):not([disabled]).pressed i,
.btn.btn-primary:not(.disabled):not([disabled]).popover-shown i,
.tox-button:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .btn.btn-primary:not(.disabled):not([disabled]):focus,
.open > .tox-button:not(.disabled):not([disabled]):focus,
.active > .btn.btn-primary:not(.disabled):not([disabled]):focus,
.active > .tox-button:not(.disabled):not([disabled]):focus,
.btn.btn-primary:not(.disabled):not([disabled]).pressed:focus,
.tox-button:not(.disabled):not([disabled]).pressed:focus,
.btn.btn-primary:not(.disabled):not([disabled]).popover-shown:focus,
.tox-button:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .btn.btn-primary:not(.disabled):not([disabled]):focus i,
.open > .tox-button:not(.disabled):not([disabled]):focus i,
.active > .btn.btn-primary:not(.disabled):not([disabled]):focus i,
.active > .tox-button:not(.disabled):not([disabled]):focus i,
.btn.btn-primary:not(.disabled):not([disabled]).pressed:focus i,
.tox-button:not(.disabled):not([disabled]).pressed:focus i,
.btn.btn-primary:not(.disabled):not([disabled]).popover-shown:focus i,
.tox-button:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .btn.btn-primary:not(.disabled):not([disabled]):hover,
  .open > .tox-button:not(.disabled):not([disabled]):hover,
  .active > .btn.btn-primary:not(.disabled):not([disabled]):hover,
  .active > .tox-button:not(.disabled):not([disabled]):hover,
  .btn.btn-primary:not(.disabled):not([disabled]).pressed:hover,
  .tox-button:not(.disabled):not([disabled]).pressed:hover,
  .btn.btn-primary:not(.disabled):not([disabled]).popover-shown:hover,
  .tox-button:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--primary-button-background-hover-color-start);
    color: var(--primary-button-text-hover-color);
  }
}
.open > .btn.btn-primary:not(.disabled):not([disabled]):active,
.open > .tox-button:not(.disabled):not([disabled]):active,
.active > .btn.btn-primary:not(.disabled):not([disabled]):active,
.active > .tox-button:not(.disabled):not([disabled]):active,
.btn.btn-primary:not(.disabled):not([disabled]).pressed:active,
.tox-button:not(.disabled):not([disabled]).pressed:active,
.btn.btn-primary:not(.disabled):not([disabled]).popover-shown:active,
.tox-button:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--primary-button-background-pressed-color-start);
  color: var(--primary-button-text-hover-color);
}
.btn.btn-primary:not(.disabled):not([disabled]):focus,
.tox-button:not(.disabled):not([disabled]):focus {
  background-color: var(--primary-button-background-color-start);
  color: var(--primary-button-text-color);
  border: 1px dashed #333;
  border-radius: 5px;
}
@media (hover) {
  .btn.btn-primary:not(.disabled):not([disabled]):hover,
  .tox-button:not(.disabled):not([disabled]):hover {
    color: var(--primary-button-text-hover-color);
    background-color: var(--primary-button-background-hover-color-start);
    border-radius: 5px;
  }
  .btn.btn-primary:not(.disabled):not([disabled]):hover a,
  .tox-button:not(.disabled):not([disabled]):hover a,
  .btn.btn-primary:not(.disabled):not([disabled]):hover i,
  .tox-button:not(.disabled):not([disabled]):hover i,
  .btn.btn-primary:not(.disabled):not([disabled]):hover label,
  .tox-button:not(.disabled):not([disabled]):hover label,
  .btn.btn-primary:not(.disabled):not([disabled]):hover span,
  .tox-button:not(.disabled):not([disabled]):hover span {
    color: var(--primary-button-text-hover-color) !important;
  }
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .btn.btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active,
  .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.btn.btn-primary:not(.disabled):not([disabled]):active,
.tox-button:not(.disabled):not([disabled]):active {
  color: var(--primary-button-text-hover-color);
  background-color: var(--primary-button-background-pressed-color-start);
  border-radius: 5px;
}
.btn.btn-primary:not(.disabled):not([disabled]):active a,
.tox-button:not(.disabled):not([disabled]):active a,
.btn.btn-primary:not(.disabled):not([disabled]):active i,
.tox-button:not(.disabled):not([disabled]):active i,
.btn.btn-primary:not(.disabled):not([disabled]):active label,
.tox-button:not(.disabled):not([disabled]):active label,
.btn.btn-primary:not(.disabled):not([disabled]):active span,
.tox-button:not(.disabled):not([disabled]):active span {
  color: var(--primary-button-text-hover-color) !important;
}
.btn.btn-primary.disabled,
.tox-button.disabled,
.btn.btn-primary[disabled],
.tox-button[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.btn.btn-primary.disabled:active,
.tox-button.disabled:active,
.btn.btn-primary[disabled]:active,
.tox-button[disabled]:active {
  color: #999;
}
.btn.btn-primary.disabled:active a,
.tox-button.disabled:active a,
.btn.btn-primary[disabled]:active a,
.tox-button[disabled]:active a,
.btn.btn-primary.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.tox-button.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.btn.btn-primary[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.tox-button[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.btn.btn-primary.disabled:active label,
.tox-button.disabled:active label,
.btn.btn-primary[disabled]:active label,
.tox-button[disabled]:active label,
.btn.btn-primary.disabled:active span,
.tox-button.disabled:active span,
.btn.btn-primary[disabled]:active span,
.tox-button[disabled]:active span {
  color: #999;
}
.btn.btn-primary.disabled:active i,
.tox-button.disabled:active i,
.btn.btn-primary[disabled]:active i,
.tox-button[disabled]:active i {
  opacity: 1;
}
.btn.btn-primary.disabled:active i.g-menu-item-always-enabled-icon,
.tox-button.disabled:active i.g-menu-item-always-enabled-icon,
.btn.btn-primary[disabled]:active i.g-menu-item-always-enabled-icon,
.tox-button[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.btn.btn-primary.disabled:active i.g-menu-item-always-enabled-icon:focus,
.tox-button.disabled:active i.g-menu-item-always-enabled-icon:focus,
.btn.btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:focus,
.tox-button[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.btn.btn-primary.disabled:active i.g-menu-item-always-enabled-icon:hover,
.tox-button.disabled:active i.g-menu-item-always-enabled-icon:hover,
.btn.btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:hover,
.tox-button[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.btn.btn-primary.disabled:active i.g-menu-item-always-enabled-icon:active,
.tox-button.disabled:active i.g-menu-item-always-enabled-icon:active,
.btn.btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:active,
.tox-button[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.btn.btn-primary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.tox-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.btn.btn-primary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.tox-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.btn.btn-primary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.tox-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.btn.btn-primary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.tox-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.btn.btn-primary.btn-toggle:not(.active),
.tox-button.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.btn.btn-primary.btn-toggle.active,
.tox-button.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
button[disabled],
.btn[disabled],
.gwOptionButtonGrid input[type=radio][disabled] + label {
  color: #999;
  background-color: #FFFFFF;
  border-color: transparent;
  cursor: default;
}
.g-action-state,
li > a.g-action-state {
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-action-state:focus,
li > a.g-action-state:focus {
  outline: none;
  opacity: 1;
  text-decoration: none;
}
.g-action-state:hover,
li > a.g-action-state:hover {
  opacity: 1;
  border-color: transparent;
}
.g-action-state:active,
li > a.g-action-state:active {
  opacity: 1;
  border-color: transparent;
}
.g-action-state i.icon-globe,
li > a.g-action-state i.icon-globe,
.g-action-state:focus i.icon-globe,
li > a.g-action-state:focus i.icon-globe {
  color: var(--theme-default-button);
}
.g-action-state i.icon-unpin,
li > a.g-action-state i.icon-unpin,
.g-action-state:focus i.icon-unpin,
li > a.g-action-state:focus i.icon-unpin,
.g-action-state i.icon-pin,
li > a.g-action-state i.icon-pin,
.g-action-state:focus i.icon-pin,
li > a.g-action-state:focus i.icon-pin {
  color: var(--theme-icons);
}
.g-action-state-list-item,
li > a.g-action-state-list-item {
  background-color: transparent;
  color: var(--theme-general-text);
  border-radius: 5px;
}
.g-action-state-list-item a,
li > a.g-action-state-list-item a,
.g-action-state-list-item label,
li > a.g-action-state-list-item label {
  color: var(--theme-general-text);
}
.g-action-state-list-item i,
li > a.g-action-state-list-item i {
  color: var(--theme-general-text);
}
.g-action-state-list-item i.chevron,
li > a.g-action-state-list-item i.chevron {
  color: var(--theme-general-text-lighten10);
}
.open > .g-action-state-list-item:not(.disabled):not([disabled]),
.open > li > a.g-action-state-list-item:not(.disabled):not([disabled]),
.active > .g-action-state-list-item:not(.disabled):not([disabled]),
.active > li > a.g-action-state-list-item:not(.disabled):not([disabled]),
.g-action-state-list-item:not(.disabled):not([disabled]).pressed,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).pressed,
.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-action-state-list-item:not(.disabled):not([disabled]) i,
.open > li > a.g-action-state-list-item:not(.disabled):not([disabled]) i,
.active > .g-action-state-list-item:not(.disabled):not([disabled]) i,
.active > li > a.g-action-state-list-item:not(.disabled):not([disabled]) i,
.g-action-state-list-item:not(.disabled):not([disabled]).pressed i,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).pressed i,
.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown i,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .g-action-state-list-item:not(.disabled):not([disabled]):focus,
.open > li > a.g-action-state-list-item:not(.disabled):not([disabled]):focus,
.active > .g-action-state-list-item:not(.disabled):not([disabled]):focus,
.active > li > a.g-action-state-list-item:not(.disabled):not([disabled]):focus,
.g-action-state-list-item:not(.disabled):not([disabled]).pressed:focus,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).pressed:focus,
.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:focus,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-action-state-list-item:not(.disabled):not([disabled]):focus i,
.open > li > a.g-action-state-list-item:not(.disabled):not([disabled]):focus i,
.active > .g-action-state-list-item:not(.disabled):not([disabled]):focus i,
.active > li > a.g-action-state-list-item:not(.disabled):not([disabled]):focus i,
.g-action-state-list-item:not(.disabled):not([disabled]).pressed:focus i,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).pressed:focus i,
.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:focus i,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .g-action-state-list-item:not(.disabled):not([disabled]):hover,
  .open > li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover,
  .active > .g-action-state-list-item:not(.disabled):not([disabled]):hover,
  .active > li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover,
  .g-action-state-list-item:not(.disabled):not([disabled]).pressed:hover,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]).pressed:hover,
  .g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:hover,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--theme-hover);
    color: var(--theme-hover-text);
  }
}
.open > .g-action-state-list-item:not(.disabled):not([disabled]):active,
.open > li > a.g-action-state-list-item:not(.disabled):not([disabled]):active,
.active > .g-action-state-list-item:not(.disabled):not([disabled]):active,
.active > li > a.g-action-state-list-item:not(.disabled):not([disabled]):active,
.g-action-state-list-item:not(.disabled):not([disabled]).pressed:active,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).pressed:active,
.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:active,
li > a.g-action-state-list-item:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--primary-button-background-pressed-color-start);
  color: var(--theme-hover-text);
}
.g-action-state-list-item:not(.disabled):not([disabled]):focus,
li > a.g-action-state-list-item:not(.disabled):not([disabled]):focus {
  background-color: transparent;
  color: var(--theme-general-text);
  border: 1px dashed #333;
  border-radius: 5px;
}
@media (hover) {
  .g-action-state-list-item:not(.disabled):not([disabled]):hover,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover {
    color: var(--theme-hover-text);
    background-color: var(--theme-hover);
    border-radius: 5px;
  }
  .g-action-state-list-item:not(.disabled):not([disabled]):hover a,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover a,
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i,
  .g-action-state-list-item:not(.disabled):not([disabled]):hover label,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover label,
  .g-action-state-list-item:not(.disabled):not([disabled]):hover span,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover span {
    color: var(--theme-hover-text) !important;
  }
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active,
  li > a.g-action-state-list-item:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.g-action-state-list-item:not(.disabled):not([disabled]):active,
li > a.g-action-state-list-item:not(.disabled):not([disabled]):active {
  color: var(--theme-hover-text);
  background-color: var(--primary-button-background-pressed-color-start);
  border-radius: 5px;
}
.g-action-state-list-item:not(.disabled):not([disabled]):active a,
li > a.g-action-state-list-item:not(.disabled):not([disabled]):active a,
.g-action-state-list-item:not(.disabled):not([disabled]):active i,
li > a.g-action-state-list-item:not(.disabled):not([disabled]):active i,
.g-action-state-list-item:not(.disabled):not([disabled]):active label,
li > a.g-action-state-list-item:not(.disabled):not([disabled]):active label,
.g-action-state-list-item:not(.disabled):not([disabled]):active span,
li > a.g-action-state-list-item:not(.disabled):not([disabled]):active span {
  color: var(--theme-hover-text) !important;
}
.g-action-state-list-item.disabled,
li > a.g-action-state-list-item.disabled,
.g-action-state-list-item[disabled],
li > a.g-action-state-list-item[disabled] {
  background-color: transparent;
  color: #999;
}
.g-action-state-list-item.disabled:active,
li > a.g-action-state-list-item.disabled:active,
.g-action-state-list-item[disabled]:active,
li > a.g-action-state-list-item[disabled]:active {
  color: #999;
}
.g-action-state-list-item.disabled:active a,
li > a.g-action-state-list-item.disabled:active a,
.g-action-state-list-item[disabled]:active a,
li > a.g-action-state-list-item[disabled]:active a,
.g-action-state-list-item.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
li > a.g-action-state-list-item.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-action-state-list-item[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
li > a.g-action-state-list-item[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-action-state-list-item.disabled:active label,
li > a.g-action-state-list-item.disabled:active label,
.g-action-state-list-item[disabled]:active label,
li > a.g-action-state-list-item[disabled]:active label,
.g-action-state-list-item.disabled:active span,
li > a.g-action-state-list-item.disabled:active span,
.g-action-state-list-item[disabled]:active span,
li > a.g-action-state-list-item[disabled]:active span {
  color: #999;
}
.g-action-state-list-item.disabled:active i,
li > a.g-action-state-list-item.disabled:active i,
.g-action-state-list-item[disabled]:active i,
li > a.g-action-state-list-item[disabled]:active i {
  opacity: 1;
}
.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon,
li > a.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon,
.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon,
li > a.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon:focus,
li > a.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon:focus,
.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon:focus,
li > a.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon:hover,
li > a.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon:hover,
.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon:hover,
li > a.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon:active,
li > a.g-action-state-list-item.disabled:active i.g-menu-item-always-enabled-icon:active,
.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon:active,
li > a.g-action-state-list-item[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-action-state-list-item.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
li > a.g-action-state-list-item.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-action-state-list-item[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
li > a.g-action-state-list-item[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-action-state-list-item.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
li > a.g-action-state-list-item.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.g-action-state-list-item[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
li > a.g-action-state-list-item[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.g-action-state-header,
li > a.g-action-state-header {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: transparent;
  color: #FFFFFF;
  border-radius: none;
}
.g-action-state-header a,
li > a.g-action-state-header a,
.g-action-state-header label,
li > a.g-action-state-header label {
  color: #FFFFFF;
}
.g-action-state-header i,
li > a.g-action-state-header i {
  color: #FFFFFF;
}
.g-action-state-header i.chevron,
li > a.g-action-state-header i.chevron {
  color: #ffffff;
}
.open > .g-action-state-header:not(.disabled):not([disabled]),
.open > li > a.g-action-state-header:not(.disabled):not([disabled]),
.active > .g-action-state-header:not(.disabled):not([disabled]),
.active > li > a.g-action-state-header:not(.disabled):not([disabled]),
.g-action-state-header:not(.disabled):not([disabled]).pressed,
li > a.g-action-state-header:not(.disabled):not([disabled]).pressed,
.g-action-state-header:not(.disabled):not([disabled]).popover-shown,
li > a.g-action-state-header:not(.disabled):not([disabled]).popover-shown {
  background-color: #FFFFFF;
  color: var(--header-background-color-start);
}
.open > .g-action-state-header:not(.disabled):not([disabled]) i,
.open > li > a.g-action-state-header:not(.disabled):not([disabled]) i,
.active > .g-action-state-header:not(.disabled):not([disabled]) i,
.active > li > a.g-action-state-header:not(.disabled):not([disabled]) i,
.g-action-state-header:not(.disabled):not([disabled]).pressed i,
li > a.g-action-state-header:not(.disabled):not([disabled]).pressed i,
.g-action-state-header:not(.disabled):not([disabled]).popover-shown i,
li > a.g-action-state-header:not(.disabled):not([disabled]).popover-shown i {
  color: var(--header-background-color-start);
}
.open > .g-action-state-header:not(.disabled):not([disabled]):focus,
.open > li > a.g-action-state-header:not(.disabled):not([disabled]):focus,
.active > .g-action-state-header:not(.disabled):not([disabled]):focus,
.active > li > a.g-action-state-header:not(.disabled):not([disabled]):focus,
.g-action-state-header:not(.disabled):not([disabled]).pressed:focus,
li > a.g-action-state-header:not(.disabled):not([disabled]).pressed:focus,
.g-action-state-header:not(.disabled):not([disabled]).popover-shown:focus,
li > a.g-action-state-header:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: #FFFFFF;
  color: var(--header-background-color-start);
}
.open > .g-action-state-header:not(.disabled):not([disabled]):focus i,
.open > li > a.g-action-state-header:not(.disabled):not([disabled]):focus i,
.active > .g-action-state-header:not(.disabled):not([disabled]):focus i,
.active > li > a.g-action-state-header:not(.disabled):not([disabled]):focus i,
.g-action-state-header:not(.disabled):not([disabled]).pressed:focus i,
li > a.g-action-state-header:not(.disabled):not([disabled]).pressed:focus i,
.g-action-state-header:not(.disabled):not([disabled]).popover-shown:focus i,
li > a.g-action-state-header:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--header-background-color-start);
}
@media (hover) {
  .open > .g-action-state-header:not(.disabled):not([disabled]):hover,
  .open > li > a.g-action-state-header:not(.disabled):not([disabled]):hover,
  .active > .g-action-state-header:not(.disabled):not([disabled]):hover,
  .active > li > a.g-action-state-header:not(.disabled):not([disabled]):hover,
  .g-action-state-header:not(.disabled):not([disabled]).pressed:hover,
  li > a.g-action-state-header:not(.disabled):not([disabled]).pressed:hover,
  .g-action-state-header:not(.disabled):not([disabled]).popover-shown:hover,
  li > a.g-action-state-header:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--header-background-color-start-darken10);
    color: var(--theme-hover-text);
  }
}
.open > .g-action-state-header:not(.disabled):not([disabled]):active,
.open > li > a.g-action-state-header:not(.disabled):not([disabled]):active,
.active > .g-action-state-header:not(.disabled):not([disabled]):active,
.active > li > a.g-action-state-header:not(.disabled):not([disabled]):active,
.g-action-state-header:not(.disabled):not([disabled]).pressed:active,
li > a.g-action-state-header:not(.disabled):not([disabled]).pressed:active,
.g-action-state-header:not(.disabled):not([disabled]).popover-shown:active,
li > a.g-action-state-header:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--header-background-color-start-lighten10);
  color: var(--theme-hover-text);
}
.g-action-state-header:not(.disabled):not([disabled]):focus,
li > a.g-action-state-header:not(.disabled):not([disabled]):focus {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px dashed #333;
  border-radius: none;
  border-radius: 3px;
}
@media (hover) {
  .g-action-state-header:not(.disabled):not([disabled]):hover,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover {
    color: var(--theme-hover-text);
    background-color: var(--header-background-color-start-darken10);
    border-radius: none;
  }
  .g-action-state-header:not(.disabled):not([disabled]):hover a,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover a,
  .g-action-state-header:not(.disabled):not([disabled]):hover i,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i,
  .g-action-state-header:not(.disabled):not([disabled]):hover label,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover label,
  .g-action-state-header:not(.disabled):not([disabled]):hover span,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover span {
    color: var(--theme-hover-text) !important;
  }
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active,
  li > a.g-action-state-header:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.g-action-state-header:not(.disabled):not([disabled]):active,
li > a.g-action-state-header:not(.disabled):not([disabled]):active {
  color: var(--theme-hover-text);
  background-color: var(--header-background-color-start-lighten10);
  border-radius: none;
}
.g-action-state-header:not(.disabled):not([disabled]):active a,
li > a.g-action-state-header:not(.disabled):not([disabled]):active a,
.g-action-state-header:not(.disabled):not([disabled]):active i,
li > a.g-action-state-header:not(.disabled):not([disabled]):active i,
.g-action-state-header:not(.disabled):not([disabled]):active label,
li > a.g-action-state-header:not(.disabled):not([disabled]):active label,
.g-action-state-header:not(.disabled):not([disabled]):active span,
li > a.g-action-state-header:not(.disabled):not([disabled]):active span {
  color: var(--theme-hover-text) !important;
}
.g-action-state-header.disabled,
li > a.g-action-state-header.disabled,
.g-action-state-header[disabled],
li > a.g-action-state-header[disabled] {
  background-color: transparent;
  opacity: 0.5;
}
/* Problem with backticks - https://github.com/googlefonts/noto-fonts/issues/736 */
@font-face {
  font-family: 'Noto Sans';
  src: local("Arial"), local("Sans-Serif");
  unicode-range: U+60;
}
@font-face {
  font-family: 'Noto Sans KR';
  src: local("Arial"), local("Sans-Serif");
  unicode-range: U+60;
}
@font-face {
  font-family: 'Noto Sans Condensed';
  src: local("Arial"), local("Sans-Serif");
  unicode-range: U+60;
}
/* End Problem with backticks */
@font-face {
  font-family: 'Noto Sans Condensed';
  src: url('../../Content/Themes/Shared/fonts/NotoSans-CondensedBold.6H3DGo7wIlucwm3bXdkkv2aajtw.woff2') format('woff2'), url('../../Content/Themes/Shared/fonts/NotoSans-CondensedBold.3_UdNFFtZ4xmC6RLzey1gUrs6os.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Condensed';
  src: url('../../Content/Themes/Shared/fonts/NotoSans-Condensed.9CtZHjwxsd_6HpaFHuPbCG8QNWM.woff2') format('woff2'), url('../../Content/Themes/Shared/fonts/NotoSans-Condensed.Nhe-theHinQL11jHLrYqdcZfzsU.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Condensed';
  src: url('../../Content/Themes/Shared/fonts/NotoSans-CondensedItalic.A6VYQG5ztx23WbqS_4WE0LjSYYI.woff2') format('woff2'), url('../../Content/Themes/Shared/fonts/NotoSans-CondensedItalic.CrCl1jQ1OE_2O4BFi_C0vKStKgw.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Noto Sans Condensed';
  src: url('../../Content/Themes/Shared/fonts/NotoSans-CondensedBoldItalic.tlE9kpZAIyqDokXUUB5POC5cPlk.woff2') format('woff2'), url('../../Content/Themes/Shared/fonts/NotoSans-CondensedBoldItalic.nKFl5tMSSJfIlHwvo8gQuZT3M_A.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
body {
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
[data-font-weight="SemiBold"] {
  font-weight: bold;
}
[data-font-weight="Bold"] {
  font-weight: bold;
}
[data-textwrapping="NoWrap"] {
  white-space: nowrap;
}
[data-textwrapping="Wrap"] {
  white-space: normal;
}
[data-textwrapping="WrapWithOverflow"] {
  white-space: normal;
}
html.no-touch .g-tablet-mode-btn,
.dropdown-backdrop {
  display: none;
}
.g-centered {
  position: absolute;
  top: 50%;
  left: 50%;
}
.g-hidden {
  display: none !important;
}
.g-label-tester {
  max-width: initial;
}
.g-offscreen {
  position: absolute;
  top: -9999em;
  left: -9999em;
}
.g-uneditable {
  color: var(--input-control-text-color);
  border-color: #d3d6d9;
}
.g-uneditable.input-group-addon {
  background-color: transparent;
}
.hideWithSpace {
  visibility: hidden;
}
.g-grip {
  width: 1px;
}
.g-grip li {
  width: 1px;
  height: 3px;
  margin-bottom: 2px;
  background: var(--theme-field-title);
}
.g-sidebar-no-items {
  margin: 8px 28px;
  font-size: 11px;
  color: var(--theme-field-title);
  text-align: center;
}
.g-flat-button {
  padding: 5px 10px;
  font-size: 16px;
  color: #FFFFFF;
  border: none;
  background: var(--theme-value-text);
}
.g-flat-button span {
  padding-left: 6px;
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-size: 14px;
}
.g-group-input.form-control {
  border-radius: 0;
  height: 42px;
}
.g-group-input.form-control[type="text"] {
  padding-top: 18px !important;
}
.g-group-input.form-control.g-top-left-input {
  border-top-left-radius: 5px;
}
.g-group-input.form-control.g-top-right-input {
  border-top-right-radius: 5px;
}
.g-group-input.form-control.g-bottom-left-input {
  border-bottom-left-radius: 5px;
}
.g-group-input.form-control.g-bottom-right-input {
  border-bottom-right-radius: 5px;
}
.g-header-button {
  display: block;
  padding: 5px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
}
.g-header-button:hover,
.g-header-button:focus,
.g-header-button:active,
.dropdown.open .g-header-button,
.pressed.g-header-button {
  text-decoration: none;
}
.alert-count {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  width: 14px;
  height: 14px;
  padding: 2px;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
}
.g-dynamic-menu-list .alert-count {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -7px;
  color: #fff;
}
.error-count {
  background-color: #d90000;
}
.warning-count {
  background-color: #ffaa33;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  position: static !important;
  border: 0;
  box-shadow: none;
}
.dropdown-submenu .pull-left {
  float: none;
}
.dropdown-submenu .pull-left > .dropdown-menu {
  left: -100%;
  border-radius: 6px 0 6px 6px;
}
.g-anchor-container {
  position: absolute;
}
.g-anchored {
  position: absolute;
  width: 100%;
}
.g-anchored > .gwButton button {
  min-width: 94px;
  padding: 0;
  margin: 3px;
  width: calc(100% -  6px);
  height: calc(100% -  6px);
}
.g-anchored > .gwLabel {
  max-width: 100%;
  white-space: normal;
}
.gwErrorLabel-wrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  border: 1px dashed #b94c4a;
}
.gwErrorLabel-wrapper .gwErrorLabel {
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.gwErrorLabel-wrapper .gwErrorLabel > p {
  font-size: 12px;
  color: #ff0000;
  max-height: 100%;
  margin-bottom: 0px;
  white-space: pre-wrap;
}
.g-heading {
  position: absolute;
  right: 0;
  left: 30px;
  z-index: 900;
  min-height: 40px;
  margin-top: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}
.g-heading .navbar {
  position: absolute;
  top: 0;
  right: 0;
  margin-bottom: 0;
}
.g-heading .navbar.pull-right {
  margin-right: 10px;
}
.g-heading .navbar .btn-group > .btn:first-child,
.g-heading .navbar li button {
  margin-top: 5px;
  overflow: visible;
}
.g-heading .navbar li button {
  padding: 2px 10px;
  margin-right: 3px;
  overflow: visible;
}
.g-heading .navbar .dropdown-menu {
  right: 0;
  left: auto;
  padding: 10px 15px;
}
.g-heading .navbar .dropdown-menu.pull-left {
  right: auto;
}
.g-heading .navbar .dropdown-menu h3 {
  margin: 0 0 10px;
  line-height: 30px;
}
.g-heading .navbar .dropdown-menu > ul > li > a {
  display: block;
  padding: 3px 20px 3px 3px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.g-heading .navbar .btn-group .dropdown-menu {
  padding: 10px 0;
  text-align: left;
  text-shadow: 0 0;
}
.g-heading .navbar .dropdown-submenu > .dropdown-menu {
  left: -106%;
  border-radius: 6px 0 6px 6px;
}
.g-heading .navbar .nav > li:not(.divider-vertical) {
  margin-right: 2px;
}
.g-heading .navbar .nav > li > a.g-has-alerts {
  color: #fff;
  background: none;
}
.g-heading .navbar .nav > li > a.g-has-alerts > img,
.g-heading .navbar .nav > li > a.g-has-alerts i {
  color: #fff;
}
.g-header1 {
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-weight: Bold;
  color: var(--rich-text-editor-header1-text-color);
  font-size: 15px;
}
.g-header2 {
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-weight: Normal;
  color: var(--rich-text-editor-header2-text-color);
  font-size: 14px;
}
.g-header3 {
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-weight: Normal;
  color: #003479;
  font-size: 13px;
}
.g-default {
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-weight: Normal;
  color: var(--body-text-color);
  font-size: 13px;
  background-color: var(--body-background-color-end);
}
.gwLabel-wrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
.gwLabel {
  min-width: 100%;
  display: table;
  table-layout: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.gwLabel > p {
  margin: 0px;
  width: 100%;
  height: 100%;
  display: table-cell;
  white-space: pre-wrap;
}
.gwNote-wrapper {
  height: 100%;
  overflow: auto;
  line-height: 18px;
}
.notification-label {
  color: white;
}
.g-information-label {
  background: #3a87ad;
}
.g-success-label {
  background: #58ba00;
}
.g-warning-label {
  background: #ffc825;
}
.g-message-error-label {
  background: #ffaa33;
}
.g-error-label {
  background: #d90000;
}
.g-extra-small-text {
  font-size: 12px;
}
.g-small-text {
  font-size: 13px;
}
.g-medium-text {
  font-size: 15px;
}
.g-large-text {
  font-size: 22px;
}
.g-extra-large-text {
  font-size: 35px;
}
.g-extra-extra-large-text {
  font-size: 55px;
}
.g-small-padding {
  padding: 0px 4px;
}
.g-medium-padding {
  padding: 0px 10px;
}
.g-large-padding {
  padding: 0px 18px;
}
.icons-color {
  color: var(--icons-color);
}
.gwMessageBar {
  display: flex;
  width: 100%;
  height: 32px;
  padding: 5px;
  line-height: 22px;
  color: #fff;
  background-color: var(--input-control-label-color);
  border-bottom: 1px solid var(--sidebar-panel-border-color);
}
.gwMessageBar small {
  text-transform: none;
}
.gwMessageBar .g-kg-actions-container {
  flex: 1 0 82px;
  height: auto;
}
.gwMessageBar .g-kg-action {
  margin: 0;
}
.gwMessageBar-title {
  display: inline-block;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwMessageBar,
.gwMessageBar .g-kg-action a,
.gwMessageBar .g-kg-action a:hover {
  color: #fff;
}
.g-fade {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.25s, opacity 0.25s linear;
  -webkit-transition: visibility 0.25s, opacity 0.25s linear;
  -o-transition: visibility 0.25s, opacity 0.25s linear;
}
.g-in {
  opacity: 1;
  visibility: visible;
}
.g-fade.in {
  opacity: 1;
  visibility: visible;
}
.g-fade.disabled {
  pointer-events: none;
}
.dropdown > .dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.25s, opacity 0.25s linear;
  -webkit-transition: visibility 0.25s, opacity 0.25s linear;
  -o-transition: visibility 0.25s, opacity 0.25s linear;
  display: block !important;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 5px;
}
.dropdown > .dropdown-menu > .dropdown-submenu,
.g-grid-actions-dropdown.dropdown-submenu,
.g-dynamic-menu-list > .dropdown-submenu {
  margin-left: 5px;
  margin-right: 5px;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove) {
  margin-left: 5px !important;
}
.dropdown-submenu > .dropdown-menu {
  padding-left: 15px;
}
.popover-content > .gwDynamicMenu,
.dropdown.open > .dropdown-menu {
  border-radius: 5px;
  opacity: 1;
  visibility: visible;
}
.popover-content > .gwDynamicMenu {
  max-width: calc(100vw - 22px);
}
.popover-content > .gwDynamicMenu > ul {
  padding-left: 0;
}
.popover-content > .gwDynamicMenu > ul > li > a > input {
  margin: 0 6px 0 0;
}
.g-favorites-popover .g-jump-item > a,
.g-recents-popover .g-jump-item > a {
  padding-left: 0;
}
.hideable-dropdown-menu > .popover-content {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.g-grid-action-popover.hide-pin > .popover-content .icon-pin,
.g-grid-action-popover.hide-pin > .popover-content .icon-unpin {
  display: none;
}
.g-grid-action-popover.hide-pin > .popover-content .g-menu-item-icon-container .icon-pin,
.g-grid-action-popover.hide-pin > .popover-content .g-menu-item-icon-container .icon-unpin {
  display: none;
}
.g-header-popover .popover-content {
  padding-left: 0;
  padding-right: 0;
  -webkit-transform: translate3d(0, 0, 0);
}
.g-header-popover .g-dynamic-menu-list {
  padding-left: 0;
}
.g-dynamic-menu-list .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu-shared,
.g-dynamic-menu-list {
  -webkit-transform: translate3d(0, 0, 0);
}
.dropdown-menu-shared.pull-left,
.g-dynamic-menu-list.pull-left {
  right: auto;
}
.dropdown-menu-shared.pull-right,
.g-dynamic-menu-list.pull-right {
  left: auto;
}
.dropdown-menu-shared .divider,
.g-dynamic-menu-list .divider,
.dropdown-menu-shared .divider:hover,
.g-dynamic-menu-list .divider:hover {
  margin: 5px 0;
  background-color: #aaa;
}
.dropdown-menu-shared .dropdown-header,
.g-dynamic-menu-list .dropdown-header {
  font-size: 13px;
  color: var(--theme-default-button);
  padding: 2px 8px;
  height: auto;
  line-height: 28px;
  border-top: 1px solid #aaaaaa;
  cursor: default;
  margin-bottom: 0px;
}
.dropdown-menu-shared .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header),
.g-dynamic-menu-list .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-menu-shared > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header),
.g-dynamic-menu-list > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
  margin-left: 5px;
  margin-right: 5px;
}
.dropdown-menu-shared .g-loggedin-info-section + .dropdown-header,
.g-dynamic-menu-list .g-loggedin-info-section + .dropdown-header {
  border-top: 0;
}
.dropdown-menu-shared .g-loggedin-info-section,
.g-dynamic-menu-list .g-loggedin-info-section {
  border-bottom: 1px solid #aaa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.dropdown-menu-shared > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.alert):not(.dropdown-header),
.g-dynamic-menu-list > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.alert):not(.dropdown-header) {
  margin-bottom: 5px;
}
.dropdown-menu-shared > li.g-jump-add-remove > a,
.g-dynamic-menu-list > li.g-jump-add-remove > a {
  padding: 0;
}
.dropdown-menu-shared > li > a,
.g-dynamic-menu-list > li > a,
.dropdown-menu-shared > li > div > a,
.g-dynamic-menu-list > li > div > a,
.dropdown-menu-shared > li > span,
.g-dynamic-menu-list > li > span,
.dropdown-menu-shared > span,
.g-dynamic-menu-list > span,
.dropdown-menu-shared > li > label,
.g-dynamic-menu-list > li > label,
.dropdown-menu-shared > li.checkbox-item,
.g-dynamic-menu-list > li.checkbox-item,
.dropdown-menu-shared > li > .g-menu-item--colorscheme,
.g-dynamic-menu-list > li > .g-menu-item--colorscheme {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  clear: both;
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  color: var(--theme-general-text);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  position: relative;
}
.dropdown-menu-shared > li > a.g-pin-target,
.g-dynamic-menu-list > li > a.g-pin-target,
.dropdown-menu-shared > li > div > a.g-pin-target,
.g-dynamic-menu-list > li > div > a.g-pin-target,
.dropdown-menu-shared > li > span.g-pin-target,
.g-dynamic-menu-list > li > span.g-pin-target,
.dropdown-menu-shared > span.g-pin-target,
.g-dynamic-menu-list > span.g-pin-target,
.dropdown-menu-shared > li > label.g-pin-target,
.g-dynamic-menu-list > li > label.g-pin-target,
.dropdown-menu-shared > li.checkbox-item.g-pin-target,
.g-dynamic-menu-list > li.checkbox-item.g-pin-target,
.dropdown-menu-shared > li > .g-menu-item--colorscheme.g-pin-target,
.g-dynamic-menu-list > li > .g-menu-item--colorscheme.g-pin-target {
  padding-right: 0;
}
.dropdown-menu-shared > li > a .icon-spinner.icon-spin,
.g-dynamic-menu-list > li > a .icon-spinner.icon-spin,
.dropdown-menu-shared > li > div > a .icon-spinner.icon-spin,
.g-dynamic-menu-list > li > div > a .icon-spinner.icon-spin,
.dropdown-menu-shared > li > span .icon-spinner.icon-spin,
.g-dynamic-menu-list > li > span .icon-spinner.icon-spin,
.dropdown-menu-shared > span .icon-spinner.icon-spin,
.g-dynamic-menu-list > span .icon-spinner.icon-spin,
.dropdown-menu-shared > li > label .icon-spinner.icon-spin,
.g-dynamic-menu-list > li > label .icon-spinner.icon-spin,
.dropdown-menu-shared > li.checkbox-item .icon-spinner.icon-spin,
.g-dynamic-menu-list > li.checkbox-item .icon-spinner.icon-spin,
.dropdown-menu-shared > li > .g-menu-item--colorscheme .icon-spinner.icon-spin,
.g-dynamic-menu-list > li > .g-menu-item--colorscheme .icon-spinner.icon-spin {
  margin-right: 5px;
}
.dropdown-menu-shared > li > label,
.g-dynamic-menu-list > li > label {
  margin-bottom: 0;
}
.dropdown-menu-shared > li > label > input[type="radio"],
.g-dynamic-menu-list > li > label > input[type="radio"] {
  margin-top: 0;
  margin-right: 6px;
  cursor: pointer;
}
.dropdown-menu-shared > li > a > input[type="radio"],
.g-dynamic-menu-list > li > a > input[type="radio"] {
  margin: 0;
  line-height: normal;
  height: calc(100% - 1em);
}
.dropdown-menu-shared span,
.g-dynamic-menu-list span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-menu-shared > li.checkbox-item,
.g-dynamic-menu-list > li.checkbox-item {
  padding-left: 9px;
  overflow: hidden;
}
.dropdown-menu-shared > li.checkbox-item > label,
.g-dynamic-menu-list > li.checkbox-item > label {
  font-size: 13px;
}
.dropdown-menu-shared > li.checkbox-item > label > span,
.g-dynamic-menu-list > li.checkbox-item > label > span {
  line-height: normal;
}
.dropdown-menu-shared > li .g-jump-item > a,
.g-dynamic-menu-list > li .g-jump-item > a {
  padding: 0 2px;
}
.dropdown-menu-shared > li > a,
.g-dynamic-menu-list > li > a,
.dropdown-menu-shared > li.checkbox-item,
.g-dynamic-menu-list > li.checkbox-item,
.dropdown-menu-shared > li > .g-dropdown-list-item,
.g-dynamic-menu-list > li > .g-dropdown-list-item,
.dropdown-menu-shared > li > div > a,
.g-dynamic-menu-list > li > div > a {
  display: flex;
  align-items: center;
}
.dropdown-menu-shared > li > span,
.g-dynamic-menu-list > li > span {
  display: flex;
  align-items: center;
}
.dropdown-menu-shared > li .g-pin-target span,
.g-dynamic-menu-list > li .g-pin-target span,
.dropdown-menu-shared > li .g-no-pin span,
.g-dynamic-menu-list > li .g-no-pin span,
.dropdown-menu-shared > li .g-jump-item .g-menu-item-target,
.g-dynamic-menu-list > li .g-jump-item .g-menu-item-target {
  padding-right: 10px;
}
.dropdown-menu-shared:not(.g-display-option) > li:last-child,
.g-dynamic-menu-list:not(.g-display-option) > li:last-child,
.dropdown-menu-shared:not(.g-display-option) > li:last-child > a,
.g-dynamic-menu-list:not(.g-display-option) > li:last-child > a,
.dropdown-menu-shared:not(.g-display-option) > li:last-child > span,
.g-dynamic-menu-list:not(.g-display-option) > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.dropdown-menu-shared:not(.g-display-option) > li:first-child,
.g-dynamic-menu-list:not(.g-display-option) > li:first-child,
.dropdown-menu-shared:not(.g-display-option) > li:first-child > a,
.g-dynamic-menu-list:not(.g-display-option) > li:first-child > a,
.dropdown-menu-shared:not(.g-display-option) > li:first-child > span,
.g-dynamic-menu-list:not(.g-display-option) > li:first-child > span {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.dropdown-menu-shared:not(.g-display-option) > li:last-child,
.g-dynamic-menu-list:not(.g-display-option) > li:last-child {
  margin-bottom: 5px;
}
.dropdown-menu-shared:not(.g-display-option) > li:first-child:not(.g-loggedin-info-section):not(.dropdown-header),
.g-dynamic-menu-list:not(.g-display-option) > li:first-child:not(.g-loggedin-info-section):not(.dropdown-header),
.dropdown-menu-shared:not(.g-display-option) > li:nth-child(2):not(.dropdown-header):not(.alert),
.g-dynamic-menu-list:not(.g-display-option) > li:nth-child(2):not(.dropdown-header):not(.alert) {
  margin-top: 5px;
}
.dropdown-menu-shared.g-dynamic-menu-list > li.doc-menu-item,
.g-dynamic-menu-list.g-dynamic-menu-list > li.doc-menu-item {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-menu-shared.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation,
.g-dynamic-menu-list.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation {
  display: inline-flex;
  overflow-y: hidden;
  width: 100%;
}
.dropdown-menu-shared.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation label,
.g-dynamic-menu-list.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation label {
  margin: 0;
}
.dropdown-menu-shared label.dropdown-header,
.g-dynamic-menu-list label.dropdown-header,
.dropdown-menu-shared li.dropdown-header,
.g-dynamic-menu-list li.dropdown-header {
  cursor: default;
}
.dropdown-menu-shared label a.disabled,
.g-dynamic-menu-list label a.disabled,
.dropdown-menu-shared li a.disabled,
.g-dynamic-menu-list li a.disabled,
.dropdown-menu-shared label a .disabled,
.g-dynamic-menu-list label a .disabled,
.dropdown-menu-shared li a .disabled,
.g-dynamic-menu-list li a .disabled {
  cursor: default;
}
.dropdown-menu-shared label.disabled,
.g-dynamic-menu-list label.disabled,
.dropdown-menu-shared li.disabled,
.g-dynamic-menu-list li.disabled,
.dropdown-menu-shared label .disabled,
.g-dynamic-menu-list label .disabled,
.dropdown-menu-shared li .disabled,
.g-dynamic-menu-list li .disabled {
  color: #999;
  cursor: text;
}
.dropdown-menu-shared label.disabled:hover,
.g-dynamic-menu-list label.disabled:hover,
.dropdown-menu-shared li.disabled:hover,
.g-dynamic-menu-list li.disabled:hover,
.dropdown-menu-shared label .disabled:hover,
.g-dynamic-menu-list label .disabled:hover,
.dropdown-menu-shared li .disabled:hover,
.g-dynamic-menu-list li .disabled:hover,
.dropdown-menu-shared label.disabled:focus,
.g-dynamic-menu-list label.disabled:focus,
.dropdown-menu-shared li.disabled:focus,
.g-dynamic-menu-list li.disabled:focus,
.dropdown-menu-shared label .disabled:focus,
.g-dynamic-menu-list label .disabled:focus,
.dropdown-menu-shared li .disabled:focus,
.g-dynamic-menu-list li .disabled:focus {
  background-color: transparent;
  color: #999;
}
.dropdown-menu-shared label.disabled.g-error-text,
.g-dynamic-menu-list label.disabled.g-error-text,
.dropdown-menu-shared li.disabled.g-error-text,
.g-dynamic-menu-list li.disabled.g-error-text,
.dropdown-menu-shared label .disabled.g-error-text,
.g-dynamic-menu-list label .disabled.g-error-text,
.dropdown-menu-shared li .disabled.g-error-text,
.g-dynamic-menu-list li .disabled.g-error-text {
  color: #ff0000;
}
.dropdown-menu-shared label.disabled.g-error-actions,
.g-dynamic-menu-list label.disabled.g-error-actions,
.dropdown-menu-shared li.disabled.g-error-actions,
.g-dynamic-menu-list li.disabled.g-error-actions,
.dropdown-menu-shared label .disabled.g-error-actions,
.g-dynamic-menu-list label .disabled.g-error-actions,
.dropdown-menu-shared li .disabled.g-error-actions,
.g-dynamic-menu-list li .disabled.g-error-actions {
  display: block;
  white-space: normal;
  word-break: break-word;
  height: auto;
}
.dropdown-menu-shared .dropdown-submenu > .dropdown-menu,
.g-dynamic-menu-list .dropdown-submenu > .dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 0;
}
.dropdown-menu-shared .dropdown-submenu > .dropdown-menu > li,
.g-dynamic-menu-list .dropdown-submenu > .dropdown-menu > li {
  margin-top: 0 !important;
}
.dropdown-menu-shared .dropdown-submenu > .dropdown-menu > li:last-child,
.g-dynamic-menu-list .dropdown-submenu > .dropdown-menu > li:last-child {
  margin-bottom: 0;
}
.dropdown-menu-shared .dropdown-submenu > .dropdown-menu > li > a input,
.g-dynamic-menu-list .dropdown-submenu > .dropdown-menu > li > a input {
  margin: 0 6px 0 0;
}
.dropdown-menu-shared .dropdown-submenu i.chevron,
.g-dynamic-menu-list .dropdown-submenu i.chevron {
  margin: -2px 6px 0 0;
}
.dropdown-menu-shared .dropdown-submenu i.chevron.icon-chevron-down,
.g-dynamic-menu-list .dropdown-submenu i.chevron.icon-chevron-down {
  display: inline;
}
.dropdown-menu-shared .dropdown-submenu.open > a > i.icon-chevron-down,
.g-dynamic-menu-list .dropdown-submenu.open > a > i.icon-chevron-down {
  transform: rotate(180deg) translateY(-3px);
}
.navbar .nav .dropdown.open:before,
.g-modal-header-buttonpane .dropdown.open:before,
.g-popout-header-buttonpane .dropdown.open:before,
.navbar .nav .dropdown.open:after,
.g-modal-header-buttonpane .dropdown.open:after,
.g-popout-header-buttonpane .dropdown.open:after {
  position: absolute;
  right: calc(50% - 6px);
  z-index: 1001;
  display: block;
  border: 6px solid transparent;
  border-top: none;
  content: '';
}
.navbar .nav .dropdown.open:before,
.g-modal-header-buttonpane .dropdown.open:before,
.g-popout-header-buttonpane .dropdown.open:before {
  bottom: -6px;
  border-bottom-color: #999;
}
.navbar .nav .dropdown.open.more-dropdown:after,
.g-modal-header-buttonpane .dropdown.open.more-dropdown:after,
.g-popout-header-buttonpane .dropdown.open.more-dropdown:after {
  border-bottom-color: #f2f2f2;
}
.navbar .nav .dropdown.open:after,
.g-modal-header-buttonpane .dropdown.open:after,
.g-popout-header-buttonpane .dropdown.open:after {
  bottom: -7px;
  border-bottom-color: #fff;
}
.navbar .nav .g-tasktray-actions.g-mobile-only,
.g-modal-header-buttonpane .g-tasktray-actions.g-mobile-only,
.g-popout-header-buttonpane .g-tasktray-actions.g-mobile-only {
  flex-direction: column;
  justify-content: center;
}
.navbar .nav .g-tasktray-actions.g-mobile-only.hide .dropdown.open,
.g-modal-header-buttonpane .g-tasktray-actions.g-mobile-only.hide .dropdown.open,
.g-popout-header-buttonpane .g-tasktray-actions.g-mobile-only.hide .dropdown.open {
  display: none;
}
.g-watermark {
  color: #cccccc;
}
.g-pageload-error {
  padding: 10px;
}
.g-menu-item--colorscheme__actions {
  display: flex;
  align-items: center;
}
.g-menu-item--colorscheme__selector {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-menu-item-icon-container,
.open > .g-menu-item-target > .g-menu-item-icon-container {
  margin-left: auto;
}
.g-menu-item-icon-container .g-menu-item-unclickable-persistent-icon,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-unclickable-persistent-icon,
.g-menu-item-icon-container .g-menu-item-persistent-icon,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-persistent-icon,
.g-menu-item-icon-container .g-menu-item-icon,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-icon {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-menu-item-icon-container .g-menu-item-persistent-icon,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-persistent-icon,
.g-menu-item-icon-container .g-menu-item-icon,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-menu-item-icon-container .g-menu-item-persistent-icon:focus,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-persistent-icon:focus,
.g-menu-item-icon-container .g-menu-item-icon:focus,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-menu-item-icon-container .g-menu-item-persistent-icon:hover,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-persistent-icon:hover,
.g-menu-item-icon-container .g-menu-item-icon:hover,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-menu-item-icon-container .g-menu-item-persistent-icon:active,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-persistent-icon:active,
.g-menu-item-icon-container .g-menu-item-icon:active,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-menu-item-icon-container .g-menu-item-icon,
.open > .g-menu-item-target > .g-menu-item-icon-container .g-menu-item-icon {
  opacity: 0;
}
.g-menu-item-icon {
  color: var(--theme-icons);
}
.g-menu-item-icon:not(.g-in) {
  opacity: 0;
}
.g-menu-item-unclickable-persistent-icon {
  cursor: default;
}
.g-menu-item-persistent-icon,
.g-menu-item-icon {
  cursor: pointer;
}
.g-menu-item-target {
  position: relative;
}
.g-menu-item-target > .g-menu-item-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-menu-item-target > .g-menu-item-text.g-published,
.g-menu-item-target > .g-menu-item-text.g-with-persistent-icon {
  width: calc(100% - 21px);
}
.g-menu-item-target:hover > .g-menu-item-text {
  width: calc(100% - 21px);
}
.g-menu-item-target:hover > .g-menu-item-text.g-published {
  width: calc(100% - 45px);
}
.g-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 1px;
}
.g-divider:before {
  height: 20px;
  border-left: 1px solid var(--theme-general-text);
  content: ' ';
  opacity: 0.5;
  left: 0;
}
.btn-primary + .g-divider {
  background-color: var(--primary-button-background-color-start);
}
.btn-primary + .g-divider:before {
  border-color: #fff;
}
.gwPinnedItems > .gwPinnedItem-hidden-button {
  position: absolute;
  top: -100px;
  left: -500px;
  visibility: hidden;
}
.g-foreground-theme-background {
  color: var(--theme-background) !important;
}
.g-foreground-theme-general-text {
  color: var(--theme-general-text) !important;
}
.g-foreground-theme-icons {
  color: var(--theme-icons) !important;
}
.g-foreground-theme-field-title-text {
  color: var(--theme-field-title) !important;
}
.g-foreground-theme-value-text {
  color: var(--theme-value-text) !important;
}
.g-foreground-theme-default-button {
  color: var(--theme-default-button) !important;
}
.g-foreground-theme-button {
  color: var(--theme-button) !important;
}
.g-foreground-theme-default-button-text {
  color: var(--theme-default-button-text) !important;
}
.g-foreground-theme-hover {
  color: var(--theme-hover) !important;
}
.g-foreground-theme-hover-text {
  color: var(--theme-hover-text) !important;
}
.g-foreground-theme-field-control {
  color: var(--theme-field-control) !important;
}
.g-background-theme-background {
  background: var(--theme-background) !important;
  border-color: var(--theme-background) !important;
}
.g-background-theme-background:hover {
  border-color: var(--theme-background-darken15) !important;
}
.g-background-theme-general-text {
  background: var(--theme-general-text) !important;
  border-color: var(--theme-general-text) !important;
}
.g-background-theme-general-text:hover {
  border-color: var(--theme-general-text-darken15) !important;
}
.g-background-theme-icons {
  background: var(--theme-icons) !important;
  border-color: var(--theme-icons) !important;
}
.g-background-theme-icons:hover {
  border-color: var(--theme-icons-darken15) !important;
}
.g-background-theme-field-title-text {
  background: var(--theme-field-title) !important;
  border-color: var(--theme-field-title) !important;
}
.g-background-theme-field-title-text:hover {
  border-color: var(--theme-field-title-darken15) !important;
}
.g-background-theme-value-text {
  background: var(--theme-value-text) !important;
  border-color: var(--theme-value-text) !important;
}
.g-background-theme-value-text:hover {
  border-color: var(--theme-value-text-darken15) !important;
}
.g-background-theme-default-button {
  background: var(--theme-default-button) !important;
  border-color: var(--theme-default-button) !important;
}
.g-background-theme-default-button:hover {
  border-color: var(--theme-default-button-darken15) !important;
}
.g-background-theme-button {
  background: var(--theme-button) !important;
  border-color: var(--theme-button) !important;
}
.g-background-theme-button:hover {
  border-color: var(--theme-button-darken15) !important;
}
.g-background-theme-default-button-text {
  background: var(--theme-default-button-text) !important;
  border-color: var(--theme-default-button-text) !important;
}
.g-background-theme-default-button-text:hover {
  border-color: var(--theme-default-button-text-darken15) !important;
}
.g-background-theme-hover {
  background: var(--theme-hover) !important;
  border-color: var(--theme-hover) !important;
}
.g-background-theme-hover:hover {
  border-color: var(--theme-hover-darken15) !important;
}
.g-background-theme-hover-text {
  background: var(--theme-hover-text) !important;
  border-color: var(--theme-hover-text) !important;
}
.g-background-theme-hover-text:hover {
  border-color: var(--theme-hover-text-darken15) !important;
}
.g-background-theme-field-control {
  background: var(--theme-field-control) !important;
  border-color: var(--theme-field-control) !important;
}
.g-background-theme-field-control:hover {
  border-color: var(--theme-field-control-darken15) !important;
}
.icon-flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.icon-flip-vertical {
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.g-body-dragging {
  pointer-events: none;
}
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font-size: 14px;
}
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* SHARED */
.g-header {
  display: flex;
  width: 100%;
  height: 38px;
  flex: 1 0 38px;
  position: relative;
  z-index: 12;
}
.g-header:empty {
  background-color: var(--theme-default-button);
}
.g-header .g-header-contents {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 112;
  background-color: var(--theme-default-button);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 38px;
}
.g-header .g-header-title {
  display: flex;
  align-items: center;
  height: 100%;
  color: #FFFFFF;
  font-size: 22px;
  padding: 0;
  min-width: 0;
  flex: 1 0 0%;
  overflow: hidden;
}
.g-header .g-header-title .g-logo {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding: 0 6px;
}
.g-header .g-header-title .g-logo + .g-tagline {
  margin-left: 2px;
}
.g-header .g-header-title .g-tagline {
  margin: 0 0 0 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 34px;
}
.g-header .g-header-title .g-tagline .g-tagline-text-container {
  display: flex;
}
.g-header .g-header-title .g-title-name {
  max-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-header .g-header-menu {
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
}
.g-header .g-header-menu .nav {
  height: 100%;
  flex: 0 0 auto;
}
.g-header .g-header-menu .nav:first-child {
  margin-left: auto;
}
.g-header .g-header-menu .nav.gwPinnedItems {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}
.g-header .g-header-menu .nav > li {
  height: 100%;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.g-header .g-header-menu .nav > li > a {
  height: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 0;
}
.g-header .g-header-menu .nav > li > a > i {
  font-size: 14px;
  line-height: 24px;
  margin: 0 3px;
  text-align: center;
  float: left;
}
.g-header .g-header-menu .nav > li > a .g-display-option-text,
.g-header .g-header-menu .nav > li > a .g-not-loggedin-display {
  line-height: 24px;
  font-size: 12px;
  float: left;
  margin: 0 3px;
  font-weight: normal;
}
.g-header .g-header-menu .nav > li > .dropdown-menu {
  margin: 6px 0 0;
}
.g-header .g-header-menu .nav > li .g-divider {
  width: 10px;
}
.g-header .g-header-menu .nav > li.more-dropdown > .dropdown-menu:before {
  border-bottom-color: #333;
}
.g-header .g-header-menu .nav > li.more-dropdown > .dropdown-menu:after {
  border-bottom-color: #f2f2f2;
}
.g-header .g-header-menu .nav > li.g-overflow-button > a {
  font-size: 22px;
  line-height: 25px;
}
.g-header .g-header-menu .nav > .dropdown > .dropdown-menu {
  right: 0;
  left: auto;
  margin-right: calc(50% - 20px);
}
.g-header .g-header-menu .nav-pills > li + li,
.g-header .g-header-menu .nav-pills + .nav-pills {
  margin-left: 0;
}
.g-header.g-header-light {
  background-color: var(--popup-header-background-color-start);
  box-shadow: none;
  border-bottom: 1px solid var(--grid-header-border-color);
}
.g-header.g-header-light .g-header-title {
  color: var(--theme-value-text);
  flex: 0 1 auto;
  font-weight: 500;
  margin: 0 0 0 8px;
}
.g-header.g-header-light .g-header-menu {
  flex-shrink: 0;
}
.g-header.g-header-light .g-header-menu .nav {
  padding-left: 0px;
}
.g-header.g-header-light .g-header-menu .nav > li > a {
  background-color: transparent;
  color: var(--theme-icons);
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
  font-weight: 500;
}
.g-header.g-header-light .g-header-menu .nav > li > a:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-header.g-header-light .g-header-menu .nav > li > a:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-header.g-header-light .g-header-menu .nav > li > a:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-header.g-header-light .g-header-menu .nav > li > a > i {
  color: var(--theme-icons);
}
.g-header .g-homepage {
  visibility: Visible;
}
header .g-header .g-header-title {
  flex: 0 1 auto;
}
header .g-header .g-header-menu {
  flex: 1 0 auto;
  margin-left: 0;
}
.nav.buttons-only .g-display-option-text,
.nav.text-only .g-display-option-button:not(.g-header-item-persistent-icon) {
  display: none;
}
.nav.buttons-only i {
  min-width: 14px;
}
.nav.buttons-only > li > a > span.alert-count.error-count {
  display: block !important;
}
.g-header-popover .g-loggedin-info-section {
  background-color: var(--theme-background);
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.g-header-popover .g-loggedin-info-section .loggedin-info,
.g-header-popover .g-loggedin-info-section .loggedin-info:hover {
  font-size: 11px;
  color: var(--theme-field-title);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-overflow: initial;
  overflow-y: hidden;
  cursor: default;
}
.g-header-popover .g-loggedin-info-section .loggedin-info .loggedin-user,
.g-header-popover .g-loggedin-info-section .loggedin-info:hover .loggedin-user {
  font-size: 12px;
}
.g-header-popover .g-loggedin-info-section .loggedin-img-section {
  margin: 0 10px 0 10px;
  cursor: default;
  z-index: 9001;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}
.g-header-popover .g-loggedin-info-section .loggedin-img-section .loggedin-img {
  max-width: 40px;
}
.g-header-popover h3 {
  margin: 0 0 10px;
  line-height: 30px;
}
.g-saving-indicator-container .g-header-button {
  cursor: default;
}
.g-saving-indicator-container .g-header-button:hover {
  box-shadow: none;
}
.g-index-count {
  color: #FFFFFF;
  font-size: 12px;
  padding: 0 2px;
}
.g-logo-img {
  font-size: 30px;
}
#g-content {
  width: 100%;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  border-top: none;
  display: flex;
}
#g-content .g-page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}
#g-content .g-page.g-helppage {
  padding: 0;
}
#g-content .g-page.g-helppage .g-popout-wrap {
  height: 100%;
  padding-bottom: 38px;
  overflow: hidden;
}
#g-content .g-page.g-helppage .g-popout-wrap .g-popout-body-wrap {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-top: 38px;
  overflow-y: scroll;
}
#g-content .g-page.g-helppage .g-popout-wrap .g-popout-body-wrap img {
  height: auto;
  max-width: 100%;
}
.g-app-container {
  height: 100%;
  overflow: auto;
}
.g-app-container .g-page-shell {
  overflow: inherit;
}
.g-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.g-loading.g-page-loading {
  font-size: 20px;
}
.g-page-popout .g-page-shell {
  padding: 0;
  overflow: hidden;
}
.g-popout-body {
  padding: 10px;
  overflow: auto;
}
.g-popout-body > .g-modal-loading-indicator {
  height: 100%;
}
.g-popout-body:not(.g-popout-body-no-footer) {
  height: calc(100% - 82px);
}
.g-popout-body.g-popout-body-no-footer {
  height: calc(100% - 38px);
}
header {
  position: relative;
}
#global-loading-indicator {
  display: flex;
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: var(--icons-color-lighten50);
  overflow: hidden;
  z-index: 13;
}
#global-loading-indicator > div {
  padding: 0;
  margin: 0;
  content: '';
  background-color: var(--icons-color);
  width: 200px;
  height: 100%;
  will-change: transform;
  z-index: 1001;
  left: -200px;
  animation: indicatorBarMovement 2s cubic-bezier(0.97, 0.4, 0.99, 0.93) infinite;
}
@keyframes indicatorBarMovement {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(8000px);
  }
}
.g-arrow {
  width: 10px;
  height: 10px;
  padding: 3px;
  border: solid #000;
  border-width: 0 3px 3px 0;
}
.g-arrow--right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.g-arrow--left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.g-arrow--up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.g-arrow--down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.g-async-indicator {
  position: absolute;
  top: 0;
  z-index: 1030;
  width: 100%;
  height: 100vh;
  cursor: wait;
}
.g-async-indicator .g-async-splash {
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
}
.g-async-indicator .g-async-splash-content {
  padding: 50px;
  font-size: 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
.nav > li.disabled > a {
  color: #777777;
}
.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  cursor: default;
  background-color: transparent;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #337ab7;
}
.nav > li > a > img {
  max-width: none;
}
.nav-pills > li {
  float: left;
}
.nav-pills > li > a {
  border-radius: 4px;
}
.nav-pills > li + li {
  margin-left: 2px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #337ab7;
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}
.nav-pills > .active > a > .badge {
  color: #337ab7;
  background-color: #fff;
}
.nav-pills > li > a > .badge {
  margin-left: 3px;
}
.nav:before,
.nav:after,
.navbar:before,
.navbar:after {
  display: table;
  content: " ";
}
.nav:after,
.navbar:after {
  clear: both;
}
.navbar {
  min-height: 30px;
}
.navbar .nav > li > .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 12px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.navbar .nav > li > .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 13px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}
.navbar .nav > li > .dropdown-menu.pull-left:before {
  right: auto;
  left: 12px;
}
.navbar .nav > li > .dropdown-menu.pull-left:after {
  right: auto;
  left: 13px;
}
.panel-body.jspScrollable {
  outline: none;
}
.panel-body .jspVerticalBar {
  z-index: 5;
  width: 5px;
  background: transparent;
}
.panel-body .jspHorizontalBar {
  height: 0;
}
.panel-body .jspTrack {
  height: 100%;
}
.panel-body .jspDrag {
  background: var(--theme-hover);
}
.preview-holder {
  position: absolute;
  z-index: 3;
  cursor: move;
  background: #f2f2f2;
  border: 1px solid #d3d6d9;
  border-radius: 4px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.g-stack-container {
  position: absolute;
  overflow: hidden;
  border: 1px solid var(--input-control-border-brush-start);
}
.g-stack-container.active {
  border-color: #333 !important;
}
.g-stack-container:not([data-disable-drag="true"]) {
  cursor: move;
}
.g-stack-container > .g-container {
  visibility: hidden;
}
.g-stack-container > .g-container.g-selected {
  visibility: visible;
}
.g-stack-container-buttonpane {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 5;
}
.g-stack-container-buttonpane li {
  display: inline-block;
}
.g-stack-container-menu-item > a > i + span {
  margin-left: 5px;
}
.g-stack-container-menu-item .g-btn-hide {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -7px;
}
.g-stack-container-menu-item .g-btn-configure-list {
  top: 1px;
  right: 21px;
  padding: 0;
  background: none;
}
.g-stack-container-menu-item .g-btn-loading .icon-cog {
  display: none;
}
.g-stack-container-menu-item .g-loading-animation .icon-spinner {
  font-size: 14px;
}
.g-stack-container-menu-item:hover .g-btn-configure-list,
.g-stack-container-menu-item:hover .g-btn-hide:not(.disabled) {
  color: #fff;
}
.popover-content ul > li.g-stack-container-menu-item .g-loading-animation .icon-spinner {
  margin-right: 0;
}
.g-configurable-container {
  z-index: 1;
}
.g-configurable-container:hover {
  z-index: 6;
}
.g-configurable-container.dragging {
  z-index: 7;
}
.g-configurable-container.active {
  z-index: 5;
  border-color: #333;
}
.g-configurable-container.closing {
  z-index: 4;
}
.g-configurable-container > .gwListView {
  height: 100%;
  overflow: hidden;
}
.g-configurable-container .gwTrackingControl.g-widget-config-mode .gwTextBox-textarea {
  height: calc(100% - 40px);
}
.g-configurable-container .gwTrackingControl.g-widget-config-mode .btn {
  cursor: inherit;
}
.draggable {
  cursor: move;
}
.g-btn-drag {
  left: -9px;
  padding: 2px;
}
.g-configuration-master-list {
  height: 100%;
}
.g-configurable-custom > .g-btn-hide {
  right: -9px;
  padding: 1px 3px 2px;
  cursor: pointer;
}
.g-configurable-custom .g-btn-drag,
.g-configurable-custom > .g-btn-hide {
  position: absolute;
  top: -9px;
  z-index: 5;
  display: none;
  font-size: 14px;
  color: #fff;
  background: var(--theme-default-button);
  border-radius: 50%;
}
.g-configurable-custom > .g-btn-hide.disabled,
.g-configurable-custom > .g-btn-hide.disabled:hover {
  background: #ccc;
}
.g-configurable-custom > .g-btn-hide.disabled {
  cursor: default;
}
.g-configurable-custom .g-btn-drag:hover,
.g-configurable-custom > .g-btn-hide:not(.disabled):hover {
  background: var(--theme-hover);
}
.g-configurable-custom.active .g-btn-drag,
.g-configurable-custom.dragging .g-btn-drag,
.g-configurable-custom:hover .g-btn-drag,
.g-configurable-custom.active > .g-btn-hide,
.g-configurable-custom.dragging > .g-btn-hide,
.g-configurable-custom:hover > .g-btn-hide {
  display: block;
}
.g-configurable-custom:not(.active) .g-btn-drag,
.g-configurable-custom:not(.active) > .g-btn-hide:not(.disabled) {
  background: var(--theme-value-text);
}
.g-configurable-custom > .gs-resize-handle-both {
  background-size: contain;
}
.g-configurable-shell .g-menu-item-icon.icon-cog {
  display: none;
}
.g-stack-container-buttonpane,
.g-stack-container-menu-item .g-btn-hide,
.g-stack-container .g-btn-hide,
.g-configurable-default .g-btn-hide,
.g-btn-configure-list {
  color: var(--group-box-header-text-color);
}
.g-stack-container-buttonpane li:hover i {
  color: var(--theme-hover);
  cursor: pointer;
}
.g-stack-container .g-btn-hide,
.g-configurable-default .g-btn-hide,
.g-btn-configure-list {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 5;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  padding: 2px;
}
.g-stack-container .g-btn-hide:hover,
.g-configurable-default .g-btn-hide:hover,
.g-btn-configure-list:hover {
  color: var(--theme-hover);
}
.g-stack-container .g-btn-hide.disabled,
.g-configurable-default .g-btn-hide.disabled,
.g-btn-configure-list.disabled,
.g-stack-container .g-btn-hide.disabled:hover,
.g-configurable-default .g-btn-hide.disabled:hover,
.g-btn-configure-list.disabled:hover {
  color: #ccc;
}
.g-stack-container .g-btn-hide.disabled,
.g-configurable-default .g-btn-hide.disabled,
.g-btn-configure-list.disabled {
  cursor: default;
}
.gwDataGrid .g-stack-container .g-btn-hide,
.gwDataGrid .g-configurable-default .g-btn-hide,
.gwDataGrid .g-btn-configure-list {
  top: 37px;
  right: 5px;
  z-index: 6;
}
.gwDataGrid .g-stack-container .g-btn-hide:not(.disabled),
.gwDataGrid .g-configurable-default .g-btn-hide:not(.disabled),
.gwDataGrid .g-btn-configure-list:not(.disabled) {
  color: #FFFFFF;
}
.gwDataGrid .g-stack-container .g-btn-hide:not(.disabled):hover,
.gwDataGrid .g-configurable-default .g-btn-hide:not(.disabled):hover,
.gwDataGrid .g-btn-configure-list:not(.disabled):hover {
  color: #666;
}
.g-btn-configure-list {
  top: 2px;
  right: 18px;
}
.g-configurable-default:hover .g-config-widget-style-shadow,
.g-configurable-default.active .g-config-widget-style-shadow {
  border: 1px solid var(--input-control-border-brush-start);
}
.g-configurable-custom:hover > .gwGroupBox,
.g-configurable-custom.active > .gwGroupBox,
.g-configurable-custom:hover .g-config-widget-style-shadow,
.g-configurable-custom.active .g-config-widget-style-shadow {
  box-shadow: 0 0 6px #c7caca;
}
.g-configurable-custom:hover > .gwGroupBox.alert-danger,
.g-configurable-custom.active > .gwGroupBox.alert-danger,
.g-configurable-custom:hover .g-config-widget-style-shadow.alert-danger,
.g-configurable-custom.active .g-config-widget-style-shadow.alert-danger {
  box-shadow: 0 0 6px #d90000;
}
.g-configurable-custom:hover > .gwGroupBox.alert-message-error,
.g-configurable-custom.active > .gwGroupBox.alert-message-error,
.g-configurable-custom:hover .g-config-widget-style-shadow.alert-message-error,
.g-configurable-custom.active .g-config-widget-style-shadow.alert-message-error {
  box-shadow: 0 0 6px #ffaa33;
}
.g-configurable-custom:hover > .gwGroupBox.alert-info,
.g-configurable-custom.active > .gwGroupBox.alert-info,
.g-configurable-custom:hover .g-config-widget-style-shadow.alert-info,
.g-configurable-custom.active .g-config-widget-style-shadow.alert-info {
  box-shadow: 0 0 6px #3a87ad;
}
.g-configurable-custom:hover > .gwGroupBox.alert-warning,
.g-configurable-custom.active > .gwGroupBox.alert-warning,
.g-configurable-custom:hover .g-config-widget-style-shadow.alert-warning,
.g-configurable-custom.active .g-config-widget-style-shadow.alert-warning {
  box-shadow: 0 0 6px #ffc825;
}
.g-configurable-custom > .gwGroupBox.alert-danger,
.g-configurable-custom .g-config-widget-style-shadow.alert-danger {
  box-shadow: 0 0 6px #ffb0b0;
}
.g-configurable-custom > .gwGroupBox.alert-message-error,
.g-configurable-custom .g-config-widget-style-shadow.alert-message-error {
  box-shadow: 0 0 6px #ffc675;
}
.g-configurable-custom > .gwGroupBox.alert-info,
.g-configurable-custom .g-config-widget-style-shadow.alert-info {
  box-shadow: 0 0 6px #b5e6fd;
}
.g-configurable-custom > .gwGroupBox.alert-warning,
.g-configurable-custom .g-config-widget-style-shadow.alert-warning {
  box-shadow: 0 0 6px #fef293;
}
.g-configurable-custom > .gwGroupBox.gwValidation-highlight.alert-danger,
.g-configurable-custom .g-config-widget-style-shadow.gwValidation-highlight.alert-danger {
  box-shadow: 0 0 6px #d90000;
}
.g-configurable-custom > .gwGroupBox.gwValidation-highlight.alert-message-error,
.g-configurable-custom .g-config-widget-style-shadow.gwValidation-highlight.alert-message-error {
  box-shadow: 0 0 6px #ffaa33;
}
.g-configurable-custom > .gwGroupBox.gwValidation-highlight.alert-info,
.g-configurable-custom .g-config-widget-style-shadow.gwValidation-highlight.alert-info {
  box-shadow: 0 0 6px #3a87ad;
}
.g-configurable-custom > .gwGroupBox.gwValidation-highlight.alert-warning,
.g-configurable-custom .g-config-widget-style-shadow.gwValidation-highlight.alert-warning {
  box-shadow: 0 0 6px #ffc825;
}
.gs-w:hover span.gs-resize-handle {
  opacity: 0;
}
.g-configurable-custom:hover > span.gs-resize-handle,
.g-configurable-default:hover > span.gs-resize-handle,
.g-stack-container:hover > span.gs-resize-handle {
  opacity: 1;
}
span.gs-resize-handle-x {
  bottom: 0;
}
span.gs-resize-handle-y {
  right: 0;
}
.g-config-widget-style-default {
  background-color: var(--input-control-background-color-end);
}
.g-config-widget-style-default,
.g-config-widget-style-border,
.g-configurable-default .gwAsyncImage,
.g-configurable-default .glow-vue-component {
  border: 1px solid var(--input-control-border-brush-start);
}
div.g-config-widget-with-icon {
  display: table;
  width: 100%;
  height: 100%;
}
div.g-config-widget-with-icon i {
  display: table-cell;
  padding-right: 8px;
  text-align: right;
  vertical-align: middle;
  color: var(--theme-icons);
}
.g-config-widget-style-border.alert-danger,
.alert-danger .g-config-widget-style-border {
  color: #333;
  background-color: #ffe8e7;
  border-color: #ffb0b0;
}
.g-config-widget-style-border.alert-message-error,
.alert-message-error .g-config-widget-style-border {
  color: #333;
  background-color: #ffd599;
  border-color: #ffc675;
}
.g-config-widget-style-border.alert-warning,
.alert-warning .g-config-widget-style-border {
  color: #333;
  background-color: #fff7bd;
  border-color: #fef293;
}
.g-config-widget-style-border.alert-info,
.alert-info .g-config-widget-style-border {
  color: #333;
  background-color: #e0f4fe;
  border-color: #b5e6fd;
}
.g-config-widget-style-validation.alert-danger,
.g-config-widget-style-default.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #ffb0b0;
}
.g-config-widget-style-validation.alert-danger .gwTile-img,
.g-config-widget-style-default.alert-danger .gwTile-img,
.g-config-widget-style-validation.alert-danger .gwTile-text,
.g-config-widget-style-default.alert-danger .gwTile-text {
  color: #333;
}
.g-config-widget-style-validation.alert-danger.gwCheckBox,
.g-config-widget-style-default.alert-danger.gwCheckBox,
.g-config-widget-style-validation.alert-danger.gwLabel,
.g-config-widget-style-default.alert-danger.gwLabel,
.g-config-widget-style-validation.alert-danger.gwTile[data-color],
.g-config-widget-style-default.alert-danger.gwTile[data-color],
.g-config-widget-style-validation.alert-danger.gwSearchBox,
.g-config-widget-style-default.alert-danger.gwSearchBox,
.g-config-widget-style-validation.alert-danger.gwGroupBox,
.g-config-widget-style-default.alert-danger.gwGroupBox {
  color: #333;
  background-color: #ffe8e7;
  border-color: #ffb0b0;
}
.g-config-widget-style-validation.alert-danger.gwTile[data-color]:hover,
.g-config-widget-style-default.alert-danger.gwTile[data-color]:hover,
.g-config-widget-style-validation.alert-danger:hover,
.g-config-widget-style-default.alert-danger:hover {
  border-color: #d90000;
}
.g-config-widget-style-validation.alert-message-error,
.g-config-widget-style-default.alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffc675;
}
.g-config-widget-style-validation.alert-message-error .gwTile-img,
.g-config-widget-style-default.alert-message-error .gwTile-img,
.g-config-widget-style-validation.alert-message-error .gwTile-text,
.g-config-widget-style-default.alert-message-error .gwTile-text {
  color: #333;
}
.g-config-widget-style-validation.alert-message-error.gwCheckBox,
.g-config-widget-style-default.alert-message-error.gwCheckBox,
.g-config-widget-style-validation.alert-message-error.gwLabel,
.g-config-widget-style-default.alert-message-error.gwLabel,
.g-config-widget-style-validation.alert-message-error.gwTile[data-color],
.g-config-widget-style-default.alert-message-error.gwTile[data-color],
.g-config-widget-style-validation.alert-message-error.gwSearchBox,
.g-config-widget-style-default.alert-message-error.gwSearchBox,
.g-config-widget-style-validation.alert-message-error.gwGroupBox,
.g-config-widget-style-default.alert-message-error.gwGroupBox {
  color: #333;
  background-color: #ffd599;
  border-color: #ffc675;
}
.g-config-widget-style-validation.alert-message-error.gwTile[data-color]:hover,
.g-config-widget-style-default.alert-message-error.gwTile[data-color]:hover,
.g-config-widget-style-validation.alert-message-error:hover,
.g-config-widget-style-default.alert-message-error:hover {
  border-color: #ffaa33;
}
.g-config-widget-style-validation.alert-info,
.g-config-widget-style-default.alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #b5e6fd;
}
.g-config-widget-style-validation.alert-info .gwTile-img,
.g-config-widget-style-default.alert-info .gwTile-img,
.g-config-widget-style-validation.alert-info .gwTile-text,
.g-config-widget-style-default.alert-info .gwTile-text {
  color: #333;
}
.g-config-widget-style-validation.alert-info.gwCheckBox,
.g-config-widget-style-default.alert-info.gwCheckBox,
.g-config-widget-style-validation.alert-info.gwLabel,
.g-config-widget-style-default.alert-info.gwLabel,
.g-config-widget-style-validation.alert-info.gwTile[data-color],
.g-config-widget-style-default.alert-info.gwTile[data-color],
.g-config-widget-style-validation.alert-info.gwSearchBox,
.g-config-widget-style-default.alert-info.gwSearchBox,
.g-config-widget-style-validation.alert-info.gwGroupBox,
.g-config-widget-style-default.alert-info.gwGroupBox {
  color: #333;
  background-color: #e0f4fe;
  border-color: #b5e6fd;
}
.g-config-widget-style-validation.alert-info.gwTile[data-color]:hover,
.g-config-widget-style-default.alert-info.gwTile[data-color]:hover,
.g-config-widget-style-validation.alert-info:hover,
.g-config-widget-style-default.alert-info:hover {
  border-color: #3a87ad;
}
.g-config-widget-style-validation.alert-warning,
.g-config-widget-style-default.alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #fef293;
}
.g-config-widget-style-validation.alert-warning .gwTile-img,
.g-config-widget-style-default.alert-warning .gwTile-img,
.g-config-widget-style-validation.alert-warning .gwTile-text,
.g-config-widget-style-default.alert-warning .gwTile-text {
  color: #333;
}
.g-config-widget-style-validation.alert-warning.gwCheckBox,
.g-config-widget-style-default.alert-warning.gwCheckBox,
.g-config-widget-style-validation.alert-warning.gwLabel,
.g-config-widget-style-default.alert-warning.gwLabel,
.g-config-widget-style-validation.alert-warning.gwTile[data-color],
.g-config-widget-style-default.alert-warning.gwTile[data-color],
.g-config-widget-style-validation.alert-warning.gwSearchBox,
.g-config-widget-style-default.alert-warning.gwSearchBox,
.g-config-widget-style-validation.alert-warning.gwGroupBox,
.g-config-widget-style-default.alert-warning.gwGroupBox {
  color: #333;
  background-color: #fff7bd;
  border-color: #fef293;
}
.g-config-widget-style-validation.alert-warning.gwTile[data-color]:hover,
.g-config-widget-style-default.alert-warning.gwTile[data-color]:hover,
.g-config-widget-style-validation.alert-warning:hover,
.g-config-widget-style-default.alert-warning:hover {
  border-color: #ffc825;
}
.g-config-widget-style-validation.gwValidation-highlight.gwTile[data-color].alert-danger,
.g-config-widget-style-default.gwValidation-highlight.gwTile[data-color].alert-danger,
.g-config-widget-style-validation.gwValidation-highlight.alert-danger,
.g-config-widget-style-default.gwValidation-highlight.alert-danger {
  background: #ffb0b0;
  border-color: #d90000;
}
.g-config-widget-style-validation.gwValidation-highlight.gwTile[data-color].alert-message-error,
.g-config-widget-style-default.gwValidation-highlight.gwTile[data-color].alert-message-error,
.g-config-widget-style-validation.gwValidation-highlight.alert-message-error,
.g-config-widget-style-default.gwValidation-highlight.alert-message-error {
  color: #ffc675;
}
.g-config-widget-style-validation.gwValidation-highlight.gwTile[data-color].alert-info,
.g-config-widget-style-default.gwValidation-highlight.gwTile[data-color].alert-info,
.g-config-widget-style-validation.gwValidation-highlight.alert-info,
.g-config-widget-style-default.gwValidation-highlight.alert-info {
  background: #b5e6fd;
}
.g-config-widget-style-validation.gwValidation-highlight.gwTile[data-color].alert-warning,
.g-config-widget-style-default.gwValidation-highlight.gwTile[data-color].alert-warning,
.g-config-widget-style-validation.gwValidation-highlight.alert-warning,
.g-config-widget-style-default.gwValidation-highlight.alert-warning {
  background: #fef293;
}
.g-config-widget-style-validation.gwLabel {
  border: 1px solid var(--input-control-border-brush-start);
}
.g-configurable-default .g-widget-config-mode.gwDataGrid {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.g-configurable-default.active .g-config-widget-style-border,
.g-configurable-default.active .g-widget-config-mode,
.g-configurable-default.dragging .g-widget-config-mode,
.g-configurable-default.active .gwLabel,
.g-configurable-default.dragging .gwLabel,
.g-configurable-default.active .gwAsyncImage,
.g-configurable-default.dragging .gwAsyncImage,
.g-configurable-default.active .glow-vue-component,
.g-configurable-default.dragging .glow-vue-component {
  border-color: #333 !important;
}
.g-configurable-default:not(.g-overlap-widget):not(.g-overlap-container),
.g-configurable-default label,
.g-configurable-default .btn {
  cursor: move;
}
.g-configurable-widget {
  z-index: 4;
  overflow: hidden;
}
.g-configurable-widget.dragging {
  z-index: 5;
}
.g-configurable-widget .g-uneditable,
.g-configurable-widget .dateclockpicker-readonly .input-group-addon,
.g-configurable-widget .gwTriStateRadioButton.captionLeft,
.g-configurable-widget .gwTriStateRadioButton.captionLeft .gwTriStateRadioButton-label,
.g-configurable-widget .gwCheckBox.gwCheckBox--readonly {
  cursor: inherit;
}
.g-configurable-widget .gwTriStateRadioButton.captionLeft {
  background-color: var(--input-control-background-color-end);
}
.g-configurable-widget .gwMeasure .g-measure-divider {
  position: absolute;
  right: 62px;
  bottom: 0;
}
.g-configurable-widget .gwMeasure.g-readonly-measure {
  cursor: inherit;
}
.gwJobDocAddress-container.g-configurable-widget {
  overflow: visible;
}
.gwJobDocAddress-container.g-configurable-widget .gwJobDocAddress {
  height: 100%;
}
.g-configuration-sidebar ul {
  margin-bottom: 0;
  font-size: 12px;
}
.g-configuration-sidebar li {
  position: relative;
}
.g-configuration-sidebar li.active {
  background: var(--grid-viewport-selected-row-color) !important;
}
.g-configuration-sidebar li:first-child {
  border-top-color: #ccc;
}
.g-configuration-sidebar li:not(.gwConfigurablePanels-no-results):hover {
  border-color: #aaa;
}
.g-configuration-sidebar li.g-has-alerts > a {
  padding-right: 12px;
}
.g-configuration-sidebar li .alert-count {
  top: 2px;
  right: 2px;
}
.g-configuration-sidebar li > a {
  display: block;
  padding: 8px;
  color: var(--theme-general-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-configuration-sidebar li > a:hover {
  color: var(--theme-hover-text);
  background-color: var(--theme-hover);
}
.g-configuration-sidebar li > a:hover i,
.g-configuration-sidebar li > a:hover i.disable-icon {
  color: var(--theme-hover-text);
}
.g-configuration-sidebar li > a i {
  margin-right: 3px;
}
.g-configuration-sidebar li > a i.disable-icon {
  color: var(--theme-field-title);
}
.g-configuration-sidebar li > a span i {
  color: var(--theme-default-button);
}
.g-configuration-sidebar li > a i,
.g-configuration-sidebar li > a span span {
  vertical-align: middle;
}
.g-configuration-sidebar h3.panel-title {
  padding-left: 0;
  color: var(--input-control-label-color);
}
.g-configuration-sidebar .panel-heading h3 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-configuration-sidebar .gwCheckBox--bordered {
  min-width: 193px;
}
.g-configuration-sidebar .gwImageEdit-container {
  height: 150px;
}
.g-configuration-sidebar .gwImageEdit-container .gw-img-edit {
  padding-top: 21px;
  border-radius: 0;
  background-color: var(--input-control-background-color-end);
}
.g-configuration-sidebar__heading {
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 5px;
  margin-bottom: 3px;
  color: var(--theme-field-title);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-configuration-sidebar__heading i {
  margin-right: 1px;
}
.g-configuration-sidebar-active .gwGroupBox > div.panel-heading {
  background-color: var(--theme-hover);
}
.g-configuration-sidebar-active .gwGroupBox > div.panel-heading a,
.g-configuration-sidebar-active .gwGroupBox > div.panel-heading a i {
  color: var(--theme-hover-text);
}
.g-configuration-sidebar-no-results {
  padding: 8px;
  color: var(--theme-field-title);
  text-align: center;
}
.g-configuration-sidebar-addl-item.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.g-configuration-sidebar-addl-item.alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.g-configuration-sidebar-addl-item.alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.g-configuration-sidebar-addl-item.alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.g-configuration-sidebar-addl-item.gwValidation-highlight.alert-danger {
  background: #ffb0b0;
}
.g-configuration-sidebar-addl-item.gwValidation-highlight.alert-message-error {
  color: #ffc675;
}
.g-configuration-sidebar-addl-item.gwValidation-highlight.alert-info {
  background: #b5e6fd;
}
.g-configuration-sidebar-addl-item.gwValidation-highlight.alert-warning {
  background: #fef293;
}
.g-widget-config-mode.gwJobDocAddress .g-widget-config-mode {
  position: relative;
  height: 42px;
  border-radius: 4px;
  background-color: var(--input-control-background-color-end);
}
.g-widget-config-mode.gwJobDocAddress .g-widget-config-mode.disabled {
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-inner .g-widget-config-mode {
  border-radius: 0;
}
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-top .g-widget-config-mode {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-bottom .g-widget-config-mode {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-bottom.g-cols-2 li:first-child .g-widget-config-mode {
  border-bottom-right-radius: 0;
}
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-bottom.g-cols-2 li:last-child .g-widget-config-mode {
  border-bottom-left-radius: 0;
}
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-inner .g-widget-config-mode,
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-top .g-widget-config-mode,
.g-widget-config-mode.gwJobDocAddress .gwJobDocAddress-bottom .g-widget-config-mode {
  border: 1px solid var(--input-control-border-brush-start) !important;
}
.g-widget-config-mode.gwMapsContainer .gwMaps .g-sidebar-right .g-sidebar-wrap {
  border-radius: 0;
}
.g-widget-config-mode.gwMapsContainer i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -20px;
  font-size: 36px;
  color: var(--theme-icons);
}
.g-widget-config-mode.gwButton {
  border-radius: 5px;
}
.g-widget-config-mode.gwButton .btn {
  cursor: move;
}
.g-widget-config-mode.gwButton .btn:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.g-widget-config-mode.gwButton .btn span {
  display: inline-block;
  width: calc(100% - 2px);
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-widget-config-mode.gwRichTextEditor .tox-tinymce {
  display: none;
}
.g-widget-config-mode.gwRichTextEditor .tox-editor-header,
.g-widget-config-mode.gwRichTextEditor iframe {
  visibility: hidden;
}
.g-widget-config-mode.gwTile .gwTile-wrap {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px;
}
.g-widget-config-mode.gwTile[data-size="Wide"] .gwTile-wrap {
  padding: 5px 10px;
}
.g-widget-config-mode.gwTile[data-size="Large"] .gwTile-wrap {
  padding: 10px;
}
.g-widget-config-mode .gwSearchBox-description-divider {
  position: absolute;
  bottom: 0;
  left: 40px;
}
.g-widget-config-mode.gwConversationManager .g-textarea-panel {
  background-color: var(--input-control-background-color-end);
  border: 1px solid var(--input-control-border-brush-start);
}
.g-widget-config-mode.gwConversationManager .g-conversation-panel {
  left: 1px;
  right: 1px;
}
.g-widget-config-mode.gwConversationManager .btn {
  cursor: move;
}
.g-widget-config-mode.gwSearchBox.alert-danger,
.g-widget-config-mode.gwSearchBox.alert-message-error,
.g-widget-config-mode.gwSearchBox.alert-warning,
.g-widget-config-mode.gwSearchBox.alert-info {
  border-width: 1px;
  border-style: solid;
}
.g-widget-config-mode.gwFavorites {
  overflow: hidden;
}
.g-widget-config-mode.gwFavorites.gwFavorites-with-recents,
.g-widget-config-mode.gwFavorites.gwFavorites-with-recents .g-recents {
  min-height: auto;
}
.g-widget-config-mode.gwFavorites .gwFavorites-error {
  z-index: 1;
  border-radius: 0;
}
.g-widget-config-mode.gwTriStateRadioButton .btn-group .btn {
  cursor: inherit;
}
.g-widget-config-mode.gwTriStateRadioButton .btn-group .btn:hover {
  color: inherit;
  background: inherit;
}
.g-widget-config-mode.gwTextBox {
  height: 100%;
}
.g-widget-config-mode.gwTextBox input,
.g-widget-config-mode.gwTextBox textarea {
  display: none;
}
.g-widget-config-mode.gwDataGrid .kgNoSelect,
.g-widget-config-mode.gwDataGrid .kgHeaderContainer {
  height: 100%;
}
.g-widget-config-mode.gwDataGrid .kgTopPanel {
  height: 24px;
}
.g-widget-config-mode.gwDataGrid .kgNonFixedViewport,
.g-widget-config-mode.gwDataGrid .kgNonFixedHeaderContainer {
  width: calc(100% - 31px);
}
.g-widget-config-mode.gwDataGrid .kgViewport {
  height: calc(100% - 24px);
}
.g-widget-config-mode.gwDataGrid .kgFixedViewport,
.g-widget-config-mode.gwDataGrid .kgFixedHeaderContainer {
  width: 31px;
  border-right: 1px solid var(--grid-header-border-color);
}
.g-widget-config-mode.gwDataGrid .gwDataGrid-inner-wrap {
  overflow: hidden;
}
.g-widget-config-mode.gwDataGrid .g-kg-title-container {
  display: block;
}
.g-widget-config-mode.gwSearchList .g-global-search-results ul {
  height: 100%;
}
.g-widget-config-mode.gwSearchList .g-global-search-results ul li {
  height: 100%;
  min-height: inherit;
  margin-bottom: 0;
}
.g-widget-config-mode.gwSearchList .g-global-search-results ul li:hover {
  background-color: #fff;
}
.g-widget-config-mode.gwSearchList .kgTopPanel {
  width: 100% !important;
}
.g-widget-config-mode.gwSearchList .g-search-results {
  flex: 1;
}
.g-widget-config-mode.gwSearchList .g-search-results-items-container {
  height: calc(100% - 42px);
}
.g-widget-config-mode.gwFinder {
  display: flex;
  flex-direction: column;
}
.g-widget-config-mode.gwFinder.gwFinder-item-position-top {
  flex-direction: column-reverse;
}
.g-widget-config-mode.gwFinder .search-container {
  width: 100%;
  border-radius: 4px;
  order: 1;
}
.g-widget-config-mode.gwFinder .template-container {
  order: 2;
}
.g-widget-config-mode.gwOptionButtonGrid input[type=radio] + label {
  cursor: move;
}
.g-widget-config-mode.btn span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-footer-buttons[type="checkbox"] {
  cursor: pointer;
}
.dateclockpicker-popover .popover-title,
.dateclockpicker-popover .popover-content,
.dateclockpicker-popover .dateclockpicker-footer {
  position: relative;
  padding: 0;
}
.dateclockpicker-popover .popover-title {
  font-size: 32px !important;
  text-align: center;
  line-height: 34px;
  height: 45px;
  margin: 0;
  background-color: #f7f7f7;
}
.dateclockpicker-popover .popover-title .dateclockpicker-calendar-view,
.dateclockpicker-popover .popover-title .dateclockpicker-clock-view {
  padding-top: 3px;
  padding-bottom: 3px;
}
.dateclockpicker-popover .popover-title span {
  cursor: pointer;
  margin: 0 5px;
}
.dateclockpicker-popover .popover-title span.dateclockpicker-span-separator {
  margin: 0 -4px;
  cursor: default;
}
.dateclockpicker-popover .popover-title span.dateclockpicker-span-months {
  position: relative;
}
.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:before,
.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:after {
  content: "-";
  position: absolute;
  top: 4px;
}
.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:before {
  left: -17px;
}
.dateclockpicker-popover .popover-title span.dateclockpicker-span-months:after {
  right: -17px;
}
.dateclockpicker-popover .popover-content {
  width: 234px;
  height: 218px;
  font-size: 12px;
}
.dateclockpicker-popover .popover-content:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dateclockpicker-popover .popover-content .dateclockpicker-clock-view {
  padding: 8px;
}
.dateclockpicker-popover.popover.top > .arrow,
.dateclockpicker-popover.popover.bottom > .arrow {
  margin-left: -15px;
}
.dateclockpicker-popover.popover.top > .arrow:after,
.dateclockpicker-popover.popover.bottom > .arrow:after {
  margin-left: -6px;
}
.dateclockpicker-popover.popover > .arrow {
  border-width: 7px;
}
.dateclockpicker-popover.popover > .arrow:after {
  border-width: 6px;
}
.dateclockpicker-popover.hideable-dropdown-menu > .popover-content {
  overflow: hidden;
}
.dateclockpicker .input-group-addon {
  cursor: pointer;
}
.dateclockpicker-moving {
  cursor: move;
}
.popover.dateclockpicker-align-right.top > .arrow,
.popover.dateclockpicker-align-right.bottom > .arrow {
  left: auto;
  right: 7px;
}
.dateclockpicker-align-middle.popover > .arrow {
  left: auto;
  right: 50%;
  margin-right: -7px;
}
.dateclockpicker-days,
.dateclockpicker-months,
.dateclockpicker-years,
.dateclockpicker-calendar-view,
.dateclockpicker-clock-view,
.dateclockpicker-dial {
  -webkit-transition: -webkit-transform 350ms, opacity 350ms;
  -moz-transition: -moz-transform 350ms, opacity 350ms;
  -o-transition: -o-transform 350ms, opacity 350ms;
  transition: transform 350ms, opacity 350ms;
}
.dateclockpicker-transition-out {
  opacity: 0;
}
.dateclockpicker-transition-out.dateclockpicker-calendar-view,
.dateclockpicker-transition-out.dateclockpicker-days,
.dateclockpicker-transition-out.dateclockpicker-hours {
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.dateclockpicker-transition-out.dateclockpicker-clock-view,
.dateclockpicker-transition-out.dateclockpicker-months,
.dateclockpicker-transition-out.dateclockpicker-years,
.dateclockpicker-transition-out.dateclockpicker-minutes {
  -webkit-transform: scale(0.8, 0.8);
  -moz-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
}
.dateclockpicker-plate {
  border-radius: 50%;
  width: 198px;
  height: 198px;
  overflow: visible;
  position: relative;
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.dateclockpicker-canvas,
.dateclockpicker-dial {
  width: 198px;
  height: 198px;
  position: absolute;
  left: -2px;
  top: -2px;
  touch-action: none;
}
.dateclockpicker-tick {
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  width: 28px;
  height: 28px;
  position: absolute;
  cursor: pointer;
  font-size: 14px;
}
.dateclockpicker-hours .dateclockpicker-outertick {
  font-size: 11px;
}
.dateclockpicker-canvas {
  -webkit-transition: opacity 175ms;
  -moz-transition: opacity 175ms;
  -o-transition: opacity 175ms;
  transition: opacity 175ms;
}
.dateclockpicker-canvas line {
  stroke-width: 1;
}
.dateclockpicker-canvas-out {
  opacity: 0.25;
}
.dateclockpicker-canvas-bearing,
.dateclockpicker-canvas-fg {
  stroke: none;
}
.dateclockpicker-canvas-hours-outertick {
  font-size: 11px;
}
.dateclockpicker-canvas-bg {
  stroke: none;
}
.dateclockpicker-calendar-view {
  overflow: hidden;
}
.dateclockpicker-calendar-view,
.dateclockpicker-clock-view {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 8px;
}
.dateclockpicker-days,
.dateclockpicker-months,
.dateclockpicker-years {
  position: absolute;
}
.dateclockpicker-months,
.dateclockpicker-years {
  width: 100%;
  overflow: hidden;
}
.dateclockpicker-months span,
.dateclockpicker-years span {
  display: block;
  float: left;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  width: 48px;
  height: 40px;
  line-height: 40px;
  margin: 3px;
}
.dateclockpicker-days {
  width: 216px;
  margin-left: 1px;
}
.dateclockpicker-days .dateclockpicker-daysofweek,
.dateclockpicker-days .dateclockpicker-daysofmonth {
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
}
.dateclockpicker-days .dateclockpicker-daysofweek span,
.dateclockpicker-days .dateclockpicker-daysofmonth span {
  float: left;
  width: 24px;
  margin: 4px;
  padding: 0;
  text-align: center;
}
.dateclockpicker-days .dateclockpicker-daysofweek {
  margin-top: 8px;
  margin-bottom: 8px;
}
.dateclockpicker-days .dateclockpicker-daysofweek span {
  text-transform: uppercase;
  margin: 0 4px;
  font-size: 11px;
  line-height: 11px;
}
.dateclockpicker-days .dateclockpicker-daysofweek span:first-child {
  margin-left: 0;
}
.dateclockpicker-days .dateclockpicker-daysofweek span:last-child {
  margin-right: 0;
}
.dateclockpicker-days .dateclockpicker-daysofmonth span {
  height: 20px;
  cursor: pointer;
  border-radius: 4px;
  display: block;
  line-height: 20px;
}
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(7n+1) {
  margin-left: 0;
}
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(7n+0) {
  margin-right: 0;
}
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(1),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(2),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(3),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(4),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(5),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(6),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(7) {
  margin-top: 0;
}
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(36),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(37),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(38),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(39),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(40),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(41),
.dateclockpicker-days .dateclockpicker-daysofmonth span:nth-child(42) {
  margin-bottom: 0;
}
.dateclockpicker-months span:hover,
.dateclockpicker-years span:hover,
.dateclockpicker-calendar-switch:hover,
.dateclockpicker-calendar-prev:hover,
.dateclockpicker-calendar-next:hover {
  cursor: pointer;
}
.dateclockpicker-oldday {
  color: #999;
}
.dateclockpicker-calendar-header {
  text-align: center;
  line-height: 14px;
  margin-bottom: 4px;
}
.dateclockpicker-calendar-switch {
  font-weight: bold;
  padding: 2px 10px;
}
.dateclockpicker-calendar-prev {
  float: left;
}
.dateclockpicker-calendar-next {
  float: right;
}
.dateclockpicker-calendar-prev,
.dateclockpicker-calendar-next {
  padding: 0 5px;
  font-size: 16px;
}
.dateclockpicker-footer {
  height: 44px;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
}
.dateclockpicker-footer button {
  height: 28px;
  border-radius: 2px;
  font-size: 14px;
  padding: 0 7px 1px;
  border-radius: 5px;
  line-height: normal;
}
.dateclockpicker-displaymode-datetime .dateclockpicker-footer button:first-child {
  float: left;
}
.dateclockpicker-displaymode-datetime .dateclockpicker-footer button:last-child {
  float: right;
}
.dateclockpicker-displaymode-date .dateclockpicker-footer button:last-child,
.dateclockpicker-displaymode-time .dateclockpicker-footer button:last-child {
  display: none;
}
.dateclockpicker-displaymode-date .dateclockpicker-footer > div,
.dateclockpicker-displaymode-time .dateclockpicker-footer > div {
  text-align: center;
}
.dateclockpicker-displaymode-duration .input-group-addon {
  display: none;
}
.dateclockpicker-popover.popover {
  color: #575A5D;
  border: 1px solid #333333;
}
.dateclockpicker-popover.popover .popover-title {
  color: #575A5D;
  font-weight: normal;
  padding: 0;
  display: block;
  height: 48px;
}
.dateclockpicker-popover.popover.bottom > .arrow {
  border-bottom-color: #333333;
}
.dateclockpicker-popover.popover.bottom > .arrow:after {
  border-bottom-color: #FFFFFF;
}
.dateclockpicker-popover.popover.top > .arrow {
  border-top-color: #333333;
}
.dateclockpicker-popover.popover.top > .arrow:after {
  border-top-color: #FAFAFA;
}
.dateclockpicker-popover.popover .dateclockpicker-calendar-view,
.dateclockpicker-popover.popover .dateclockpicker-clock-view {
  display: block;
}
.dateclockpicker-popover.popover .dateclockpicker-calendar-view .dateclockpicker-span-months:before,
.dateclockpicker-popover.popover .dateclockpicker-calendar-view .dateclockpicker-span-months:after {
  top: 3px;
}
.dateclockpicker-popover .popover-title {
  background-color: #FFFFFF;
  border-bottom: 1px solid #D8D8D8;
}
.dateclockpicker-popover .popover-title span:hover {
  color: var(--theme-hover);
}
.dateclockpicker-popover .popover-title span:hover:before,
.dateclockpicker-popover .popover-title span:hover:after {
  color: #575A5D;
}
.dateclockpicker-popover .popover-content {
  background-color: #F2F2F2;
}
.dateclockpicker-popover .text-primary {
  color: var(--theme-default-button);
}
.dateclockpicker .input-group-addon {
  height: 42px;
}
.dateclockpicker .input-group-addon i {
  color: var(--theme-icons);
}
.dateclockpicker-readonly .input-group-addon .icon-calendar {
  color: var(--content-text-color);
  cursor: default;
  opacity: 0.5;
}
.dateclockpicker-readonly .input-group-addon .icon-calendar.icon-calendar:hover {
  opacity: 1;
}
.dateclockpicker-readonly .input-group-addon .icon-clock-o {
  color: var(--content-text-color);
  cursor: default;
  opacity: 0.5;
}
.dateclockpicker-readonly .input-group-addon .icon-clock-o.icon-clock-o:hover {
  opacity: 1;
}
.dateclockpicker-plate {
  background-color: #FFFFFF;
}
.dateclockpicker-canvas line {
  stroke: var(--theme-default-button);
}
.dateclockpicker-canvas-bearing,
.dateclockpicker-canvas-fg,
.dateclockpicker-canvas-bg {
  fill: var(--theme-default-button);
}
.dateclockpicker-canvas-txt {
  fill: #FFFFFF;
}
.dateclockpicker-tick.dateclockpicker-active {
  color: var(--theme-default-button-text);
  background: var(--theme-default-button);
}
.dateclockpicker-tick:hover {
  color: var(--theme-default-button-text);
  background: var(--theme-hover);
}
.dateclockpicker-outertick {
  color: var(--theme-default-button);
}
.dateclockpicker-days .dateclockpicker-daysofmonth .dateclockpicker-active,
.dateclockpicker-days .dateclockpicker-daysofmonth .dateclockpicker-active:hover,
.dateclockpicker-months span.dateclockpicker-active,
.dateclockpicker-months span.dateclockpicker-active:hover,
.dateclockpicker-years span.dateclockpicker-active,
.dateclockpicker-years span.dateclockpicker-active:hover {
  color: var(--theme-default-button-text);
  background: var(--theme-default-button);
}
.dateclockpicker-days .dateclockpicker-daysofmonth span:hover,
.dateclockpicker-months span:hover,
.dateclockpicker-years span:hover,
.dateclockpicker-calendar-switch:hover,
.dateclockpicker-calendar-prev:hover,
.dateclockpicker-calendar-next:hover {
  color: var(--theme-hover-text);
  background: var(--theme-hover);
  border-radius: 3px;
}
.dateclockpicker-footer {
  background: #FAFAFA;
  border-top: 1px solid #D8D8D8;
}
.dateclockpicker-footer button {
  color: #575A5D;
  background: #fff;
  border: 1px solid #D6D6D6;
}
.dateclockpicker-footer button:hover {
  color: var(--theme-hover-text);
  background: var(--theme-hover);
  border-color: #D6D6D6;
}
.g-modal .modal-dialog.g-modal-autoresize,
.g-modal .modal-dialog.g-modal-sizetocontent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
}
.g-modal .modal-dialog.g-modal-autoresize {
  min-width: 1000px;
  min-height: 200px;
  margin: 0;
  width: auto;
}
.g-modal .modal-dialog.g-modal-autoresize .g-modal-alert-bar {
  position: absolute;
  top: 38px;
  z-index: 1;
  width: 100%;
}
.g-modal .modal-dialog.g-modal-autoresize .modal-content {
  width: auto;
  height: 80%;
  min-width: 1000px;
  min-height: 200px;
  align-items: stretch;
}
.g-modal .modal-dialog.g-modal-autoresize .modal-content .modal-body-wrapper {
  height: 100%;
}
.g-modal .modal-dialog.g-modal-autoresize .g-heading {
  margin-bottom: 0;
}
.g-modal .modal-dialog.g-modal-sizetocontent {
  margin: 0px;
  height: 100%;
}
.g-modal .modal-dialog.g-modal-sizetocontent .modal-body-wrapper {
  position: static;
  height: auto;
}
.g-modal .modal-dialog.g-modal-sizetocontent .g-header {
  border-bottom: 0;
  background-color: transparent;
}
.g-modal .modal-dialog .modal-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  width: 100%;
  padding: 0;
  height: 38px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.g-modal .modal-dialog .modal-header .g-tasktray-alerts .g-display-option-button,
.g-modal .modal-dialog .modal-header .g-tasktray-alerts .g-display-option-text {
  color: var(--theme-icons);
}
.g-modal .modal-dialog .modal-title-container {
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
}
.g-modal .modal-dialog .modal-title .g-import-file-name {
  color: var(--theme-field-title);
}
.g-modal .modal-dialog .modal-content {
  border: none;
  box-shadow: 6px 6px 10px -3px rgba(102, 102, 102, 0.9);
  background-color: var(--body-background-color-start);
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}
.g-modal .modal-dialog .modal-body {
  color: var(--body-text-color);
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 15px;
  -webkit-overflow-scrolling: touch;
  background-color: var(--content-background-color-start);
}
.g-modal .modal-dialog .modal-body > .g-icon-error,
.g-modal .modal-dialog .modal-body > .g-icon-message-error,
.g-modal .modal-dialog .modal-body > .g-icon-success,
.g-modal .modal-dialog .modal-body > .g-icon-warning,
.g-modal .modal-dialog .modal-body > .g-icon-information,
.g-modal .modal-dialog .modal-body > .g-icon-question {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 34px;
}
.g-modal .modal-dialog .modal-body > div > .gwValidationSummary {
  padding-top: 15px;
  margin-left: -40px;
}
.g-modal .modal-dialog .modal-body > .g-icon-error {
  color: #d90000;
}
.g-modal .modal-dialog .modal-body > .g-icon-message-error {
  color: #ffaa33;
}
.g-modal .modal-dialog .modal-body > .g-icon-success {
  color: #58ba00;
}
.g-modal .modal-dialog .modal-body > .g-icon-warning {
  color: #ffc825;
}
.g-modal .modal-dialog .modal-body > .g-icon-information {
  color: #3a87ad;
}
.g-modal .modal-dialog .modal-body > .g-icon-question {
  color: #3a87ad;
}
.g-modal .modal-dialog .modal-body.text-only {
  white-space: pre-wrap;
}
.g-modal .modal-dialog .modal-body-with-icon {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 50px;
  position: relative;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-danger:hover {
  background: #ffe8e7;
  border-top-color: #ffe8e7;
  border-bottom-color: #d90000;
  border-left-color: #ffe8e7;
  border-right-color: #ffe8e7;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-danger:hover span {
  background: #ffe8e7 !important;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-danger:hover:last-child {
  border-bottom-color: #ffe8e7;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-message-error:hover {
  background: #ffd599;
  border-top-color: #ffd599;
  border-bottom-color: #ffaa33;
  border-left-color: #ffd599;
  border-right-color: #ffd599;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-message-error:hover span {
  background: #ffd599 !important;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-message-error:hover:last-child {
  border-bottom-color: #ffd599;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-warning:hover {
  background: #fff7bd;
  border-top-color: #fff7bd;
  border-bottom-color: #ffc825;
  border-left-color: #fff7bd;
  border-right-color: #fff7bd;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-warning:hover span {
  background: #fff7bd !important;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-warning:hover:last-child {
  border-bottom-color: #fff7bd;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-info:hover {
  background: #e0f4fe;
  border-top-color: #e0f4fe;
  border-bottom-color: #3a87ad;
  border-left-color: #e0f4fe;
  border-right-color: #e0f4fe;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-info:hover span {
  background: #e0f4fe !important;
}
.g-modal .modal-dialog .modal-body-with-icon .gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-info:hover:last-child {
  border-bottom-color: #e0f4fe;
}
.g-modal .modal-dialog .modal-footer {
  width: 100%;
  padding: 6px 6px;
  bottom: 0;
  margin-top: 0;
  border-radius: 0 0 6px 6px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
}
.g-modal .modal-dialog .modal-footer .g-footer-buttons-wrap {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.g-modal .modal-dialog .modal-footer .g-footer-buttons-wrap .g-footer-container {
  display: flex;
  align-items: center;
}
.g-modal .modal-dialog .modal-footer .g-footer-buttons-wrap .g-footer-container .g-divider {
  height: 100%;
  background-color: white;
}
.g-modal .modal-dialog .modal-footer .g-footer-buttons-wrap .g-footer-container.right {
  min-width: 0;
}
.g-modal .modal-dialog .modal-footer .g-footer-buttons-wrap .g-footer-container .btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-modal .modal-dialog .modal-footer .g-footer-buttons-wrap .g-footer-container .btn span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.g-modal .modal-dialog .modal-footer:before,
.g-modal .modal-dialog .modal-footer:after {
  content: none;
}
.g-modal .modal-dialog .modal-footer.g-vertical {
  display: table;
  margin-right: auto;
  margin-left: auto;
}
.g-modal .modal-dialog .modal-footer.g-vertical .btn {
  display: block;
  width: 100%;
  margin: 10px auto;
}
.g-modal .modal-dialog .modal-footer.g-horizontal .btn {
  min-width: 100px;
  font-size: 15px;
}
.g-modal .modal-dialog .modal-footer.g-horizontal .btn.g-import-retry {
  float: left;
}
.g-modal.g-dialog-help .modal-dialog.g-modal-autoresize .modal-content {
  height: 100%;
}
.g-modal.g-task-dialog .g-modal-autoresize .modal-content {
  width: auto;
}
.g-modal .g-heading {
  position: static;
  border-bottom: none;
  min-height: 0;
  height: 100%;
}
.g-modal .g-heading .navbar {
  position: relative;
  right: 2px;
  height: 100%;
  border: 0;
}
.g-modal .g-heading .navbar .nav {
  height: 100%;
  display: flex;
  align-items: center;
}
.g-modal .g-heading .navbar .nav > li {
  height: 100%;
  margin: 0 2px;
}
.g-modal .g-heading .navbar .nav > li > a {
  margin-top: 0;
  outline: none;
}
.g-modal .g-heading .navbar .nav > li > a:not(.g-has-alerts):hover,
.g-modal .g-heading .navbar .nav > li > a:not(.g-has-alerts):focus {
  background: none;
}
.g-modal .g-heading .navbar .nav .navigation-buttons .btn {
  width: 73px;
}
.g-modal .g-modal-alert-bar {
  display: none;
  padding: 5px 10px;
}
.g-modal .g-modal-alert-bar.alert {
  margin-bottom: 5px;
  border-radius: 0;
}
.g-modal .modal-body .gwFilter-save-form {
  width: 330px;
}
.g-modal .ui-resizable-handle.ui-resizable-se {
  width: 13px;
  height: 13px;
}
.g-modal.no-padding .modal-dialog .modal-body {
  padding: 0;
}
.g-modal .g-heading .navbar .nav > li .dropdown-menu,
.g-modal-header-buttonpane .dropdown-menu,
.g-popout-header-buttonpane .dropdown-menu {
  margin-top: 6px;
  right: 0;
  left: auto;
  height: auto;
}
.g-modal .g-heading .navbar .nav > li .dropdown-menu:before,
.g-modal-header-buttonpane .dropdown-menu:before,
.g-popout-header-buttonpane .dropdown-menu:before {
  right: 9px;
  left: auto;
}
.g-modal .g-heading .navbar .nav > li .dropdown-menu:after,
.g-modal-header-buttonpane .dropdown-menu:after,
.g-popout-header-buttonpane .dropdown-menu:after {
  right: 10px;
  left: auto;
}
.g-modal .g-heading .navbar .nav > li .dropdown-menu a:hover,
.g-modal-header-buttonpane .dropdown-menu a:hover,
.g-popout-header-buttonpane .dropdown-menu a:hover {
  opacity: 0.5;
}
.g-modal .g-heading .navbar .nav > li .g-tasktray-alerts .dropdown-menu,
.g-modal-header-buttonpane .g-tasktray-alerts .dropdown-menu,
.g-popout-header-buttonpane .g-tasktray-alerts .dropdown-menu {
  padding: 5px;
}
.g-modal .g-heading .navbar .nav > li .g-tasktray-alerts .dropdown-menu li.alert,
.g-modal-header-buttonpane .g-tasktray-alerts .dropdown-menu li.alert,
.g-popout-header-buttonpane .g-tasktray-alerts .dropdown-menu li.alert {
  cursor: auto;
}
.g-modal .g-heading .navbar .nav > li .g-tasktray-alerts .dropdown-menu li.gwValidationSummary-alert-entity-message:hover a,
.g-modal-header-buttonpane .g-tasktray-alerts .dropdown-menu li.gwValidationSummary-alert-entity-message:hover a,
.g-popout-header-buttonpane .g-tasktray-alerts .dropdown-menu li.gwValidationSummary-alert-entity-message:hover a {
  background: #ffb0b0 !important;
}
.g-modal .g-heading .navbar .nav > li .g-tasktray-alerts .dropdown-menu li.gwValidationSummary-alert-entity-message:hover span,
.g-modal-header-buttonpane .g-tasktray-alerts .dropdown-menu li.gwValidationSummary-alert-entity-message:hover span,
.g-popout-header-buttonpane .g-tasktray-alerts .dropdown-menu li.gwValidationSummary-alert-entity-message:hover span {
  color: #333 !important;
  background-color: #ffe8e7 !important;
  border-color: #d90000 !important;
  background: #ffb0b0 !important;
}
.g-modal-header-buttonpane.text-only .g-display-option-button,
.g-popout-header-buttonpane.text-only .g-display-option-button,
.modal-body-wrapper.text-only .g-display-option-button,
.g-heading.text-only .g-display-option-button,
.g-modal-header-buttonpane.buttons-only .g-display-option-text,
.g-popout-header-buttonpane.buttons-only .g-display-option-text,
.modal-body-wrapper.buttons-only .g-display-option-text,
.g-heading.buttons-only .g-display-option-text {
  display: none;
}
.g-modal-header-buttonpane a:hover img,
.g-popout-header-buttonpane a:hover img,
.modal-body-wrapper a:hover img,
.g-heading a:hover img {
  color: #fff;
}
.g-modal-header-buttonpane a:hover img + .g-hover-icon,
.g-popout-header-buttonpane a:hover img + .g-hover-icon,
.modal-body-wrapper a:hover img + .g-hover-icon,
.g-heading a:hover img + .g-hover-icon {
  display: inline;
}
.g-modal-header-buttonpane .g-hover-icon,
.g-popout-header-buttonpane .g-hover-icon,
.modal-body-wrapper .g-hover-icon,
.g-heading .g-hover-icon {
  display: none;
  color: #fff;
}
.g-modal-header-buttonpane,
.g-popout-header-buttonpane {
  order: 1;
  margin-left: auto;
  margin-bottom: 0;
  display: flex;
  height: 100%;
  flex: 0 0 auto;
}
.g-modal-header-buttonpane li,
.g-popout-header-buttonpane li {
  display: flex;
  align-items: center;
  padding: 0;
  flex: 0 0 auto;
}
.g-modal-header-buttonpane li a,
.g-popout-header-buttonpane li a {
  display: flex;
  align-items: center;
}
.g-filter-dialog .gwFilter,
.g-filter-dialog .gwFilter-groups-wrapper {
  height: 100%;
}
.g-filter-dialog .gwFilter .g-filter-button-container,
.g-filter-dialog .gwFilter-groups-wrapper .g-filter-button-container {
  width: auto;
}
.g-filter-dialog .gwFilter-container {
  height: 100%;
}
.g-filter-dialog .gwFilter-actions .g-kg-title-sub-container {
  width: 350px;
  margin-right: 5px;
}
.g-filter-dialog .modal-dialog.g-modal-autoresize .modal-content {
  height: 500px;
}
.g-change-password {
  width: 330px;
  margin: 0;
}
.g-change-password input[type="password"] {
  height: 42px;
}
.g-error-reporter {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.g-error-reporter label {
  white-space: normal;
  flex: 0 0 auto;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: var(--theme-general-text);
}
.g-error-reporter textarea {
  width: 100%;
  flex: 1 1 auto;
  padding: 15px 5px;
  margin-top: 6px;
  resize: none;
}
.bulk-allocation-dialog .modal-dialog.g-modal-autoresize .modal-content {
  width: 100%;
  height: 100%;
  max-width: 730px;
  max-height: 500px;
}
.bulk-allocation-dialog-results {
  position: absolute;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.bulk-allocation-dialog-log-warning {
  font-weight: bold;
  color: #333;
}
.bulk-allocation-dialog-log-error {
  font-weight: bold;
  color: #333;
}
.bulk-allocation-dialog-log-important {
  font-weight: bold;
}
.g-dialog__location-tracker.g-modal .modal-body {
  position: relative;
  padding: 0;
}
.g-dialog__location-tracker.g-modal iframe {
  position: absolute;
  height: 100%;
}
.g-workflow-confirm-dialog.g-modal .modal-dialog .modal-body-with-icon.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.g-workflow-confirm-dialog.g-modal .g-confirmation-question-wrapper {
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.g-workflow-confirm-dialog.g-modal .g-confirmation-question-wrapper .g-confirmation-retype-message-input {
  margin-top: 10px;
}
.g-workflow-confirm-dialog.g-modal .g-confirmation-question-wrapper .g-confirmation-retype-message-input .gwTextBox-textarea {
  padding-top: 0;
}
.g-workflow-confirm-dialog.g-modal .g-confirmation-question-wrapper .g-confirmation-user-message-input {
  margin-top: 10px;
}
.g-workflow-confirm-dialog.g-modal .g-confirmation-question-wrapper .g-confirmation-user-message-input .gwTextBox-textarea {
  padding-top: 0;
}
.g-about-info-section {
  margin-top: 73px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 992px) {
  .g-about-info-section {
    max-width: calc(80vw);
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .g-about-info-section.g-about-info-section-for-disclaimer {
    max-width: calc(80vw);
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .g-about-info-section.g-about-info-section-for-disclaimer {
    max-width: calc(60vw);
  }
}
@media (min-width: 992px) {
  .g-about-info-section.g-about-info-section-for-disclaimer {
    max-width: calc(40vw);
  }
}
.g-about-info-section img {
  max-width: 100%;
}
.g-about-info-section .g-about-item {
  display: flex;
}
@media (max-width: 768px) {
  .g-about-info-section .g-about-item {
    flex-direction: column;
  }
}
.g-about-info-section .g-about-item .g-about-title {
  text-align: right;
  color: #20a2dc;
  width: 200px;
}
@media (max-width: 768px) {
  .g-about-info-section .g-about-item .g-about-title {
    text-align: left;
  }
}
.g-about-info-section .g-about-item .g-about-text {
  margin-left: 8px;
  word-break: break-all;
  flex: 1;
}
@media (max-width: 768px) {
  .g-about-info-section .g-about-item .g-about-text {
    margin-left: 0;
  }
}
.g-about-info-section > div + div {
  margin-top: 8px;
}
.g-usage-message {
  text-indent: 3em;
}
.g-dialog-about-logo {
  width: 228px;
}
.g-dialog-default-help .modal-dialog.g-modal-autoresize .modal-body {
  padding: 48px 10px 51px;
}
.g-dialog-configure-help .g-dialog-configure-help-instructions .cleditorToolbar {
  margin-top: 15px;
}
.g-dialog-configure-help .modal-body ul {
  margin-bottom: 0;
}
.g-dialog-configure-help .modal-body ul li {
  position: relative;
  padding: 5px 0;
  overflow: hidden;
}
.g-dialog-configure-help .modal-body ul li:not(.g-dialog-configure-help-instructions) label {
  position: relative;
  float: left;
}
.modal-content.ui-resizable.ui-draggable {
  min-width: 200px;
}
.g-modal-configurator .modal-content.ui-resizable {
  min-width: 400px !important;
  min-height: 300px !important;
}
.g-modal-configurator .modal-body .g-grid-action-button {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 15px;
  outline: none;
  background: transparent;
  border: none;
  border-radius: 0;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-modal-configurator .modal-body .g-grid-action-button:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-modal-configurator .modal-body .g-grid-action-button:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-modal-configurator .modal-body .g-grid-action-button:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-modal-configurator .modal-body .g-grid-action-button i {
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: var(--theme-icons);
}
.g-modal-theme-configurator .g-modal-body-wrap li {
  height: 42px;
}
.g-modal-theme-configurator .g-modal-body-wrap li:first-child .input-group .form-control {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.g-modal-theme-configurator .g-modal-body-wrap li:first-child .input-group-addon {
  border-top-right-radius: 4px;
}
.g-modal-theme-configurator .g-modal-body-wrap li:last-child .input-group .form-control {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.g-modal-theme-configurator .g-modal-body-wrap li:last-child .input-group-addon {
  border-bottom-right-radius: 4px;
}
.g-modal-theme-configurator.g-modal .modal-dialog .modal-body {
  padding: 0;
  border-radius: 0;
}
.g-modal-theme-configurator .g-modal-body-wrap {
  height: calc(100% - 32px);
  padding: 15px;
}
.g-modal-theme-configurator .g-configure-item-body-wrap {
  position: relative;
  height: 100%;
}
.g-modal-theme-configurator .g-configure-theme__container {
  position: relative;
}
.g-modal-theme-configurator .g-configure-theme__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 6;
  border-radius: 4px;
  background: rgba(246, 246, 246, 0.35);
}
.g-modal-theme-configurator .g-configure-theme__overlay .icon-spin {
  font-size: 24px;
  align-self: center;
}
.g-modal-theme-configurator .g-configure-theme__top-container {
  display: flex;
  padding: 3px 0;
}
.g-modal-theme-configurator .g-configure-theme__title-container {
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
  color: var(--theme-default-button);
}
.g-modal-theme-configurator .g-configure-theme__title-sub-container {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
}
.g-modal-theme-configurator .g-configure-theme__button {
  cursor: pointer;
  color: var(--theme-icons);
  display: flex;
  align-items: center;
}
.g-modal-theme-configurator .g-configure-theme__button span {
  margin-left: 5px;
}
.g-modal-theme-configurator .g-configure-theme__button.disabled {
  opacity: 0.5;
}
.g-modal-theme-configurator .gwSelectBox-container {
  position: relative;
  height: 42px;
  margin-bottom: 10px;
}
.g-modal-body-wrap {
  height: 100%;
}
.g-modal-body-wrap > .g-modal-loading-indicator {
  height: 100%;
}
.g-modal-body-wrap .list-unstyled li {
  display: flex;
}
.g-modal-body-wrap .list-unstyled li.configure-staff-help {
  justify-content: space-between;
}
.g-modal-body-wrap .list-unstyled li.configure-customer-help {
  justify-content: flex-end;
}
.g-modal-configurator-content {
  position: relative;
  height: 100%;
}
.tox .tox-dialog__title,
.popover .popover-title {
  color: var(--theme-value-text);
}
li a.g-restore-button-visible i.g-maximize,
li a:not(.g-restore-button-visible) i.g-restore {
  display: none;
}
.g-maximized-dialog {
  width: 100% !important;
  height: 100% !important;
}
.g-maximized-resized-dialog {
  top: 50px !important;
  left: 50px !important;
  width: calc(100% - 100px) !important;
  height: calc(100% - 100px) !important;
}
.g-maximized-dragged-dialog {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.modal-content.ui-resizable.ui-state-disabled {
  opacity: 1;
}
div.tox .tox-dialog__title {
  max-height: 30px;
  line-height: 30px;
}
div.tox .tox-dialog__title,
.popover .popover-title {
  display: block;
  font-size: 22px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-modal .modal-dialog .modal-footer .btn,
.tox-dialog__footer .tox-button--secondary {
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: transparent;
  color: var(--button-text-color);
  border-radius: 5px;
}
.g-modal .modal-dialog .modal-footer .btn a,
.tox-dialog__footer .tox-button--secondary a,
.g-modal .modal-dialog .modal-footer .btn label,
.tox-dialog__footer .tox-button--secondary label {
  color: var(--button-text-color);
}
.g-modal .modal-dialog .modal-footer .btn i,
.tox-dialog__footer .tox-button--secondary i {
  color: var(--button-text-color);
}
.g-modal .modal-dialog .modal-footer .btn i.chevron,
.tox-dialog__footer .tox-button--secondary i.chevron {
  color: var(--button-text-color-lighten10);
}
.open > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]),
.open > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]),
.active > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]),
.active > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]),
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).pressed,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).pressed,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).popover-shown,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]) i,
.open > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]) i,
.active > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]) i,
.active > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]) i,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).pressed i,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).pressed i,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).popover-shown i,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):focus,
.open > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):focus,
.active > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):focus,
.active > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):focus,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).pressed:focus,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).pressed:focus,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).popover-shown:focus,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):focus i,
.open > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):focus i,
.active > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):focus i,
.active > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):focus i,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).pressed:focus i,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).pressed:focus i,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).popover-shown:focus i,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover,
  .open > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover,
  .active > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover,
  .active > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).pressed:hover,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).pressed:hover,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).popover-shown:hover,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--button-background-hover-color-start);
    color: var(--button-text-hover-color);
  }
}
.open > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):active,
.open > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):active,
.active > .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):active,
.active > .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):active,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).pressed:active,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).pressed:active,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]).popover-shown:active,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--button-background-pressed-color-start);
  color: var(--button-text-hover-color);
}
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):focus,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):focus {
  background-color: transparent;
  color: var(--button-text-color);
  border: 1px dashed #333;
  border-radius: 5px;
}
@media (hover) {
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover {
    color: var(--button-text-hover-color);
    background-color: var(--button-background-hover-color-start);
    border-radius: 5px;
  }
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover a,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover a,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover label,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover label,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover span,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover span {
    color: var(--button-text-hover-color) !important;
  }
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active,
  .tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):active,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):active {
  color: var(--button-text-hover-color);
  background-color: var(--button-background-pressed-color-start);
  border-radius: 5px;
}
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):active a,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):active a,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):active i,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):active i,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):active label,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):active label,
.g-modal .modal-dialog .modal-footer .btn:not(.disabled):not([disabled]):active span,
.tox-dialog__footer .tox-button--secondary:not(.disabled):not([disabled]):active span {
  color: var(--button-text-hover-color) !important;
}
.g-modal .modal-dialog .modal-footer .btn.disabled,
.tox-dialog__footer .tox-button--secondary.disabled,
.g-modal .modal-dialog .modal-footer .btn[disabled],
.tox-dialog__footer .tox-button--secondary[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active,
.tox-dialog__footer .tox-button--secondary.disabled:active,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active,
.tox-dialog__footer .tox-button--secondary[disabled]:active {
  color: #999;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active a,
.tox-dialog__footer .tox-button--secondary.disabled:active a,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active a,
.tox-dialog__footer .tox-button--secondary[disabled]:active a,
.g-modal .modal-dialog .modal-footer .btn.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.tox-dialog__footer .tox-button--secondary.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.tox-dialog__footer .tox-button--secondary[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-modal .modal-dialog .modal-footer .btn.disabled:active label,
.tox-dialog__footer .tox-button--secondary.disabled:active label,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active label,
.tox-dialog__footer .tox-button--secondary[disabled]:active label,
.g-modal .modal-dialog .modal-footer .btn.disabled:active span,
.tox-dialog__footer .tox-button--secondary.disabled:active span,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active span,
.tox-dialog__footer .tox-button--secondary[disabled]:active span {
  color: #999;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active i,
.tox-dialog__footer .tox-button--secondary.disabled:active i,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i,
.tox-dialog__footer .tox-button--secondary[disabled]:active i {
  opacity: 1;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active i.g-menu-item-always-enabled-icon,
.tox-dialog__footer .tox-button--secondary.disabled:active i.g-menu-item-always-enabled-icon,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i.g-menu-item-always-enabled-icon,
.tox-dialog__footer .tox-button--secondary[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active i.g-menu-item-always-enabled-icon:focus,
.tox-dialog__footer .tox-button--secondary.disabled:active i.g-menu-item-always-enabled-icon:focus,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i.g-menu-item-always-enabled-icon:focus,
.tox-dialog__footer .tox-button--secondary[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active i.g-menu-item-always-enabled-icon:hover,
.tox-dialog__footer .tox-button--secondary.disabled:active i.g-menu-item-always-enabled-icon:hover,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i.g-menu-item-always-enabled-icon:hover,
.tox-dialog__footer .tox-button--secondary[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active i.g-menu-item-always-enabled-icon:active,
.tox-dialog__footer .tox-button--secondary.disabled:active i.g-menu-item-always-enabled-icon:active,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i.g-menu-item-always-enabled-icon:active,
.tox-dialog__footer .tox-button--secondary[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-modal .modal-dialog .modal-footer .btn.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.tox-dialog__footer .tox-button--secondary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.tox-dialog__footer .tox-button--secondary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-modal .modal-dialog .modal-footer .btn.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.tox-dialog__footer .tox-button--secondary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.g-modal .modal-dialog .modal-footer .btn[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.tox-dialog__footer .tox-button--secondary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.g-modal .modal-dialog .modal-footer .btn.btn-toggle:not(.active),
.tox-dialog__footer .tox-button--secondary.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.g-modal .modal-dialog .modal-footer .btn.btn-toggle.active,
.tox-dialog__footer .tox-button--secondary.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.g-modal .modal-dialog .modal-footer .btn-primary,
.tox-dialog__footer .tox-button {
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: var(--primary-button-background-color-start);
  color: var(--primary-button-text-color);
  border-radius: 5px;
}
.g-modal .modal-dialog .modal-footer .btn-primary a,
.tox-dialog__footer .tox-button a,
.g-modal .modal-dialog .modal-footer .btn-primary label,
.tox-dialog__footer .tox-button label {
  color: var(--primary-button-text-color);
}
.g-modal .modal-dialog .modal-footer .btn-primary i,
.tox-dialog__footer .tox-button i {
  color: var(--primary-button-text-color);
}
.g-modal .modal-dialog .modal-footer .btn-primary i.chevron,
.tox-dialog__footer .tox-button i.chevron {
  color: var(--primary-button-text-color-lighten10);
}
.open > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]),
.open > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]),
.active > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]),
.active > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]),
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).pressed,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).pressed,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).popover-shown,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]) i,
.open > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]) i,
.active > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]) i,
.active > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]) i,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).pressed i,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).pressed i,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).popover-shown i,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):focus,
.open > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):focus,
.active > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):focus,
.active > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):focus,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).pressed:focus,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).pressed:focus,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).popover-shown:focus,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):focus i,
.open > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):focus i,
.active > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):focus i,
.active > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):focus i,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).pressed:focus i,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).pressed:focus i,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).popover-shown:focus i,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover,
  .open > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover,
  .active > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover,
  .active > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).pressed:hover,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]).pressed:hover,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).popover-shown:hover,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--primary-button-background-hover-color-start);
    color: var(--primary-button-text-hover-color);
  }
}
.open > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):active,
.open > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):active,
.active > .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):active,
.active > .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):active,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).pressed:active,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).pressed:active,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]).popover-shown:active,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--primary-button-background-pressed-color-start);
  color: var(--primary-button-text-hover-color);
}
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):focus,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]):focus {
  background-color: var(--primary-button-background-color-start);
  color: var(--primary-button-text-color);
  border: 1px dashed #333;
  border-radius: 5px;
}
@media (hover) {
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover {
    color: var(--primary-button-text-hover-color);
    background-color: var(--primary-button-background-hover-color-start);
    border-radius: 5px;
  }
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover a,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover a,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover label,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover label,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover span,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover span {
    color: var(--primary-button-text-hover-color) !important;
  }
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active,
  .tox-dialog__footer .tox-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):active,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]):active {
  color: var(--primary-button-text-hover-color);
  background-color: var(--primary-button-background-pressed-color-start);
  border-radius: 5px;
}
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):active a,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]):active a,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):active i,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]):active i,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):active label,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]):active label,
.g-modal .modal-dialog .modal-footer .btn-primary:not(.disabled):not([disabled]):active span,
.tox-dialog__footer .tox-button:not(.disabled):not([disabled]):active span {
  color: var(--primary-button-text-hover-color) !important;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled,
.tox-dialog__footer .tox-button.disabled,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled],
.tox-dialog__footer .tox-button[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active,
.tox-dialog__footer .tox-button.disabled:active,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active,
.tox-dialog__footer .tox-button[disabled]:active {
  color: #999;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active a,
.tox-dialog__footer .tox-button.disabled:active a,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active a,
.tox-dialog__footer .tox-button[disabled]:active a,
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.tox-dialog__footer .tox-button.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.tox-dialog__footer .tox-button[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active label,
.tox-dialog__footer .tox-button.disabled:active label,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active label,
.tox-dialog__footer .tox-button[disabled]:active label,
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active span,
.tox-dialog__footer .tox-button.disabled:active span,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active span,
.tox-dialog__footer .tox-button[disabled]:active span {
  color: #999;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i,
.tox-dialog__footer .tox-button.disabled:active i,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i,
.tox-dialog__footer .tox-button[disabled]:active i {
  opacity: 1;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i.g-menu-item-always-enabled-icon,
.tox-dialog__footer .tox-button.disabled:active i.g-menu-item-always-enabled-icon,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon,
.tox-dialog__footer .tox-button[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i.g-menu-item-always-enabled-icon:focus,
.tox-dialog__footer .tox-button.disabled:active i.g-menu-item-always-enabled-icon:focus,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:focus,
.tox-dialog__footer .tox-button[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i.g-menu-item-always-enabled-icon:hover,
.tox-dialog__footer .tox-button.disabled:active i.g-menu-item-always-enabled-icon:hover,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:hover,
.tox-dialog__footer .tox-button[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i.g-menu-item-always-enabled-icon:active,
.tox-dialog__footer .tox-button.disabled:active i.g-menu-item-always-enabled-icon:active,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:active,
.tox-dialog__footer .tox-button[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.tox-dialog__footer .tox-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.tox-dialog__footer .tox-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-modal .modal-dialog .modal-footer .btn-primary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.tox-dialog__footer .tox-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.g-modal .modal-dialog .modal-footer .btn-primary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.tox-dialog__footer .tox-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.g-modal .modal-dialog .modal-footer .btn-primary.btn-toggle:not(.active),
.tox-dialog__footer .tox-button.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.g-modal .modal-dialog .modal-footer .btn-primary.btn-toggle.active,
.tox-dialog__footer .tox-button.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.g-modal-open {
  position: fixed;
  pointer-events: none;
}
[data-modal-active=true] {
  pointer-events: auto;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal-in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
}
.modal-content {
  position: relative;
  background-clip: padding-box;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.typeahead.dropdown-menu {
  width: 350px;
  z-index: 2000;
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  float: none;
  min-width: 250px;
  padding: 0;
  margin-bottom: 0;
  font-size: 13px;
  list-style-type: none;
  border-color: #999;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.6);
}
.dropdown-menu.pull-left {
  right: auto;
}
.dropdown-menu.pull-right {
  left: auto;
}
.dropdown-menu .divider,
.dropdown-menu .divider:hover {
  margin: 5px 0;
  background-color: #aaa;
}
.dropdown-menu .dropdown-header {
  font-size: 13px;
  color: var(--theme-default-button);
  padding: 2px 8px;
  height: auto;
  line-height: 28px;
  border-top: 1px solid #aaaaaa;
  cursor: default;
  margin-bottom: 0px;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
  margin-left: 5px;
  margin-right: 5px;
}
.dropdown-menu .g-loggedin-info-section + .dropdown-header {
  border-top: 0;
}
.dropdown-menu .g-loggedin-info-section {
  border-bottom: 1px solid #aaa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.alert):not(.dropdown-header) {
  margin-bottom: 5px;
}
.dropdown-menu > li.g-jump-add-remove > a {
  padding: 0;
}
.dropdown-menu > li > a,
.dropdown-menu > li > div > a,
.dropdown-menu > li > span,
.dropdown-menu > span,
.dropdown-menu > li > label,
.dropdown-menu > li.checkbox-item,
.dropdown-menu > li > .g-menu-item--colorscheme {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  clear: both;
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  color: var(--theme-general-text);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  position: relative;
}
.dropdown-menu > li > a.g-pin-target,
.dropdown-menu > li > div > a.g-pin-target,
.dropdown-menu > li > span.g-pin-target,
.dropdown-menu > span.g-pin-target,
.dropdown-menu > li > label.g-pin-target,
.dropdown-menu > li.checkbox-item.g-pin-target,
.dropdown-menu > li > .g-menu-item--colorscheme.g-pin-target {
  padding-right: 0;
}
.dropdown-menu > li > a .icon-spinner.icon-spin,
.dropdown-menu > li > div > a .icon-spinner.icon-spin,
.dropdown-menu > li > span .icon-spinner.icon-spin,
.dropdown-menu > span .icon-spinner.icon-spin,
.dropdown-menu > li > label .icon-spinner.icon-spin,
.dropdown-menu > li.checkbox-item .icon-spinner.icon-spin,
.dropdown-menu > li > .g-menu-item--colorscheme .icon-spinner.icon-spin {
  margin-right: 5px;
}
.dropdown-menu > li > label {
  margin-bottom: 0;
}
.dropdown-menu > li > label > input[type="radio"] {
  margin-top: 0;
  margin-right: 6px;
  cursor: pointer;
}
.dropdown-menu > li > a > input[type="radio"] {
  margin: 0;
  line-height: normal;
  height: calc(100% - 1em);
}
.dropdown-menu span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-menu > li.checkbox-item {
  padding-left: 9px;
  overflow: hidden;
}
.dropdown-menu > li.checkbox-item > label {
  font-size: 13px;
}
.dropdown-menu > li.checkbox-item > label > span {
  line-height: normal;
}
.dropdown-menu > li .g-jump-item > a {
  padding: 0 2px;
}
.dropdown-menu > li > a,
.dropdown-menu > li.checkbox-item,
.dropdown-menu > li > .g-dropdown-list-item,
.dropdown-menu > li > div > a {
  display: flex;
  align-items: center;
}
.dropdown-menu > li > span {
  display: flex;
  align-items: center;
}
.dropdown-menu > li .g-pin-target span,
.dropdown-menu > li .g-no-pin span,
.dropdown-menu > li .g-jump-item .g-menu-item-target {
  padding-right: 10px;
}
.dropdown-menu:not(.g-display-option) > li:last-child,
.dropdown-menu:not(.g-display-option) > li:last-child > a,
.dropdown-menu:not(.g-display-option) > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.dropdown-menu:not(.g-display-option) > li:first-child,
.dropdown-menu:not(.g-display-option) > li:first-child > a,
.dropdown-menu:not(.g-display-option) > li:first-child > span {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.dropdown-menu:not(.g-display-option) > li:last-child {
  margin-bottom: 5px;
}
.dropdown-menu:not(.g-display-option) > li:first-child:not(.g-loggedin-info-section):not(.dropdown-header),
.dropdown-menu:not(.g-display-option) > li:nth-child(2):not(.dropdown-header):not(.alert) {
  margin-top: 5px;
}
.dropdown-menu.g-dynamic-menu-list > li.doc-menu-item {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-menu.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation {
  display: inline-flex;
  overflow-y: hidden;
  width: 100%;
}
.dropdown-menu.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation label {
  margin: 0;
}
.dropdown-menu label.dropdown-header,
.dropdown-menu li.dropdown-header {
  cursor: default;
}
.dropdown-menu label a.disabled,
.dropdown-menu li a.disabled,
.dropdown-menu label a .disabled,
.dropdown-menu li a .disabled {
  cursor: default;
}
.dropdown-menu label.disabled,
.dropdown-menu li.disabled,
.dropdown-menu label .disabled,
.dropdown-menu li .disabled {
  color: #999;
  cursor: text;
}
.dropdown-menu label.disabled:hover,
.dropdown-menu li.disabled:hover,
.dropdown-menu label .disabled:hover,
.dropdown-menu li .disabled:hover,
.dropdown-menu label.disabled:focus,
.dropdown-menu li.disabled:focus,
.dropdown-menu label .disabled:focus,
.dropdown-menu li .disabled:focus {
  background-color: transparent;
  color: #999;
}
.dropdown-menu label.disabled.g-error-text,
.dropdown-menu li.disabled.g-error-text,
.dropdown-menu label .disabled.g-error-text,
.dropdown-menu li .disabled.g-error-text {
  color: #ff0000;
}
.dropdown-menu label.disabled.g-error-actions,
.dropdown-menu li.disabled.g-error-actions,
.dropdown-menu label .disabled.g-error-actions,
.dropdown-menu li .disabled.g-error-actions {
  display: block;
  white-space: normal;
  word-break: break-word;
  height: auto;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 0;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu > li {
  margin-top: 0 !important;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu > li:last-child {
  margin-bottom: 0;
}
.dropdown-menu .dropdown-submenu > .dropdown-menu > li > a input {
  margin: 0 6px 0 0;
}
.dropdown-menu .dropdown-submenu i.chevron {
  margin: -2px 6px 0 0;
}
.dropdown-menu .dropdown-submenu i.chevron.icon-chevron-down {
  display: inline;
}
.dropdown-menu .dropdown-submenu.open > a > i.icon-chevron-down {
  transform: rotate(180deg) translateY(-3px);
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.dropdown-menu-shared {
  float: none;
  min-width: 250px;
  padding: 0;
  margin-bottom: 0;
  font-size: 13px;
  list-style-type: none;
  border-color: #999;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.6);
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  padding: 0;
  font-weight: 400;
  line-height: 1.42857143;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 14px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.6);
  z-index: 1060;
  max-width: none;
  font-family: inherit;
  font-style: inherit;
}
.popover .popover-title {
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
  height: 38px;
}
.popover .popover-title > div {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.popover .popover-title .popover-title-button {
  margin-left: auto;
  padding: 10px 15px;
  color: var(--theme-value-text);
  display: flex;
  align-items: center;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.popover .popover-title .popover-title-button:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.popover .popover-title .popover-title-button:hover {
  opacity: 0.5;
  text-decoration: none;
}
.popover .popover-title .popover-title-button:active {
  opacity: 0.3;
  text-decoration: none;
}
.popover.right {
  margin-left: 7px;
}
.popover.right > .arrow {
  top: 50%;
  left: -7px;
  margin-top: -7px;
  border-left-width: 0;
  border-right-color: #999;
}
.popover.right > .arrow:after {
  content: " ";
  border-left-width: 0;
  bottom: -6px;
  left: 1px;
  border-right-color: var(--popover-background-color);
}
.popover.left {
  margin-left: -10px;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  border-right-width: 0;
  right: -7px;
  margin-top: -7px;
  border-left-color: #999;
}
.popover.left > .arrow:after {
  content: " ";
  border-right-width: 0;
  right: 1px;
  bottom: -6px;
  border-left-color: var(--popover-background-color);
}
.popover.bottom {
  margin-top: 10px;
}
.popover.bottom > .arrow {
  left: 50%;
  border-top-width: 0;
  top: -7px;
  margin-left: -8px;
  border-bottom-color: #999;
}
.popover.bottom > .arrow:after {
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
  top: 1px;
  margin-left: -6px;
  border-bottom-color: var(--popover-background-color);
}
.popover.top {
  margin-top: -10px;
}
.popover.top > .arrow {
  border-bottom-width: 0;
  left: 50%;
  bottom: -7px;
  margin-left: -7px;
  border-top-color: #999;
}
.popover.top > .arrow:after {
  content: " ";
  border-bottom-width: 0;
  bottom: 1px;
  margin-left: -6px;
  border-top-color: var(--popover-background-color);
}
.popover.g-header-popover,
.popover.g-grid-action-popover {
  min-width: 270px;
}
.popover.g-grid-action-popover.top {
  margin-top: -8px;
}
.popover > .arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  display: block;
  border-width: 7px;
}
.popover > .arrow:after {
  content: "";
  border-width: 10px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
}
.popover li:hover > .submenu-arrow:after {
  border-left-color: #fff;
}
.popover > .gwDynamicMenu {
  max-width: calc(100vw - 22px);
}
.popover > .gwDynamicMenu > ul {
  padding-left: 0;
}
.popover > .gwDynamicMenu > ul > li > a > input {
  margin: 0 6px 0 0;
}
.popover-content > .gwDynamicMenu,
.dropdown.open > .dropdown-menu {
  border-radius: 5px;
  opacity: 1;
  visibility: visible;
}
.popover-content {
  height: initial;
  padding: 0;
  border-radius: 5px;
  background-color: var(--popover-background-color);
}
.popover-content ul {
  -webkit-transform: translate3d(0, 0, 0);
  float: none;
  min-width: 250px;
  padding: 0;
  margin-bottom: 0;
  font-size: 13px;
  list-style-type: none;
  border-color: #999;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.6);
  box-shadow: none;
}
.popover-content ul.pull-left {
  right: auto;
}
.popover-content ul.pull-right {
  left: auto;
}
.popover-content ul .divider,
.popover-content ul .divider:hover {
  margin: 5px 0;
  background-color: #aaa;
}
.popover-content ul .dropdown-header {
  font-size: 13px;
  color: var(--theme-default-button);
  padding: 2px 8px;
  height: auto;
  line-height: 28px;
  border-top: 1px solid #aaaaaa;
  cursor: default;
  margin-bottom: 0px;
}
.popover-content ul .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
  margin-left: 0;
  margin-right: 0;
}
.popover-content ul > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header) {
  margin-left: 5px;
  margin-right: 5px;
}
.popover-content ul .g-loggedin-info-section + .dropdown-header {
  border-top: 0;
}
.popover-content ul .g-loggedin-info-section {
  border-bottom: 1px solid #aaa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.popover-content ul > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.alert):not(.dropdown-header) {
  margin-bottom: 5px;
}
.popover-content ul > li.g-jump-add-remove > a {
  padding: 0;
}
.popover-content ul > li > a,
.popover-content ul > li > div > a,
.popover-content ul > li > span,
.popover-content ul > span,
.popover-content ul > li > label,
.popover-content ul > li.checkbox-item,
.popover-content ul > li > .g-menu-item--colorscheme {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  clear: both;
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  color: var(--theme-general-text);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  position: relative;
}
.popover-content ul > li > a.g-pin-target,
.popover-content ul > li > div > a.g-pin-target,
.popover-content ul > li > span.g-pin-target,
.popover-content ul > span.g-pin-target,
.popover-content ul > li > label.g-pin-target,
.popover-content ul > li.checkbox-item.g-pin-target,
.popover-content ul > li > .g-menu-item--colorscheme.g-pin-target {
  padding-right: 0;
}
.popover-content ul > li > a .icon-spinner.icon-spin,
.popover-content ul > li > div > a .icon-spinner.icon-spin,
.popover-content ul > li > span .icon-spinner.icon-spin,
.popover-content ul > span .icon-spinner.icon-spin,
.popover-content ul > li > label .icon-spinner.icon-spin,
.popover-content ul > li.checkbox-item .icon-spinner.icon-spin,
.popover-content ul > li > .g-menu-item--colorscheme .icon-spinner.icon-spin {
  margin-right: 5px;
}
.popover-content ul > li > label {
  margin-bottom: 0;
}
.popover-content ul > li > label > input[type="radio"] {
  margin-top: 0;
  margin-right: 6px;
  cursor: pointer;
}
.popover-content ul > li > a > input[type="radio"] {
  margin: 0;
  line-height: normal;
  height: calc(100% - 1em);
}
.popover-content ul span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popover-content ul > li.checkbox-item {
  padding-left: 9px;
  overflow: hidden;
}
.popover-content ul > li.checkbox-item > label {
  font-size: 13px;
}
.popover-content ul > li.checkbox-item > label > span {
  line-height: normal;
}
.popover-content ul > li .g-jump-item > a {
  padding: 0 2px;
}
.popover-content ul > li > a,
.popover-content ul > li.checkbox-item,
.popover-content ul > li > .g-dropdown-list-item,
.popover-content ul > li > div > a {
  display: flex;
  align-items: center;
}
.popover-content ul > li > span {
  display: flex;
  align-items: center;
}
.popover-content ul > li .g-pin-target span,
.popover-content ul > li .g-no-pin span,
.popover-content ul > li .g-jump-item .g-menu-item-target {
  padding-right: 10px;
}
.popover-content ul:not(.g-display-option) > li:last-child,
.popover-content ul:not(.g-display-option) > li:last-child > a,
.popover-content ul:not(.g-display-option) > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.popover-content ul:not(.g-display-option) > li:first-child,
.popover-content ul:not(.g-display-option) > li:first-child > a,
.popover-content ul:not(.g-display-option) > li:first-child > span {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.popover-content ul:not(.g-display-option) > li:last-child {
  margin-bottom: 5px;
}
.popover-content ul:not(.g-display-option) > li:first-child:not(.g-loggedin-info-section):not(.dropdown-header),
.popover-content ul:not(.g-display-option) > li:nth-child(2):not(.dropdown-header):not(.alert) {
  margin-top: 5px;
}
.popover-content ul.g-dynamic-menu-list > li.doc-menu-item {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.popover-content ul.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation {
  display: inline-flex;
  overflow-y: hidden;
  width: 100%;
}
.popover-content ul.g-dynamic-menu-list > li.dropdown-submenu > .dropdown-menu > li > .g-click-stop-propagation label {
  margin: 0;
}
.popover-content ul label.dropdown-header,
.popover-content ul li.dropdown-header {
  cursor: default;
}
.popover-content ul label a.disabled,
.popover-content ul li a.disabled,
.popover-content ul label a .disabled,
.popover-content ul li a .disabled {
  cursor: default;
}
.popover-content ul label.disabled,
.popover-content ul li.disabled,
.popover-content ul label .disabled,
.popover-content ul li .disabled {
  color: #999;
  cursor: text;
}
.popover-content ul label.disabled:hover,
.popover-content ul li.disabled:hover,
.popover-content ul label .disabled:hover,
.popover-content ul li .disabled:hover,
.popover-content ul label.disabled:focus,
.popover-content ul li.disabled:focus,
.popover-content ul label .disabled:focus,
.popover-content ul li .disabled:focus {
  background-color: transparent;
  color: #999;
}
.popover-content ul label.disabled.g-error-text,
.popover-content ul li.disabled.g-error-text,
.popover-content ul label .disabled.g-error-text,
.popover-content ul li .disabled.g-error-text {
  color: #ff0000;
}
.popover-content ul label.disabled.g-error-actions,
.popover-content ul li.disabled.g-error-actions,
.popover-content ul label .disabled.g-error-actions,
.popover-content ul li .disabled.g-error-actions {
  display: block;
  white-space: normal;
  word-break: break-word;
  height: auto;
}
.popover-content ul .dropdown-submenu > .dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 0;
}
.popover-content ul .dropdown-submenu > .dropdown-menu > li {
  margin-top: 0 !important;
}
.popover-content ul .dropdown-submenu > .dropdown-menu > li:last-child {
  margin-bottom: 0;
}
.popover-content ul .dropdown-submenu > .dropdown-menu > li > a input {
  margin: 0 6px 0 0;
}
.popover-content ul .dropdown-submenu i.chevron {
  margin: -2px 6px 0 0;
}
.popover-content ul .dropdown-submenu i.chevron.icon-chevron-down {
  display: inline;
}
.popover-content ul .dropdown-submenu.open > a > i.icon-chevron-down {
  transform: rotate(180deg) translateY(-3px);
}
.g-grid-error {
  margin: 0;
  font-size: 16px;
  text-align: center;
  border-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.g-grid-error span {
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
}
.g-searchlist-notifications {
  position: inherit;
  margin-top: 1px;
  margin-left: 1px;
}
.gwDataGrid__notifications {
  z-index: 6;
  white-space: nowrap;
  cursor: pointer;
  position: absolute;
  top: -14px;
  left: 6px;
  line-height: normal;
}
.gwDataGrid__notifications .label-danger {
  min-width: 18px;
  background-color: #d90000;
  height: 18px;
}
.gwDataGrid__notifications .label-danger i {
  font-size: 10px;
}
.gwDataGrid__notifications .label-danger span {
  font-size: 12px;
  font-weight: normal;
}
.grid__actions-filter,
.gwDataGrid__actions-filter,
.gwDataMappingSourceGrid__actions-filter,
.gwDataMappingTargetGrid__actions-filter {
  z-index: 6;
  white-space: nowrap;
  cursor: pointer;
  margin: 2px 0 0 0;
}
.grid__actions-filter .g-hidden-filter,
.gwDataGrid__actions-filter .g-hidden-filter,
.gwDataMappingSourceGrid__actions-filter .g-hidden-filter,
.gwDataMappingTargetGrid__actions-filter .g-hidden-filter,
.grid__actions-filter .g-active-action-filter,
.gwDataGrid__actions-filter .g-active-action-filter,
.gwDataMappingSourceGrid__actions-filter .g-active-action-filter,
.gwDataMappingTargetGrid__actions-filter .g-active-action-filter,
.grid__actions-filter .g-dormant-action-filter,
.gwDataGrid__actions-filter .g-dormant-action-filter,
.gwDataMappingSourceGrid__actions-filter .g-dormant-action-filter,
.gwDataMappingTargetGrid__actions-filter .g-dormant-action-filter {
  width: 30px;
  line-height: 23px;
  padding: 0;
  font-size: 14px;
  vertical-align: middle;
  display: block;
  cursor: pointer;
}
.grid__actions-filter .g-dormant-action-filter,
.gwDataGrid__actions-filter .g-dormant-action-filter,
.gwDataMappingSourceGrid__actions-filter .g-dormant-action-filter,
.gwDataMappingTargetGrid__actions-filter .g-dormant-action-filter {
  opacity: 0.5;
}
.grid__actions-filter .g-dormant-action-filter:hover,
.gwDataGrid__actions-filter .g-dormant-action-filter:hover,
.gwDataMappingSourceGrid__actions-filter .g-dormant-action-filter:hover,
.gwDataMappingTargetGrid__actions-filter .g-dormant-action-filter:hover {
  opacity: 1;
}
.grid__actions-filter .g-active-action-filter:hover,
.gwDataGrid__actions-filter .g-active-action-filter:hover,
.gwDataMappingSourceGrid__actions-filter .g-active-action-filter:hover,
.gwDataMappingTargetGrid__actions-filter .g-active-action-filter:hover {
  opacity: 0.5;
}
.g-dormant-column-filter,
.g-active-column-filter {
  float: right;
  font-size: 14px;
  cursor: pointer;
  margin: 0px 3px;
  z-index: 1;
}
.g-dormant-column-filter {
  opacity: 0.5;
}
.g-dormant-column-filter:hover {
  opacity: 1;
}
.g-active-column-filter:hover {
  opacity: 0.5;
}
.g-grid-actions {
  min-width: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}
.g-grid-actions ul {
  margin-bottom: 0;
  margin-left: 0;
}
.g-grid-actions li {
  height: auto;
  position: relative;
  padding: 0;
}
.g-grid-actions .g-search-results-loading {
  padding: 5px 10px;
  color: #FFFFFF;
}
.g-grid-actions a,
.g-grid-actions button {
  display: block;
  font-size: 16px;
  background: var(--tiles-large-background-brush);
  border: 2px solid var(--tiles-large-background-brush);
}
.g-grid-actions a,
.g-grid-actions button,
.g-grid-actions .g-menu-item-icon {
  color: #FFFFFF;
}
.g-grid-actions a {
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  margin-right: 4px;
  float: left;
}
.g-grid-actions a:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
  border-color: var(--tiles-large-background-brush-darken15);
}
.g-grid-action-popover .popover-content ul > li {
  position: relative;
}
.g-icon-section-container {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  right: 0;
  border-bottom: 1px solid var(--grid-viewport-border-color);
  border-right: 1px solid var(--grid-viewport-border-color);
  background-color: var(--grid-viewport-alternating-row-color);
}
.g-icon-section-container .g-dynamic-icon-list {
  padding: 0;
  margin: 0;
  float: left;
}
.g-action-icon-item {
  height: 100px;
  width: 100px;
  display: block;
  float: left;
}
.g-action-icon-item .g-menu-item-icon-container {
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--theme-value-text);
  float: left;
}
.g-action-icon-item .g-menu-item-icon-container .g-action-icon {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -90%);
}
.g-action-icon-item .g-menu-item-icon-container i.g-action-icon {
  font-size: 40px;
}
.g-action-icon-item .g-menu-item-icon-container img.g-action-icon {
  height: 40px;
  width: 40px;
}
.g-action-icon-item .g-menu-item-icon-container .g-action-icon-number-container {
  position: absolute;
  height: 15px;
  width: 15px;
  left: 27%;
  top: 12%;
}
.g-action-icon-item .g-menu-item-icon-container .g-action-icon-number-container.g-icon-number {
  background: #d8e0eb;
}
.g-action-icon-item .g-menu-item-icon-container .g-action-icon-number-container.g-icon-number > span {
  color: var(--icons-color);
}
.g-action-icon-item .g-menu-item-icon-container .g-action-icon-number-container > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  font-size: 12px;
  font-weight: bold;
}
.g-action-icon-item .g-menu-item-icon-container .g-action-icon-text-container {
  height: 40%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0 8px;
  text-align: center;
}
.g-action-icon-item .g-menu-item-icon-container .g-action-icon-text-container > span:not(.g-submenu-action-icon-text) {
  display: block;
  display: -webkit-box;
  height: 35px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.g-action-icon-item .g-menu-item-icon-container.disabled {
  color: #999;
  cursor: default;
}
.g-action-icon-item:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.g-grid-icon-item-popover.popover.top {
  margin-top: 4px;
}
.g-grid-icon-item-popover.popover.bottom {
  margin-top: -17px;
}
#toast-container.gwSearchList__drop-message,
#toast-container.gwDataGrid__drop-message {
  position: absolute;
  bottom: 14px;
  left: 14px;
  margin: 0;
  height: 38px;
  font-size: 12px;
}
#toast-container.gwSearchList__drop-message .toast,
#toast-container.gwDataGrid__drop-message .toast {
  padding: 0 30px 0 37px;
}
#toast-container.gwSearchList__drop-message .toast-icon,
#toast-container.gwDataGrid__drop-message .toast-icon {
  font-size: 14px;
  top: initial;
  margin-top: initial;
  line-height: 38px;
}
#toast-container.gwSearchList__drop-message .toast-message,
#toast-container.gwDataGrid__drop-message .toast-message {
  line-height: 38px;
}
#toast-container.gwSearchList__drop-message .toast-close-button,
#toast-container.gwDataGrid__drop-message .toast-close-button {
  font-size: 14px;
  text-shadow: none;
}
input[type="checkbox"],
input[type="radio"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: default;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control {
  height: auto;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px;
  }
}
.form-group {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
}
.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group .form-control:focus {
  z-index: 3;
}
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:last-child > .btn:not(:last-child),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}
.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}
.form-control {
  padding-right: 7px;
  padding-left: 7px;
  box-shadow: none;
}
a.form-control:focus,
input.form-control:focus,
textarea.form-control:focus {
  box-shadow: none;
}
.input-group {
  width: 100%;
  height: 100%;
}
.input-group .form-control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 18px;
  padding-right: 30px;
  border-radius: 4px;
}
.input-group .form-control:first-child {
  padding-right: 26px;
  border-radius: 4px;
}
.input-group-addon {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-align: center;
  display: table;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  line-height: 100%;
  cursor: pointer;
  z-index: 5;
  background: none;
  border: none;
}
.input-group-addon i {
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: default;
}
.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: default;
}
.gwTextBox > input,
.gwNumeric > input,
.importTaskPage .g-control-wrap input,
.g-mapping-name-input {
  width: 100%;
  height: 100%;
  padding-top: 18px;
  margin-bottom: 0;
}
.input-group.no-bottom-radius .form-control:first-child {
  border-bottom-left-radius: 0;
}
.input-group.no-bottom-radius .input-group-addon:last-child {
  border-bottom-right-radius: 0;
}
.gwTextBox-textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="datetime-local"],
input[type="time"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  padding-bottom: 3px;
  color: var(--input-control-text-color);
}
.gwTextBox-textarea.alert-danger,
input[type="text"].alert-danger,
input[type="password"].alert-danger,
input[type="datetime"].alert-danger,
input[type="date"].alert-danger,
input[type="datetime-local"].alert-danger,
input[type="time"].alert-danger,
input[type="number"].alert-danger,
input[type="email"].alert-danger,
input[type="url"].alert-danger,
input[type="search"].alert-danger,
input[type="tel"].alert-danger,
input[type="color"].alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwTextBox-textarea.alert-danger + label,
input[type="text"].alert-danger + label,
input[type="password"].alert-danger + label,
input[type="datetime"].alert-danger + label,
input[type="date"].alert-danger + label,
input[type="datetime-local"].alert-danger + label,
input[type="time"].alert-danger + label,
input[type="number"].alert-danger + label,
input[type="email"].alert-danger + label,
input[type="url"].alert-danger + label,
input[type="search"].alert-danger + label,
input[type="tel"].alert-danger + label,
input[type="color"].alert-danger + label {
  color: #575a5d;
}
.gwTextBox-textarea.alert-danger:focus,
input[type="text"].alert-danger:focus,
input[type="password"].alert-danger:focus,
input[type="datetime"].alert-danger:focus,
input[type="date"].alert-danger:focus,
input[type="datetime-local"].alert-danger:focus,
input[type="time"].alert-danger:focus,
input[type="number"].alert-danger:focus,
input[type="email"].alert-danger:focus,
input[type="url"].alert-danger:focus,
input[type="search"].alert-danger:focus,
input[type="tel"].alert-danger:focus,
input[type="color"].alert-danger:focus {
  color: var(--input-control-text-color);
}
.gwTextBox-textarea.alert-danger:focus + label,
input[type="text"].alert-danger:focus + label,
input[type="password"].alert-danger:focus + label,
input[type="datetime"].alert-danger:focus + label,
input[type="date"].alert-danger:focus + label,
input[type="datetime-local"].alert-danger:focus + label,
input[type="time"].alert-danger:focus + label,
input[type="number"].alert-danger:focus + label,
input[type="email"].alert-danger:focus + label,
input[type="url"].alert-danger:focus + label,
input[type="search"].alert-danger:focus + label,
input[type="tel"].alert-danger:focus + label,
input[type="color"].alert-danger:focus + label {
  color: var(--theme-field-title);
}
.gwTextBox-textarea.alert-message-error,
input[type="text"].alert-message-error,
input[type="password"].alert-message-error,
input[type="datetime"].alert-message-error,
input[type="date"].alert-message-error,
input[type="datetime-local"].alert-message-error,
input[type="time"].alert-message-error,
input[type="number"].alert-message-error,
input[type="email"].alert-message-error,
input[type="url"].alert-message-error,
input[type="search"].alert-message-error,
input[type="tel"].alert-message-error,
input[type="color"].alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.gwTextBox-textarea.alert-warning,
input[type="text"].alert-warning,
input[type="password"].alert-warning,
input[type="datetime"].alert-warning,
input[type="date"].alert-warning,
input[type="datetime-local"].alert-warning,
input[type="time"].alert-warning,
input[type="number"].alert-warning,
input[type="email"].alert-warning,
input[type="url"].alert-warning,
input[type="search"].alert-warning,
input[type="tel"].alert-warning,
input[type="color"].alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.gwTextBox-textarea.alert-info,
input[type="text"].alert-info,
input[type="password"].alert-info,
input[type="datetime"].alert-info,
input[type="date"].alert-info,
input[type="datetime-local"].alert-info,
input[type="time"].alert-info,
input[type="number"].alert-info,
input[type="email"].alert-info,
input[type="url"].alert-info,
input[type="search"].alert-info,
input[type="tel"].alert-info,
input[type="color"].alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
input[type="text"].gwValidation-highlight.alert-danger,
input[type="password"].gwValidation-highlight.alert-danger,
input[type="datetime-local"].gwValidation-highlight.alert-danger,
.gwTextBox-textarea.gwValidation-highlight.alert-danger {
  background: #ffb0b0;
}
input[type="text"].gwValidation-highlight.alert-message-error,
input[type="password"].gwValidation-highlight.alert-message-error,
input[type="datetime-local"].gwValidation-highlight.alert-message-error,
.gwTextBox-textarea.gwValidation-highlight.alert-message-error {
  color: #ffc675;
}
input[type="text"].gwValidation-highlight.alert-info,
input[type="password"].gwValidation-highlight.alert-info,
input[type="datetime-local"].gwValidation-highlight.alert-info,
.gwTextBox-textarea.gwValidation-highlight.alert-info {
  background: #b5e6fd;
}
input[type="text"].gwValidation-highlight.alert-warning,
input[type="password"].gwValidation-highlight.alert-warning,
input[type="datetime-local"].gwValidation-highlight.alert-warning,
.gwTextBox-textarea.gwValidation-highlight.alert-warning {
  background: #fef293;
}
input[type="text"]:-webkit-autofill + label,
input[type="password"]:-webkit-autofill + label {
  /* fixed autofill labels colour for Chrome and Safari, due to the fact
		that these browsers have custom autofill light-coloured backgrounds	*/
  color: #575a5d;
}
.form-control,
.gwTextBox-textarea {
  border-color: var(--input-control-border-brush-start);
  color: var(--input-control-text-color);
  background-color: var(--input-control-background-color-start);
}
.form-control,
.select2-container,
.typeahead.dropdown-menu {
  font-size: 15px;
  font-weight: Normal;
}
input[type="text"],
input[type="datetime-local"] {
  border-radius: 4px;
}
input[type="text"]:focus:not([readonly]) input[type="text"]:focus:not(.g-uneditable),
input[type="text"]:focus:not([readonly]) input[type="datetime-local"]:focus:not(.g-uneditable),
input[type="datetime-local"]:focus:not([readonly]) input[type="text"]:focus:not(.g-uneditable),
input[type="datetime-local"]:focus:not([readonly]) input[type="datetime-local"]:focus:not(.g-uneditable) {
  background: var(--input-control-focused-background-color-end);
  border: 1px solid var(--input-control-focused-border-brush-end);
}
.dateclockpicker > input.popover-active,
.gwTextBox-textarea.active:not(.g-uneditable),
.select2-container--focus:not(.select2-container--disabled) .select2-selection,
.select2-container--open .select2-selection,
input.form-control:focus:not([readonly]),
.form-control.active:not(.g-uneditable),
.g-readonly-override:focus,
.g-next-control-focus {
  background: var(--input-control-focused-background-color-end) !important;
  border: 1px solid var(--input-control-focused-border-brush-end) !important;
}
.typeahead.dropdown-menu {
  border-color: #333;
}
.gwSearchBox-drop-above {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 2px;
}
.g-dropdown-opened-below {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.g-dropdown-opened-above {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.gwTriStateRadioButton-wrap:focus,
input.form-control[readonly]:focus:not(.g-readonly-override) {
  border-color: var(--input-control-border-brush-end) !important;
}
.g-jobDocAddress-loading-wrap,
.g-readonly-jobDocAddress,
.form-control[disabled],
.form-control[readonly]:not(.g-readonly-override),
fieldset[disabled] .form-control,
.gwTextBox-textarea.g-uneditable,
.gwMeasure.g-readonly-measure {
  color: var(--input-control-read-only-text-color);
  background-color: var(--input-control-read-only-unfocused-background-color-start);
  cursor: text;
}
.form-control[readonly].g-readonly-override {
  cursor: default;
}
.gwSearchBox input[type="text"],
.gwDatePicker.input-group input[type="text"].form-control {
  border-radius: 4px;
}
.gwDatePicker.input-group input[type="datetime-local"].form-control {
  border-radius: 4px;
}
.gwSearchBox.input-group .input-group-addon:last-child,
.gwDatePicker.input-group .input-group-addon:last-child {
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.input-group-addon:not(.disabled):hover {
  opacity: 0.5;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"] {
  -webkit-appearance: none;
  display: block;
  color: var(--theme-icons);
  vertical-align: top;
  line-height: 20px;
  width: 100%;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
  color: var(--theme-icons);
}
input[type="date"]::-webkit-clear-button,
input[type="time"]::-webkit-clear-button,
input[type="datetime-local"]::-webkit-clear-button {
  -webkit-appearance: none;
  margin: 0;
}
input.g-menu-item--colorscheme__selector__radio[type="radio"] {
  margin: 0 5px 0 0;
}
.g-item-list-command-row {
  padding: 7px 9px 5px 5px;
  text-align: right;
}
.g-item-list-command-row .form-control {
  padding-top: 2px;
  width: 175px;
}
.g-item-list-command-row .input-group {
  width: 100%;
  height: 36px;
}
.g-item-list-command-row .input-group-addon {
  width: 60px;
  padding: 10px 0 0;
}
.g-item-list-command-row .form-group-wide {
  display: block;
}
.g-item-list-command-row .form-group-wide > .form-control {
  width: 100%;
}
.g-item-list-fetchnext {
  width: 100%;
  display: inline-block;
  text-align: center;
}
.g-item-list-count {
  padding: 3px 15px;
  padding-top: 1px;
  display: none;
}
.g-item-list-groupbox {
  width: 100%;
}
.g-item-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.g-item-list.list-inline {
  margin-left: 0;
  margin-bottom: 0;
}
.g-item-list .gwGroupBox {
  position: relative;
  right: -8px;
  height: 100%;
}
.g-item-list .gwGroupBox .panel-title {
  padding-left: 9px;
}
.g-item-list .gwGroupBox .panel-body {
  height: calc(100% - 40px);
}
.g-item-list > li {
  width: 49%;
  height: 100%;
  position: relative;
  display: inline-flex;
}
.g-item-list > li .ui-sortable,
.g-item-list > li .g-entity-available-list {
  min-height: 35px;
  padding: 0px 5px;
  margin: 0 1px 0 0;
  list-style-type: none;
}
.g-item-list > li .ui-sortable li,
.g-item-list > li .g-entity-available-list li {
  padding: 5px 5px 6px;
  margin: 4px 3px;
  color: var(--theme-value-text);
  background-color: var(--grid-viewport-row-color);
  border: 1px solid var(--grid-viewport-border-color);
}
.g-item-list > li .ui-sortable li:hover,
.g-item-list > li .g-entity-available-list li:hover {
  cursor: pointer;
  background-color: var(--grid-viewport-selected-row-color);
}
.g-item-list > li .ui-sortable li.g-item-list-highlight,
.g-item-list > li .g-entity-available-list li.g-item-list-highlight {
  padding: 1px;
  margin: 0;
  background-color: #000;
}
.g-item-list > li .ui-sortable li.ui-sortable-helper,
.g-item-list > li .g-entity-available-list li.ui-sortable-helper {
  background-color: var(--grid-viewport-selected-row-color);
}
.g-item-list > li .ui-sortable .btn,
.g-item-list > li .g-entity-available-list .btn {
  padding: 0 4px;
  margin: 0 0 3px;
}
.g-item-list > li .ui-sortable .col-desc,
.g-item-list > li .g-entity-available-list .col-desc {
  font-size: 12px;
  color: var(--theme-general-text);
}
.g-item-list > li:first-child ul li,
.g-item-list > li:last-child ul li {
  position: relative;
}
.g-item-list > li:first-child ul .btn,
.g-item-list > li:last-child ul .btn {
  position: absolute;
  top: 5px;
}
.g-item-list > li:first-child ul span,
.g-item-list > li:last-child ul span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.g-item-list > li:first-child ul .btn {
  right: 5px;
}
.g-item-list > li:first-child ul span {
  margin-right: 30px;
}
.g-item-list > li:last-child ul .btn {
  left: 5px;
}
.g-item-list > li:last-child ul span {
  margin-left: 30px;
}
.g-item-list > li:first-child .g-item-list-groupbox.alert-danger,
.g-item-list > li:last-child .g-item-list-groupbox.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.g-item-list > li:first-child .g-item-list-groupbox.alert-danger.gwValidation-highlight > .panel-heading,
.g-item-list > li:last-child .g-item-list-groupbox.alert-danger.gwValidation-highlight > .panel-heading {
  background: #ffb0b0;
}
.g-item-list > li:first-child .g-item-list-groupbox.alert-danger > .panel-heading,
.g-item-list > li:last-child .g-item-list-groupbox.alert-danger > .panel-heading {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.g-item-list > li .show-available-items-count > .g-item-list-count {
  display: block;
}
.g-hierarchical-item-list {
  height: 100%;
  overflow: hidden;
}
.g-hierarchical-item-list a {
  outline: none;
}
.g-hierarchical-item-list .alert-danger {
  color: #333 !important;
  background-color: #ffe8e7 !important;
  border-color: #d90000 !important;
}
.g-hierarchical-item-list .gwValidation-highlight {
  background: #ffb0b0 !important;
}
.g-hierarchical-item-list .g-hierarchical-item-list-col {
  padding-left: 0;
  padding-right: 0;
}
.g-hierarchical-item-list .left {
  margin-right: 5px;
}
.g-hierarchical-item-list .right {
  margin-left: 5px;
}
.g-hierarchical-item-list .full-height {
  height: 100%;
}
.g-hierarchical-item-list .g-hierarchical-item-list-row.full-height {
  height: calc(100% - 76px);
}
.g-hierarchical-item-list .push-to-front {
  z-index: 3;
}
.g-hierarchical-item-list .center-icon {
  vertical-align: middle;
  margin-top: -1px;
}
.g-hierarchical-item-list .form-group {
  margin-bottom: -1px;
}
.g-hierarchical-item-list .form-group input[type="text"] {
  padding-top: 3px;
  border-radius: 5px 5px 0px 0px;
}
.g-hierarchical-item-list .form-group input[type="text"]:-moz-placeholder {
  color: none;
  font-style: italic;
}
.g-hierarchical-item-list .form-group input[type="text"]::-moz-placeholder {
  color: none;
  font-style: italic;
}
.g-hierarchical-item-list .form-group input[type="text"]::-webkit-input-placeholder {
  color: none;
  font-style: italic;
}
.g-hierarchical-item-list .form-group .input-group-addon {
  top: 9px;
}
.g-hierarchical-item-list .form-group .input-group-addon i {
  vertical-align: baseline;
  display: inline-block;
}
.g-hierarchical-item-list .form-group .input-group-addon:hover {
  opacity: 1;
  cursor: default;
}
.g-hierarchical-item-list .g-mapping-table-container {
  margin-top: -1px;
  height: 100% !important;
}
.g-hierarchical-item-list .g-mapping-table-container .no-items {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
.g-hierarchical-item-list .g-mapping-table-container .no-items span {
  color: var(--theme-field-title);
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content {
  margin-left: -1px;
  min-height: 100%;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .indentedGroupBox .g-groupbox-wrapper:first-child {
  margin-top: -1px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .indentedGroupBox .item {
  top: -1px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .indentedGroupBox .item:last-child {
  border-bottom-left-radius: 4px !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item {
  position: relative;
  height: 30px;
  padding: 6px 8px;
  white-space: nowrap;
  border: 1px solid var(--sidebar-panel-border-color);
  border-right-width: 0px;
  margin-bottom: -1px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item .enable-icon,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item .disable-icon {
  color: #808080 !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item:hover {
  background-color: var(--theme-hover);
  cursor: pointer;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item:hover a,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item:hover i,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item:hover span,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .item:hover .disable-icon.icon-lock {
  color: #fff;
  color: var(--theme-hover-text) !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header {
  width: calc(100% - 15px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header i {
  display: inline-block;
  margin-left: 4px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .g-table-title {
  margin-left: 5px;
  position: relative;
  top: -1px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .g-table-title-children {
  vertical-align: middle;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .g-table-title-nochildren span {
  font-size: 14px;
  color: var(--theme-general-text);
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .g-table-title-nochildren i {
  margin-left: 0px;
  color: var(--theme-default-button);
  opacity: 0.9;
  vertical-align: inherit;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .g-item-path,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .g-item-desc {
  font-size: 11px !important;
  color: var(--theme-field-title) !important;
  font-style: italic !important;
  position: relative;
  top: -1px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .enable-icon {
  text-align: right;
  position: absolute;
  right: 8px;
  top: 8px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .disable-icon {
  margin-left: 2px !important;
  margin-right: 8px;
  position: relative;
  top: -1px;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .table-name-header .disable-icon.icon-lock {
  margin-left: 5px !important;
  margin-right: 10px !important;
  color: #cccccc !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-sortable-helper {
  background-color: var(--theme-hover) !important;
  cursor: move !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-sortable-helper .table-name-header {
  cursor: move !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-sortable-helper a,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-sortable-helper i,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-sortable-helper span {
  color: var(--theme-hover-text) !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-draggable-dragging {
  width: 100%;
  z-index: 200;
  border: 1px solid #C4C7C8;
  border-radius: 0 !important;
  background-color: var(--theme-hover);
  cursor: move !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-draggable-dragging a,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-draggable-dragging i,
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-draggable-dragging span {
  color: var(--theme-hover-text) !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .ui-draggable-dragging .table-name-header {
  cursor: move !important;
}
.g-hierarchical-item-list .g-mapping-table-container .g-mapping-table-container-content .show-border {
  border-bottom: 1px solid #C4C7C8;
  margin-bottom: -1px;
}
.g-hierarchical-item-list .left .dragging-placeholder .table-name-header {
  visibility: hidden;
}
.g-hierarchical-item-list .left .drop-adorner {
  visibility: collapse;
  border: 1px solid var(--theme-hover);
  background-color: var(--grid-viewport-selected-row-color);
  border-radius: 4px;
  position: absolute;
  top: -33px;
  bottom: 1px;
  right: 5px;
  left: 0px;
  z-index: 100;
}
.g-hierarchical-item-list .right .dragging-placeholder:not(.ui-draggable):not(.dragged-out-placeholder) {
  height: 30px;
  margin-left: 1px;
  margin-top: 1px;
  background-color: var(--grid-viewport-selected-row-color);
  border: 1px solid var(--theme-hover);
}
.g-hierarchical-item-list .right .dragging-placeholder:not(.ui-draggable).dragged-out-placeholder {
  height: 30px;
}
.g-hierarchical-item-list .right .dragging-placeholder:not(.ui-draggable).dragged-out-placeholder .table-name-header {
  visibility: hidden;
}
.g-hierarchical-item-list .right .dragging-placeholder:not(.ui-draggable):first-child {
  margin-top: 2px;
}
.g-hierarchical-item-list .right .dragging-placeholder:not(.ui-draggable):last-child {
  border-bottom-width: 2px;
}
.g-hierarchical-item-list .right .dragging-placeholder.ui-draggable .table-name-header {
  visibility: hidden;
}
.g-hierarchical-item-list .right .drop-adorner {
  visibility: collapse;
  border: 1px solid var(--theme-hover);
  background-color: var(--grid-viewport-selected-row-color);
  border-radius: 4px;
  position: absolute;
  top: -33px;
  bottom: 1px;
  right: 0px;
  left: 5px;
  z-index: 100;
}
.g-hierarchical-item-list .ui-droppable-hover .drop-adorner {
  visibility: visible;
}
.g-hierarchical-item-list-fetchnext {
  display: block;
  height: 30px;
  padding: 6px 8px;
  text-align: center;
}
.g-hierarchical-item-list-fetchnext:focus {
  text-decoration: none;
}
.g-hierarchical-item-list-row {
  display: flex;
}
.g-hierarchical-item-list-col {
  width: 50%;
  position: relative;
}
.g-anchored > label[for],
.importTaskPage .g-control-wrap label,
.mappingPage label.control-label,
.delimiter-selector > label,
.worksheet-selector > label,
.header-line-selector > label,
.g-change-password li label,
.gwConfigureFilter label,
.gwJobDocAddress li label,
.gwSearchBox label,
.gwSelectBox label,
.gwTextBox label,
.gwNumeric label,
.gwDatePicker label,
.gwAnchor label,
.g-import-input label,
.gwRichTextEditor label,
.gwColorPicker label,
.gwFinder label,
.gwMeasure label,
.gwTransitionTextBox label,
.g-configuration-sidebar .gwImageEdit-label,
.g-quickSearch-input-group label {
  position: absolute;
  z-index: 4;
  padding: 5px 8px 2px;
  margin-bottom: 0;
  line-height: 14px;
  order: 0;
  max-width: 100%;
}
.g-tab {
  padding-top: 2px;
  color: #FFFFFF;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
  position: relative;
}
.g-tab:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-tab:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-tab:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-tab:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  content: "";
  -webkit-transition: all 0.12s ease-out;
  -moz-transition: all 0.12s ease-out;
  -o-transition: all 0.12s ease-out;
  transition: all 0.12s ease-out;
  border-bottom: 2px solid transparent;
  bottom: -1px;
  pointer-events: none;
}
.g-tab.active:after {
  border-bottom: 2px solid var(--theme-value-text);
}
table {
  background-color: transparent;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fff;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.g-tickbox {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 170px;
  margin-left: 4px;
}
.g-tickbox input[type="checkbox"] {
  opacity: 0;
  filter: alpha(opacity=0);
}
.g-tickbox input[type="checkbox"]:checked + label {
  border-color: var(--input-control-focused-border-brush-end);
}
.g-tickbox input[type="checkbox"]:checked + label:hover:after {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.g-tickbox input[type="checkbox"]:checked + label:after {
  border: 1px solid var(--theme-value-text);
  border-top: none;
  border-right: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
.g-tickbox input[type="checkbox"]:focus + label {
  box-shadow: inset 0 1px 1px var(--input-control-focused-border-brush-end), 0 1px 3px var(--input-control-focused-border-brush-end);
}
.g-tickbox label {
  position: absolute;
  top: 50%;
  left: 0;
  overflow: visible;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  font-size: 12px;
  color: var(--input-control-focused-border-brush-end);
  cursor: pointer;
  background-color: var(--input-control-background-color-start);
  border: 1px solid var(--input-control-focused-border-brush-end-lighten30);
  border-radius: 2px;
}
.g-tickbox label:hover {
  border-color: var(--input-control-focused-border-brush-end);
}
.g-tickbox label:hover:after {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.g-tickbox label:after {
  position: absolute;
  top: -1px;
  left: 1px;
  width: 12px;
  height: 4px;
  background: transparent;
  content: '';
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0;
  filter: alpha(opacity=0);
}
.g-tickbox span {
  position: absolute;
  top: -4px;
  left: 14px;
}
#toast-container {
  font-size: 20px;
  margin: 10px;
}
#toast-container.toast-center {
  position: absolute;
}
#toast-container.toast-center-glow-2 {
  position: fixed;
}
#toast-container.toast-top-left,
#toast-container.toast-top-right,
#toast-container.toast-top-full-width {
  margin-top: 72px;
}
#toast-container.toast-top-full-width > .toast,
#toast-container.toast-bottom-full-width > .toast {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 15px;
  border-radius: 0;
}
#toast-container.toast-top-full-width > .toast > .toast-close-button,
#toast-container.toast-bottom-full-width > .toast > .toast-close-button {
  top: 50%;
  margin-top: -14px;
  line-height: 17px;
}
#toast-container > .toast {
  position: relative;
  width: 100%;
  padding-left: 57px;
  padding-right: 30px;
  opacity: 1;
  filter: alpha(opacity=100);
}
#toast-container > .toast a {
  color: #fff;
}
#toast-container > .toast a:hover {
  text-decoration: underline;
}
#toast-container > .toast > i {
  position: absolute;
  top: 50%;
  left: 13px;
  margin-top: -17px;
  font-size: 34px;
}
#toast-container > .toast > .toast-message {
  display: inline-block;
  word-break: break-word;
  white-space: pre-wrap;
}
#toast-container > .toast > .toast-close-button {
  position: absolute;
  top: -0.2em;
  right: 0.3em;
}
#toast-container > .toast.toast-error {
  color: #fff;
  background-color: #d90000;
  background-image: none !important;
}
#toast-container > .toast.toast-error > .toast-close-button {
  color: #fff;
}
#toast-container > .toast.toast-success {
  color: #fff;
  background-color: #58ba00;
  background-image: none !important;
}
#toast-container > .toast.toast-success > .toast-close-button {
  color: #fff;
}
#toast-container > .toast.toast-warning {
  color: #fff;
  background-color: #ffc825;
  background-image: none !important;
}
#toast-container > .toast.toast-warning > .toast-close-button {
  color: #fff;
}
#toast-container > .toast.toast-info {
  color: #fff;
  background-color: #3a87ad;
  background-image: none !important;
}
#toast-container > .toast.toast-info > .toast-close-button {
  color: #fff;
}
#toast-container > .toast.toast-message-error {
  color: #fff;
  background-color: #ffaa33;
  background-image: none !important;
}
#toast-container > .toast.toast-message-error > .toast-close-button {
  color: #fff;
}
.tooltip {
  z-index: 2000;
  /* In Bootstrap 2.3.1 Tooltips fall behind modal dialogs, so this places it above */
  position: absolute;
  display: block;
  filter: alpha(opacity=0);
  opacity: 0;
}
.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: 0;
}
.tooltip.top.g-grid-icon-item-tooltip {
  margin-top: 10px;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #333333;
}
.tooltip.top.tooltip-error .tooltip-arrow {
  border-top-color: #d90000;
}
.tooltip.top.tooltip-message-error .tooltip-arrow {
  border-top-color: #ffaa33;
}
.tooltip.top.tooltip-warning .tooltip-arrow {
  border-top-color: #ffc825;
}
.tooltip.top.tooltip-information .tooltip-arrow {
  border-top-color: #3a87ad;
}
.tooltip.right {
  padding: 0 5px;
  margin-left: 0;
}
.tooltip.bottom {
  padding: 5px 0;
  margin-top: 0;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #333333;
}
.tooltip.bottom.tooltip-error .tooltip-arrow {
  border-bottom-color: #d90000;
}
.tooltip.bottom.tooltip-message-error .tooltip-arrow {
  border-bottom-color: #ffaa33;
}
.tooltip.bottom.tooltip-warning .tooltip-arrow {
  border-bottom-color: #ffc825;
}
.tooltip.bottom.tooltip-information .tooltip-arrow {
  border-bottom-color: #3a87ad;
}
.tooltip.left {
  padding: 0 5px;
  margin-left: 0;
}
.tooltip-inner {
  max-width: 250px;
  padding: 5px 8px;
  background-color: #333333;
  border-radius: 4px;
  color: #fff;
  font-weight: Normal;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  line-break: auto;
  white-space: pre-wrap;
  word-break: normal;
  word-spacing: normal;
  font-size: 12px;
}
.tooltip-inner p {
  margin: 2px;
}
.tooltip-inner .tooltipHeader {
  font-weight: bold;
}
.tooltip-error .tooltip-inner {
  background-color: #d90000;
}
.tooltip-message-error .tooltip-inner {
  background-color: #ffaa33;
}
.tooltip-warning .tooltip-inner {
  background-color: #ffc825;
}
.tooltip-information .tooltip-inner {
  background-color: #3a87ad;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.gwSearchBox.gwAddressSearchBox {
  height: 100%;
}
.gwAddressSearchBox {
  overflow: hidden;
  height: 100%;
  background: #fff;
  border: 1px solid #d3d6d9;
  border-radius: 4px;
}
.gwAddressSearchBox.alert-danger .form-control:first-child {
  border: 1px solid #d90000;
  border-radius: 4px;
}
.gwAddressSearchBox.gwAddressSearchBox.alert-message-error .form-control:first-child {
  border: 1px solid #ffaa33;
  border-radius: 4px;
}
.gwAddressSearchBox.gwAddressSearchBox.alert-warning .form-control:first-child {
  border: 1px solid #ffc825;
  border-radius: 4px;
}
.gwAddressSearchBox.gwAddressSearchBox.alert-info .form-control:first-child {
  border: 1px solid #3a87ad;
  border-radius: 4px;
}
.gwAddressSearchBox .gwSearchBox-wrap {
  height: 42px;
}
.gwAddressSearchBox .form-control:first-child {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 4px 4px 0 0;
}
.gwAddressSearchBox .g-selected-address {
  min-height: 55px;
  padding: 6px 8px;
  font-size: 12px;
  line-height: normal;
}
.gwAddressSearchBox-results-item {
  font-size: 13px;
  border-bottom: 1px solid #d3d6d9;
  padding: 5px 8px;
  cursor: pointer;
}
.gwAddressSearchBox-results-item:nth-last-child(2) {
  border-bottom: none;
}
.g-selected-address .g-address-capabilities,
.gwAddressSearchBox-results-item .g-address-capabilities {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}
.g-selected-address .g-address-capabilities span,
.gwAddressSearchBox-results-item .g-address-capabilities span {
  background-color: #EBEEF3;
  border: 1px solid #D3D6D9;
  border-radius: 6px;
  margin: 2px 5px 2px 0;
  text-align: center;
  height: 22px;
  min-width: 40px;
  padding: 0 5px;
}
.g-selected-address.active .g-address-capabilities span,
.gwAddressSearchBox-results-item.active .g-address-capabilities span {
  background-color: transparent;
  border: 1px solid #fff;
}
.gwAddressSearchBox .g-orginfo,
.gwAddressSearchBox-results-item .g-orginfo {
  cursor: pointer;
  font-weight: bold;
}
.gwAddressSearchBox .g-address div,
.gwAddressSearchBox-results-item .g-address div,
.gwAddressSearchBox .g-address-code,
.gwAddressSearchBox-results-item .g-address-code {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--theme-value-text);
}
.g-address-code {
  font-weight: bold;
}
.gwAsyncImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gwAsyncImage img {
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.g-designed-button {
  padding: 3px;
}
.gwButton {
  height: 100%;
}
div.gwButton .btn {
  height: 100%;
  width: 100%;
  margin: 0;
}
.gwButton .btn.g-btn-async {
  padding-left: 20px;
  padding-right: 20px;
}
.gwButton .btn.g-btn-async[data-icon] {
  padding-left: 11px;
  padding-right: 11px;
}
.gwButton .btn.g-btn-async.g-btn-loading,
.gwButton .btn.g-btn-async.g-btn-loading[data-icon] {
  padding-left: 9.5px;
  padding-right: 9.762px;
}
.gwCarousel {
  height: 100%;
  width: 100%;
}
.gwCarousel [data-contents="gwCarousel"] {
  height: 100%;
  width: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.gwCarousel .gwGroupBox {
  height: 100% !important;
  width: 100% !important;
}
.gwCarousel__child {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  margin-right: 4px;
}
.gwCheckBox__input {
  display: none;
}
.gwCheckBox {
  display: flex;
  width: 100%;
  padding: 0 !important;
  margin: 0;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  align-items: center;
}
.gwCheckBox--bordered {
  height: 100%;
  min-width: 200px;
  min-height: 42px;
  background-color: #FFFFFF;
  border-color: var(--input-control-border-brush-start);
  border-radius: 4px;
  justify-content: flex-end;
}
.gwCheckBox--bordered .gwCheckBox__span {
  max-height: 32px;
  padding: 0 8px;
  line-height: 1.42857143;
  text-align: right;
  white-space: normal;
}
.gwCheckBox--bordered .gwCheckBox__box {
  height: 100%;
  min-width: 60px;
  min-height: 40px;
  border-left: 1px solid var(--input-control-border-brush-start);
  justify-content: center;
}
.gwCheckBox--borderless-left-aligned {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.gwCheckBox--borderless-left-aligned .gwCheckBox__span {
  padding-left: 7px;
}
.gwCheckBox--borderless-right-aligned {
  justify-content: space-between;
}
.gwCheckBox--borderless-right-aligned .gwCheckBox__span {
  padding-right: 8px;
}
.gwCheckBox--borderless-centered {
  height: 100%;
  justify-content: center;
}
.gwCheckBox--borderless-centered .gwCheckBox__span {
  display: none;
}
.gwCheckBox--readonly {
  color: var(--input-control-label-color);
  background-color: var(--input-control-read-only-unfocused-background-color-start);
  cursor: default;
}
.gwCheckBox:not(.gwCheckBox--readonly):hover .gwCheckBox__box::before {
  border-color: var(--checkbox-border-hover-color);
}
.gwCheckBox.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwCheckBox.alert-danger.gwValidation-highlight {
  background: #ffb0b0;
}
.gwCheckBox.alert-danger .gwCheckBox__box {
  border-color: #d90000;
}
.gwCheckBox.alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.gwCheckBox.alert-message-error.gwValidation-highlight {
  color: #ffc675;
}
.gwCheckBox.alert-message-error .gwCheckBox__box {
  border-color: #ffaa33;
}
.gwCheckBox.alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.gwCheckBox.alert-warning.gwValidation-highlight {
  background: #fef293;
}
.gwCheckBox.alert-warning .gwCheckBox__box {
  border-color: #ffc825;
}
.gwCheckBox.alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.gwCheckBox.alert-info.gwValidation-highlight {
  background: #b5e6fd;
}
.gwCheckBox.alert-info .gwCheckBox__box {
  border-color: #3a87ad;
}
.g-anchored > label[for].gwCheckBox {
  padding: 0;
}
.gwCheckBox__span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.gwCheckBox__box,
.gwFastCheckBox {
  display: flex;
  font-size: 12px;
  font-style: italic;
  font-weight: 900;
  line-height: 14px;
  align-items: center;
}
.gwCheckBox__box::before,
.gwFastCheckBox::before {
  width: 14px;
  height: 14px;
  background-color: #FFFFFF;
  border: 1px solid var(--theme-value-text);
  border-radius: 2px;
  content: '';
}
.gwCheckBox__box::after,
.gwFastCheckBox::after {
  width: 14px;
  margin-left: -14px;
  color: var(--theme-hover);
}
.gwFastCheckBox {
  width: 100%;
  height: 100%;
  cursor: inherit;
  justify-content: center;
}
.gwCheckBox__input:checked + .gwCheckBox .gwCheckBox__box::after,
.gwFastCheckBox--checked::after {
  content: '\2713';
}
.gwFastCheckBox--null::after {
  content: '\2014';
  padding-left: 1.5px;
}
.gwCheckBox--readonly .gwCheckBox__box::before,
.g-uneditable .gwFastCheckBox::before,
.gwFastCheckBox--readonly::before {
  background-color: #F2F2F2;
  border-color: #D3D6D9;
}
.gwCheckBox--readonly .gwCheckBox__box::after,
.g-uneditable .gwFastCheckBox::after,
.gwFastCheckBox--readonly::after {
  color: #575A5D;
}
.gwColorPicker .input-group-addon {
  line-height: 42px;
  border: 1px solid var(--input-control-border-brush-start);
}
.gwColorPicker .form-control[disabled] + .input-group-addon {
  cursor: default;
}
.gwColorPicker .form-control {
  padding-left: 15px;
}
.gwColorPicker .form-control:focus + .input-group-addon {
  border: 1px solid var(--input-control-focused-border-brush-end);
}
.gwColorPicker input[type="text"] {
  padding-bottom: 0;
}
.gwColorPicker.input-group:before {
  content: '#';
  position: absolute;
  z-index: 5;
  left: 7px;
  top: 50%;
  font-size: 14px;
}
.colpick_glow {
  background-color: #fff;
  border-width: 1px;
  border-color: #999;
  border-radius: 5px;
  box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.6);
  z-index: 1000;
}
.colpick_glow .colpick_field_letter,
.colpick_glow .colpick_field_arrs,
.colpick_glow input[type="text"] {
  background: #fff;
}
.colpick_glow .colpick_submit {
  color: var(--theme-default-button-text);
  background: var(--theme-default-button);
  border: 1px solid #d6d6d6;
  top: 145px;
}
.colpick_glow .colpick_submit:hover {
  color: var(--theme-hover-text);
  background: var(--theme-hover);
  border-color: #d6d6d6;
}
.colpick_glow .colpick_field_letter {
  width: 17px;
  z-index: 1;
}
.colpick_glow .ui-spinner-button {
  width: 12px;
  font-size: 0;
  cursor: pointer;
}
.colpick_glow .ui-spinner-button:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.colpick_glow .ui-spinner-button .ui-icon {
  left: -1px;
  width: 12px;
  height: 12px;
}
.colpick_glow .ui-spinner-input {
  left: 0;
  width: 35px;
}
.colpick_glow .colpick_field .ui-spinner {
  position: absolute;
  right: 0;
  left: 17px;
  margin: 0;
  padding: 0;
  height: 19px;
  line-height: 19px;
  border: none;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #555;
  text-align: right;
  outline: none;
}
.colpick_glow .colpick_field_arrs {
  display: none;
}
.colpick_field_uarr:before {
  border-bottom-color: var(--theme-value-text);
}
.colpick_field_darr:before {
  border-top-color: var(--theme-value-text);
}
.gwConfigureFilter {
  height: 42px;
}
.gwConfigureFilter .input-group-addon i {
  color: var(--theme-icons);
}
.gwConfigureFilter.disabled .form-control {
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.gwConfigureFilter.disabled .input-group-addon {
  pointer-events: none;
}
.gwConfigureFilter.disabled .form-control,
.gwConfigureFilter.disabled .input-group-addon {
  color: var(--input-control-read-only-text-color);
}
.gwConversationManager {
  width: 100%;
  height: 100%;
}
.gwConversationManager .g-controls-panel {
  display: flex;
  flex-direction: row;
}
.gwConversationManager .g-textarea-panel {
  width: 100%;
  height: 120px;
}
.gwConversationManager .g-textarea-panel textarea {
  height: 100%;
  resize: none;
  background-color: var(--input-control-background-color-end);
}
.gwConversationManager .g-textarea-panel textarea:focus {
  border-color: var(--input-control-border-brush-start);
}
.gwConversationManager .g-textarea-panel span {
  float: right;
  text-align: right;
}
.gwConversationManager .g-button-panel {
  width: 125px;
  padding-left: 5px;
  vertical-align: top;
  display: flex;
  flex-direction: column;
}
.gwConversationManager .g-button-panel button {
  min-width: 100px;
}
.gwConversationManager .g-conversation-panel {
  position: absolute;
  top: 140px;
  right: 0;
  bottom: 10px;
  left: 0;
  margin-top: 5px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.gwConversationManager .g-conversation-panel .g-conversation-readonly {
  background-color: var(--input-control-read-only-unfocused-background-color-start);
  color: var(--input-control-read-only-text-color);
}
.gwConversationManager .g-conversation-panel .g-conversation-readonly .g-message-sender-time {
  color: var(--input-control-read-only-text-color);
}
.gwConversationManager .g-conversation-panel:after {
  clear: both;
  content: "";
  height: 3px;
  display: block;
}
.gwConversationManager .g-conversation-date {
  float: left;
  width: 100%;
  padding: 10px 6px;
  font-weight: bold;
  text-align: center;
  color: var(--group-box-header-text-color);
}
.gwConversationManager .g-conversation-message {
  width: 85%;
  margin: 3px 6px;
  padding: 6px;
  border-radius: 4px;
}
.gwConversationManager .g-conversation-message-other-staff,
.gwConversationManager .g-conversation-message-other-contact,
.gwConversationManager .g-conversation-message-self-staff,
.gwConversationManager .g-conversation-message-self-contact {
  width: 85%;
  margin: 3px 6px;
  padding: 6px;
  border-radius: 4px;
  color: #fff;
}
.gwConversationManager .g-conversation-message-other-staff,
.gwConversationManager .g-conversation-message-other-contact {
  float: left;
}
.gwConversationManager .g-conversation-message-self-staff,
.gwConversationManager .g-conversation-message-self-contact {
  float: right;
}
.gwConversationManager .g-conversation-message-other-staff,
.gwConversationManager .g-conversation-message-self-staff {
  background-color: var(--theme-value-text);
}
.gwConversationManager .g-conversation-message-other-contact,
.gwConversationManager .g-conversation-message-self-contact {
  background-color: var(--theme-default-button);
}
.gwConversationManager .g-conversation-message-system {
  width: 85%;
  margin: 3px 6px;
  padding: 6px;
  border-radius: 4px;
  color: #333;
  float: left;
  background-color: #F2F2F2;
}
.gwConversationManager .g-conversation-message-system .g-message-sender-time {
  color: #333;
}
.gwConversationManager .g-message-body {
  display: inline;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.gwConversationManager .g-message-sender-time-score {
  display: inline-block;
  float: right;
  margin-left: 6px;
  font-size: 12px;
  color: #333;
  text-align: right;
}
.gwConversationManager .g-message-sender-time {
  display: inline-block;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #fff;
}
.gwConversationManager .g-message-score {
  display: inline-block;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;
  background-color: rgba(242, 242, 242, 0.8);
}
.gwConversationManager .g-message-score .btn {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwConversationManager .g-message-score .btn:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwConversationManager .g-message-score .btn:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwConversationManager .g-message-score .btn:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwConversationManager .g-thumbs {
  font-size: 14px;
  color: #333;
}
.gwConversationManager .g-thumbs:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: pointer;
}
.gwConversationManager .g-thumbs-active {
  font-size: 14px;
  color: #333;
}
.gwConversationManager .g-thumbs-active:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: pointer;
}
.gwConversationManager .g-thumbs-readonly {
  font-size: 14px;
  color: #333;
  color: #575A5D;
}
.gwConversationManager .g-thumbs-readonly:hover {
  cursor: default;
}
.gwConversationManager .g-follower-manager {
  margin-top: auto;
}
.gwConversationManager .g-follower-manager .btn:not([disabled]) {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwConversationManager .g-follower-manager .btn:not([disabled]):focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwConversationManager .g-follower-manager .btn:not([disabled]):hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwConversationManager .g-follower-manager .btn:not([disabled]):active {
  opacity: 0.3;
  text-decoration: none;
}
.gwConversationManager .g-follower-list {
  overflow-y: auto;
  width: 320px;
}
.gwConversationManager .g-follower {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: default;
}
.gwConversationManager .g-follower-image-section {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 8px;
  z-index: 1000;
  overflow: hidden;
  text-align: center;
}
.gwConversationManager .g-follower-icon-section {
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 8px;
  text-align: center;
}
.gwConversationManager .g-follower-image {
  height: 40px;
  width: 40px;
}
.gwConversationManager .g-follower-icon {
  font-size: 40px;
  color: #32324a;
}
.gwConversationManager .g-follower-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
.gwConversationManager .g-follower-remove-button {
  margin-left: auto;
  margin-right: 8px;
}
.gwConversationManager .g-follower-info {
  padding: 10px 15px;
}
.gwConversationManager .g-follower-info p {
  margin: 0;
}
.gwConversationManager .g-follower-info .g-follower-info-value {
  color: var(--theme-value-text);
}
.gwConversationManager .g-addfollowers-menu .divider {
  height: 1px;
  background-color: #aaa;
}
.gwConversationManager .btn.btn-link {
  background: 0;
  padding: 0;
  text-transform: none;
  text-decoration: none;
  color: #003479;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
}
.gwConversationManager .btn.btn-link i {
  margin-right: 5px;
  font-size: 13px;
}
.gwConversationManager .btn.btn-link input[type="checkbox"] {
  height: 13px;
  margin-top: 3px;
  margin-right: 5px;
}
.gwConversationManager .btn.btn-link.g-btn-loading > i {
  display: inline;
}
.gwConversationManager .btn.btn-link.text-left {
  text-align: left;
  justify-content: flex-start;
}
.gwConversationManager .btn.btn-link.btn-icon {
  min-width: 0 !important;
  padding: 0;
}
.gwConversationManager .btn.btn-link.btn-icon i {
  margin: 0;
  padding: 2px;
}
.gwConversationManager .btn.btn-link.btn-icon i:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gwConversationManager .btn.btn-link:focus {
  border-color: transparent;
}
.gwConversationManager .popover {
  z-index: 1040;
}
.gwConversationManager .popover .popover-content {
  max-height: none !important;
}
.g-dialog-conversation .gwConversationManager .g-conversation-panel {
  top: 190px;
  right: 15px;
  bottom: 55px;
  left: 15px;
}
.g-modal.conversation .g-modal-autoresize .modal-content {
  height: auto;
}
.g-dialog-conversation-add-email .modal-content {
  min-height: auto !important;
  min-width: min(90vw, 300px) !important;
  height: 160px !important;
}
.gwDataGrid {
  position: relative;
  width: 100%;
  height: 100%;
}
.gwDataGrid .g-grid-action-button {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 15px;
  outline: none;
  background: transparent;
  border: none;
  border-radius: 0;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwDataGrid .g-grid-action-button:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwDataGrid .g-grid-action-button:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwDataGrid .g-grid-action-button:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwDataGrid .g-grid-action-button i {
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: var(--theme-icons);
}
.gwDataGrid .add-row {
  height: 25px;
  border-right: 1px solid #ccc;
}
.gwDataGrid .add-row:hover {
  background-color: var(--grid-viewport-hover-row-color);
}
.gwDataGrid .add-row button {
  padding-top: 5px;
  padding-left: 7px;
}
.gwDataGrid .add-row button img {
  color: var(--theme-icons);
}
.gwDataGrid .add-row .g-grid-actions {
  height: 100%;
}
.gwDataGrid .kgHeaderContainer .g-grid-action-button {
  padding: 4px 6px;
}
.gwDataGrid .kgRow {
  background-color: var(--grid-viewport-alternating-row-color);
}
.gwDataGrid [readonly='readonly'] .kgRow {
  background-color: var(--grid-readonly-viewport-alternating-color);
}
.gwDataGrid .label-danger {
  background-color: #d9534f;
}
.gwDataGrid .label-danger[href]:hover,
.gwDataGrid .label-danger[href]:focus {
  background-color: #c9302c;
}
.kgCell:not(.g-uneditable):hover .gwFastCheckBox--hoverable::before {
  border-color: var(--checkbox-border-hover-color);
}
.gwDataGrid-message-toast {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  margin-top: -20px;
}
.gwDataGrid-message-toast .alert {
  text-align: center;
}
.gwDataGrid-message-toast .alert-success {
  border-color: #468847;
  box-shadow: 0 1px 15px #468847;
}
.gwDataGrid-message-toast .alert-danger {
  border-color: #333;
  box-shadow: 0 1px 15px #333;
}
.gwDataGrid-message-toast .alert-info {
  border-color: #333;
  box-shadow: 0 1px 15px #333;
}
.gwDataGrid-inner-wrap {
  display: flex;
  height: 42px;
}
.gwDataGrid-inner-wrap .g-kg-top-container {
  align-self: flex-end;
}
.gwDataGrid-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  top: 42px;
}
.gwDataGrid-wrapper .gwDataGrid-load-error {
  display: none;
}
.gwDataGrid-wrapper.gwDataGrid-no-title {
  top: 0px;
}
.gwDataGrid-data-error .gwDataGrid-load-error {
  z-index: 1005;
  display: block;
  margin: -42px 0 0 0;
  font-size: 16px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.gwDataGrid-data-error .gwDataGrid-load-error > div {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.gwDataGrid-access-error {
  width: 100%;
  height: 100%;
  border: 1px solid #ffc825;
  border-radius: 5px;
}
.gwDataGrid-access-error .g-content {
  display: table;
  margin: auto;
  top: 46px;
  position: relative;
}
.gwDataGrid-access-error .g-content i {
  display: initial;
  margin-right: 5px;
  font-size: 24px;
  color: var(--theme-default-button);
}
.gwDataGrid-access-error .g-content > li {
  list-style-type: none;
}
.gwDataGrid-access-error .g-content h3 {
  display: initial;
  line-height: 24px;
  color: var(--theme-default-button);
}
.gwDataGrid-loading-message {
  display: none;
  position: absolute;
  top: 94px;
  left: 50%;
  z-index: 2;
  display: block;
  height: 30px;
}
.gwDataGrid-loading-message .g-loading-animation .icon-spinner {
  font-size: 30px;
  margin: 0;
  color: var(--theme-icons);
}
.gwDataGrid-loading-message.alert {
  margin: 0;
  padding: 0;
}
.gwDataGrid-loading {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gwDataGrid-cell-text-popover {
  border: 1px solid var(--input-control-focused-border-brush-end);
  border-radius: 4px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  padding: 5px;
  overflow: auto;
}
.gwDataGrid-cell-text-popover.popover.right {
  margin-left: 0px;
}
.gwDataGrid-cell-text-popover .arrow,
.gwDataGrid-cell-text-popover .popover-title {
  display: none;
}
.gwDataGrid-cell-text-popover .popover-content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.gwDatePicker {
  display: inline-block;
}
.gwDatePicker input[readonly]:not(.g-read-restricted):-moz-placeholder {
  color: transparent;
}
.gwDatePicker input[readonly]:not(.g-read-restricted)::-moz-placeholder {
  color: transparent;
}
.gwDatePicker input[readonly]:not(.g-read-restricted):-ms-input-placeholder {
  color: transparent;
}
.gwDatePicker input[readonly]:not(.g-read-restricted)::-webkit-input-placeholder {
  color: transparent;
}
.gwDatePicker input[readonly]:not(.g-read-restricted):-moz-placeholder {
  color: transparent;
  font-style: italic;
}
.gwDatePicker input[readonly]:not(.g-read-restricted)::-moz-placeholder {
  color: transparent;
  font-style: italic;
}
.gwDatePicker input[readonly]:not(.g-read-restricted)::-webkit-input-placeholder {
  color: transparent;
  font-style: italic;
}
.gwDatePicker:not(.dateclockpicker-readonly) label {
  padding-right: 30px;
}
li.g-jump-item {
  position: relative;
}
li.g-jump-item > a {
  width: 100%;
  height: 30px;
  overflow: hidden;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
li.g-jump-item > a > span {
  display: inline;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
li.g-jump-item i.chevron {
  margin: -2px 6px 0 0;
}
li.g-jump-item i.g-filled-star,
li.g-jump-item i.g-empty-star {
  width: 30px;
  height: 30px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.02s ease-in;
  -moz-transition: all 0.02s ease-in;
  -o-transition: all 0.02s ease-in;
  transition: all 0.02s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
li.g-jump-item i.g-filled-star:focus,
li.g-jump-item i.g-empty-star:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
li.g-jump-item i.g-filled-star:hover,
li.g-jump-item i.g-empty-star:hover {
  opacity: 0.5;
  text-decoration: none;
}
li.g-jump-item i.g-filled-star:active,
li.g-jump-item i.g-empty-star:active {
  opacity: 0.3;
  text-decoration: none;
}
li.g-jump-item i.g-filled-star {
  color: #f5b400;
}
li.g-jump-item i.g-empty-star {
  color: #808080;
}
li.g-jump-item.g-no-pin > a {
  padding-right: 10px;
}
li.g-jump-item > span {
  width: 100%;
  height: 30px;
  padding-left: calc(30px/2 - 7px);
  font-size: 13px;
  line-height: 30px;
  cursor: auto;
}
li.g-jump-add-remove > a {
  padding: 0;
}
li.g-jump-add-remove > a > span {
  width: 100%;
  height: 30px;
  padding-left: calc(30px/2 - 7px);
  font-size: 13px;
  line-height: 30px;
}
.gwFavorites {
  width: 100%;
  height: 100%;
  padding: 4px;
}
.gwFavorites > .gwGroupBox-container {
  height: 100%;
}
.gwFavorites .g-favorites-wrap {
  position: relative;
}
.gwFavorites .gwGroupBox {
  width: 100%;
}
.gwFavorites .gwGroupBox .panel-heading {
  border: 0;
}
.gwFavorites .gwGroupBox .panel-body {
  position: relative;
  height: calc(100% - 42px);
  margin: 0;
}
.gwFavorites .dropdown-submenu i.chevron {
  margin: -2px 6px 0 0;
}
.gwFavorites .dropdown-submenu i.chevron.icon-chevron-down {
  display: inline;
}
.gwFavorites .dropdown-submenu.open > a > i.icon-chevron-down {
  transform: rotate(180deg) translateY(-3px);
}
.gwFavorites [data-contents="gwGroupBox"] {
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #D3D6D9;
  border-radius: 5px;
}
.gwFavorites [data-contents="gwGroupBox"] ul {
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.gwFavorites [data-contents="gwGroupBox"] ul li {
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  border-radius: 5px;
}
.gwFavorites [data-contents="gwGroupBox"] ul li:first-child {
  margin-top: 5px;
}
.gwFavorites [data-contents="gwGroupBox"] ul li > a {
  width: 100%;
}
.gwFavorites [data-contents="gwGroupBox"] ul li > a > i.g-empty-star,
.gwFavorites [data-contents="gwGroupBox"] ul li > a > i.g-filled-star {
  float: left;
}
.gwFavorites [data-contents="gwGroupBox"] ul li > a > span {
  float: left;
  max-width: calc(100% - 30px);
}
.gwFavorites [data-contents="gwGroupBox"] ul li.g-favorites-placeholder {
  height: 2px;
  margin: 0;
  background-color: #000;
  border-radius: 0;
}
.gwFavorites [data-contents="gwGroupBox"] ul li.g-no-pin > a > span {
  max-width: calc(100% - 30px);
}
.gwFavorites-with-recents > .gwGroupBox-container {
  height: auto;
}
.gwFavorites-error {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1005;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}
.g-absolute .gwFavorites-with-recents {
  display: flex;
  flex-direction: column;
  min-height: 240px;
  max-height: 100%;
}
.g-absolute .gwFavorites-with-recents .g-favorites {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  min-height: 120px;
  max-height: calc(100% - 120px);
}
.g-absolute .gwFavorites-with-recents .g-recents {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 120px;
}
.g-absolute .gwFavorites-with-recents .gwGroupBox {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.g-absolute .gwFavorites-with-recents .gwGroupBox .panel-heading {
  flex: 0 0 auto;
}
.g-absolute .gwFavorites-with-recents .gwGroupBox .panel-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.g-absolute .gwFavorites-with-recents .gwGroupBox .panel-body > div {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.gwFileSelect {
  height: 100%;
}
.gwFileUploader ul .btn {
  padding: 0 5px;
}
.gwFileUploader > ul:first-child > li {
  margin: 10px 0;
  border-bottom: 1px solid #ccc;
}
.gwFileUploader > ul:first-child > li:last-child {
  border-bottom: none;
}
.gwFileUploader .fileinput-wrapper {
  position: relative;
}
.gwFileUploader-buttonbar .btn span:before {
  content: ' ';
}
.gwFileUploader-log-warning {
  font-weight: bold;
  color: #333;
}
.gwFileUploader-log-error {
  font-weight: bold;
  color: #a3131c;
}
.gwFileUploader-log-important {
  font-weight: bold;
  color: #003478;
}
.gwFileUploader-log-success {
  font-weight: bold;
  color: #01a7e1;
}
.gwFileUploader-results .file-upload-info {
  padding: 8px 8px 8px;
  background-color: #f6f6f6;
  border: solid 1px #e6e8e9;
  border-radius: 5px;
}
.gwFileUploader-results .progress {
  margin-bottom: 6px;
}
.gwFileUploader-results .progress span {
  position: absolute;
  display: block;
  width: 100%;
  color: #000;
}
.gwFileUploader-results .upload-outcome {
  text-align: center;
}
.gwFileUploaderInput {
  padding-right: 90px;
}
.gwFilter {
  position: relative;
  z-index: 6;
  width: 100%;
  display: flex;
}
.gwFilter .gwFilter-groups-wrapper {
  width: 100%;
  min-height: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.gwFilter .gwFilter-groups-wrapper .g-kg-item-name {
  vertical-align: middle;
}
.gwFilter .gwFilter-groups-wrapper .gwFilter-container {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  height: 100%;
  min-height: 0;
  border: 1px solid #d3d6d9;
  border-radius: 4px;
  background: #F6F6F6;
  padding: 0px;
  margin-bottom: 10px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups {
  flex: 0 1 auto;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  margin-bottom: 4px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group {
  position: relative;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.user .gwFilter-strips {
  padding: 8px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip {
  margin-bottom: 4px;
  list-style: none;
  overflow: hidden;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .form-group {
  margin-bottom: 4px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .form-group .gwSearchBox-description-wrap {
  padding-right: 32px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .form-group .gwSearchBox-description-wrap .gwSearchBox-description {
  padding-top: 11px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .form-group .gwSearchBox-description-wrap .gwSearchBox-description .gwSearchBox-description-text {
  line-height: 10px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .form-group .gwAddressSearchBox {
  border: none;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .form-group .gwAddressSearchBox .form-control:first-child {
  border-radius: 4px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .gwFilter-path-span {
  font-size: 11px;
  font-style: italic;
  color: var(--theme-value-text);
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-strips .gwFilter-strip .gwPath-indent {
  margin-left: 25px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-separator-container {
  padding-top: 0;
  padding-bottom: 8px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group .gwFilter-separator-container .gwFilter-separator {
  margin: 0 8px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-strips {
  padding: 8px 8px 0 8px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-strips .gwFilter-strip {
  margin-bottom: 8px;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-strips .gwFilter-strip .form-group {
  margin-bottom: 0;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-strips .gwFilter-strip .g-icon-grip-button {
  cursor: default;
  background: #eeeeee;
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-strips .gwFilter-strip .g-icon-grip-button i {
  color: var(--theme-field-title);
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-strips .gwFilter-strip .gwFilter-3rd-col {
  display: none;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-strips .gwFilter-strip:last-child {
  margin-bottom: 0;
}
.gwFilter .gwFilter-groups-wrapper ul.gwFilter-groups .gwFilter-group.default .gwFilter-separator-container {
  padding-top: 16px;
}
.gwFilter .gwFilter-1st-col {
  width: 24%;
  float: left;
}
.gwFilter .gwFilter-1st-col .gwFilter-path-span {
  margin-left: 25px;
}
.gwFilter .gwFilter-1st-col .g-icon-grip-button {
  width: 19px;
  height: 32px;
  float: left;
  cursor: move;
  display: block;
  padding: 5px 10px;
  font-size: 16px;
  color: #FFFFFF;
  border: none;
  background: var(--theme-value-text);
  text-align: center;
  padding: 4px 0;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwFilter .gwFilter-1st-col .g-icon-grip-button span {
  padding-left: 6px;
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-size: 14px;
}
.gwFilter .gwFilter-1st-col .g-icon-grip-button:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwFilter .gwFilter-1st-col .g-icon-grip-button:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwFilter .gwFilter-1st-col .g-icon-grip-button:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwFilter .gwFilter-1st-col .g-icon-grip-button.g-disabled-grip {
  cursor: default;
  background: #eeeeee;
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.gwFilter .gwFilter-1st-col .g-icon-grip-button.g-disabled-grip i {
  color: var(--theme-field-title);
}
.gwFilter .gwFilter-2nd-col {
  width: calc(76% - 29px);
  float: left;
}
.gwFilter .gwFilter-2nd-col .colWidth-1rd {
  width: 33.3%;
  float: left;
  padding-left: 5px;
}
.gwFilter .gwFilter-2nd-col .colWidth-1rd .form-control {
  display: table;
}
.gwFilter .gwFilter-2nd-col .colWidth-1rd .gwNumeric label,
.gwFilter .gwFilter-2nd-col .colWidth-1rd .gwDatePicker label {
  position: absolute;
  z-index: 4;
  padding-top: 10px;
  padding-right: 0;
  padding-left: 6px;
  margin-top: 0;
  margin-left: 0;
  line-height: inherit;
  text-align: left;
}
.gwFilter .gwFilter-2nd-col .colWidth-2rd {
  width: 66.6%;
  float: left;
  padding-left: 5px;
}
.gwFilter .gwFilter-2nd-col .colWidth-2rd .form-control {
  display: table;
}
.gwFilter .gwFilter-2nd-col .colWidth-2rd .gwNumeric label,
.gwFilter .gwFilter-2nd-col .colWidth-2rd .gwDatePicker label {
  position: absolute;
  z-index: 4;
  padding-top: 10px;
  padding-right: 0;
  padding-left: 6px;
  margin-top: 0;
  margin-left: 0;
  line-height: inherit;
  text-align: left;
}
.gwFilter .gwFilter-2nd-col .colWidth-2rd .gwFilter-boolean .gwTriStateRadioButton .btn-group {
  display: inline;
}
.gwFilter .gwFilter-2nd-col .colWidth-2rd .gwSearchBox {
  height: 32px;
}
.gwFilter .gwFilter-2nd-col .gwFilter-operator {
  height: 32px;
  font-style: italic;
  cursor: pointer;
}
.gwFilter .gwFilter-2nd-col ::-webkit-input-placeholder,
.gwFilter .gwFilter-2nd-col :-moz-placeholder,
.gwFilter .gwFilter-2nd-col ::-moz-placeholder {
  color: #999;
}
.gwFilter .gwFilter-2nd-col .input-group-addon {
  height: 32px;
  position: relative;
  float: right;
}
.gwFilter .gwFilter-2nd-col .gwTriStateRadioButton {
  height: 32px;
}
.gwFilter .gwFilter-2nd-col .gwTriStateRadioButton .btn-group .btn {
  height: 32px;
  width: 50px;
}
.gwFilter .gwFilter-3rd-col {
  width: 24px;
  padding: 8px 5px 6px 5px;
  float: left;
}
.gwFilter .gwFilter-1st-col .select2 .select2-selection__arrow,
.gwFilter .gwFilter-2nd-col .select2 .select2-selection__arrow {
  color: var(--theme-value-text);
}
.gwFilter .gwFilter-1st-col .select2 .select2-selection__rendered,
.gwFilter .gwFilter-2nd-col .select2 .select2-selection__rendered {
  padding-top: 0;
  line-height: 31px;
}
.gwFilter .gwFilter-separator {
  height: 1px;
  margin: 15px 8px;
  border-bottom: 1px dotted var(--theme-default-button);
}
.gwFilter .gwFilter-separator:after {
  position: absolute;
  left: 50%;
  z-index: 1;
  padding-right: 8px;
  padding-left: 8px;
  margin-top: -8px;
  color: var(--theme-field-title);
  font-size: 11px;
  font-weight: bold;
  background: #F6F6F6;
  content: 'OR';
}
.gwFilter .gwFilter-dropbox {
  padding: 16px;
  color: var(--theme-value-text);
  text-align: center;
  background: #F3F9FC;
  border: 1px dashed var(--theme-default-button);
  border-radius: 5px;
  margin-bottom: 10px;
  max-height: 50px;
}
.gwFilter .gwFilter-dropbox .ui-sortable-placeholder {
  height: 1px;
}
.gwFilter .gwFilter-dropbox:before {
  content: 'Drop Filter Here To Create New Group';
}
.gwFilter .clear {
  clear: both;
}
.gwFilter-button {
  float: right;
  width: calc(100% - 24px);
  height: 32px;
}
.gwFilter-select-operator .select2-results__option {
  font-style: italic;
}
.gwFilter-select-operator .select2-results__option[aria-disabled=true] {
  padding-top: 10px;
}
.gwFilter-select-operator .select2-results__option[aria-disabled=true] .g-code-desc-container {
  font-weight: bold;
}
.gwFilter-strip select.form-control {
  color: var(--theme-value-text);
  background-color: var(--input-control-background-color-end);
}
.gwFilter-strip select.form-control:focus {
  background: #fff;
  border-color: #333;
  box-shadow: none;
}
.gwFilter-strip .inner-numeric-from .form-control,
.gwFilter-strip .inner-duration-from .form-control {
  padding: 0 6px 0 38px;
}
.gwFilter-strip .inner-numeric-to .form-control,
.gwFilter-strip .inner-duration-to .form-control {
  padding: 0 6px 0 24px;
}
.gwFilter-strip .inner-date-from .form-control {
  padding: 0 28px 0 40px;
}
.gwFilter-strip .inner-date-to .form-control {
  padding: 0 28px 0 24px;
}
.gwFilter-strip .gwDatePicker {
  height: 32px;
}
.gwFilter-strip .gwDatePicker .form-control {
  line-height: inherit;
}
.gwFilter-strip .form-control {
  padding: 0 6px;
  height: 32px;
  text-align: left;
}
.gwFilter-strip .descriptionReadOnly {
  background: #F2F2F2;
  color: var(--input-control-read-only-text-color);
  padding: 5px 6px 0 6px;
}
.gwFilter-strip .gwFilter-error {
  display: flex;
  align-items: center;
  float: left;
}
.gwFilter-strip .gwFilter-error .errorMessage {
  white-space: pre-wrap;
}
.gwFilter-strip .gwFilter-error i {
  color: #d90000;
  margin: 5px;
}
.gwFilter-strip a i {
  font-size: 15px;
  color: #003479;
}
.gwFilter-strip a i:hover {
  opacity: 0.5;
}
.g-filter-button.filter-button-disabled {
  color: #999;
  cursor: default !important;
}
.g-filter-button {
  flex: 0 1 auto;
  display: flex;
  white-space: nowrap;
  width: auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  min-width: auto;
  background: none;
  height: 26px;
  line-height: normal;
  color: var(--theme-icons);
  padding: 0 10px;
  font-size: 12px;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.g-filter-button .g-display-option-button {
  font-size: 14px;
  float: left;
  margin-top: 0px;
}
.g-filter-button .g-display-option-text {
  margin: 0 0 0 5px;
  font-weight: normal;
}
.g-filter-button:not(.filter-button-disabled) {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-filter-button:not(.filter-button-disabled):focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-filter-button:not(.filter-button-disabled):hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-filter-button:not(.filter-button-disabled):active {
  opacity: 0.3;
  text-decoration: none;
}
.gwFilter-actions {
  float: right;
  width: 100%;
  flex: 0 0 42px;
}
.gwFilter-actions .g-kg-top-container {
  height: auto;
}
.gwFilter-actions .g-kg-title-sub-container {
  width: 485px;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}
.gwFilter-actions .g-kg-title-container {
  line-height: 42px;
}
.gwFilter-actions .text-only .g-display-option-button,
.gwFilter-actions .buttons-only .g-display-option-text {
  display: none;
}
.gwFilter-actions .buttons-only .g-display-option-button {
  min-width: 14px;
}
.gwFilter-actions > ul {
  padding: 0;
}
.gwFilter-actions > ul > li {
  width: 100px;
  padding: 0;
  display: inline-block;
  float: left;
  list-style: none;
}
.gwFilter-actions > ul > li button {
  width: 100%;
  height: auto;
}
.gwFilter-actions > ul > li > ul:before {
  position: absolute;
  top: -10px;
  right: 0;
  display: inline-block;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #CCC;
  border-left: 10px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.gwFilter-actions > ul > li button[disabled] {
  color: #999;
}
.gwFilter-actions > ul > li button[disabled]:hover {
  opacity: 1;
}
.gwFilter-actions > ul > li span {
  position: relative;
  line-height: 20px;
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  margin-left: 5px;
}
.gwFilter-actions .g-flat-button {
  height: 30px;
  width: auto;
  margin: 0 2px 0 0;
  border-radius: 0;
}
.gwFilter-actions .g-flat-button:hover {
  opacity: 0.5;
}
.gwFilter-actions li:first-child .g-flat-button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.gwFilter-actions li:last-child .g-flat-button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.gwFilter-actions-main-buttons {
  padding: 0 6px 6px 6px;
  flex: 0 0 42px;
}
.gwFilter-actions-main-buttons .g-filter-add-button,
.gwFilter-actions-main-buttons .g-filter-find-button {
  width: 100px;
  height: auto;
  line-height: 32px;
  padding: 0;
}
.gwFilter-actions-main-buttons .g-filter-find-button {
  float: right;
}
.gwFilter-actions-popover {
  width: 300px;
  max-width: 300px;
}
.gwFilter-actions-popover.bottom {
  border-radius: 4px;
}
.gwFilter-actions-popover .arrow {
  display: block;
}
.gwFilter-actions-popover .popover-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.gwFilter-actions-popover .popover-content .gwDynamicMenu > span {
  padding: 6px 10px;
}
.gwFilter-actions-popover .popover-content .gwDynamicMenu .icon-spinner.icon-spin {
  margin-right: 10px;
}
.gwFilter-actions-popover .popover-content .gwDynamicMenu ul {
  margin-bottom: 0;
}
.gwFilter-actions-popover .popover-content .gwDynamicMenu li {
  position: relative;
}
.gwFilter-actions-popover .popover-content .gwDynamicMenu li a {
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwFilter-actions-popover .popover-content .gwDynamicMenu li .button-bar {
  display: flex;
}
.g-filter-widget-container {
  display: flex;
  flex: 1 0 100%;
}
.g-aggregate-filter-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
}
.g-aggregate-filter-container .g-aggregate-summary {
  flex: 1 0 auto;
}
.g-aggregate-filter-container .g-edit-aggregate {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  color: var(--theme-value-text);
  margin-left: -25px;
  padding-right: 11px;
}
.g-aggregate-filter-container .g-edit-aggregate:hover {
  opacity: 0.5;
}
.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/placeholder-flag.41ne5BLoWuBayPBs0rc7rhjNf0c.png');
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: '\00a0';
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon.flag-icon-ad {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ad.BikIfkvhBhtY5_TluJrG7hOBBfg.png');
}
.flag-icon.flag-icon-ae {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ae.w_OTw5p1IZVMkho6pMh6x4eO5zQ.png');
}
.flag-icon.flag-icon-af {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/af.hzvqlKG4k3HRVxfWhzXEZHuYyzE.png');
}
.flag-icon.flag-icon-ag {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ag.E6yj6YyV0QTk5EOesJsOMAVFcp4.png');
}
.flag-icon.flag-icon-ai {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ai.zRfQrSCs629zaSF4J4q3P16R8Hw.png');
}
.flag-icon.flag-icon-al {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/al.Pup3LtNzRfSE8EYKx_Anpre74AE.png');
}
.flag-icon.flag-icon-am {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/am.3l7lcFsiwf2b_j6SiS3KO3Fkd8w.png');
}
.flag-icon.flag-icon-ao {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ao.kEpCbqzHPkeuhIhuDirOPEXebao.png');
}
.flag-icon.flag-icon-aq {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/aq.WgtlpA0v4hgDKrx29fEp-9pIjHU.png');
}
.flag-icon.flag-icon-ar {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ar.A1zEItPYUO8B_OyGKw1YQnocJ5c.png');
}
.flag-icon.flag-icon-as {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/as.GkSReEN3WZ62mYzdQkpIUDp4maU.png');
}
.flag-icon.flag-icon-at {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/at.24MikLQ-qmJoYjHDjcvryFtBb8U.png');
}
.flag-icon.flag-icon-au {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/au.4oAhbli0_Q3XJkG-ZmycZwr9YxA.png');
}
.flag-icon.flag-icon-aw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/aw.f4Sa_W30tbaIhZN2Ht4r8DMrqXg.png');
}
.flag-icon.flag-icon-ax {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ax.GmczBf9bffJAWvB8xZBerz-4GQQ.png');
}
.flag-icon.flag-icon-az {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/az.vw5SdWLpk4txz33o5mCdZ2OAMNA.png');
}
.flag-icon.flag-icon-ba {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ba.dO1WLbf3tthkPUjjOcjzQFiSj_k.png');
}
.flag-icon.flag-icon-bb {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bb.wd3dDitsapIAgJ8Jdn-woMxppL4.png');
}
.flag-icon.flag-icon-bd {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bd.s0bRbJEi7YQYweNxTcUr-4C60sE.png');
}
.flag-icon.flag-icon-be {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/be.qxe4qB4Pa72z6Vyp96Ah_u4oOaE.png');
}
.flag-icon.flag-icon-bf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bf.24M26Di6vWd_PwPfBPEpNwkW_Xw.png');
}
.flag-icon.flag-icon-bg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bg.dLLGH6dLZuLm_fZv8bh8U_REgDM.png');
}
.flag-icon.flag-icon-bh {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bh.1ltx05FoZCefk2Nj87VGMTTIh9E.png');
}
.flag-icon.flag-icon-bi {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bi.irUqDWUU8tidwDwIcQ6EB6LsF_E.png');
}
.flag-icon.flag-icon-bj {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bj.QDzWv25FsqXUlJAON6Anx_PcwLE.png');
}
.flag-icon.flag-icon-bl {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bl.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-bm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bm.eznjgdnJf_mWSXShvcrQ-Gd-xNk.png');
}
.flag-icon.flag-icon-bn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bn.WQAW6TmbCnee4v5EoW4Pk0sI60Y.png');
}
.flag-icon.flag-icon-bo {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bo.VeUQzR4-vxgPgATTLeb5W4CRroA.png');
}
.flag-icon.flag-icon-bq {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bq.WeM4S7cbNIC_9nuNMqvzcIZKVqY.png');
}
.flag-icon.flag-icon-br {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/br.spHrG7kMIwbHOT8-sQ0BWMttYHk.png');
}
.flag-icon.flag-icon-bs {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bs.tOZ_hwOXFHkN3QmTKTdPFRT-FsM.png');
}
.flag-icon.flag-icon-bt {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bt.8Fnjl5kVyiJd_1ACx0_cXF7NvUc.png');
}
.flag-icon.flag-icon-bv {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bv.fg43BhIeoLA2512YaEMhFENOpLI.png');
}
.flag-icon.flag-icon-bw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bw.Do-dTURyHjp2vBNid32jJ25z8qY.png');
}
.flag-icon.flag-icon-by {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/by.ZBxill3hiUsVs4GNRIrPEPIPmmc.png');
}
.flag-icon.flag-icon-bz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/bz.mDHWXISw-B33H4YqDT4dFHP1TcU.png');
}
.flag-icon.flag-icon-ca {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ca.APty-NcHADxXCxncDJJjL--HTMU.png');
}
.flag-icon.flag-icon-cc {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cc.tqM56tkgTqyB2OmerJgo0Atppcc.png');
}
.flag-icon.flag-icon-cd {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cd.Vz12FySnklg135NoyPhf6QT84eU.png');
}
.flag-icon.flag-icon-cf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cf.a_VwINSg8jiaLV9NgzKzu1kcLrM.png');
}
.flag-icon.flag-icon-cg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cg.Mj88o4zvP5ztGat7L0jZqxPt164.png');
}
.flag-icon.flag-icon-ch {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ch.fzAu_AY0X0knu3kaPAdcpBNV554.png');
}
.flag-icon.flag-icon-ci {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ci.5pni6rkrz6dsY59ghCumJsOf5Zo.png');
}
.flag-icon.flag-icon-ck {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ck.CcxkhDg40u9lh_HeCWOY1Npspj0.png');
}
.flag-icon.flag-icon-cl {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cl.WZky6QNmgOkPFRuTYwimV4n9Np4.png');
}
.flag-icon.flag-icon-cm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cm.1AVCXRMXfpjPGxusW-kcoViuw4c.png');
}
.flag-icon.flag-icon-cn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cn.nVqkZjyiQ5ReSd9-WF5ddnnpPbE.png');
}
.flag-icon.flag-icon-co {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/co.gp2-WlPY3LF0k9GKVCv3vfDEyj4.png');
}
.flag-icon.flag-icon-cr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cr.7bmejnLm73B7o7EFNTGioJqifnk.png');
}
.flag-icon.flag-icon-cu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cu.fS3rEzBsWnkk_1Y_lwESULlIrHI.png');
}
.flag-icon.flag-icon-cv {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cv.M3Zy1aeURxEgoWb0Rld6z2wXw4A.png');
}
.flag-icon.flag-icon-cw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cw.I9P5tkQC27bRopJTAYM7NJnhO-0.png');
}
.flag-icon.flag-icon-cx {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cx._vZ-ZNVosPkXM8OgFtN61FcHnIo.png');
}
.flag-icon.flag-icon-cy {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cy.K5Z9jBrhOKR_TMqT8CbYalfpKgY.png');
}
.flag-icon.flag-icon-cz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/cz.NJhBzyqWyfRHBS6XFeteBQzasBQ.png');
}
.flag-icon.flag-icon-de {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/de.mIYsYxS0lxChiBR23bGPuzCmHDo.png');
}
.flag-icon.flag-icon-dj {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/dj.xmsebxmunds58yfJvZSl2h3DcZY.png');
}
.flag-icon.flag-icon-dk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/dk.e5DOZ0flSJvd8ULWT6Tp6ofiyuY.png');
}
.flag-icon.flag-icon-dm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/dm.FYkmvecExhkOieoyIULLyHgXFX4.png');
}
.flag-icon.flag-icon-do {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/do.zMS5egVEIc_rqfV5ewd78ILpxP8.png');
}
.flag-icon.flag-icon-dz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/dz.7vGad1ZlV_V0-UTq9ml_5E7Q_JY.png');
}
.flag-icon.flag-icon-ec {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ec.8qb4074G67STy4p0q84vcBYXfJI.png');
}
.flag-icon.flag-icon-ee {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ee.kFN_YpDHu8a0S_fL90nqHSF8KSE.png');
}
.flag-icon.flag-icon-eg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/eg.rGFx8r3i9Cv6jK6ytwU4G154T8o.png');
}
.flag-icon.flag-icon-eh {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/eh.h9XqP_4MfOdSlgsCedlwr1WkAS0.png');
}
.flag-icon.flag-icon-er {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/er._T_-VcaUGMnUxCuGlUNWp2VjN_o.png');
}
.flag-icon.flag-icon-es {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/es.x3ofouy56195M4akf9e5SPIjz_M.png');
}
.flag-icon.flag-icon-et {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/et.sV0x59U4Stfh4dsVc5OXkdbegcc.png');
}
.flag-icon.flag-icon-fi {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/fi.DUkiuma-kKMJVwe2QteWmJJhxrM.png');
}
.flag-icon.flag-icon-fj {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/fj.7UtIjxHJPyw-dtXNilxzQi2hMpw.png');
}
.flag-icon.flag-icon-fk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/fk.ATxCuxYS2nc-wDSEfx21qlGtnhA.png');
}
.flag-icon.flag-icon-fm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/fm.e_zCgBnd85Qqu8kDsg2oFctQbPE.png');
}
.flag-icon.flag-icon-fo {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/fo.oJrxTPaXjLRb6LmAgAAssmS7xbM.png');
}
.flag-icon.flag-icon-fr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/fr.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-ga {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ga.Vf-c9IoZOMx5cBGSMCUtTg00YoA.png');
}
.flag-icon.flag-icon-gb {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gb.h5kLQbp8Uandkp4tVr84vqmkecA.png');
}
.flag-icon.flag-icon-gd {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gd.ZY1bc0tWNTLPOeVXmIlaDBO871c.png');
}
.flag-icon.flag-icon-ge {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ge.dBd3AGxL60lPiv4jb5I75APnBrU.png');
}
.flag-icon.flag-icon-gf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gf.aY2NgRYm0P1uaERRzQK0NIW4Sw0.png');
}
.flag-icon.flag-icon-gg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gg.YrhZYh17NMexWQBCNBr7wYyBCfI.png');
}
.flag-icon.flag-icon-gh {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gh.rpHG_EF6ChHkVjWisNqcCpt3ZmI.png');
}
.flag-icon.flag-icon-gi {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gi.KBDOjkoFxEtiP8UA4SOU2TrPiwU.png');
}
.flag-icon.flag-icon-gl {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gl.Li049ucjojPbdj0FSwBiIJJ1yv4.png');
}
.flag-icon.flag-icon-gm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gm.Hg5Yb_4a5OQYBeXfuauX6hLVdf0.png');
}
.flag-icon.flag-icon-gn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gn.4s8rCkPsAD8srA8ylLPOMjC8V5g.png');
}
.flag-icon.flag-icon-gp {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gp.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-gq {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gq.1n_WSdKPUhDPzTHRLuaNOYvFUvQ.png');
}
.flag-icon.flag-icon-gr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gr.fy-Sm1kT3aOPfrXDVncxpkL-Vio.png');
}
.flag-icon.flag-icon-gs {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gs.y5RZdIp75KuR5Gw5JUXrtp5a-dk.png');
}
.flag-icon.flag-icon-gt {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gt.xb_Oqw90HvKog4kuLji4sE-KuDA.png');
}
.flag-icon.flag-icon-gu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gu.W5UgFqJ9f-vNfeopwd9fzfze2Mo.png');
}
.flag-icon.flag-icon-gw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gw.LWp8K_FXuCiv0D_EmkhZxsIJlFk.png');
}
.flag-icon.flag-icon-gy {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gy.4Fyo_TUv3N2MS4b5teznRVLymdo.png');
}
.flag-icon.flag-icon-hk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/hk.FJFDP7BMZhFM6OLVRmP_ns5Awcs.png');
}
.flag-icon.flag-icon-hm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/hm.y4Sv1KCSsfo4dp9cB_HFcHB1-F0.png');
}
.flag-icon.flag-icon-hn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/hn.YhBFTCDeF2dbvXf-5I3tN1w52Qo.png');
}
.flag-icon.flag-icon-hr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/hr.0ErF3JJYI4h2kYk8nfHtV1rdhyY.png');
}
.flag-icon.flag-icon-ht {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ht.3od1GtstJngv4NEOL7cDfZVbxlI.png');
}
.flag-icon.flag-icon-hu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/hu.shJOEvIhSnD4nNj96W7i_N58zso.png');
}
.flag-icon.flag-icon-id {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/id.KVTyYrVXVODBznmgSCqUOpu-DQE.png');
}
.flag-icon.flag-icon-ie {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ie.IKh-LacYP7wT7oPNdX2NMrRImz4.png');
}
.flag-icon.flag-icon-il {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/il.-ePGUzr8H16Vwd2o-NW-fiq3ph8.png');
}
.flag-icon.flag-icon-im {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/im.NpiJnzzDTIf55ssA58ZoBPsr2PY.png');
}
.flag-icon.flag-icon-in {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/in.kcv1ifhs6VM9IIJwbvxAmAU-v1E.png');
}
.flag-icon.flag-icon-io {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/io.TVoiTKud6CbKrDPNKKe3rm-7dog.png');
}
.flag-icon.flag-icon-iq {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/iq.IbnN6BQYhrC-V42AIDlcXLwjuOU.png');
}
.flag-icon.flag-icon-ir {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ir.WUV9rxKKjgaw5PuzCG-EU0z_5Eo.png');
}
.flag-icon.flag-icon-is {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/is.upg7Vq164Y_IBYQEeBwYpBopsOM.png');
}
.flag-icon.flag-icon-it {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/it.Zz55gcJ86u9MxaKStO0DJ75lFZg.png');
}
.flag-icon.flag-icon-je {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/je.q-rWzdG9_77rWi9EAXEaFM18zD8.png');
}
.flag-icon.flag-icon-jm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/jm.y1SPdDehysy6wW_0zQkErbsMGg8.png');
}
.flag-icon.flag-icon-jo {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/jo.4nV4W3JPPWqbXrCY5tLhTnOf3wc.png');
}
.flag-icon.flag-icon-jp {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/jp.Yg0N9a1P1lcMFzjdcrJ17RYh1L0.png');
}
.flag-icon.flag-icon-ke {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ke.BJeMHMU8bEDMgdyfKqv4C2Yzvbo.png');
}
.flag-icon.flag-icon-kg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/kg.ttI28wYjYUany9rclCjcqVnVg5c.png');
}
.flag-icon.flag-icon-kh {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/kh.Aig2XOzLaAP0qI0twTdtC1O8Drw.png');
}
.flag-icon.flag-icon-ki {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ki.qgbYIxLj1wZiIuLIyKr3sdSYr6U.png');
}
.flag-icon.flag-icon-km {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/km.z_O_W1UTgVqMAJDnnmZtlroLLiE.png');
}
.flag-icon.flag-icon-kn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/kn.2LeAPlIhq_5N4Tw97vlO7SLub1s.png');
}
.flag-icon.flag-icon-kp {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/kp.aXoogK04HN8FrLnUKwyJ6lCQ53M.png');
}
.flag-icon.flag-icon-kr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/kr.PcNe1p9sJIoKTmEVUk-fwGhcMQk.png');
}
.flag-icon.flag-icon-kw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/kw.EfiyF_7dJdEUCiWqbPh28raptlM.png');
}
.flag-icon.flag-icon-ky {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ky.lIsRLd6iHgYkY4Lid1lE-FP9n0M.png');
}
.flag-icon.flag-icon-kz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/kz.fqlgV5wVzHqUHboB-74P_TyE6Bc.png');
}
.flag-icon.flag-icon-la {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/la.vAOvzGMO8tWY98w4leV73NfQ-Y0.png');
}
.flag-icon.flag-icon-lb {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/lb.puIG0DacdzznbzC90o3vJ4vsjs4.png');
}
.flag-icon.flag-icon-lc {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/lc.xi5EUoQC3tLJILVFDPGZ4yi4b1I.png');
}
.flag-icon.flag-icon-li {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/li.8-SuuKWJmpL0M-7cwuthfP9l62g.png');
}
.flag-icon.flag-icon-lk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/lk.uPylpCmtAz3o6KpS0bpiTGbb4ag.png');
}
.flag-icon.flag-icon-lr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/lr.HDWrt6TBVPSLgISVHVwIpKVwkEI.png');
}
.flag-icon.flag-icon-ls {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ls.lfap3m-87A9OSEj2wmoPrGgWfgc.png');
}
.flag-icon.flag-icon-lt {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/lt.nodL4BULCwF6U4WYeXKyO15Oo8k.png');
}
.flag-icon.flag-icon-lu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/lu.chiHZSaxadewlk9p1shTW-BLnvA.png');
}
.flag-icon.flag-icon-lv {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/lv.otO_yVqczK3C-KkD-7JS6b0_NuQ.png');
}
.flag-icon.flag-icon-ly {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ly.v3GW_E4cehYvhQNDy-Vfaol512Q.png');
}
.flag-icon.flag-icon-ma {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ma.Ei7XF7D5nDe82IxextQETZq9hys.png');
}
.flag-icon.flag-icon-mc {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mc.8_4qSkW3Mn7XVN1MPjVKeIA1fT8.png');
}
.flag-icon.flag-icon-md {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/md.gcZuendEb0b0vUBk0sosGf4fbmo.png');
}
.flag-icon.flag-icon-me {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/me.TYEPa29e1aKYoaL8ikYv-C745Rk.png');
}
.flag-icon.flag-icon-mf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mf.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-mg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mg.foSRK5VilHOfgSSyAcF5Es0JYAI.png');
}
.flag-icon.flag-icon-mh {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mh.jXfb1DrH-plzlgjSmEkT5YBFOic.png');
}
.flag-icon.flag-icon-mk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mk.J5C5Ezb0liwzTY1XectRE-AHsdg.png');
}
.flag-icon.flag-icon-ml {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ml.2m1peh1sqLlLqhQFPTmfYW55AdM.png');
}
.flag-icon.flag-icon-mm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mm.U5Yl6pZRRsLtlfyfyzWLj8iiuF8.png');
}
.flag-icon.flag-icon-mn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mn.YoUXFOCWamFGI8F1lduVYz0im8Q.png');
}
.flag-icon.flag-icon-mo {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mo.JWDp3vzdfF7oviMI5Wy73shok-4.png');
}
.flag-icon.flag-icon-mp {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mp.npLzaDiJQoK21znPz0_5HXmvUQo.png');
}
.flag-icon.flag-icon-mq {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mq.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-mr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mr.NviYx62FRS6yuQ5fOtN0JZ4Mk-8.png');
}
.flag-icon.flag-icon-ms {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ms.2MP4l-WBk8vxd_-3fiWi3a1FjfY.png');
}
.flag-icon.flag-icon-mt {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mt.TogG9kFwNv3wj9U4KDQrLDdNp84.png');
}
.flag-icon.flag-icon-mu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mu.bEswb_wyc9mIfgHHGzCGwT3GIac.png');
}
.flag-icon.flag-icon-mv {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mv.M7D9UfAuEp7ldZaEWe0uiK3POZs.png');
}
.flag-icon.flag-icon-mw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mw.2yRVLijNqNE8C1ldmvQYvSlfRz0.png');
}
.flag-icon.flag-icon-mx {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mx.Mx2wlRGOO1CAYn4JYTTlrsU3b20.png');
}
.flag-icon.flag-icon-my {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/my.46ni4Ubgjr58auvg2LTBJepCkv8.png');
}
.flag-icon.flag-icon-mz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/mz.yQomNz0ucDb2Wq8tk2SoXQ0Q8Fg.png');
}
.flag-icon.flag-icon-na {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/na.yrHN8AwyDpqLBkqTUPlbOgcbQpU.png');
}
.flag-icon.flag-icon-nc {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/nc.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-ne {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ne.hNAxFZRrRQkt2Nj4priMMxjehsw.png');
}
.flag-icon.flag-icon-nf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/nf.vfmwIE2JqDEFm4KCXBERAYi1sGM.png');
}
.flag-icon.flag-icon-ng {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ng.QMej4WQYLJ89Cm99YJEdWPdSze0.png');
}
.flag-icon.flag-icon-ni {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ni.AHpezI3wgxrJ4UWtggw9u-MBVoY.png');
}
.flag-icon.flag-icon-nl {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/nl.5G_Omh2G4C5lm_y153BHeb79tb8.png');
}
.flag-icon.flag-icon-no {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/no.lVy4P5dU-xKAuVaWgA39ysyQatY.png');
}
.flag-icon.flag-icon-np {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/np.2KXF6DuhXO39uKFje9a6OEEpn_k.png');
}
.flag-icon.flag-icon-nr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/nr.AxZCDCmOsm0iKlF5iHU5cWj3470.png');
}
.flag-icon.flag-icon-nu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/nu.Xx8Ql0wFPujjlXKKWv3ijCHsVJc.png');
}
.flag-icon.flag-icon-nz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/nz.hAVZsHYqLHa4hajcGBlJJ909734.png');
}
.flag-icon.flag-icon-om {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/om._tpSYKvZ8Dr6DtrRONVqOytWVr0.png');
}
.flag-icon.flag-icon-pa {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pa.6mcJsrhHvn3s1qOjlf-ydzC5KFY.png');
}
.flag-icon.flag-icon-pe {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pe.giyhNMhpM3P3m7RBOKuDfBd2J7o.png');
}
.flag-icon.flag-icon-pf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pf.qGBwSg37ncxhyw4M5LFPoq7r7mA.png');
}
.flag-icon.flag-icon-pg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pg.HVI8V-UlAz-tlY4BWfYJW9Gw_Vs.png');
}
.flag-icon.flag-icon-ph {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ph.UaRrG5Ex5sRZUwa4aNRJsVZ_iIA.png');
}
.flag-icon.flag-icon-pk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pk.Irh4_G4Q_p0U6PFY1EkDyGQqpys.png');
}
.flag-icon.flag-icon-pl {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pl.K-SNljgcz82niFskMa6etl_qQSE.png');
}
.flag-icon.flag-icon-pm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pm.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-pn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pn.ozeDG6EmtICp6iFnOnXN-dz0d9Q.png');
}
.flag-icon.flag-icon-pr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pr.uPN_TKaR-NU6NLmJQH4Xc2G9Z6M.png');
}
.flag-icon.flag-icon-ps {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ps.HenQ5X18D4bfmJPhhIZLVFFOq1k.png');
}
.flag-icon.flag-icon-pt {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pt.YWeWE8EbMnICir3rve2doj3QKAM.png');
}
.flag-icon.flag-icon-pw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/pw.6GHn63i0qXpHEkTBxXP_lEwsst4.png');
}
.flag-icon.flag-icon-py {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/py.egNbw8sqFSCt7xQEzyoeifVX6vQ.png');
}
.flag-icon.flag-icon-qa {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/qa.ZT2ITKnycrGSlojYT0V7kVSYJq0.png');
}
.flag-icon.flag-icon-re {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/re.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-ro {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ro.f8qPamU__QX8lnj9M6tGYT9uc9M.png');
}
.flag-icon.flag-icon-rs {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/rs.Pwcbbw8Isig4kliCWv6JcZWLsVM.png');
}
.flag-icon.flag-icon-ru {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ru.YgvQxf1Exq9LLkK9oTWHrkmYlTQ.png');
}
.flag-icon.flag-icon-rw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/rw.f3yqV5mhcoGnwEOSNA_NIHoL7bw.png');
}
.flag-icon.flag-icon-sa {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sa.rSLmoQe7pitQ7_L-g9zivo6JUbo.png');
}
.flag-icon.flag-icon-sb {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sb.-wYlfmALoxamBapxd4wTm4L7Ns0.png');
}
.flag-icon.flag-icon-sc {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sc.mFh1CRZVVE2pi6h_pfVV2Ylm-vo.png');
}
.flag-icon.flag-icon-sd {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sd.TzXWbKrvjRR_EYccelM-xC0somk.png');
}
.flag-icon.flag-icon-se {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/se.GVtUCLnrcKP8C7WaGGv_ovmTXvo.png');
}
.flag-icon.flag-icon-sg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sg.h-A6YyLKvFnEHPFyRHq30HaE7q0.png');
}
.flag-icon.flag-icon-sh {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sh.q2QdhrCubr1PFmnqGEJ0firWVh4.png');
}
.flag-icon.flag-icon-si {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/si.sZIE5fSivvccXrEO91i0OHKoIxo.png');
}
.flag-icon.flag-icon-sj {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sj.pjx3OISWUL4vO6c5oqQzFczrgEc.png');
}
.flag-icon.flag-icon-sk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sk.X_oT5AiREzboKUhxou5SsXTyTWE.png');
}
.flag-icon.flag-icon-sl {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sl.LpG27F067nR-E7DB2qbGjQj-z4A.png');
}
.flag-icon.flag-icon-sm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sm.n98mvMWGhGae7lb5zupgjj4R1dw.png');
}
.flag-icon.flag-icon-sn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sn.ZR2u9MlOGuvsWiz-uJzwLqmcCqE.png');
}
.flag-icon.flag-icon-so {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/so.PfbsQNvTn_EBooJBvqTsB_8s09I.png');
}
.flag-icon.flag-icon-sr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sr.BErhUg9HI_u7TpqQ0RMaHJbc0_0.png');
}
.flag-icon.flag-icon-ss {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ss.bM1Yv3AJO3zxI6LfTmasMY8eIOc.png');
}
.flag-icon.flag-icon-st {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/st.E7sze5TXC-vybFZSIn1dzhQhN6E.png');
}
.flag-icon.flag-icon-sv {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sv.5A__moIYDbqYJO95US0c-bEXr0s.png');
}
.flag-icon.flag-icon-sx {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sx.cH16fxNz7BpnbPBZ3L2w8oBz6K8.png');
}
.flag-icon.flag-icon-sy {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sy.saN3j8sAMl4PgTHFxL52adAEE5U.png');
}
.flag-icon.flag-icon-sz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/sz.b3-fjtGkqG8dW7ybu4oflKx4-ls.png');
}
.flag-icon.flag-icon-tc {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tc.OuWOrKlpRQvO4HCala-p6C5YeoY.png');
}
.flag-icon.flag-icon-td {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/td.gYtFHS6UR0Qn7-RsRqP4eQq3j1Q.png');
}
.flag-icon.flag-icon-tf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tf.IgpqIoiqNz87jIPTHn66z5OIA6k.png');
}
.flag-icon.flag-icon-tg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tg.UqjvZh6gvIrGOjql0INczDNG5fc.png');
}
.flag-icon.flag-icon-th {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/th.X2oQ7WkrsdPT8Cibfk71-McRzNo.png');
}
.flag-icon.flag-icon-tj {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tj.oHqYilWoDkd-ypLzbaa480LJnSs.png');
}
.flag-icon.flag-icon-tk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tk.piLSKCPvdUTvnWnEwn0ROd-y7dg.png');
}
.flag-icon.flag-icon-tl {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tl._exQYsnRw5oR7d_Aw6Isdtl4m9Y.png');
}
.flag-icon.flag-icon-tm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tm.BCrjYtOGU1LrgEWr3ux53paRHH0.png');
}
.flag-icon.flag-icon-tn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tn.iqnKq2HdF8j_ySugaBuP37HRAdA.png');
}
.flag-icon.flag-icon-to {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/to.0Py8T-KyJgPrrByAg6fWUNA8GEE.png');
}
.flag-icon.flag-icon-tr {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tr.BUFds2vTNloBH9fE6CgTVXmKJqs.png');
}
.flag-icon.flag-icon-tt {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tt.yJIlxYEoPJHDHLhf6FnkjQpCq_E.png');
}
.flag-icon.flag-icon-tv {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tv.0UBP6kJzQKE2q-7Ca3pRVKyyxjw.png');
}
.flag-icon.flag-icon-tw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tw.E5D_f3-xB9-aK3ZKnNgsfGjkXWg.png');
}
.flag-icon.flag-icon-tz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/tz.1uFoOpGkywlBsa1yKid4m-IAZd8.png');
}
.flag-icon.flag-icon-ua {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ua.O75QOyrxUjoaMazNa6jdI2Z5Rs4.png');
}
.flag-icon.flag-icon-ug {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ug.QxEBWIaSdwZaCDIASHkdULF5yRo.png');
}
.flag-icon.flag-icon-um {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/um.JiJuuKnbGsVuf9--HHtWu0sbpTc.png');
}
.flag-icon.flag-icon-us {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/us.JiJuuKnbGsVuf9--HHtWu0sbpTc.png');
}
.flag-icon.flag-icon-uy {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/uy.VMRnfJxzEgZ-IAdgLYODucxRJNc.png');
}
.flag-icon.flag-icon-uz {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/uz.o-LL9D_IEAM-hF1xPDztL5LYxbA.png');
}
.flag-icon.flag-icon-va {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/va.bzlYjQc0al_Qdg204exWT3Vbyp4.png');
}
.flag-icon.flag-icon-vc {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/vc.MQhPVV_ZBuMAcr7Kh2jbilLrDu4.png');
}
.flag-icon.flag-icon-ve {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ve.61WCeZtCvXUQr7HU6CZ9AOQcr1o.png');
}
.flag-icon.flag-icon-vg {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/vg.kAXoPjEMXiSmz9myoLw2x_ebM3I.png');
}
.flag-icon.flag-icon-vi {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/vi.vGqygxI61IXvFZNyD8pVUSjCWdw.png');
}
.flag-icon.flag-icon-vn {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/vn.d9Hh1PmDyoIKlNW7CqEkw0dwmAg.png');
}
.flag-icon.flag-icon-vu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/vu.edAOcfor-IGAQua_ziVI1QcLq20.png');
}
.flag-icon.flag-icon-wf {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/wf.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-ws {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ws.nGCa20fY_P_sz4MsHC5JM3Cj2ug.png');
}
.flag-icon.flag-icon-ye {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/ye.OyHm71ZMrXtt0Wikq5ugry7g4nw.png');
}
.flag-icon.flag-icon-yt {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/yt.WK69K1XUtdWdNIShG1xPSk_c-Pk.png');
}
.flag-icon.flag-icon-za {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/za.aKs4-MafZrPye5yiYP7DaeDZKvw.png');
}
.flag-icon.flag-icon-zm {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/zm.0a7E8YNhbbSMj9zBtTHtO2c8eoM.png');
}
.flag-icon.flag-icon-zw {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/zw.Ge5wbXDrAV3-0l34RLcxHXRnxA8.png');
}
.flag-icon.flag-icon-es-ct {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/es-ct.8M2WsbnztzXpajm0IoBc9zUBnt8.png');
}
.flag-icon.flag-icon-eu {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/eu.bGyIstUVdTIE2X27nW4tT4Sw25k.png');
}
.flag-icon.flag-icon-gb-eng {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gb-eng.31QpN5bgGMAETl-N5xilshDKoEs.png');
}
.flag-icon.flag-icon-gb-nir {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gb-nir.9y1ehUByCJ3k0Q8i6G91-wGwJZE.png');
}
.flag-icon.flag-icon-gb-sct {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gb-sct.I619Xwi8UwzO0CV8ZkmnaC938aM.png');
}
.flag-icon.flag-icon-gb-wls {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/gb-wls.LVYur7wFaAt-0ONeqxF5Cg01KHA.png');
}
.flag-icon.flag-icon-un {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/un.sSVdOiFJwG9B54KH8QywUwohjFQ.png');
}
.flag-icon.flag-icon-xk {
  background-image: url('../../Content/Themes/Shared/components/gwFlag/flags/xk.6MJ0gxcCQeHav8Fk993IF7Aks9M.png');
}
.flag-icon {
  box-shadow: 0 0 1px #ddd, 0 0 1px #ddd, 0 0 1px #ddd;
}
.gwFlowStepsBar {
  position: relative;
  height: 25px;
  flex: 1 0 25px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  border-bottom: 1px solid #d3d6d9;
  background-color: #ebeef3;
}
.gwFlowStepsBar li {
  padding: 0;
}
.gwFlowStepsBar li:first-child {
  margin-left: -7px;
}
.gwFlowStepsBar li:first-child .gwFlowStepsBar-step-text:before {
  display: none;
}
.gwFlowStepsBar-arrow-left,
.gwFlowStepsBar-arrow-right {
  position: absolute;
  z-index: 7;
  display: inline-block;
  top: 0;
  height: 0;
  border-top: 12px solidtransparent;
  border-bottom: 12px solidtransparent;
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.gwFlowStepsBar-arrow-left {
  left: 5px;
  border-right: 12px solidtransparent;
}
.gwFlowStepsBar-arrow-right {
  right: 5px;
  border-left: 12px solidtransparent;
}
.gwFlowStepsBar-bar {
  width: 100%;
  height: auto;
  max-height: 24px;
  margin-left: 0;
  margin-bottom: 0;
  font-size: 0;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.gwFlowStepsBar-bar::-webkit-scrollbar {
  display: none;
}
.gwFlowStepsBar-step {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  background: transparent;
}
.gwFlowStepsBar-step .gwFlowStepsBar-end {
  display: none;
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-step-text {
  margin-right: 0;
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-step-text:after {
  display: none;
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-step-highlight {
  right: 0;
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-step-highlight:after {
  display: none;
}
.gwFlowStepsBar-step-shell {
  cursor: default;
}
.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-completed .gwFlowStepsBar-step-text {
  color: var(--flow-step-previous-step-hover-text-color);
  background: var(--flow-step-previous-step-hover-background-color-start);
}
.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-completed .gwFlowStepsBar-step-text:after {
  border-left: 7px solid var(--flow-step-previous-step-hover-background-color-start);
}
.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-completed.gwFlowStepsBar-step-final .gwFlowStepsBar-end:before {
  border-color: var(--flow-step-previous-step-hover-background-color-start);
  background-color: var(--flow-step-previous-step-hover-background-color-start);
}
.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-current .gwFlowStepsBar-step-text,
.gwFlowStepsBar-step-current .gwFlowStepsBar-step-text {
  color: #575A5D;
  background: #D8E0EB;
}
.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-current .gwFlowStepsBar-step-text:after,
.gwFlowStepsBar-step-current .gwFlowStepsBar-step-text:after {
  border-left: 7px solid #D8E0EB;
}
.gwFlowStepsBar-step-shell:hover .gwFlowStepsBar-step-current.gwFlowStepsBar-step-final .gwFlowStepsBar-end:before,
.gwFlowStepsBar-step-current.gwFlowStepsBar-step-final .gwFlowStepsBar-end:before {
  border-color: #D8E0EB;
  background-color: #D8E0EB;
}
.gwFlowStepsBar-step-clickable {
  cursor: pointer;
}
.gwFlowStepsBar-bar li:first-child .gwFlowStepsBar-step-text {
  padding-left: 14px;
}
.gwFlowStepsBar-step-text {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  height: 24px;
  padding-right: 8px;
  padding-left: 17px;
  color: #AAAAAA;
  background: #EBEEF3;
}
.gwFlowStepsBar-step-text:before,
.gwFlowStepsBar-step-text:after {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 24px;
  content: ' ';
}
.gwFlowStepsBar-step-text:before {
  top: 0;
  left: 1px;
  z-index: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 8px solid #fff;
}
.gwFlowStepsBar-step-text:after {
  top: 0;
  left: 100%;
  z-index: 1;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 7px solid #EBEEF3;
}
.gwFlowStepsBar-step-highlight {
  position: absolute;
  right: 1px;
  bottom: 0;
  left: 4px;
  z-index: 2;
  height: 2px;
  background-color: var(--flow-step-current-step-highlight-color);
}
.gwFlowStepsBar-step-highlight:before,
.gwFlowStepsBar-step-highlight:after {
  position: absolute;
  bottom: 0;
  z-index: 2;
  display: inline-block;
  width: 0;
  height: 2px;
  content: ' ';
}
.gwFlowStepsBar-step-highlight:before {
  left: -2px;
  border-top: 1px solid transparent;
  border-right: 1px solid var(--flow-step-current-step-highlight-color);
  border-bottom: 1px solid var(--flow-step-current-step-highlight-color);
  border-left: 1px solid transparent;
}
.gwFlowStepsBar-step-highlight:after {
  left: 100%;
  border-top: 1px solid var(--flow-step-current-step-highlight-color);
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 1px solid var(--flow-step-current-step-highlight-color);
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end,
.gwFlowStepsBar-step-final .gwFlowStepsBar-end {
  display: inline-block;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end {
  width: 25px;
  margin-left: 0;
  background-color: #EBEEF3;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: inline-block;
  width: 0;
  background-color: transparent;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 8px solid #fff;
  content: ' ';
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-one,
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-two {
  display: inline-block;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-one:before,
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-two:before,
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-one:after,
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-two:after {
  position: absolute;
  top: 4px;
  width: 0;
  height: 16px;
  background-color: transparent;
  content: ' ';
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-one:before,
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-two:before {
  border-left: 7px solid #EBEEF3;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-one:after,
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-two:after {
  border-left: 7px solid #fff;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-one:before {
  left: 9px;
  z-index: 5;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-one:after {
  left: 11px;
  z-index: 4;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-two:before {
  left: 15px;
  z-index: 3;
}
.gwFlowStepsBar-step-undefined .gwFlowStepsBar-end-arrow-two:after {
  left: 17px;
  z-index: 2;
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-end {
  width: 11px;
  height: 24px;
  background-color: var(--theme-background);
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-end:before,
.gwFlowStepsBar-step-final .gwFlowStepsBar-end:after {
  position: absolute;
  display: inline-block;
  top: 0;
  width: 6px;
  height: 24px;
  background-color: transparent;
  content: ' ';
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-end:before {
  left: -2px;
  border: 3px solid;
  border-color: #EBEEF3;
  background-color: #EBEEF3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-end:after {
  right: -2px;
  border: 3px solid #EBEEF3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #EBEEF3;
}
.gwFlowStepsBar-step-final .gwFlowStepsBar-end-arrow-one,
.gwFlowStepsBar-step-final .gwFlowStepsBar-end-arrow-two {
  display: none;
}
.gwFlowStepsBar-step-final.gwFlowStepsBar-step-completed .gwFlowStepsBar-end:before {
  border-color: #D8E0EB;
  background-color: #D8E0EB;
}
.gwFlowStepsBar-end {
  position: absolute;
  height: 24px;
  overflow: hidden;
}
.gwFlowStepsBar-end-undefined {
  top: 5px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #fff;
}
.gwFlowStepsBar-step-completed .gwFlowStepsBar-step-text {
  color: #575A5D;
  background: #D8E0EB;
}
.gwFlowStepsBar-step-completed .gwFlowStepsBar-step-text:after {
  border-left: 7px solid #D8E0EB;
}
.gwGroupBox {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  background: transparent;
  color: #000000;
  border: 1px solid #C4C7C8;
  border-radius: 0;
  box-shadow: none;
}
.gwGroupBox .panel-title {
  padding-left: 0;
  vertical-align: middle;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}
.gwGroupBox .panel-title > a {
  color: inherit;
}
.gwGroupBox .panel-title.Left {
  text-align: left;
}
.gwGroupBox .panel-title.Center {
  text-align: center;
}
.gwGroupBox .panel-title.Right {
  text-align: right;
}
.gwGroupBox .panel-heading {
  display: block;
  padding: 0;
  color: var(--group-box-header-text-color);
  background-image: none;
  border-radius: 0;
  border-bottom: 1px solid transparent;
}
.gwGroupBox .panel-heading .panel-title a {
  color: var(--group-box-header-text-color);
}
.gwGroupBox .panel-heading.g-groupbox-panel-heading .panel-title {
  padding-top: 0px;
  line-height: 42px;
  margin-left: 0px;
}
.gwGroupBox .panel-body {
  height: 100%;
  padding: 0;
  position: relative;
}
.gwGroupBox .panel-body:before,
.gwGroupBox .panel-body:after {
  display: table;
  content: " ";
}
.gwGroupBox .panel-body:after {
  clear: both;
}
.gwGroupBox [data-contents="gwGroupBox"] {
  width: 100%;
  height: 100%;
  padding: 0;
}
.gwGroupBox .panel-title a i {
  display: none;
}
.gwGroupBox .panel-title a:focus {
  text-decoration: none;
}
.gwGroupBox.g-show-collapsible-icon .panel-title a {
  position: relative;
}
.gwGroupBox.g-show-collapsible-icon .panel-title a span {
  margin-left: 12px;
}
.gwGroupBox.g-show-collapsible-icon .panel-title a .g-collapsible-icon {
  position: absolute;
  top: 50%;
  left: 8px;
  display: block;
  margin-top: -6px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.gwGroupBox.g-show-collapsible-icon .panel-title a .g-collapsible-icon.fa:before {
  content: "\f0de";
}
.gwGroupBox.g-show-collapsible-icon .panel-title a.collapsed > .g-collapsible-icon {
  margin-top: -6px;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.g-collapsed {
  height: auto !important;
}
.gwGroupBox-header-hidden > .panel-body {
  margin-top: 0;
}
.gwGroupBox:not(.gwGroupBox-header-hidden) .panel-body {
  height: calc(100% - 43px);
}
.gwGroupMessageDialog {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.recipient-area {
  display: flex;
}
/*bootstrap typeahead styling - start*/
.recipient-area .typeahead.dropdown-menu {
  background-color: var(--theme-background);
}
.recipient-area .typeahead.dropdown-menu .active a {
  background-color: var(--theme-hover);
}
.recipient-area.typeahead.dropdown-menu a {
  font-weight: bold;
  color: var(--theme-default-button);
}
/*bootstrap typeahead styling - end*/
.recipient-area__label {
  display: inline-block;
  padding: 3px 0 3px 7px;
  color: var(--theme-value-text);
  white-space: nowrap;
}
.recipient-area__label.recipient-area__label--underline a {
  color: var(--theme-value-text);
}
.recipient-area__label--bold {
  font-weight: bold;
}
.recipient-area__label:first-of-type {
  padding-left: 0;
  padding-right: 7px;
}
.recipient-area__label:last-of-type {
  padding-right: 0;
}
.recipient-area__label--underline {
  text-decoration: underline;
}
.recipient-list-inputbox__pill {
  background-color: #dddddd;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin: 2px;
  margin-left: 5px;
}
.recipient-list-inputbox__icon {
  color: #777777;
}
.recipient-list-inputbox__name {
  padding-right: 18px;
  padding-left: 18px;
  text-decoration-line: none;
  color: #777777;
  font-size: 12px;
}
.recipient-list-inputbox__all-drivers {
  text-decoration-line: underline;
}
.recipient-list-inputbox {
  background-color: var(--input-control-background-color-end);
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 75px;
}
.gwTextBox-textarea.recipient-list-inputbox {
  order: 0;
  padding-top: 3px;
}
.recipient-list-inputbox__inputbox {
  border: none;
  background-color: transparent;
  display: block !important;
  margin-left: 5px;
}
.message-history-area {
  border: var(--sidebar-panel-border-color) 1px solid;
  padding: 2px;
  margin-top: 10px;
  background-color: var(--input-control-background-color-end);
  overflow: auto;
  flex: 1 1;
  border-radius: 4px;
}
.current-message-area {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.current-message-area__current-message {
  width: 100%;
  resize: none;
}
.sendButton {
  margin: 10px 0px 0px 0px;
}
.recipient-list-inputbox__pill > .icon-times-circle {
  font-size: 18px;
  cursor: pointer;
}
.current-message-area__current-message.g-container .gwTextBox.gwTextBox-textarea.g-widget-border {
  padding-top: 3px;
}
.gwHorizontalWheelScroll {
  padding-bottom: 8px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.gwHorizontalWheelScroll:hover {
  padding-bottom: 0;
}
.gwHorizontalWheelScroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: rgba(0, 0, 0, 0);
}
.gwHorizontalWheelScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.gwHorizontalWheelScroll::-webkit-scrollbar-thumb {
  background: #575a5d;
}
.gwHyperlink {
  width: 100%;
  height: 100%;
  display: inline-block;
  color: var(--theme-default-button);
  font-size: 12px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 8px;
}
.gwHyperlink:hover {
  color: var(--theme-field-title);
}
.gw-img-edit {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  color: var(--input-control-text-color);
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-weight: Normal;
  font-size: 14px;
}
.gw-img-edit:not(.gw-viewonly) {
  background-color: #f2f2f2;
  border: 1px solid #d3d6d9;
  border-radius: 4px;
}
.gw-img-edit .gw-img-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.gw-img-edit .gw-img-wrap .gw-img {
  border-radius: 3px;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}
.gw-img-edit .gw-img-wrap .gw-img-fallback {
  max-width: 50%;
  max-height: 50%;
}
.gw-img-edit .gw-viewonly.gw-img-wrap {
  margin-right: 0px;
}
.gw-img-edit.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwSignature {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  color: var(--input-control-text-color);
  display: flex;
  flex-direction: column;
}
.gwSignature .gwSignature-canvas-wrapper {
  display: flex;
  flex: 1 0 0px;
  border: 1px solid var(--input-control-border-brush-start);
  background-color: var(--input-control-focused-background-color-end);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
  height: 100%;
}
.gwSignature.alert-danger {
  background-color: transparent;
}
.gwSignature.alert-danger .gwSignature-canvas-wrapper {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwSignature.alert-danger .gwSignature-canvas-wrapper:hover {
  border: 1px solid var(--input-control-border-brush-start);
  background-color: var(--input-control-focused-background-color-end);
}
.gwSignature.alert-danger.gwValidation-highlight .gwSignature-canvas-wrapper {
  background: #ffb0b0;
}
.gwSignature.alert-message-error {
  background-color: transparent;
}
.gwSignature.alert-message-error .gwSignature-canvas-wrapper {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.gwSignature.alert-message-error.gwValidation-highlight .gwSignature-canvas-wrapper {
  color: #ffc675;
}
.gwSignature.alert-warning {
  background-color: transparent;
}
.gwSignature.alert-warning .gwSignature-canvas-wrapper {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.gwSignature.alert-warning.gwValidation-highlight .gwSignature-canvas-wrapper {
  background: #fef293;
}
.gwSignature .gwSignature-canvas {
  width: 100%;
  height: 100%;
  touch-action: none;
}
.gwSignature .gwSignature-canvas.readonly {
  border: 1px solid var(--input-control-border-brush-start);
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.gwSignature .btn {
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: var(--button-background-color-start);
  color: var(--button-text-color);
  border-radius: 0 0 5px 5px;
}
.gwSignature .btn a,
.gwSignature .btn label {
  color: var(--button-text-color);
}
.gwSignature .btn i {
  color: var(--button-text-color);
}
.gwSignature .btn i.chevron {
  color: var(--button-text-color-lighten10);
}
.open > .gwSignature .btn:not(.disabled):not([disabled]),
.active > .gwSignature .btn:not(.disabled):not([disabled]),
.gwSignature .btn:not(.disabled):not([disabled]).pressed,
.gwSignature .btn:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .gwSignature .btn:not(.disabled):not([disabled]) i,
.active > .gwSignature .btn:not(.disabled):not([disabled]) i,
.gwSignature .btn:not(.disabled):not([disabled]).pressed i,
.gwSignature .btn:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .gwSignature .btn:not(.disabled):not([disabled]):focus,
.active > .gwSignature .btn:not(.disabled):not([disabled]):focus,
.gwSignature .btn:not(.disabled):not([disabled]).pressed:focus,
.gwSignature .btn:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .gwSignature .btn:not(.disabled):not([disabled]):focus i,
.active > .gwSignature .btn:not(.disabled):not([disabled]):focus i,
.gwSignature .btn:not(.disabled):not([disabled]).pressed:focus i,
.gwSignature .btn:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .gwSignature .btn:not(.disabled):not([disabled]):hover,
  .active > .gwSignature .btn:not(.disabled):not([disabled]):hover,
  .gwSignature .btn:not(.disabled):not([disabled]).pressed:hover,
  .gwSignature .btn:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--button-background-hover-color-start);
    color: var(--button-text-hover-color);
  }
}
.open > .gwSignature .btn:not(.disabled):not([disabled]):active,
.active > .gwSignature .btn:not(.disabled):not([disabled]):active,
.gwSignature .btn:not(.disabled):not([disabled]).pressed:active,
.gwSignature .btn:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--button-background-pressed-color-start);
  color: var(--button-text-hover-color);
}
.gwSignature .btn:not(.disabled):not([disabled]):focus {
  background-color: var(--button-background-color-start);
  color: var(--button-text-color);
  border: 1px dashed #333;
  border-radius: 0 0 5px 5px;
}
@media (hover) {
  .gwSignature .btn:not(.disabled):not([disabled]):hover {
    color: var(--button-text-hover-color);
    background-color: var(--button-background-hover-color-start);
    border-radius: 0 0 5px 5px;
  }
  .gwSignature .btn:not(.disabled):not([disabled]):hover a,
  .gwSignature .btn:not(.disabled):not([disabled]):hover i,
  .gwSignature .btn:not(.disabled):not([disabled]):hover label,
  .gwSignature .btn:not(.disabled):not([disabled]):hover span {
    color: var(--button-text-hover-color) !important;
  }
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .gwSignature .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.gwSignature .btn:not(.disabled):not([disabled]):active {
  color: var(--button-text-hover-color);
  background-color: var(--button-background-pressed-color-start);
  border-radius: 0 0 5px 5px;
}
.gwSignature .btn:not(.disabled):not([disabled]):active a,
.gwSignature .btn:not(.disabled):not([disabled]):active i,
.gwSignature .btn:not(.disabled):not([disabled]):active label,
.gwSignature .btn:not(.disabled):not([disabled]):active span {
  color: var(--button-text-hover-color) !important;
}
.gwSignature .btn.disabled,
.gwSignature .btn[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.gwSignature .btn.disabled:active,
.gwSignature .btn[disabled]:active {
  color: #999;
}
.gwSignature .btn.disabled:active a,
.gwSignature .btn[disabled]:active a,
.gwSignature .btn.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwSignature .btn[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwSignature .btn.disabled:active label,
.gwSignature .btn[disabled]:active label,
.gwSignature .btn.disabled:active span,
.gwSignature .btn[disabled]:active span {
  color: #999;
}
.gwSignature .btn.disabled:active i,
.gwSignature .btn[disabled]:active i {
  opacity: 1;
}
.gwSignature .btn.disabled:active i.g-menu-item-always-enabled-icon,
.gwSignature .btn[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwSignature .btn.disabled:active i.g-menu-item-always-enabled-icon:focus,
.gwSignature .btn[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwSignature .btn.disabled:active i.g-menu-item-always-enabled-icon:hover,
.gwSignature .btn[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwSignature .btn.disabled:active i.g-menu-item-always-enabled-icon:active,
.gwSignature .btn[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwSignature .btn.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwSignature .btn[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwSignature .btn.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.gwSignature .btn[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.gwSignature .btn.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.gwSignature .btn.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.gwSignature-config-control {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 50%;
  max-height: 50%;
  border-radius: 3px;
  color: var(--input-control-text-color);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.g-img-resizer-dialog .modal-dialog.g-modal-autoresize .modal-body {
  background-color: #333;
  padding: 0px;
}
.g-img-resizer {
  height: 100%;
}
.g-img-resizer #g-img-resizer-cropit {
  overflow: hidden;
  height: 100%;
  position: relative;
}
.g-img-resizer #g-img-resizer-cropit .cropit-preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.g-img-resizer #g-img-resizer-cropit .cropit-preview-wrapper .cropit-preview-image-container {
  background-color: #F2F2F2;
  border-radius: 3px;
  pointer-events: none;
}
.g-img-resizer #g-img-resizer-cropit .cropit-preview-wrapper .cropit-preview.cropit-image-loaded .cropit-preview-background {
  opacity: 0.2;
  cursor: move;
}
.g-img-resizer #g-img-resizer-cropit .cropit-preview-wrapper .cropit-preview.cropit-image-loaded .cropit-preview-image {
  cursor: move;
}
.g-img-resizer #g-img-resizer-cropit .cropit-preview-wrapper .cropit-image-zoom-input[disabled] {
  opacity: 0.2;
}
.g-img-resizer .g-img-resizer-menu {
  position: absolute;
  bottom: 0;
  background-color: #F2F2F2;
  width: 100%;
  height: 40px;
  padding: 0 0 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-img-resizer .g-img-resizer-menu input.cropit-image-zoom-input {
  width: 150px;
  margin-left: 7px;
  padding: 0;
}
.g-img-resizer .g-img-resizer-menu .g-img-resizer-btn {
  height: 16px;
  width: 16px;
  margin-left: 7px;
  cursor: pointer;
}
.gwItemSelector {
  width: 100%;
  height: 100%;
}
.gwItemSelector > .g-item-selector-heading {
  font-size: 18px;
  color: #000;
}
.gwItemSelector > .g-item-selector-sub-heading {
  font-style: italic;
  color: #000;
}
.gwItemSelector > .g-item-selector-search-list {
  position: relative;
  height: calc(100% - 100px);
  margin-top: 10px;
}
.gwItemSelector > .progress {
  margin-top: 20px;
}
.gwJobDocAddress-container {
  min-height: auto;
}
.gwJobDocAddress-container[data-bind*="isReadOnly: true"] {
  min-height: auto;
}
.gwJobDocAddress .gwGroupBox-container > .gwGroupBox {
  width: 100%;
  height: 100%;
}
.gwJobDocAddress .panel-heading {
  width: 100%;
}
.gwJobDocAddress .panel-title-container {
  display: flex;
  align-self: flex-end;
  align-items: baseline;
}
.gwJobDocAddress .panel-title-container .panel-title {
  flex: 1 1 auto;
}
.gwJobDocAddress .nav-pills {
  line-height: 42px;
  position: relative;
  top: 0;
  right: 0;
  text-align: center;
}
.gwJobDocAddress .nav-pills li.nav-pills-inner-container {
  background: #FFFFFF;
  border: 1px solid #d3d6d9;
  border-radius: 4px;
}
.gwJobDocAddress .nav-pills li.nav-pills-inner-container.disabled {
  background: transparent;
}
.gwJobDocAddress .nav-pills li:first-child {
  margin-right: 2.2%;
}
.gwJobDocAddress .nav-pills li:last-child {
  margin-left: 2.2%;
}
.gwJobDocAddress .nav-pills li {
  width: 31.8%;
  min-width: 26px;
  height: 36px;
  display: inline-block;
  float: left;
  line-height: normal;
  text-align: center;
  margin: 0 0 6px 0;
}
.gwJobDocAddress .nav-pills li a {
  padding: 8px 0 7px 0;
  border-radius: 3px;
}
.gwJobDocAddress .nav-pills li i,
.gwJobDocAddress .nav-pills li button {
  font-size: 14px;
}
.gwJobDocAddress .nav-pills .gwJobDocAddress-clear-all-icon-right {
  padding: 0;
  color: var(--address-control-mode-selector-background-color);
  display: flex;
  justify-content: flex-end;
  background: none;
}
.gwJobDocAddress .nav-pills .gwJobDocAddress-clear-all-icon-right button {
  padding: 8px 8px;
  min-height: 33px;
  height: 100%;
  background: none;
}
.gwJobDocAddress .nav-pills .gwJobDocAddress-clear-all-icon-right button:hover:not(:disabled) {
  opacity: 0.5;
}
.gwJobDocAddress .nav-pills a,
.gwJobDocAddress .nav-pills a:focus {
  color: var(--theme-default-button);
  background-image: none;
}
.gwJobDocAddress .nav-pills a:hover {
  color: var(--theme-default-button-text);
  background-color: var(--theme-hover);
  background-image: none;
}
.gwJobDocAddress .nav-pills > .active > a,
.gwJobDocAddress .nav-pills > .active > a:hover,
.gwJobDocAddress .nav-pills > .active > a:focus {
  color: var(--theme-default-button-text);
  background-color: var(--theme-default-button);
  background-image: none;
}
.gwJobDocAddress .tab-content {
  overflow: hidden;
  border: 0;
  border-radius: 4px;
  margin-bottom: 0;
}
.gwJobDocAddress .form-group {
  margin: 0;
}
.gwJobDocAddress .form-group .gwTextBox > input {
  margin-bottom: 0;
}
.gwJobDocAddress .btn-toggle {
  width: 90px;
  padding: 4px;
}
.gwJobDocAddress .form-horizontal {
  height: 20px;
  margin-bottom: 6px;
}
.gwJobDocAddress .form-horizontal .controls {
  margin-left: 88px;
}
.gwJobDocAddress .form-horizontal .col-xs-9 {
  padding-right: 0;
  padding-left: 0;
}
.gwJobDocAddress .form-horizontal .control-label {
  padding-top: 0;
  padding-right: 5px;
  padding-left: 0;
}
.gwJobDocAddress .form-horizontal .form-group {
  height: 100%;
}
.gwJobDocAddress .form-horizontal .form-group > div {
  height: 100%;
}
.gwJobDocAddress .form-horizontal .input-group-addon {
  padding-top: 1px;
}
.gwJobDocAddress .form-horizontal .input-group-addon i {
  margin-top: 0;
}
.gwJobDocAddress .g-selected-org {
  margin: 5px 0;
  font-weight: bold;
  line-height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwJobDocAddress [data-contents="gwGroupBox"] {
  overflow: visible;
}
.gwJobDocAddress .g-name-label {
  position: absolute;
  z-index: 1;
  margin-top: 2px;
  margin-left: 5px;
  line-height: 14px;
  text-align: left;
}
.gwJobDocAddress .g-contact-pane {
  margin-top: 30px;
}
.gwJobDocAddress .g-cols-2 ul {
  display: table;
  width: 100%;
}
.gwJobDocAddress .g-cols-2 li {
  display: table-cell;
  width: 50%;
  height: 100%;
  vertical-align: top;
}
.gwJobDocAddress .g-cols-2 li.g-col-1 input[type="text"] {
  border: 1px solid #d3d6d9;
}
.gwJobDocAddress .g-cols-2.gwJobDocAddress-bottom li.g-col-1 input[type="text"] {
  border-bottom-right-radius: 0;
}
.gwJobDocAddress .g-cols-2.gwJobDocAddress-bottom li.g-col-2 input[type="text"] {
  border-bottom-left-radius: 0;
}
.gwJobDocAddress li input[type="text"] {
  padding-top: 18px;
  padding-bottom: 3px;
}
.gwJobDocAddress li.gwJobDocAddress-inner input[type="text"] {
  border-radius: 0;
}
.gwJobDocAddress li.gwJobDocAddress-inner ul > li {
  position: relative;
}
.gwJobDocAddress li.gwJobDocAddress-top input[type="text"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.gwJobDocAddress li.gwJobDocAddress-bottom input[type="text"] {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.gwJobDocAddress li.gwJobDocAddress-inner input[type="text"],
.gwJobDocAddress li.gwJobDocAddress-top input[type="text"],
.gwJobDocAddress li.gwJobDocAddress-bottom input[type="text"] {
  border: 1px solid #d3d6d9;
}
.gwJobDocAddress button[disabled] {
  color: #ccc;
}
.gwJobDocAddress button[disabled]:hover {
  opacity: initial;
}
.gwJobDocAddress .gwAddressSearchBox {
  border: none;
  border-radius: 0;
}
.gwJobDocAddress .gwJobDocAddress-top .gwAddressSearchBox {
  border-radius: 4px 4px 0 0;
}
.gwJobDocAddress .icon-spinner {
  font-size: 16px;
  color: var(--theme-icons);
}
.gwJobDocAddress .g-readonly-jobDocAddress ul {
  padding: 0;
  margin-bottom: 0;
}
.gwJobDocAddress .g-readonly-jobDocAddress li {
  min-height: 20px;
  line-height: 20px;
  list-style-type: none;
  font-size: 14px;
  word-break: break-all;
}
.gwJobDocAddress .g-readonly-jobDocAddress li .g-contact-indicator {
  float: left;
  width: 18px;
  font-size: 11px;
  color: var(--theme-field-title);
}
.gwJobDocAddress .g-readonly-jobDocAddress li .g-contact-detail {
  float: left;
  width: calc(100% - 18px);
  word-break: break-all;
}
.gwJobDocAddress .g-readonly-jobDocAddress li .g-readonly-contact-email span {
  background: yellow;
  word-break: break-all;
}
.gwJobDocAddress .g-readonly-jobDocAddress li > span {
  font-size: 14px;
  color: var(--input-control-read-only-text-color);
}
.gwJobDocAddress .g-readonly-jobDocAddress .divider {
  border-top: 1px solid #d3d6d9;
  margin: 4px 0;
}
.gwJobDocAddress .g-readonly-jobDocAddress .g-empty-jobDocAddress-tab-text {
  display: block;
  border: 0;
  font-size: 11px;
  color: var(--theme-field-title);
  height: 18px;
  line-height: 18px;
  padding: 0;
}
.gwJobDocAddress .g-readonly-jobDocAddress .g-empty-jobDocAddress-tab-text > span {
  font-size: 14px;
  color: var(--input-control-read-only-text-color);
}
.gwJobDocAddress .g-jobDocAddress-loading-wrap {
  position: relative;
}
.gwJobDocAddress .g-jobDocAddress-loading-wrap > div {
  transform: translate(-50%, -50%);
}
.gwJobDocAddress .g-jobDocAddress-loading-wrap span {
  font-size: 16px;
}
.gwJobDocAddress .g-readonly-jobDocAddress,
.gwJobDocAddress .g-jobDocAddress-loading-wrap {
  min-height: 252px;
  max-height: 252px;
  border: 1px solid var(--input-control-border-brush-start);
  border-radius: 5px;
  padding: 3px 8px 5px 8px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.gwJobDocAddress-override-indicator {
  width: 20px;
  color: var(--theme-field-title);
  cursor: default;
}
.gwJobDocAddress-divider {
  height: 14px;
  margin-top: 11px !important;
  margin-right: 4px;
  border-left: 1px solid #d3d6d9;
}
.gwJobDocAddress-full-address {
  margin-top: 5px;
}
.gwJobDocAddress-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
}
.gwListView {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.gwListView .g-listview-header {
  flex: 0 0 42px;
  align-items: center;
  display: flex;
  line-height: 42px;
}
.gwListView .g-listview-header .g-listview-title-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwListView .g-listview-header .g-listview-title-container--Left {
  text-align: left;
}
.gwListView .g-listview-header .g-listview-title-container--Center {
  text-align: center;
}
.gwListView .g-listview-header .g-listview-title-container--Right {
  text-align: right;
}
.gwListView .g-listview-header .g-listview-title {
  font-weight: bold;
  font-size: 15px;
  color: var(--grid-title-font-color);
}
.gwListView .g-listview-header .g-listview-sub-title-container {
  flex: 1 0 0%;
  height: 100%;
}
.gwListView .g-listview-header .g-listview-sub-title-container .g-kg-actions-container {
  cursor: pointer;
  color: var(--theme-icons);
}
.gwListView .g-listview-header .g-listview-sub-title-container .g-kg-actions-container .g-kg-action:hover,
.gwListView .g-listview-header .g-listview-sub-title-container .g-kg-actions-container .g-container .g-kg-action:hover {
  color: var(--theme-value-text);
}
.gwListView .g-listview-header .g-listview-sub-title-container .g-kg-actions-container .g-kg-action:hover span,
.gwListView .g-listview-header .g-listview-sub-title-container .g-kg-actions-container .g-container .g-kg-action:hover span,
.gwListView .g-listview-header .g-listview-sub-title-container .g-kg-actions-container .g-kg-action:hover i,
.gwListView .g-listview-header .g-listview-sub-title-container .g-kg-actions-container .g-container .g-kg-action:hover i {
  opacity: 0.5;
}
.gwListView .g-listview-body {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.gwListView .g-listview-body .g-listview-noRecords {
  padding: 17px;
  min-height: 30px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 1;
  border: 1px solid var(--input-control-border-brush-start);
  border-radius: 4px;
}
.gwListView .g-count {
  font-size: 12px;
  color: var(--grid-item-count-font-color);
}
.gwListView.g-stackpanel div {
  position: static;
  height: auto;
}
.gwListView.g-stackpanel .g-anchored {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.gwListView-ul {
  min-height: 30px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 10px;
  flex: 1 1;
  border: 1px solid var(--input-control-border-brush-start);
  border-radius: 4px;
}
.gwListView-ul.g-templated {
  margin: 0;
}
.gwListView-ul > li {
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
}
.gwListView-ul > li.well {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-top: 0;
  border-bottom: 1px solid var(--input-control-border-brush-start);
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  box-shadow: none;
}
.gwListView-ul > li.well:last-child {
  border-bottom: 0;
}
.gwListView-ul > li.well.templated {
  position: relative;
}
.gwListView-ul > li.well.templated .g-absolute {
  float: unset;
  width: 0%;
}
.gwListView-ul > li.well.templated .g-absolute .g-anchored {
  position: absolute;
}
.gwListView-ul > li.well i.gwListView-li-button-icon {
  padding: 5px;
}
.gwListView-ul > li.well i.gwListView-li-button-icon:hover {
  opacity: 0.5;
}
.gwListView-ul > li.gwListView-li:hover a {
  text-decoration: none;
}
.gwListView-li-inner {
  position: relative;
  display: inline-block;
  width: 100%;
}
.gwListView-li-inner.g-editable {
  padding-left: 17.5px;
}
.gwListView-li > a {
  position: absolute;
  z-index: 1;
}
.gwListView-li > a.non-templated {
  position: relative;
  display: block;
  padding: 0.7em 15px 0.7em 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwListView-li > a.non-templated i {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -7px;
  text-align: right;
}
.gwListView-ul .gwListView-li {
  display: flex;
  flex-direction: column;
}
.gwListView-ul .gwListView-li-template {
  display: flex;
  flex-direction: column;
}
.gwListView-ul .gwListView-li-button {
  display: flex;
  justify-content: flex-end;
}
.gwListView-ul .gwListView-li-button--edit,
.gwListView-ul .gwListView-li-button--remove {
  align-self: center;
}
.gwListView-ul .gwListView-li-button--edit > a,
.gwListView-ul .gwListView-li-button--remove > a {
  padding: 5px;
  color: var(--theme-icons);
}
.gwListView.alert-danger {
  background: none;
}
.gwListView.alert-danger .g-listview-header {
  background-color: #fafafa;
}
.gwListView.alert-danger .gwListView-ul {
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwListView.alert-danger .gwListView-ul .gwListView-li {
  border-color: #d90000;
}
.g-loading-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.g-loading-animation .icon-spinner {
  font-size: 18px;
  line-height: unset;
  margin-right: 8px;
}
.gwMapsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
}
.gwMapsContainer .gwEventLog {
  padding-right: 5px;
  height: 100%;
  flex: 0 1 auto;
}
.gwMapsContainer .gwEventLog .g-sidebar-left {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-wrap {
  width: 600px;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  border-right: 1px solid var(--sidebar-panel-border-color);
  border-radius: 5px;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-grip.list-unstyled.left {
  align-self: center;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents {
  overflow: hidden;
  height: 100%;
  position: relative;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher {
  height: 100%;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar {
  height: 100%;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar div.g-close-sidebar {
  float: right;
  height: 24px;
  cursor: pointer;
  width: 16px;
  margin-top: 2px;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar div.g-close-sidebar i {
  left: 0px;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar .gwGroupBox-container {
  height: 100%;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar .gwGroupBox-container > .gwGroupBox .panel-title {
  width: 90%;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar .gwGroupBox-container > .gwGroupBox .panel-collapse {
  height: calc(100% - 33px);
  width: 100%;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar .gwGroupBox-container > .gwGroupBox .panel-collapse .panel-body {
  height: 100%;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar .gwGroupBox-container > .gwGroupBox .panel-collapse .padded {
  padding: 2px 10px 0;
  height: 100%;
}
.gwMapsContainer .gwEventLog .g-sidebar-left .g-sidebar-contents .g-map-plugin-launcher .gwEventLogSideBar .gwGroupBox-container > .gwGroupBox .panel-collapse .padded .search-results {
  height: 100%;
}
.gwMapsContainer .gwMaps {
  height: 100%;
  flex: 1 1 50px;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
}
.gwMapsContainer .gwMaps iframe {
  height: 100%;
}
.gwMapsContainer .gwMaps .map-wrapper {
  height: 100%;
  flex: 1 1 50px;
  padding-top: 1px;
  padding-right: 5px;
  position: relative;
}
.gwMapsContainer .gwMaps .map-wrapper .map {
  height: 100%;
  border: 1px solid var(--sidebar-panel-border-color);
  border-radius: 5px;
}
.gwMapsContainer .gwMaps .map-wrapper .map .cluster div,
.gwMapsContainer .gwMaps .map-wrapper .map .cluster-label {
  font-weight: normal !important;
  color: #fff !important;
}
.gwMapsContainer .gwMaps .g-sidebar-right {
  flex: 0 1 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.gwMapsContainer .gwMaps .g-sidebar-right .g-sidebar-wrap {
  min-width: 350px;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  border-right: 1px solid var(--sidebar-panel-border-color);
  border-radius: 5px;
}
.gwMapsContainer .gwMaps .g-sidebar-right .gwSearchBox {
  height: 32px;
}
.gwMapsContainer .gwMaps .g-sidebar-right .g-grip.list-unstyled {
  align-self: center;
}
.gwLocationTrackingSidebar > .gwGroupBox-container > .gwGroupBox,
.gwGeofencingSideBar > .gwGroupBox-container > .gwGroupBox {
  background: transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.gwLocationTrackingSidebar > .gwGroupBox-container > .gwGroupBox .padded,
.gwGeofencingSideBar > .gwGroupBox-container > .gwGroupBox .padded {
  padding: 2px 2px 0;
}
.gwLocationTrackingSidebar .search-options,
.gwGeofencingSideBar .search-options {
  padding: 2px;
}
.gwLocationTrackingSidebar .search-options > div,
.gwGeofencingSideBar .search-options > div {
  margin-bottom: 2px;
}
.gwLocationTrackingSidebar .search-options .input-group,
.gwGeofencingSideBar .search-options .input-group {
  height: 28px;
}
.gwLocationTrackingSidebar .search-options .input-group input,
.gwGeofencingSideBar .search-options .input-group input {
  height: 28px;
  padding-top: 0;
  font-style: italic;
  background-image: none;
}
.gwLocationTrackingSidebar .search-options .input-group input[disabled],
.gwGeofencingSideBar .search-options .input-group input[disabled] {
  cursor: default;
  background-color: #fff;
}
.gwLocationTrackingSidebar .search-options .input-group .input-group-addon,
.gwGeofencingSideBar .search-options .input-group .input-group-addon {
  height: 28px;
}
.gwLocationTrackingSidebar .search-options .input-group .input-group-addon i,
.gwGeofencingSideBar .search-options .input-group .input-group-addon i {
  margin-top: 0;
}
.gwLocationTrackingSidebar .search-options .input-group .input-group-addon .icon-search,
.gwGeofencingSideBar .search-options .input-group .input-group-addon .icon-search {
  color: var(--theme-icons);
  animation: none;
}
.gwLocationTrackingSidebar .advanced-filter-tag,
.gwGeofencingSideBar .advanced-filter-tag {
  position: absolute;
  top: 4px;
  left: 7px;
  z-index: 2;
  font-style: italic;
}
.gwLocationTrackingSidebar .advanced-filter-tag a,
.gwGeofencingSideBar .advanced-filter-tag a {
  margin-right: 2px;
}
.gwLocationTrackingSidebar .advanced-filter-tag a:hover,
.gwGeofencingSideBar .advanced-filter-tag a:hover {
  color: #003479;
}
.gwLocationTrackingSidebar .search-results,
.gwGeofencingSideBar .search-results {
  padding: 0;
  margin: 2px 0 0 0;
  overflow: hidden;
  background: none;
}
.gwLocationTrackingSidebar .search-results .view-route,
.gwGeofencingSideBar .search-results .view-route {
  padding: 6px 12px;
}
.gwLocationTrackingSidebar .search-results > .search-result-group,
.gwGeofencingSideBar .search-results > .search-result-group {
  list-style: none;
  background: white;
}
.gwLocationTrackingSidebar .search-results > .search-result-group > .search-result-items,
.gwGeofencingSideBar .search-results > .search-result-group > .search-result-items {
  padding: 0;
  overflow: hidden;
}
.gwLocationTrackingSidebar .search-results > .search-result-group > .search-result-items .search-result-item,
.gwGeofencingSideBar .search-results > .search-result-group > .search-result-items .search-result-item {
  padding: 4px 8px;
  font-size: 12px;
  color: var(--theme-general-text);
  list-style: none;
  cursor: pointer;
  background: none;
}
.gwLocationTrackingSidebar .search-results > .search-result-group > .search-result-items .search-result-item.active,
.gwGeofencingSideBar .search-results > .search-result-group > .search-result-items .search-result-item.active {
  background-color: #ebeef3;
}
.gwLocationTrackingSidebar .search-results > .search-result-group > .search-result-items .search-result-item + .search-result-item,
.gwGeofencingSideBar .search-results > .search-result-group > .search-result-items .search-result-item + .search-result-item {
  border-top: 1px solid #d3d6d9;
}
.gwLocationTrackingSidebar .search-results > .search-result-group + .search-result-group,
.gwGeofencingSideBar .search-results > .search-result-group + .search-result-group {
  margin-top: 2px;
}
.gwLocationTrackingSidebar .search-results .search-result-title,
.gwGeofencingSideBar .search-results .search-result-title {
  margin: 0;
  font-size: 12px;
  background: none;
}
.gwLocationTrackingSidebar .search-results .search-result-title.outlier,
.gwGeofencingSideBar .search-results .search-result-title.outlier {
  opacity: 0.5;
}
.gwLocationTrackingSidebar .search-results .search-result-title a,
.gwGeofencingSideBar .search-results .search-result-title a {
  display: flex;
  line-height: 40px;
  align-items: center;
  color: var(--theme-icons);
  font-weight: 600;
  text-decoration: none;
  padding: 0px 8px 0px 7px;
}
.gwLocationTrackingSidebar .search-results .search-result-title a i,
.gwGeofencingSideBar .search-results .search-result-title a i {
  top: 6px;
  right: 8px;
  color: var(--theme-icons);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  font-size: 10px;
}
.gwLocationTrackingSidebar .search-results .search-result-title a.collapsed i,
.gwGeofencingSideBar .search-results .search-result-title a.collapsed i {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.gwLocationTrackingSidebar .search-results .search-result-title a span.g-messages,
.gwGeofencingSideBar .search-results .search-result-title a span.g-messages {
  position: relative;
}
.gwLocationTrackingSidebar .search-results .search-result-title a span.g-messages .unread-icon,
.gwGeofencingSideBar .search-results .search-result-title a span.g-messages .unread-icon {
  position: absolute;
  height: 10px;
  width: 10px;
  left: 56%;
  top: 20%;
  background-color: red;
  border-radius: 50%;
  border: solid 1px white;
}
.gwLocationTrackingSidebar .search-results .search-result-title a span.g-speed-alert,
.gwGeofencingSideBar .search-results .search-result-title a span.g-speed-alert {
  color: #d90000;
}
.gwLocationTrackingSidebar .search-results .search-result-title a span.g-icons,
.gwGeofencingSideBar .search-results .search-result-title a span.g-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}
.gwLocationTrackingSidebar .search-results .search-result-title a span.g-icons i,
.gwGeofencingSideBar .search-results .search-result-title a span.g-icons i {
  margin: 5px 10px;
  transform: none;
  font-size: 14px;
}
.gwLocationTrackingSidebar .search-results .search-result-title a .g-speed-alert.icon-tachometer:before,
.gwGeofencingSideBar .search-results .search-result-title a .g-speed-alert.icon-tachometer:before {
  margin: 0 5px;
  font-size: 17px;
}
.gwLocationTrackingSidebar .search-results .actions,
.gwGeofencingSideBar .search-results .actions {
  float: right;
  margin: -1px -4px 1px 8px;
}
.gwLocationTrackingSidebar .search-results .actions i,
.gwGeofencingSideBar .search-results .actions i {
  color: var(--theme-icons);
}
.gwLocationTrackingSidebar .search-results .actions i:hover,
.gwGeofencingSideBar .search-results .actions i:hover {
  opacity: 0.5;
}
.gwLocationTrackingSidebar .search-results .actions .gps-actions-icon,
.gwGeofencingSideBar .search-results .actions .gps-actions-icon {
  padding: 2px 5px;
}
.gwLocationTrackingSidebar .search-results .type,
.gwGeofencingSideBar .search-results .type {
  font-weight: bold;
  color: var(--theme-default-button);
}
.gwLocationTrackingSidebar .search-results .driver,
.gwGeofencingSideBar .search-results .driver {
  font-weight: bold;
}
.gwLocationTrackingSidebar .search-results .time,
.gwGeofencingSideBar .search-results .time {
  margin: 8px 0;
}
.gwLocationTrackingSidebar .search-results .g-dashboard,
.gwGeofencingSideBar .search-results .g-dashboard {
  display: inline-flex;
}
.gwLocationTrackingSidebar .search-results .road-sign,
.gwGeofencingSideBar .search-results .road-sign {
  width: 30px;
  height: 30px;
  border: 3px solid #EE0000;
  border-radius: 100px;
  margin: 10px 0 0 10px;
}
.gwLocationTrackingSidebar .search-results .speed-limit,
.gwGeofencingSideBar .search-results .speed-limit {
  text-align: center;
  padding-top: 4px;
  color: #000000;
  font-family: Trebuchet MS;
  font-weight: bold;
  font-size: 12px;
}
.gwLocationTrackingSidebar .history-options,
.gwGeofencingSideBar .history-options {
  padding-top: 4px;
  margin-top: 4px;
  border-top: 1px solid #d3d6d9;
}
.gwLocationTrackingSidebar .history-options .header,
.gwGeofencingSideBar .history-options .header {
  margin-bottom: 4px;
  font-weight: bold;
  color: #003479;
}
.gwLocationTrackingSidebar .history-options .g-anchored,
.gwGeofencingSideBar .history-options .g-anchored {
  position: relative;
}
.gwLocationTrackingSidebar .history-options .start-date-time,
.gwGeofencingSideBar .history-options .start-date-time {
  height: 44px;
}
.gwLocationTrackingSidebar .history-options .start-date-time input,
.gwGeofencingSideBar .history-options .start-date-time input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.gwLocationTrackingSidebar .history-options .end-date-time,
.gwGeofencingSideBar .history-options .end-date-time {
  height: 43px;
  margin-bottom: 8px;
}
.gwLocationTrackingSidebar .history-options .end-date-time input,
.gwGeofencingSideBar .history-options .end-date-time input {
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.gwLocationTrackingSidebar .g-geofencetool,
.gwGeofencingSideBar .g-geofencetool {
  overflow: hidden;
  margin-bottom: 6px;
  margin-top: 6px;
  height: 34px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row nowrap;
}
.gwLocationTrackingSidebar .g-geofencetool .g-geofencetool-button,
.gwGeofencingSideBar .g-geofencetool .g-geofencetool-button {
  flex: 1 1 20px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row nowrap;
  max-width: 50%;
}
.gwLocationTrackingSidebar .g-geofencetool .g-geofencetool-button + .g-geofencetool-button,
.gwGeofencingSideBar .g-geofencetool .g-geofencetool-button + .g-geofencetool-button {
  margin-left: 2px;
}
.gwLocationTrackingSidebar .g-geofencetool .g-geofencetool-button .btn,
.gwGeofencingSideBar .g-geofencetool .g-geofencetool-button .btn {
  flex: 1 1 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.gwLocationTrackingSidebar .g-drawing.disabled,
.gwGeofencingSideBar .g-drawing.disabled,
.gwLocationTrackingSidebar .g-geofencesearch.disabled,
.gwGeofencingSideBar .g-geofencesearch.disabled {
  color: var(--input-control-read-only-text-color);
  border-color: #D3D6D9;
  background-color: #FFFFFF;
}
.gwLocationTrackingSidebar .g-geofence-result,
.gwGeofencingSideBar .g-geofence-result {
  padding: 5px 7px;
  background-color: white;
}
.gwLocationTrackingSidebar .g-geofence-result + .g-geofence-result,
.gwGeofencingSideBar .g-geofence-result + .g-geofence-result {
  border-top: 1px solid #d3d6d9;
}
.gwLocationTrackingSidebar .g-geofence-result .g-geofencetool,
.gwGeofencingSideBar .g-geofence-result .g-geofencetool {
  padding: 0;
}
.gwLocationTrackingSidebar .g-geofence-drawing,
.gwGeofencingSideBar .g-geofence-drawing,
.gwLocationTrackingSidebar .g-geofence-search,
.gwGeofencingSideBar .g-geofence-search {
  margin-bottom: 6px;
  border-top: 1px solid #d3d6d9;
  border-bottom: 1px solid #d3d6d9;
}
.gwLocationTrackingSidebar .g-geofence-drawing .g-address-geofence,
.gwGeofencingSideBar .g-geofence-drawing .g-address-geofence,
.gwLocationTrackingSidebar .g-geofence-search .g-address-geofence,
.gwGeofencingSideBar .g-geofence-search .g-address-geofence {
  font-size: 12px;
  color: var(--theme-general-text);
}
.gwLocationTrackingSidebar .g-geofence-drawing .g-address-geofence .company-header,
.gwGeofencingSideBar .g-geofence-drawing .g-address-geofence .company-header,
.gwLocationTrackingSidebar .g-geofence-search .g-address-geofence .company-header,
.gwGeofencingSideBar .g-geofence-search .g-address-geofence .company-header {
  font-weight: bold;
}
.gwLocationTrackingSidebar .g-geofence-drawing .g-address-geofence .g-sidebar-contents-description.g-no-additional-info,
.gwGeofencingSideBar .g-geofence-drawing .g-address-geofence .g-sidebar-contents-description.g-no-additional-info,
.gwLocationTrackingSidebar .g-geofence-search .g-address-geofence .g-sidebar-contents-description.g-no-additional-info,
.gwGeofencingSideBar .g-geofence-search .g-address-geofence .g-sidebar-contents-description.g-no-additional-info {
  text-align: center;
}
.gwLocationTrackingSidebar .g-geofence-drawing .g-address-geofence.readOnly,
.gwGeofencingSideBar .g-geofence-drawing .g-address-geofence.readOnly,
.gwLocationTrackingSidebar .g-geofence-search .g-address-geofence.readOnly,
.gwGeofencingSideBar .g-geofence-search .g-address-geofence.readOnly {
  cursor: text;
}
.gwLocationTrackingSidebar .g-geofence-drawing .g-address-geofence.readOnly .company-header,
.gwGeofencingSideBar .g-geofence-drawing .g-address-geofence.readOnly .company-header,
.gwLocationTrackingSidebar .g-geofence-search .g-address-geofence.readOnly .company-header,
.gwGeofencingSideBar .g-geofence-search .g-address-geofence.readOnly .company-header,
.gwLocationTrackingSidebar .g-geofence-drawing .g-address-geofence.readOnly .address,
.gwGeofencingSideBar .g-geofence-drawing .g-address-geofence.readOnly .address,
.gwLocationTrackingSidebar .g-geofence-search .g-address-geofence.readOnly .address,
.gwGeofencingSideBar .g-geofence-search .g-address-geofence.readOnly .address {
  opacity: 0.5;
}
.gwLocationTrackingSidebar .g-geofence-drawing .active,
.gwGeofencingSideBar .g-geofence-drawing .active,
.gwLocationTrackingSidebar .g-geofence-search .active,
.gwGeofencingSideBar .g-geofence-search .active {
  background-color: #ebeef3;
}
.gwLocationTrackingSidebar .g-geofence-drawing .active .g-address-geofence,
.gwGeofencingSideBar .g-geofence-drawing .active .g-address-geofence,
.gwLocationTrackingSidebar .g-geofence-search .active .g-address-geofence,
.gwGeofencingSideBar .g-geofence-search .active .g-address-geofence {
  cursor: pointer;
}
.gwLocationTrackingSidebar .g-geofence-drawing .active .g-address-geofence.readOnly,
.gwGeofencingSideBar .g-geofence-drawing .active .g-address-geofence.readOnly,
.gwLocationTrackingSidebar .g-geofence-search .active .g-address-geofence.readOnly,
.gwGeofencingSideBar .g-geofence-search .active .g-address-geofence.readOnly {
  cursor: text;
}
.gwMapsContainer .refresh-timer {
  position: absolute;
  top: 12px;
  left: 11px;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-value-text);
  background-color: var(--input-control-background-color-start);
  border-radius: 3px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.298039);
}
.gwMapsContainer .refresh-timer div {
  float: left;
  padding: 7px 16px 8px 17px;
}
.gwMapsContainer .refresh-timer i {
  display: block;
  float: right;
  padding: 8px 13px 7px;
  cursor: pointer;
  border-left: 1px solid #d3d6d9;
  line-height: inherit;
  font-size: 18px;
}
.gwMapsContainer .refresh-timer i:hover {
  opacity: 0.5;
}
.gwMapsContainer .map-tooltip {
  position: absolute;
}
.gwMapsContainer .map-tooltip .content {
  width: 300px;
  min-width: 180px;
  max-width: 500px;
  max-height: 230px;
  font-size: 12px;
  color: #000;
  border: 1px solid #333;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 0 rgba(87, 90, 93, 0.4);
}
.gwMapsContainer .map-tooltip .cluster {
  overflow: hidden;
  background-color: #fafafa;
}
.gwMapsContainer .map-tooltip .cluster ul {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 202px;
  padding: 0;
  margin: 0;
}
.gwMapsContainer .map-tooltip .cluster li {
  padding: 6px 8px 4px;
  list-style-type: none;
  cursor: pointer;
  border-bottom: 1px solid #d3d6d9;
}
.gwMapsContainer .map-tooltip .cluster li:last-child {
  border-bottom: 0;
}
.gwMapsContainer .map-tooltip .cluster li.active {
  background-color: #ebeef3;
}
.gwMapsContainer .map-tooltip .cluster > div {
  padding: 4px 8px;
  background-color: #f2f2f2;
  border-top: 1px solid #d3d6d9;
}
.gwMapsContainer .map-tooltip .single {
  padding: 6px 8px 4px;
  background-color: #f2f2f2;
}
.gwMapsContainer .map-tooltip h4 {
  margin: 0 0 2px;
  font-size: 12px;
  color: #003479;
}
.gwMapsContainer .map-tooltip .arrow {
  position: absolute;
}
.gwMapsContainer .map-tooltip .arrow:before,
.gwMapsContainer .map-tooltip .arrow:after {
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  content: '';
}
.gwMapsContainer .map-tooltip .arrow:before {
  margin-left: -7px;
  border-color: rgba(51, 51, 51, 0);
  border-width: 6px 7px;
}
.gwMapsContainer .map-tooltip .arrow:after {
  margin-left: -6px;
  border-color: rgba(242, 242, 242, 0);
  border-width: 5px 6px;
}
.gwMapsContainer .map-tooltip .arrow.bottom {
  bottom: 1px;
}
.gwMapsContainer .map-tooltip .arrow.bottom:before,
.gwMapsContainer .map-tooltip .arrow.bottom:after {
  top: 100%;
}
.gwMapsContainer .map-tooltip .arrow.bottom:before {
  border-top-color: #333;
}
.gwMapsContainer .map-tooltip .arrow.bottom:after {
  border-top-color: #f2f2f2;
}
.gwMapsContainer .map-tooltip .arrow.top {
  top: 1px;
}
.gwMapsContainer .map-tooltip .arrow.top:before,
.gwMapsContainer .map-tooltip .arrow.top:after {
  bottom: 100%;
}
.gwMapsContainer .map-tooltip .arrow.top:before {
  border-bottom-color: #333;
}
.gwMapsContainer .map-tooltip .arrow.top:after {
  border-bottom-color: #f2f2f2;
}
.gwMapsContainer .over-limit {
  top: 80px;
  width: 100%;
}
.gwMapsContainer .over-limit .content {
  width: 367px;
  margin: 0 auto;
}
.gwMapsContainer .g-marker-label {
  position: relative;
  padding-left: 16px;
}
.gwMapsContainer .g-marker-label .content {
  width: 100%;
  height: 18px;
  max-width: 250px;
  padding: 0 4px 0 2px;
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-size: 12px;
  color: #000;
  background-color: #fff;
  border: 1px solid #003479;
  border-left: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwMapsContainer .g-marker-label .curve {
  position: absolute;
  background: none;
  top: 0px;
  left: 9px;
}
.gwLocationSearchBox .input-group-addon i.icon-map-marker {
  font-size: 18px;
}
.g-location-search-item .main {
  font-weight: 500;
}
.g-location-search-item .additional {
  font-size: 11px;
  color: #999;
}
.g-location-search-item.active .additional {
  color: #fff;
}
.g-activity-indicator {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0 5px;
}
.g-detail-text {
  flex-grow: 1;
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-inactive {
  background-color: #d90000;
}
.g-slow {
  background-color: #ffc825;
}
.g-active {
  background-color: #58ba00;
}
.g-location-tracking-root i.g-location-popup,
.g-geofencing-root i.g-geofencing-popup {
  font-size: 20px;
  color: var(--theme-icons);
}
.g-autogeofence {
  font-size: 18px;
  color: var(--theme-value-text);
  background-color: var(--input-control-background-color-start);
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.298039);
  padding: 7px 16px 8px 17px;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: 12px;
  position: relative;
}
.g-autogeofence[disabled] {
  cursor: default;
  color: #999999;
}
.message-multiple-button-container {
  width: 100%;
  padding: 10px;
}
.message-multiple-button {
  display: flex;
  align-items: baseline;
}
.message-multiple-button__image {
  margin-right: 10px;
}
.g1-map-component-marker-label {
  max-width: 250px;
  padding: 0 4px;
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #d3d6d9;
  transform: translate(-50%, -62px);
}
.g1-map-component-container {
  padding: 4px;
}
.g1-map-component {
  border-radius: 5px;
}
.tracking-map-popup {
  max-height: 100px;
}
.tracking-map-popup .tracking-map-popup__progress:not(:last-child) {
  border-bottom: 1px solid #d3d6d9;
  margin-bottom: 5px;
}
.tracking-map-popup__header {
  padding: 0px 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--theme-default-button);
}
.tracking-map-popup__header:hover {
  color: var(--theme-field-title);
}
.tracking-map-popup__progress {
  padding: 0px 5px;
}
.gwMeasure {
  height: 100%;
  display: flex;
  border: 1px solid var(--input-control-border-brush-start);
  background-color: #fff;
}
.gwMeasure label {
  padding: 4px 7px 0;
}
.gwMeasure input.form-control:focus:not([readonly]) {
  background: none !important;
  border: none !important;
}
.gwMeasure .input-group-btn.btn {
  z-index: 2;
  width: 18px;
  padding: 0;
  border: none;
  color: var(--icons-color);
  background: none;
  display: block;
  order: 1;
}
.gwMeasure .input-group-btn.btn[disabled] {
  opacity: 1;
}
.gwMeasure .input-group-btn.btn span {
  text-align: center;
  vertical-align: middle;
}
.gwMeasure .input-group-btn.btn i.icon-caret-down {
  text-align: center;
}
.gwMeasure .input-group-btn.btn.g-measure-mouseover.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwMeasure .input-group-btn.btn.g-measure-mouseover.alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.gwMeasure .input-group-btn.btn.g-measure-mouseover.alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.gwMeasure .input-group-btn.btn.g-measure-mouseover.alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.gwMeasure .input-group-btn.btn.g-measure-mouseover .icon-caret-down {
  opacity: 0.5;
}
.gwMeasure.g-measure-focused {
  background: var(--input-control-focused-background-color-end);
  border: 1px solid var(--input-control-focused-border-brush-end);
}
.gwMeasure.g-measure-focused .input-group-btn.btn {
  outline: none;
}
.gwMeasure input {
  min-width: 0;
}
.gwMeasure input.g-measure-unit {
  text-align: center;
  text-transform: uppercase;
  position: static;
  border: none;
  padding-left: 0;
  padding-right: 0;
  background: none;
}
.gwMeasure input.g-measure-magnitude {
  text-align: right;
  position: static;
  flex: 1 1 0px;
  padding-right: 6px;
  border: none;
  background: none;
}
.gwMeasure .g-measure-divider {
  display: inline-block;
  align-self: flex-end;
  height: 12px;
  width: 1px;
  z-index: 3;
  background-color: var(--input-control-border-brush-start);
  border-width: 0;
  order: 1;
}
.gwMeasure:not(.g-measure-focused).alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwMeasure:not(.g-measure-focused).alert-danger .g-measure-divider {
  background-color: #d90000;
}
.gwMeasure:not(.g-measure-focused).alert-danger input,
.gwMeasure:not(.g-measure-focused).alert-danger button {
  color: #333;
  background-color: transparent;
}
.gwMeasure:not(.g-measure-focused).alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.gwMeasure:not(.g-measure-focused).alert-message-error .g-measure-divider {
  background-color: #ffaa33;
}
.gwMeasure:not(.g-measure-focused).alert-message-error input,
.gwMeasure:not(.g-measure-focused).alert-message-error button {
  color: #333;
  background-color: transparent;
}
.gwMeasure:not(.g-measure-focused).alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.gwMeasure:not(.g-measure-focused).alert-warning .g-measure-divider {
  background-color: #ffc825;
}
.gwMeasure:not(.g-measure-focused).alert-warning input,
.gwMeasure:not(.g-measure-focused).alert-warning button {
  color: #333;
  background-color: transparent;
}
.gwMeasure:not(.g-measure-focused).alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.gwMeasure:not(.g-measure-focused).alert-info .g-measure-divider {
  background-color: #3a87ad;
}
.gwMeasure:not(.g-measure-focused).alert-info input,
.gwMeasure:not(.g-measure-focused).alert-info button {
  color: #333;
  background-color: transparent;
}
.gwMeasure.g-readonly-measure .input-group-btn {
  pointer-events: initial;
}
.gwMeasure.g-readonly-measure .input-group-btn .icon-caret-down {
  color: var(--content-text-color);
  cursor: default;
  opacity: 0.5;
}
label.g-measure-label:not(.disabled) {
  padding-right: 18px;
}
.g-measure-unit-length-1 input.g-measure-unit {
  width: 30px;
}
.g-measure-unit-length-2 input.g-measure-unit {
  width: 44px;
}
.g-measure-unit-length-3 input.g-measure-unit {
  width: 58px;
}
.gwMeasure-popover {
  z-index: 1050;
  font-size: 13px;
}
.gwMeasure-popover ul {
  padding: 0;
  margin: 0;
}
.gwMeasure-popover li {
  list-style-type: none;
}
.gwMeasure-popover .popover-title {
  display: none;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure label {
  display: none;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure:not(.g-measure-focused).alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
  border-style: solid;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure:not(.g-measure-focused).alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
  border-style: solid;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure:not(.g-measure-focused).alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
  border-style: solid;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure:not(.g-measure-focused).alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
  border-style: solid;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure.g-measure-focused {
  background: var(--input-control-focused-background-color-end) !important;
  border: 1px solid var(--input-control-focused-border-brush-end);
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure button.g-measure-unit {
  z-index: 1000;
  height: 100%;
  left: 0;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure input.g-measure-magnitude {
  border-width: 1px 0 1px 1px;
}
.kgCanvas .kgRow .kgCell .gwMeasure.gwDataGridMeasure input.g-measure-unit {
  border-width: 1px 1px 1px 0;
}
.kgCanvas .kgRow .kgCell .g-measure-unit-length-1 input.g-measure-unit {
  width: 30px;
}
.kgCanvas .kgRow .kgCell .g-measure-unit-length-2 input.g-measure-unit {
  width: 44px;
}
.kgCanvas .kgRow .kgCell .g-measure-unit-length-3 input.g-measure-unit {
  width: 58px;
}
.gwMeasureCalculator {
  width: 259px;
}
.gwMeasureCalculator .list-group {
  padding-top: 53px;
  margin-bottom: 0;
  padding-left: 0;
}
.gwMeasureCalculator .list-group a {
  text-align: center;
}
.gwMeasureCalculator a.list-group-item {
  color: var(--grid-viewport-text-color);
  background-color: #fff;
  border: 1px solid var(--grid-viewport-border-color);
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
}
.gwMeasureCalculator a.list-group-item.active {
  z-index: 2;
  color: var(--grid-viewport-text-color);
  background-color: var(--grid-viewport-selected-row-color);
  border-color: var(--grid-viewport-border-color);
}
.gwMeasureCalculator a.list-group-item.invalid {
  background-color: var(--grid-readonly-viewport-background-color);
  background-image: none;
  cursor: default;
}
.gwMeasureCalculator a.list-group-item.invalid:hover {
  color: var(--grid-viewport-text-color);
  background-color: var(--grid-readonly-viewport-background-color);
}
.gwMeasureCalculator a.list-group-item:hover {
  color: var(--theme-hover-text);
  text-decoration: none;
  background-color: var(--theme-hover);
}
.gwMeasureCalculator a.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.gwMeasureCalculator a.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.gwMeasureCalculator .magnitude {
  width: 200px;
}
.gwMeasureCalculator .unit {
  left: 214px;
  width: 55px;
}
.gwMeasureCalculator .unit label {
  margin-left: 10px;
}
.gwMeasureCalculator .unit input {
  text-align: center;
}
.g-anchored > .gwNumeric {
  height: 100%;
}
.gwNumeric-column {
  text-align: right;
}
.gwOptionButtonGrid {
  width: 100%;
  height: 100%;
}
.gwOptionButtonGrid__wrap {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: stretch;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.gwOptionButtonGrid .gwGroupBox-container {
  height: 100%;
}
.gwOptionButtonGrid__cols--0 .gwOptionButton:first-child {
  border-bottom-left-radius: 4px;
}
.gwOptionButtonGrid__cols--0 .gwOptionButton:last-child {
  border-top-right-radius: 4px;
}
.gwOptionButtonGrid__cols--0 .gwOptionButton:not(:last-child) {
  border-right: none;
}
.gwOptionButtonGrid__cols--1,
.gwOptionButtonGrid__cols--2,
.gwOptionButtonGrid__cols--3,
.gwOptionButtonGrid__cols--4 {
  flex-wrap: wrap;
}
.gwOptionButtonGrid__cols--1 .gwOptionButton:first-child {
  border-top-right-radius: 4px;
}
.gwOptionButtonGrid__cols--1 .gwOptionButton:last-child {
  border-bottom-left-radius: 4px;
}
.gwOptionButtonGrid__cols--1 .gwOptionButton:not(:last-child) {
  border-bottom: none;
}
.gwOptionButtonGrid__cols--2 .gwOptionButton {
  width: 50%;
}
.gwOptionButtonGrid__cols--2 .gwOptionButton:nth-child(odd) {
  border-right: none;
}
.gwOptionButtonGrid__cols--2 .gwOptionButton:not(:last-child):not(:nth-last-child(2)) {
  border-bottom: none;
}
.gwOptionButtonGrid__cols--2 .gwOptionButton:nth-child(2) {
  border-top-right-radius: 4px;
}
.gwOptionButtonGrid__cols--2 .gwOptionButton:nth-last-child(2) {
  border-bottom-left-radius: 4px;
}
.gwOptionButtonGrid__cols--3 .gwOptionButton {
  width: 33%;
}
.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-child(3n+1),
.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-child(3n+2) {
  border-right: none;
}
.gwOptionButtonGrid__cols--3 .gwOptionButton:not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)) {
  border-bottom: none;
}
.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-child(3) {
  border-top-right-radius: 4px;
}
.gwOptionButtonGrid__cols--3 .gwOptionButton:nth-last-child(3) {
  border-bottom-left-radius: 4px;
}
.gwOptionButtonGrid__cols--4 .gwOptionButton {
  width: 25%;
}
.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4n+1),
.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4n+2),
.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4n+3) {
  border-right: none;
}
.gwOptionButtonGrid__cols--4 .gwOptionButton:not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)):not(:nth-last-child(4)) {
  border-bottom: none;
}
.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-child(4) {
  border-top-right-radius: 4px;
}
.gwOptionButtonGrid__cols--4 .gwOptionButton:nth-last-child(4) {
  border-bottom-left-radius: 4px;
}
.gwOptionButton {
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--input-control-border-brush-start);
}
.gwOptionButton:first-child {
  border-top-left-radius: 4px;
}
.gwOptionButton:last-child {
  border-bottom-right-radius: 4px;
}
.gwOptionButton__icon-position--left {
  flex-direction: row;
}
.gwOptionButton__icon-position--left .gwOptionButton__icon {
  margin-right: 5px;
}
.gwOptionButton__icon-position--right {
  flex-direction: row-reverse;
}
.gwOptionButton__icon-position--right .gwOptionButton__icon {
  margin-left: 5px;
}
.gwOptionButton__icon-position--top {
  flex-direction: column;
}
.gwOptionButton__icon-position--top .gwOptionButton__icon {
  margin-bottom: 5px;
}
.gwOptionButton__icon-position--bottom {
  flex-direction: column-reverse;
}
.gwOptionButton__icon-position--bottom .gwOptionButton__icon {
  margin-top: 5px;
}
.gwOptionButton__icon-position--top .gwOptionButton__text,
.gwOptionButton__icon-position--bottom .gwOptionButton__text {
  width: 100%;
}
.gwOptionButton__icon-size--m {
  font-size: medium;
}
.gwOptionButton__icon-size--l {
  font-size: large;
}
.gwOptionButton__icon-size--xl {
  font-size: x-large;
}
.gwOptionButton__icon-size--xxl {
  font-size: xx-large;
}
.gwOptionButton__label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5px;
  margin-bottom: 0;
  text-align: center;
  text-shadow: none;
  cursor: pointer;
  font-size: 14px;
  background-color: var(--input-control-background-color-start);
  border: 1px dashed transparent;
}
.gwOptionButton__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwOptionButton__radio {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.gwOptionButton__radio:focus + .gwOptionButton__label {
  border-color: var(--theme-general-text);
}
.gwOptionButton__placeholder,
.gwOptionButton__radio[disabled] {
  cursor: default;
}
.gwOptionButton__radio:checked + .gwOptionButton__label {
  color: #fff;
  background: #2292c6;
  font-weight: bold;
}
.gwOptionButton__radio:checked + .gwOptionButton__label:not(.disabled):hover,
.gwOptionButton__radio:checked + .gwOptionButton__label:not(.disabled):focus {
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}
.gwPdfViewer,
.gwPdfViewer__content {
  height: 100%;
  width: 100%;
}
.gwPdfViewer__loading-animation {
  height: 100%;
}
.gwPdfViewer__error-message {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.gwProgressLinear {
  position: relative;
  height: 5px;
  background: #ededed;
  border-radius: 10px;
}
.gwProgressLinear__point {
  position: absolute;
  top: 0;
  left: 50%;
  width: 10px;
  height: 10px;
  margin-top: -2.5px;
  margin-left: -5px;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
}
.gwProgressLinear__bar {
  display: block;
  height: 100%;
}
.gwProgressLinear__point--status-default {
  border-color: #575a5d;
}
.gwProgressLinear__point--status-success {
  border-color: #58ba00;
}
.gwProgressLinear__point--status-warning {
  border-color: #ffc825;
}
.gwProgressLinear__bar--status-default {
  background-color: #575a5d;
}
.gwProgressLinear__bar--status-success {
  background-color: #58ba00;
}
.gwProgressLinear__bar--status-warning {
  background-color: #ffc825;
}
.gwRichTextEditor {
  width: 100%;
  height: 100%;
}
.gwSearchList {
  height: 100%;
  min-width: 525px;
  display: flex;
  flex-direction: column;
}
.gwSearchList .g-grid-action-button {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 15px;
  outline: none;
  background: transparent;
  border: none;
  border-radius: 0;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwSearchList .g-grid-action-button:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwSearchList .g-grid-action-button:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwSearchList .g-grid-action-button:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwSearchList .g-grid-action-button i {
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: var(--theme-icons);
}
.gwSearchList .g-kg-status-search {
  font-size: 12px;
  text-align: center;
  color: #575a5d;
}
.gwSearchList .gwSearchList-wrap {
  position: relative;
  height: 100%;
}
.gwSearchList .g-search-list-filter {
  max-height: 285px;
  height: auto;
  min-height: 165px;
  flex: 0 1 auto;
}
.gwSearchList .g-search-list-mask {
  position: absolute;
  background: var(--theme-background);
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.gwSearchList .g-search-results {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 162px;
  min-height: 0;
}
.gwSearchList .g-search-results ul {
  list-style: none;
}
.gwSearchList .g-search-results .notifications-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: 67px;
  width: 100%;
  z-index: 100;
}
.gwSearchList .g-search-results .notifications-wrapper .searching {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.gwSearchList .g-search-results .notifications-wrapper .icon-spinner {
  font-size: 30px;
  border-radius: 50%;
  line-height: initial;
  margin: 0;
}
.gwSearchList .g-search-results .notifications-wrapper .gwLabel-wrapper {
  height: auto;
  margin-top: 18px;
}
.gwSearchList .g-search-results .notifications-wrapper .gwLabel {
  padding: 6px;
  border-radius: 6px;
}
.gwSearchList .g-search-results .notifications-wrapper.g-grouped {
  margin-top: 92px;
}
.gwSearchList .g-search-results .dropdown-menu > li > a:hover {
  background-color: var(--theme-hover);
}
.gwSearchList .g-search-results .g-search-noresult {
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  text-align: center;
}
.gwSearchList .g-search-results > ul {
  margin-left: 0;
}
.gwSearchList .g-search-results > ul > li img {
  float: left;
  color: var(--icons-color);
}
.gwSearchList .g-search-results .kgCanvas .kgRow .kgCell .gwSearchListAddresses .kgCellText div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.gwSearchList .g-search-results .kgCanvas .kgRow .kgCell .gwSearchListAddresses .kgCellText div.g-multi-address {
  padding-right: 33px;
}
.gwSearchList .g-search-results .kgCanvas .kgRow .kgCell .gwSearchListAddresses .kgCellText span {
  padding: 0;
  line-height: 20px;
}
.gwSearchList .g-search-results .kgCanvas .kgRow .kgCell .gwSearchListAddresses .kgCellText span.badge {
  position: absolute;
  top: 4px;
  right: 8px;
  padding: 3px 7px;
  line-height: 1;
}
.gwSearchList .g-search-results-items-container {
  height: calc(100% -  45px);
  overflow: hidden;
}
.gwSearchList .g-search-results-grid {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
}
.gwSearchList .g-search-results-grid .koGrid {
  flex: 1 0 auto;
  height: 120px;
}
.gwSearchList .g-search-results-grid .g-kg-top-container {
  flex: 0 0 42px;
}
.gwSearchList .g-search-results-grid .row-template-container,
.gwSearchList .g-search-results-grid .row-template-container > div {
  height: 100%;
}
.gwSearchList .g-search-results-grid-error {
  z-index: 1005;
  display: none;
  margin: 0;
  font-size: 16px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.gwSearchList .g-search-results-grid-error > div {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.gwSearchList .g-search-results-grid-error-visible {
  display: inline-block;
}
.gwSearchList .g-search-results-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: Normal;
  font-size: 13px;
}
.gwSearchList .g-search-results-header-contextmenu {
  position: absolute;
  z-index: 100;
  display: none;
}
.gwSearchList .g-search-results-header-contextmenu .dropdown-menu {
  position: static;
  display: block;
}
.gwSearchList .g-search-results-header-contextmenu .dropdown-menu a {
  width: 100%;
}
.gwSearchList .g-grid-actions {
  width: 100%;
}
.gwSearchList .g-grid-actions .gwDynamicMenu ul {
  background-color: var(--grid-header-background-color-end);
}
.gwSearchList .g-quickSearch-container {
  display: flex;
  flex: 0 1 auto;
  width: 200px;
  flex-flow: row nowrap;
  align-items: center;
  margin: 4px 0 0 7px;
}
.gwSearchList .g-quickSearch-container .g-quickSearch-input-group {
  position: relative;
}
.gwSearchList .g-quickSearch-container .g-quickSearch-input-group > Label {
  padding: 2px 8px 0;
  display: block;
}
.gwSearchList .g-quickSearch-container .g-quickSearch-input-group .g-quickSearch-text {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 12px;
  padding-bottom: 2px;
}
.gwSearchList .g-quickSearch-container .g-quickSearch-button {
  margin-left: 5px;
  flex: 0 0 15px;
}
.g-kg-title-container--fill {
  flex-grow: 1;
}
.gwSearchList__refresh-container {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
  border: none;
  flex-grow: 1;
  margin: 2px 0 11px 0;
  min-width: 0;
  white-space: nowrap;
  padding-left: 5px;
}
.gwSearchList__refresh-container:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwSearchList__refresh-container:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwSearchList__refresh-container:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwSearchList__refresh-label {
  flex-grow: 1;
  font-size: 12px;
  white-space: nowrap;
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.modal-body .gwSearchList .g-search-list-mask {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
}
.kgCell__drag-handle {
  cursor: move;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.g-move-record {
  background: #808d9f;
  padding-top: 11px;
  color: var(--theme-default-button-text);
  border-radius: 5px;
  width: 130px;
  height: 40px;
  text-align: center;
  cursor: move;
  pointer-events: all;
}
.koGrid--drag-over {
  border-color: var(--theme-default-button);
  position: relative;
}
.koGrid--drag-over .koGridContainer:after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--theme-default-button);
  opacity: 0.1;
}
.kgRow--drag-over:before {
  content: '';
  height: 100%;
  width: calc(100% - 1px);
  position: absolute;
  top: 0;
  background-color: var(--theme-default-button);
  opacity: 0.1;
}
.kgRow--drag-over:after {
  content: '';
  height: 100%;
  width: calc(100% - 1px);
  position: absolute;
  top: 0;
  border: 1px solid var(--theme-default-button);
}
.gwSearchListAddressesPopup.popover {
  width: 300px;
  margin: 0;
}
.gwSearchListAddressesPopup.popover .arrow {
  display: none;
}
.gwSearchListAddressesPopup.popover .popover-content {
  max-height: 400px;
  padding-bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.gwSearchListAddressesPopup.popover ul {
  padding: 0;
  margin: 0;
}
.gwSearchListAddressesPopup.popover ul li {
  padding-top: 9px;
  padding-bottom: 9px;
  list-style-type: none;
}
.gwSearchListAddressesPopup.popover ul li:first-child {
  padding-top: 0;
}
.gwSearchListAddressesPopup.popover ul li:not(:first-child) {
  border-top: 1px solid;
}
.gwSearchListAddressesPopup.popover .badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777777;
  border-radius: 10px;
}
.gwSearchListAddressesPopup.popover .badge:empty {
  display: none;
}
.gwSearchBox-results .g-location-search-item {
  padding: 3px 8px;
  cursor: pointer;
}
.gwSearchBox-results .g-location-search-item .main {
  color: var(--theme-general-text);
}
.gwSearchBox {
  height: 42px;
  border-radius: 4px;
}
.gwSearchBox label:not(.disabled) {
  padding-right: 30px;
}
.gwSearchBox.alert-danger input {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwSearchBox.alert-message-error input {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.gwSearchBox.alert-warning input {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.gwSearchBox.alert-info input {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.gwSearchBox.alert-danger,
.gwSearchBox.alert-message-error,
.gwSearchBox.alert-warning,
.gwSearchBox.alert-info {
  border: none;
  background: none;
}
.gwSearchBox .input-group-addon i:not(.icon-spin) {
  animation: none;
}
.gwSearchBox .input-group-addon i {
  color: var(--theme-icons);
}
.gwSearchBox.gwValidation-highlight.alert-danger input[type="text"] {
  background: #ffb0b0;
}
.gwSearchBox.gwValidation-highlight.alert-message-error input[type="text"] {
  color: #ffc675;
}
.gwSearchBox.gwValidation-highlight.alert-info input[type="text"] {
  background: #b5e6fd;
}
.gwSearchBox.gwValidation-highlight.alert-warning input[type="text"] {
  background: #fef293;
}
.gwSearchBox .input-group > .form-control[readonly] ~ .input-group-addon .icon-search {
  color: var(--content-text-color);
  cursor: default;
  opacity: 0.5;
}
.gwSearchBox .input-group > .form-control[readonly] ~ .input-group-addon .icon-search.icon-search:hover {
  opacity: 1;
}
.gwSearchBox-count {
  border-bottom: 1px solid #d3d6d9;
  padding: 0px 8px;
}
.gwSearchBox-count .gwSearchBox-count-text {
  font-size: 11px;
  color: #999;
  line-height: 22px;
}
.gwSearchBox-results {
  height: 100%;
  max-height: 400px;
  margin-bottom: 0;
  list-style: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 13px;
}
.gwSearchBox-show-more {
  text-align: center;
}
.gwSearchBox-show-more > a {
  display: block;
  cursor: pointer;
  padding-bottom: 4px;
}
.gwSearchBox-wrap {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.gwSearchBox-wrap.gwSearchBox-CodeDescMode .input-group .input-group-addon:last-child {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.gwSearchBox-wrap.gwSearchBox-CodeDescMode .gwSearchBox-text-wrap {
  width: 45%;
}
.gwSearchBox-wrap.gwSearchBox-CodeDescMode .gwSearchBox-desc-wrap {
  width: 55%;
}
.gwSearchBox-wrap > i {
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 14px;
  z-index: 5;
}
.gwSearchBox-desc-wrap,
.gwSearchBox-text-wrap {
  height: 100%;
  vertical-align: top;
}
.gwSearchBox-desc-wrap input[type="text"] {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  border-left: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.gwSearchBox-text-wrap .input-group {
  height: 100%;
  white-space: normal;
}
.gwSearchBox-text-wrap input[type="text"] {
  z-index: 0;
  padding-top: 14px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.gwSearchBox-description-text {
  display: inline-block;
  max-height: 15px;
  font-size: 15px;
  line-height: 15px;
  color: var(--input-control-read-only-text-color);
  white-space: nowrap;
  vertical-align: top;
}
.gwSearchBox-description-divider {
  display: inline-block;
  float: left;
  padding-top: 12px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  border-right: 1px solid #d3d6d9;
}
.gwSearchBox-description-wrap {
  padding-left: 8px;
  padding-right: 30px;
}
.gwSearchBox-description {
  cursor: text;
  position: relative;
  overflow: hidden;
  z-index: 3;
  float: left;
  padding-top: 22px;
  white-space: nowrap;
  max-width: 100%;
}
.gwSearchBox-description-spacer {
  float: left;
  font-size: 14px;
  min-width: 40px;
  min-height: 1px;
  white-space: nowrap;
  visibility: hidden;
}
.kgCell .gwSearchBox-description-spacer {
  display: list-item;
}
.typeahead.dropdown-menu {
  width: auto;
}
.g-is-using-min-width {
  border-top-right-radius: 5px;
}
.g-is-using-min-width.gwSearchBox-drop-above {
  border-bottom-right-radius: 5px;
}
input.g-read-restricted + div .gwSearchBox-description-divider {
  display: none;
}
.select2 {
  width: 100%;
  height: 100%;
}
.select2 .select2-selection {
  height: 100%;
  border-color: #d3d6d9;
  background-color: var(--input-control-focused-background-color-end);
}
.select2 .select2-selection .select2-selection__rendered {
  padding: 16px 26px 0 7px;
  line-height: 24px;
  color: var(--theme-value-text);
  -webkit-user-select: text;
  user-select: text;
}
.select2 .select2-selection .select2-selection__arrow {
  top: 0;
  right: 0;
  height: 100%;
  color: var(--theme-icons);
}
.select2 .select2-selection .select2-selection__arrow .select2-selection__icon {
  position: static;
  margin: 0 4px 0 0;
  border: none;
}
.select2 .select2-selection .select2-selection__arrow .icon-caret-down {
  width: 8px;
  height: 15px;
}
.select2 .select2-selection .select2-selection__arrow .icon-spinner {
  width: 14px;
  height: 14px;
}
.select2.select2-container--disabled .select2-selection {
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.select2.select2-container--disabled .select2-selection__rendered {
  color: var(--input-control-read-only-text-color);
}
.select2.select2-container--disabled .select2-selection__arrow {
  display: none;
}
.select2.alert-danger {
  background-color: transparent;
}
.select2.alert-danger .select2-selection {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.select2.alert-message-error {
  background-color: transparent;
}
.select2.alert-message-error .select2-selection {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.select2.alert-warning {
  background-color: transparent;
}
.select2.alert-warning .select2-selection {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.select2.alert-info {
  background-color: transparent;
}
.select2.alert-info .select2-selection {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.select2.gwValidation-highlight.alert-danger .select2-selection {
  background: #ffb0b0;
}
.select2.gwValidation-highlight.alert-message-error .select2-selection {
  color: #ffc675;
}
.select2.gwValidation-highlight.alert-info .select2-selection {
  background: #b5e6fd;
}
.select2.gwValidation-highlight.alert-warning .select2-selection {
  background: #ffb0b0;
}
.select2-container--open .select2-dropdown--below {
  border-top: 1px solid var(--input-control-focused-border-brush-end);
}
.select2-container--open .select2-dropdown--above {
  border-bottom: 1px solid var(--input-control-focused-border-brush-end);
}
.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.select2-container--open.select2-container--above .select2-selection {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.select2-selection__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select2-selection__icon:hover {
  opacity: 0.5;
}
.select2-dropdown {
  overflow-y: hidden;
  border: 1px solid var(--input-control-focused-border-brush-end);
  background: var(--input-control-focused-background-color-end);
}
.select2-dropdown--above {
  margin-top: 1px;
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}
.select2-dropdown--above.select2-dropdown--larger-than-container {
  border-bottom-right-radius: 4px;
}
.select2-dropdown--below {
  margin-top: -1px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.select2-dropdown--below.select2-dropdown--larger-than-container {
  border-top-right-radius: 4px;
}
.select2-search {
  height: 35px;
  border-bottom: 1px solid #d3d6d9;
}
.select2-search .input-group-addon {
  display: flex;
  height: 34px;
  align-items: center;
  justify-content: center;
  color: var(--theme-icons);
}
.select2-search__field {
  height: 100%;
  font-size: 13px;
  background-color: var(--input-control-focused-background-color-end);
  border-radius: 4px;
}
.select2-results {
  font-size: 13px;
  -webkit-overflow-scrolling: touch;
}
.select2-results__message {
  color: var(--theme-general-text);
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.select2-results__option {
  padding: 0;
}
.select2-results__option.select2-results__message {
  padding: 0 5px;
}
.gwSelectBox {
  height: 100%;
}
.gwSelectBox label:not(.disabled) {
  padding-right: 18px;
  pointer-events: none;
}
.gwSelectBox-code-only .g-code-desc-container .g-code {
  width: 100%;
  background-color: var(--input-control-focused-background-color-end);
}
.gwSelectBox-code-only .g-code-desc-container .g-desc {
  display: none;
}
.gwSelectBox-desc-only .g-code-desc-container .g-code {
  display: none;
}
.gwSelectBox-desc-only .g-code-desc-container .g-desc {
  width: 100%;
  background-color: var(--input-control-focused-background-color-end);
}
.gwSelectList__item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  cursor: pointer;
}
.gwSelectList__item:last-child {
  border-bottom: 0;
}
.gwSelectList__item:hover {
  background-color: var(--theme-hover);
}
.gwSelectList__item:hover .gwSelectList__arrow,
.gwSelectList__item:hover .gwSelectList__code,
.gwSelectList__item:hover .gwSelectList__desc {
  color: var(--theme-hover-text);
}
.gwSelectList__name {
  flex: 1;
}
.gwSelectList__code {
  font-size: 20px;
  color: var(--theme-field-title);
}
.gwSelectList__desc {
  font-size: 16px;
  color: var(--theme-value-text);
}
.gwSelectList__arrow {
  align-self: center;
  padding: 5px;
  color: var(--theme-icons);
}
.gwSelectMenu-list .select2-results > .select2-results__options {
  max-height: 400px;
}
.gwSelectMenu-list .select2-results__option {
  margin: 0 0 5px 0;
}
.gwSelectMenu-list .select2-results__option[aria-selected=true] {
  background: none;
}
.gwSelectMenu-list .select2-results__option--highlighted[aria-selected] {
  background: none;
}
.gwSelectMenu-list .select2-results__option--highlighted .gwSelectMenu-item {
  color: var(--theme-hover-text);
  background-color: var(--theme-hover);
}
.gwSelectMenu-list .select2-results__option--highlighted .gwSelectMenu-item label,
.gwSelectMenu-list .select2-results__option--highlighted .gwSelectMenu-item span,
.gwSelectMenu-list .select2-results__option--highlighted .gwSelectMenu-item a,
.gwSelectMenu-list .select2-results__option--highlighted .gwSelectMenu-item i.chevron {
  color: var(--theme-hover-text);
}
.gwSelectMenu-list .select2-results__message {
  margin-bottom: 0;
}
.gwSelectMenu-item {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  clear: both;
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  color: var(--theme-general-text);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  position: relative;
}
.gwSelectMenu-item.g-pin-target {
  padding-right: 0;
}
.gwSelectMenu-item i {
  margin: -2px 6px 0 0;
  color: var(--theme-field-title);
}
.gwSelectMenu-item a.g-desc {
  color: var(--content-text-color);
}
.gwSelectMenu-item.dropdown-header {
  font-size: 13px;
  color: var(--theme-default-button);
  padding: 2px 8px;
  height: auto;
  line-height: 28px;
  border-top: 1px solid #aaaaaa;
  cursor: default;
  margin-bottom: 0px;
}
.gwSelectMenu-item.dropdown-header a {
  color: var(--theme-default-button);
}
.gwSelectMenu-item.selected {
  background-color: var(--grid-viewport-selected-row-color);
  border-color: var(--grid-viewport-selected-row-color);
}
.gwSelectMenu-item.selected > i.icon-chevron-down {
  transform: rotate(180deg) translateY(-3px);
}
.g-code-desc-container {
  cursor: pointer;
  display: flex;
  background: var(--theme-background);
  padding: 0;
  font-weight: normal;
  line-height: 1.42857143;
}
.g-code-desc-container .g-code {
  color: var(--theme-general-text);
  padding: 2px 8px;
  background-color: var(--input-control-read-only-unfocused-background-color-start);
  border-right: 1px solid #d3d6d9;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 56px;
  white-space: nowrap;
}
.g-code-desc-container .g-desc {
  color: var(--theme-general-text);
  padding: 2px 8px;
  padding-right: 16px;
}
.g-code-only-container,
.g-desc-only-container {
  cursor: pointer;
  display: block;
  padding: 3px 8px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
}
.select2-results__option--highlighted.gwAddressSearchBox-results-item,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item,
.select2-results__option--highlighted.g-location-search-item,
.gwSearchBox-results > .active.g-location-search-item,
.select2-results__option--highlighted.gwSearchBox-show-more,
.gwSearchBox-results > .active.gwSearchBox-show-more,
.select2-results__option--highlighted .g-code-only-container,
.gwSearchBox-results > .active .g-code-only-container,
.select2-results__option--highlighted .g-desc-only-container,
.gwSearchBox-results > .active .g-desc-only-container,
.select2-results__option--highlighted .g-code-desc-container,
.gwSearchBox-results > .active .g-code-desc-container {
  color: var(--theme-hover-text);
  text-decoration: none;
  outline: 0;
  background: var(--theme-hover) !important;
}
.select2-results__option--highlighted.gwAddressSearchBox-results-item .g-code,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .g-code,
.select2-results__option--highlighted.g-location-search-item .g-code,
.gwSearchBox-results > .active.g-location-search-item .g-code,
.select2-results__option--highlighted.gwSearchBox-show-more .g-code,
.gwSearchBox-results > .active.gwSearchBox-show-more .g-code,
.select2-results__option--highlighted .g-code-only-container .g-code,
.gwSearchBox-results > .active .g-code-only-container .g-code,
.select2-results__option--highlighted .g-desc-only-container .g-code,
.gwSearchBox-results > .active .g-desc-only-container .g-code,
.select2-results__option--highlighted .g-code-desc-container .g-code,
.gwSearchBox-results > .active .g-code-desc-container .g-code,
.select2-results__option--highlighted.gwAddressSearchBox-results-item .g-desc,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .g-desc,
.select2-results__option--highlighted.g-location-search-item .g-desc,
.gwSearchBox-results > .active.g-location-search-item .g-desc,
.select2-results__option--highlighted.gwSearchBox-show-more .g-desc,
.gwSearchBox-results > .active.gwSearchBox-show-more .g-desc,
.select2-results__option--highlighted .g-code-only-container .g-desc,
.gwSearchBox-results > .active .g-code-only-container .g-desc,
.select2-results__option--highlighted .g-desc-only-container .g-desc,
.gwSearchBox-results > .active .g-desc-only-container .g-desc,
.select2-results__option--highlighted .g-code-desc-container .g-desc,
.gwSearchBox-results > .active .g-code-desc-container .g-desc,
.select2-results__option--highlighted.gwAddressSearchBox-results-item .g-orginfo,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .g-orginfo,
.select2-results__option--highlighted.g-location-search-item .g-orginfo,
.gwSearchBox-results > .active.g-location-search-item .g-orginfo,
.select2-results__option--highlighted.gwSearchBox-show-more .g-orginfo,
.gwSearchBox-results > .active.gwSearchBox-show-more .g-orginfo,
.select2-results__option--highlighted .g-code-only-container .g-orginfo,
.gwSearchBox-results > .active .g-code-only-container .g-orginfo,
.select2-results__option--highlighted .g-desc-only-container .g-orginfo,
.gwSearchBox-results > .active .g-desc-only-container .g-orginfo,
.select2-results__option--highlighted .g-code-desc-container .g-orginfo,
.gwSearchBox-results > .active .g-code-desc-container .g-orginfo,
.select2-results__option--highlighted.gwAddressSearchBox-results-item .g-address-code,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .g-address-code,
.select2-results__option--highlighted.g-location-search-item .g-address-code,
.gwSearchBox-results > .active.g-location-search-item .g-address-code,
.select2-results__option--highlighted.gwSearchBox-show-more .g-address-code,
.gwSearchBox-results > .active.gwSearchBox-show-more .g-address-code,
.select2-results__option--highlighted .g-code-only-container .g-address-code,
.gwSearchBox-results > .active .g-code-only-container .g-address-code,
.select2-results__option--highlighted .g-desc-only-container .g-address-code,
.gwSearchBox-results > .active .g-desc-only-container .g-address-code,
.select2-results__option--highlighted .g-code-desc-container .g-address-code,
.gwSearchBox-results > .active .g-code-desc-container .g-address-code,
.select2-results__option--highlighted.gwAddressSearchBox-results-item .g-address > div,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .g-address > div,
.select2-results__option--highlighted.g-location-search-item .g-address > div,
.gwSearchBox-results > .active.g-location-search-item .g-address > div,
.select2-results__option--highlighted.gwSearchBox-show-more .g-address > div,
.gwSearchBox-results > .active.gwSearchBox-show-more .g-address > div,
.select2-results__option--highlighted .g-code-only-container .g-address > div,
.gwSearchBox-results > .active .g-code-only-container .g-address > div,
.select2-results__option--highlighted .g-desc-only-container .g-address > div,
.gwSearchBox-results > .active .g-desc-only-container .g-address > div,
.select2-results__option--highlighted .g-code-desc-container .g-address > div,
.gwSearchBox-results > .active .g-code-desc-container .g-address > div,
.select2-results__option--highlighted.gwAddressSearchBox-results-item .main,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .main,
.select2-results__option--highlighted.g-location-search-item .main,
.gwSearchBox-results > .active.g-location-search-item .main,
.select2-results__option--highlighted.gwSearchBox-show-more .main,
.gwSearchBox-results > .active.gwSearchBox-show-more .main,
.select2-results__option--highlighted .g-code-only-container .main,
.gwSearchBox-results > .active .g-code-only-container .main,
.select2-results__option--highlighted .g-desc-only-container .main,
.gwSearchBox-results > .active .g-desc-only-container .main,
.select2-results__option--highlighted .g-code-desc-container .main,
.gwSearchBox-results > .active .g-code-desc-container .main,
.select2-results__option--highlighted.gwAddressSearchBox-results-item > a,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item > a,
.select2-results__option--highlighted.g-location-search-item > a,
.gwSearchBox-results > .active.g-location-search-item > a,
.select2-results__option--highlighted.gwSearchBox-show-more > a,
.gwSearchBox-results > .active.gwSearchBox-show-more > a,
.select2-results__option--highlighted .g-code-only-container > a,
.gwSearchBox-results > .active .g-code-only-container > a,
.select2-results__option--highlighted .g-desc-only-container > a,
.gwSearchBox-results > .active .g-desc-only-container > a,
.select2-results__option--highlighted .g-code-desc-container > a,
.gwSearchBox-results > .active .g-code-desc-container > a {
  color: var(--theme-hover-text) !important;
}
.select2-results__option--highlighted.gwAddressSearchBox-results-item .g-code,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .g-code,
.select2-results__option--highlighted.g-location-search-item .g-code,
.gwSearchBox-results > .active.g-location-search-item .g-code,
.select2-results__option--highlighted.gwSearchBox-show-more .g-code,
.gwSearchBox-results > .active.gwSearchBox-show-more .g-code,
.select2-results__option--highlighted .g-code-only-container .g-code,
.gwSearchBox-results > .active .g-code-only-container .g-code,
.select2-results__option--highlighted .g-desc-only-container .g-code,
.gwSearchBox-results > .active .g-desc-only-container .g-code,
.select2-results__option--highlighted .g-code-desc-container .g-code,
.gwSearchBox-results > .active .g-code-desc-container .g-code,
.select2-results__option--highlighted.gwAddressSearchBox-results-item .g-desc,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item .g-desc,
.select2-results__option--highlighted.g-location-search-item .g-desc,
.gwSearchBox-results > .active.g-location-search-item .g-desc,
.select2-results__option--highlighted.gwSearchBox-show-more .g-desc,
.gwSearchBox-results > .active.gwSearchBox-show-more .g-desc,
.select2-results__option--highlighted .g-code-only-container .g-desc,
.gwSearchBox-results > .active .g-code-only-container .g-desc,
.select2-results__option--highlighted .g-desc-only-container .g-desc,
.gwSearchBox-results > .active .g-desc-only-container .g-desc,
.select2-results__option--highlighted .g-code-desc-container .g-desc,
.gwSearchBox-results > .active .g-code-desc-container .g-desc {
  background: var(--theme-hover) !important;
}
.select2-results__option--highlighted.gwAddressSearchBox-results-item > a,
.gwSearchBox-results > .active.gwAddressSearchBox-results-item > a,
.select2-results__option--highlighted.g-location-search-item > a,
.gwSearchBox-results > .active.g-location-search-item > a,
.select2-results__option--highlighted.gwSearchBox-show-more > a,
.gwSearchBox-results > .active.gwSearchBox-show-more > a,
.select2-results__option--highlighted .g-code-only-container > a,
.gwSearchBox-results > .active .g-code-only-container > a,
.select2-results__option--highlighted .g-desc-only-container > a,
.gwSearchBox-results > .active .g-desc-only-container > a,
.select2-results__option--highlighted .g-code-desc-container > a,
.gwSearchBox-results > .active .g-code-desc-container > a {
  cursor: pointer;
}
.gwShellContainer.g-anchor-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.gwTextBox {
  display: flex;
  flex-direction: column;
  height: 42px;
}
.gwTextBox .character-casing-upper {
  text-transform: uppercase;
}
.gwTextBox .character-casing-lower {
  text-transform: lowercase;
}
.gwTextBox-textarea {
  height: 100%;
  padding-top: 18px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.gwTextBox-textarea input {
  display: none;
  order: 1;
}
.gwTextBox-textarea textarea {
  height: 100%;
  resize: none;
  border: none;
  background: transparent;
  order: 1;
}
.gwTextBox-textarea textarea:focus {
  box-shadow: none;
}
.gwTextBox-textarea textarea .character-casing-upper {
  text-transform: uppercase;
}
.gwTextBox-textarea textarea .character-casing-lower {
  text-transform: lowercase;
}
.gwTextBox-textarea label {
  margin-top: -18px;
}
.gwTextBox-popover {
  border: 1px solid var(--input-control-focused-border-brush-end);
  border-radius: 4px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.gwTextBox-popover .arrow,
.gwTextBox-popover .popover-title {
  display: none;
}
.gwTextBox-popover .popover-content {
  padding: 5px 0px 5px 5px;
  height: 200px;
}
.gwTextBox-popover .popover-content textarea {
  height: 100%;
  width: 100%;
  resize: none;
  border: none;
  color: var(--input-control-text-color);
  font-size: 15px;
}
.gwTextBox-popover.g-uneditable .popover-content {
  color: var(--input-control-read-only-text-color);
  background-color: var(--input-control-read-only-unfocused-background-color-start);
  cursor: text;
}
.gwTextOverflowPopover {
  max-width: 100%;
  background: #f3fafe;
  border: 1px solid #333;
  padding: 8px;
}
.gwTextOverflowPopover .arrow {
  display: none;
}
.gwThemeColorSelectMenu__option {
  display: flex;
  padding: 3px 5px;
}
.gwThemeColorSelectMenu__option-color-box {
  width: 30px;
  border: 1px solid black;
}
.gwThemeColorSelectMenu__option-description {
  flex: 1;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwTile {
  text-align: center;
  border-radius: 5px;
  margin: 4px;
  padding: 0;
  overflow: hidden;
  border-style: solid;
  border-width: 2px;
  box-shadow: #aaa 2px 2px 4px 1px;
}
.gwTile:hover {
  opacity: 0.6;
}
.gwTile > a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px;
}
.gwTile a:focus {
  outline: 1px dashed var(--tiles-foreground-brush);
  outline-offset: -3px;
}
.gwTile .gwTile-wrap {
  position: relative;
}
.gwTile .gwTile-img {
  height: 100%;
  max-width: 100%;
  color: var(--tiles-foreground-brush);
}
.gwTile .gwTile-img img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}
.gwTile .gwTile-img:focus {
  outline: none;
}
.gwTile .gwTile-text {
  color: var(--tiles-foreground-brush);
}
.gwTile .gwTile-text .gwTile-text-contents:focus {
  outline: none;
}
.gwTile .gwTile-text .gwTile-text-contents span {
  font-weight: Normal;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
}
.gwTile .g-menu-item-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--tiles-foreground-brush);
}
.gwTile .g-menu-item-target:hover .g-menu-item-icon {
  opacity: 1;
}
.gwTile[data-displaymode="TextOnly"] .gwTile-text {
  margin: 0;
}
.gwTile[data-displaymode="TextOnly"] .gwTile-text .gwTile-text-contents span {
  line-height: normal;
  white-space: normal;
}
.gwTile[data-displaymode="IconOnly"] .gwTile-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gwTile[data-displaymode="IconOnly"] .gwTile-text {
  display: none;
}
.gwTile[data-size="Standard"] {
  width: 92px;
  height: 76px;
}
.gwTile[data-size="Standard"][data-displaymode="TextAndIcon"] .gwTile-img {
  height: 36px;
  line-height: 36px;
}
.gwTile[data-size="Standard"][data-displaymode="TextAndIcon"] .gwTile-text {
  height: 26px;
  margin-top: 5px;
}
.gwTile[data-size="Standard"][data-displaymode="TextAndIcon"] .gwTile-text-contents span {
  max-height: 26px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwTile[data-size="Standard"][data-displaymode="TextOnly"] .gwTile-text-contents {
  line-height: 62px;
}
.gwTile[data-size="Wide"] {
  width: 192px;
  height: 76px;
  margin: 4px;
  padding: 0;
}
.gwTile[data-size="Wide"] > a {
  padding: 5px 10px;
}
.gwTile[data-size="Wide"] .gwTile-img {
  float: left;
  margin-top: 5px;
}
.gwTile[data-size="Wide"] .gwTile-text {
  color: var(--tiles-foreground-brush);
  float: right;
}
.gwTile[data-size="Wide"] .gwTile-text-contents {
  line-height: 62px;
}
.gwTile[data-size="Wide"] .gwTile-text-contents span {
  line-height: normal;
  font-size: 15px;
}
.gwTile[data-size="Wide"][data-displaymode="TextAndIcon"] .gwTile-img {
  width: 54px;
}
.gwTile[data-size="Wide"][data-displaymode="TextAndIcon"] .gwTile-text {
  width: 108px;
  margin-left: 6px;
}
.gwTile[data-size="Wide"][data-displaymode="TextOnly"] .gwTile-img {
  display: none;
}
.gwTile[data-size="Wide"][data-displaymode="TextOnly"] .gwTile-text {
  margin: 0;
  width: 100%;
}
.gwTile[data-size="Wide"][data-displaymode="TextOnly"] .gwTile-text .gwTile-text-contents span {
  display: inline-block;
  line-height: normal;
  white-space: normal;
}
.gwTile[data-size="Wide"][data-displaymode="IconOnly"] .gwTile-img {
  width: 100%;
  margin-top: 0;
}
.gwTile[data-size="Wide"][data-displaymode="IconOnly"] .gwTile-img img {
  padding: 2px 0;
}
.gwTile[data-size="Wide"][data-displaymode="IconOnly"] .gwTile-text {
  display: none;
}
.gwTile[data-color="Default"] {
  background: var(--tiles-background-brush);
  border-color: var(--tiles-background-brush);
}
.gwTile[data-color="Default"]:hover {
  border-color: var(--tiles-background-brush-darken15);
}
.gwTile[data-color="Primary"] {
  background: var(--tiles-large-background-brush);
  border-color: var(--tiles-large-background-brush);
}
.gwTile[data-color="Primary"]:hover {
  border-color: var(--tiles-large-background-brush-darken15);
}
.gwTile[data-color="Secondary"] {
  background: var(--tiles-rectangle-background-brush);
  border-color: var(--tiles-rectangle-background-brush);
}
.gwTile[data-color="Secondary"]:hover {
  border-color: var(--tiles-rectangle-background-brush-darken15);
}
.gwTile[data-size="Large"] {
  width: 192px;
  height: 160px;
  margin: 4px;
  padding: 0;
}
.gwTile[data-size="Large"] > a {
  padding: 10px;
}
.gwTile[data-size="Large"] .gwTile-text {
  color: var(--tiles-foreground-brush);
}
.gwTile[data-size="Large"] .gwTile-text .gwTile-text-contents {
  width: 100%;
  overflow: hidden;
}
.gwTile[data-size="Large"] .gwTile-text .gwTile-text-contents span {
  line-height: normal;
  font-size: 15px;
}
.gwTile[data-size="Large"][data-displaymode="TextAndIcon"] .gwTile-img {
  height: 80px;
  line-height: 80px;
}
.gwTile[data-size="Large"][data-displaymode="TextAndIcon"] .gwTile-text {
  margin-top: 5px;
}
.gwTile[data-size="Large"][data-displaymode="TextAndIcon"] .gwTile-text-contents {
  line-height: 56px;
}
.gwTile[data-size="Large"][data-displaymode="TextAndIcon"] .gwTile-text-contents span {
  max-height: 46px;
  overflow: hidden;
}
.gwTile[data-size="Large"][data-displaymode="TextOnly"] .gwTile-text .gwTile-text-contents {
  height: 100%;
  line-height: 136px;
}
.gwTile[data-size="Large"][data-displaymode="TextOnly"] .gwTile-text .gwTile-text-contents span {
  max-height: 130px;
}
.gwTile[data-size="Large"][data-color="Default"] {
  background: var(--tiles-large-background-brush);
  border-color: var(--tiles-large-background-brush);
}
.gwTile[data-size="Large"][data-color="Default"]:hover {
  border-color: var(--tiles-large-background-brush-darken15);
}
.g-configurable-container .gwTile:hover,
.g-configurable-widget .gwTile:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.g-configurable-container > .gwTile a {
  cursor: default;
}
.g-configurable-widget > .gwTile a {
  cursor: inherit;
}
.gwTrackingControl {
  display: flex;
  width: 100%;
  height: 100%;
}
.gwTrackingControl .gwTrackingControl-text {
  flex-grow: 1;
}
.gwTrackingControl .gwTrackingControl-button {
  margin: auto 5px auto 5px;
  height: 30px;
  width: 80px;
}
.gwTrackingControl-multiLine {
  width: 100%;
  height: 100%;
}
.gwTrackingControl-multiLine .gwTrackingControl-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  bottom: 40px;
}
.gwTrackingControl-multiLine .gwTrackingControl-button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;
}
.gwTrackingFlagDisplay {
  display: inline-flex;
  align-items: center;
  height: 46px;
  padding: 3px 7px;
  font-size: 24px;
}
.gwTrackingFlagDisplay > i {
  font-size: 24px;
}
.gwTrackingFlagDisplay > i.icon-chevron-right {
  margin: 0 5px;
  font-size: 22px;
  color: #C4C7C8;
}
.gwTrackingFlagDisplay > i.icon-truck {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.gwTrackingFlagDisplay > i:hover,
.gwTrackingFlagDisplay > i.fa:hover {
  cursor: default;
  opacity: 1;
}
.gwTrackingFlagDisplay > .g-iata {
  position: relative;
}
.gwTrackingFlagDisplay > .g-iata > .gwFlag {
  opacity: 0.4;
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
.gwTrackingFlagDisplay > .g-iata > .g-iata-text {
  position: absolute;
  top: 0;
  left: 0;
  margin: 4px 1px;
  color: var(--theme-value-text);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 18px;
  font-weight: bold;
  text-shadow: -1px 1px 2px #ffffff, 1px 1px 2px #ffffff, 1px -1px 2px #ffffff, -1px -1px 2px #ffffff;
}
.gwTrackingPort {
  display: flex;
  flex-direction: column;
}
.gwTrackingPort__info {
  display: flex;
}
.gwTrackingPort__name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gwTrackingPort__unloco {
  padding-left: 5px;
  font-weight: bold;
  font-size: 20px;
}
.gwTrackingPort__country {
  opacity: 0.4;
}
.gwTrackingProgress .gwProgressLinear {
  margin: 10px 0;
}
.gwTrackingProgress .gwFlag {
  width: 32px;
  height: 25px;
}
.gwTrackingProgress .flag-icon {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.gwTrackingProgress__row {
  display: flex;
  justify-content: space-between;
  margin: 3px 0;
}
.gwTrackingProgress__arrow {
  width: 15px;
  height: 15px;
  align-self: center;
}
.gwTrackingProgress-compact__arrow {
  width: 15px;
  height: 15px;
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
}
.gwTrackingProgress__port {
  width: 180px;
  height: 50px;
}
.gwTrackingProgress__mode {
  width: 50px;
  text-align: center;
}
.gwTrackingProgress__mode {
  font-size: 50px;
}
.gwTrackingProgress__mode.icon-truck {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.gwTrackingProgress__scheduling {
  display: flex;
  height: 50px;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.gwTrackingProgress__location {
  display: flex;
  align-items: center;
}
.gwTrackingProgress__location .gwTrackingMapButton {
  font-size: 24px;
}
.gwTrackingProgress__caption {
  width: 100px;
  font-family: 'Noto Sans Condensed';
}
.gwTrackingProgress__date {
  width: 100px;
  color: var(--theme-default-button);
}
.gwTrackingProgress-compact__date {
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: var(--theme-default-button);
}
.gwTransitionButton {
  height: 100%;
  width: 100%;
}
.gwTriStateRadioButton {
  height: 100%;
  min-width: 200px;
  position: inherit;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.gwTriStateRadioButton:focus {
  box-shadow: none;
}
.gwTriStateRadioButton .btn-group .btn-tri-state {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: #FFFFFF;
  color: var(--button-text-color);
  border-radius: 0px;
  width: 50px;
  height: 26px;
  padding: 0 0 2px;
  border-radius: 5px;
  border: 1px solid #D3D6D9;
  text-transform: none;
}
.gwTriStateRadioButton .btn-group .btn-tri-state a,
.gwTriStateRadioButton .btn-group .btn-tri-state label {
  color: var(--button-text-color);
}
.gwTriStateRadioButton .btn-group .btn-tri-state i {
  color: var(--button-text-color);
}
.gwTriStateRadioButton .btn-group .btn-tri-state i.chevron {
  color: var(--button-text-color-lighten10);
}
.open > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]),
.active > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]),
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).pressed,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--toggle-button-turned-on-background-color-end);
  color: var(--toggle-button-turned-on-text-color);
}
.open > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]) i,
.active > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]) i,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).pressed i,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).popover-shown i {
  color: var(--toggle-button-turned-on-text-color);
}
.open > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):focus,
.active > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):focus,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).pressed:focus,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--toggle-button-turned-on-background-color-end);
  color: var(--toggle-button-turned-on-text-color);
}
.open > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):focus i,
.active > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):focus i,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).pressed:focus i,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--toggle-button-turned-on-text-color);
}
@media (hover) {
  .open > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover,
  .active > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).pressed:hover,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--theme-hover);
    color: var(--button-text-hover-color);
  }
}
.open > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):active,
.active > .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):active,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).pressed:active,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--primary-button-background-pressed-color-start);
  color: var(--button-text-hover-color);
}
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):focus {
  background-color: #FFFFFF;
  color: var(--button-text-color);
  border: 1px solid #333;
}
@media (hover) {
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover {
    color: var(--button-text-hover-color);
    background-color: var(--theme-hover);
  }
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover a,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover label,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover span {
    color: var(--button-text-hover-color) !important;
  }
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):active {
  color: var(--button-text-hover-color);
  background-color: var(--primary-button-background-pressed-color-start);
}
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):active a,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):active i,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):active label,
.gwTriStateRadioButton .btn-group .btn-tri-state:not(.disabled):not([disabled]):active span {
  color: var(--button-text-hover-color) !important;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active {
  color: #999;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active a,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active a,
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active label,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active label,
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active span,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active span {
  color: #999;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i {
  opacity: 1;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i.g-menu-item-always-enabled-icon,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i.g-menu-item-always-enabled-icon:focus,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i.g-menu-item-always-enabled-icon:hover,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i.g-menu-item-always-enabled-icon:active,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwTriStateRadioButton .btn-group .btn-tri-state.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.gwTriStateRadioButton .btn-group .btn-tri-state[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.gwTriStateRadioButton .btn-group .btn-tri-state:focus + .btn-tri-state {
  z-index: 1;
}
.gwTriStateRadioButton .btn-group .btn-tri-state:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.gwTriStateRadioButton .btn-group .btn-tri-state:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.gwTriStateRadioButton .btn-group .btn-tri-state:not(:last-child):not(:first-child) {
  border-radius: 0;
}
.gwTriStateRadioButton.captionLeft {
  justify-content: flex-end;
}
.gwTriStateRadioButton.captionLeft .btn-group {
  width: 117px;
  height: 100%;
  border-left: solid 1px var(--input-control-border-brush-start);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}
.gwTriStateRadioButton.captionLeft .active:not(.g-uneditable) .btn-group {
  border-left-color: var(--input-control-focused-border-brush-end);
}
.gwTriStateRadioButton.captionLeft .gwTriStateRadioButton-label {
  flex: 1 1 auto;
  margin: 0 7px 0 7px;
  text-align: right;
}
.gwTriStateRadioButton.captionLeft.alert-danger .btn-group {
  border-color: #d90000;
}
.gwTriStateRadioButton.captionLeft.alert-warning .btn-group {
  border-color: #ffc825;
}
.gwTriStateRadioButton.captionLeft.alert-message-error .btn-group {
  border-color: #ffaa33;
}
.gwTriStateRadioButton.captionLeft.alert-info .btn-group {
  border-color: #3a87ad;
}
.gwTriStateRadioButton.captionLeft.alert-danger label.gwTriStateRadioButton-label,
.gwTriStateRadioButton.captionLeft.alert-warning label.gwTriStateRadioButton-label,
.gwTriStateRadioButton.captionLeft.alert-info label.gwTriStateRadioButton-label,
.gwTriStateRadioButton.captionLeft.alert-message-error label.gwTriStateRadioButton-label {
  color: var(--input-control-label-color);
}
.gwTriStateRadioButton.captionLeft:not(.g-uneditable) {
  background-color: var(--input-control-background-color-end);
}
.gwTriStateRadioButton.captionLeft.active:not(.g-uneditable) .btn-group {
  border-left-color: var(--input-control-focused-border-brush-end);
}
.gwTriStateRadioButton.captionLeft.alert-danger {
  color: #333;
  background-color: #ffe8e7;
  border-color: #d90000;
}
.gwTriStateRadioButton.captionLeft.alert-danger.gwValidation-highlight {
  background: #ffb0b0;
}
.gwTriStateRadioButton.captionLeft.alert-message-error {
  color: #333;
  background-color: #ffd599;
  border-color: #ffaa33;
}
.gwTriStateRadioButton.captionLeft.alert-message-error.gwValidation-highlight {
  color: #ffc675;
}
.gwTriStateRadioButton.captionLeft.alert-warning {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.gwTriStateRadioButton.captionLeft.alert-warning.gwValidation-highlight {
  background: #fef293;
}
.gwTriStateRadioButton.captionLeft.alert-info {
  color: #333;
  background-color: #e0f4fe;
  border-color: #3a87ad;
}
.gwTriStateRadioButton.captionLeft.alert-info.gwValidation-highlight {
  background: #b5e6fd;
}
.gwTriStateRadioButton.g-uneditable {
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.kgCellInner .gwTriStateRadioButton {
  justify-content: center;
  min-width: unset;
  padding: 0 5px;
}
.kgCellInner .gwTriStateRadioButton .btn-group {
  display: flex;
  overflow: hidden;
}
.kgCellInner .gwTriStateRadioButton .btn-group .btn-tri-state {
  width: 40px;
  height: 20px;
  font-size: 12px;
}
.gwValidationSummary {
  margin: 0;
}
.gwValidationSummary input[type="checkbox"] {
  position: absolute;
  top: 5px;
  right: 5px;
}
.gwValidationSummary ul {
  margin: 2px 2px 4px;
}
.gwValidationSummary ul li.alert {
  border-radius: 0;
  margin-bottom: 0;
}
.gwValidationSummary ul:last-child a:hover,
.gwValidationSummary ul li:last-child {
  border-radius: 0 0 3px 3px;
}
.gwValidationSummary ul:last-child {
  margin-bottom: 2px;
}
.gwValidationSummary ul:last-child a {
  border-bottom: none;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-header {
  display: block;
  min-height: 25px;
  padding: 0 8px;
  border: none;
  border-radius: 3px 3px 0 0;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-header > span {
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message {
  position: relative;
  padding: 8px;
  border-color: transparent;
  color: #333;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-danger {
  background: #ffe8e7;
  border-bottom-color: #d90000;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-danger:hover {
  background: #ffb0b0;
  border-color: #d90000;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-danger:hover a {
  background: #ffb0b0 !important;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-danger:hover span {
  color: #333 !important;
  background-color: #ffe8e7 !important;
  border-color: #d90000 !important;
  background: #ffb0b0 !important;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-message-error {
  background: #ffd599;
  border-bottom-color: #ffaa33;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-message-error:hover {
  background: #ffc675;
  border-color: #ffaa33;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-warning {
  background: #fff7bd;
  border-bottom-color: #ffc825;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-warning:hover {
  background: #fef293;
  border-color: #ffc825;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-warning .gwValidationSummary-alert-caption {
  margin-right: 16px;
  padding-right: 5px;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-info {
  background: #e0f4fe;
  border-bottom-color: #3a87ad;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message.alert-info:hover {
  background: #b5e6fd;
  border-color: #3a87ad;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message a {
  display: block;
  font-size: 12px;
  height: 100%;
  color: #333;
  padding: 0;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message a:focus {
  text-decoration: none;
}
.gwValidationSummary ul li.gwValidationSummary-alert-entity-message:last-child {
  border-bottom-color: transparent;
}
.gwValidationSummary ul .gwValidationSummary-alert-caption {
  font-weight: bold;
  margin-bottom: 4px;
}
.gwValidationSummary ul .gwValidationSummary-alert-caption,
.gwValidationSummary ul .gwValidationSummary-alert-description {
  display: block;
  line-height: 20px;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.gwValidationSummary-alert-entity-message .g-tickbox label:after {
  width: 15px;
  height: 5px;
  top: 0;
  left: 1px;
}
.gwValidationSummary-alert-entity-message.alert-warning:hover .g-tickbox input[type="checkbox"]:checked + label:after {
  border-color: #333;
}
.gwValidationSummary-alert-entity-message.alert-warning .g-tickbox {
  position: absolute;
  top: 10px;
  right: 8px;
  width: 16px;
  height: 16px;
  padding: 0;
  margin-left: 0;
  z-index: 999;
}
.gwValidationSummary-alert-entity-message.alert-warning .g-tickbox input[type="checkbox"] {
  top: 0;
  right: 0;
}
.gwValidationSummary-alert-entity-message.alert-warning .g-tickbox input[type="checkbox"]:checked + label:hover:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.gwValidationSummary-alert-entity-message.alert-warning .g-tickbox input[type="checkbox"]:checked + label span {
  background: #fff7c3;
  left: 10px;
}
.gwValidationSummary-alert-entity-message.alert-warning .g-tickbox input[type="checkbox"]:focus + label {
  box-shadow: none;
}
.gwValidationSummary-alert-entity-message.alert-warning .g-tickbox label {
  background: transparent;
  border-color: #d3d6d9;
  width: 16px;
  height: 16px;
  top: 0;
  margin: 0;
}
.gwValidationSummary-alert-entity-message.alert-warning .g-tickbox span {
  top: -1px;
  left: 5px;
  width: 5px;
  height: 6px;
}
.alert-danger,
.alert-message-error,
.alert-warning,
.alert-info {
  color: #fff;
}
.alert-danger {
  background-color: #d90000;
}
.alert-message-error {
  background-color: #ffaa33;
}
.alert-warning {
  background-color: #ffc825;
}
.alert-info {
  background-color: #3a87ad;
}
.gwAnchor {
  width: 100%;
  height: 104.8px;
  padding: 0;
}
.gwAnchor-selection {
  position: relative;
  display: block;
  width: 100.8px;
  height: 100.8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
}
.gwAnchor-center {
  position: absolute;
  top: 28px;
  left: 28px;
  width: 43.4px;
  height: 43.4px;
  border: 2px solid #000;
}
.gwAnchor-edge {
  position: absolute;
  border: 1px solid #707070;
  background-color: #ddd;
}
.gwAnchor-edge:hover {
  cursor: pointer;
  background-color: #b5e6fd;
  border-color: #3191C4;
}
.gwAnchor-edge-left {
  top: 42.7px;
  left: 0;
  width: 28px;
  height: 14px;
}
.gwAnchor-edge-top {
  top: 0;
  left: 42.7px;
  width: 14px;
  height: 28px;
}
.gwAnchor-edge-right {
  top: 42.7px;
  left: 71.4px;
  width: 28px;
  height: 14px;
}
.gwAnchor-edge-bottom {
  top: 71.4px;
  left: 42.7px;
  width: 14px;
  height: 28px;
}
.gwAnchor-edge-selected {
  background-color: #BCDDEE;
  border-color: #245A83;
}
.gwAnchor-edge-selected:hover {
  background-color: #BCDDEE;
  border-color: #245A83;
}
.gwTransitionTextBox,
.gwFinder {
  height: 100%;
}
.gwTransitionTextBox .search-container,
.gwFinder .search-container {
  height: 42px;
  max-width: 500px;
  margin: 0 auto;
}
.gwTransitionTextBox .search-container label,
.gwFinder .search-container label {
  max-width: 500px;
  padding-right: 30px;
}
.gwTransitionTextBox .search-container .input-group-addon,
.gwFinder .search-container .input-group-addon {
  color: var(--theme-icons);
}
.gwTransitionTextBox .template-container,
.gwFinder .template-container {
  height: calc(100% - 42px);
}
.gwTransitionTextBox .template-container .g-absolute,
.gwFinder .template-container .g-absolute {
  margin: 0 auto;
  float: unset;
  width: 0%;
}
.gwTransitionTextBox .template-container .g-absolute .g-anchored,
.gwFinder .template-container .g-absolute .g-anchored {
  position: absolute;
}
.gwLabel {
  height: 100%;
  flex: 1 1;
}
.gwLabel > p {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
}
.gwNote-wrapper :last-child {
  margin-bottom: 0;
}
/**,
*:before,
*:after {
	box-sizing: border-box;
}*/
/*body {
	font-family: Roboto, sans-serif;
	color: #f9fafc;
	font-size: 20px;
}*/
/*Do not change styles as it is an ongoing change. This will be handled int he cleanup styles in WI00202923*/
.gwTruckDashboard {
  background-color: var(--theme-background);
  color: var(--content-text-color);
  height: inherit;
}
.gwTruckDashboard h1 {
  font-weight: normal;
  font-size: 35px;
  color: var(--content-text-color);
}
.gwTruckDashboard h2 {
  font-weight: normal;
  font-size: 25px;
  margin: 0px;
  color: var(--content-text-color);
}
.gwTruckDashboard input[type=text] {
  background-color: rgba(255, 255, 255, 0.22);
  color: var(--content-text-color);
  padding: 10px;
  border: 1px solid var(--content-text-color);
  font-size: 25px;
  padding: 18px 28px;
  border-radius: 0px;
  background-image: none;
}
.gwTruckDashboard input[type=text]::placeholder {
  color: rgba(249, 250, 252, 0.8);
}
.gwTruckDashboard button:not(.g-grid-action-button),
.gwTruckDashboard input[type=button]:not(.g-grid-action-button),
.gwTruckDashboard input[type=submit]:not(.g-grid-action-button) {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid var(--content-text-color);
  text-transform: capitalize;
  letter-spacing: 0.2em;
  padding: 12px 30px;
  color: var(--content-text-color);
  font-weight: bold;
  font-size: 20px;
  border-radius: 6px;
  min-width: 206px;
}
.gwTruckDashboard button + button,
.gwTruckDashboard input[type=button] + input[type=button],
.gwTruckDashboard input[type=submit] + input[type=submit],
.gwTruckDashboard .btn-group + button,
.gwTruckDashboard button + .btn-group {
  margin-left: 8px;
}
.gwTruckDashboard button.action,
.gwTruckDashboard input[type=button].action,
.gwTruckDashboard input[type=submit].action {
  background-color: #00A8E1;
  border: 1px solid #00A8E1;
}
.gwTruckDashboard input[type=checkbox] {
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.gwTruckDashboard input[type=checkbox] + label {
  padding: 3px 0 0 40px;
  position: relative;
  cursor: pointer;
}
.gwTruckDashboard input[type=checkbox] + label::before {
  content: '';
  width: 26px;
  height: 26px;
  border: 1px solid var(--content-text-color);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 1px;
}
.gwTruckDashboard input[type=checkbox]:checked + label::after {
  content: '';
  position: absolute;
  width: 16.5px;
  height: 12.2px;
  background-image: url('../../Content/Themes/Shared/Images/check.YT_sDhWWSmx9IxaCweL_eDFU10w.svg');
  background-size: contain;
  left: 4px;
  top: 7px;
}
.gwTruckDashboard .g-kg-status-search {
  font-size: 20px;
  color: var(--content-text-color);
}
.gwTruckDashboard .gwPinnedItems .gwPinnedItem-sticky-button {
  background-color: transparent;
}
.gwTruckDashboard .g-kg-item-name,
.gwTruckDashboard .g-kg-actions-container .g-kg-action a,
.gwTruckDashboard .g-kg-title-container .g-kg-item-count {
  color: var(--content-text-color);
}
.gwTruckDashboard__error {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin: 4px 0 0 7px;
  justify-content: flex-end;
  flex: 1 0 auto;
  font-size: 20px;
}
.gwTruckDashboard__error .gwTruckDashboard__error-icon {
  margin-right: 5px;
  color: #ffb0b0;
}
.gwTruckDashboard__error .gwTruckDashboard__error-message {
  color: #ffb0b0;
}
#top-bar {
  height: 79px;
  padding: 15px 25px;
}
#top-bar .left {
  width: 30%;
  float: left;
}
#top-bar .right {
  width: 70%;
  float: right;
  text-align: right;
}
#top-bar .right > * {
  vertical-align: top;
}
.gwMainContent {
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 24px;
  height: calc(100% - 179px);
  border-radius: 15px 15px 0 0;
  padding: 15px 30px;
}
.gwMainContent > *:first-child {
  margin-top: 0;
}
.gwMainContent .page {
  display: none;
  max-height: 100%;
}
.gwMainContent #page-runsheet {
  height: 100%;
}
.gwMainContent #page-runsheet input[type=text] {
  color: #003479;
  font-size: inherit;
  border-color: #d3d6d9;
  padding: 10px;
  background-color: var(--content-text-color);
}
.gwMainContent #page-runsheet button.btn-primary {
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  background-color: #00a8e1;
  border-color: transparent;
  color: white;
  text-shadow: none;
  letter-spacing: normal;
  text-transform: capitalize;
  min-width: initial;
}
.gwMainContent #page-runsheet .g-btn-async {
  font-size: 14px;
  text-shadow: none;
  letter-spacing: normal;
  text-transform: capitalize;
  min-width: initial;
}
.gwMainContent #page-runsheet .search-results {
  height: 100%;
  position: relative;
}
.gwMainContent #page-runsheet .search-results .gwSearchList {
  width: 100%;
}
.gwMainContent .truck-status-error {
  text-align: center;
}
.gwMainContent .tap-instruction {
  display: inline-block;
  padding-top: 20px;
  color: var(--content-text-color);
}
.gwMainContent .tap-instruction h4 {
  display: inline-block;
  font-weight: normal;
  margin-left: 10px;
}
.gwMainContent #page-edocs {
  height: 100%;
}
.gwMainContent #page-conversation {
  height: 100%;
}
.gwMainContent #page-conversation .gwConversationList__conversation-list {
  background-color: transparent;
}
.gwMainContent #page-conversation .gwConversationList__conversation-list--border {
  border: 0;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button {
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(27, 36, 49, 0.9);
  border-radius: 50%;
  right: 90px;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button a,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button label {
  color: rgba(27, 36, 49, 0.9);
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button i {
  color: rgba(27, 36, 49, 0.9);
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button i.chevron {
  color: rgba(45, 60, 82, 0.9);
}
.open > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]),
.active > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]),
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]) i,
.active > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]) i,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed i,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus,
.active > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:focus,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus i,
.active > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus i,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:focus i,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover,
  .active > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:hover,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--button-background-hover-color-start);
    color: var(--button-text-hover-color);
  }
}
.open > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active,
.active > .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:active,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--button-background-pressed-color-start);
  color: var(--button-text-hover-color);
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus {
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(27, 36, 49, 0.9);
  border: 1px dashed #333;
  border-radius: 50%;
}
@media (hover) {
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover {
    color: var(--button-text-hover-color);
    background-color: var(--button-background-hover-color-start);
    border-radius: 50%;
  }
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover a,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover label,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover span {
    color: var(--button-text-hover-color) !important;
  }
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active {
  color: var(--button-text-hover-color);
  background-color: var(--button-background-pressed-color-start);
  border-radius: 50%;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active a,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active i,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active label,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active span {
  color: var(--button-text-hover-color) !important;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active {
  color: #999;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active a,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active a,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active label,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active label,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active span,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active span {
  color: #999;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i {
  opacity: 1;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon:focus,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon:hover,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon:active,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.gwMainContent #page-conversation .gwConversationList__conversation-more-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.gwMainContent #page-conversation .gwConversationList__conversation-more-button.unreadMessages {
  background-color: var(--button-background-hover-color-start);
}
.gwMainContent .message-notification-dot {
  height: 20px;
  width: 20px;
  background-color: #d90000;
  border-radius: 50%;
  display: block;
  position: relative;
  top: -1em;
  left: calc(50% + 15px);
}
.gwMainContent .page.current {
  display: flex;
}
.gwMainContent .page.current .g-container {
  display: flex;
  max-height: 100%;
  width: 100%;
}
#bottom-bar {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.86);
  display: flex;
  justify-content: space-between;
}
#bottom-bar a {
  width: 166px;
  height: 100%;
  font-size: 50px;
  padding-top: 20px;
  text-align: center;
  color: var(--content-text-color);
  text-decoration: none;
}
#bottom-bar a.active {
  color: var(--theme-default-button);
}
#container.page-login #bottom-bar {
  display: none;
}
.scroll-list {
  overflow: scroll;
  max-width: 50%;
  max-height: 462px;
}
.scroll-list ul {
  font-size: 25px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  background-color: rgba(249, 250, 252, 0.25);
}
.scroll-list li {
  padding: 19px 25px;
  margin: 0;
}
.scroll-list li.selected {
  background-color: rgba(0, 0, 0, 0.5);
}
.scroll-list .jspVerticalBar {
  width: 23px;
  background-color: rgba(249, 250, 252, 0.25);
  border: 1px solid rgba(249, 250, 252, 0.5);
}
.scroll-list .jspTrack {
  background-color: transparent;
}
.scroll-list .jspDrag {
  background-color: #456A8F;
}
#page-map,
#page-map iframe {
  height: 100%;
}
.gwTruckDisplayer {
  color: #f9fafc;
  width: 100%;
  /*
 * TRUCK DIAGRAMS
 * https://www.truck.net.au/sites/default/files/TAPs%20-%20description%20of%20truck%20configuration%20September%202016.pdf
 *
 * Vehicle classes:
 * vehicle-r  - Rigid truck
 * vehicle-tp - Pig trailer
 * vehicle-td - Dog trailer
 * vehicle-m  - Prime mover
 * vehicle-s  - Semi trailer 
 * vehicle-l  - Lead trailer
 * vehicle-d  - Dolly
 * vehicle-a-x - Any vehicle with a single axle group with x axles
 * vehicle-a-x-y - Any vehicle with two axle groups with x and y axles
 */
  /* Axle groups 
 * Class axle-group-x has x axles
 */
  /* Axles
 * Class axle-x has x wheels
 */
  /* Wheels */
  /* Cab: side view only */
  /* Drawbar */
  /* Prime Mover, Lead trailer, Dolly: line above rear axle group */
  /* Box on top */
  /* Box: bottom view */
  /* Solid block with angled left side under trailer */
  /* Alert messages */
  /* Left/Right Scroller */
  /* Better click handling for bottom view; clicks on vehicles "on top" should not override clicks on wheels underneath */
  /* Axle weights page */
}
.gwTruckDisplayer label {
  margin: 0px;
  font-size: inherit;
  color: #f9fafc;
  overflow: visible;
}
.gwTruckDisplayer .page {
  display: none;
  max-height: 100%;
}
.gwTruckDisplayer .page.current {
  display: block;
}
.gwTruckDisplayer #truck-side-view .truck-type-title {
  margin-top: 0;
}
.gwTruckDisplayer #truck-side-view button.truck-config-change {
  float: right;
  margin: 0 0 20px 30px;
}
.gwTruckDisplayer .buttons-row {
  border-top: 1px solid #f9fafc;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
}
.gwTruckDisplayer .buttons-row .gwToggle .btn.btn-link {
  font-size: 20px;
  letter-spacing: 3px;
  line-height: 52px;
}
.gwTruckDisplayer .buttons-row .switch {
  position: relative;
  display: inline-block;
  margin: 0 15px;
  width: 56px;
  height: 31px;
}
.gwTruckDisplayer .buttons-row .switch .switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}
.gwTruckDisplayer .buttons-row .switch .switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grid-title-font-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 31px;
}
.gwTruckDisplayer .buttons-row .switch .switch-slider:before {
  position: absolute;
  content: "";
  height: 22.32px;
  width: 22.32px;
  left: 4.34px;
  bottom: 4.34px;
  background-color: var(--content-text-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.gwTruckDisplayer .buttons-row .switch .switch-input:checked + .switch-slider:before {
  -webkit-transform: translateX(25px);
  transform: translateX(25px);
}
.gwTruckDisplayer .truck-buttons-row-status {
  display: flex;
  align-items: flex-start;
}
.gwTruckDisplayer button {
  background-color: #1b2431;
  color: #f9fafc;
  border: none;
}
.gwTruckDisplayer button.status-check,
.gwTruckDisplayer button.status-critical,
.gwTruckDisplayer button.status-ok {
  padding: 0px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.gwTruckDisplayer button.status-check i,
.gwTruckDisplayer button.status-critical i,
.gwTruckDisplayer button.status-ok i {
  width: 55px;
  font-size: 25px;
  line-height: 2.1;
  color: #f9fafc;
}
.gwTruckDisplayer button.status-check span,
.gwTruckDisplayer button.status-critical span,
.gwTruckDisplayer button.status-ok span {
  padding: 12px 20px;
}
.gwTruckDisplayer button.status-check > i {
  background-color: #ffc825;
}
.gwTruckDisplayer button.status-critical > i {
  background-color: #d90000;
}
.gwTruckDisplayer .btn:not(.btn-link):not(.disabled):not([disabled]):focus,
.gwTruckDisplayer .btn:not(.btn-link):not(.disabled):not([disabled]):hover,
.gwTruckDisplayer .btn:not(.btn-link):not(.disabled):not([disabled]):active {
  background-color: var(--grid-title-font-color);
  border: 1px solid var(--content-text-color);
}
.gwTruckDisplayer .btn:not(.btn-link)[disabled] {
  background-color: var(--theme-background);
  border-color: var(--content-text-color-fade50);
}
.gwTruckDisplayer .open > .btn:not(.btn-link):not(.disabled):not([disabled]) {
  background-color: var(--grid-title-font-color);
}
.gwTruckDisplayer .legend label {
  margin-left: 20px;
  display: inline-block;
}
.gwTruckDisplayer .legend label::before {
  display: inline-block;
  content: '';
  width: 25px;
  height: 12px;
  border-radius: 2px;
  margin-right: 12px;
}
.gwTruckDisplayer .legend label.status-good::before {
  background-color: #58ba00;
}
.gwTruckDisplayer .legend label.status-check::before {
  background-color: #ffc825;
}
.gwTruckDisplayer .legend label.status-critical::before {
  background-color: #d90000;
}
.gwTruckDisplayer .truck.truck-view-side .legend label .status-offline::before {
  color: #f9fafc;
  opacity: 0.5;
}
.gwTruckDisplayer .legend label.status-offline::before {
  font-family: 'icomoon' !important;
  content: "\f05e";
  font-size: 15px;
  width: 10px;
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  color: #898a8c;
}
.gwTruckDisplayer .legend label.status-offline.faded::before {
  color: white;
  opacity: 0.5;
}
.gwTruckDisplayer .truck-front-back {
  color: rgba(249, 250, 252, 0.5);
  height: 2em;
  margin-top: 125px;
  font-size: 20px;
}
.gwTruckDisplayer .truck-front-back label {
  float: left;
}
.gwTruckDisplayer .truck-front-back label + label {
  float: right;
}
.gwTruckDisplayer .flex-container-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gwTruckDisplayer .flex-container-align-items-centre {
  display: flex;
  align-items: center;
}
.gwTruckDisplayer .flex-container-justify-centre {
  display: flex;
  justify-content: center;
}
.gwTruckDisplayer #tyres-detail button.view-sensors,
.gwTruckDisplayer #tyres-detail button.hide-sensors {
  margin-left: 30px;
  padding: 12px 20px;
  font-size: 16px;
}
.gwTruckDisplayer #tyres-detail button.hide-sensors,
.gwTruckDisplayer #tyres-detail.sensors button.view-sensors {
  display: none;
}
.gwTruckDisplayer #tyres-detail.sensors button.hide-sensors,
.gwTruckDisplayer #tyres-detail button.view-sensors {
  display: inline-block;
}
.gwTruckDisplayer .truck-container {
  text-align: center;
  width: 100%;
  overflow: hidden;
}
.gwTruckDisplayer .truck-container .truck-error-message {
  height: 35px;
  text-align: center;
}
.gwTruckDisplayer #truck-side-view .truck-container {
  margin-top: 70px;
  margin-left: 20px;
  margin-right: 10px;
}
.gwTruckDisplayer #axle-weights .truck-container {
  margin-top: 60px;
  min-height: 300px;
}
.gwTruckDisplayer .truck {
  display: inline-flex;
  white-space: nowrap;
  transform-origin: 0 0;
  text-align: left;
  position: relative;
}
.gwTruckDisplayer .truck.truck-view-bottom {
  margin: 20px 0;
}
.gwTruckDisplayer .truck * {
  display: flex;
  flex-shrink: 0;
}
.gwTruckDisplayer .truck .vehicle {
  position: relative;
  justify-content: flex-end;
}
.gwTruckDisplayer .truck .vehicle-m,
.gwTruckDisplayer .truck .vehicle-r,
.gwTruckDisplayer .truck .vehicle-td {
  justify-content: space-between;
}
.gwTruckDisplayer .truck .vehicle-tp {
  justify-content: center;
}
.gwTruckDisplayer .truck .vehicle-td,
.gwTruckDisplayer .truck .vehicle-tp {
  min-width: 360px;
}
.gwTruckDisplayer .truck .vehicle-r {
  min-width: 430px;
}
.gwTruckDisplayer .truck .vehicle-s {
  min-width: 590px;
}
.gwTruckDisplayer .truck .vehicle-l {
  min-width: 490px;
}
.gwTruckDisplayer .truck .vehicle-l.vehicle-a-4 {
  min-width: 550px;
}
.gwTruckDisplayer .truck .vehicle-m + .vehicle {
  margin-left: -80px;
}
.gwTruckDisplayer .truck .vehicle-m.vehicle-a-1-2 + .vehicle {
  margin-left: -150px;
}
.gwTruckDisplayer .truck .vehicle-m.vehicle-a-1-3 + .vehicle {
  margin-left: -190px;
}
.gwTruckDisplayer .truck .vehicle-m.vehicle-a-2-2 + .vehicle {
  margin-left: -160px;
}
.gwTruckDisplayer .truck .vehicle-m.vehicle-a-2-3 + .vehicle {
  margin-left: -230px;
}
.gwTruckDisplayer .truck .vehicle-tp,
.gwTruckDisplayer .truck .vehicle-td,
.gwTruckDisplayer .truck .vehicle-d {
  margin-left: 10px;
}
.gwTruckDisplayer .truck .vehicle-d + .vehicle {
  margin-left: -150px;
}
.gwTruckDisplayer .truck .vehicle-d.vehicle-a-3 + .vehicle {
  margin-left: -220px;
}
.gwTruckDisplayer .truck .vehicle-l + .vehicle {
  margin-left: -160px;
}
.gwTruckDisplayer .truck .axle-group {
  position: relative;
}
.gwTruckDisplayer .truck.truck-view-side .axle-group {
  border-radius: 30px;
  height: 60px;
  margin: 142px 10px 60px 10px;
  z-index: 10;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle-group {
  margin: -15px 10px;
}
.gwTruckDisplayer .truck .vehicle-s .axle-group {
  margin-left: 310px;
}
.gwTruckDisplayer .truck .vehicle-r .axle-group,
.gwTruckDisplayer .truck .vehicle-m .axle-group {
  margin-left: 20px;
}
.gwTruckDisplayer .truck .vehicle-d .axle-group {
  margin-left: 80px;
}
.gwTruckDisplayer .truck .vehicle-td .drawbar + .axle-group {
  margin-left: 70px;
}
.gwTruckDisplayer .truck .vehicle-tp .axle-group {
  margin-left: 130px;
  margin-right: 70px;
}
.gwTruckDisplayer .truck .axle-group + .axle-group {
  margin-left: 130px;
}
.gwTruckDisplayer .truck .vehicle-m .axle-group + .axle-group {
  margin-left: 110px;
}
.gwTruckDisplayer .truck .vehicle-l .axle-group {
  position: absolute;
  right: 0;
}
.gwTruckDisplayer .truck .vehicle-m .axle-group + .axle-group-3 {
  margin-left: 80px;
}
.gwTruckDisplayer .truck .vehicle-m .axle-group + .axle-group-4 {
  margin-left: 0;
}
.gwTruckDisplayer .truck .vehicle-m .axle-group-2 + .axle-group {
  margin-left: 50px;
}
.gwTruckDisplayer .truck .vehicle-r .axle-group + .axle-group {
  margin-left: 270px;
  margin-right: 40px;
}
.gwTruckDisplayer .truck .vehicle-r .axle-group + .axle-group-2 {
  margin-left: 220px;
}
.gwTruckDisplayer .truck .vehicle-r .axle-group-2 + .axle-group-2 {
  margin-left: 150px;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle-group {
  height: calc(100% + 30px);
}
.gwTruckDisplayer .truck.truck-view-side .axle-group .axle-group-weights {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 25px;
}
.gwTruckDisplayer .truck.truck-view-side .axle-group .axle-group-weights .weight-box,
.gwTruckDisplayer .truck.truck-view-side .axle-group .axle-group-weights label.max {
  display: none;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle-group .axle-group-weights {
  display: none;
}
.gwTruckDisplayer .truck .axle {
  width: 60px;
}
.gwTruckDisplayer .truck .axle + .axle {
  margin-left: 10px;
}
.gwTruckDisplayer .truck.truck-view-side .axle {
  height: 100%;
  border: 10px solid #E2E2E2;
  border-radius: 60px;
  position: relative;
}
.gwTruckDisplayer .truck.truck-view-side .axle.noborder {
  border: none;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle {
  height: 100%;
  position: relative;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle::before {
  content: '';
  position: absolute;
  left: calc(50% - 1.5px);
  width: 3px;
  background-color: #f9fafc;
  top: 2px;
  height: calc(100% - 4px);
}
.gwTruckDisplayer .truck.truck-view-side .wheel {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  position: absolute;
  left: -10px;
  top: -10px;
  border-radius: 50%;
}
.gwTruckDisplayer .truck.truck-view-bottom .wheel {
  width: 100%;
  height: 30px;
  background-color: #E2E2E2;
  position: absolute;
  left: 0;
  border-radius: 5px;
  flex-wrap: wrap;
  color: #000;
  padding: 2px;
}
.gwTruckDisplayer .truck.truck-view-bottom .wheel:nth-child(1) {
  top: 0;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle-2 .wheel:nth-child(2),
.gwTruckDisplayer .truck.truck-view-bottom .axle-4 .wheel:nth-child(4) {
  bottom: 0;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle-4 .wheel:nth-child(2) {
  top: 35px;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle-4 .wheel:nth-child(3) {
  bottom: 35px;
}
.gwTruckDisplayer .truck.truck-view-side:not(.truck-axle-weights) .wheel i {
  font-size: 29px;
  padding: 14px 15px;
}
.gwTruckDisplayer .truck.truck-view-side:not(.truck-axle-weights) .wheel i.icon-ban {
  font-size: 66px;
  padding: 0px 0px;
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  opacity: 0.5;
}
.gwTruckDisplayer .truck.truck-axle-weights .wheel i.icon-ban::before {
  content: '';
}
.gwTruckDisplayer .truck.truck-view-bottom:not(.stats) .wheel i {
  font-size: 15px;
  padding: 5px 19px;
}
.gwTruckDisplayer .truck.truck-view-bottom .wheel i.icon-ban {
  font-size: 15px;
  padding: 5px 22px;
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  color: #898a8c;
}
.gwTruckDisplayer .truck.truck-view-bottom.sensors .wheel i.icon-ban {
  display: none;
}
.gwTruckDisplayer .truck-axle-weights .wheel.status-check i::before,
.gwTruckDisplayer .stats .wheel.status-check i::before,
.gwTruckDisplayer .truck-axle-weights .wheel.status-critical i::before,
.gwTruckDisplayer .stats .wheel.status-critical i::before {
  content: '';
}
.gwTruckDisplayer .truck.wheel i.icon-ban {
  font-size: 50px;
}
.gwTruckDisplayer .truck:not(.truck-axle-weights) .wheel.status-check {
  background-color: #ffc825;
  color: #f9fafc;
  z-index: 2;
}
.gwTruckDisplayer .truck:not(.truck-axle-weights) .wheel.status-critical {
  background-color: #d90000;
  color: #f9fafc;
  z-index: 2;
}
.gwTruckDisplayer .truck .wheel.status-good {
  color: #f9fafc;
  z-index: 2;
  border: none;
}
.gwTruckDisplayer .truck.truck-view-side .wheel.status-good {
  border: 10px solid #58ba00;
  color: #f9fafc;
  z-index: 2;
  background: none;
}
.gwTruckDisplayer .truck.truck-view-side .wheel.status-offline {
  padding: 7px 14px;
  z-index: 2;
  background: none;
}
.gwTruckDisplayer .truck.truck-view-side.truck-axle-weights .wheel.status-good {
  border: 10px solid #e2e2e2;
  background: none;
}
.gwTruckDisplayer .truck .wheel label {
  display: none;
}
.gwTruckDisplayer .truck.stats:not(.sensors) .wheel.status-check .stat,
.gwTruckDisplayer .truck.stats:not(.sensors) .wheel.status-critical .stat {
  color: #f9fafc;
}
.gwTruckDisplayer .truck.sensors .wheel.status-check .sensor,
.gwTruckDisplayer .truck.sensors .wheel.status-critical .sensor {
  color: #f9fafc;
}
.gwTruckDisplayer .truck.stats:not(.sensors) .wheel label.stat,
.gwTruckDisplayer .truck.sensors .wheel label.sensor {
  font-size: 11.5px;
  flex-basis: 100%;
  text-align: center;
  display: block;
  line-height: 13px;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: #333;
}
.gwTruckDisplayer .truck .cab {
  display: none;
}
.gwTruckDisplayer .truck.truck-view-side .cab {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 138px;
  background-repeat: no-repeat;
  background-position: left bottom;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-r .cab {
  background-image: url('../../Content/Themes/Shared/Images/cab-1.HZus5ZocKVE73KUH6mahii7fqXI.svg');
  background-size: 120px 120px;
  width: 120px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-m .cab {
  background-image: url('../../Content/Themes/Shared/Images/cab-2.EX2Y_l6orEPpv-d2rcUFtka79lc.svg');
  background-size: 162px 105px;
  width: 162px;
}
.gwTruckDisplayer .truck .drawbar {
  display: block;
  position: absolute;
  background-color: #f9fafc;
  left: 0;
}
.gwTruckDisplayer .truck.truck-view-side .drawbar {
  top: 130px;
  width: 90px;
  height: 8px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-td .drawbar,
.gwTruckDisplayer .truck.truck-view-side .vehicle-tp .drawbar {
  right: calc(100% - 40px);
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-d .drawbar {
  left: 0;
}
.gwTruckDisplayer .truck.truck-view-side .drawbar::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  border-left: 26px solid #f9fafc;
  border-top: 26px solid transparent;
}
.gwTruckDisplayer .truck.truck-view-bottom .drawbar {
  top: calc(50% - 1px);
  height: 2px;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-td .drawbar,
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-tp .drawbar {
  width: 60px;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-d .drawbar {
  width: 70px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-m .axle-group:last-child::after,
.gwTruckDisplayer .truck.truck-view-side .vehicle-l .axle-group::after,
.gwTruckDisplayer .truck.truck-view-side .vehicle-d .axle-group::after {
  content: '';
  position: absolute;
  width: calc(100% + 10px);
  height: 8px;
  right: 0;
  top: -12px;
  background-color: #f9fafc;
  border-radius: 0 4px 4px 0;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-m .axle-group:last-child::after {
  width: calc(100% + 80px);
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-l .axle-group::after {
  width: calc(100% + 40px);
  max-width: 220px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-r::before,
.gwTruckDisplayer .truck.truck-view-side .vehicle-tp::before,
.gwTruckDisplayer .truck.truck-view-side .vehicle-td::before,
.gwTruckDisplayer .truck.truck-view-side .vehicle-s::before,
.gwTruckDisplayer .truck.truck-view-side .vehicle-l::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 138px;
  right: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.25);
  border: 8px solid #f9fafc;
  border-radius: 18px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-r::before {
  width: calc(100% - 130px);
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-tp::before,
.gwTruckDisplayer .truck.truck-view-side .vehicle-td::before {
  width: calc(100% - 60px);
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-s::before,
.gwTruckDisplayer .truck.truck-view-side .vehicle-l::before {
  height: 125px;
  border-bottom-right-radius: 0;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-l::before {
  width: calc(100% - 170px);
  left: 0;
  right: auto;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle {
  height: 210px;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  border: 2px solid #f9fafc;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-d::before {
  width: calc(100% - 70px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-tp::before,
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-td::before {
  width: calc(100% - 60px);
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-s .axle-group:last-child::before,
.gwTruckDisplayer .truck.truck-view-side .vehicle-l::after {
  content: '';
  width: calc(100% + 45px);
  height: 0;
  position: absolute;
  border-top: 15px solid #f9fafc;
  border-left: 35px solid transparent;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-s .axle-group:last-child::before {
  width: calc(100% + 45px);
  right: -10px;
  top: -20px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-l::after {
  width: 100px;
  right: 170px;
  top: 123px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-l.vehicle-a-4::after {
  width: 150px;
}
.gwTruckDisplayer .truck.truck-view-side .axle-group .alert {
  background-color: #E2E2E2;
  color: #000;
  font-size: 24px;
  position: absolute;
  bottom: calc(100% + 25px);
  left: 50%;
  transform: translateX(-35%);
  padding: 10px 15px;
  border-radius: 5px;
  z-index: 10;
  display: block;
}
.gwTruckDisplayer .truck.truck-view-side.small-scale .axle-group .alert {
  font-size: 30px;
}
.gwTruckDisplayer .truck.truck-view-side .vehicle-s .axle-group .alert,
.gwTruckDisplayer .truck.truck-view-side .vehicle-l .axle-group .alert,
.gwTruckDisplayer .truck.truck-view-side .vehicle-d .axle-group .alert {
  bottom: calc(100% + 5px);
}
.gwTruckDisplayer .truck.truck-view-side .axle-group .alert::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid #E2E2E2;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.gwTruckDisplayer .truck.truck-view-side .axle-group.status-check .alert {
  background-color: #ffc825;
  color: #F9FAFC;
}
.gwTruckDisplayer .truck.truck-view-side .axle-group.status-check .alert::after {
  border-top-color: #ffc825;
}
.gwTruckDisplayer .truck.truck-view-side .axle-group.status-critical .alert {
  background-color: #d90000;
  color: #F9FAFC;
}
.gwTruckDisplayer .truck.truck-view-side .axle-group.status-critical .alert::after {
  border-top-color: #d90000;
}
.gwTruckDisplayer .truck.truck-view-bottom .axle-group .alert {
  display: none;
}
.gwTruckDisplayer .truck-scroller {
  position: relative;
  width: calc(100% + 120px);
  margin-left: -60px;
}
.gwTruckDisplayer .truck-scroller button.scroll-left,
.gwTruckDisplayer .truck-scroller button.scroll-right {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  height: 38px;
  width: 22px;
  min-width: 0;
  padding: 0;
  text-align: center;
  margin: 0;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
}
.gwTruckDisplayer .truck-scroller button.scroll-left {
  left: 25px;
  background-image: url('../../Content/Themes/Shared/Images/arrow-left.THWLHlPjQMxV8iAA1mE4MKQQrfw.svg');
}
.gwTruckDisplayer .truck-scroller button.scroll-right {
  right: 25px;
  background-image: url('../../Content/Themes/Shared/Images/arrow-right.pMzWbviEn3RF3g1_-fG7oVKKsZI.svg');
}
.gwTruckDisplayer .truck-scroller button[disabled] {
  opacity: 0.3;
}
.gwTruckDisplayer .truck-scroller button.hidden {
  display: none;
}
.gwTruckDisplayer .truck-scroller {
  overflow: hidden;
  width: calc((100% + 100px) / 1.5);
  transform-origin: 50% 0;
  transform: scale(1.5);
  margin: 0 auto 0 auto;
}
.gwTruckDisplayer .truck-scroller .truck-container {
  margin-top: 0;
  text-align: left;
}
.gwTruckDisplayer .truck-scroller .truck.animated {
  transition: transform 0.5s ease;
}
.gwTruckDisplayer .truck-scroller .truck.animated .vehicle {
  transition: opacity 0.5s ease;
}
.gwTruckDisplayer .truck-scroller .truck .vehicle:not(.selected) {
  opacity: 0.2;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-s {
  z-index: 3;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-l {
  z-index: 4;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-m {
  z-index: 5;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle.selected {
  z-index: 6;
}
.gwTruckDisplayer .truck.truck-view-bottom .vehicle-d {
  z-index: 7;
}
.gwTruckDisplayer .truck.truck-axle-weights .axle-group {
  margin-bottom: 120px;
}
.gwTruckDisplayer .truck.truck-axle-weights .axle-group .axle-group-weights {
  display: block;
  min-width: 100%;
}
.gwTruckDisplayer .truck.truck-axle-weights .axle-group .axle-group-weights label.weight-caption {
  min-width: 100%;
  text-align: center;
  display: block;
}
.gwTruckDisplayer .truck.truck-axle-weights .axle-group .axle-group-weights label.max {
  min-width: 100%;
  text-align: center;
  display: block;
  padding: 20px 10px;
  font-size: 24px;
}
.gwTruckDisplayer .truck.truck-axle-weights .axle-group .axle-group-weights .weight-box {
  display: flex;
}
.gwTruckDisplayer .truck.truck-axle-weights .axle-group .axle-group-weights label.max {
  display: block;
  color: rgba(255, 255, 255, 0.6);
}
.gwTruckDisplayer .truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::before,
.gwTruckDisplayer .truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::after {
  text-align: right;
  position: absolute;
  right: calc(100% + 10px);
  padding: 20px 10px;
  text-transform: capitalize;
}
.gwTruckDisplayer .truck.truck-axle-weights .axle-group .axle-group-weights input.weight,
.gwTruckDisplayer .truck.truck-axle-weights .axle-group .axle-group-weights label.max,
.gwTruckDisplayer .truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::before,
.gwTruckDisplayer .truck.truck-axle-weights .vehicle:first-child .cab + .axle-group .axle-group-weights::after {
  font-size: 30px;
}
.gwTruckDisplayer .truck.truck-axle-weights.small-scale .axle-group .axle-group-weights input.weight,
.gwTruckDisplayer .truck.truck-axle-weights.small-scale .axle-group .axle-group-weights label.max,
.gwTruckDisplayer .truck.truck-axle-weights.small-scale .vehicle:first-child .cab + .axle-group .axle-group-weights::before,
.gwTruckDisplayer .truck.truck-axle-weights.small-scale .vehicle:first-child .cab + .axle-group .axle-group-weights::after {
  font-size: 34px;
}
.gwTruckDisplayer .weight-box {
  width: 180px;
  height: 42px;
  margin: 15px auto;
}
.gwTruckDisplayer .gwNumeric {
  width: 100%;
}
.gwTruckDisplayer .gwNumeric input {
  border-radius: 0;
  padding-top: 3px;
  padding-right: 65px;
  font-size: 30px;
  background-color: inherit;
  color: inherit;
  border-color: inherit;
  border-width: 0 0 1px 0;
}
.gwTruckDisplayer .gwNumeric input.alert-danger {
  background-color: inherit;
  color: inherit;
  border-color: inherit;
}
.gwTruckDisplayer .gwNumeric input:focus {
  /* Force !important to override !important used in \Glow\DotNet\HTML\Client\Client\Content\Themes\Shared\components\_input.less */
  background-color: transparent !important;
  border-width: 0 0 1px 0 !important;
}
.gwTruckDisplayer label.weight-unit {
  order: 2;
  margin-left: -60px;
  font-size: 30px;
  align-self: center;
  color: var(--content-text-color-fade50);
}
.gwTruckDisplayer label.weight-unit:after {
  content: "\f040";
  font-family: 'icomoon';
  margin: 5px;
  font-size: 22px;
  color: var(--grid-title-font-color);
}
.gwTruckDisplayer .alerts {
  margin-left: auto;
  align-self: center;
}
.gwTruckDisplayer button.btn-alerts {
  padding: 0;
  min-width: 0;
}
.gwTruckDisplayer .btn-alerts--icon {
  width: 24px;
  font-size: 24px;
  margin: 14px;
}
.gwTruckDisplayer .alert-count {
  border-radius: 5px;
  height: 18px;
  width: 18px;
  padding: 4px 6px;
  font-size: 12px;
}
.gwTruckDisplayer .gwValidationSummary {
  width: 100%;
}
.gwTruckDisplayer .tap-to-edit-instruction {
  font-size: 18px;
  font-style: italic;
  margin: 0 80px;
}
.gwTruckDisplayer .tap-to-edit-instruction:before {
  content: "\f0a6";
  font-family: 'icomoon';
  margin: 0 5px;
  font-style: normal;
}
.gwTruckHeaderDisplayer .indicator {
  display: inline-block;
  width: 40px;
  height: 50px;
  font-size: 15px;
  text-align: center;
  padding-top: 33px;
  position: relative;
  margin: 0 5px;
  text-transform: uppercase;
}
.gwTruckHeaderDisplayer .indicator::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border: 7px solid #d90000;
  border-radius: 7px;
}
.gwTruckHeaderDisplayer .indicator.on::before {
  border-color: #58ba00;
}
.gwTruckHeaderDisplayer .time {
  display: inline-block;
  font-size: 18px;
  margin: 15px 10px 0 10px;
}
.g-modal.g-truck-dashboard-delivery .modal-body,
.g-truck-dashboard-delivery__address .gwGroupBox .g-groupbox-panel-heading .panel-title {
  padding-top: 0;
}
.g-truck-dashboard-delivery__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.g-truck-dashboard-delivery__form .gwTextBox {
  position: relative;
}
.g-truck-dashboard-delivery__form__item {
  padding: 5px 5px 0 5px;
  width: 475px;
}
.g-truck-dashboard-delivery__form__item:first-child {
  padding-bottom: 5px;
}
.g-truck-dashboard-delivery__address.gwJobDocAddress-container {
  width: 100%;
}
.g-truck-dashboard-delivery__container {
  display: flex;
}
.g-truck-dashboard-delivery__container__item {
  flex: 1;
}
.g-truck-dashboard-delivery__declaration {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--theme-value-text);
}
.g-truck-dashboard-delivery__signature {
  margin-top: 10px;
  width: 465px;
  height: 300px;
}
.filelist {
  color: var(--content-text-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
}
.filelist__container {
  overflow: auto;
  height: calc(100% - 37px);
  margin-top: 10px;
}
.filelist__container-not-available {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filelist__container-not-available span {
  font-style: italic;
  margin-top: 150px;
}
.filelist__error {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 4px 0 0 7px;
  justify-content: flex-end;
  flex: 1 0 auto;
}
.filelist__error .filelist__error-icon {
  margin-right: 5px;
  color: #ffb0b0;
}
.filelist__error .filelist__error-message {
  color: #ffb0b0;
}
.filelist__error .filelist__offline-icon {
  margin-right: 5px;
  color: #999;
}
.filelist__error .filelist__offline-message {
  color: #999;
}
.filelist__error + .g-kg-title-sub-container {
  flex: 0 1 auto;
}
.filelist__item {
  display: flex;
  align-items: center;
  background-color: var(--content-text-color-fade40);
  text-transform: capitalize;
  letter-spacing: 0.2em;
  font-size: 20px;
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
}
.filelist__item + .filelist__item {
  margin-top: 10px;
}
.filelist__item .filelist__item-title {
  padding: 16px;
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filelist__item .filelist__item-type {
  padding: 16px;
  font-size: 18px;
  color: var(--content-text-color);
  position: relative;
}
.filelist__item .filelist__item-type__crossed {
  position: absolute;
  width: 30px;
  height: 1px;
  transform: rotate(45deg);
  background-color: var(--content-text-color);
  top: 25px;
  left: 9px;
}
.filelist__item .filelist__item-button {
  text-transform: capitalize;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: var(--content-text-color-fade60);
  text-transform: uppercase;
  padding: 16px 30px;
}
.filelist--disabled,
.filelist__item--disabled {
  opacity: 0.5;
  cursor: default;
}
.g-dialog-colorscheme .modal-dialog.g-modal-autoresize .modal-content {
  height: 500px;
}
.g-dialog-colorscheme .g-colorscheme {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.g-dialog-colorscheme .g-colorscheme__name {
  float: left;
  width: 50%;
}
.g-dialog-colorscheme .g-colorscheme__ispublished {
  float: left;
  width: 25%;
}
.g-dialog-colorscheme .g-colorscheme__colorstrips {
  height: 300px;
}
.g-dialog-colorscheme .koGrid .kgViewport .g-colorscheme-action-button {
  padding: 4px 6px;
}
.g-dialog-colorscheme .koGrid .kgViewport .kgRow .g-colorscheme__colorstrips__input input {
  background-color: transparent;
}
.g-dialog-colorscheme .koGrid .kgViewport .kgRow .g-colorscheme__colorstrips__input input[disabled] {
  color: var(--input-control-read-only-text-color);
  background-color: var(--input-control-read-only-unfocused-background-color-start);
}
.g-dialog-colorscheme .koGrid .kgViewport .kgRow .gwColorPicker.input-group:before {
  top: 2px;
}
.g-dialog-colorscheme .koGrid .kgViewport .kgRow .gwColorPicker input.form-control {
  padding-left: 16px;
}
.g-dialog-colorscheme .koGrid .kgViewport .kgRow .gwColorPicker input.ui-spinner-input {
  padding-right: 12px;
}
.g-dialog-colorscheme .koGrid .kgViewport .kgRow .gwColorPicker .colpick_hex_field input {
  right: 0;
}
.gwConversationList-container {
  height: 100%;
  width: 100%;
}
.gwConversationList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.gwConversationList__conversation-list--border {
  border: 1px solid var(--sidebar-panel-border-color);
}
.gwConversationList__conversation-list {
  background-color: var(--popover-background-color);
  border-radius: 4px;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}
.gwConversationList__message-separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.gwConversationList__message-separator > span {
  width: 150px;
}
.gwConversationList__message-separator--line {
  border-top: 1px solid var(--sidebar-panel-border-color);
}
.gwConversationList__conversation-more-button {
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: var(--button-background-color-start);
  color: var(--button-text-color);
  border-radius: 50%;
  -webkit-transform: translate(0, -20px);
  -ms-transform: translate(0, -20px);
  transform: translate(0, -20px);
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  right: 40px;
  text-align: center;
  vertical-align: middle;
  width: 50px;
}
.gwConversationList__conversation-more-button a,
.gwConversationList__conversation-more-button label {
  color: var(--button-text-color);
}
.gwConversationList__conversation-more-button i {
  color: var(--button-text-color);
}
.gwConversationList__conversation-more-button i.chevron {
  color: var(--button-text-color-lighten10);
}
.open > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]),
.active > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]),
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]) i,
.active > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]) i,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed i,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus,
.active > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:focus,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus i,
.active > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus i,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:focus i,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover,
  .active > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:hover,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--button-background-hover-color-start);
    color: var(--button-text-hover-color);
  }
}
.open > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active,
.active > .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).pressed:active,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--button-background-pressed-color-start);
  color: var(--button-text-hover-color);
}
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]):focus {
  background-color: var(--button-background-color-start);
  color: var(--button-text-color);
  border: 1px dashed #333;
  border-radius: 50%;
}
@media (hover) {
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover {
    color: var(--button-text-hover-color);
    background-color: var(--button-background-hover-color-start);
    border-radius: 50%;
  }
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover a,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover label,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover span {
    color: var(--button-text-hover-color) !important;
  }
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .gwConversationList__conversation-more-button:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active {
  color: var(--button-text-hover-color);
  background-color: var(--button-background-pressed-color-start);
  border-radius: 50%;
}
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active a,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active i,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active label,
.gwConversationList__conversation-more-button:not(.disabled):not([disabled]):active span {
  color: var(--button-text-hover-color) !important;
}
.gwConversationList__conversation-more-button.disabled,
.gwConversationList__conversation-more-button[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.gwConversationList__conversation-more-button.disabled:active,
.gwConversationList__conversation-more-button[disabled]:active {
  color: #999;
}
.gwConversationList__conversation-more-button.disabled:active a,
.gwConversationList__conversation-more-button[disabled]:active a,
.gwConversationList__conversation-more-button.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwConversationList__conversation-more-button[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.gwConversationList__conversation-more-button.disabled:active label,
.gwConversationList__conversation-more-button[disabled]:active label,
.gwConversationList__conversation-more-button.disabled:active span,
.gwConversationList__conversation-more-button[disabled]:active span {
  color: #999;
}
.gwConversationList__conversation-more-button.disabled:active i,
.gwConversationList__conversation-more-button[disabled]:active i {
  opacity: 1;
}
.gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon,
.gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon:focus,
.gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon:hover,
.gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.gwConversationList__conversation-more-button.disabled:active i.g-menu-item-always-enabled-icon:active,
.gwConversationList__conversation-more-button[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.gwConversationList__conversation-more-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwConversationList__conversation-more-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.gwConversationList__conversation-more-button.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.gwConversationList__conversation-more-button[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.gwConversationList__conversation-more-button.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.gwConversationList__conversation-more-button.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.gwConversationList__conversation-more-button.unreadMessages {
  opacity: 1;
  background-color: var(--button-background-hover-color-start);
}
.gwConversationList__conversation-more-button i {
  font-size: 28px;
}
.gwConversationList__conversation-messages {
  display: flex;
  overflow: auto;
  flex-direction: row;
  flex: 2 0 0;
}
.gwConversationList__conversation-list-item {
  padding: 10px 20px;
  display: flex;
}
.gwConversationList__message-group-posted-date {
  font-weight: bold;
  font-style: italic;
  margin: auto;
}
.gwConversationList__message {
  border-radius: 5px;
  padding: 10px 15px;
  position: relative;
}
.gwConversationList__message--own {
  background-color: var(--grid-viewport-row-color);
  margin-left: auto;
}
.gwConversationList__message--own:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 44%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-left-color: var(--grid-viewport-row-color);
  border-right: 0;
}
.gwConversationList__message--system,
.gwConversationList__message--other {
  align-self: flex-start;
  background-color: var(--panel-border-color-lighten10);
}
.gwConversationList__message--system:after,
.gwConversationList__message--other:after {
  content: '';
  position: absolute;
  right: 100%;
  top: 44%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-right-color: var(--panel-border-color-lighten10);
  border-left: 0;
}
.gwConversationList__message-header {
  font-weight: bold;
  font-size: 12px;
}
.gwConversationList__message-body {
  margin-top: 4px;
  white-space: pre-wrap;
}
.gwConversationList__send-message-text {
  width: 100%;
}
.gwConversationList__send-message-text .gwTextBox {
  width: 100%;
  padding: 3px 0;
}
.gwConversationList__send-message-text label {
  display: none;
}
.gwConversationList__send-message-container {
  display: flex;
}
.gwConversationList__send-message-button {
  margin-top: 10px;
}
.gwToggle {
  display: flex;
  align-items: center;
}
.gwToggle .btn.btn-link {
  color: var(--content-text-color);
  padding: 0;
  cursor: pointer;
}
.gwToggle .btn.btn-link.btn-selected {
  color: var(--grid-title-font-color);
}
.gwToggle .switch {
  position: relative;
  display: inline-block;
  margin: 0 15px;
  width: 42px;
  height: 23px;
}
.gwToggle .switch .switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}
.gwToggle .switch .switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grid-title-font-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 23px;
}
.gwToggle .switch .switch-slider:before {
  position: absolute;
  content: "";
  height: 16.56px;
  width: 16.56px;
  left: 3.22px;
  bottom: 3.22px;
  background-color: var(--content-text-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.gwToggle .switch .switch-input:checked + .switch-slider:before {
  -webkit-transform: translateX(19px);
  transform: translateX(19px);
}
.image-capture {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.image-capture .image-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: flex-start;
  overflow: auto;
}
.image-capture .image-thumbnail-container {
  position: relative;
}
.image-capture .image-thumbnail {
  width: 84px;
  height: 84px;
  border: 1px solid var(--panel-border-color);
  margin: 8px;
  border-radius: 5px;
  transition: all 2s ease-in;
}
.image-capture .image-delete {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  text-decoration: none;
  line-height: 23px;
  padding: 0 6px;
  border: 1px solid var(--panel-border-color-darken20);
  background-color: var(--popover-background-color-darken5);
}
.image-capture .image-add {
  width: 84px;
  height: 84px;
  border: 1px solid var(--panel-border-color);
  background-color: transparent;
  margin: 8px;
  padding: 0;
  font-size: 40px;
}
.image-capture .image-data {
  padding: 8px;
  width: 100%;
  align-self: flex-end;
}
.image-capture-dialog .modal-dialog.g-modal-autoresize .modal-content {
  height: 500px;
}
.progress {
  height: 20px;
  overflow: hidden;
  background-color: #fff;
  border: solid 1px #d2d6d9;
  border-radius: 4px;
}
.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #8fd300;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.clientLinkContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.clientLinkContainer .clientLinkImage {
  content: url('../../Content/Themes/Shared/Images/ClientLink/android-chrome-256x256.--yF8KIc88yqFMUkiKYi4TXIzaU.png');
}
.clientLinkContainer h1 {
  font-size: 3.5em;
  margin-top: 20px;
  margin-bottom: 5px;
}
.g-tasktray-actions ul li:hover a {
  text-decoration: none;
}
.g-tasktray-actions ul li.active,
.g-tasktray-actions ul li.active:hover,
.g-tasktray-actions ul li.ui-btn-up-active,
.g-tasktray-actions ul li.ui-btn-up-active:hover {
  background-color: #428bca;
}
.g-tasktray-actions ul li.active:first-child,
.g-tasktray-actions ul li.active:hover:first-child,
.g-tasktray-actions ul li.ui-btn-up-active:first-child,
.g-tasktray-actions ul li.ui-btn-up-active:hover:first-child {
  border-radius: 4px 4px 0 0;
}
.g-tasktray-actions ul li.active:last-child,
.g-tasktray-actions ul li.active:hover:last-child,
.g-tasktray-actions ul li.ui-btn-up-active:last-child,
.g-tasktray-actions ul li.ui-btn-up-active:hover:last-child {
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.g-tasktray-actions ul li.active a,
.g-tasktray-actions ul li.active:hover a,
.g-tasktray-actions ul li.ui-btn-up-active a,
.g-tasktray-actions ul li.ui-btn-up-active:hover a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.g-tasktray-actions ul li.active a,
.g-tasktray-actions ul li.active:hover a,
.g-tasktray-actions ul li.ui-btn-up-active a,
.g-tasktray-actions ul li.ui-btn-up-active:hover a,
.g-tasktray-actions ul li.active i,
.g-tasktray-actions ul li.active:hover i,
.g-tasktray-actions ul li.ui-btn-up-active i,
.g-tasktray-actions ul li.ui-btn-up-active:hover i {
  color: #fff;
}
.g-tasktray-actions ul li > div {
  position: relative;
}
.g-tasktray-actions ul li > div a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0.7em 15px 0.7em 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.g-tasktray-actions ul li > div i {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -7px;
}
.g-row-task-shell {
  position: relative;
  height: 100%;
  margin-right: 200px;
  background-color: var(--content-background-color-start);
}
.g-row-task-shell h1 {
  float: left;
  margin: 7px 0 0;
  font-size: 22px;
}
.g-row-task-shell .g-shell-content {
  margin-bottom: 77px;
  overflow-x: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.g-row-task-shell .g-shell-content > .gwShellContainer {
  position: relative;
}
.g-footer-buttons {
  padding: 0;
  margin: 0;
  font-size: 0;
}
.g-footer-buttons > div {
  position: relative;
  padding: 0 0 0 4px;
}
.g-footer-buttons .btn {
  width: 100%;
}
.g-footer-buttons .btn > img {
  vertical-align: sub;
}
.g-footer-buttons [type="checkbox"] {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 1;
}
.g-sidebar-hidden {
  margin-right: 0 !important;
}
.g-configurable-page .gwMessageBar,
.g-configurable-task .gwMessageBar {
  margin-top: 2px;
}
.g-configurable-task .g-sidebar {
  top: 35px;
}
.g-configurable-task.g-has-flow-steps .g-sidebar {
  top: 61px;
}
.g-task-page .g-sidebar {
  top: 4px;
}
.g-configurable-page .g-sidebar {
  top: 37px;
}
.g-task-page.g-has-flow-steps .g-sidebar {
  top: 31px;
}
.g-page-shell {
  height: 100%;
  overflow: auto;
  margin: 0;
}
.g-page-padding {
  padding: 10px;
}
.g-page-overlay .g-page-shell {
  height: calc(100% - 84px);
}
.g-configurable-page .g-page-shell {
  padding: 0;
}
.g-configurable-page .gwShellContainer {
  padding: 10px;
}
.g-page-overlay .g-sidebar {
  top: 40px;
}
.g-configurable-page .g-page-shell,
.g-configurable-task .g-page-shell {
  margin-top: 0px;
}
.g-sidebar {
  position: absolute;
  right: 1px;
  bottom: 44px;
  z-index: 10;
}
.g-sidebar .g-grip {
  position: absolute;
  top: 50%;
  left: -1px;
  margin-top: -12px;
}
.g-sidebar .g-grip li {
  background: var(--theme-field-title);
}
.g-sidebar-wrap {
  left: 0 !important;
  width: 194px;
  height: 100%;
  background: var(--sidebar-panel-background-color);
  border-top: 1px solid var(--sidebar-panel-border-color);
  border-left: 1px solid var(--sidebar-panel-border-color);
  border-radius: 5px 0 0;
}
.g-sidebar-contents {
  bottom: 64px;
  padding-left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.g-sidebar-contents aside:first-of-type,
.g-sidebar-contents aside:first-of-type .panel-heading {
  border-top-left-radius: 4px;
}
.g-sidebar-contents .g-related-tasks-group-box {
  height: auto;
}
.g-sidebar-contents .g-validation-group-box {
  height: auto;
}
.g-sidebar-contents > ul > li {
  padding-bottom: 5px;
}
.g-sidebar-contents aside:last-of-type .panel-collapse.in {
  border-bottom: none;
}
.g-sidebar-contents .gwGroupBox {
  margin-bottom: 0;
  border: none;
}
.g-sidebar-contents .gwGroupBox .panel-body {
  position: inherit;
  margin-top: 0;
  color: #000;
  background: var(--sidebar-panel-background-color);
}
.g-sidebar-contents .gwGroupBox .panel-collapse.in {
  border-bottom: 1px solid var(--sidebar-panel-border-color);
}
.g-sidebar-contents .gwGroupBox > .panel-heading {
  height: 24px;
  line-height: normal;
  padding: 0;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  background-color: var(--theme-button);
}
.g-sidebar-contents .gwGroupBox > .panel-heading .panel-title {
  width: 100%;
  font-weight: normal;
}
.g-sidebar-contents .gwGroupBox > .panel-heading:hover {
  background-color: var(--primary-button-background-hover-color-start);
}
.g-sidebar-contents .gwGroupBox > .panel-heading:hover a,
.g-sidebar-contents .gwGroupBox > .panel-heading:hover i {
  color: #fff;
}
.g-sidebar-contents .gwGroupBox > .panel-heading a {
  display: block;
  padding: 0 8px;
  line-height: 22px;
  color: var(--theme-general-text);
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-sidebar-contents .gwGroupBox > .panel-heading a i {
  color: var(--theme-general-text);
}
.g-sidebar-contents-description {
  display: block;
  padding: 5px;
  margin: 0;
  font-size: 12px;
  color: var(--theme-general-text);
  white-space: normal;
}
.g-sidebar-contents-description.g-no-additional-info {
  color: var(--theme-field-title);
  text-align: center;
}
.g-footer-bar {
  height: 44px;
  flex: 1 0 44px;
  display: flex;
  order: 1;
}
.g-footer-bar .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.g-footer-bar .btn {
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: transparent;
  color: var(--button-text-color);
  border-radius: 5px;
}
.g-footer-bar .btn a,
.g-footer-bar .btn label {
  color: var(--button-text-color);
}
.g-footer-bar .btn i {
  color: var(--button-text-color);
}
.g-footer-bar .btn i.chevron {
  color: var(--button-text-color-lighten10);
}
.open > .g-footer-bar .btn:not(.disabled):not([disabled]),
.active > .g-footer-bar .btn:not(.disabled):not([disabled]),
.g-footer-bar .btn:not(.disabled):not([disabled]).pressed,
.g-footer-bar .btn:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-footer-bar .btn:not(.disabled):not([disabled]) i,
.active > .g-footer-bar .btn:not(.disabled):not([disabled]) i,
.g-footer-bar .btn:not(.disabled):not([disabled]).pressed i,
.g-footer-bar .btn:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .g-footer-bar .btn:not(.disabled):not([disabled]):focus,
.active > .g-footer-bar .btn:not(.disabled):not([disabled]):focus,
.g-footer-bar .btn:not(.disabled):not([disabled]).pressed:focus,
.g-footer-bar .btn:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-footer-bar .btn:not(.disabled):not([disabled]):focus i,
.active > .g-footer-bar .btn:not(.disabled):not([disabled]):focus i,
.g-footer-bar .btn:not(.disabled):not([disabled]).pressed:focus i,
.g-footer-bar .btn:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .g-footer-bar .btn:not(.disabled):not([disabled]):hover,
  .active > .g-footer-bar .btn:not(.disabled):not([disabled]):hover,
  .g-footer-bar .btn:not(.disabled):not([disabled]).pressed:hover,
  .g-footer-bar .btn:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--button-background-hover-color-start);
    color: var(--button-text-hover-color);
  }
}
.open > .g-footer-bar .btn:not(.disabled):not([disabled]):active,
.active > .g-footer-bar .btn:not(.disabled):not([disabled]):active,
.g-footer-bar .btn:not(.disabled):not([disabled]).pressed:active,
.g-footer-bar .btn:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--button-background-pressed-color-start);
  color: var(--button-text-hover-color);
}
.g-footer-bar .btn:not(.disabled):not([disabled]):focus {
  background-color: transparent;
  color: var(--button-text-color);
  border: 1px dashed #333;
  border-radius: 5px;
}
@media (hover) {
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover {
    color: var(--button-text-hover-color);
    background-color: var(--button-background-hover-color-start);
    border-radius: 5px;
  }
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover a,
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i,
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover label,
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover span {
    color: var(--button-text-hover-color) !important;
  }
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .g-footer-bar .btn:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.g-footer-bar .btn:not(.disabled):not([disabled]):active {
  color: var(--button-text-hover-color);
  background-color: var(--button-background-pressed-color-start);
  border-radius: 5px;
}
.g-footer-bar .btn:not(.disabled):not([disabled]):active a,
.g-footer-bar .btn:not(.disabled):not([disabled]):active i,
.g-footer-bar .btn:not(.disabled):not([disabled]):active label,
.g-footer-bar .btn:not(.disabled):not([disabled]):active span {
  color: var(--button-text-hover-color) !important;
}
.g-footer-bar .btn.disabled,
.g-footer-bar .btn[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.g-footer-bar .btn.disabled:active,
.g-footer-bar .btn[disabled]:active {
  color: #999;
}
.g-footer-bar .btn.disabled:active a,
.g-footer-bar .btn[disabled]:active a,
.g-footer-bar .btn.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-footer-bar .btn[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-footer-bar .btn.disabled:active label,
.g-footer-bar .btn[disabled]:active label,
.g-footer-bar .btn.disabled:active span,
.g-footer-bar .btn[disabled]:active span {
  color: #999;
}
.g-footer-bar .btn.disabled:active i,
.g-footer-bar .btn[disabled]:active i {
  opacity: 1;
}
.g-footer-bar .btn.disabled:active i.g-menu-item-always-enabled-icon,
.g-footer-bar .btn[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-footer-bar .btn.disabled:active i.g-menu-item-always-enabled-icon:focus,
.g-footer-bar .btn[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-footer-bar .btn.disabled:active i.g-menu-item-always-enabled-icon:hover,
.g-footer-bar .btn[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-footer-bar .btn.disabled:active i.g-menu-item-always-enabled-icon:active,
.g-footer-bar .btn[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-footer-bar .btn.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-footer-bar .btn[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-footer-bar .btn.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.g-footer-bar .btn[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.g-footer-bar .btn.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.g-footer-bar .btn.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.g-footer-bar .btn-primary {
  font-size: 14px;
  text-shadow: none;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: var(--primary-button-background-color-start);
  color: var(--primary-button-text-color);
  border-radius: 5px;
}
.g-footer-bar .btn-primary a,
.g-footer-bar .btn-primary label {
  color: var(--primary-button-text-color);
}
.g-footer-bar .btn-primary i {
  color: var(--primary-button-text-color);
}
.g-footer-bar .btn-primary i.chevron {
  color: var(--primary-button-text-color-lighten10);
}
.open > .g-footer-bar .btn-primary:not(.disabled):not([disabled]),
.active > .g-footer-bar .btn-primary:not(.disabled):not([disabled]),
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).pressed,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).popover-shown {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-footer-bar .btn-primary:not(.disabled):not([disabled]) i,
.active > .g-footer-bar .btn-primary:not(.disabled):not([disabled]) i,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).pressed i,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).popover-shown i {
  color: var(--theme-general-text);
}
.open > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):focus,
.active > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):focus,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).pressed:focus,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).popover-shown:focus {
  background-color: var(--grid-viewport-selected-row-color);
  color: var(--theme-general-text);
}
.open > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):focus i,
.active > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):focus i,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).pressed:focus i,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).popover-shown:focus i {
  color: var(--theme-general-text);
}
@media (hover) {
  .open > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover,
  .active > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]).pressed:hover,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]).popover-shown:hover {
    background-color: var(--primary-button-background-hover-color-start);
    color: var(--primary-button-text-hover-color);
  }
}
.open > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):active,
.active > .g-footer-bar .btn-primary:not(.disabled):not([disabled]):active,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).pressed:active,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]).popover-shown:active {
  background-color: var(--primary-button-background-pressed-color-start);
  color: var(--primary-button-text-hover-color);
}
.g-footer-bar .btn-primary:not(.disabled):not([disabled]):focus {
  background-color: var(--primary-button-background-color-start);
  color: var(--primary-button-text-color);
  border: 1px dashed #333;
  border-radius: 5px;
}
@media (hover) {
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover {
    color: var(--primary-button-text-hover-color);
    background-color: var(--primary-button-background-hover-color-start);
    border-radius: 5px;
  }
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover a,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover label,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover span {
    color: var(--primary-button-text-hover-color) !important;
  }
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;
    opacity: 1;
    cursor: pointer;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px dashed transparent;
  }
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:focus,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:focus {
    border: 1px dashed #333;
    border-radius: 3px;
    outline: none;
    text-decoration: none;
  }
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:hover,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-icon:active,
  .g-footer-bar .btn-primary:not(.disabled):not([disabled]):hover i.g-menu-item-persistent-icon:active {
    opacity: 0.3;
    text-decoration: none;
  }
}
.g-footer-bar .btn-primary:not(.disabled):not([disabled]):active {
  color: var(--primary-button-text-hover-color);
  background-color: var(--primary-button-background-pressed-color-start);
  border-radius: 5px;
}
.g-footer-bar .btn-primary:not(.disabled):not([disabled]):active a,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]):active i,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]):active label,
.g-footer-bar .btn-primary:not(.disabled):not([disabled]):active span {
  color: var(--primary-button-text-hover-color) !important;
}
.g-footer-bar .btn-primary.disabled,
.g-footer-bar .btn-primary[disabled] {
  background-color: #FFFFFF;
  color: #999;
}
.g-footer-bar .btn-primary.disabled:active,
.g-footer-bar .btn-primary[disabled]:active {
  color: #999;
}
.g-footer-bar .btn-primary.disabled:active a,
.g-footer-bar .btn-primary[disabled]:active a,
.g-footer-bar .btn-primary.disabled:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-footer-bar .btn-primary[disabled]:active i:not(.g-menu-item-unclickable-persistent-icon):not(.g-menu-item-always-enabled-icon),
.g-footer-bar .btn-primary.disabled:active label,
.g-footer-bar .btn-primary[disabled]:active label,
.g-footer-bar .btn-primary.disabled:active span,
.g-footer-bar .btn-primary[disabled]:active span {
  color: #999;
}
.g-footer-bar .btn-primary.disabled:active i,
.g-footer-bar .btn-primary[disabled]:active i {
  opacity: 1;
}
.g-footer-bar .btn-primary.disabled:active i.g-menu-item-always-enabled-icon,
.g-footer-bar .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-footer-bar .btn-primary.disabled:active i.g-menu-item-always-enabled-icon:focus,
.g-footer-bar .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-footer-bar .btn-primary.disabled:active i.g-menu-item-always-enabled-icon:hover,
.g-footer-bar .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-footer-bar .btn-primary.disabled:active i.g-menu-item-always-enabled-icon:active,
.g-footer-bar .btn-primary[disabled]:active i.g-menu-item-always-enabled-icon:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-footer-bar .btn-primary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-footer-bar .btn-primary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-icon,
.g-footer-bar .btn-primary.disabled:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon,
.g-footer-bar .btn-primary[disabled]:active i:not(.g-menu-item-always-enabled-icon).g-menu-item-persistent-icon {
  display: none;
}
.g-footer-bar .btn-primary.btn-toggle:not(.active) {
  background-color: #FFFFFF;
}
.g-footer-bar .btn-primary.btn-toggle.active {
  background-color: var(--toggle-button-turned-on-background-color-start);
}
.g-footer-bar .g-footer-content {
  height: 44px;
  position: fixed;
  z-index: 11;
  width: 100%;
  left: 0;
  z-index: 10;
  border-top: 1px solid var(--input-control-border-brush-start);
  display: flex;
  justify-content: space-between;
}
.g-footer-bar .g-footer-content .btn {
  height: 30px;
  min-width: 100px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
}
.g-footer-bar .g-footer-content .btn.g-btn-async {
  padding-left: 20px;
  padding-right: 20px;
}
.g-footer-bar .g-footer-content .btn.g-btn-async[data-icon] {
  padding-left: 11px;
  padding-right: 11px;
}
.g-footer-bar .g-footer-content .btn.g-btn-async.g-btn-loading,
.g-footer-bar .g-footer-content .btn.g-btn-async.g-btn-loading[data-icon] {
  padding-left: 9.5px;
  padding-right: 9.762px;
}
.g-footer-bar .g-footer-content .g-footer-container {
  display: flex;
  flex: 0 0 auto;
  padding: 6px 6px;
}
.g-footer-bar .g-footer-content .g-footer-container.open > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.g-footer-bar .g-footer-content .g-footer-container .g-button-container {
  min-width: 104px;
}
.g-footer-bar .g-footer-content .g-footer-container .g-button-container div {
  min-width: 100px;
}
.g-footer-bar .g-footer-content .g-footer-container.left .g-button-container {
  margin-right: 5px;
}
.g-footer-bar .g-footer-content .g-footer-container.right {
  min-width: 0;
}
.g-footer-bar .g-footer-content .g-footer-container.right.collapsed {
  flex-shrink: 1;
}
.g-footer-bar .g-footer-content .g-footer-container.collapsed .btn-primary {
  border-radius: 5px 0 0 5px !important;
}
.g-footer-bar .g-footer-content .g-footer-container.collapsed .g-divider {
  margin: 0 2px 0 2px;
}
.g-footer-bar .dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.25s, opacity 0.25s linear;
  -webkit-transition: visibility 0.25s, opacity 0.25s linear;
  -o-transition: visibility 0.25s, opacity 0.25s linear;
  display: block;
  top: auto;
  bottom: 44px;
  width: initial;
  border-radius: 5px;
  border-width: 1px;
  box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.6);
}
.g-footer-bar .dropdown-menu > li > span {
  min-width: 0;
}
.g-footer-bar .g-footer-button-container.g-form-actions {
  min-width: 100px;
}
.g-footer-bar .g-footer-button-container.g-form-actions div {
  min-width: 100px;
}
.g-footer-bar .g-footer-button-container.g-form-actions .btn {
  min-width: 100px;
}
.g-footer-bar .g-footer-button-container.g-dropdown-arrow {
  margin-left: 0;
  padding: 0;
  color: var(--icons-color);
  min-width: 44px;
}
.g-footer-bar .g-footer-button-container.g-dropdown-arrow div {
  min-width: 44px;
}
.g-footer-bar .g-footer-button-container.g-dropdown-arrow .btn {
  min-width: 44px;
}
.g-footer-bar .g-footer-button-container .icon-caret-down {
  min-width: 44px;
}
.g-footer-bar .g-button-container {
  height: 30px;
}
.g-task-dialog2 .modal-dialog .modal-body {
  padding: 0;
}
.g-task-dialog2 .g-task-page {
  height: calc(100% - 44px);
}
.g-task-dialog2 .g-footer-bar .g-footer-content {
  position: static;
  border-radius: 0 0 6px 6px;
}
.g-task-dialog2 .modal-dialog .modal-footer {
  display: none !important;
}
.g-task-dialog2 .g-sidebar {
  top: 37px;
}
.g-task-dialog2 .g-header-menu {
  flex-grow: 1;
  /* Force override mixin .action-state set in mixins.less */
}
.g-task-dialog2 .g-header-menu .g-modal-header-buttonpane {
  flex-grow: 1;
  justify-content: flex-end;
}
.g-task-dialog2 .g-header-menu li > a.g-action-state-header:not(.disabled):not([disabled]):hover i,
.g-task-dialog2 .g-header-menu li > a.g-action-state-header:not(.disabled):not([disabled]):hover span {
  color: var(--icons-color) !important;
}
.g-material-design .g-app-container .g-page-shell {
  overflow-y: hidden;
}
#clienterror,
#browsernotsupported {
  min-width: 260px;
}
#clienterror .g-heading,
#browsernotsupported .g-heading {
  margin-top: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}
#clienterror .g-heading h1,
#browsernotsupported .g-heading h1 {
  color: var(--body-text-color);
  font-size: 22px;
  line-height: 26px;
}
#clienterror .g-content,
#browsernotsupported .g-content {
  color: var(--body-text-color);
  position: absolute;
  top: 25%;
  left: 25%;
  height: 50%;
  width: 50%;
  margin: 0;
}
#clienterror .g-content > ul,
#browsernotsupported .g-content > ul {
  width: 100%;
  height: 100%;
  margin: 0;
}
#clienterror .g-content > ul i,
#browsernotsupported .g-content > ul i {
  display: initial;
  margin-right: 5px;
  font-size: 24px;
  color: var(--theme-default-button);
}
#clienterror .g-content > ul > li,
#browsernotsupported .g-content > ul > li {
  list-style-type: none;
}
#clienterror .g-content .well,
#browsernotsupported .g-content .well {
  text-align: left;
  margin-top: 20px;
  background-color: var(--body-background-color-start);
}
#clienterror .g-content .well ul li,
#browsernotsupported .g-content .well ul li {
  margin-top: 5px;
}
#clienterror .g-content .well ul.g-browserlinks,
#browsernotsupported .g-content .well ul.g-browserlinks {
  margin-top: 0;
  margin-left: 0;
}
#clienterror .g-content h3,
#browsernotsupported .g-content h3 {
  display: initial;
  line-height: 24px;
  color: var(--theme-default-button);
}
.allocate-shopping-cart__inventory {
  display: flex;
  margin: 5px;
}
.allocate-shopping-cart__control {
  width: 200px;
}
.allocate-shopping-cart__sm-control {
  width: 100px;
}
.allocate-shopping-cart__input {
  width: 200px;
  margin-left: 5px;
}
.allocate-shopping-cart--warning input {
  color: #333;
  background-color: #fff7bd;
  border-color: #ffc825;
}
.g-modal-configurator-content .gwSearchList-container {
  height: 100%;
}
.g-configurationtmpl__optionbutton-group .gwOptionButtonGrid__wrap {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
  border: none;
}
.g-configurationtmpl-filter .gwFilter-groups {
  max-height: 128px;
}
.g-context-change-dialog .modal-dialog .modal-body {
  padding: 0;
}
.g-context-change {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.g-context-change__header {
  padding: 10px;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
}
.g-context-change__title {
  font-size: 17px;
  color: var(--theme-field-title);
}
.g-context-change__subtitle {
  font-size: 17px;
  color: var(--theme-field-title);
}
.g-context-change__body {
  flex: 1;
}
.customMappingPage {
  width: 613px;
  height: 220px;
}
.customMappingPage div {
  height: 100%;
}
.customMappingPage .g-mapping-editable-cell {
  height: 100%;
  display: flex;
  align-items: center;
}
.customMappingPage .g-mapping-editable-cell input {
  font-size: 12px;
}
.customMappingPage .g-mapping-editable-cell span {
  padding: 0px 8px 1px 8px;
}
.g-document-shell {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.g-document-shell.gwGroupBox {
  height: auto;
}
.g-document-shell > .gwGroupBox > .panel-body {
  position: relative;
  height: 100%;
  margin-top: 0;
}
.g-document-shell > .gwGroupBox > .panel-body #g-page-options {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.g-document-shell > .gwGroupBox > .panel-body #g-page-options .gwGroupBox {
  padding-left: 10px;
}
.g-document-shell > .gwGroupBox > .panel-body #g-page-options .tab-pane {
  width: 100%;
  height: 100%;
}
.g-document-shell > .gwGroupBox > .panel-body #g-page-options .tab-pane:not(.active) {
  display: none;
}
.g-document-shell > .gwGroupBox > .panel-body #g-page-options .tab-pane embed,
.g-document-shell > .gwGroupBox > .panel-body #g-page-options .tab-pane object {
  width: 100%;
  height: 100%;
}
.g-document-shell > .gwGroupBox > .panel-body #g-page-options .tab-pane object > span.text-not-supported-display {
  font-weight: bold;
}
.g-document-shell > .gwGroupBox > .panel-body #g-page-options .tab-pane#preview {
  text-align: center;
}
.g-document-shell h1 {
  margin-left: 30px;
  font-size: 22px;
}
.g-document-shell .gt-cover-note {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.g-document-shell .gt-cover-note .gwCheckBox {
  position: absolute;
  width: 230px;
  height: 42px;
}
.g-document-shell .gt-cover-note-text {
  height: 100%;
  padding-top: 45px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.g-document-shell .gt-cover-note-text textarea {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.g-document-shell .gt-cover-note .gwGroupBox-container,
.g-document-shell .gt-documents-list .gwGroupBox-container {
  height: 100%;
}
.g-document-shell .gt-documents-list {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.g-document-shell #recipients {
  display: flex;
  flex-flow: column;
}
.g-document-shell #recipients,
.g-document-shell #documents {
  padding: 0 15px 15px 15px;
}
.g-document-shell .g-printing {
  display: flex;
  flex-wrap: wrap;
}
.g-document-shell .g-printing .select2-selection {
  min-height: 42px;
}
.g-document-shell .g-printing-printer {
  width: 100%;
}
.g-document-shell #preview .panel-body {
  padding: 0;
}
.g-document-shell .tab-pane,
.g-document-shell #recipients .gwDataGrid,
.g-document-shell .doc-preview {
  height: 100%;
}
.g-document-shell.g-document-popup .doc-preview {
  position: relative;
}
.g-document-shell.g-document-window {
  position: absolute;
  top: 0;
  left: 0;
}
.g-document-shell.g-document-window .panel-body {
  display: flex;
  flex-direction: column;
}
.g-document-shell.g-document-window .panel-body #g-page-options {
  display: flex;
  position: static;
}
.g-document-shell.g-document-window .g-document-footer {
  position: static;
}
.relative-data-grid {
  position: relative;
  flex-grow: 1;
}
.g-modal.edocs .g-modal-autoresize .modal-content {
  min-height: 200px;
}
.g-edocs-modal {
  height: 100%;
  display: flex;
}
.g-edocs-modal .entity-references {
  width: 200px;
  display: block;
  flex-shrink: 0;
}
.g-edocs-modal .entity-references .panel-body {
  overflow-y: auto;
  margin-top: 0;
  position: inherit;
}
.g-edocs-modal .edocs-grid {
  margin-left: 10px;
  position: relative;
  height: 100%;
  padding-bottom: 42px;
  flex: 1;
  overflow: hidden;
}
.g-edocs-modal .edocs-grid .koGrid {
  height: calc(100% - 44px);
}
.g-edocs-modal .edocs-grid .g-grid-actions {
  height: 100%;
}
.g-edocs-modal .edocs-grid .g-grid-actions .g-grid-action-button {
  height: 100%;
  display: inline-block;
  padding: 0 6px;
  border: none;
  background: transparent;
  outline: none;
}
.g-edocs-modal .edocs-grid .loading {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.g-edocs-modal .edocs-grid .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 42px;
}
.g-edocs-modal .edocs-grid .footer .show-deleted-edocs {
  float: left;
  width: 250px;
  height: 100%;
  margin-right: 3px;
}
.g-edocs-modal .edocs-grid .footer .btn {
  height: 100%;
}
.g-edoc-row-actions li i {
  width: 17px;
}
.g-edocs-edit-page {
  min-width: 400px;
}
.g-edocs-edit-page .g-anchored {
  height: 44px;
  position: static;
}
.g-edocs-edit-page .g-anchored:not(:first-child) {
  margin-top: 4px;
}
.g-edocs-edit-page .error {
  padding-top: 8px;
  color: #333;
}
.g-edocs-edit-page .gwFileUploader .fileinput-button {
  float: right;
  margin-top: -39px;
}
.edocs-grid__file-select {
  float: left;
  height: 100%;
  margin-right: 3px;
}
.g-edocs-edit-page-header h4 {
  width: 389px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-import-wizard-page .modal-dialog.g-modal-autoresize .modal-body {
  padding: 0;
}
.importTaskPage {
  position: relative;
  width: 100%;
  height: calc(100% - 25px);
  padding: 0 10px;
}
.importTaskPage .g-control-wrap input.form-control {
  float: left;
  width: 200px;
  height: 44px;
}
.importTaskPage .g-control-wrap .g-import-parameter input.form-control {
  float: none;
}
.importTaskPage .g-control-wrap button {
  margin-top: 3px;
}
.importTaskPage .g-control-wrap span.error {
  color: #333;
}
.importTaskPage .g-control-wrap .gwGroupBox .panel-body {
  position: relative;
  margin-top: 0;
}
.importTaskPage .g-control-wrap.file-uploader {
  height: calc(100% - 55px);
}
.importTaskPage .g-control-wrap.file-uploader > .gwGroupBox > .panel-body {
  height: calc(100% - 45px);
}
.importTaskPage .g-control-wrap.file-uploader > .gwGroupBox > .panel-body .gwFileUploader {
  height: 100%;
}
.importTaskPage .g-control-wrap.file-uploader > .gwGroupBox > .panel-body .gwFileUploader .gwFileUploader-results-logs {
  height: calc(100% - 90px);
  margin-bottom: 0;
}
.importTaskPage .g-control-wrap .g-import-parameter .gwTextBox {
  width: 100%;
  float: left;
  margin-top: 2px;
}
.importTaskPage .g-control-wrap .g-import-parameter .gwTextBox input {
  width: 100%;
}
.importTaskPage .g-import-tab {
  height: 100%;
}
.importTaskPage .file-uploader {
  padding-top: 11px;
}
.importTaskPage .gwFileUploader {
  width: 100%;
}
.importTaskPage .gwFileUploader-results {
  height: 100%;
}
.importTaskPage .gwFileUploader-results-logs {
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.item-tracking-shell {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.item-tracking-shell h3 {
  margin-top: 0;
  font-size: 15px;
  color: var(--theme-default-button);
  display: inline;
}
.item-tracking-query {
  width: 100%;
  max-width: 1450px;
  height: auto;
  padding: 10px;
}
.item-tracking-control-wrapper {
  max-width: 725px;
}
.item-tracking-map-wrapper {
  width: 100%;
  max-width: 1450px;
  height: 300px;
  padding: 10px;
}
.item-tracking-map {
  height: 100% !important;
  width: 100%;
}
.item-tracking-search-list {
  flex-grow: 1;
  width: 100%;
  max-width: 1450px;
  padding: 10px;
}
.item-tracking-search-list > div {
  height: 100%;
}
.g-dialog-track .item-tracking-map-wrapper {
  width: 1220px;
}
.g-dialog-track .item-tracking-search-list {
  width: 1220px;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.mappingPage {
  margin-right: 0;
}
.mappingPage .g-heading .navbar .dropdown.open .dropdown-menu {
  margin-top: 6px;
}
.mappingPage .g-heading .navbar li .btn i {
  margin-right: 2px;
}
.mappingPage .g-sample-file .gwFileUploader input[type='file'] {
  left: 200px;
}
.mappingPage .gwGroupBox .g-mapping-details {
  width: auto;
}
.mappingPage .modal-dialog.g-modal-autoresize .modal-content {
  top: 10px;
  min-width: 1050px;
}
.mappingPage .modal-dialog.g-modal-autoresize .modal-body {
  padding: 0;
}
.mappingPage .g-row-task-shell {
  height: calc(100% - 39px);
}
.mappingPage .tab-content {
  height: 100%;
  overflow: hidden;
}
.mappingPage .tab-pane {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 10px;
  padding-bottom: 60px;
}
.mappingPage .tab-pane.g-import-preview-tab {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0;
  height: calc(100% - 20px);
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container {
  border-radius: 5px;
  border-width: 1px;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table > .gwGroupBox > .panel-heading {
  height: 24px;
  line-height: normal;
  padding: 0;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  background-color: var(--theme-button);
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table > .gwGroupBox > .panel-heading .panel-title {
  width: 100%;
  font-weight: normal;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table > .gwGroupBox > .panel-heading:hover {
  background-color: var(--primary-button-background-hover-color-start);
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table > .gwGroupBox > .panel-heading:hover a,
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table > .gwGroupBox > .panel-heading:hover i {
  color: #fff;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table > .gwGroupBox > .panel-heading a {
  display: block;
  padding: 0 8px;
  line-height: 22px;
  color: var(--theme-general-text);
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table > .gwGroupBox > .panel-heading a i {
  color: var(--theme-general-text);
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table .panel-body {
  border-radius: 5px;
  height: 300px;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table div[data-contents='gwGroupBox'] {
  padding: 10px;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper .g-preview-table .koGrid {
  height: calc(100% - 40px);
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper:first-child .g-preview-table > .gwGroupBox {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper:first-child .g-preview-table > .gwGroupBox .panel-heading {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper:not(:first-child) .g-preview-table > .gwGroupBox {
  border-top: none;
}
.mappingPage .tab-pane.g-import-preview-tab .g-preview-container .g-preview-wrapper:last-child .g-preview-table > .gwGroupBox {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mappingPage .kgCell > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mappingPage .gwGroupBox .panel-body {
  position: relative;
  margin-top: 0;
}
.mappingPage .mapping-tab .g-mapping-table-container {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
}
.mappingPage .mapping-tab .g-groupbox-wrapper {
  height: 95%;
}
.mappingPage .mapping-tab .gwGroupBox-container {
  height: 100%;
}
.mappingPage .mapping-tab .gwGroupBox-container .panel-body {
  height: 100%;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox {
  overflow-y: auto;
  border-bottom: none;
  background-color: var(--theme-background);
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox > .panel-heading {
  height: 24px;
  line-height: normal;
  padding: 0;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  background-color: var(--theme-button);
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox > .panel-heading .panel-title {
  width: 100%;
  font-weight: normal;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox > .panel-heading:hover {
  background-color: var(--primary-button-background-hover-color-start);
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox > .panel-heading:hover a,
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox > .panel-heading:hover i {
  color: #fff;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox > .panel-heading a {
  display: block;
  padding: 0 8px;
  line-height: 22px;
  color: var(--theme-general-text);
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table > .gwGroupBox > .panel-heading a i {
  color: var(--theme-general-text);
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table.g-first-mapping-table {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table.g-last-mapping-table {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #d3d6d9;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table .panel-title .g-table-title {
  float: left;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table .panel-title .g-remove-table {
  float: right;
  padding-top: 3px;
  display: block;
  color: var(--theme-icons);
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table .panel-title .g-remove-table:hover {
  opacity: 0.5;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table .panel-collapse {
  height: calc(100% - 34px);
  min-height: 300px;
}
.mappingPage .mapping-tab .gwGroupBox-container.g-mapping-table .panel-collapse > .panel-body {
  margin-top: 10px;
  margin-left: 10px;
}
.mappingPage .mapping-tab .gwGroupBox-container[data-collapsible='false'] .panel-heading {
  padding-left: 0;
}
.mappingPage .mapping-tab .gwGroupBox-container[data-collapsible='false'] .panel-heading .panel-title {
  padding-left: 0;
}
.mappingPage .mapping-tab .g-mapping-footer {
  display: flex;
  position: absolute;
  bottom: 10px;
}
.mappingPage .mapping-tab .g-mapping-footer .btn {
  margin-right: 10px;
}
.mappingPage .g-shell-content {
  margin: 0 10px 0 10px;
}
.mappingPage .gwFileUploader .fileinput-wrapper {
  float: right;
  margin-top: -38px;
}
.mappingPage .data-tab > div:first-child {
  height: 38px;
  margin-bottom: 10px;
}
.mappingPage .g-mapping-name,
.mappingPage .g-sample-file,
.mappingPage .gwFileUploader-buttonbar,
.mappingPage .worksheet-selector {
  width: 660px;
}
.mappingPage .worksheet-selector {
  height: 42px;
}
.mappingPage .delimiter-selector,
.mappingPage .header-line-selector {
  float: left;
  width: 330px;
}
.mappingPage .g-include-headers {
  float: left;
  width: 220px;
}
.mappingPage .g-line-delimiter-container {
  height: auto;
  width: 200px;
  margin-bottom: 8px;
  display: inline-block;
}
.mappingPage .g-mapped-table-container {
  margin-bottom: 8px;
  display: inline-block;
}
.mappingPage .sourcePanel {
  width: 30%;
  height: 100%;
}
.mappingPage .sourcePanel .g-mapped-table-container {
  width: 100%;
}
.mappingPage .sourcePanel .panel-buttons {
  margin-top: 18px;
  text-align: left;
}
.mappingPage .sourcePanel .panel-buttons .icon-chevron-left {
  padding-right: 5px;
}
.mappingPage .sourcePanel .panel-buttons .icon-chevron-right {
  padding-left: 5px;
}
.mappingPage .sourcePanel .g-loading-animation {
  position: absolute;
  top: 50%;
  left: 35%;
}
.mappingPage .targetPanel {
  width: 68%;
  height: 100%;
  margin-left: 10px;
}
.mappingPage .targetPanel .g-mapped-table-container {
  width: 450px;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 4px;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-applied {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-actions {
  margin-left: auto;
  flex-shrink: 0;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-icon {
  float: right;
  height: 25px;
  width: 24px;
  padding: 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-icon:hover {
  -webkit-transition: all 0s ease-in;
  -moz-transition: all 0s ease-in;
  -o-transition: all 0s ease-in;
  transition: all 0s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-icon:hover:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-icon:hover:hover {
  opacity: 0.5;
  text-decoration: none;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-icon:hover:active {
  opacity: 0.3;
  text-decoration: none;
}
.mappingPage .targetPanel .g-custom-mapping-list-cell .g-custom-mapping-list-icon i {
  color: var(--theme-icons);
}
.mappingPage .targetPanel .g-matching-mode-cell-container {
  height: 100%;
}
.mappingPage .targetPanel .g-matching-mode-cell-container .gwSearchBox {
  height: 25px;
}
.mappingPage .targetPanel .g-matching-mode-cell-container .gwSearchBox .form-control {
  padding-left: 5px;
  font-size: 12px;
}
.mappingPage .targetPanel .g-matching-mode-cell .select2-selection__rendered {
  padding-left: 4px !important;
}
.mappingPage .targetPanel .g-mapping-default-cell {
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mappingPage .targetPanel .panel-buttons {
  float: right;
  margin-top: 18px;
}
.mappingPage .panel-container {
  display: flex;
}
.mappingPage .panel-container .sourcePanel,
.mappingPage .panel-container .targetPanel {
  flex: 0 1 auto;
}
.mappingPage .panel-container .sourcePanel [data-contents='gwGroupBox'] .g-mapping-grid-container,
.mappingPage .panel-container .targetPanel [data-contents='gwGroupBox'] .g-mapping-grid-container {
  height: 100%;
}
.mappingPage .panel-container .sourcePanel [data-contents='gwGroupBox'] .g-mapping-grid-container .g-mapping-grid,
.mappingPage .panel-container .targetPanel [data-contents='gwGroupBox'] .g-mapping-grid-container .g-mapping-grid {
  height: calc(100% - 30px);
}
.mappingPage .g-mapped-to {
  width: 100%;
  height: 100%;
  padding-top: 4px;
  padding-left: 8px;
}
.mappingPage .g-data-mapping-droppable {
  height: 100%;
}
.mappingPage .g-data-mapping-draggable {
  overflow: hidden;
  height: 100%;
  cursor: move;
}
.mappingPage .g-data-mapping-draggable .g-data-mapping-draggable-field {
  overflow: hidden;
  color: #003479;
}
.mappingPage .g-data-mapping-draggable .g-data-mapping-draggable-header {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.mappingPage .g-data-mapping-draggable .g-data-mapping-draggable-header .g-data-mapping-draggable-inner {
  height: 100%;
  display: flex;
  align-items: center;
}
.mappingPage .g-data-mapping-draggable .g-data-mapping-draggable-column {
  width: 32px;
  height: 20px;
  line-height: 20px;
  background: var(--theme-general-text);
  color: #fff;
  margin: 2px 0 0 2px;
  border-radius: 3px;
  text-align: center;
  float: left;
}
.mappingPage .g-data-mapping-draggable .g-data-mapping-draggable-column .g-data-mapping-draggable-inner {
  line-height: normal;
  padding: 0;
}
.mappingPage .g-data-mapping-draggable.ui-draggable-dragging {
  border: 2px solid var(--grid-viewport-hover-row-color);
  border-radius: 5px;
  background: white;
  height: 30px !important;
}
.mappingPage .g-data-mapping-draggable.ui-draggable-dragging .g-data-mapping-draggable-column {
  margin-top: 3px;
  background: var(--grid-viewport-hover-row-color);
}
.mappingPage .select-table {
  margin-right: 4px;
}
.mappingPage .select-table-wrapper {
  float: right;
  position: relative;
  margin-top: -38px;
}
.mappingPage .kgRow--odd:not(.kgRow--selected):not(.kgRow--hovered) .g-mapping-readonly-cell {
  background-color: var(--grid-readonly-viewport-background-color);
}
.mappingPage .kgRow--even:not(.kgRow--selected):not(.kgRow--hovered) .g-mapping-readonly-cell {
  background-color: var(--grid-readonly-viewport-alternating-color);
}
.mappingPage.importMappingPage .mapping-tab .gwGroupBox .panel-body,
.mappingPage.g-full-page .mapping-tab .gwGroupBox .panel-body {
  padding-bottom: 77px;
}
.g-mapping-default-cell span,
.g-data-mapping-draggable-field {
  padding: 0 8px;
  line-height: 25px;
}
.g-mapping-full-page .g-sidebar {
  top: 27px;
}
.tablePage {
  height: 100%;
}
.tablePage .modal-dialog.g-modal-autoresize .modal-content {
  width: 400px;
  height: 600px;
}
.tablePage .modal-dialog.g-modal-autoresize .modal-content .modal-body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.tablePage .title-container {
  background-color: #f2f2f2;
  padding-left: 6px;
  padding-right: 4px;
  line-height: 30px;
  border-width: 1px 1px 0px 1px;
  border-color: #d3d6d9;
  border-style: solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tablePage .title {
  font-size: 12px;
  color: var(--theme-general-text);
}
.tablePage .g-technical-icon,
.tablePage .g-technical-icon-selected {
  width: 24px;
  height: 24px;
  line-height: 24px;
  float: right;
  border-radius: 4px;
  text-align: center;
  margin-top: 3px;
  display: inline-block;
}
.tablePage .g-technical-icon:hover,
.tablePage .g-technical-icon-selected:hover {
  opacity: 0.5;
  cursor: pointer;
}
.tablePage .g-technical-icon-selected {
  background: var(--theme-default-button);
}
.tablePage .g-technical-name-title {
  display: block;
  font-size: 11px;
  line-height: 14px;
  font-style: italic;
  margin-bottom: 5px;
}
.tablePage .g-technical-name-container > .panel-heading,
.tablePage .g-technical-name-container > .gwGroupBox > .panel-heading {
  height: 100% !important;
}
.tablePage .rootTable.g-collapsed {
  border-bottom: 1px solid #d3d6d9;
}
.tablePage .rootTable > .panel-heading,
.tablePage .rootTable > .gwGroupBox > .panel-heading {
  border-left: none !important;
  border-bottom-left-radius: initial !important;
}
.tablePage .rootTable.firstChild,
.tablePage .rootTable.firstChild > .gwGroupBox {
  border-top: 1px solid #d3d6d9;
  border-bottom: 1px solid #d3d6d9;
}
.tablePage .contents {
  padding-bottom: 4px;
}
.tablePage .gwGroupBox {
  overflow: hidden;
  border-bottom: none;
  border-right: none;
  border-left: none;
}
.tablePage .gwGroupBox > .panel-heading {
  height: 28px;
  line-height: normal;
  padding: 0;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  background-color: var(--theme-button);
}
.tablePage .gwGroupBox > .panel-heading .panel-title {
  width: 100%;
  font-weight: normal;
}
.tablePage .gwGroupBox > .panel-heading:hover {
  background-color: var(--primary-button-background-hover-color-start);
}
.tablePage .gwGroupBox > .panel-heading:hover a,
.tablePage .gwGroupBox > .panel-heading:hover i {
  color: #fff;
}
.tablePage .gwGroupBox > .panel-heading a {
  display: block;
  padding: 0 8px;
  line-height: 26px;
  color: var(--theme-general-text);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tablePage .gwGroupBox > .panel-heading a i {
  color: var(--theme-general-text);
}
.tablePage .gwGroupBox.g-show-collapsible-icon .panel-title a {
  position: relative;
}
.tablePage .gwGroupBox.g-show-collapsible-icon .panel-title a span {
  margin-left: 12px;
}
.tablePage .gwGroupBox.g-show-collapsible-icon .panel-title a .g-collapsible-icon {
  position: absolute;
  top: 50%;
  left: 8px;
  display: block;
  margin-top: -5px;
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}
.tablePage .gwGroupBox.g-show-collapsible-icon .panel-title a .g-collapsible-icon.fa:before {
  content: "\f0de";
}
.tablePage .gwGroupBox.g-show-collapsible-icon .panel-title a.collapsed > .g-collapsible-icon {
  margin-top: -7px;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.tablePage .gwGroupBox > .panel-heading {
  border-bottom: 1px solid #d3d6d9;
  border-left: 1px solid #d3d6d9;
  padding-left: 0px;
}
.tablePage .gwGroupBox > .panel-heading .panel-title {
  padding: 0px;
}
.tablePage .gwGroupBox > .panel-heading .panel-title a span {
  margin-left: 2px;
}
.tablePage .gwGroupBox > .panel-heading .panel-title a > i {
  margin-left: 2px;
}
.tablePage .gwGroupBox > .panel-heading .panel-title a.collapsed > i {
  margin-left: 0px;
}
.tablePage .g-table-group:last-child:not(.rootTable) > .tablePage .g-table-group.g-collapsed .tablePage .g-table-group > .panel-heading {
  border-bottom: initial !important;
}
.tablePage .g-table-group > .panel-heading,
.tablePage .g-table-group > .gwGroupBox > .panel-heading {
  background-color: var(--theme-button-darken15);
  border-bottom-left-radius: 4px;
}
.tablePage .g-table-group > .panel-heading a,
.tablePage .g-table-group > .gwGroupBox > .panel-heading a {
  color: var(--theme-value-text);
}
.tablePage .g-table-group > .panel-heading a i,
.tablePage .g-table-group > .gwGroupBox > .panel-heading a i {
  color: var(--theme-value-text);
}
.tablePage .g-table-group > .panel-heading > .panel-title > a > span > .table-name-header,
.tablePage .g-table-group > .gwGroupBox > .panel-heading > .panel-title > a > span > .table-name-header,
.tablePage .g-table-group > .panel-heading > .panel-title > a > .table-name-header,
.tablePage .g-table-group > .gwGroupBox > .panel-heading > .panel-title > a > .table-name-header {
  float: left;
  margin-left: 12px !important;
}
.tablePage .g-table-group > .panel-heading:hover,
.tablePage .g-table-group > .gwGroupBox > .panel-heading:hover {
  background: var(--theme-hover);
}
.tablePage .g-table-group > .panel-heading:hover a,
.tablePage .g-table-group > .gwGroupBox > .panel-heading:hover a {
  color: var(--theme-hover-text);
}
.tablePage .g-table-group > .panel-heading:hover a i,
.tablePage .g-table-group > .gwGroupBox > .panel-heading:hover a i {
  color: var(--theme-hover-text);
}
.tablePage .g-table-group.g-collapsed > .panel-heading,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading {
  height: 28px;
  line-height: normal;
  padding: 0;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  background-color: var(--theme-button);
}
.tablePage .g-table-group.g-collapsed > .panel-heading .panel-title,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading .panel-title {
  width: 100%;
  font-weight: normal;
}
.tablePage .g-table-group.g-collapsed > .panel-heading:hover,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading:hover {
  background-color: var(--primary-button-background-hover-color-start);
}
.tablePage .g-table-group.g-collapsed > .panel-heading:hover a,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading:hover a,
.tablePage .g-table-group.g-collapsed > .panel-heading:hover i,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading:hover i {
  color: #fff;
}
.tablePage .g-table-group.g-collapsed > .panel-heading a,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading a {
  display: block;
  padding: 0 8px;
  line-height: 26px;
  color: var(--theme-general-text);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tablePage .g-table-group.g-collapsed > .panel-heading a i,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading a i {
  color: var(--theme-general-text);
}
.tablePage .g-table-group.g-collapsed > .panel-heading,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading {
  border-bottom: none;
  border-bottom-left-radius: 0 !important;
}
.tablePage .g-table-group.g-collapsed > .panel-heading:hover,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading:hover {
  background-color: var(--primary-button-background-hover-color-start);
}
.tablePage .g-table-group.g-collapsed > .panel-heading:hover a,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading:hover a {
  color: var(--theme-hover-text);
}
.tablePage .g-table-group.g-collapsed > .panel-heading:hover a i,
.tablePage .g-table-group.g-collapsed > .gwGroupBox > .panel-heading:hover a i {
  color: var(--theme-hover-text);
}
.tablePage .g-table-group-nochild > .panel-heading {
  height: 28px;
  line-height: normal;
  padding: 0;
  border-bottom: 1px solid var(--sidebar-panel-border-color);
  background-color: var(--theme-button);
}
.tablePage .g-table-group-nochild > .panel-heading .panel-title {
  width: 100%;
  font-weight: normal;
}
.tablePage .g-table-group-nochild > .panel-heading:hover {
  background-color: var(--primary-button-background-hover-color-start);
}
.tablePage .g-table-group-nochild > .panel-heading:hover a,
.tablePage .g-table-group-nochild > .panel-heading:hover i {
  color: #fff;
}
.tablePage .g-table-group-nochild > .panel-heading a {
  display: block;
  padding: 0 8px;
  line-height: 26px;
  color: var(--theme-general-text);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tablePage .g-table-group-nochild > .panel-heading a i {
  color: var(--theme-general-text);
}
.tablePage .g-table-group-nochild > .panel-heading,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading {
  border-bottom: none;
  background-color: #fafafa;
}
.tablePage .g-table-group-nochild > .panel-heading .table-name-header,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading .table-name-header {
  display: inline-block;
}
.tablePage .g-table-group-nochild > .panel-heading .g-technical-name-title,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading .g-technical-name-title {
  padding-left: 0px;
}
.tablePage .g-table-group-nochild > .panel-heading .g-table-title-nochildren,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading .g-table-title-nochildren {
  color: var(--theme-value-text);
}
.tablePage .g-table-group-nochild > .panel-heading:hover,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading:hover {
  background: var(--theme-hover);
}
.tablePage .g-table-group-nochild > .panel-heading:hover .g-table-title-nochildren,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading:hover .g-table-title-nochildren,
.tablePage .g-table-group-nochild > .panel-heading:hover .g-technical-name-title,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading:hover .g-technical-name-title,
.tablePage .g-table-group-nochild > .panel-heading:hover i,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading:hover i {
  color: var(--theme-hover-text);
}
.tablePage .g-table-group-nochild > .panel-heading:hover .g-select-table-icon,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading:hover .g-select-table-icon {
  visibility: hidden;
  height: 0px;
  width: 0px;
}
.tablePage .g-table-group-nochild > .panel-heading:hover .g-select-table-icon-hover,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading:hover .g-select-table-icon-hover {
  visibility: visible;
}
.tablePage .firstChild,
.tablePage .firstChild > .gwGroupBox {
  border-top: none;
}
.tablePage .gwGroupBoxSelectedTable > .panel-heading,
.tablePage .gwGroupBoxSelectedTable > .gwGroupBox > .panel-heading {
  background-color: var(--grid-viewport-selected-row-color);
}
.tablePage .g-mapping-table-container {
  border-width: 0 1px 1px 1px;
  border-color: var(--panel-border-color);
  border-style: solid;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--accordion-background-color);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* Firefox */
  height: -moz-calc(100% - 30px);
  /* WebKit */
  height: -webkit-calc(100% - 30px);
  /* Opera */
  height: -o-calc(100% - 30px);
  /* Standard */
  height: calc(100% - 30px);
}
.tablePage .g-table-group > .panel-collapse > .panel-body {
  position: relative;
  margin-top: 0;
}
.tablePage .g-table-group-nochild .panel-collapse > .panel-body {
  visibility: hidden !important;
}
.tablePage .g-table-group-nochild > .panel-heading > .panel-title > a > i:before,
.tablePage .g-table-group-nochild > .gwGroupBox > .panel-heading > .panel-title > a > i:before {
  visibility: hidden !important;
}
.tablePage .g-shell-content {
  margin: 0 10px;
  font-size: 16px;
}
.tablePage .g-select-table-icon {
  float: right;
  margin: 4px 0 0 0;
  color: var(--icons-color);
}
.tablePage .g-select-table-icon-hover {
  float: right;
  margin: 4px 0 0 0;
  color: var(--theme-hover-text);
  visibility: hidden;
}
.tablePage .indentedGroupBox {
  margin-left: 20px;
}
.tablePage .indentedGroupBox > .g-groupbox-wrapper:last-child > .gwGroupBox > .panel-heading,
.tablePage .indentedGroupBox > .g-groupbox-wrapper:last-child > .gwGroupBox-container > .gwGroupBox > .panel-heading {
  border-bottom-left-radius: 4px !important;
  border-bottom: 1px solid #d3d6d9;
}
.g-search-result-key-fields {
  color: var(--theme-field-title);
  width: calc(100% - 90px);
}
.g-search-result-key-fields li {
  display: inline-block;
  min-width: 300px;
  margin-right: 10px;
  vertical-align: top;
}
.g-search-result-key-fields li .key-field-value {
  color: var(--theme-value-text);
}
.g-search-result-key-fields li .key-field-heading {
  text-decoration: none;
}
.g-search-result-key-fields li .key-field-heading .key-field-value {
  color: var(--theme-default-button);
}
.g-search-result-key-fields li .key-field-heading .key-field-value:hover {
  color: var(--theme-field-title);
}
.g-search-result-key-fields li span {
  display: inline;
  vertical-align: top;
  line-height: 20px;
}
.g-search-result-key-fields li:first-child {
  display: block;
  font-size: 16px;
  font-weight: bold;
}
.g-global-search-results {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  border: 1px solid #d3d6d9;
  height: 100%;
  width: 100%;
}
.g-global-search-results ul {
  margin: 0;
}
.g-search-result-info-container {
  padding: 3px 7px;
  text-align: center;
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
}
.g-search-result-last-event {
  padding: 3px 7px;
  background: var(--grid-readonly-viewport-alternating-color);
  border: 1px solid #C4C7C8;
  border-radius: 4px;
}
.g-search-result-last-event .g-last-event-desc {
  font-size: 15px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-search-result-last-event .g-last-event-details {
  font-size: 12px;
  color: var(--theme-field-title);
}
.g-search-result-last-event .g-last-event-date {
  font-weight: bold;
  color: var(--grid-title-font-color);
}
.g-search-results-items > li.focused-text {
  color: #fff;
}
.g-search-results-items > li {
  padding: 6px;
  height: auto;
  background-color: #fff;
}
.g-search-results-items > li + li {
  border-top: 1px solid #d3d6d9;
}
.g-search-results-items > li:last-child {
  border-bottom: 1px solid #d3d6d9;
}
.g-search-results-items > li.g-info-card-field-container {
  padding: 0;
}
.g-search-results-items > li.g-info-card-field-container .entity-and-key-fields-container {
  display: flex;
  border-radius: 0;
}
.g-search-results-items > li.g-info-card-field-container .g-card-actions {
  width: 31px;
  border-right: 1px solid #d3d6d9;
  align-self: stretch;
  flex: none;
  color: var(--theme-icons);
}
.g-search-results-items > li.g-info-card-field-container .g-card-actions > button {
  background-color: transparent;
}
.g-search-results-items > li.g-info-card-field-container .g-card-key-field {
  color: var(--theme-icons);
  font-size: 14px;
  padding: 8px;
}
.g-search-results-items > li.g-info-card-field-container .g-card-key-field > ul {
  padding: 0;
}
.g-search-results-items > li.g-info-card-field-container .g-card-sub-heading {
  font-size: 12px;
  color: var(--theme-field-title);
}
.g-search-results-items > li .gwTrackingProgress__mode {
  cursor: default;
}
.g-search-results-items > li .gwTrackingProgress__mode:hover {
  opacity: 1;
}
.g-search-results-items > li.g-tracking-search-result h3 {
  display: inline;
  margin-top: 0;
  font-size: 15px;
  color: var(--theme-default-button);
}
.g-search-results-items > li.g-tracking-search-result .g-grid-action-button {
  width: auto;
  height: auto;
  padding: 4px;
  display: inline-block;
}
.g-search-results-items > li.g-tracking-search-result .entity-and-key-fields-container {
  position: relative;
}
.g-search-results-items > li.g-tracking-search-result .entity-img-container {
  flex-basis: 22px;
  position: static;
  top: 0;
  right: auto;
  align-self: auto;
}
.g-search-results-items > li.g-tracking-search-result .hide-img-container .entity-img-container {
  display: none;
}
.g-search-results-items > li.g-tracking-search-result .hide-img-container .g-search-result-key-fields {
  margin-left: 5px;
}
.g-search-results-items > li.g-tracking-search-result > .g-search-results-selected {
  width: 100%;
  margin: 7px 0 0;
  border: none;
  padding: 5px;
}
.g-search-results-items > li.g-tracking-search-result.focused {
  background-color: var(--grid-viewport-selected-row-color);
}
.g-search-results-items > li.g-tracking-search-result:hover {
  background-color: #CDE6F7;
}
.g-search-results-items > li .g-search-results-events-container {
  position: relative;
  height: auto;
}
.g-search-results-items > li .entity-and-key-fields-container {
  display: flex;
}
.g-search-results-items > li .entity-img-container {
  flex-basis: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: left;
  align-self: center;
}
.g-search-results-items > li i {
  color: var(--icons-color);
  cursor: pointer;
}
.g-search-results-items > li i:hover {
  opacity: 0.5;
}
.g-search-results-items > li .g-search-results-expand {
  margin-top: auto;
  text-align: center;
}
.g-search-results-items > li .g-search-results-selected {
  border: 1px solid #d3d6d9;
  border-radius: 4px;
  padding: 0;
  margin: 15px 5px 5px 5px;
  width: calc(100% -  10px);
  height: calc(100% -  10px);
  background: #fff;
}
.g-search-results-items .g-search-results .dropdown-menu {
  background-color: var(--grid-header-background-color-start);
}
.g-search-results-items .g-search-results .dropdown-menu a {
  width: 100%;
}
.g-search-results-items .g-search-results .dropdown-menu > li > a {
  color: #FFFFFF;
}
.g-search-results-items .g-tracking-event-container {
  width: 100%;
}
.g-search-results-items .g-tracking-event-container li {
  padding: 4px 0;
}
.g-search-results-items .g-tracking-event-container li:hover {
  background-color: #CDE6F7;
  border-radius: 4px;
}
.g-search-results-items .g-tracking-event-container .g-tracking-event {
  display: flex;
}
.g-search-results-items .g-tracking-event-container .g-tracking-event .g-tracking-event-left {
  display: block;
  min-width: 105px;
  border-right: 1px solid #C4C7C8;
}
.g-search-results-items .g-tracking-event-container .g-tracking-event .g-tracking-event-right {
  display: block;
}
.g-search-results-items .g-tracking-event-container .g-tracking-event .g-tracking-event-time {
  display: inline-block;
  color: var(--theme-value-text);
  width: 100%;
  text-align: right;
  padding-right: 4px;
}
.g-search-results-items .g-tracking-event-container .g-tracking-event .g-tracking-event-desc {
  display: inline-block;
  padding-left: 4px;
  font-weight: bold;
}
.g-search-results-items .g-tracking-event-container .g-tracking-event .g-tracking-event-details {
  display: block;
  padding-left: 4px;
}
.g-search-results-items .g-tracking-item,
.g-search-results-items .g-tracking-group-item {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  vertical-align: top;
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #d3d6d9;
  font-size: 13px;
}
.g-search-results-items .g-tracking-item.focused,
.g-search-results-items .g-tracking-group-item.focused {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.g-search-results-items .g-tracking-item:not(.focused):hover,
.g-search-results-items .g-tracking-group-item:not(.focused):hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.g-search-results-items .g-tracking-item {
  min-width: 1170px;
}
.g-search-results-items .g-tracking-group-item__master-key-field {
  padding-right: 10px;
}
.g-search-results-items .g-tracking-group-item__master-key-field:not(:last-child) {
  padding-bottom: 10px;
}
.g-search-results-items .g-tracking-group-item__address {
  font-family: 'Noto Sans Condensed';
}
.g-search-results-items .g-tracking-group-item__address-company {
  font-size: 17px;
  font-weight: bold;
  color: var(--theme-default-button);
}
.g-search-results-items .g-tracking-group-item__address-company,
.g-search-results-items .g-tracking-group-item__address-line1,
.g-search-results-items .g-tracking-group-item__address-line2,
.g-search-results-items .g-tracking-group-item__address-line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-search-results-items .g-tracking-group-item__master .gwFlag {
  width: 32px;
  height: 25px;
}
.g-search-results-items .g-tracking-group-item__master .gwFlag .flag-icon {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.g-search-results-items .g-tracking-group-item__master {
  flex: 1;
  min-width: 235px;
  border-right: 1px solid #d3d6d9;
}
.g-search-results-items .g-tracking-group-item__details {
  display: flex;
  flex: 4;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: start;
  padding: 0 10px 8px;
}
.g-search-results-items .g-tracking-group-item__child {
  display: flex;
  border-bottom: 1px solid #d3d6d9;
  padding: 5px 0;
}
.g-search-results-items .g-tracking-group-item__child:last-child {
  border-bottom: none;
}
.g-search-results-items .g-tracking-group-item__child.buttons-only .g-tracking-group-item__child-actions {
  width: 40px;
}
.g-search-results-items .g-tracking-group-item__child.buttons-only .g-tracking-group-item__child-actions span {
  display: none;
}
.g-search-results-items .g-tracking-group-item__child.text-only .g-tracking-group-item__child-actions {
  width: 100px;
}
.g-search-results-items .g-tracking-group-item__child.text-only .g-tracking-group-item__child-actions i {
  display: none;
}
.g-search-results-items .g-tracking-group-item__child.text-only .g-tracking-group-item__child-actions .btn span {
  margin-left: 0;
}
.g-search-results-items .g-tracking-group-item__child-field {
  width: 150px;
  padding-left: 5px;
  padding-right: 5px;
}
.g-search-results-items .g-tracking-group-item__child--padding-right {
  padding-right: 130px;
}
.g-search-results-items .g-tracking-group-item__child--padding-right.buttons-only {
  padding-right: 40px;
}
.g-search-results-items .g-tracking-group-item__child--padding-right.text-only {
  padding-right: 100px;
}
.g-search-results-items .g-tracking-group-item__child-actions {
  position: absolute;
  right: 15px;
  width: 130px;
  height: 20px;
  margin-top: -1px;
  padding-right: 5px;
  padding-left: 5px;
  background: #fff;
  text-align: center;
  border-left: 1px solid #d3d6d9;
}
.g-search-results-items .g-tracking-group-item__child-actions .btn {
  display: block;
  padding: 0 6px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-search-results-items .g-tracking-group-item__child-actions .btn span {
  margin-left: 5px;
}
.g-search-results-items .g-tracking-group-item__child-actions .btn i {
  font-size: 12px;
}
.g-search-results-items .g-tracking-group-item__child-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-search-results-items .g-tracking-group-item__child-value .key-field-value:empty:after {
  content: '\200b';
}
.g-search-results-items .g-tracking-item__key-field-heading {
  text-decoration: none;
  color: var(--theme-default-button);
}
.g-search-results-items .g-tracking-item__key-field-heading:hover {
  color: var(--theme-field-title);
}
.g-search-results-items .g-tracking-item__key-fields {
  flex: 1;
  min-width: 235px;
  border-right: 1px solid #d3d6d9;
}
.g-search-results-items .g-tracking-item__key-fields a {
  font-weight: bold;
  font-size: 16px;
}
.g-search-results-items .g-tracking-item__key-fields-featured,
.g-search-results-items .g-tracking-item__key-fields-additional {
  display: flex;
  margin: 3px 0;
  justify-content: space-between;
}
.g-search-results-items .g-tracking-item__key-fields a,
.g-search-results-items .g-tracking-item__key-fields-featured,
.g-search-results-items .g-tracking-item__key-fields-additional {
  padding-right: 10px;
}
.g-search-results-items .g-tracking-item__key {
  width: 120px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Noto Sans Condensed';
  font-size: 12px;
}
.g-search-results-items .g-tracking-item__value {
  flex: 1;
  margin-left: 10px;
  color: var(--theme-default-button);
}
.g-search-results-items .g-tracking-item__details {
  display: flex;
  flex: 3;
  flex-wrap: wrap;
  align-content: start;
}
.g-search-results-items .g-tracking-item__progress {
  width: 600px;
  padding: 0 15px;
  flex: 2;
}
.g-search-results-items .g-tracking-item__expand {
  position: absolute;
  right: 15px;
  bottom: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid var(--theme-default-button);
  border-width: 0 3px 3px 0;
  padding: 3px;
  cursor: pointer;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
  -o-transition: 0.35s ease-out;
  transition: 0.35s ease-out;
}
.g-search-results-items .g-tracking-item__events {
  flex: 1;
  min-width: 235px;
  padding-left: 15px;
  border-left: 1px solid #d3d6d9;
}
.g-search-results-items .g-tracking-item__events-history {
  width: 100%;
  padding: 15px 0;
  margin: 15px 0 0 15px;
  border-top: 1px solid #d3d6d9;
}
.g-search-results-items .g-tracking-item__event {
  display: flex;
  padding: 3px 0;
  border-top: 1px solid #d3d6d9;
}
.g-search-results-items .g-tracking-item__event-date {
  padding-right: 4px;
  min-width: 65px;
  text-align: right;
}
.g-search-results-items .g-tracking-item__event-time {
  padding-right: 4px;
  min-width: 40px;
  text-align: right;
  opacity: 0.4;
}
.g-search-results-items .g-tracking-item__event-description {
  padding-left: 4px;
  border-left: 1px solid #d3d6d9;
}
.g-search-results-items .g-tracking-item__event-details {
  padding-left: 4px;
  flex: 1;
}
.g-search-results-items .g-tracking-item__event-user {
  padding-left: 4px;
  border-left: 1px solid #d3d6d9;
  text-align: right;
  opacity: 0.4;
  flex: 0 0 170px;
}
.g-search-results-items .g-tracking-item__last-event {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.g-search-results-items .g-tracking-item__last-event-description {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #d3d6d9;
  font-weight: bold;
  font-family: 'Noto Sans Condensed';
}
.g-padding-bottom-none {
  padding-bottom: 0 !important;
}
.g-modal-workflow,
.g-popout-workflow {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.g-workflow-sidebar {
  margin-right: 10px;
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.g-workflow-sidebar .g-workflow-default-sidebar {
  overflow-y: auto;
  margin-top: 42px;
}
.g-workflow-main {
  height: 100%;
  flex-grow: 1;
}
.g-workflow-main .g-container {
  height: 100%;
}
.g-workflow-main .tox-tinymce {
  height: calc(100% - 1px) !important;
}
.g-workflow-events-add-modal .modal-dialog.g-modal-autoresize .modal-content {
  width: 100%;
  height: 100%;
  min-width: 350px;
  max-width: 500px;
  min-height: 265px;
  max-height: 300px;
}
.g-workflow-events-add-modal .modal-body ul li {
  height: 42px;
}
.g-workflow-events-add-modal .modal-body ul li .g-container {
  height: 100%;
}
.g-workflow-exceptions .gwGroupBox-container {
  height: 50%;
}
.g-workflow-milestones .g-workflow-sidebar .g-workflow-default-sidebar {
  margin-top: 5px;
}
.g-workflow-milestones .g-workflow-milestones-main {
  height: 100%;
}
.g-workflow-milestones .g-workflow-milestones-main.g-show-trigger-actions .gwGroupBox-container {
  height: 50%;
}
.g-workflow-milestones .g-last-milestone-event {
  overflow: hidden;
  width: 100%;
  text-align: center;
  background: #d9d9d9;
  border-radius: 4px;
  z-index: 1;
}
.g-workflow-milestones .g-last-milestone-event span {
  display: block;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.g-workflow-milestones .g-last-milestone-event span:first-child {
  font-size: 16px;
  font-weight: bold;
}
.g-workflow-milestones .g-last-milestone-event span:last-child {
  color: var(--grid-title-font-color);
  text-transform: uppercase;
}
.g-workflow-tasks .gwGroupBox-container {
  height: calc(50% - 42px);
}
.g-workflow-tasks .gwGroupBox-container:first-child {
  height: 84px;
}
.g-workflow-tasks.g-workflow-headers-unsupported .g-workflow-sidebar {
  display: none;
}
.g-workflow-tasks.g-workflow-headers-unsupported .g-workflow-main {
  margin-left: 0;
}
.g-workflow-tasks li.active .g-menu-item-icon {
  color: #fff;
}
.g-workflow-tasks .g-workflow-sidebar .gwGroupBox-container {
  height: 100%;
}
.g-workflow-tasks .g-workflow-sidebar .gwGroupBox-container .panel-body {
  overflow-y: auto;
}
.g-workflow-tasks .g-workflow-sidebar .g-menu-item-target {
  display: flex;
  align-items: center;
  height: 40px;
}
.g-workflow-tasks .g-workflow-sidebar .g-menu-item-target:hover .g-menu-item-icon {
  opacity: 1;
}
.g-workflow-details {
  display: flex;
  height: 100%;
}
.g-workflow-details > .g-container {
  flex: 1;
}
.g-workflow-details .g-checkbox-container,
.g-workflow-details .gwCheckBox {
  min-width: 120px;
}
.g-workflow-details .gwCheckBox__box {
  min-width: 66px;
}
.g-workflow-triggers .gwGroupBox-container {
  height: 50%;
}
#importPage .g-import-controls {
  padding: 10px;
  width: 530px;
  float: left;
  margin-right: 90px;
}
#importPage .g-import-controls .g-import-input .select2-selection {
  min-height: 42px;
}
#importPage .g-import-controls .gwGroupBox .panel-body {
  position: relative;
  margin-top: 0;
}
#importPage .g-import-controls .gwGroupBox div[data-contents='gwGroupBox'] {
  padding-left: 10px;
}
#importPage .g-import-controls .gwValidation-highlight {
  background: #ffb0b0;
}
#importPage .g-import-controls .gwValidation-highlight .select2-choice {
  background: #ffb0b0;
}
#importPage .g-import-controls .g-import-input {
  width: 500px;
}
#importPage .g-import-controls .g-import-input .g-sample-file {
  position: relative;
}
#importPage .g-import-controls .g-import-input .g-sample-file .gwFileUploaderInput {
  height: 100%;
  padding-top: 18px;
  margin-bottom: 0;
}
#importPage .g-import-controls .g-import-input .g-sample-file .gwFileUploader {
  position: absolute;
  top: 5px;
  right: 0;
}
#importPage .g-import-controls .g-import-buttons {
  margin-top: 10px;
  float: right;
}
#importPage .g-import-controls .g-import-buttons .btn {
  width: 150px;
}
#importPage + .g-sidebar {
  top: 3px;
}
.g-notes-modal {
  height: 100%;
}
.g-notes-modal .gwRichTextEditor {
  position: relative;
}
.g-notes-modal .gwRichTextEditor .tox-tinymce {
  height: 100% !important;
}
#gNotesDialogGrid .gwDataGrid {
  position: relative;
}
#gNotesDialogGrid {
  height: 50%;
  min-height: 200px;
}
#gNotesDialogText,
#gNotesDialogData {
  height: calc(50% - 25px);
  margin-top: 10px;
  margin-bottom: 15px;
  min-height: 150px;
}
.android__controls {
  padding: 10px;
  width: 640px;
  margin-right: 90px;
}
.android__input {
  width: 500px;
  margin-top: 10px;
  position: relative;
}
.android__input .select2-selection {
  min-height: 42px;
}
.android__warning {
  font-weight: bold;
}
.android__warningLink {
  font-weight: bold;
  font-size: 20px;
}
.android__note {
  font-weight: bold;
  margin-top: 10px;
}
.android__topMargin {
  margin-top: 20px;
}
.android__ol {
  list-style: none;
  counter-reset: steps;
}
.android__li {
  counter-increment: steps;
  margin-top: 20px;
}
.android__li:before {
  content: counter(steps);
  font-weight: bold;
  float: left;
  margin-right: 15px;
  font-size: 15px;
}
.android__triStateRadioButton {
  margin-left: 15px;
}
.android__step {
  overflow-x: hidden;
}
.android__error {
  color: #ff0000;
  margin-left: 22px;
  margin-top: 15px;
}
.android__flexstep {
  display: flex;
}
.android__barcode {
  margin-left: 24px;
}
.android__checkBoxStep {
  display: flex;
  margin-top: 10px;
}
.android__multierror {
  width: 500px;
}
.android__multierror .gwErrorLabel-container:not(:first-of-type) .gwErrorLabel-wrapper {
  border-top: none;
}
.android__multierror .gwErrorLabel-container:not(:last-of-type) .gwErrorLabel-wrapper {
  border-bottom: none;
}
.user-agreement {
  display: flex;
  flex-direction: column;
  min-width: 275px;
  max-width: 1000px;
  min-height: 400px;
  height: calc(100% - 42px);
  margin-left: auto;
  margin-right: auto;
}
.user-agreement-header {
  margin-top: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}
.user-agreement-header__text {
  color: var(--body-text-color);
  font-size: 22px;
  line-height: 26px;
}
.user-agreement-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
  height: calc(100% - 42px);
}
.user-agreement-content__text {
  flex: 1;
  margin: 5px;
  color: var(--body-text-color);
  overflow-y: auto;
  white-space: pre-line;
}
.user-agreement-content__checkbox {
  margin: 5px;
  margin-left: auto;
  width: 275px;
  height: 42px;
}
.user-agreement-content__button {
  margin: 5px;
  margin-left: auto;
  width: 150px;
  height: 42px;
}
.reset-password-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-password-container > div {
  max-width: 500px;
}
.g-pave {
  display: flex;
  font-size: 13px;
}
.g-pave .g-board {
  height: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.g-pave .g-board .g-board-sections {
  display: grid;
  width: 100%;
  height: 100%;
}
.g-pave .g-board .g-board-sections .g-section-container {
  padding: 5px;
  overflow: auto;
  height: auto;
  width: auto;
}
.g-pave-unsupported {
  color: #d3d6d9;
  background-color: #fff;
  border: 1px solid #d3d6d9;
  border-radius: 4px;
  padding: 15px;
  overflow: auto;
  text-align: center;
}
.g-pave-section-index-font {
  font-size: 14px;
}
.g-pave-section-zone-font {
  font-size: 14px;
}
.g-duration {
  display: flex;
  align-items: baseline;
}
.g-duration .g-duration-label {
  display: inline-block;
  font-size: 10px;
}
.g-duration .g-duration-label:not(:last-child) {
  margin-right: 4px;
}
.g-duration .g-duration-value {
  display: inline-block;
}
.g-title {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--header-background-color-start);
  font-weight: 700;
}
.g-pave-button {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: var(--header-background-color-start);
  background-color: #fff;
  border-radius: 5px;
  padding: 3px 7px;
  min-width: 24px;
  font-weight: 700;
  z-index: 1;
  font-size: 12px;
}
.g-pave-button > i {
  font-size: 14px;
}
.g-pave-button:hover,
.g-pave-button:active,
.g-pave-button.popover-shown,
.g-pave-button.in-meeting-mode {
  cursor: pointer;
  background: var(--header-background-color-start);
  color: white;
}
.g-pave-button:active,
.g-pave-button.popover-shown,
.g-pave-button.in-meeting-mode {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset, 0 0px 6px rgba(0, 0, 0, 0.16) inset;
}
.g-pave-icon-button {
  color: #fff;
  cursor: pointer;
  display: flex;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.g-pave-icon-button > i {
  font-size: 14px;
}
.g-pave-icon-button:hover {
  opacity: 0.5;
}
.g-pave-icon-button:active {
  opacity: 0.2;
}
.g-pave-action-state-icon {
  margin-right: 10px;
  opacity: 0.85;
}
.g-pave-unsupported {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 24px;
}
.g-pave-unsupported i {
  font-size: 24px;
  margin-right: 10px;
}
.g-pave-health-risk-excellent {
  background-color: #C3F0C3;
  border-color: #C3F0C3;
}
.g-pave-health-risk-good {
  background-color: #BBDDFD;
  border-color: #BBDDFD;
}
.g-pave-health-risk-caution {
  background-color: #FFE164;
  border-color: #FFE164;
}
.g-pave-health-risk-bad {
  background-color: #f99495;
  border-color: #f99495;
}
.g-pave-health-risk-alert {
  background-color: #ffaa33;
  border-color: #ffaa33;
}
.g-pave-health-risk-none {
  background-color: #d3d6d9;
}
.g-health-bar {
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  overflow: hidden;
  flex: 0 0 20px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  height: 22px;
  background-color: #fff;
  border: 1px solid #d3d6d9;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.g-health-bar .g-health-bar-capacity-fill {
  background-color: #d3d6d9;
  -webkit-transition: width 0.5s ease-out, height 0.5s ease-out, background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  -moz-transition: width 0.5s ease-out, height 0.5s ease-out, background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  -o-transition: width 0.5s ease-out, height 0.5s ease-out, background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  transition: width 0.5s ease-out, height 0.5s ease-out, background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
.g-health-bar .g-health-bar-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 5px;
  min-width: 0;
}
.g-health-bar .g-health-bar-text > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-health-bar.g-channel-health-bar {
  cursor: pointer;
}
.g-health-bar.g-channel-health-bar:focus,
.g-health-bar.g-channel-health-bar:active,
.g-health-bar.g-channel-health-bar.popover-shown {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset, 0 0px 6px rgba(0, 0, 0, 0.16) inset;
}
.g-health-bar.g-channel-health-bar:focus .g-health-bar-capacity-fill,
.g-health-bar.g-channel-health-bar:active .g-health-bar-capacity-fill,
.g-health-bar.g-channel-health-bar.popover-shown .g-health-bar-capacity-fill {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset, 0 0px 6px rgba(0, 0, 0, 0.16) inset;
}
.g-health-bar.g-pave-health-risk-excellent {
  background-color: #e4f8e4 !important;
}
.g-health-bar.g-pave-health-risk-excellent .g-health-bar-capacity-fill {
  background-color: #C3F0C3;
}
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar:hover,
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar:focus,
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar:active,
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar.popover-shown {
  background: #c7f1c7 !important;
}
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar:hover .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar:focus .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar:active .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-excellent.g-channel-health-bar.popover-shown .g-health-bar-capacity-fill {
  background-color: #a6e9a6;
}
.g-health-bar.g-pave-health-risk-good {
  background-color: #deeefe !important;
}
.g-health-bar.g-pave-health-risk-good .g-health-bar-capacity-fill {
  background-color: #BBDDFD;
}
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar:hover,
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar:focus,
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar:active,
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar.popover-shown {
  background: #bbddfd !important;
}
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar:hover .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar:focus .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar:active .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-good.g-channel-health-bar.popover-shown .g-health-bar-capacity-fill {
  background-color: #98ccfc;
}
.g-health-bar.g-pave-health-risk-caution {
  background-color: #fff3c0 !important;
}
.g-health-bar.g-pave-health-risk-caution .g-health-bar-capacity-fill {
  background-color: #FFE164;
}
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar:hover,
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar:focus,
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar:active,
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar.popover-shown {
  background: #ffec9c !important;
}
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar:hover .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar:focus .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar:active .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-caution.g-channel-health-bar.popover-shown .g-health-bar-capacity-fill {
  background-color: #ffda40;
}
.g-health-bar.g-pave-health-risk-bad {
  background-color: #fdd3d3 !important;
}
.g-health-bar.g-pave-health-risk-bad .g-health-bar-capacity-fill {
  background-color: #F98A8B;
}
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar:hover,
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar:focus,
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar:active,
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar.popover-shown {
  background: #fbb1b1 !important;
}
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar:hover .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar:focus .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar:active .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-bad.g-channel-health-bar.popover-shown .g-health-bar-capacity-fill {
  background-color: #f76869;
}
.g-health-bar.g-pave-health-risk-alert {
  background-color: #ffd499 !important;
}
.g-health-bar.g-pave-health-risk-alert .g-health-bar-capacity-fill {
  background-color: #ffaa33;
}
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar:hover,
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar:focus,
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar:active,
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar.popover-shown {
  background: #ffc675 !important;
}
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar:hover .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar:focus .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar:active .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-alert.g-channel-health-bar.popover-shown .g-health-bar-capacity-fill {
  background-color: #ff9b0f;
}
.g-health-bar.g-pave-health-risk-none {
  background-color: #e9eaec !important;
}
.g-health-bar.g-pave-health-risk-none .g-health-bar-capacity-fill {
  background-color: #d3d6d9;
}
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar:hover,
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar:focus,
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar:active,
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar.popover-shown {
  background: #d6d9db !important;
}
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar:hover .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar:focus .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar:active .g-health-bar-capacity-fill,
.g-health-bar.g-pave-health-risk-none.g-channel-health-bar.popover-shown .g-health-bar-capacity-fill {
  background-color: #c0c4c8;
}
.g-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  --g-section-header-space-display: initial;
}
.g-section.BUF {
  --g-channel-header-size: 75px;
  --g-channel-header-info-container-size: 300px;
}
.g-section.BUC {
  --g-channel-header-size: 55px;
  --g-channel-header-info-container-size: auto;
}
.g-section.NOT {
  --g-section-header-space-display: none;
}
.g-section .g-section-flow-up {
  --g-section-flow-direction: column;
  --g-section-flow-justify-content: flex-start;
}
.g-section .g-section-flow-down {
  --g-section-flow-direction: column-reverse;
  --g-section-flow-justify-content: flex-end;
}
.g-section .g-section-flow-left {
  --g-section-flow-direction: row;
  --g-section-flow-justify-content: flex-start;
}
.g-section .g-section-flow-right {
  --g-section-flow-direction: row-reverse;
  --g-section-flow-justify-content: flex-end;
}
.g-section .g-section-filter-launcher.active,
.g-section .g-section-ticket-size-selection.active,
.g-section .g-section-reset-layout-button.active {
  background-color: var(--theme-icons);
  color: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-section .g-section-filter-launcher.active:focus,
.g-section .g-section-ticket-size-selection.active:focus,
.g-section .g-section-reset-layout-button.active:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-section .g-section-filter-launcher.active:hover,
.g-section .g-section-ticket-size-selection.active:hover,
.g-section .g-section-reset-layout-button.active:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-section .g-section-filter-launcher.active:active,
.g-section .g-section-ticket-size-selection.active:active,
.g-section .g-section-reset-layout-button.active:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-section .g-section-reset-layout-button {
  pointer-events: none;
  color: gray;
}
.g-section .g-section-reset-layout-button.active {
  pointer-events: auto;
}
.g-section .g-section-header {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  flex: 0 0 auto;
}
.g-section .g-section-header .g-section-acceptability-bands {
  flex-grow: 1;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
  margin-bottom: -4px;
  min-width: 60px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.g-section .g-section-header .g-section-acceptability-bands::-webkit-scrollbar {
  display: none;
}
.g-section .g-section-header .g-section-acceptability-bands .g-acceptability-band.overflow {
  position: absolute;
  visibility: hidden;
  top: -1000px;
  left: 0;
}
.g-section .g-section-header .g-section-controls {
  display: flex;
  margin-left: auto;
}
.g-section .g-section-header .g-section-controls .g-container {
  padding: 0 1px;
}
.g-section .g-section-header > .g-title {
  margin: 0;
  line-height: initial;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-section .g-section-grid {
  overflow: auto;
  display: flex;
  flex: 1 0 0%;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
}
.g-section .g-section-grid.g-section-flow-vertical {
  --g-section-grid-contents-direction: row;
  --g-section-cells-direction: column;
  --g-section-grid-channel-container-min-size: 132px;
}
.g-section .g-section-grid.g-section-flow-horizontal {
  --g-section-grid-contents-direction: column;
  --g-section-cells-direction: row;
  --g-section-grid-channel-container-min-size: 132px;
}
.g-section .g-section-grid .g-section-grid-contents {
  display: flex;
  flex: 1 0 0%;
  overflow: auto;
  flex-direction: var(--g-section-grid-contents-direction);
}
@media not all and (min-resolution: 0.001dpcm) {
  .g-section .g-section-grid {
    /* CSS Statements that only apply to Safari 11+ */
  }
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .g-section .g-section-grid .g-section-grid-contents {
      overflow: visible;
    }
  }
}
.g-section .g-section-grid .g-section-index-headers,
.g-section .g-section-grid .g-section-zone-headers {
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  z-index: 91;
  background-color: var(--body-background-color-start);
  flex-direction: var(--g-section-cells-direction);
}
.single-cell.g-section .g-section-grid .g-section-index-headers .g-section-index-header,
.single-cell.g-section .g-section-grid .g-section-zone-headers .g-section-index-header {
  display: none;
}
.g-section .g-section-grid .g-section-index-headers .g-section-header-space,
.g-section .g-section-grid .g-section-zone-headers .g-section-header-space {
  display: var(--g-section-header-space-display);
  flex: 0 0 var(--g-channel-header-size);
  align-self: flex-end;
}
.g-section .g-section-grid .g-section-index-headers .g-section-index-headers-list,
.g-section .g-section-grid .g-section-zone-headers .g-section-index-headers-list,
.g-section .g-section-grid .g-section-index-headers .g-section-zone-headers-list,
.g-section .g-section-grid .g-section-zone-headers .g-section-zone-headers-list {
  display: flex;
  flex: 1 0 0%;
  flex-direction: var(--g-section-flow-direction);
  justify-content: var(--g-section-flow-justify-content);
}
.g-section .g-section-grid .g-section-index-headers .g-section-index-header,
.g-section .g-section-grid .g-section-zone-headers .g-section-index-header,
.g-section .g-section-grid .g-section-index-headers .g-section-zone-header,
.g-section .g-section-grid .g-section-zone-headers .g-section-zone-header {
  transition: flex 0.25s linear 0.25s;
  overflow: hidden;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  display: flex;
  justify-content: center;
  flex: 1 0 0%;
}
.g-section .g-section-grid .g-section-index-headers .g-section-index-header.collapsed,
.g-section .g-section-grid .g-section-zone-headers .g-section-index-header.collapsed,
.g-section .g-section-grid .g-section-index-headers .g-section-zone-header.collapsed,
.g-section .g-section-grid .g-section-zone-headers .g-section-zone-header.collapsed {
  flex-grow: 0 !important;
}
.g-section .g-section-grid .g-section-index-headers .g-section-index-header.reset,
.g-section .g-section-grid .g-section-zone-headers .g-section-index-header.reset,
.g-section .g-section-grid .g-section-index-headers .g-section-zone-header.reset,
.g-section .g-section-grid .g-section-zone-headers .g-section-zone-header.reset {
  flex-grow: 1 !important;
}
.g-section .g-section-grid .g-section-index-headers .g-section-index-header.shrink-text,
.g-section .g-section-grid .g-section-zone-headers .g-section-index-header.shrink-text,
.g-section .g-section-grid .g-section-index-headers .g-section-zone-header.shrink-text,
.g-section .g-section-grid .g-section-zone-headers .g-section-zone-header.shrink-text {
  font-size: 11px;
}
.g-section .g-section-grid .g-section-index-headers .g-section-index-header .g-section-index-header-text,
.g-section .g-section-grid .g-section-zone-headers .g-section-index-header .g-section-index-header-text,
.g-section .g-section-grid .g-section-index-headers .g-section-zone-header .g-section-index-header-text,
.g-section .g-section-grid .g-section-zone-headers .g-section-zone-header .g-section-index-header-text,
.g-section .g-section-grid .g-section-index-headers .g-section-index-header .g-section-zone-header-text,
.g-section .g-section-grid .g-section-zone-headers .g-section-index-header .g-section-zone-header-text,
.g-section .g-section-grid .g-section-index-headers .g-section-zone-header .g-section-zone-header-text,
.g-section .g-section-grid .g-section-zone-headers .g-section-zone-header .g-section-zone-header-text {
  text-align: center;
  padding: 5px;
  width: 100%;
  height: 100%;
  flex: 1 0 auto;
}
.single-cell.g-section .g-section-grid .g-section-index-headers {
  background-color: transparent;
}
.g-section .g-section-grid .g-channels-container {
  display: flex;
  flex: 1 0 0%;
  min-width: 0;
  min-height: 0;
}
.g-section .g-section-grid .g-channels-container .g-channels {
  display: flex;
  flex: 1 0 0%;
  min-width: 0;
  min-height: 0;
  flex-direction: var(--g-section-grid-contents-direction);
}
.g-section .g-section-grid .g-channels-container .g-channels .g-channel-container {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  min-width: 0;
  min-height: 0;
  flex-basis: var(--g-section-grid-channel-container-min-size);
}
.g-ticket-size-item-container,
.g-filter-item-container {
  display: flex;
  justify-content: space-between;
}
.g-ticket-size-item-container-loading,
.g-filter-item-container-loading {
  pointer-events: all;
  cursor: wait;
}
.g-ticket-size-item-container-loading label,
.g-filter-item-container-loading label,
.g-ticket-size-item-container-loading span,
.g-filter-item-container-loading span,
.g-ticket-size-item-container-loading input,
.g-filter-item-container-loading input {
  pointer-events: none;
}
.g-ticket-size-item-container .g-item-checkbox,
.g-filter-item-container .g-item-checkbox {
  flex-grow: 1;
}
.g-ticket-size-item-container .g-item-spinner,
.g-filter-item-container .g-item-spinner {
  align-self: flex-end;
  flex-grow: 0;
  width: 0px;
  padding-right: 21px;
  margin: 0;
}
.g-section-grid.g-section-flow-vertical .g-section-index-headers,
.g-section-grid.g-section-flow-vertical .g-section-zone-headers {
  width: 30px;
}
.single-cell .g-section-grid.g-section-flow-vertical .g-section-index-headers,
.single-cell .g-section-grid.g-section-flow-vertical .g-section-zone-headers {
  width: 1px;
}
.g-section-grid.g-section-flow-vertical .g-section-index-headers .g-section-header-space,
.g-section-grid.g-section-flow-vertical .g-section-zone-headers .g-section-header-space {
  width: 5px;
}
.g-section-grid.g-section-flow-vertical .g-section-index-headers .g-section-index-header-text,
.g-section-grid.g-section-flow-vertical .g-section-zone-headers .g-section-index-header-text,
.g-section-grid.g-section-flow-vertical .g-section-index-headers .g-section-zone-header-text,
.g-section-grid.g-section-flow-vertical .g-section-zone-headers .g-section-zone-header-text {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-rl;
}
.g-section-grid.g-section-flow-vertical .g-section-index-headers {
  left: 0;
}
.g-section-grid.g-section-flow-vertical .g-section-index-headers .g-section-index-header {
  border-left: 1px solid #fff;
}
.g-section-grid.g-section-flow-vertical .g-section-zone-headers {
  left: 30px;
}
.g-section-grid.g-section-flow-vertical .g-section-header-space.channel-header-border {
  margin-right: -4px;
  border-top-left-radius: 5px;
  border-top: 1px solid #d3d6d9;
  border-left: 1px solid #d3d6d9;
}
.g-section-grid.g-section-flow-vertical .g-channels-container {
  margin-left: -1px;
}
.g-section-grid.g-section-flow-vertical .g-channels-container .g-channels .g-channel-container {
  min-width: var(--g-section-grid-channel-container-min-size);
}
.g-section-grid.g-section-flow-vertical .g-channels-container .g-channels .g-channel-container:first-child .g-channel-header {
  margin-left: 0;
}
.g-section-grid.g-section-flow-vertical .g-channels-container .g-channels .g-channel-container:last-child .ui-resizable-handle {
  right: 0px;
}
.g-section-grid.g-section-flow-horizontal .g-section-index-headers,
.g-section-grid.g-section-flow-horizontal .g-section-zone-headers {
  height: 30px;
}
.single-cell .g-section-grid.g-section-flow-horizontal .g-section-index-headers,
.single-cell .g-section-grid.g-section-flow-horizontal .g-section-zone-headers {
  height: 1px;
}
.g-section-grid.g-section-flow-horizontal .g-section-index-headers .g-section-header-space,
.g-section-grid.g-section-flow-horizontal .g-section-zone-headers .g-section-header-space {
  height: 5px;
}
.g-section-grid.g-section-flow-horizontal .g-section-index-headers {
  top: 0;
}
.g-section-grid.g-section-flow-horizontal .g-section-index-headers .g-section-index-header {
  border-top: 1px solid #fff;
}
.g-section-grid.g-section-flow-horizontal .g-section-zone-headers {
  top: 30px;
}
.g-section-grid.g-section-flow-horizontal .g-section-header-space.channel-header-border {
  margin-bottom: -4px;
  border-top-left-radius: 5px;
  border-top: 1px solid #d3d6d9;
  border-left: 1px solid #d3d6d9;
}
.g-section-grid.g-section-flow-horizontal .g-channels-container {
  margin-top: -1px;
}
.g-section-grid.g-section-flow-horizontal .g-channels-container .g-channels .g-channel-container {
  min-height: var(--g-section-grid-channel-container-min-size);
}
.g-section-grid.g-section-flow-horizontal .g-channels-container .g-channels .g-channel-container:first-child .g-channel-header {
  margin-top: 0;
}
.g-section-grid.g-section-flow-horizontal .g-channels-container .g-channels .g-channel-container:last-child .ui-resizable-handle {
  bottom: 0px;
}
.g-section-flow-up .g-section-index-headers-list .g-section-index-header:first-child,
.g-section-flow-up .g-section-zone-headers-list .g-section-index-header:first-child {
  border-top-left-radius: 5px;
}
.g-section-flow-up .g-section-index-headers-list .g-section-index-header:last-child,
.g-section-flow-up .g-section-zone-headers-list .g-section-index-header:last-child {
  border-bottom-left-radius: 5px;
}
.g-section-flow-up .g-section-index-headers-list .g-section-index-header:first-child,
.g-section-flow-up .g-section-zone-headers-list .g-section-index-header:first-child,
.g-section-flow-up .g-section-index-headers-list .g-section-zone-header:first-child,
.g-section-flow-up .g-section-zone-headers-list .g-section-zone-header:first-child {
  border-top: 1px solid #fff;
}
.g-section-flow-down .g-section-index-headers-list .g-section-index-header:first-child,
.g-section-flow-down .g-section-zone-headers-list .g-section-index-header:first-child {
  border-bottom-left-radius: 5px;
}
.g-section-flow-down .g-section-index-headers-list .g-section-index-header:last-child,
.g-section-flow-down .g-section-zone-headers-list .g-section-index-header:last-child {
  border-top-left-radius: 5px;
}
.g-section-flow-down .g-section-index-headers-list .g-section-index-header:last-child,
.g-section-flow-down .g-section-zone-headers-list .g-section-index-header:last-child,
.g-section-flow-down .g-section-index-headers-list .g-section-zone-header:last-child,
.g-section-flow-down .g-section-zone-headers-list .g-section-zone-header:last-child {
  border-top: 1px solid #fff;
}
.g-section-flow-left .g-section-index-headers-list .g-section-index-header:first-child,
.g-section-flow-left .g-section-zone-headers-list .g-section-index-header:first-child {
  border-top-left-radius: 5px;
}
.g-section-flow-left .g-section-index-headers-list .g-section-index-header:last-child,
.g-section-flow-left .g-section-zone-headers-list .g-section-index-header:last-child {
  border-top-right-radius: 5px;
}
.g-section-flow-left .g-section-index-headers-list .g-section-index-header:first-child,
.g-section-flow-left .g-section-zone-headers-list .g-section-index-header:first-child,
.g-section-flow-left .g-section-index-headers-list .g-section-zone-header:first-child,
.g-section-flow-left .g-section-zone-headers-list .g-section-zone-header:first-child {
  border-left: 1px solid #fff;
}
.g-section-flow-right .g-section-index-headers-list .g-section-index-header:first-child,
.g-section-flow-right .g-section-zone-headers-list .g-section-index-header:first-child {
  border-top-right-radius: 5px;
}
.g-section-flow-right .g-section-index-headers-list .g-section-index-header:last-child,
.g-section-flow-right .g-section-zone-headers-list .g-section-index-header:last-child {
  border-top-left-radius: 5px;
}
.g-section-flow-right .g-section-index-headers-list .g-section-index-header:last-child,
.g-section-flow-right .g-section-zone-headers-list .g-section-index-header:last-child,
.g-section-flow-right .g-section-index-headers-list .g-section-zone-header:last-child,
.g-section-flow-right .g-section-zone-headers-list .g-section-zone-header:last-child {
  border-left: 1px solid #fff;
}
.g-channel {
  display: flex;
  flex: 1 0 0%;
  min-width: 0;
  min-height: 0;
  flex-direction: var(--g-section-cells-direction);
  --g-channel-resource-image-size: calc(var(--g-channel-header-size) - 20px);
  --g-channel-status-indicator-size: 26px;
  --g-channel-status-indicator-icon-size: 14px;
  --g-channel-name-text-align: center;
  --g-channel-header-image-position: relative;
}
.small-channel .g-channel {
  --g-channel-resource-image-size: calc(var(--g-channel-header-size) - 45px);
  --g-channel-status-indicator-size: 22px;
  --g-channel-status-indicator-icon-size: 12px;
  --g-channel-name-text-align: flex-start;
  --g-channel-header-info-container-size: 100%;
  --g-channel-header-image-position: absolute;
}
.small-channel .g-channel .g-channel-header .g-health-bar {
  border-radius: 0;
}
.g-channel .g-channel-header {
  display: flex;
  flex-direction: var(--g-channel-header-direction);
  flex: 0 0 var(--g-channel-header-size);
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d3d6d9;
  border-top: 1px solid #d3d6d9;
  background-color: #fff;
}
.g-channel .g-channel-header .g-channel-header-image-container {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  border-radius: 100%;
  overflow: hidden;
  flex: 0 0 auto;
  height: var(--g-channel-resource-image-size);
  width: var(--g-channel-resource-image-size);
  position: var(--g-channel-header-image-position);
  background-color: #231f20;
  z-index: 2;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.g-channel .g-channel-header .g-channel-header-image-container:hover,
.g-channel .g-channel-header .g-channel-header-image-container:focus {
  cursor: pointer;
}
.g-channel .g-channel-header .g-channel-header-image-container:hover .g-channel-header-image,
.g-channel .g-channel-header .g-channel-header-image-container:focus .g-channel-header-image {
  opacity: 0.8;
}
.g-channel .g-channel-header .g-channel-header-image-container:active:before,
.g-channel .g-channel-header .g-channel-header-image-container.popover-shown:before {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19) inset, 0 0px 6px rgba(0, 0, 0, 0.23) inset;
}
.g-channel .g-channel-header .g-channel-header-image-container:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  content: "";
  z-index: 3;
  box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}
.g-channel .g-channel-header .g-channel-header-image-container .g-channel-header-image {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.g-channel .g-channel-header.g-group-channel-header {
  --g-channel-resource-image-size: calc(var(--g-channel-header-size) - 30px);
  --g-channel-header-image-position: relative;
}
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-info-container {
  max-width: none;
  flex-grow: 0;
  flex-basis: auto;
}
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container {
  background-color: #fafafa;
  border: 2px solid #d3d6d9;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 38px;
  min-width: 38px;
}
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container:before {
  box-shadow: none;
}
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container:hover,
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container:focus {
  background-color: #f0f0f0;
}
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container:active:before,
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container.popover-shown:before {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset, 0 0px 6px rgba(0, 0, 0, 0.16) inset;
}
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container .g-channel-name-icon {
  font-size: 20px;
}
.g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container .g-channel-name-text {
  margin-bottom: 0;
}
.g-channel .g-channel-header .g-channel-header-info-container {
  display: flex;
  flex-direction: var(--g-channel-header-info-container-direction);
  justify-content: center;
  align-items: stretch;
  flex: 0 1 var(--g-channel-header-info-container-size);
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-name {
  display: flex;
  align-items: center;
  justify-content: var(--g-channel-name-text-align);
  min-width: 0;
  flex-shrink: 0;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-name .g-channel-name-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 16px;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-health-bar {
  border: 0;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status {
  display: flex;
  align-items: center;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 12px;
  color: #95989a;
  display: flex;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-text > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-indicators {
  z-index: 3;
  border-radius: var(--g-channel-status-indicator-size);
  overflow: hidden;
  display: flex;
  flex: 0 0 auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-indicators:before {
  z-index: 2;
  border-radius: var(--g-channel-status-indicator-size);
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-status-indicator {
  flex: 0 0 var(--g-channel-status-indicator-size);
  height: var(--g-channel-status-indicator-size);
  width: var(--g-channel-status-indicator-size);
  border-radius: 100%;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-status-indicator i {
  font-size: var(--g-channel-status-indicator-icon-size);
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-status-indicator i.icon-play {
  margin-right: -3px;
}
.g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-status-indicator i.icon-exclamation {
  margin-top: 1px;
}
.g-channel .g-cells {
  display: flex;
  flex: 1 0 0%;
  flex-direction: var(--g-section-flow-direction);
  justify-content: var(--g-section-flow-justify-content);
}
.g-channel .g-cells .g-cell {
  transition: flex 0.25s linear 0.25s;
  flex: 1 0 auto;
  display: flex;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  overflow: hidden;
  position: relative;
}
.g-channel .g-cells .g-cell .after,
.g-channel .g-cells .g-cell .before {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  pointer-events: none;
  z-index: 20;
}
.g-channel .g-cells .g-cell .before {
  top: 0;
}
.g-channel .g-cells .g-cell .after {
  bottom: 0;
}
.g-channel .g-cells .g-cell.collapsed {
  flex-grow: 0;
}
.g-channel .g-cells .g-cell .g-tickets {
  flex: 1 0 auto;
  padding: 6px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.g-channel .g-cells .g-cell .g-tickets::-webkit-scrollbar {
  display: none;
}
.g-channel .g-cells .g-cell .g-cell-ticket-counter-container:not(:empty) {
  flex: 0 0 auto;
  padding-left: 4px;
  z-index: 21;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.g-channel .g-cells .g-cell .g-cell-ticket-counter-container:not(:empty) .g-cell-ticket-counter-button {
  border-radius: 0;
  border-bottom-left-radius: 5px;
}
.g-section-flow-vertical {
  --g-channel-header-direction: row;
  --g-channel-header-info-container-direction: column;
}
.single-cell .g-section-flow-vertical .g-channel-container:first-child .g-cell {
  border-left: 1px solid #fff;
  border-bottom-left-radius: 5px;
}
.single-cell.NOT .g-section-flow-vertical .g-channel-container:first-child .g-cell {
  border-top-left-radius: 5px;
}
.g-section-flow-vertical .g-channel .g-channel-header {
  width: auto;
  padding: 0 10px;
  margin-left: -1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-right: 1px solid #d3d6d9;
}
.g-section-flow-vertical .g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container {
  margin-right: 15px;
}
.g-section.BUC .g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-image-container {
  margin-right: 0;
}
.g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container {
  margin-left: -2px;
}
.g-section.BUC .g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container {
  margin-left: 10px;
}
.g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container .g-channel-name {
  margin-bottom: 2px;
  min-width: 0;
}
.g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container .g-channel-health-bar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status {
  margin-left: -20px;
  margin-top: -6px;
}
.g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-indicators .g-status-indicator {
  border-radius: 0;
}
.g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-text {
  margin-top: 4px;
  margin-left: 5px;
}
.g-section-flow-vertical .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-text .g-duration {
  margin-left: 4px;
}
.g-section-flow-vertical .g-channel .g-cells {
  width: 100%;
}
.g-section-flow-vertical .g-channel .g-cells .g-cell {
  flex-direction: row;
}
.g-section-flow-vertical .g-channel:last-child .g-cell {
  border-right: 1px solid #fff;
}
.g-section-flow-vertical .small-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container {
  margin-right: 10px;
}
.g-section-flow-vertical .small-channel .g-channel-header:not(.g-group-channel-header) {
  padding: 0;
}
.g-section-flow-vertical .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-image-container {
  top: 5px;
  left: 5px;
}
.g-section.BUC .g-section-flow-vertical .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-image-container {
  top: auto;
}
.g-section-flow-vertical .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container {
  margin-left: 0;
}
.g-section-flow-vertical .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container .g-channel-name {
  display: initial;
  margin-left: calc(var(--g-channel-resource-image-size) + 12px);
  padding-right: 5px;
  min-height: 0;
}
.g-section.BUC .g-section-flow-vertical .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container .g-channel-name {
  margin-bottom: 0;
}
.g-section-flow-vertical .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container .g-channel-status {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -4px;
}
.g-section-flow-horizontal {
  --g-channel-header-direction: column-reverse;
  --g-channel-header-info-container-direction: row;
}
.single-cell .g-section-flow-horizontal .g-channel-container:first-child .g-cell {
  border-top: 1px solid #fff;
  border-top-right-radius: 5px;
}
.single-cell.NOT .g-section-flow-horizontal .g-channel-container:first-child .g-cell {
  border-top-left-radius: 5px;
}
.g-section-flow-horizontal .g-channel .g-channel-header {
  padding: 10px 0;
  height: auto;
  margin-top: -1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid #d3d6d9;
}
.g-section-flow-horizontal .g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container {
  margin-top: 15px;
}
.g-section.BUC .g-section-flow-horizontal .g-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container {
  margin-top: 0;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container {
  margin-bottom: -2px;
}
.g-section.BUC .g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container {
  margin-bottom: 10px;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-name {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-rl;
  margin-right: 2px;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-name-icon {
  margin-bottom: 10px;
  transform: rotate(180deg);
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-health-bar {
  height: auto;
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-rl;
  width: 22px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-health-bar .g-health-bar-capacity-fill {
  align-self: flex-end;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status {
  margin-bottom: -20px;
  margin-left: -6px;
  flex-direction: column-reverse;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-indicators {
  flex-direction: column-reverse;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-indicators .g-status-indicator {
  border-radius: 0;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-text {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-rl;
  margin-left: 4px;
  margin-bottom: 5px;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-channel-status-text .g-duration {
  margin-top: 4px;
}
.g-section-flow-horizontal .g-channel .g-channel-header .g-channel-header-info-container .g-channel-status .g-duration-label:not(:last-child) {
  margin-bottom: 4px;
  margin-right: 0;
}
.g-section-flow-horizontal .g-channel .g-cells {
  height: 100%;
  width: 100%;
}
.g-section-flow-horizontal .g-channel .g-cells .g-cell .g-tickets {
  flex: 1;
}
.g-section-flow-horizontal .g-channel:last-child .g-cell {
  border-bottom: 1px solid #fff;
}
.g-section-flow-horizontal .small-channel .g-channel-header.g-group-channel-header .g-channel-header-image-container {
  margin-top: 10px;
}
.g-section-flow-horizontal .small-channel .g-channel-header:not(.g-group-channel-header) {
  padding: 0;
}
.g-section-flow-horizontal .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-image-container {
  bottom: 5px;
  left: 5px;
}
.g-section.BUC .g-section-flow-horizontal .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-image-container {
  left: auto;
}
.g-section-flow-horizontal .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container {
  margin-bottom: 0;
}
.g-section-flow-horizontal .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container .g-channel-name {
  padding-top: calc(var(--g-channel-resource-image-size) + 12px);
  padding-bottom: 5px;
}
.g-section.BUC .g-section-flow-horizontal .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container .g-channel-name {
  margin-right: 0;
}
.g-section-flow-horizontal .small-channel .g-channel-header:not(.g-group-channel-header) .g-channel-header-info-container .g-channel-status {
  margin-top: 8px;
  margin-bottom: 6px;
  margin-left: -4px;
}
.g-section-flow-up .g-channel-container .g-channel .g-cells .g-cell:first-child {
  border-top: 1px solid #fff;
}
.g-section-flow-up .g-channel-container:last-child .g-cell:last-child {
  border-bottom-right-radius: 5px;
}
.NOT .g-section-flow-up .g-channel-container:first-child .g-cell:first-child {
  border-top-right-radius: 5px;
}
.g-section-flow-down .g-channel-container .g-channel .g-cells .g-cell:last-child {
  border-top: 1px solid #fff;
}
.g-section-flow-down .g-channel-container:last-child .g-cell:first-child {
  border-bottom-right-radius: 5px;
}
.NOT .g-section-flow-down .g-channel-container:first-child .g-cell:last-child {
  border-top-right-radius: 5px;
}
.g-section-flow-left .g-channel-container .g-channel .g-cells .g-cell:first-child {
  border-left: 1px solid #fff;
}
.g-section-flow-left .g-channel-container:last-child .g-cell:last-child {
  border-bottom-right-radius: 5px;
}
.NOT .g-section-flow-left .g-channel-container:first-child .g-cell:first-child {
  border-bottom-left-radius: 5px;
}
.g-section-flow-right .g-channel-container .g-channel .g-cells .g-cell:last-child {
  border-left: 1px solid #fff;
}
.g-section-flow-right .g-channel-container:last-child .g-cell:first-child {
  border-bottom-right-radius: 5px;
}
.NOT .g-section-flow-right .g-channel-container:first-child .g-cell:last-child {
  border-bottom-left-radius: 5px;
}
.g-ticket {
  display: flex;
}
.g-ticket .g-ticket-tags {
  display: flex;
  --g-ticket-tag-size: 0px;
}
.g-ticket .g-ticket-tags .g-ticket-tag {
  background-color: #fff;
  border-width: var(--g-ticket-tag-size);
}
.g-ticket .g-ticket-tags .g-ticket-tag.size-small {
  --g-ticket-tag-size: 2px;
}
.g-ticket .g-ticket-tags .g-ticket-tag.size-large {
  --g-ticket-tag-size: 5px;
}
.g-ticket .g-ticket-tags:empty {
  display: none;
}
.g-ticket .g-ticket-body {
  flex: 1 0 0%;
  display: flex;
  height: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  background-clip: padding-box;
  position: relative;
  overflow: hidden;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 1;
}
.g-ticket .g-ticket-body .g-ticket-main-tag {
  z-index: 3;
}
.g-ticket .g-ticket-body .g-ticket-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  background-color: rgba(211, 214, 217, 0.7);
  z-index: 10;
  cursor: not-allowed;
}
.g-ticket .g-ticket-body .g-ticket-body-content {
  flex: 1 0 0%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  background-color: #231f20;
}
.g-ticket .g-ticket-body .g-ticket-body-content > * {
  position: relative;
  z-index: 2;
}
.g-ticket .g-ticket-body .g-ticket-body-content:before {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  content: "";
  z-index: 0;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: 1;
  opacity: 0.18;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-job-code {
  font-weight: bold;
  line-height: 12px;
  display: flex;
  align-items: center;
  min-height: 18px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-job-summary {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 6px;
  max-height: 20px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-task-type {
  margin-right: 5px;
  font-weight: bold;
  line-height: 12px;
  display: flex;
  align-items: center;
  height: 22px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-task-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 6px;
  max-height: 22px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack {
  transition: all 0.12s ease-out, order 0s;
  height: 22px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack:not(:empty) {
  background-color: #7c8084;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack:not(:empty):hover,
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack:not(:empty):active,
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack:not(:empty).popover-shown {
  background-color: #686c6f;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack:not(:empty):active,
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack:not(:empty).popover-shown {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset, 0 0px 6px rgba(0, 0, 0, 0.16) inset;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack span {
  margin: 0 5px;
  line-height: 12px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack i {
  display: flex;
  margin-right: 5px;
  color: #FFFFFF;
  font-size: 12px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-duration {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 44px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators {
  overflow: hidden;
  display: flex;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators .g-status-indicators-content {
  display: flex;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-task-note:not(:empty) {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  background-color: rgba(140, 140, 140, 0.25);
  font-weight: 500;
  padding: 2px 6px;
  max-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-text-container {
  overflow: hidden;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-ticket-text-container > div {
  width: inherit;
  margin: 0px 6px;
}
.g-ticket .g-ticket-body .g-ticket-body-content .g-duration {
  overflow: hidden;
  margin: 0px 6px;
  flex-shrink: 0;
}
.g-ticket .g-ticket-body .g-ticket-body-content:focus,
.g-ticket .g-ticket-body .g-ticket-body-content:hover {
  cursor: pointer;
}
.g-ticket .g-ticket-body .g-ticket-body-content:focus:before,
.g-ticket .g-ticket-body .g-ticket-body-content:hover:before {
  opacity: 0.9;
}
.g-ticket .g-ticket-body .g-ticket-body-content:active {
  cursor: pointer;
}
.g-ticket .g-ticket-body .g-ticket-body-content:active:before {
  opacity: 0.85;
}
.g-ticket-large .g-ticket-tags {
  flex: 0 0 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.g-ticket-large .g-ticket-tags .g-ticket-tag {
  flex: 0 1 15px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.g-ticket-large .g-ticket-tags .g-ticket-tag + .g-ticket-tag {
  margin-top: 5px;
}
.g-ticket-large .g-ticket-body .g-ticket-main-tag {
  flex: 0 0 12px;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-job-code {
  order: 1;
  margin-left: 6px;
  margin-right: auto;
  min-height: 22px;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-duration {
  order: 2;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators {
  order: 3;
  border-bottom-left-radius: 5px;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-job-summary {
  order: 4;
  flex: 1 0 100%;
  padding-top: 1px;
  padding-bottom: 1px;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-task-type {
  order: 5;
  margin-left: 6px;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-task-description {
  order: 6;
  margin-right: auto;
  flex: 1 0 66%;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack-container {
  order: 7;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack-container .g-ticket-upcoming-tasks-stack {
  border-top-left-radius: 5px;
}
.g-ticket-large .g-ticket-body .g-ticket-body-content .g-ticket-task-note {
  order: 8;
}
.g-ticket-medium .g-ticket-tags {
  flex: 0 0 18px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -5px;
  padding-top: 6px;
  padding-bottom: 5px;
}
.g-ticket-medium .g-ticket-tags .g-ticket-tag {
  flex: 0 1 16px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.g-ticket-medium .g-ticket-tags .g-ticket-tag + .g-ticket-tag {
  margin-top: 4px;
}
.g-ticket-medium .g-ticket-body .g-ticket-main-tag {
  flex: 0 0 12px;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-job-code,
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-task-description {
  display: none;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-task-type {
  order: 1;
  padding-left: 6px;
  margin-right: auto;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-duration {
  order: 2;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators {
  order: 3;
  border-bottom-left-radius: 5px;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-job-summary {
  order: 4;
  flex: 1 0 74%;
  min-height: 22px;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack-container {
  order: 5;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack-container .g-ticket-upcoming-tasks-stack {
  border-top-left-radius: 5px;
}
.g-ticket-medium .g-ticket-body .g-ticket-body-content .g-ticket-task-note {
  order: 6;
}
.g-ticket-small {
  width: 100%;
  position: relative;
}
.g-ticket-small .g-ticket-tags {
  position: absolute;
  height: 6px;
  bottom: 0;
  right: 0;
  width: 48%;
}
.g-ticket-small .g-ticket-tags .g-ticket-tag {
  flex: 1 0 0;
  z-index: 4;
  border-top-width: 0 !important;
}
.g-ticket-small .g-ticket-tags .g-ticket-tag.size-large {
  --g-ticket-tag-size: 4px;
}
.g-ticket-small .g-ticket-tags .g-ticket-tag:last-child {
  border-bottom-right-radius: 5px;
}
.g-ticket-small .g-ticket-body {
  flex: 1 0 auto;
  flex-wrap: wrap;
}
.g-ticket-small .g-ticket-body .g-ticket-main-tag {
  flex: 0 0 100%;
  height: 6px;
  order: 2;
  display: flex !important;
}
.g-ticket-small .g-ticket-body .g-ticket-body-content {
  flex: 1 0 100%;
  flex-wrap: nowrap;
  order: 1;
}
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-job-code,
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-job-summary,
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-task-note,
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-duration,
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-task-description,
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-status-text {
  display: none;
}
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-task-type {
  order: 1;
  padding-left: 6px;
  width: 38px;
  margin-right: 0;
}
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack-container {
  order: 2;
  margin-left: auto;
  width: 40px;
}
.g-ticket-small .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators {
  order: 3;
  max-width: 44px;
}
.g-ticket-tiny .g-ticket-tags {
  display: none;
}
.g-ticket-tiny .g-ticket-body .g-ticket-main-tag {
  flex: 0 0 12px;
}
.g-ticket-tiny .g-ticket-body .g-ticket-body-content {
  column-count: 2;
  column-gap: 0;
  display: initial;
  position: initial;
}
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-job-code,
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-job-summary,
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-task-note,
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-duration,
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-task-description,
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-status-text {
  display: none;
}
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-task-type {
  width: 40px;
  padding-left: 6px;
  margin-right: 0;
}
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-upcoming-tasks-stack-container {
  height: 22px;
  width: 40px;
}
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators {
  width: 22px;
  height: 44px;
  margin-left: auto;
}
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators .g-status-indicators-content {
  flex-direction: column;
}
.g-ticket-tiny .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators .g-status-indicator {
  width: 22px;
}
.g-ticket-upcoming-task {
  min-width: 400px;
  max-width: 500px;
  flex-direction: column-reverse;
}
.g-ticket-upcoming-task .g-ticket-tags {
  flex: 0 0 14px;
  align-self: flex-end;
  justify-content: flex-end;
  margin-bottom: -5px;
  padding-right: 6px;
  width: 30%;
}
.g-ticket-upcoming-task .g-ticket-tags .g-ticket-tag {
  flex: 0 1 16px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.g-ticket-upcoming-task .g-ticket-tags .g-ticket-tag + .g-ticket-tag {
  margin-left: 4px;
}
.g-ticket-upcoming-task .g-ticket-body {
  flex: 1 0 auto;
}
.g-ticket-upcoming-task .g-ticket-body .g-ticket-main-tag {
  flex: 0 0 12px;
}
.g-ticket-upcoming-task .g-ticket-body .g-ticket-body-content {
  flex: 1 0 0%;
  flex-direction: column;
}
.g-ticket-upcoming-task .g-ticket-body .g-ticket-body-content .g-ticket-row {
  display: flex;
  flex: 1 0 0%;
  height: 34px;
  width: 100%;
  align-items: center;
  padding: 2px 6px;
}
.g-ticket-upcoming-task .g-ticket-body .g-ticket-body-content .g-ticket-task-type {
  margin-left: 6px;
}
.g-ticket-upcoming-task .g-ticket-body .g-ticket-body-content .g-ticket-task-description {
  margin-right: auto;
}
.g-ticket-upcoming-task .g-ticket-body .g-ticket-body-content .g-ticket-status-indicators {
  border-radius: 22px;
  flex-shrink: 0;
}
.g-ticket-upcoming-task .g-ticket-body .g-ticket-body-content .g-ticket-task-note {
  display: block;
  padding: 2px 12px !important;
}
.g-ticket-skeleton {
  width: 100%;
  height: 60px;
  max-width: 200px;
  margin: 10px;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.g-ticket-skeleton-elements {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  background-image: linear-gradient(#d3d6d9 100%, transparent 0), /* job # line */ linear-gradient(#d3d6d9 100%, transparent 0), /* time line */ linear-gradient(#d3d6d9 100%, transparent 0), /* job desc */ linear-gradient(#d3d6d9 100%, transparent 0), /* task type */ linear-gradient(#d3d6d9 100%, transparent 0), /* task desc */ linear-gradient(#fff 100%, transparent 0);
  /* ticket bg */
  background-size: 40% 8px, /* job # line */ 15% 8px, /* time line */ calc(100% - 8px * 2) 8px, /* job desc line */ 18% 8px, /* task type */ 40% 8px, /* task desc */ 100% 100%;
  /* ticket bg */
  background-position: 8px 8px, /* job # line */ calc(100% - 8px) 8px, /* time line */ 8px 26px, /* job desc line */ 8px 44px, /* task type */ 58px 44px, /* task desc */ 0 0;
  /* ticket bg */
  background-repeat: no-repeat;
}
.g-ticket-skeleton-bar {
  position: absolute;
  left: -200px;
  height: 100%;
  width: 50%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  background-size: 100px 100%;
  background-position: 0 0;
  animation: loading 1s infinite;
}
.g-status-popover-contents .g-ticket-skeleton {
  border: 1px solid #d3d6d9;
}
@keyframes loading {
  to {
    transform: translateX(400px);
  }
}
.g-status-indicators {
  position: relative;
  background-clip: padding-box;
}
.g-status-indicators:before {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: 2;
}
.g-status-indicators:hover {
  cursor: pointer;
}
.g-status-indicators:hover .g-status-indicator {
  background-color: #231f20;
}
.g-status-indicators:hover .g-status-indicator:before {
  opacity: 0.9;
}
.g-status-indicators:active:before,
.g-status-indicators.popover-shown:before {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) inset, 0 0px 6px rgba(0, 0, 0, 0.16) inset;
}
.g-status-indicator {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
  flex-shrink: 0;
  background-color: transparent;
  overflow: hidden;
  -webkit-text-stroke: var(--g-status-indicator-text-stroke);
  --g-status-indicator-text-stroke: initial;
  --g-status-indicator-icon-color: #fff;
  --g-status-indicator-bg-color: #fff;
}
.g-status-indicator:before {
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  content: "";
  z-index: 0;
  background-color: var(--g-status-indicator-bg-color);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.g-status-indicator i {
  color: var(--g-status-indicator-icon-color);
  line-height: 12px;
  font-size: 12px;
  position: relative;
  z-index: 2;
}
.g-status-alert {
  --g-status-indicator-text-stroke: 0.8px #995900;
  --g-status-indicator-bg-color: #ffaa33;
}
.g-status-alert > i {
  font-size: 17px !important;
  line-height: 17px !important;
}
.g-status-unallocated {
  --g-status-indicator-bg-color: #c75644;
  width: auto;
}
.g-status-unallocated > i {
  font-size: 14px !important;
  line-height: 14px !important;
}
.g-status-unallocated i {
  width: 22px;
  display: flex;
  justify-content: center;
  margin-top: 1px;
}
.g-status-current {
  --g-status-indicator-text-stroke: 0.8px #0986f1;
  --g-status-indicator-bg-color: #98cefb;
}
.g-status-working {
  --g-status-indicator-text-stroke: 0.8px #145f21;
  --g-status-indicator-bg-color: #37d552;
}
.g-status-working i {
  margin-left: 2px;
}
.g-status-idle {
  --g-status-indicator-text-stroke: 0.8px #997a00;
  --g-status-indicator-bg-color: #ffd633;
}
.g-status-idle > i {
  font-size: 11px !important;
  line-height: 11px !important;
}
.g-status-suspended {
  --g-status-indicator-text-stroke: 0.8px #997a00;
  --g-status-indicator-bg-color: #ffd633;
}
.g-status-blocked {
  --g-status-indicator-text-stroke: 0.8px #c60026;
  --g-status-indicator-bg-color: #ff607e;
}
.g-status-blocked > i {
  font-size: 14px !important;
  line-height: 14px !important;
}
.g-status-cancelled {
  --g-status-indicator-text-stroke: 0.8px #960800;
  --g-status-indicator-bg-color: #ff3b30;
}
.g-status-cancelled > i {
  font-size: 14px !important;
  line-height: 14px !important;
}
.g-status-closed,
.g-status-none {
  --g-status-indicator-bg-color: #a8a8ac;
}
.g-status-assigned {
  --g-status-indicator-bg-color: #ffffff;
  --g-status-indicator-icon-color: #8E8E93;
}
.g-status-open {
  --g-status-indicator-bg-color: #fff;
  --g-status-indicator-icon-color: #26b33e;
}
.g-status-away {
  --g-status-indicator-bg-color: #8E8E93;
}
.g-status-away:active {
  box-shadow: none;
}
.g-status-loading {
  --g-status-indicator-bg-color: #a8a8ac;
}
.g-status-text {
  -webkit-transition: all 0.07s ease-in-out;
  -moz-transition: all 0.07s ease-in-out;
  -o-transition: all 0.07s ease-in-out;
  transition: all 0.07s ease-in-out;
  padding-left: 8px;
  color: #FFFFFF;
  font-weight: 500;
  line-height: 12px;
  position: relative;
  z-index: 2;
}
.g-pave-popover {
  background-color: #fafafa;
}
.g-pave-popover .popover-title {
  background-color: #fff;
}
.g-pave-popover .popover-content {
  padding: 10px;
}
.g-pave-popover .popover-content .g-dynamic-menu-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
}
.g-pave-popover ul li {
  cursor: initial;
}
.g-pave-popover .g-channel-popup-subtitle {
  font-weight: bold;
}
.g-cell-ticket-popover {
  max-width: 630px;
}
.g-cell-ticket-popover .g-tickets {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.g-ticket-upcoming-tasks-popover .popover-content .g-dynamic-menu-list {
  padding-bottom: 14px;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-section {
  margin-bottom: 10px;
  max-width: 450px;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-section:last-child {
  margin-bottom: 0;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-section .g-upcoming-task-popup-details {
  margin-left: 35px;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-section .g-upcoming-task-popup-details span {
  overflow-wrap: break-word;
  white-space: normal;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-section.task-group-section + .task-group-section {
  margin-top: 35px;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-subtitle img {
  height: 22px;
  width: auto;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-subtitle span {
  font-weight: bold;
  margin-left: 10px;
  font-size: 14px;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-popup-subtitle i {
  font-size: 16px;
  width: 25px;
  display: flex;
  justify-content: center;
}
.g-ticket-upcoming-tasks-popover .g-upcoming-task-tickets {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.g-ticket-upcoming-tasks-popover .g-ticket-upcoming-task .g-status-indicator {
  height: 26px;
  min-width: 26px;
}
.g-ticket-upcoming-tasks-popover .g-ticket-upcoming-task + .g-ticket-upcoming-task {
  margin-top: 8.88px;
}
.g-popup-small-text {
  font-size: 12px;
  opacity: 0.6;
}
.g-channel-image-popover.g-action-buttons-popover .popover-content {
  width: 250px;
}
.g-channel-health-popover .popover-content .g-dynamic-menu-list {
  padding-bottom: 0;
}
.g-channel-health-popover .g-channel-health-popup-components {
  margin-top: 30px;
}
.g-channel-health-popover .g-channel-health-popup-status {
  display: flex;
}
.g-channel-health-popover .g-channel-health-popup-status .g-health-status-bar {
  flex: 0 0 10px;
  min-width: 10px;
  border-radius: 5px;
  margin-right: 8px;
}
.g-channel-health-popover .g-channel-health-popup-status .g-health-status-text {
  --g-health-status-text-max-width: 430px;
  max-width: var(--g-health-status-text-max-width);
}
.g-channel-health-popover .g-channel-health-popup-status .g-health-status-text .g-channel-popup-text {
  margin-top: 5px;
}
.g-channel-health-popover .g-channel-health-popup-status .g-health-status-text-1 {
  --g-health-status-text-max-width: 322px;
}
.g-channel-health-popover .g-channel-health-popup-status .g-health-status-text-2 {
  --g-health-status-text-max-width: 340px;
}
.g-channel-health-popover .g-health-component-capacity-container {
  margin-top: 10px;
}
.g-channel-health-popover .g-health-component-capacity-container .g-health-component-capacity + .g-health-component-capacity {
  margin-top: 8px;
}
.g-channel-health-popover .g-health-component-capacity-container .g-health-bar {
  margin-top: 2px;
}
.g-channel-health-popover .g-channel-health-popup-breakdown {
  margin-top: 25px;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table th {
  padding: 5px 10px;
  background-color: #e9eaec;
  width: 130px;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table th.full-width {
  max-width: 240px;
  width: 240px;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table th,
.g-channel-health-popover .g-channel-health-popup-breakdown table td {
  text-align: center;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table thead th:first-child {
  background-color: transparent;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table tbody th {
  text-align: left;
  font-weight: normal;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table thead tr th:first-child,
.g-channel-health-popover .g-channel-health-popup-breakdown table tbody tr th {
  width: 100px;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-channel-health-breakdown-table-row-block td,
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-channel-health-breakdown-table-row-block th {
  font-weight: bold;
  padding: 10px;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-channel-health-breakdown-table-row-inner {
  font-size: 12px;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-channel-health-row-block-content {
  display: flex;
  justify-content: center;
  position: relative;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-pave-health-risk-bad {
  color: #f7595b;
  background-color: transparent;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-health-utilised-capacity-button {
  color: var(--theme-icons);
  position: absolute;
  right: 0px;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  opacity: 1;
  cursor: pointer;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px dashed transparent;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-health-utilised-capacity-button:focus {
  border: 1px dashed #333;
  border-radius: 3px;
  outline: none;
  text-decoration: none;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-health-utilised-capacity-button:hover {
  opacity: 0.5;
  text-decoration: none;
}
.g-channel-health-popover .g-channel-health-popup-breakdown table .g-health-utilised-capacity-button:active {
  opacity: 0.3;
  text-decoration: none;
}
.g-channel-health-popover .g-channel-health-popup-breakdown .g-channel-health-popup-breakdown-footnote {
  margin-top: 10px;
  max-width: 330px;
}
.g-channel-health-popover .g-channel-health-popup-breakdown .g-channel-health-popup-breakdown-footnote span {
  font-size: 11px;
}
.g-channel-status-popover .popover-content .g-dynamic-menu-list,
.g-ticket-status-popover .popover-content .g-dynamic-menu-list {
  padding-bottom: 0;
}
.g-channel-status-popover .g-status-popover-contents,
.g-ticket-status-popover .g-status-popover-contents {
  display: flex;
  flex-direction: column;
}
.g-channel-status-popover .g-status-popover-contents .g-status-popover-statuses,
.g-ticket-status-popover .g-status-popover-contents .g-status-popover-statuses {
  display: flex;
  flex-direction: column;
}
.g-channel-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status,
.g-ticket-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status {
  display: flex;
}
.g-channel-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status + .g-status-popover-status,
.g-ticket-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status + .g-status-popover-status {
  margin-top: 10px;
}
.g-channel-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status .g-status-indicator,
.g-ticket-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status .g-status-indicator {
  margin-right: 10px;
  border-radius: 22px;
  height: 26px;
  width: 26px;
  border-radius: 100%;
}
.g-channel-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status .g-status-popover-status-text,
.g-ticket-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status .g-status-popover-status-text {
  margin-top: 4px;
}
.g-channel-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status .g-status-popover-status-text .g-status-popover-status-text-content,
.g-ticket-status-popover .g-status-popover-contents .g-status-popover-statuses .g-status-popover-status .g-status-popover-status-text .g-status-popover-status-text-content {
  white-space: initial;
}
.g-channel-status-popover .g-status-popover-contents .g-status-popover-workdetails,
.g-ticket-status-popover .g-status-popover-contents .g-status-popover-workdetails,
.g-channel-status-popover .g-status-popover-contents .g-ticket-status-popover-actions:not(:empty),
.g-ticket-status-popover .g-status-popover-contents .g-ticket-status-popover-actions:not(:empty) {
  margin-top: 20px;
  min-width: 0;
}
.g-channel-status-popover .g-status-popover-contents .g-title,
.g-ticket-status-popover .g-status-popover-contents .g-title {
  margin: 0;
}
.g-channel-status-popover .g-status-popover-contents .g-title:not(:empty),
.g-ticket-status-popover .g-status-popover-contents .g-title:not(:empty) {
  margin-top: 20px;
}
.g-channel-status-popover .g-status-popover-contents .g-channel-status-popover-tickets:not(:empty),
.g-ticket-status-popover .g-status-popover-contents .g-channel-status-popover-tickets:not(:empty) {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.g-channel-status-popover .g-status-popover-contents {
  min-width: 250px;
  max-width: 282px;
}
.g-ticket-status-popover .g-status-popover-contents {
  width: 100%;
}
.g-action-buttons-popover .popover-content {
  width: 200px;
}
.g-action-buttons-popover .g-dynamic-menu-list {
  min-width: initial;
}
.g-health-reserved-info-popover {
  max-width: 300px;
}
.g-health-reserved-info-popover .popover-content .g-dynamic-menu-list {
  padding-bottom: 0;
}
.g-health-reserved-info-popover .g-health-reserved-info-description {
  margin-bottom: 15px;
  font-weight: 500;
}
.g-health-reserved-info-popover .g-health-reserved-info-capacity {
  display: flex;
}
.g-health-reserved-info-popover .g-health-reserved-info-capacity .g-duration {
  margin-left: 5px;
  font-weight: 700;
}
.g-board-meeting-mode {
  position: absolute;
  top: -5px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  right: 0;
}
.g-board-meeting-mode.g-board-meeting-mode-in-view-mode {
  opacity: 1;
  z-index: 5;
  pointer-events: initial;
  right: 130px;
}
.g-board-meeting-mode:not(.g-board-meeting-mode-in-view-mode) {
  right: 0px !important;
}
.g-board-meeting-mode .g-board-meeting-mode-container {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  height: 100%;
  overflow: hidden;
  background-color: var(--grid-header-background-color-end);
  padding-top: 2px;
}
.g-board-meeting-mode .g-board-meeting-mode-container .g-timer,
.g-board-meeting-mode .g-board-meeting-mode-container .g-board-meeting-mode-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 5px;
}
.g-board-meeting-mode .g-board-meeting-mode-container .g-timer {
  background-color: rgba(255, 255, 255, 0.35);
  font-weight: bold;
  padding: 0 10px;
}
.g-board-meeting-mode .g-board-meeting-mode-container .g-timer .g-timer-text {
  margin-left: 8px;
}
.g-board-meeting-mode .g-board-meeting-mode-container .g-timer .g-date-text {
  margin-left: 8px;
  margin-right: 8px;
  white-space: nowrap;
}
.g-board-meeting-mode .g-board-meeting-mode-container .g-board-meeting-mode-controls .g-board-meeting-control-button {
  width: 14px;
  padding: 15px 8px;
  height: 100%;
  width: 100%;
}
.g-board-meeting-mode .g-board-meeting-mode-container .g-board-meeting-mode-controls .g-board-meeting-control-button > i {
  width: 12px;
}
#g-board-refresh-timer-menu-button-container {
  cursor: default;
  pointer-events: none;
  opacity: 0.6;
}
#g-board-refresh-timer-menu-button-container #g-board-refresh-timer-menu-button {
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
#g-board-refresh-timer-menu-button-container #g-board-refresh-timer-menu-button .g-display-option-text {
  margin-left: 8px;
  margin-right: 2px;
}
#g-board-refresh-timer-menu-button-container #g-board-refresh-timer-menu-button i {
  opacity: 0.8;
}
.g-board-refresh-hidden-refresh {
  pointer-events: none;
  visibility: hidden;
  position: absolute;
}
.g-board-search-container {
  padding: 0 8px;
  z-index: 4;
}
.g-board-search {
  border-radius: 5px;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: center;
  cursor: text;
  background-color: var(--g-board-search-background-color);
  border: var(--g-board-search-border);
}
.g-board-search:hover {
  background-color: var(--g-board-search-background-color-hover);
}
.g-board-search .g-board-search-icon {
  margin-top: -1px;
}
.g-board-search.header-search {
  --g-board-search-background-color: rgba(255, 255, 255, 0.3);
  --g-board-search-background-color-hover: rgba(255, 255, 255, 0.44);
  --g-board-search-border: none;
  --g-board-search-input-width: 110px;
}
@media (max-width: 768px) {
  .g-board-search.header-search {
    display: none;
  }
}
.g-board-search.header-search .g-board-search-input {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.g-board-search.header-search .g-board-search-input:-moz-placeholder {
  color: white;
  font-style: none;
}
.g-board-search.header-search .g-board-search-input::-moz-placeholder {
  color: white;
  font-style: none;
}
.g-board-search.header-search .g-board-search-input::-webkit-input-placeholder {
  color: white;
  font-style: none;
}
.g-board-search.header-search .g-board-search-icon {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: white;
}
.g-board-search.header-search.focused {
  background-color: white !important;
}
.g-board-search.header-search.focused .g-board-search-input {
  width: 180px;
  color: black;
}
.g-board-search.header-search.focused .g-board-search-icon {
  color: var(--header-background-color-start);
}
.g-board-search.menu-search {
  --g-board-search-background-color: intial;
  --g-board-search-background-color-hover: #f2f2f2;
  --g-board-search-border: 1px solid var(--input-control-border-brush-start);
  --g-board-search-input-width: 90%;
}
.g-board-search .g-board-search-input {
  width: var(--g-board-search-input-width);
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
}
.g-acceptability-band,
.g-acceptability-bands-overflow-button {
  border-radius: 5px;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  padding: 5px 10px;
}
.g-acceptability-band:focus,
.g-acceptability-bands-overflow-button:focus,
.g-acceptability-band:active,
.g-acceptability-bands-overflow-button:active,
.g-acceptability-band.popover-shown,
.g-acceptability-bands-overflow-button.popover-shown {
  box-shadow: none;
}
.g-acceptability-band.g-pave-health-risk-excellent,
.g-acceptability-bands-overflow-button.g-pave-health-risk-excellent {
  background-color: #C3F0C3 !important;
}
.g-acceptability-band.g-pave-health-risk-excellent:hover,
.g-acceptability-bands-overflow-button.g-pave-health-risk-excellent:hover,
.g-acceptability-band.g-pave-health-risk-excellent:focus,
.g-acceptability-bands-overflow-button.g-pave-health-risk-excellent:focus,
.g-acceptability-band.g-pave-health-risk-excellent:active,
.g-acceptability-bands-overflow-button.g-pave-health-risk-excellent:active,
.g-acceptability-band.g-pave-health-risk-excellent.popover-shown,
.g-acceptability-bands-overflow-button.g-pave-health-risk-excellent.popover-shown {
  background: #a6e9a6 !important;
}
.g-acceptability-band.g-pave-health-risk-good,
.g-acceptability-bands-overflow-button.g-pave-health-risk-good {
  background-color: #BBDDFD !important;
}
.g-acceptability-band.g-pave-health-risk-good:hover,
.g-acceptability-bands-overflow-button.g-pave-health-risk-good:hover,
.g-acceptability-band.g-pave-health-risk-good:focus,
.g-acceptability-bands-overflow-button.g-pave-health-risk-good:focus,
.g-acceptability-band.g-pave-health-risk-good:active,
.g-acceptability-bands-overflow-button.g-pave-health-risk-good:active,
.g-acceptability-band.g-pave-health-risk-good.popover-shown,
.g-acceptability-bands-overflow-button.g-pave-health-risk-good.popover-shown {
  background: #98ccfc !important;
}
.g-acceptability-band.g-pave-health-risk-caution,
.g-acceptability-bands-overflow-button.g-pave-health-risk-caution {
  background-color: #FFE164 !important;
}
.g-acceptability-band.g-pave-health-risk-caution:hover,
.g-acceptability-bands-overflow-button.g-pave-health-risk-caution:hover,
.g-acceptability-band.g-pave-health-risk-caution:focus,
.g-acceptability-bands-overflow-button.g-pave-health-risk-caution:focus,
.g-acceptability-band.g-pave-health-risk-caution:active,
.g-acceptability-bands-overflow-button.g-pave-health-risk-caution:active,
.g-acceptability-band.g-pave-health-risk-caution.popover-shown,
.g-acceptability-bands-overflow-button.g-pave-health-risk-caution.popover-shown {
  background: #ffda40 !important;
}
.g-acceptability-band.g-pave-health-risk-bad,
.g-acceptability-bands-overflow-button.g-pave-health-risk-bad {
  background-color: #F98A8B !important;
}
.g-acceptability-band.g-pave-health-risk-bad:hover,
.g-acceptability-bands-overflow-button.g-pave-health-risk-bad:hover,
.g-acceptability-band.g-pave-health-risk-bad:focus,
.g-acceptability-bands-overflow-button.g-pave-health-risk-bad:focus,
.g-acceptability-band.g-pave-health-risk-bad:active,
.g-acceptability-bands-overflow-button.g-pave-health-risk-bad:active,
.g-acceptability-band.g-pave-health-risk-bad.popover-shown,
.g-acceptability-bands-overflow-button.g-pave-health-risk-bad.popover-shown {
  background: #f76869 !important;
}
.g-acceptability-band.g-pave-health-risk-alert,
.g-acceptability-bands-overflow-button.g-pave-health-risk-alert {
  background-color: #ffaa33 !important;
}
.g-acceptability-band.g-pave-health-risk-alert:hover,
.g-acceptability-bands-overflow-button.g-pave-health-risk-alert:hover,
.g-acceptability-band.g-pave-health-risk-alert:focus,
.g-acceptability-bands-overflow-button.g-pave-health-risk-alert:focus,
.g-acceptability-band.g-pave-health-risk-alert:active,
.g-acceptability-bands-overflow-button.g-pave-health-risk-alert:active,
.g-acceptability-band.g-pave-health-risk-alert.popover-shown,
.g-acceptability-bands-overflow-button.g-pave-health-risk-alert.popover-shown {
  background: #ff9b0f !important;
}
.g-acceptability-band.g-pave-health-risk-none,
.g-acceptability-bands-overflow-button.g-pave-health-risk-none {
  background-color: #d3d6d9 !important;
}
.g-acceptability-band.g-pave-health-risk-none:hover,
.g-acceptability-bands-overflow-button.g-pave-health-risk-none:hover,
.g-acceptability-band.g-pave-health-risk-none:focus,
.g-acceptability-bands-overflow-button.g-pave-health-risk-none:focus,
.g-acceptability-band.g-pave-health-risk-none:active,
.g-acceptability-bands-overflow-button.g-pave-health-risk-none:active,
.g-acceptability-band.g-pave-health-risk-none.popover-shown,
.g-acceptability-bands-overflow-button.g-pave-health-risk-none.popover-shown {
  background: #c0c4c8 !important;
}
.g-acceptability-band {
  white-space: nowrap;
  font-weight: 500;
}
.g-acceptability-band + .g-acceptability-band {
  margin-left: 8px;
}
.g-acceptability-bands-overflow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  margin-left: 8px;
}
.g-acceptability-bands-overflow-button i {
  margin-top: -3px;
  font-size: 18px;
}
.g-acceptability-bands-overflow-button i:before {
  content: '•••';
}
.g-acceptability-bands-in-overflow-popover .g-dynamic-menu-list {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.g-acceptability-bands-in-overflow-popover .g-acceptability-band + .g-acceptability-band {
  margin-left: 0px;
  margin-top: 8px;
}
#g-acceptability-band-popup {
  width: 100%;
  min-width: 550px;
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container {
  margin-top: 10px;
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-blocks {
  display: flex;
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-blocks .g-acceptability-band-boundary {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  align-items: center;
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-blocks .g-acceptability-band-boundary:first-child .g-acceptability-band-boundary-block {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-blocks .g-acceptability-band-boundary:last-child .g-acceptability-band-boundary-block {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-blocks .g-acceptability-band-boundary.has-value .g-acceptability-band-boundary-block {
  border-color: rgba(0, 0, 0, 0.2);
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-blocks .g-acceptability-band-boundary .g-acceptability-band-boundary-block {
  width: 100%;
  height: 40px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-width: 1.5px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-values {
  display: flex;
  justify-content: space-between;
  margin-left: -25px;
  margin-right: -25px;
}
#g-acceptability-band-popup .g-acceptability-band-boundaries-container .g-acceptability-band-boundaries-values-text {
  width: 50px;
  text-align: center;
}
#g-acceptability-band-popup .g-acceptability-band-popup-actions {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.g-action-buttons-container {
  display: flex;
  flex-direction: column;
}
.g-action-button-item {
  width: 100%;
}
.g-action-button-item + .g-action-button-item {
  margin-top: 10px;
}
.g-action-button {
  display: flex;
  -webkit-transition: all 0.12s ease-in-out;
  -moz-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  border: none;
  width: 100%;
}
.g-action-button:not(.disabled):not([disabled]) {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.g-action-button:not(.disabled):not([disabled]):hover {
  cursor: pointer;
}
.g-action-button:not(.disabled):not([disabled]):active {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.g-action-button-icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  text-align: center;
  opacity: 0.8;
}
.g-action-button-text {
  margin-right: 15px;
}
.g-action-button .g-pave-external-icon {
  margin-left: auto;
}
.g-action-button.default {
  background-color: #ffffff;
}
.g-action-button.default:hover,
.g-action-button.default:focus {
  background-color: #f2f2f2;
}
.g-action-button.good {
  background-color: #d7f5d7;
}
.g-action-button.good:hover,
.g-action-button.good:focus {
  background-color: #afebaf;
}
.g-action-button.info {
  background-color: #d4e9fe;
}
.g-action-button.info:hover,
.g-action-button.info:focus {
  background-color: #a2d1fc;
}
.g-action-button.warning {
  background-color: #ffe98d;
}
.g-action-button.warning:hover,
.g-action-button.warning:focus {
  background-color: #ffdc4a;
}
.g-action-button.error {
  background-color: #faa2a3;
}
.g-action-button.error:hover,
.g-action-button.error:focus {
  background-color: #f87273;
}
.g-action-button.bad {
  background-color: #fdd3d3;
}
.g-action-button.bad:hover,
.g-action-button.bad:focus {
  background-color: #fbb6b6;
}
.g-action-button.alert {
  background-color: #ffb54d;
}
.g-action-button.alert:hover,
.g-action-button.alert:focus {
  background-color: #ff9f19;
}
.g-action-button.none {
  background-color: #e1e3e5;
}
.g-action-button.none:hover,
.g-action-button.none:focus {
  background-color: #c5c9cd;
}
.g-action-button.disabled,
.g-action-button[disabled] {
  color: #333;
  background-color: #e1e3e5;
}
.g-action-button.disabled:hover,
.g-action-button[disabled]:hover,
.g-action-button.disabled:active,
.g-action-button[disabled]:active {
  background-color: #e1e3e5 !important;
}
.g-action-button-separator {
  margin-top: 10px;
  margin-bottom: 10px;
}
.g-containment-barrier-popover .modal-dialog .modal-content {
  overflow: hidden;
}
.g-containment-barrier-popover .modal-dialog .modal-content > .modal-header {
  border-bottom: 1px solid #d3d6d9;
}
.g-containment-barrier-popover .modal-dialog .modal-content > .modal-body {
  padding: 0px;
  background-color: initial;
}
.g-containment-barrier-popover .modal-dialog .modal-content > .modal-footer {
  display: none !important;
}
.g-containment-barrier-popover .gwSelectBox {
  position: relative;
}
.g-containment-barrier-popover .gwSelectBox .select2-container--focus {
  z-index: 2;
}
.g-containment-barrier-popover .gwSelectMenu-list .select2-results__option {
  margin-bottom: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info,
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-selections,
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include {
  margin-left: 15px;
  margin-right: 15px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-title {
  font-weight: bold;
  display: flex;
  align-items: center;
}
.g-containment-barrier-popover .g-cb-content .g-cb-title .g-cb-title-text {
  margin-left: 5px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info .g-cb-title-container {
  margin-right: 50px;
  max-width: 160px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info .g-cb-title-container .g-cb-title-subtitle {
  margin-left: 22px;
  overflow-wrap: break-word;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info-resource {
  display: flex;
  flex: 1 0 auto;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info-resource-image-container {
  border-radius: 100%;
  overflow: hidden;
  height: 40px;
  width: 40px;
  position: relative;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info-resource-image-container:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  content: "";
  box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  z-index: 2;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info-resource-image-container > img {
  width: 100%;
  height: 100%;
}
.g-containment-barrier-popover .g-cb-content .g-cb-info-resource-select {
  padding-left: 10px;
  min-width: 230px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions {
  transition: all 0.3s cubic-bezier(0.72, 0.08, 0.15, 1);
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 150px;
  padding: 15px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration {
  height: 70px;
  padding-left: 0;
  padding-right: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration .g-action-button {
  box-shadow: none;
  border-radius: 0;
  margin-right: 0;
  margin-left: 0;
  min-width: 0;
  flex-direction: row;
  pointer-events: none;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration .g-action-button:not(.visible) {
  overflow: hidden;
  padding: 0;
  margin: 0;
  flex: 0 0 0px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration .g-action-button:not(.visible) .g-action-button-icon {
  font-size: 0px;
  width: 0px;
  height: 0px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration .g-action-button:not(.visible) .g-action-button-text {
  font-size: 0px;
  margin: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration .g-action-button + .g-action-button {
  margin-left: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration .g-action-button-icon {
  font-weight: normal;
  width: auto;
  height: auto;
  font-size: 20px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions.iteration .g-action-button-text {
  margin-left: 15px;
  margin-top: 0;
  font-size: inherit;
  line-height: normal;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions .g-action-button {
  transition: all 0.3s cubic-bezier(0.72, 0.08, 0.15, 1), background-color 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 140px;
  flex: 1 0 140px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions .g-action-button + .g-action-button {
  margin-left: 20px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions .g-action-button-icon {
  transition: all 0.3s cubic-bezier(0.72, 0.08, 0.15, 1);
  width: 35px;
  height: 35px;
  font-size: 30px;
  font-weight: bold;
  margin-right: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-actions .g-action-button-text {
  transition: all 0.3s cubic-bezier(0.72, 0.08, 0.15, 1);
  font-size: 17px;
  line-height: 20px;
  margin: 0;
  margin-top: 5px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration {
  display: none;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-selections .select2-container {
  height: 45px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-selections-reasons .selection .select2-selection {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-selections-iterate-from {
  margin-top: -1px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-selections-iterate-from .selection .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include {
  margin-top: 15px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include-table-container {
  margin-top: 10px;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table {
  width: 100%;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table thead th {
  top: 0;
  position: sticky;
  font-weight: bold;
  background-color: #e9eaec;
  z-index: 1;
  padding: 5px 5px 5px 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table thead th:first-child {
  text-align: center;
  padding: 5px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody tr {
  cursor: pointer;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody tr:nth-child(odd) {
  background-color: #fff;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody tr:hover {
  background-color: #ededed;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody tr.disabled {
  cursor: not-allowed;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody tr.disabled .g-cb-iteration-included-container .g-cb-iteration-included-checkbox + .g-cb-iteration-included {
  cursor: not-allowed;
  background-color: #cecece;
  border-color: #cecece;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody tr td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 10px 5px 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody .g-cb-iteration-included-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody .g-cb-iteration-included-container .g-cb-iteration-included-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody .g-cb-iteration-included-container .g-cb-iteration-included-checkbox + .g-cb-iteration-included {
  transition: all 0.2s ease-in-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: #fff;
  border-style: solid;
  border-color: #cecece;
  border-width: 2px;
  border-radius: 4px;
  margin: 0;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody .g-cb-iteration-included-container .g-cb-iteration-included-checkbox + .g-cb-iteration-included:after {
  content: "\F00C";
  transition: all 0.2s ease-in-out 0s;
  font-family: "icomoon" !important;
  color: #fff;
  font-size: 16px;
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody .g-cb-iteration-included-container .g-cb-iteration-included-checkbox:checked + .g-cb-iteration-included {
  border-color: var(--header-background-color-start);
  background-color: var(--header-background-color-start);
}
.g-containment-barrier-popover .g-cb-content .g-cb-iteration-tasks-to-include table tbody .g-cb-iteration-included-container .g-cb-iteration-included-checkbox:focus + .g-cb-iteration-included {
  border-color: #333;
}
.power-bi-shell {
  height: 100%;
  padding: 12px 20px 20px;
  position: relative;
}
.power-bi-shell .power-bi-content {
  width: 100%;
  height: 100%;
  flex: 0 1 auto;
}
.power-bi-shell .power-bi-content .power-bi-report-link {
  margin: 5px;
}
.power-bi-shell .power-bi-content .power-bi-report-item {
  display: flex;
  margin-bottom: 15px;
}
/* DESKTOP */
.g-body--no-header {
  height: 100%;
  padding-top: 0;
}
.g-body--no-header #g-header-for-binding {
  display: none;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu {
  padding-left: 15px;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove) {
  padding-left: 5px !important;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu {
  padding-left: 15px;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove) {
  padding-left: 5px !important;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu {
  padding-left: 15px;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove) {
  padding-left: 5px !important;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu {
  padding-left: 15px;
}
.dropdown > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-grid-actions-dropdown.dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove),
.g-dynamic-menu-list > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li:not(.doc-menu-item):not(.g-loggedin-info-section):not(.divider):not(.dropdown-header):not(.g-jump-item):not(.g-jump-add-remove) {
  padding-left: 5px !important;
}
.gwConversationList__send-message-container {
  align-items: flex-end;
  flex-direction: column;
}
.gwConversationList__send-message-button-icon {
  display: none;
}
/* DESKTOP */
.panel-title {
  font-size: 15px;
}
.gwHorizontalWheelScroll {
  overflow-x: hidden;
}
.gwHorizontalWheelScroll:hover {
  overflow-x: auto;
}
/* DESKTOP */
.gw-img-edit:not(.gw-viewonly):not(.disabled).gw-drag-over .gw-img {
  opacity: 0.5;
}
.gw-img-edit:not(.gw-viewonly):not(.disabled):hover {
  cursor: pointer;
}
.gw-img-edit:not(.gw-viewonly):not(.disabled):hover .gw-img-wrap-vert {
  display: block;
}
.gw-img-edit:not(.gw-viewonly):not(.disabled):hover .gw-img {
  opacity: 0.5;
}
.gw-img-edit.disabled {
  opacity: 0.5;
  filter: grayscale(100%);
}
.gw-img-edit.disabled:hover,
.gw-img-edit.disabled:focus {
  color: #999;
}
.gw-img-edit .gw-img-wrap-vert {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1px;
  background-color: #fff;
  border-radius: 4px 0;
  display: none;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap {
  color: var(--input-control-text-color);
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-weight: Normal;
  font-size: 14px;
  width: 94px;
  height: 30px;
  padding: 4px;
  border-radius: 5px;
  margin: 0 2px 2px 2px;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap label {
  margin: 0px;
  display: block;
  height: 22px;
  color: var(--input-control-text-color);
  font-family: 'Noto Sans', Microsoft YaHei, PingFang SC, 'Noto Sans KR', Arial, Sans-Serif;
  font-weight: Normal;
  font-size: 14px;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap .gw-img-btn-edit,
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap .gw-img-btn-remove {
  float: left;
  font-size: 16px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 6px 0 0;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap .gw-img-text {
  float: left;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap .gw-img-input {
  display: none;
  height: 0px;
  width: 0px;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap:hover {
  background-color: var(--theme-hover);
  color: var(--theme-hover-text);
  cursor: pointer;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap:hover label {
  color: var(--theme-hover-text);
  cursor: pointer;
}
.gw-img-edit .gw-img-wrap-vert .gw-img-input-wrap:first-child {
  margin-top: 2px;
}
.gwValidationSummary-alert-entity-message.alert-warning:hover .g-tickbox label {
  border-color: #575a5d;
  width: 16px;
  height: 16px;
}
.gwValidationSummary-alert-entity-message.alert-warning:hover .g-tickbox label span {
  background: #fef293;
  left: 10px;
}
.gwValidationSummary-alert-entity-message.alert-warning:hover .g-tickbox label:after {
  border: 1px solid #fdca30;
  border-top: none;
  border-right: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
.g-workflow-menu-dialog .modal-dialog.g-modal-autoresize .modal-content {
  min-width: 800px;
  min-height: 500px;
}
.g-dialog-change-branch-department .modal-content {
  min-width: 500px;
}
