.gwSearchBox.gwAddressSearchBox {
	height: 100%;
}

.gwAddressSearchBox {
	overflow: hidden;
	height: 100%;
	background: @white;
	border: 1px solid @grey;
	border-radius: 4px;

	&.alert-danger .form-control:first-child {
		border: 1px solid @state-error;
		border-radius: 4px;
	}

	&.gwAddressSearchBox.alert-message-error .form-control:first-child {
		border: 1px solid @state-message-error;
		border-radius: 4px;
	}

	&.gwAddressSearchBox.alert-warning .form-control:first-child {
		border: 1px solid @state-warning;
		border-radius: 4px;
	}

	&.gwAddressSearchBox.alert-info .form-control:first-child {
		border: 1px solid @state-info;
		border-radius: 4px;
	}

	.gwSearchBox-wrap {
		height: @g-control-height;
	}

	.form-control:first-child {
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-radius: 4px 4px 0 0;
	}

	.g-selected-address {
		min-height: 55px;
		padding: 6px 8px;
		font-size: 12px;
		line-height: normal;
	}
}

.gwAddressSearchBox-results-item {
	font-size: @g-menu-font-size;
	border-bottom: 1px solid @grey;
	padding: 5px 8px;
	cursor: pointer;

	&:nth-last-child(2) {
		border-bottom: none;
	}
}

.g-selected-address,
.gwAddressSearchBox-results-item {

	.g-address-capabilities {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;

		span {
			background-color: #EBEEF3;
			border: 1px solid #D3D6D9;
			border-radius: 6px;
			margin: 2px 5px 2px 0;
			text-align: center;
			height: 22px;
			min-width: 40px;
			padding: 0 5px;
		}
	}

	&.active {
		.g-address-capabilities {
			span {
				background-color: transparent;
				border: 1px solid @white;
			}
		}
	}
}

.gwAddressSearchBox,
.gwAddressSearchBox-results-item {
	.g-orginfo {
		cursor: pointer;
		font-weight: bold;
	}

	.g-address div,
	.g-address-code {
		cursor: pointer;
		.text-overflow();
		color: @Theme_ValueText;
	}
}

.g-address-code {
	font-weight: bold;
}
