.gwConversationManager {
	width: 100%;
	height: 100%;

	.g-controls-panel {
		display: flex;
		flex-direction: row;
	}

	.g-textarea-panel {
		width: 100%;
		height: 120px;

		textarea {
			height: 100%;
			resize: none;
			background-color: @InputControl_BackgroundColorEnd;

			&:focus {
				border-color: @InputControl_BorderBrushStart;
			}
		}
		
		span {
			float: right;
			text-align: right;
		}
	}

	.g-button-panel {
		width: 125px;
		padding-left: 5px;
		vertical-align: top;
		display: flex;
		flex-direction: column;

		button {
			min-width: 100px;
		}
	}

	.g-conversation-panel {
		& .g-conversation-readonly {
			background-color: @InputControl_ReadOnlyUnfocusedBackgroundColorStart;
			color: @InputControl_ReadOnlyTextColor;

			.g-message-sender-time {
				color: @InputControl_ReadOnlyTextColor
			}
		}

		position: absolute;
		top: 140px;
		right: 0;
		bottom: 10px;
		left: 0;
		margin-top: 5px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		font-size: 14px;
		text-align: left;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

	.g-conversation-panel:after {
		clear: both;
		content: "";
		height: 3px;
		display: block;
	}

	.g-conversation-date {
		float: left;
		width: 100%;
		padding: 10px 6px;
		font-weight: bold;
		text-align: center;
		color: @GroupBox_HeaderTextColor;
	}

	.g-conversation-message {
		width: 85%;
		margin: 3px 6px;
		padding: 6px;
		border-radius: 4px;
	}

	.g-conversation-message-other-staff,
	.g-conversation-message-other-contact,
	.g-conversation-message-self-staff,
	.g-conversation-message-self-contact {
		.g-conversation-message;
		color: @white;
	}

	.g-conversation-message-other-staff,
	.g-conversation-message-other-contact {
		float: left;
	}

	.g-conversation-message-self-staff,
	.g-conversation-message-self-contact {
		float: right;
	}

	.g-conversation-message-other-staff,
	.g-conversation-message-self-staff {
		background-color: @Theme_ValueText;
	}

	.g-conversation-message-other-contact,
	.g-conversation-message-self-contact {
		background-color: @Theme_DefaultButton;
	}

	.g-conversation-message-system {
		.g-conversation-message;
		color: @black;
		float: left;
		background-color: #F2F2F2;

		.g-message-sender-time {
			color: @black;
		}
	}

	.g-message-body {
		display: inline;
		word-wrap: break-word;
		white-space: pre-wrap;
	}

	.g-message-sender-time-score {
		display: inline-block;
		float: right;
		margin-left: 6px;
		font-size: 12px;
		color: @black;
		text-align: right;
	}

	.g-message-sender-time {
		display: inline-block;
		padding-top: 2px;
		padding-bottom: 2px;
		color: @white;
	}

	.g-message-score {
		display: inline-block;
		padding: 2px 5px 2px 5px;
		border-radius: 4px;
		background-color: rgba(242,242,242,.8);

		.btn {
			.opacity-hover();
		}
	}

	.g-thumbs {
		font-size: 14px;
		color: @black;
	}

	.g-thumbs:hover {
		.opacity(.5);
		cursor: pointer;
	}

	.g-thumbs-active {
		.g-thumbs;
	}

	.g-thumbs-active:hover {
		.opacity(.5);
		cursor: pointer;
	}

	.g-thumbs-readonly {
		.g-thumbs;
		color: @Control_ReadOnlyWatermark;
	}

	.g-thumbs-readonly:hover {
		cursor: default;
	}

	.g-follower-manager {
		margin-top: auto;

		.btn:not([disabled]) {
			.opacity-hover();
		}
	}

	.g-follower-list {
		overflow-y: auto;
		width: 320px;
	}

	.g-follower {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 10px 0;
		cursor: default;
	}

	.g-follower-image-section {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		display: flex;
		margin: 0 8px;
		z-index: 1000;
		overflow: hidden;
		text-align: center;
	}

	.g-follower-icon-section {
		height: 40px;
		width: 40px;
		align-items: center;
		justify-content: center;
		display: flex;
		margin: 0 8px;
		text-align: center;
	}

	.g-follower-image {
		height: 40px;
		width: 40px;
	}

	.g-follower-icon {
		font-size: 40px;
		color: #32324a;
	}

	.g-follower-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 70%;
	}

	.g-follower-remove-button {
		margin-left: auto;
		margin-right: 8px;
	}

	.g-follower-info {
		padding: 10px 15px;

		p {
			margin: 0;
		}

		.g-follower-info-value {
			color: @Theme_ValueText;
		}
	}

	.g-addfollowers-menu {

		.divider {
			height: 1px;
			background-color: #aaa;
		}
	}

	.btn.btn-link {
		background: 0;
		padding: 0;
		text-transform: none;
		text-decoration: none;
		color: @Body_LinkColor;
		display: inline-flex;
		align-items: center;
		.g-font-size-px(@Body_FontSize);

		i {
			margin-right: 5px;
			.g-font-size-px(@Body_FontSize);
		}

		input[type="checkbox"] {
			.g-height-px(@Body_FontSize);
			margin-top: 3px;
			margin-right: 5px;
		}

		&.g-btn-loading > i {
			display: inline;
		}

		&.text-left {
			text-align: left;
			justify-content: flex-start;
		}

		&.btn-icon {
			min-width: 0 !important;
			padding: 0;

			i {
				margin: 0;
				padding: 2px;

				&:hover {
					.opacity(.5);
				}
			}
		}

		&:focus {
			border-color: transparent;
		}
	}

	.popover {
		z-index: 1040;

		.popover-content {
			max-height: none !important;
		}
	}
}

.g-dialog-conversation .gwConversationManager {
	.g-conversation-panel {
		top: 190px;
		right: 15px;
		bottom: 55px;
		left: 15px;
	}
}

.g-modal.conversation .g-modal-autoresize {
	.modal-content {
		height: auto;
	}
}

.g-dialog-conversation-add-email{
	.modal-content{
		min-height: auto !important;
		min-width: min(90vw, 300px) !important;
		height: 160px !important;
	}
}
