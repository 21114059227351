.reset-password-container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	& > div {
		max-width: 500px;
	}
}
