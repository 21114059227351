@g-containment-barrier-content-margin: 15px;
@g-cb-transition: all 0.3s cubic-bezier(0.72, 0.08, 0.15, 1);

.g-containment-barrier-popover {
	.modal-dialog .modal-content {
		overflow: hidden;

		& > .modal-header {
			border-bottom: 1px solid #d3d6d9;
		}

		& > .modal-body {
			padding: 0px;
			background-color: initial;
		}

		& > .modal-footer {
			display: none !important;
		}
	}

	.gwSelectBox {
		position: relative;

		.select2-container--focus {
			z-index: 2;
		}
	}

	.gwSelectMenu-list .select2-results__option {
		margin-bottom: 0;
	}

	.g-cb-content {
		.g-cb-info,
		.g-cb-iteration-selections,
		.g-cb-iteration-tasks-to-include {
			margin-left: @g-containment-barrier-content-margin;
			margin-right: @g-containment-barrier-content-margin;
		}

		.g-cb-title {
			font-weight: bold;
			display: flex;
			align-items: center;

			.g-cb-title-text {
				margin-left: 5px;
			}
		}

		.g-cb-info {
			display: flex;
			align-items: flex-start;
			margin-top: @g-containment-barrier-content-margin;

			.g-cb-title-container {
				margin-right: 50px;
				max-width: 160px;

				.g-cb-title-subtitle {
					margin-left: 22px;
					overflow-wrap: break-word;
				}
			}

			&-resource {
				display: flex;
				flex: 1 0 auto;

				&-image-container {
					border-radius: 100%;
					overflow: hidden;
					height: 40px;
					width: 40px;
					position: relative;
					.user-select(none);

					&:before {
						.g-stretch-to-parent-container-absolute();
						content: "";
						box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.2);
						border-radius: 100%;
						z-index: 2;
					}

					& > img {
						width: 100%;
						height: 100%;
					}
				}

				&-select {
					padding-left: 10px;
					min-width: 230px;
				}
			}
		}

		.g-cb-actions {
			transition: @g-cb-transition;
			display: flex;
			justify-content: center;
			margin-top: 5px;
			margin-bottom: 5px;
			height: 150px;
			padding: @g-containment-barrier-content-margin;

			&.iteration {
				height: 70px;
				padding-left: 0;
				padding-right: 0;

				.g-action-button {
					box-shadow: none;
					border-radius: 0;
					margin-right: 0;
					margin-left: 0;
					min-width: 0;
					flex-direction: row;
					pointer-events: none;

					&:not(.visible) {
						overflow: hidden;
						padding: 0;
						margin: 0;
						flex: 0 0 0px;

						.g-action-button-icon {
							font-size: 0px;
							width: 0px;
							height: 0px;
						}

						.g-action-button-text {
							font-size: 0px;
							margin: 0;
						}
					}

					& + .g-action-button {
						margin-left: 0;
					}

					&-icon {
						font-weight: normal;
						width: auto;
						height: auto;
						font-size: 20px;
					}

					&-text {
						margin-left: 15px;
						margin-top: 0;
						font-size: inherit;
						line-height: normal;
					}
				}
			}

			.g-action-button {
				transition: @g-cb-transition, background-color 0.2s ease-in-out;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				min-width: 140px;
				flex: 1 0 140px;

				& + .g-action-button {
					margin-left: 20px;
				}

				&-icon {
					transition: @g-cb-transition;
					width: 35px;
					height: 35px;
					font-size: 30px;
					font-weight: bold;
					margin-right: 0;
				}

				&-text {
					transition: @g-cb-transition;
					font-size: 17px;
					line-height: 20px;
					margin: 0;
					margin-top: 5px;
				}
			}
		}

		.g-cb-iteration {
			display: none;

			&-selections {
				.select2-container {
					height: 45px;
				}

				&-reasons .selection .select2-selection {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

				&-iterate-from {
					margin-top: -1px;

					.selection .select2-selection {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}
				}
			}

			&-tasks-to-include {
				margin-top: 15px;

				&-table-container {
					margin-top: 10px;
					max-height: 300px;
					overflow: auto;
					-webkit-overflow-scrolling: touch;
				}

				table {
					width: 100%;

					thead {
						th {
							top: 0;
							position: sticky;
							font-weight: bold;
							background-color: lighten(@grey, 8%);
							z-index: 1;
							padding: 5px 5px 5px 0;

							&:first-child {
								text-align: center;
								padding: 5px;
							}
						}
					}

					tbody {
						tr {
							cursor: pointer;

							&:nth-child(odd) {
								background-color: @white;
							}

							&:hover {
								background-color: #ededed;
							}

							&.disabled {
								cursor: not-allowed;

								.g-cb-iteration-included-container .g-cb-iteration-included-checkbox + .g-cb-iteration-included {
									cursor: not-allowed;
									background-color: #cecece;
									border-color: #cecece;
								}
							}

							td {
								max-width: 200px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								padding: 5px 10px 5px 0;
							}
						}

						.g-cb-iteration-included-container {
							display: flex;
							justify-content: center;
							align-items: center;

							.g-cb-iteration-included-checkbox {
								position: absolute; // take it out of document flow
								opacity: 0; // hide it
								pointer-events: none; // make it non clickable
								& + .g-cb-iteration-included {
									transition: all 0.2s ease-in-out 0s;
									display: flex;
									justify-content: center;
									align-items: center;
									cursor: pointer;
									width: 20px;
									height: 20px;
									background: #fff;
									border-style: solid;
									border-color: #cecece;
									border-width: 2px;
									border-radius: 4px;
									margin: 0;

									&:after {
										content: "\F00C";
										transition: all 0.2s ease-in-out 0s;
										font-family: "icomoon" !important;
										color: #fff;
										font-size: 16px;
									}
								}

								&:checked + .g-cb-iteration-included {
									border-color: @Header_BackgroundColorStart;
									background-color: @Header_BackgroundColorStart;
								}

								&:focus + .g-cb-iteration-included {
									border-color: #333;
								}
							}
						}
					}
				}
			}
		}
	}
}


