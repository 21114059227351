.g1-map-component-marker-label {
	max-width: 250px;
	padding: 0 4px;
	font-family: @default-font;
	font-size: 12px;
	font-weight: bold;
	color: @black;
	text-overflow: ellipsis;
	white-space: nowrap;
	background-color: @white;
	border: 1px solid @grey;
	transform: translate(-50%, -62px);
}

.g1-map-component-container {
	padding: 4px;
}

.g1-map-component {
	border-radius: 5px;
}

.tracking-map-popup {
	max-height: 100px;

	.tracking-map-popup__progress:not(:last-child) {
		border-bottom: 1px solid @grey;
		margin-bottom: 5px;
	}
}

.tracking-map-popup__header {
	padding: 0px 5px;
	font-size: 14px;
	font-weight: bold;
	color: @Theme_DefaultButton;

	&:hover {
		color: @Theme_FieldTitle;
	}
}

.tracking-map-popup__progress {
	padding: 0px 5px;
}
