.gwListView {
	display: flex;
	flex-direction: column;
	height: 100%;

	.g-listview-header {
		flex: 0 0 @g-control-height;
		align-items: center;
		display: flex;
		line-height: @g-control-height;

		.g-listview-title-container {
			height: 100%;
			width: 100%;
			.text-overflow();
		}

		.g-listview-title-container--Left {
			text-align: left;
		}

		.g-listview-title-container--Center {
			text-align: center;
		}

		.g-listview-title-container--Right {
			text-align: right;
		}


		.g-listview-title {
			font-weight: bold;
			.g-font-size-px(@Grid_TitleFontSize);
			color: @Grid_TitleFontColor;
		}

		.g-listview-sub-title-container {
			flex: 1 0 0%;
			height: 100%;

			.g-kg-actions-container {
				cursor: pointer;
				color: @Theme_Icons;

				.g-kg-action, .g-container .g-kg-action {
					&:hover {
						color: @Theme_ValueText;

						span,
						i {
							opacity: 0.5;
						}
					}
				}
			}
		}
	}

	.g-listview-body {
		flex: 1 1;
		display: flex;
		flex-direction: column;
		overflow-y: hidden;

		.g-listview-noRecords {
			padding: 17px;
			min-height: 30px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			flex: 1 1;
			border: 1px solid @InputControl_BorderBrushStart;
			border-radius: 4px;
		}
	}

	.g-count {
		.g-font-size-px(@Grid_ItemCountFontSize);
		color: @Grid_ItemCountFontColor;
	}

	&.g-stackpanel {
		div {
			position: static;
			height: auto;
		}

		.g-anchored {
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
		}
	}
}

.gwListView-ul {
	min-height: 30px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	margin: 10px;
	flex: 1 1;
	border: 1px solid @InputControl_BorderBrushStart;
	border-radius: 4px;

	&.g-templated {
		margin: 0;
	}

	> li {
		width: 100%;
		-webkit-transform: translate3d(0, 0, 0);

		&.well {
			padding: 0;
			margin-bottom: 0;
			background-color: transparent;
			border-top: 0;
			border-bottom: 1px solid @InputControl_BorderBrushStart;
			border-left: 0;
			border-right: 0;
			border-radius: 0;
			box-shadow: none;

			&:last-child {
				border-bottom: 0;
			}

			&.templated {
				position: relative;

				.g-absolute {
					float: unset;
					width: 0%;

					.g-anchored {
						position: absolute;
					}
				}
			}

			i.gwListView-li-button-icon {
				padding: 5px;
			}

			i.gwListView-li-button-icon:hover {
				opacity: 0.5;
			}
		}

		&.gwListView-li:hover a {
			text-decoration: none;
		}
	}
}

.gwListView-li-inner {
	position: relative;
	display: inline-block;
	width: 100%;

	&.g-editable {
		padding-left: 17.5px;
	}
}

.gwListView-li > a {
	position: absolute;
	z-index: 1;

	&.non-templated {
		position: relative;
		display: block;
		padding: .7em 15px .7em 15px;
		.text-overflow();

		i {
			position: absolute;
			top: 50%;
			right: 10px;
			margin-top: -7px;
			text-align: right;
		}
	}
}

.gwListView-ul {
	.gwListView-li {
		display: flex;
		flex-direction: column;

		&-template {
			display: flex;
			flex-direction: column;
		}

		&-button {
			display: flex;
			justify-content: flex-end;

			&--edit, &--remove {
				align-self: center;

				> a {
					padding: 5px;
					color: @Theme_Icons;
				}
			}
		}
	}
}

.gwListView.alert-danger {
	background: none;

	.g-listview-header {
		background-color: #fafafa;
	}

	.gwListView-ul {
		background-color: @state-error-pale;
		border-color: @state-error;

		.gwListView-li {
			border-color: @state-error;
		}
	}
}
