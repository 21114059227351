header {
	position: relative;
}

@AnimationDuration: 2s;
@IndicatorBarWidth: 200px;
@IndicatorBarStartPosition: -1 * @IndicatorBarWidth;
@IndicatorBarEndPosition: 8000px;

#global-loading-indicator {
	display: flex;
	position: absolute;
	height: 5px;
	width: 100%;
	background-color: @Icons_Color_Lighten50;
	overflow: hidden;
	z-index: 13;
}

#global-loading-indicator > div {
	padding: 0;
	margin: 0;
	content: '';
	background-color: @Icons_Color;
	width: @IndicatorBarWidth;
	height: 100%;
	will-change: transform;
	z-index: 1001;
	left: @IndicatorBarStartPosition;
	animation: indicatorBarMovement @AnimationDuration cubic-bezier(0.97, 0.4, 0.99, 0.93) infinite;
}


@keyframes indicatorBarMovement {
	0% {
		transform: translateX(@IndicatorBarStartPosition);
	}

	100% {
		transform: translateX(@IndicatorBarEndPosition);
	}
}
