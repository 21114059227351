.g-modal-workflow,
.g-popout-workflow {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.g-workflow-sidebar {
	margin-right: 10px;
	width: 240px;
	height: 100%;
	display: flex;
	flex-direction: column;

	.g-workflow-default-sidebar {
		overflow-y: auto;
		margin-top: @g-control-height;
	}
}

.g-workflow-main {
	height: 100%;
	flex-grow: 1;

	.g-container {
		height: 100%;
	}

	.tox-tinymce {
		height: calc(~'100% - 1px') !important;
	}
}
