.g-img-resizer-dialog .modal-dialog.g-modal-autoresize .modal-body {
	background-color: #333;
	padding: 0px;
}

.g-img-resizer {
	height: 100%;

	#g-img-resizer-cropit {
		overflow: hidden;
		height: 100%;
		position: relative;

		.cropit-preview-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;

			.cropit-preview-image-container {
				background-color: #F2F2F2;
				border-radius: 3px;
				pointer-events: none;
			}

			.cropit-preview.cropit-image-loaded {
				.cropit-preview-background {
					opacity: .2;
					cursor: move;
				}

				.cropit-preview-image {
					cursor: move;
				}
			}

			.cropit-image-zoom-input[disabled] {
				opacity: .2;
			}
		}
	}

	.g-img-resizer-menu {
		position: absolute;
		bottom: 0;
		background-color: #F2F2F2;
		width: 100%;
		height: 40px;
		padding: 0 0 0 7px;
		display: flex;
		justify-content: center;
		align-items: center;

		input.cropit-image-zoom-input {
			width: 150px;
			margin-left: 7px;
			padding: 0;
		}

		.g-img-resizer-btn {
			height: 16px;
			width: 16px;
			margin-left: 7px;
			cursor: pointer;
		}
	}
}
