.g-grid-error {
	margin: 0;
	font-size: 16px;
	text-align: center;
	border-radius: 0;
	.g-stretch-to-parent-container-absolute();

	span {
		position: absolute;
		top: 50%;
		display: block;
		margin-top: -10px;
	}
}

.g-searchlist-notifications {
	position: inherit;
	margin-top: 1px;
	margin-left: 1px;
}

.gwDataGrid__notifications {
	z-index: 6;
	white-space: nowrap;
	cursor: pointer;
	position: absolute;
	top: -14px;
	left: 6px;
	line-height: normal;

	.label-danger {
		min-width: @g-grid-notifications-label-danger-size;
		background-color: @state-error;
		height: @g-grid-notifications-label-danger-size;

		i {
			font-size: @g-grid-notifications-label-danger-icon-size;
		}

		span {
			font-size: @g-grid-notifications-label-danger-font-size;
			font-weight: normal;
		}
	}
}

.grid__actions-filter,
.gwDataGrid__actions-filter,
.gwDataMappingSourceGrid__actions-filter,
.gwDataMappingTargetGrid__actions-filter {
	z-index: 6;
	white-space: nowrap;
	cursor: pointer;
	margin: 2px 0 0 0;

	.g-hidden-filter,
	.g-active-action-filter,
	.g-dormant-action-filter {
		width: @g-hidden-filter-width;
		line-height: 23px;
		padding: 0;
		font-size: 14px;
		vertical-align: middle;
		display: block;
		cursor: pointer;
	}

	.g-dormant-action-filter {
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}

	.g-active-action-filter:hover {
		opacity: 0.5;
	}
}

.g-dormant-column-filter,
.g-active-column-filter {
	float: right;
	font-size: 14px;
	cursor: pointer;
	margin: 0px 3px;
	z-index: 1;
}

.g-dormant-column-filter {
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
}

.g-active-column-filter:hover {
	opacity: 0.5;
}

.g-grid-actions {
	min-width: 0;
	padding: 0;
	margin: 0;
	list-style: none;

	ul {
		margin-bottom: 0;
		margin-left: 0;
	}

	li {
		height: auto;
		position: relative;
		padding: 0;
	}

	.g-search-results-loading {
		padding: 5px 10px;
		color: @Grid_HeaderTextColor;
	}

	a,
	button {
		display: block;
		font-size: 16px;
		background: @Tiles_LargeBackgroundBrush;
		border: 2px solid @Tiles_LargeBackgroundBrush;
	}

	a,
	button,
	.g-menu-item-icon {
		color: @Grid_HeaderTextColor;
	}

	a {
		font-size: 16px;
		border-radius: 5px;
		text-align: center;
		margin-right: 4px;
		float: left;
	}

	a:hover {
		.opacity(.6);
		border-color: @Tiles_LargeBackgroundBrush_Darken15;
	}
}

.g-grid-action-popover .popover-content ul > li {
	position: relative;
}

.g-icon-section-container {
	position: relative;
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	right: 0;
	border-bottom: 1px solid @Grid_ViewportBorderColor;
	border-right: 1px solid @Grid_ViewportBorderColor;
	background-color: @Grid_ViewportAlternatingRowColor;

	.g-dynamic-icon-list {
		padding: 0;
		margin: 0;
		float: left;
	}
}

.g-action-icon-item {
	height: 100px;
	width: 100px;
	display: block;
	float: left;

	.g-menu-item-icon-container {
		position: relative;
		height: 100%;
		width: 100%;
		color: @Theme_ValueText;
		float: left;

		.g-action-icon {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -90%);
		}

		i.g-action-icon {
			font-size: 40px;
		}

		img.g-action-icon {
			height: 40px;
			width: 40px;
		}

		.g-action-icon-number-container {
			position: absolute;
			height: 15px;
			width: 15px;
			left: 27%;
			top: 12%;

			&.g-icon-number {
				background: #d8e0eb;

				> span {
					color: @Icons_Color;
				}
			}

			> span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -55%);
				font-size: 12px;
				font-weight: bold;
			}
		}

		.g-action-icon-text-container {
			height: 40%;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 0 8px;
			text-align: center;

			> span:not(.g-submenu-action-icon-text) {
				display: block;
				display: -webkit-box;
				height: 35px;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&.disabled {
			color: @disabled-text;
			cursor: default;
		}
	}

	&:hover {
		.opacity(0.5);
	}
}

.g-grid-icon-item-popover.popover {
	&.top {
		margin-top: 4px;
	}

	&.bottom {
		margin-top: -17px;
	}
}

#toast-container {
	&.gwSearchList__drop-message,
	&.gwDataGrid__drop-message {
		position: absolute;
		bottom: 14px;
		left: 14px;
		margin: 0;
		height: 38px;
		font-size: 12px;

		.toast {
			padding: 0 30px 0 37px;
		}

		.toast-icon {
			font-size: 14px;
			top: initial;
			margin-top: initial;
			line-height: 38px;
		}

		.toast-message {
			line-height: 38px;
		}

		.toast-close-button {
			font-size: 14px;
			text-shadow: none;
		}
	}
}
