#tinymce {
	width: auto;
	height: auto;
	padding-top: 0;
}

.tox {
	&.tox-tinymce {
		padding-top: 15px;
		border: 1px solid @grey;
		border-radius: 4px;
		background-color: @white;

		.tox-tbtn {
			&:active,
			&:focus {
				background: transparent;
			}

			&[aria-disabled='true'] {
				&:hover {
					background: transparent;
				}

				&:active,
				&:focus {
					svg {
						fill: rgba(34, 47, 62, 0.5);
					}
				}
			}
		}

		.tox-toolbar__group {
			padding: 0;

			&:first-child {
				padding-left: 6px;
			}
		}

		.tox-edit-area,
		.tox-statusbar {
			border-top: 1px solid @grey;
		}
	
		.tox-statusbar__path-item, 
		.tox-statusbar__path-divider {
			display: none
		}
	}

	.tox-menu {
		&.tox-collection--list {
			.tox-collection__group {
				.tox-collection__item--active {
					background-color: var(--theme-hover);
					color: white !important;
				}
			}
		}

		.tox-collection__item-label span {
			background-color: transparent !important;
		}
	}

	.tox-dialog {
		border-radius: @modal-border-radius !important;
		.box-shadow(@modal-dialog-box-shadow) !important;

		* {
			.box-sizing(border-box);
		}

		.tox-dialog__header {
			height: @g-modal-header-max-height;
			padding: 2px 10px 3px;
			cursor: default;
			background-color: @Body_BackgroundColorStart;

			.tox-button--naked {
				color: @Theme_Icons;
				.opacity-hover;

				&:hover:not(:disabled) {
					background-color: transparent;
				}
			}
		}

		.tox-dialog__body {
			border-top: 1px solid @grey;
			background-color: @Body_BackgroundColorStart;

			.tox-dialog__body-content {
				padding: 15px 10px 30px;

				.tox-form__group {
					position: relative;
					height: @g-control-height;
					margin-bottom: 0;

					.tox-label {
						position: absolute;
						z-index: 99999;
						padding: 5px 8px 0;
					}

					
					.tox-textfield {
						height: @g-control-height;
						padding: 18px 7px 4px;
						border-radius: 0;
						border-color: @InputControl_BorderBrushStart;
						color: @InputControl_TextColor;
						font-size: 14px;

						&:focus {
							background: var(--input-control-focused-background-color-end);
							border: 1px solid var(--input-control-focused-border-brush-end);
							box-shadow: none;
						}
						
						&:first-child {
							border-top-left-radius: @InputControl_BorderRoundedCorners;
							border-top-right-radius: @InputControl_BorderRoundedCorners;
						}
					}

					.tox-form__controls-h-stack {
						.tox-form__group {
							margin-left: 0;
							
							.tox-button[title='Constrain proportions'] {
								position: relative;
								top: 4px;
								left: 4px;
							}

						}
					}

					.tox-listbox {
						height: @g-control-height;
						padding: 18px 7px 4px;
						border-radius: 0;
						border-color: @InputControl_BorderBrushStart;
						color: @InputControl_TextColor;

						&__select-label {
							font-size: 14px;
							color: @Button_TextColor;
						}

						&:last-child:not(&--active) {
							border-bottom-left-radius: @InputControl_BorderRoundedCorners;
							border-bottom-right-radius: @InputControl_BorderRoundedCorners;
						}

						&--active {
							background: var(--input-control-focused-background-color-end);
							border: 1px solid var(--input-control-focused-border-brush-end);
							border-radius: 0;
						}

						&:focus {
							background: var(--input-control-focused-background-color-end);
							border: 1px solid var(--input-control-focused-border-brush-end);
							box-shadow: none;
						}


						span {
							margin-left: 0;
						}
					}
				}
			}
		}

		.tox-dialog__footer {
			padding: 6px;
			border-top: 1px solid @grey;

			.tox-button {
				width: 100px;
				padding: 2px;
				margin-left: 5px;
				font-size: @g-footer-button-font-size;
				font-weight: bold;

				&.tox-button--secondary {
					color: @Button_TextColor;
					background-color: transparent;

					&:hover {
						color: @Button_TextHoverColor;
						background-color: @Button_BackgroundHoverColorStart;
					}
				}
			}
		}

		.tox-dialog__title, 
		.tox-label,
		.tox-listbox__select-label,
		.tox-collection__item-label,
		.tox-button {
			font-family: @default-font;
		}

		.tox-label {
			font-size: @g-label-font-size;
		}
	}

	.tox-dialog-wrap .tox-dialog-wrap__backdrop {
		background-color: @black;
		opacity: 0.5;
	}
} 
