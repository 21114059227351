.flag-icon-background {
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
}

.flag-icon {
	.flag-icon-background;
	background-image: url('@{ContentBaseUri}/Shared/components/gwFlag/flags/placeholder-flag.41ne5BLoWuBayPBs0rc7rhjNf0c.png');
	position: relative;
	display: inline-block;
	width: unit((4 / 3), em);
	line-height: 1em;

	&:before {
		content: '\00a0';
	}

	&.flag-icon-squared {
		width: 1em;
	}
}
