.g-channel {
	display: flex;
	flex: 1 0 0%;
	min-width: 0; //necessary for text ellipsis to work (see css-tricks)
	min-height: 0;
	flex-direction: var(--g-section-cells-direction); // --g-section-cells-direction comes from gwSection
	--g-channel-resource-image-size: ~"calc(var(--g-channel-header-size) - 20px)";
	--g-channel-status-indicator-size: @channel-status-indicator-size;
	--g-channel-status-indicator-icon-size: @status-indicator-icon-size-channel;
	--g-channel-name-text-align: center;
	--g-channel-header-image-position: relative;

	.small-channel & {
		--g-channel-resource-image-size: ~"calc(var(--g-channel-header-size) - 45px)";
		@g-channel-status-indicator-size-intermediate: @channel-status-indicator-size - 4px;
		--g-channel-status-indicator-size: @g-channel-status-indicator-size-intermediate;
		@g-channel-status-indicator-icon-size-intermediate: @status-indicator-icon-size-channel - 2px;
		--g-channel-status-indicator-icon-size: @g-channel-status-indicator-icon-size-intermediate;
		--g-channel-name-text-align: flex-start;
		--g-channel-header-info-container-size: 100%;
		--g-channel-header-image-position: absolute;

		.g-channel-header .g-health-bar {
			border-radius: 0;
		}
	}

	.g-channel-header {
		display: flex;
		flex-direction: var(--g-channel-header-direction);
		flex: 0 0 var(--g-channel-header-size);
		justify-content: center;
		align-items: center;
		border-left: @channel-header-border;
		border-top: @channel-header-border;
		background-color: #fff;

		.g-channel-header-image-container {
			.transition(0.12s, ease-in-out);
			border-radius: 100%;
			overflow: hidden;
			flex: 0 0 auto;
			height: var(--g-channel-resource-image-size);
			width: var(--g-channel-resource-image-size);
			position: var(--g-channel-header-image-position);
			background-color: @color-light-black;
			z-index: 2;
			.user-select(none);

			&:hover,
			&:focus {
				cursor: pointer;

				.g-channel-header-image {
					opacity: 0.8;
				}
			}

			&:active,
			&.popover-shown {
				&:before {
					.element-elevation(-3);
				}
			}

			&:before {
				.g-stretch-to-parent-container-absolute();
				content: "";
				z-index: 3;
				box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.2);
				border-radius: 100%;
			}

			.g-channel-header-image {
				.transition(0.12s, ease-in-out);
				width: 100%;
				height: 100%;
				z-index: 2;
			}
		}

		&.g-group-channel-header {
			--g-channel-resource-image-size: ~"calc(var(--g-channel-header-size) - 30px)";
			--g-channel-header-image-position: relative;

			.g-channel-header-info-container {
				max-width: none;
				flex-grow: 0;
				flex-basis: auto;
			}

			.g-channel-header-image-container {
				background-color: #fafafa;
				border: 2px solid @grey;
				display: flex;
				justify-content: center;
				align-items: center;
				min-height: 38px;
				min-width: 38px;

				&:before {
					.element-elevation(0);
				}

				&:hover,
				&:focus {
					background-color: #f0f0f0;
				}

				&:active,
				&.popover-shown {
					&:before {
						.element-elevation(-2);
					}
				}

				.g-channel-name-icon {
					font-size: 20px;
				}

				.g-channel-name-text {
					margin-bottom: 0;
				}
			}
		}

		.g-channel-header-info-container {
			display: flex;
			flex-direction: var(--g-channel-header-info-container-direction);
			justify-content: center;
			align-items: stretch;
			flex: 0 1 var(--g-channel-header-info-container-size);
			min-width: 0; // necessary for text ellipsis to work (see css-tricks)
			min-height: 0;
			max-width: 100%;
			max-height: 100%;

			.g-channel-name {
				display: flex;
				align-items: center;
				justify-content: var(--g-channel-name-text-align);
				min-width: 0;
				flex-shrink: 0;

				.g-channel-name-text {
					.text-ellipsis();
					font-weight: 700;
					font-size: 16px;
				}
			}

			.g-health-bar {
				border: 0;
			}

			.g-channel-status {
				display: flex;
				align-items: center;

				.g-channel-status-text {
					.text-ellipsis();
					text-align: center;
					font-size: 12px;
					color: #95989a;
					display: flex;

					& > span {
						.text-ellipsis();
					}
				}

				.g-channel-status-indicators {
					z-index: 3;
					border-radius: var(--g-channel-status-indicator-size);
					overflow: hidden;
					display: flex;
					flex: 0 0 auto;
					box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

					&:before {
						z-index: 2;
						border-radius: var(--g-channel-status-indicator-size);
					}
				}

				.g-status-indicator {
					flex: 0 0 var(--g-channel-status-indicator-size);
					height: var(--g-channel-status-indicator-size);
					width: var(--g-channel-status-indicator-size);
					border-radius: 100%;

					i {
						font-size: var(--g-channel-status-indicator-icon-size);

						&.icon-play {
							margin-right: -3px;
						}

						&.icon-exclamation {
							margin-top: 1px;
						}
					}
				}
			}
		}
	}

	.g-cells {
		display: flex;
		flex: 1 0 0%;
		flex-direction: var(--g-section-flow-direction);
		justify-content: var(--g-section-flow-justify-content);

		.g-cell {
			transition: @pave-expand-transition;
			flex: 1 0 auto; //basis overwritten in JS
			display: flex;
			border-right: @section-grid-border;
			border-bottom: @section-grid-border;
			overflow: hidden;
			position: relative;

			.after,
			.before {
				content: "";
				position: absolute;
				width: 100%;
				height: 10px;
				pointer-events: none;
				z-index: 20;
			}

			.before {
				top: 0;
			}

			.after {
				bottom: 0;
			}

			&.collapsed {
				flex-grow: 0;
			}

			.g-tickets {
				flex: 1 0 auto; //basis overwritten in JS
				padding: 6px;
				overflow: hidden;
				position: relative;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				align-content: flex-start;

				&::-webkit-scrollbar {
					display: none;
				}
			}

			.g-cell-ticket-counter-container:not(:empty) {
				flex: 0 0 auto;
				padding-left: 4px;
				z-index: 21;
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;

				.g-cell-ticket-counter-button {
					border-radius: 0;
					border-bottom-left-radius: @rounded-corners;
				}
			}
		}
	}
}

.g-section-flow-vertical {
	--g-channel-header-direction: row;
	--g-channel-header-info-container-direction: column;

	.g-channel-container:first-child .g-cell {
		.single-cell & {
			border-left: @section-grid-border;
			border-bottom-left-radius: @rounded-corners;
		}

		.single-cell.NOT & {
			border-top-left-radius: @rounded-corners;
		}
	}

	.g-channel {
		.g-channel-header {
			width: auto;
			padding: 0 10px;
			margin-left: -1px;
			border-top-left-radius: @rounded-corners;
			border-top-right-radius: @rounded-corners;
			border-right: @channel-header-border;

			&.g-group-channel-header {
				.g-channel-header-image-container {
					margin-right: 15px;
				}
			}

			.g-channel-header-image-container {
				.g-section.BUC & {
					margin-right: 0;
				}
			}

			.g-channel-header-info-container {
				margin-left: -2px;

				.g-section.BUC & {
					margin-left: 10px;
				}

				.g-channel-name {
					margin-bottom: 2px;
					min-width: 0;
				}

				.g-channel-health-bar {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}

				.g-channel-status {
					margin-left: -20px;
					margin-top: -6px;

					.g-channel-status-indicators {
						.g-status-indicator {
							border-radius: 0;
						}
					}

					.g-channel-status-text {
						margin-top: 4px;
						margin-left: 5px;

						.g-duration {
							margin-left: 4px;
						}
					}
				}
			}
		}

		.g-cells {
			width: 100%;

			.g-cell {
				flex-direction: row;
			}
		}

		&:last-child {
			.g-cell {
				border-right: @section-grid-border;
			}
		}
	}

	.small-channel {
		.g-channel-header.g-group-channel-header {
			.g-channel-header-image-container {
				margin-right: 10px;
			}
		}

		.g-channel-header:not(.g-group-channel-header) {
			padding: 0;

			.g-channel-header-image-container {
				top: 5px;
				left: 5px;

				.g-section.BUC & {
					top: auto;
				}
			}

			.g-channel-header-info-container {
				margin-left: 0;

				.g-channel-name {
					display: initial;
					margin-left: ~"calc(var(--g-channel-resource-image-size) + 12px)";
					padding-right: 5px;
					min-height: 0;

					.g-section.BUC & {
						margin-bottom: 0;
					}
				}

				.g-channel-status {
					margin-left: 8px;
					margin-right: 8px;
					margin-top: -4px;
				}
			}
		}
	}
}

.g-section-flow-horizontal {
	--g-channel-header-direction: column-reverse;
	--g-channel-header-info-container-direction: row;

	.g-channel-container:first-child .g-cell {
		.single-cell & {
			border-top: @section-grid-border;
			border-top-right-radius: @rounded-corners;
		}

		.single-cell.NOT & {
			border-top-left-radius: @rounded-corners;
		}
	}

	.g-channel {
		.g-channel-header {
			padding: 10px 0;
			height: auto;
			margin-top: -1px;
			border-top-left-radius: @rounded-corners;
			border-bottom-left-radius: @rounded-corners;
			border-bottom: @channel-header-border;

			&.g-group-channel-header {
				.g-channel-header-image-container {
					margin-top: 15px;

					.g-section.BUC &{
						margin-top: 0;
					}
				}
			}

			.g-channel-header-info-container {
				margin-bottom: -2px;

				.g-section.BUC & {
					margin-bottom: 10px;
				}

				.g-channel-name {
					.vertical-text();
					margin-right: 2px;

					&-icon {
						margin-bottom: 10px;
						transform: rotate(180deg);
					}
				}

				.g-channel-health-bar {
					height: auto;
					.vertical-text();
					width: @health-bar-size;
					border-top-left-radius: 0;
					border-top-right-radius: 0;

					.g-health-bar-capacity-fill {
						align-self: flex-end;
					}
				}

				.g-channel-status {
					margin-bottom: -20px;
					margin-left: -6px;
					flex-direction: column-reverse;

					.g-channel-status-indicators {
						flex-direction: column-reverse;

						.g-status-indicator {
							border-radius: 0;
						}
					}

					.g-channel-status-text {
						.vertical-text();
						margin-left: 4px;
						margin-bottom: 5px;

						.g-duration {
							margin-top: 4px;
						}
					}

					.g-duration-label:not(:last-child) {
						margin-bottom: 4px;
						margin-right: 0;
					}
				}
			}
		}

		.g-cells {
			height: 100%;
			width: 100%;

			.g-cell {
				.g-tickets {
					flex: 1;
				}
			}
		}

		&:last-child {
			.g-cell {
				border-bottom: @section-grid-border;
			}
		}
	}

	.small-channel {
		.g-channel-header.g-group-channel-header {
			.g-channel-header-image-container {
				margin-top: 10px;
			}
		}

		.g-channel-header:not(.g-group-channel-header) {
			padding: 0;

			.g-channel-header-image-container {
				bottom: 5px;
				left: 5px;

				.g-section.BUC & {
					left: auto;
				}
			}

			.g-channel-header-info-container {
				margin-bottom: 0;

				.g-channel-name {
					padding-top: ~"calc(var(--g-channel-resource-image-size) + 12px)";
					padding-bottom: 5px;

					.g-section.BUC & {
						margin-right: 0;
					}
				}

				.g-channel-status {
					margin-top: 8px;
					margin-bottom: 6px;
					margin-left: -4px;
				}
			}
		}
	}
}

.g-section-flow-up {
	.g-channel-container {
		.g-channel .g-cells {
			.g-cell:first-child {
				border-top: @section-grid-border;
			}
		}

		&:last-child {
			.g-cell:last-child {
				border-bottom-right-radius: @rounded-corners;
			}
		}

		&:first-child {
			.g-cell {
				.NOT &:first-child {
					border-top-right-radius: @rounded-corners;
				}
			}
		}
	}
}

.g-section-flow-down {
	.g-channel-container {
		.g-channel .g-cells {
			.g-cell:last-child {
				border-top: @section-grid-border;
			}
		}

		&:last-child {
			.g-cell:first-child {
				border-bottom-right-radius: @rounded-corners;
			}
		}

		&:first-child {
			.g-cell {
				.NOT &:last-child {
					border-top-right-radius: @rounded-corners;
				}
			}
		}
	}
}

.g-section-flow-left {
	.g-channel-container {
		.g-channel .g-cells {
			.g-cell:first-child {
				border-left: @section-grid-border;
			}
		}

		&:last-child {
			.g-cell:last-child {
				border-bottom-right-radius: @rounded-corners;
			}
		}

		&:first-child {
			.g-cell {
				.NOT &:first-child {
					border-bottom-left-radius: @rounded-corners;
				}
			}
		}
	}
}

.g-section-flow-right {
	.g-channel-container {
		.g-channel .g-cells {
			.g-cell:last-child {
				border-left: @section-grid-border;
			}
		}

		&:last-child {
			.g-cell:first-child {
				border-bottom-right-radius: @rounded-corners;
			}
		}

		&:first-child {
			.g-cell {
				.NOT &:last-child {
					border-bottom-left-radius: @rounded-corners;
				}
			}
		}
	}
}
