.progress {
	height: 20px;
	overflow: hidden;
	background-color: #fff;
	border: solid 1px #d2d6d9;
	border-radius: 4px;
}
.progress-bar {
	float: left;
	width: 0%;
	height: 100%;
	font-size: 12px;
	line-height: 20px;
	color: #fff;
	text-align: center;
	background-color: #8fd300;
	-webkit-transition: width 0.6s ease;
	-o-transition: width 0.6s ease;
	transition: width 0.6s ease;
}
.progress.active .progress-bar,
.progress-bar.active {
	-webkit-animation: progress-bar-stripes 2s linear infinite;
	-o-animation: progress-bar-stripes 2s linear infinite;
	animation: progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}

	to {
		background-position: 0 0;
	}
}
@-o-keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}

	to {
		background-position: 0 0;
	}
}
@keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}

	to {
		background-position: 0 0;
	}
}
